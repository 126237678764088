import { BigNumber, constants } from 'ethers'
import cloneDeep from 'lodash.clonedeep'
import { PoolSymbol, poolSymbols } from '../config/contracts/pool/poolSymbol'
import {
  TokenSymbol,
  tokenSymbols,
} from '../config/contracts/token/tokenSymbol'
import { TpYieldSymbols } from '../config/TpYield'
import {
  PoolSymbolStringBooleanType,
  PoolSymbolStringStringStringType,
  PoolSymbolStringStringType,
  PoolSymbolTokenSymbolTokenSymbolBigNumberType,
  PoolSymbolTokenSymbolTokenSymbolBooleanType,
  PoolSymbolTokenSymbolTokenSymbolStringType,
  TokenSymbolBigNumberType,
  TokenSymbolStringType,
} from '../interfaces/common'
import { ApproveSpender } from '../interfaces/spender'
import {
  AprDataType,
  LpDataType,
  PtpDataType,
} from '../interfaces/StakeLpContext'
import { TpYieldLiquidtyWADType as TpYieldLiquidtyWADType } from '../interfaces/TpYield'

export const EMPTY_TOKENSYMBOL_STR = tokenSymbols.reduce((acc, symbol) => {
  return { ...acc, [symbol]: '0.0' }
}, {}) as TokenSymbolStringType

export const EMPTY_TOKENSYMBOL_BN = tokenSymbols.reduce((acc, symbol) => {
  return { ...acc, [symbol]: constants.Zero }
}, {}) as TokenSymbolBigNumberType

export const EMPTY_POOLSYMBOL_STR = poolSymbols.reduce((acc, poolSymbol) => {
  return {
    ...acc,
    [poolSymbol]: '0',
  }
}, {}) as {
  [id in PoolSymbol]: string
}

export const EMPTY_POOLSYMBOL_BN = poolSymbols.reduce((acc, poolSymbol) => {
  return {
    ...acc,
    [poolSymbol]: constants.Zero,
  }
}, {}) as {
  [id in PoolSymbol]: BigNumber
}

export const EMPTY_POOLSYMBOL_STR_STR = poolSymbols.reduce(
  (acc, poolSymbol) => {
    return {
      ...acc,
      [poolSymbol]: {},
    }
  },
  {},
) as PoolSymbolStringStringType

export const EMPTY_POOLSYMBOL_STR_BOOLEAN = poolSymbols.reduce(
  (acc, poolSymbol) => {
    return {
      ...acc,
      [poolSymbol]: {},
    }
  },
  {},
) as PoolSymbolStringBooleanType

export const EMPTY_POOLSYMBOL_STR_BN = poolSymbols.reduce((acc, poolSymbol) => {
  return {
    ...acc,
    [poolSymbol]: {},
  }
}, {}) as {
  [id in PoolSymbol]: Record<string, BigNumber>
}

export const EMPTY_TP_YIELD_LIQUIDTY_WAD = TpYieldSymbols.reduce(
  (acc, tpYieldSymbol) => {
    return {
      ...acc,
      [tpYieldSymbol]: {
        [TokenSymbol.AVAX]: constants.Zero,
        [TokenSymbol.PTP]: constants.Zero,
      },
    }
  },
  {},
) as TpYieldLiquidtyWADType

export const EMPTY_APPROVALSPENDER_TOKENSYMOBL_STR = Object.values(
  ApproveSpender,
).reduce((acc, spender) => {
  return {
    ...acc,
    [spender]: cloneDeep(EMPTY_TOKENSYMBOL_STR),
  }
}, {}) as {
  [id in ApproveSpender]: {
    [id in TokenSymbol]: string
  }
}

export const EMPTY_POOLSYMBOL_APPROVALSPENDER_TOKENSYMOBL_STR = Object.values(
  PoolSymbol,
).reduce((acc, poolSymbol) => {
  return {
    ...acc,
    [poolSymbol]: cloneDeep(EMPTY_APPROVALSPENDER_TOKENSYMOBL_STR),
  }
}, {}) as {
  [id in PoolSymbol]: {
    [id in ApproveSpender]: {
      [id in TokenSymbol]: string
    }
  }
}

// { [poolSymbol]: { [tokenSymbol]: '0.0' } } for only the tokens in the pool
export const EMPTY_POOLSYMBOL_TOKENSYMBOL_STR = poolSymbols.reduce(
  (acc, poolSymbol) => {
    return {
      ...acc,
      /** @todo refactor EMPTY_POOLSYMBOL_TOKENSYMBOL_STR */
      // 'all' asset included becuse some situations need non available asset etc.lpTokenForEmergencyWithdrawApproval
      [poolSymbol]: cloneDeep(EMPTY_TOKENSYMBOL_STR),
    }
  },
  {},
) as { [id in PoolSymbol]: TokenSymbolStringType }

// { [poolSymbol]: { [tokenSymbol]: BigNumber Zero } } for only the tokens in the pool
export const EMPTY_POOLSYMBOL_TOKENSYMBOL_BN = poolSymbols.reduce(
  (acc, poolSymbol) => {
    return {
      ...acc,
      /** @todo refactor EMPTY_POOLSYMBOL_TOKENSYMBOL_BN */
      // 'all' asset included becuse some situations need non available asset etc.lpTokenForEmergencyWithdrawApproval
      [poolSymbol]: cloneDeep(EMPTY_TOKENSYMBOL_BN),
    }
  },
  {},
) as { [id in PoolSymbol]: TokenSymbolBigNumberType }

export const EMPTY_POOLSYMBOL_STR_STR_STR = poolSymbols.reduce(
  (acc, poolSymbol) => {
    return {
      ...acc,
      [poolSymbol]: {},
    }
  },
  {},
) as PoolSymbolStringStringStringType

export const EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_STR = poolSymbols.reduce(
  (acc, poolSymbol) => {
    return {
      ...acc,
      [poolSymbol]: {},
    }
  },
  {},
) as PoolSymbolTokenSymbolTokenSymbolStringType

export const EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_BN = poolSymbols.reduce(
  (acc, poolSymbol) => {
    return {
      ...acc,
      [poolSymbol]: {},
    }
  },
  {},
) as PoolSymbolTokenSymbolTokenSymbolBigNumberType

export const EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_BOOLEAN =
  poolSymbols.reduce((acc, poolSymbol) => {
    return {
      ...acc,
      [poolSymbol]: {},
    }
  }, {}) as PoolSymbolTokenSymbolTokenSymbolBooleanType

export const EMPTY_LP_DATA = {
  inTermsOfLp: {
    stakable: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
    staked: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
    total: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
  },
  inTermsOfToken: {
    stakable: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
    staked: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
    total: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
  },
} as LpDataType

export const EMPTY_APR_DATA = {
  total: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
  base: {
    sum: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
    each: cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_STR),
  },
  ptpMonthlyEmission: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
  medianBoosted: {
    sum: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
    each: cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_STR),
  },
  exactBoosted: {
    sum: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
    each: cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_STR),
  },
} as AprDataType

export const EMPTY_PTP_DATA = {
  earned: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
  sumAllPid: cloneDeep(EMPTY_POOLSYMBOL_STR),
} as PtpDataType

export const EMPTY_TOKEN_APPROVALS = {
  [ApproveSpender.VePtp]: {
    [TokenSymbol.PTP]: '0.0',
  },
  [ApproveSpender.WAVAX]: {
    [TokenSymbol.WAVAX]: '0.0',
  },
  [ApproveSpender.Router]: cloneDeep(EMPTY_TOKENSYMBOL_STR),
  [ApproveSpender.Pool]: cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_STR),
  [ApproveSpender.PlatypusTreasure]: {
    [TokenSymbol.USP]: '0.0',
  },
}

export const EMPTY_LP_TOKEN_APPROVALS = {
  [ApproveSpender.MasterPlatypus]: cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_STR),
  [ApproveSpender.Pool]: cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_STR),
  [ApproveSpender.EmergencyWithdraw]: cloneDeep(
    EMPTY_POOLSYMBOL_TOKENSYMBOL_STR,
  ),
}
