import {
  getCommifiedFormat,
  getDpFormat,
  getPercentageFromTwoWAD,
  nativeToWAD,
  safeWdiv,
  strToWad,
  wmul,
  wsqrt,
} from '@hailstonelabs/big-number-utils'
import { Typography, useTheme } from '@mui/material'
import { BigNumber, constants, utils } from 'ethers'
import React, { ReactElement } from 'react'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import { LP_TOKENS, POOLS, TOKENS } from '../../../config/contracts'
import { useBalance } from '../../../contexts/BalanceContext'
import { usePools } from '../../../contexts/PoolsContext'
import { useStakeLpData } from '../../../contexts/StakeLpDataContext'
import { useVePtpBoosterCalculator } from '../../../contexts/VePtpBoosterCalculatorContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { getEstimatedBoostedAprPercent } from '../../../utils/aprCalculation'

function CalculatedResult(): ReactElement {
  const theme = useTheme()
  const {
    apr: { exactBoosted, base },
    aprCalculationDataInEachPool,
  } = useStakeLpData()
  const { liabilities, lpSuppliesBN } = usePools()
  const { tokenPrices } = useBalance()
  const { vePtp } = useVePtp()
  const {
    myStakedDepositInput,
    totalVePtpSupplyInputAmount,
    poolLiquidityInputAmount,
    estimatedVePtpBalance,
  } = useVePtpBoosterCalculator()

  const vePtpSharePercent = getPercentageFromTwoWAD(
    strToWad(estimatedVePtpBalance),
    strToWad(totalVePtpSupplyInputAmount),
    strToWad('100'),
  )

  const assetTokenSymbol = myStakedDepositInput.assetTokenSymbol
  const poolSymbol = myStakedDepositInput.poolSymbol

  let exactBoostedApr: string | null = null
  let baseApr: string | null = null
  let estimatedBoostedApr: string | null = null

  if (assetTokenSymbol && poolSymbol) {
    // Base APR
    baseApr = base.sum[poolSymbol][assetTokenSymbol] || '0.0'

    if (
      aprCalculationDataInEachPool &&
      (POOLS[poolSymbol].hasPtpBooster || POOLS[poolSymbol].hasBonusBooster)
    ) {
      // Exact Boosted APR
      exactBoostedApr = exactBoosted.sum[poolSymbol][assetTokenSymbol] || '0.0'

      // Estimated Boosted APR
      const tokenDecimals = TOKENS[assetTokenSymbol].decimals
      const totalVePtpInputWad = strToWad(totalVePtpSupplyInputAmount)
      const poolLiquidityInputWad = strToWad(poolLiquidityInputAmount)
      const totalVePtpWad = strToWad(vePtp.totalSupply)
      const poolLiquidityWad = strToWad(
        liabilities[poolSymbol][assetTokenSymbol],
      )

      // simulate the effect on poolTotalFactors based on different vePtp total supply and pool tvl
      const numerator = wmul(totalVePtpInputWad, poolLiquidityInputWad)
      const denominator = wmul(totalVePtpWad, poolLiquidityWad)

      let poolTotalFactorsMultiplier = constants.Zero
      if (!denominator.isZero()) {
        poolTotalFactorsMultiplier = wsqrt(safeWdiv(numerator, denominator))
      }

      const {
        poolSumOfFactorsBNs,
        ptpPerSecWad,
        weightOfEachAssetWads,
        totalWeightWad,
        nonDilutingRepartition,
        bonusNonDilutingRepartitions,
        bonusTokenPerSecBNs,
      } = aprCalculationDataInEachPool

      const assetTokenPrice = strToWad(tokenPrices[assetTokenSymbol])
      const poolSumOfFactor = poolSumOfFactorsBNs[poolSymbol][assetTokenSymbol]

      const rewards = LP_TOKENS[poolSymbol][assetTokenSymbol]?.rewards
      rewards?.forEach((reward) => {
        let newEstimatedBoostedApr: string | null = null
        if (reward.method === 'masterPlatypus.pendingTokens') {
          newEstimatedBoostedApr = getEstimatedBoostedAprPercent(
            estimatedVePtpBalance,
            myStakedDepositInput.amount,
            assetTokenSymbol,
            lpSuppliesBN[poolSymbol][assetTokenSymbol],
            utils.parseUnits(
              liabilities[poolSymbol][assetTokenSymbol],
              tokenDecimals,
            ),
            poolSumOfFactor,
            ptpPerSecWad,
            weightOfEachAssetWads[poolSymbol][assetTokenSymbol],
            totalWeightWad,
            BigNumber.from(nonDilutingRepartition),
            assetTokenPrice,
            tokenPrices.PTP,
            poolTotalFactorsMultiplier,
          )
        }
        if (reward.method === 'rewarder.pendingTokens') {
          const bonusRewardSymbol = reward.tokenSymbol
          const bonusDecimal = TOKENS[bonusRewardSymbol].decimals
          const bonusTokenPerSecBN =
            bonusTokenPerSecBNs[poolSymbol][assetTokenSymbol]?.[
              bonusRewardSymbol
            ]
          const bonusNonDilutingRepartition =
            bonusNonDilutingRepartitions[poolSymbol][assetTokenSymbol]?.[
              bonusRewardSymbol
            ]
          if (bonusTokenPerSecBN && bonusNonDilutingRepartition) {
            newEstimatedBoostedApr = getEstimatedBoostedAprPercent(
              estimatedVePtpBalance,
              myStakedDepositInput.amount,
              assetTokenSymbol,
              lpSuppliesBN[poolSymbol][assetTokenSymbol],
              utils.parseUnits(
                liabilities[poolSymbol][assetTokenSymbol],
                tokenDecimals,
              ),
              poolSumOfFactor,
              nativeToWAD(bonusTokenPerSecBN, bonusDecimal),
              strToWad('1'),
              strToWad('1'),
              BigNumber.from(bonusNonDilutingRepartition),
              assetTokenPrice,
              tokenPrices[bonusRewardSymbol],
              poolTotalFactorsMultiplier,
            )
          }
        }
        if (newEstimatedBoostedApr) {
          estimatedBoostedApr = utils.formatEther(
            strToWad(estimatedBoostedApr || '0').add(
              strToWad(newEstimatedBoostedApr),
            ),
          )
        }
      })
    }
  }

  return (
    <InfoBox paddingBottom="0">
      <InfoBox.Item mb="2px">
        <InfoBox.Item>
          <Typography variant="caption">vePTP share</Typography>
          <InfoBox.Tooltip text="The percentage of vePTP you would own." />
        </InfoBox.Item>
        <Typography variant="caption">
          <TooltipNum rightSymbol="%" amount={vePtpSharePercent}>
            {getCommifiedFormat(vePtpSharePercent)}%
          </TooltipNum>
        </Typography>
      </InfoBox.Item>
      <InfoBox.Item mb="2px">
        <InfoBox.Item>
          <Typography variant="caption">Base APR</Typography>
          <InfoBox.Tooltip text="The base APR is independent of your vePTP balance." />
        </InfoBox.Item>
        <Typography variant="caption">
          {baseApr ? (
            <TooltipNum rightSymbol="%" amount={baseApr}>
              {`${getDpFormat(baseApr, 1)}%`}
            </TooltipNum>
          ) : (
            '?%'
          )}
        </Typography>
      </InfoBox.Item>
      <InfoBox.Item mb="2px">
        <InfoBox.Item>
          <Typography variant="caption">Current Boosted APR</Typography>
          <InfoBox.Tooltip text="The current boosted APR of all rewards' emission you are earning at." />
        </InfoBox.Item>
        <Typography variant="caption">
          {exactBoostedApr ? (
            <TooltipNum rightSymbol="%" amount={exactBoostedApr}>
              {`${getDpFormat(exactBoostedApr, 1)}%`}
            </TooltipNum>
          ) : (
            '?%'
          )}
        </Typography>
      </InfoBox.Item>
      <InfoBox.Item style={{ color: theme.palette.secondary.main }}>
        <InfoBox.Item>
          <Typography variant="caption">Estimated Boosted APR</Typography>
          <InfoBox.Tooltip text="An estimate of the Boosted APR based on the vePTP balance you would have. The actual value is also affected by the distribution of vePTP." />
        </InfoBox.Item>
        <Typography variant="caption">
          {estimatedBoostedApr ? (
            <TooltipNum rightSymbol="%" amount={estimatedBoostedApr}>
              {`${getDpFormat(estimatedBoostedApr, 1)}%`}
            </TooltipNum>
          ) : (
            '?%'
          )}
        </Typography>
      </InfoBox.Item>
    </InfoBox>
  )
}

export default CalculatedResult
