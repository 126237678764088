import { Box, ButtonProps } from '@mui/material'
import React, { ReactElement } from 'react'
import Spinner from '../../Spinner/Spinner'
import { StyledButton } from './AppButton.elements'

export type VariantType =
  | 'primary'
  | 'secondary'
  | 'neutral'
  | 'negative'
  | 'unset'
  | undefined
export interface AppButtonProps extends ButtonProps {
  children?: React.ReactNode | undefined
  customVariant?: VariantType
  margin?: string
  hasSpinner?: boolean
}

function AppButton({
  children,
  customVariant = 'primary',
  margin,
  hasSpinner = false,
  ...otherProps
}: AppButtonProps): ReactElement {
  return (
    <StyledButton
      disableRipple
      disableFocusRipple
      disableTouchRipple
      $customVariant={customVariant}
      margin={margin}
      {...otherProps}
    >
      {children}
      {hasSpinner && (
        <Box marginLeft={1}>
          <Spinner width={24} />
        </Box>
      )}
    </StyledButton>
  )
}

export default AppButton
