import React, { ReactElement } from 'react'
import WaitForConfirmationModal from '../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import DisabledAppTokenInput from '../../../components/TokenInput/DisabledAppTokenInput'
import { useModal } from '../../../contexts/ModalContext'
import { usePoolInput } from '../../../contexts/PoolInputContext'
import PoolWithdrawInfoBox from '../../PoolContainer/PoolWithdrawInfoBox'

interface Props {
  isOpen: boolean
}

function PoolWithdrawWaitForConfirmationModalContainer({
  isOpen,
}: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const {
    withdrawLiquidity,
    withdrawTokenAmount,
    actions: { clearWithdrawLiquidity },
  } = usePoolInput()
  const handleCloseModal = () => {
    modalDispatch(closeModal())
    clearWithdrawLiquidity()
  }
  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="600px"
      subtitle="Confirm Withdrawal"
    >
      <DisabledAppTokenInput
        tokenItems={[
          {
            tokenSymbol: withdrawLiquidity.toTokenSymbolForDisplay,
            value: withdrawTokenAmount,
          },
        ]}
      />
      <PoolWithdrawInfoBox isWaitForComfirmation />
    </WaitForConfirmationModal>
  )
}

export default PoolWithdrawWaitForConfirmationModalContainer
