import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import { Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useVePtp } from '../../../contexts/VePtpContext'

function StakeModalInfoBox(): ReactElement {
  const { ptp, input } = useVePtp()
  return (
    <InfoBox>
      <InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">Token Price</Typography>
        </InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">
            <b>$ {ptp.price}</b>
          </Typography>
        </InfoBox.Item>
      </InfoBox.Item>
      <InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">Total Stake</Typography>
        </InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">
            <b>
              <TooltipNum
                rightSymbol={TokenSymbol.PTP}
                amount={input.stakePtpTotal.stake}
              >
                {getCommifiedFormat(input.stakePtpTotal.stake)}{' '}
                {TokenSymbol.PTP}
              </TooltipNum>
            </b>
          </Typography>
        </InfoBox.Item>
      </InfoBox.Item>
    </InfoBox>
  )
}

export default StakeModalInfoBox
