import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import AppTokenInput from '../../../components/TokenInput/AppTokenInput'

export const InputFieldContainer = styled(Box)`
  ${() => css`
    display: flex;
    flex-direction: row;
  `}
`

export const WithdrawInOtherTokenButton = styled(AppButton)`
  ${({ theme }) => css`
    padding: 0;
    margin: 0;
    color: ${theme.palette.primary['A200']};
    svg {
      font-size: 16px;
      margin: 0 8px;
    }
  `}
`
export const StyledSelectTokenButton = styled(AppTokenInput.SelectTokenButton)`
  margin-bottom: 12px;
`
