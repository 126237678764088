import { Call } from 'ethcall'
import { BigNumber, utils } from 'ethers'
import cloneDeep from 'lodash.clonedeep'
import {
  EMERGENCY_WITHDRAWS,
  LP_TOKENS,
  MASTER_PLATYPUS,
  multicall3,
  platypusTreasure,
  POOLS,
  router,
  TOKENS,
  vePtp,
} from '../../../config/contracts'
import { poolSymbols } from '../../../config/contracts/pool/poolSymbol'
import { TOKEN_SYMBOLS_FOR_DATA_FETCHING } from '../../../config/contracts/token'
import {
  TokenSymbol,
  tokenSymbols,
} from '../../../config/contracts/token/tokenSymbol'
import { ChainId } from '../../../config/networks'
import {
  EMPTY_POOLSYMBOL_TOKENSYMBOL_STR,
  EMPTY_TOKENSYMBOL_STR,
} from '../../../constants/empty'
import {
  PoolSymbolTokenSymbolStringType,
  TokenSymbolStringType,
} from '../../../interfaces/common'
import {
  CallbacksType,
  MulticallHelperSCReturnType,
} from '../../../utils/multicall'

export interface BalanceDataType {
  tokenAmounts: TokenSymbolStringType
  lpTokenAmounts: PoolSymbolTokenSymbolStringType
  // for stake ptp in vePtp
  ptpForVePtpApproval: string
  // for unwrap wavax
  wavaxApproval: string
  // for EmergencyWithdraw
  lpTokenForEmergencyWithdrawApproval: PoolSymbolTokenSymbolStringType
  // for swap, deposit and withdraw in pool
  tokenForRouterApprovals: TokenSymbolStringType
  tokenForPoolApprovals: PoolSymbolTokenSymbolStringType
  lpTokenForPoolApprovals: PoolSymbolTokenSymbolStringType
  // for stake lp in MP (can be mp1 or mp2 base on pool config)
  lpTokenForMasterPlatypusApprovals: PoolSymbolTokenSymbolStringType
  uspForPlatypusTreasureApproval: string
}

export const fetchBalanceData = (
  chainId: ChainId,
  account: string | null,
): {
  contractCalls: Call[]
  callbacks: CallbacksType<MulticallHelperSCReturnType>
  states: BalanceDataType
} => {
  /**
   * A. Create empty contractCalls and callbacks array
   */
  const contractCalls: Call[] = []
  const callbacks: CallbacksType<MulticallHelperSCReturnType> = []
  /**
   * Balance Data:
   * 1. tokenAmount, tokenApproval
   * 2. lpTokenAmount, lpTokenApproval
   */
  const states: BalanceDataType = {
    tokenAmounts: { ...EMPTY_TOKENSYMBOL_STR },
    lpTokenAmounts: cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_STR),
    ptpForVePtpApproval: '0',
    wavaxApproval: '0',
    lpTokenForEmergencyWithdrawApproval: cloneDeep(
      EMPTY_POOLSYMBOL_TOKENSYMBOL_STR,
    ),
    tokenForRouterApprovals: { ...EMPTY_TOKENSYMBOL_STR },
    tokenForPoolApprovals: cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_STR),
    lpTokenForPoolApprovals: cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_STR),
    lpTokenForMasterPlatypusApprovals: cloneDeep(
      EMPTY_POOLSYMBOL_TOKENSYMBOL_STR,
    ),
    uspForPlatypusTreasureApproval: '0',
  }

  const handleBalanceData = () => {
    if (!account) return
    // lpTokenForEmergencyWithdrawApproval
    for (let i = 0; i < poolSymbols.length; i++) {
      const poolSymbol = poolSymbols[i]
      for (let j = 0; j < tokenSymbols.length; j++) {
        const tokenSymbol = tokenSymbols[j]
        const emergencyWithdraw = EMERGENCY_WITHDRAWS[poolSymbol]?.[tokenSymbol]
        const lpToken = LP_TOKENS[poolSymbol][tokenSymbol]
        if (emergencyWithdraw && lpToken) {
          const lpTokenContract = lpToken.get(chainId)
          const emergencyWithdrawAddress = emergencyWithdraw.getAddress(chainId)
          if (lpTokenContract && emergencyWithdrawAddress) {
            contractCalls.push(
              lpTokenContract.allowance(
                account,
                emergencyWithdrawAddress,
              ) as unknown as Call,
            )
            callbacks.push((valueBN) => {
              states.lpTokenForEmergencyWithdrawApproval[poolSymbol][
                tokenSymbol
              ] = utils.formatUnits(valueBN as BigNumber, lpToken.decimals)
            })
          }
        }
      }
    }

    // uspForPlatypusTreasureApproval
    const uspContract = TOKENS[TokenSymbol.USP].get(chainId)
    const platypusTreasureAddress = platypusTreasure.getAddress(chainId)
    if (uspContract && platypusTreasureAddress) {
      contractCalls.push(
        uspContract.allowance(
          account,
          platypusTreasureAddress,
        ) as unknown as Call,
      ),
        callbacks.push((valueBn) => {
          states.uspForPlatypusTreasureApproval = utils.formatUnits(
            valueBn as BigNumber,
            TOKENS[TokenSymbol.USP].decimals,
          )
        })
    }
    // ptpApproval
    const ptpContract = TOKENS[TokenSymbol.PTP].get(chainId)
    const vePtpAddress = vePtp.getAddress(chainId)
    if (ptpContract && vePtpAddress) {
      contractCalls.push(
        ptpContract.allowance(account, vePtpAddress) as unknown as Call,
      )
      callbacks.push((valueBN) => {
        states.ptpForVePtpApproval = utils.formatUnits(
          valueBN as BigNumber,
          TOKENS[TokenSymbol.PTP].decimals,
        )
      })
    }

    // wavaxApproval
    const wavaxContract = TOKENS[TokenSymbol.WAVAX].get(chainId)
    const wavaxAddress = TOKENS[TokenSymbol.WAVAX].getAddress(chainId)
    if (wavaxContract && wavaxAddress) {
      contractCalls.push(
        wavaxContract.allowance(account, wavaxAddress) as unknown as Call,
      )
      callbacks.push((valueBN) => {
        states.wavaxApproval = utils.formatUnits(
          valueBN as BigNumber,
          TOKENS[TokenSymbol.WAVAX].decimals,
        )
      })
    }
    //token approval, lp Token balance and approval on different master platypus
    for (let j = 0; j < poolSymbols.length; j++) {
      const poolSymbol = poolSymbols[j]
      const pool = POOLS[poolSymbol]
      const poolAddress = pool.getAddress(chainId)
      const assets = pool.getAssets()
      const masterPlatypusAddress =
        MASTER_PLATYPUS[pool.masterPlatypusId].getAddress(chainId)
      for (let l = 0; l < assets.length; l++) {
        const assetTokenSymbol = assets[l].tokenSymbol
        const token = TOKENS[assetTokenSymbol]
        const tokenDecimals = token.decimals
        const tokenContract = token.get(chainId)
        const lpToken = assets[l]
        const lpTokenContract = lpToken?.get(chainId)
        // token allowance on poolAddress
        if (tokenContract && poolAddress) {
          contractCalls.push(
            tokenContract.allowance(account, poolAddress) as unknown as Call,
          )
          callbacks.push((valueBN) => {
            states.tokenForPoolApprovals[poolSymbol][assetTokenSymbol] =
              utils.formatUnits(valueBN as BigNumber, tokenDecimals)
          })
        }
        // lp Token balance and approval on all MP, lp token approval on Pool
        if (lpTokenContract) {
          if (poolAddress) {
            contractCalls.push(
              lpTokenContract.allowance(
                account,
                poolAddress,
              ) as unknown as Call,
            )
            callbacks.push((valueBN) => {
              states.lpTokenForPoolApprovals[poolSymbol][assetTokenSymbol] =
                utils.formatUnits(valueBN as BigNumber, tokenDecimals)
            })
          }
          if (masterPlatypusAddress) {
            contractCalls.push(
              lpTokenContract.allowance(
                account,
                masterPlatypusAddress,
              ) as unknown as Call,
            )
            callbacks.push((valueBN) => {
              states.lpTokenForMasterPlatypusApprovals[poolSymbol][
                assetTokenSymbol
              ] = utils.formatUnits(valueBN as BigNumber, tokenDecimals)
            })
          }
          contractCalls.push(
            lpTokenContract.balanceOf(account) as unknown as Call,
          )
          callbacks.push((valueBN) => {
            states.lpTokenAmounts[poolSymbol][assetTokenSymbol] =
              utils.formatUnits(valueBN as BigNumber, tokenDecimals)
          })
        }
      }
    }
    // token balance
    // avax balance (non ERC20)
    const multicall3Contract = multicall3.get(chainId)
    if (multicall3Contract) {
      contractCalls.push(
        multicall3Contract.getEthBalance(account) as unknown as Call,
      ),
        callbacks.push(
          (valueBN) =>
            (states.tokenAmounts.AVAX = utils.formatEther(
              valueBN as BigNumber,
            )),
        )
    }
    for (let i = 0; i < TOKEN_SYMBOLS_FOR_DATA_FETCHING.length; i++) {
      const tokenSymbol = TOKEN_SYMBOLS_FOR_DATA_FETCHING[i]
      const token = TOKENS[tokenSymbol]
      const tokenContract = token.get(chainId)
      const routerAddress = router.getAddress(chainId)
      if (tokenContract) {
        if (routerAddress) {
          contractCalls.push(
            tokenContract.allowance(account, routerAddress) as unknown as Call,
          )
          callbacks.push((valueBN) => {
            states.tokenForRouterApprovals[tokenSymbol] = utils.formatUnits(
              valueBN as BigNumber,
              token.decimals,
            )
          })
        }
        contractCalls.push(tokenContract.balanceOf(account) as unknown as Call)
        callbacks.push((valueBN) => {
          states.tokenAmounts[tokenSymbol] = utils.formatUnits(
            valueBN as BigNumber,
            token.decimals,
          )
        })
      }
    }
  }
  handleBalanceData()

  return {
    contractCalls,
    callbacks,
    states,
  }
}
