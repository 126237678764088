import {
  getCommifiedFormat,
  isParsableString,
  strToWad,
} from '@hailstonelabs/big-number-utils'
import { alpha, Box, useTheme } from '@mui/material'
import { constants, utils } from 'ethers'
import React, { ReactElement, useEffect, useState } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import AppCheckBox from '../../../components/Input/AppCheckBox/AppCheckBox'
import Modal from '../../../components/Modal/Modal'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import AppTokenInput from '../../../components/TokenInput/AppTokenInput'
import WarningMessage from '../../../components/WarningMessage/WarningMessage'
import { LP_TOKENS } from '../../../config/contracts'
import { TOKENS } from '../../../config/contracts/token'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { AVAX_AMOUNT_RESERVED_FOR_GAS } from '../../../constants'
import { useBalance } from '../../../contexts/BalanceContext'
import { useModal } from '../../../contexts/ModalContext'
import { usePoolInput } from '../../../contexts/PoolInputContext'
import { useStakeLpData } from '../../../contexts/StakeLpDataContext'
import useDepositToken from '../../../hooks/pool/useDepositToken'
import useApprove from '../../../hooks/useApprove'
import useDebounce from '../../../hooks/useDebounce'
import { ApproveSpender } from '../../../interfaces/spender'
import PoolDepositInfoBox from '../../PoolContainer/PoolDepositInfoBox'
import { InputFieldContainer } from '../../SwapContainer/TokenInputsContainer.elements'
import { StyledInputField } from './PoolDepositModalContainer.elements'

interface Props {
  isOpen: boolean
}
function PoolDepositModalContainer({ isOpen }: Props): ReactElement {
  const theme = useTheme()
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const { lp } = useStakeLpData()
  const { tokenAmounts } = useBalance()
  const {
    depositLiquidity,
    selectedAssetTokenSymbol,
    actions: {
      updateTokenSymbolFromDepositLiquidity: updateTokenFromDepositLiquidity,
      updateAmountFromDepositLiquidity,
    },
    selectedPoolSymbol,
  } = usePoolInput()
  // An amount that is used for UI
  const [depositLiquidityAmountInputted, setDepositLiquidityAmountInputted] =
    useState('')
  // An amount that is used to trigger updates
  const debouncedDepositLiquidityInputted = useDebounce(
    depositLiquidityAmountInputted,
  )
  const { handleDepositToken } = useDepositToken(depositLiquidity.tokenSymbol)
  // whether the approve button is clicked
  const { isApproved, isApproving, handleClickApprove } = useApprove(
    depositLiquidity.tokenSymbol,
    false,
    ApproveSpender.Pool,
    depositLiquidity.amount,
    selectedPoolSymbol,
  )

  /* only show checkbox when the token is either AVAX or WAVAX. */
  const isCheckBoxShown =
    selectedAssetTokenSymbol &&
    LP_TOKENS[selectedPoolSymbol][selectedAssetTokenSymbol]
      ?.isMultiTokenCheckboxSupported

  const checkedTokenSymbol =
    selectedAssetTokenSymbol &&
    LP_TOKENS[selectedPoolSymbol][selectedAssetTokenSymbol]
      ?.tokenSymbolForChecked

  const uncheckedTokenSymbol =
    selectedAssetTokenSymbol &&
    LP_TOKENS[selectedPoolSymbol][selectedAssetTokenSymbol]
      ?.tokenSymbolForUnchecked

  // when debouncedDepositLiquidityInputted changes, update it to PoolInputContext
  useEffect(() => {
    updateAmountFromDepositLiquidity(debouncedDepositLiquidityInputted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDepositLiquidityInputted])
  const handleClickMaxButton = () => {
    // get max
    if (depositLiquidity.tokenSymbol) {
      let maxAmountWad = strToWad(tokenAmounts[depositLiquidity.tokenSymbol])
      if (depositLiquidity.tokenSymbol === TokenSymbol.AVAX) {
        maxAmountWad = maxAmountWad.sub(strToWad(AVAX_AMOUNT_RESERVED_FOR_GAS))
      }
      if (maxAmountWad < constants.Zero) {
        maxAmountWad = constants.Zero
      }
      setDepositLiquidityAmountInputted(utils.formatEther(maxAmountWad))
    }
  }

  const handleInputChange = (value: string) => {
    if (value === '') {
      setDepositLiquidityAmountInputted('')
      return
    }

    if (depositLiquidity.tokenSymbol) {
      const token = TOKENS[depositLiquidity.tokenSymbol]
      if (!isParsableString(value, token.decimals, true)) {
        return
      }
      setDepositLiquidityAmountInputted(value)
    }
  }

  const handleModalClose = () => {
    modalDispatch(closeModal())
  }

  // whether the input exceeds user balance
  let exceedsBalance = false
  const hasZeroInput = strToWad(depositLiquidity.amount).eq('0')
  let deposited = '0.0'
  if (depositLiquidity.tokenSymbol && selectedAssetTokenSymbol) {
    const tokenAmountBalance = tokenAmounts[depositLiquidity.tokenSymbol]
    const token = TOKENS[depositLiquidity.tokenSymbol]
    const tokenAmountBN = utils.parseUnits(tokenAmountBalance, token.decimals)
    if (isParsableString(depositLiquidity.amount, token.decimals, true)) {
      const depositInputBN = utils.parseUnits(
        depositLiquidity.amount,
        token.decimals,
      )
      exceedsBalance = depositInputBN.gt(tokenAmountBN)
    }
    deposited =
      lp.inTermsOfToken.total[selectedPoolSymbol][selectedAssetTokenSymbol] ||
      '0.0'
  }

  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!checkedTokenSymbol || !uncheckedTokenSymbol) return
    if (e.target.checked) {
      updateTokenFromDepositLiquidity(checkedTokenSymbol)
    } else {
      updateTokenFromDepositLiquidity(uncheckedTokenSymbol)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      topBarLabel={
        <>
          Confirm Deposit
          <TokenIcon
            margin="0 4px 0 8px"
            tokenSymbol={depositLiquidity.tokenSymbol || undefined}
          />
          {depositLiquidity.tokenSymbol}
        </>
      }
      width="650px"
      disableCloseBtn
    >
      <AppTokenInput
        onChange={handleInputChange}
        value={depositLiquidityAmountInputted}
      >
        <AppTokenInput.LabelItem
          leadingLabel={
            depositLiquidity.tokenSymbol === null || tokenAmounts === null ? (
              'Deposited: 0.0'
            ) : (
              <AppTokenInput.LabelItemInnerContainer>
                <AppTokenInput.Label>Deposited:&nbsp;</AppTokenInput.Label>
                <TooltipNum
                  rightSymbol={depositLiquidity.tokenSymbolForDisplay || ''}
                  amount={deposited}
                >
                  {getCommifiedFormat(deposited)}&nbsp;
                  {depositLiquidity.tokenSymbolForDisplay}
                </TooltipNum>
              </AppTokenInput.LabelItemInnerContainer>
            )
          }
          trailingLabel={
            tokenAmounts === null || depositLiquidity.tokenSymbol === null ? (
              'Balance: 0.0'
            ) : (
              <AppTokenInput.LabelItemInnerContainer
                style={{ justifyContent: 'flex-end' }}
              >
                <AppTokenInput.Label>Balance:&nbsp;</AppTokenInput.Label>
                <TooltipNum
                  rightSymbol={depositLiquidity.tokenSymbol as string}
                  amount={tokenAmounts[depositLiquidity.tokenSymbol]}
                >
                  {getCommifiedFormat(
                    tokenAmounts[depositLiquidity.tokenSymbol],
                  )}{' '}
                  {depositLiquidity.tokenSymbol}
                </TooltipNum>
              </AppTokenInput.LabelItemInnerContainer>
            )
          }
        />
        <AppTokenInput.InnerContainer
          bgcolor={alpha(theme.palette.primary[500], 0.2)}
          mt="12px"
        >
          <InputFieldContainer>
            <StyledInputField
              placeholder="0.0"
              disableUnderline
            ></StyledInputField>
            <AppTokenInput.ActionButton onClick={handleClickMaxButton}>
              Max
            </AppTokenInput.ActionButton>
          </InputFieldContainer>
        </AppTokenInput.InnerContainer>
      </AppTokenInput>
      {isCheckBoxShown && (
        <AppCheckBox
          onChange={handleCheckBox}
          label={`Deposit in ${checkedTokenSymbol || ''}`}
          style={{
            margin: '12px 0',
          }}
        />
      )}
      <PoolDepositInfoBox />
      <Box display="flex" flexDirection="row" mt={1}>
        <AppButton onClick={handleModalClose} fullWidth customVariant="neutral">
          Cancel
        </AppButton>
        {isApproved ? (
          <AppButton
            onClick={() => void handleDepositToken()}
            disabled={!isApproved || exceedsBalance || hasZeroInput}
            fullWidth
          >
            Deposit
          </AppButton>
        ) : (
          <AppButton
            onClick={() => void handleClickApprove()}
            disabled={isApproved || isApproving || hasZeroInput}
            fullWidth
            customVariant="secondary"
            hasSpinner={isApproving}
          >
            {isApproving ? 'Approving' : 'Approve'}
          </AppButton>
        )}
      </Box>
      <AppTypography
        variant="caption2"
        style={{ textAlign: 'center', marginTop: '6px' }}
        transparent
      >
        In bankrun situation, LPs might only be able to withdraw in the
        over-covered tokens.
      </AppTypography>
      {/** show message when the amount exceeds balance*/}
      {exceedsBalance && <WarningMessage message="Exceeds account balance" />}
    </Modal>
  )
}

export default PoolDepositModalContainer
