import {
  nativeToWAD,
  safeWdiv,
  strToWad,
  sum,
  wmul,
} from '@hailstonelabs/big-number-utils'
import { BigNumber, constants, utils } from 'ethers'
import cloneDeep from 'lodash.clonedeep'
import React, { createContext, ReactElement, useContext } from 'react'
import { POOLS, TOKENS } from '../config/contracts'
import { poolSymbols } from '../config/contracts/pool/poolSymbol'
import {
  EMPTY_APR_DATA,
  EMPTY_LP_DATA,
  EMPTY_POOLSYMBOL_STR_BN,
} from '../constants/empty'
import useMedianBoostedApr from '../hooks/pool/useMedianBoostedApr'
import { AprCalculationDataInEachPoolType } from '../interfaces/apr'
import {
  PoolSymbolStringBigNumberType,
  PoolSymbolTokenSymbolTokenSymbolBooleanType,
  PoolSymbolTokenSymbolTokenSymbolStringType,
} from '../interfaces/common'
import {
  AprDataType,
  LpDataType,
  myTotalDepositAndEarningType,
} from '../interfaces/StakeLpContext'
import {
  getEmissionAprPercent,
  getExactBoostedAprPercent,
  getTokenEmissionWad,
} from '../utils/aprCalculation'
import { lptokenBNToToken } from '../utils/pool'
import { useBalance } from './BalanceContext'
import { useMulticallData } from './MulticallDataContext'
import { usePools } from './PoolsContext'
import { DEPRECATED_GAUGES_POOLS } from '../config/contracts/pool'

export type ContextType = {
  rewards?: PoolSymbolTokenSymbolTokenSymbolStringType
  isBonusRewardersActive?: PoolSymbolTokenSymbolTokenSymbolBooleanType
  apr: AprDataType
  lp: LpDataType
  aprCalculationDataInEachPool?: AprCalculationDataInEachPoolType
  myTotalUsd?: myTotalDepositAndEarningType
}

const initLpData: LpDataType = cloneDeep(EMPTY_LP_DATA)
const initAprData: AprDataType = cloneDeep(EMPTY_APR_DATA)

export const StakeLpDataContext = createContext<ContextType>({
  lp: initLpData,
  apr: initAprData,
} as ContextType)
StakeLpDataContext.displayName = 'StakeLpDataContext'

export const useStakeLpData = (): ContextType => useContext(StakeLpDataContext)
interface Props {
  children: React.ReactNode
}
export function StakeLpDataProvider({ children }: Props): ReactElement {
  const { liabilities, lpSuppliesBN } = usePools()
  const { stakeLpData, votingData } = useMulticallData()
  const { lpTokenAmounts, tokenPrices } = useBalance()
  const medianBoostedAprs = useMedianBoostedApr()
  const myTotalDepositAndEarning: myTotalDepositAndEarningType = {
    inTermsOfUsd: { deposit: '0', earning: '0' },
    isMoreThanZero: {
      earning: false,
    },
    earningSymbols: [],
  }
  const lpData: LpDataType = cloneDeep(EMPTY_LP_DATA)
  const aprData: AprDataType = cloneDeep(EMPTY_APR_DATA)
  let newUserFactorBNsData = cloneDeep(
    EMPTY_POOLSYMBOL_STR_BN,
  ) as PoolSymbolStringBigNumberType

  let aprCalculationDataInEachPool:
    | AprCalculationDataInEachPoolType
    | undefined = undefined

  if (stakeLpData.withAccount) {
    newUserFactorBNsData = stakeLpData.withAccount.userFactorBNs
  }

  if (stakeLpData.withoutAccount && votingData.withoutAccount) {
    const {
      withoutAccount: {
        bonus,
        poolSumOfFactorsBNs,
        nonDilutingRepartition,
        dilutingRepartition,
      },
    } = stakeLpData

    const {
      withoutAccount: { ptpPerSecWad, totalWeightWad, weightOfEachAssetWads },
    } = votingData

    aprCalculationDataInEachPool = {
      bonusTokenPerSecBNs: bonus.tokenPerSecBNs,
      bonusNonDilutingRepartitions: bonus.nonDilutingRepartitions,
      poolSumOfFactorsBNs,
      ptpPerSecWad,
      weightOfEachAssetWads,
      totalWeightWad,
      nonDilutingRepartition,
      dilutingRepartition,
      userFactorBNs: newUserFactorBNsData,
    } as AprCalculationDataInEachPoolType
  }
  const handleData = () => {
    if (!stakeLpData.withoutAccount || !votingData.withoutAccount) return
    const {
      bonus,
      poolSumOfFactorsBNs,
      nonDilutingRepartition,
      dilutingRepartition,
    } = stakeLpData.withoutAccount
    const { ptpPerSecWad, totalWeightWad, weightOfEachAssetWads } =
      votingData.withoutAccount
    for (let i = 0; i < poolSymbols.length; i++) {
      const poolSymbol = poolSymbols[i]
      const pool = POOLS[poolSymbol]
      const assets = DEPRECATED_GAUGES_POOLS.includes(poolSymbol)
        ? pool.getAssets('deprecatedMainPoolForGauageVoting')
        : pool.getAssets()
      for (let j = 0; j < assets.length; j++) {
        const assetTokenSymbol = assets[j].tokenSymbol
        const tokenDecimals = TOKENS[assetTokenSymbol].decimals
        const lpTokenAmount = lpTokenAmounts[poolSymbol][assetTokenSymbol]
        const liability = liabilities[poolSymbol][assetTokenSymbol]
        const liabilityBN = utils.parseUnits(liability, tokenDecimals)
        const lpSupplyBN = lpSuppliesBN[poolSymbol][assetTokenSymbol]
        const tokenPrice = tokenPrices[assetTokenSymbol]
        const rewards = assets[j].rewards
        // poolTvlWad is in token amount
        const poolTvlWad = strToWad(liability)
        const tokenPriceWad = strToWad(tokenPrice)
        const poolTvlUsdWad = wmul(poolTvlWad, tokenPriceWad)
        let stakedLpInTermsOfToken = '0'
        // My Deposits
        if (stakeLpData.withAccount) {
          const { stakedLpInTermsOfLpBNs, rewards: rewardsData } =
            stakeLpData.withAccount
          // my total usd earning
          const poolTotalRewardUsdWad = rewards.reduce((prev, curr) => {
            const rewardTokenSymbol = curr.tokenSymbol
            const rewardAmountWad = strToWad(
              rewardsData[poolSymbol][assetTokenSymbol]?.[rewardTokenSymbol],
            )
            if (rewardAmountWad.gt(constants.Zero)) {
              myTotalDepositAndEarning.isMoreThanZero.earning = true
            }
            const rewardAmountWadUsd = wmul(
              rewardAmountWad,
              strToWad(tokenPrices[rewardTokenSymbol]),
            )
            // earning Symbols
            if (
              rewardAmountWad.gt(constants.Zero) &&
              !myTotalDepositAndEarning.earningSymbols.includes(
                rewardTokenSymbol,
              )
            ) {
              myTotalDepositAndEarning.earningSymbols = [
                ...myTotalDepositAndEarning.earningSymbols,
                rewardTokenSymbol,
              ]
            }
            return prev.add(rewardAmountWadUsd)
          }, constants.Zero)
          myTotalDepositAndEarning.inTermsOfUsd.earning = sum(
            myTotalDepositAndEarning.inTermsOfUsd.earning,
            poolTotalRewardUsdWad,
          )
          const stakableLpInTermsOfLpBN = utils.parseUnits(
            lpTokenAmount,
            tokenDecimals,
          )
          const stakedLpInTermsOfLpBN =
            stakedLpInTermsOfLpBNs[poolSymbol][assetTokenSymbol]
          if (stakedLpInTermsOfLpBN) {
            stakedLpInTermsOfToken = lptokenBNToToken(
              stakedLpInTermsOfLpBN,
              lpSupplyBN,
              liabilityBN,
              assetTokenSymbol,
            )
          }
          lpData.inTermsOfLp.staked[poolSymbol][assetTokenSymbol] =
            utils.formatUnits(stakedLpInTermsOfLpBN, tokenDecimals)
          lpData.inTermsOfToken.staked[poolSymbol][assetTokenSymbol] =
            stakedLpInTermsOfToken
          lpData.inTermsOfLp.stakable[poolSymbol][assetTokenSymbol] =
            lpTokenAmount
          lpData.inTermsOfToken.stakable[poolSymbol][assetTokenSymbol] =
            lptokenBNToToken(
              stakableLpInTermsOfLpBN,
              lpSupplyBN,
              liabilityBN,
              assetTokenSymbol,
            )
          lpData.inTermsOfLp.total[poolSymbol][assetTokenSymbol] =
            utils.formatUnits(
              stakedLpInTermsOfLpBN.add(stakableLpInTermsOfLpBN),
              tokenDecimals,
            )
          const myTotalDepositTnTermsOfToken = lptokenBNToToken(
            stakedLpInTermsOfLpBN.add(stakableLpInTermsOfLpBN),
            lpSupplyBN,
            liabilityBN,
            assetTokenSymbol,
          )
          lpData.inTermsOfToken.total[poolSymbol][assetTokenSymbol] =
            myTotalDepositTnTermsOfToken
          // myTotalDepositUSD
          myTotalDepositAndEarning.inTermsOfUsd.deposit = sum(
            myTotalDepositAndEarning.inTermsOfUsd.deposit,
            wmul(strToWad(myTotalDepositTnTermsOfToken), tokenPriceWad),
          )
        }
        for (let k = 0; k < rewards.length; k++) {
          const reward = rewards[k]
          const rewardPrice = tokenPrices[reward.tokenSymbol]
          const rewardDecimals = TOKENS[reward.tokenSymbol].decimals
          let baseAprWad = constants.Zero
          let exactBoostedAprWad = constants.Zero
          if (reward.method === 'rewarder.pendingTokens') {
            const rewardTokenPerSecBN =
              bonus.tokenPerSecBNs[poolSymbol][assetTokenSymbol]?.[
                reward.tokenSymbol
              ]
            const rewardTokenNonDilutingRepartition =
              bonus.nonDilutingRepartitions[poolSymbol][assetTokenSymbol]?.[
                reward.tokenSymbol
              ]
            const rewardTokenDilutingRepartition =
              bonus.dilutingRepartitions[poolSymbol][assetTokenSymbol]?.[
                reward.tokenSymbol
              ]
            if (rewardTokenPerSecBN) {
              baseAprWad = getEmissionAprPercent(
                nativeToWAD(rewardTokenPerSecBN, rewardDecimals),
                BigNumber.from(1),
                BigNumber.from(1),
                pool.hasBonusBooster
                  ? BigNumber.from(rewardTokenDilutingRepartition)
                  : BigNumber.from('1000'),
                poolTvlUsdWad,
                rewardPrice,
              )
            }
            // Exact Boosted APR
            if (
              pool.hasBonusBooster &&
              stakeLpData.withAccount &&
              rewardTokenPerSecBN &&
              rewardTokenNonDilutingRepartition
            ) {
              const { userFactorBNs } = stakeLpData.withAccount
              // userTvl is only for exactBoostedApr.
              // userTvl should be placed after the update of newStakedLpInTermsOfToken.
              // Otherwise, it will always have zero because newStakedLpInTermsOfToken is always empty at the beginning.
              // userTvlWad is in token amount
              const userTvlWad = strToWad(stakedLpInTermsOfToken)
              const userTvlUsd = wmul(userTvlWad, tokenPriceWad)
              const rewardTokenDecimals = TOKENS[reward.tokenSymbol].decimals
              const rewardTokenPerSecWad = nativeToWAD(
                rewardTokenPerSecBN,
                rewardTokenDecimals,
              )
              exactBoostedAprWad = getExactBoostedAprPercent(
                rewardTokenPerSecWad,
                BigNumber.from(1),
                BigNumber.from(1),
                BigNumber.from(rewardTokenNonDilutingRepartition),
                userTvlUsd,
                rewardPrice,
                userFactorBNs[poolSymbol][assetTokenSymbol] || constants.Zero,
                poolSumOfFactorsBNs[poolSymbol][assetTokenSymbol] ||
                  constants.Zero,
              )
            }
          }
          if (reward.method === 'masterPlatypus.pendingTokens') {
            // Base APR
            const weightWad =
              weightOfEachAssetWads[poolSymbol][assetTokenSymbol] ||
              constants.Zero
            const ptpPrice = tokenPrices.PTP || '0'
            baseAprWad = getEmissionAprPercent(
              ptpPerSecWad,
              weightWad,
              totalWeightWad,
              pool.hasPtpBooster
                ? BigNumber.from(dilutingRepartition)
                : BigNumber.from('1000'),
              poolTvlUsdWad,
              ptpPrice,
            )
            aprData.ptpMonthlyEmission[poolSymbol][assetTokenSymbol] =
              utils.formatEther(
                getTokenEmissionWad(
                  votingData.withoutAccount.ptpPerSecWad,
                  weightWad,
                  totalWeightWad,
                  BigNumber.from('1000'),
                  30,
                ),
              )
            // Exact Boosted APR
            if (pool.hasPtpBooster && stakeLpData.withAccount) {
              const { userFactorBNs } = stakeLpData.withAccount
              // userTvl is only for exactBoostedApr.
              // userTvl should be placed after the update of newStakedLpInTermsOfToken.
              // Otherwise, it will always have zero because newStakedLpInTermsOfToken is always empty at the beginning.
              // userTvlWad is in token amount
              const userTvlWad = strToWad(stakedLpInTermsOfToken)
              const userTvlUsd = wmul(userTvlWad, tokenPriceWad)
              exactBoostedAprWad = getExactBoostedAprPercent(
                ptpPerSecWad,
                weightWad,
                totalWeightWad,
                BigNumber.from(nonDilutingRepartition),
                userTvlUsd,
                ptpPrice,
                userFactorBNs[poolSymbol][assetTokenSymbol] || constants.Zero,
                poolSumOfFactorsBNs[poolSymbol][assetTokenSymbol] ||
                  constants.Zero,
              )
            }
          }
          // BoostedApr
          aprData.exactBoosted.each[poolSymbol][assetTokenSymbol] = {
            ...aprData.exactBoosted.each[poolSymbol][assetTokenSymbol],
            [reward.tokenSymbol]: utils.formatEther(exactBoostedAprWad),
          }
          // sum of boostedApr
          aprData.exactBoosted.sum[poolSymbol][assetTokenSymbol] = sum(
            aprData.exactBoosted.sum[poolSymbol][assetTokenSymbol] || '0',
            exactBoostedAprWad,
          )
          // baseApr
          aprData.base.each[poolSymbol][assetTokenSymbol] = {
            ...aprData.base.each[poolSymbol][assetTokenSymbol],
            [reward.tokenSymbol]: utils.formatEther(baseAprWad),
          }
          // sum of baseApr
          aprData.base.sum[poolSymbol][assetTokenSymbol] = sum(
            aprData.base.sum[poolSymbol][assetTokenSymbol] || '0',
            baseAprWad,
          )
          // medianBoostedApr
          /** @TODO replace  medianBoostedAprsWithHardcoreValue with correct calculation */
          const medianBoostedApr =
            medianBoostedAprs[poolSymbol][assetTokenSymbol]?.[
              reward.tokenSymbol
            ]
          // If medianBoostedApr is 0 or undefined, then we calculate the averageBoostedAprWad that uses all staked amount as the base (instead of the boosted TVL)
          const medianBoostedAprWad =
            medianBoostedApr && strToWad(medianBoostedApr).gt('0')
              ? strToWad(medianBoostedApr)
              : safeWdiv(
                  wmul(baseAprWad, strToWad(nonDilutingRepartition)),
                  strToWad(dilutingRepartition),
                )
          // each of medianBoostedApr
          aprData.medianBoosted.each[poolSymbol][assetTokenSymbol] = {
            ...aprData.medianBoosted.each[poolSymbol][assetTokenSymbol],
            [reward.tokenSymbol]: utils.formatEther(medianBoostedAprWad),
          }
          // sum of medianBoostedApr
          aprData.medianBoosted.sum[poolSymbol][assetTokenSymbol] = sum(
            aprData.medianBoosted.sum[poolSymbol][assetTokenSymbol] || '0',
            medianBoostedAprWad,
          )
          // total Apr: "My Total APR" should be 0% if the user has not staked any LP token.
          const newTotalAprWad = baseAprWad.add(exactBoostedAprWad)
          aprData.total[poolSymbol][assetTokenSymbol] = sum(
            aprData.total[poolSymbol][assetTokenSymbol] || '0',
            newTotalAprWad,
          )
        }
      }
    }
  }
  try {
    handleData()
  } catch (error) {
    console.debug('StakeLpProvider', error)
  }
  return (
    <StakeLpDataContext.Provider
      value={{
        rewards: stakeLpData.withAccount?.rewards,
        isBonusRewardersActive:
          stakeLpData.withoutAccount?.bonus.isRewardersActive,
        apr: aprData,
        lp: lpData,
        aprCalculationDataInEachPool,
        myTotalUsd: myTotalDepositAndEarning,
      }}
    >
      {children}
    </StakeLpDataContext.Provider>
  )
}
