import { Box, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import { StatusType } from './PoolStakeStatusBar'

export const Status = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 4px;
    }
  `}
`

interface CapsuleProps {
  status?: StatusType
}
export const Capsule = styled(Box)<CapsuleProps>`
  ${({ theme, status }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0px 999px 999px 0px;
    border: 1px solid ${theme.palette.primary['A200']};
    padding: 0 6px 0 12px;
    transform: translateX(-10px);
    ${status === 'Booster+' &&
    css`
      border-color: ${theme.palette.secondary.main};
      color: ${theme.palette.secondary.main};
    `}
    ${status === 'Please Stake' &&
    css`
      border-color: ${theme.palette.accents.yellow};
      color: ${theme.palette.accents.yellow};
    `}
  `}
`

export const StatusText = styled(Typography)`
  ${() => css`
    white-space: nowrap;
  `}
`
interface ContainerProps {
  responsive?: boolean
}
export const Container = styled(Box)<ContainerProps>`
  ${({ theme, responsive }) => css`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: -12px;
    left: 0;
    width: 100%;
    z-index: 1;
    .PoolStakeStatus__token-icon {
      position: relative;
      z-index: 2;
    }
    ${responsive &&
    css`
      @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
        flex-direction: column;
        top: -8px;
      }
    `}
  `}
`
