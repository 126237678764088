import { Box } from '@mui/material'
import styled, { css } from 'styled-components'

export const Container = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 940px;
    margin: 0 auto;
    @media only screen and (max-width: ${theme.breakpoints.values.sm}px) {
      flex-direction: column;
      padding: 16px;
    }
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding: 12px;
    }

    @media only screen and (min-width: ${theme.breakpoints.values
        .md}px) and (max-width: ${theme.breakpoints.values.lg}px) {
      padding: 16px;
    }
    @media only screen and (min-width: ${theme.breakpoints.values.lg}px) {
      padding: 24px;
      padding-top: 40px;
    }
  `}
`

export const PoolCardList = styled(Box)`
  ${() => css`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`
