import * as _tanstack_react_query from '@tanstack/react-query'
import { ConnectArgs, FetchSignerResult, Signer } from '@wagmi/core'
import * as multicall from 'ethcall'
import { ethers } from 'ethers'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Connector,
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useSigner,
  useSwitchNetwork,
} from 'wagmi'
import {
  ChainId,
  DEFAULT_CHAIN_ID,
  Network,
  NETWORKS,
  SUPPORTED_CHAINS,
} from '../config/networks'
import { WalletId, WALLETS } from '../config/wallet'
import useLocalStorage from '../hooks/useLocalStorage'
import { HexString } from '../interfaces/common'
import { useSnackbar } from './SnackbarContext'

type ContextType = {
  chainId: ChainId
  network: Network
  isSupported: boolean
  connect: (args?: Partial<ConnectArgs> | undefined) => void
  disconnect: _tanstack_react_query.UseMutateFunction<
    void,
    Error,
    void,
    unknown
  >
  account: null | HexString
  setMockAccount: React.Dispatch<React.SetStateAction<HexString | null>>
  switchNetwork: (chainId: ChainId, onError: () => void) => Promise<void>
  activeWalletId?: WalletId
  signer: FetchSignerResult<Signer> | undefined
  readOnlyProvider: ethers.providers.JsonRpcProvider
  multicallProvider: multicall.Provider
  connector: Connector | undefined
}
const Web3Context = createContext<ContextType>({} as unknown as ContextType)
Web3Context.displayName = 'Web3Context'

interface Props {
  children: React.ReactNode
}

export const useWeb3 = () => {
  return useContext(Web3Context)
}

function Web3Provider({ children }: Props): ReactElement {
  // view as another account for development purpose
  const { showMessage } = useSnackbar()
  const [mockAccount, setMockAccount] = useState<HexString | null>(null)
  const { address: account, connector } = useAccount()
  const { chain: chainWithLogin } = useNetwork()
  // if user has not logged in, use the chainId from the local storage or default chainId
  const [{ value: cachedChainId }, setCachedChainId] = useLocalStorage<{
    value: ChainId
  }>({
    key: 'PLATYPUS_PREVIOUS_CHAIN_ID',
    initialValue: { value: DEFAULT_CHAIN_ID },
  })
  const { switchNetworkAsync } = useSwitchNetwork()
  const { connect } = useConnect({
    onError: (error) => {
      showMessage(error.message, 'warning')
    },
    onSuccess: (data) => {
      showMessage(`Connected to ${data.connector?.name || 'wallet'}.`)
    },
  })
  const { disconnect } = useDisconnect({
    onError: (error) => {
      showMessage(error.message, 'warning')
    },
  })
  const { data: signer } = useSigner()

  const switchNetwork = useCallback(
    async (chainId: ChainId, onError: () => void) => {
      if (switchNetworkAsync) {
        try {
          await switchNetworkAsync(NETWORKS[chainId].id)
        } catch (error) {
          console.log(error)
          onError()
        }
      }
      setCachedChainId({ value: chainId })
    },
    [setCachedChainId, switchNetworkAsync],
  )
  const { network, isSupported, chainId, readOnlyProvider, multicallProvider } =
    useMemo(() => {
      // if user has not logged in, use the chainId from the local storage or default chainId
      let isSupported = SUPPORTED_CHAINS.includes(cachedChainId)
      // if user has logged in, use the chainId from the RPC
      if (chainWithLogin) {
        const typedChainId = chainWithLogin.id as ChainId
        isSupported = SUPPORTED_CHAINS.includes(typedChainId)
        const network = NETWORKS[typedChainId]
        if (isSupported) {
          return {
            chainId: typedChainId,
            network,
            isSupported,
            readOnlyProvider: network.readOnlyProvider,
            multicallProvider: network.multicallProvider,
          }
        }
      }
      const network = NETWORKS[cachedChainId]
      return {
        chainId: cachedChainId,
        network,
        isSupported,
        readOnlyProvider: network.readOnlyProvider,
        multicallProvider: network.multicallProvider,
      }
    }, [chainWithLogin, cachedChainId])

  useEffect(() => {
    // if user has logged in and update chain which is supported, update the previous chainId in the local storage
    if (chainWithLogin && SUPPORTED_CHAINS.includes(chainWithLogin.id)) {
      setCachedChainId({ value: chainWithLogin.id as ChainId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainWithLogin])

  return (
    <Web3Context.Provider
      value={{
        readOnlyProvider,
        multicallProvider,
        chainId,
        network,
        isSupported,
        connect,
        disconnect,
        account:
          (process.env.REACT_APP_ENVIRONMENT !== 'production' && mockAccount) ||
          account ||
          null,
        setMockAccount,
        switchNetwork,
        activeWalletId: connector
          ? Object.values(WALLETS).find(
              (wallet) => wallet.connector === connector,
            )?.id
          : undefined,
        signer,
        connector,
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}

export default Web3Provider
