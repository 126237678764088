import {
  isParsableString,
  safeWdiv,
  strToWad,
  WAD,
  wadToNative,
} from '@hailstonelabs/big-number-utils'
import { solveForDeltaX } from '@platypus-finance/platypus-finance-utils'
import * as Sentry from '@sentry/react'
import { BigNumber, constants, utils } from 'ethers'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { router } from '../config/contracts'
import { POOLS } from '../config/contracts/pool'
import { PoolSymbol, poolSymbols } from '../config/contracts/pool/poolSymbol'
import { NATIVE_CHAIN_TOKEN, TOKENS } from '../config/contracts/token'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import { INFINITESIMAL_SWAP_FROM_AMOUNT_FOR_MARKET } from '../constants'
import { HexString } from '../interfaces/common'
import { SwapQuotationErrorType } from '../interfaces/Error'
import {
  calculateMinimumReceived,
  getSwapPotentialQuotesForMarketAndTarget,
  SwapQuoteType,
} from '../utils/pool'
import { getPriceImpactWad, getTokenAndPoolPaths } from '../utils/swap'
import {
  getAnkrEthToWetheRateWad,
  getTpAvaxToAvaxRateWad,
} from '../utils/tpAvax'
import { useBalance } from './BalanceContext'
import { usePools } from './PoolsContext'
import { useUserPreference } from './UserPreferenceContext'
import { useWeb3 } from './Web3Context'

export type ContextType = {
  // if poolSymbolForSwap is undefined swap though router
  poolSymbolForSwap?: PoolSymbol
  tokenAddressesPath: HexString[]
  tokenSymbolsPath: TokenSymbol[]
  poolAddressesPath: HexString[]
  poolSymbolsPath: PoolSymbol[]
  isRouter: boolean
  minimumReceived: BigNumber
  priceImpactWAD: BigNumber
  fromTokenAmount: string
  toTokenAmount: string
  feeWad: BigNumber
  fromTokenSymbol: TokenSymbol | null
  toTokenSymbol: TokenSymbol | null
  updateToTokenSymbol: (symbol: TokenSymbol | null) => void
  updateFromTokenSymbol: (symbol: TokenSymbol | null) => void
  switchTokensDirection: () => void
  updateToTokenAmount: (amount: string) => Promise<void>
  updateFromTokenAmount: (amount: string) => Promise<void>
  resetAllAmount: () => void
  isFromAmountExceedsUserBalance: boolean
  isUnwrappingWavaxToAvax: boolean
  isWrappingAvaxToWavax: boolean
  quotationErrorType: SwapQuotationErrorType | null
}

const initialContextValue = {
  poolSymbolForSwap: undefined,
  isRouter: false,
  tokenAddressesPath: [],
  poolAddressesPath: [],
  tokenSymbolsPath: [],
  poolSymbolsPath: [],
  minimumReceived: constants.Zero,
  priceImpactWAD: constants.Zero,
  fromTokenAmount: '',
  toTokenAmount: '',
  feeWad: constants.Zero,
  fromTokenSymbol: null,
  toTokenSymbol: null,
  updateToTokenSymbol: () => {
    return
  },
  updateFromTokenSymbol: () => {
    return
  },
  switchTokensDirection: () => {
    return
  },
  updateToTokenAmount: () => {
    return new Promise<void>((resolve) => resolve())
  },
  updateFromTokenAmount: () => {
    return new Promise<void>((resolve) => resolve())
  },
  resetAllAmount: () => {
    return
  },
  isFromAmountExceedsUserBalance: false,
  isUnwrappingWavaxToAvax: false,
  isWrappingAvaxToWavax: false,
  quotationErrorType: null,
}
const SwapContext = createContext<ContextType>(initialContextValue)
SwapContext.displayName = 'SwapContext'

export const useSwap = (): ContextType => {
  return useContext(SwapContext)
}
interface Props {
  children: React.ReactNode
}

export const SwapProvider = ({ children }: Props): ReactElement => {
  const { tokenAmounts } = useBalance()
  const { chainId, readOnlyProvider } = useWeb3()
  const { userPreference } = useUserPreference()
  const { assets, liabilities, swapData } = usePools()
  // number of times updateFromTokenAmount has been called
  const quoteCount = useRef<number>(0)
  const [fromTokenAmount, setFromTokenAmount] = useState<string>('')
  const [toTokenAmount, setToTokenAmount] = useState<string>('')
  const [feeWad, setFeeWad] = useState<BigNumber>(constants.Zero)
  const [priceImpactWAD, setPriceImpactWAD] = useState(constants.Zero)
  const [fromTokenSymbol, setFromTokenSymbol] = useState<TokenSymbol | null>(
    null,
  )
  const [toTokenSymbol, setToTokenSymbol] = useState<TokenSymbol | null>(null)
  const resetAllAmount = useCallback(() => {
    setFromTokenAmount('')
    setToTokenAmount('')
  }, [])
  const [poolSymbolForSwap, setPoolSymbolForSwap] = useState<
    PoolSymbol | undefined
  >(undefined)
  // For all swap including intra-pool swap, not include NATIVE_CHAIN_TOKEN swap, etc. AVAX -> sAVAX.
  const [isRouter, setIsRouter] = useState(false)
  const [tokenAddressesPath, setTokenAddressesPath] = useState<HexString[]>([])
  const [poolAddressesPath, setPoolAddressesPath] = useState<HexString[]>([])
  const [tokenSymbolsPath, setTokenSymbolsPath] = useState<TokenSymbol[]>([])
  const [poolSymbolsPath, setPoolSymbolsPath] = useState<PoolSymbol[]>([])
  const [quotationErrorType, setQuotationErrorType] =
    useState<SwapQuotationErrorType | null>(null)
  const isUnwrappingWavaxToAvax = useMemo(
    () =>
      fromTokenSymbol === TokenSymbol.WAVAX &&
      toTokenSymbol === TokenSymbol.AVAX,
    [fromTokenSymbol, toTokenSymbol],
  )
  const isWrappingAvaxToWavax = useMemo(
    () =>
      fromTokenSymbol === TokenSymbol.AVAX &&
      toTokenSymbol === TokenSymbol.WAVAX,
    [fromTokenSymbol, toTokenSymbol],
  )

  const updateQuotationErrorType = useCallback((errorReason: string) => {
    switch (errorReason) {
      case 'PRICE_DEV':
        setQuotationErrorType(SwapQuotationErrorType.PRICE_DEV)
        break
      case 'INSUFFICIENT_CASH':
        setQuotationErrorType(SwapQuotationErrorType.INSUFFICIENT_CASH)
        break
      case 'Pausable: paused':
        setQuotationErrorType(SwapQuotationErrorType.PAUSABLE_PAUSED)
        break
    }
  }, [])
  // Determine poolSymbolForSwap and isRouter
  useEffect(() => {
    setPoolSymbolForSwap(undefined)
    setIsRouter(false)
    setTokenAddressesPath([])
    setPoolAddressesPath([])
    setTokenSymbolsPath([])
    setPoolSymbolsPath([])
    if (fromTokenSymbol && toTokenSymbol) {
      // Check fromTokenSymbol and toTokenSymbol in same pool or not
      const isNativeTokenSwap =
        fromTokenSymbol === NATIVE_CHAIN_TOKEN[chainId] ||
        toTokenSymbol === NATIVE_CHAIN_TOKEN[chainId]
      if (isNativeTokenSwap) {
        // Check fromTokenSymbol and toTokenSymbol in same pool or not
        const newPoolSymbolForSwap = poolSymbols.find((poolSymbol) =>
          POOLS[poolSymbol].availableTokensForSwapIncludes([
            fromTokenSymbol,
            toTokenSymbol,
          ]),
        )
        setPoolSymbolForSwap(newPoolSymbolForSwap)
      } else {
        // If fromTokenSymbol and toTokenSymbol are not NATIVE_CHAIN_TOKEN, then setRouter and find paths
        setIsRouter(true)
        try {
          const result = getTokenAndPoolPaths(
            fromTokenSymbol,
            toTokenSymbol,
            chainId,
          )
          if (result) {
            const { token, pool } = result
            setTokenAddressesPath(token.address)
            setPoolAddressesPath(pool.address)
            setTokenSymbolsPath(token.symbol)
            setPoolSymbolsPath(pool.symbol)
            if (pool.symbol.length === 1) {
              setPoolSymbolForSwap(pool.symbol[0])
            }
          } else {
            setIsRouter(false)
            console.error(
              `${fromTokenSymbol} to ${toTokenSymbol} have no token and pool path for routing.`,
            )
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
  }, [chainId, fromTokenSymbol, toTokenSymbol])

  /**
   * check for conflicts: if the 2 tokens are the same, or if they are not in the same swapGroup
   * set fromTokenSymbol to null in case of conflicts
   * @param {TokenSymbol | null} symbol toToken symbol, allow null
   */
  const updateToTokenSymbol = useCallback(
    (symbol: TokenSymbol | null) => {
      setQuotationErrorType(null)
      if (!symbol) {
        setToTokenSymbol(null)
        resetAllAmount()
        return
      }
      // set From symbol to null if there's conflict
      if (fromTokenSymbol && toTokenSymbol) {
        const isDifferentAsset =
          TOKENS[symbol].swapGroupSymbol !==
          TOKENS[fromTokenSymbol].swapGroupSymbol
        const isSameToken = symbol === fromTokenSymbol
        if (isDifferentAsset || isSameToken) {
          setFromTokenSymbol(null)
        }
      }
      setToTokenSymbol(symbol)
    },
    [fromTokenSymbol, resetAllAmount, toTokenSymbol],
  )

  /**
   * check for conflicts: if the 2 tokens are the same, or if they are not in the same swapGroup
   * set toTokenSymbol to null in case of conflicts
   * @param {TokenSymbol | null} symbol fromToken symbol, allow null
   */
  const updateFromTokenSymbol = useCallback(
    (symbol: TokenSymbol | null) => {
      setQuotationErrorType(null)
      if (!symbol) {
        setFromTokenSymbol(null)
        resetAllAmount()
        return
      }
      // set To symbol to null if there's conflict
      if (fromTokenSymbol && toTokenSymbol) {
        const isDifferentAsset =
          TOKENS[symbol].swapGroupSymbol !==
          TOKENS[toTokenSymbol].swapGroupSymbol
        const isSameToken = symbol === toTokenSymbol
        if (isDifferentAsset || isSameToken) {
          setToTokenSymbol(null)
        }
      }
      setFromTokenSymbol(symbol)
    },
    [fromTokenSymbol, resetAllAmount, toTokenSymbol],
  )

  /**
   * switch toToken and fromToken symbol. No checking is needed if the current state is already valid.
   */
  const switchTokensDirection = () => {
    setFromTokenSymbol(toTokenSymbol)
    setToTokenSymbol(fromTokenSymbol)
    resetAllAmount()
  }

  const updateToTokenAmount = useCallback(
    async (amount: string) => {
      setQuotationErrorType(null)
      if (amount === '' || !fromTokenSymbol || !toTokenSymbol) {
        resetAllAmount()
        return
      }
      // Case 1. input To Amount is 0 (0.0 or 0.00, etc)
      // update To Amount accordingly. And update From Amount to ''
      if (Number(amount) === 0) {
        setToTokenAmount(amount)
        setFromTokenAmount('')
        return
      }
      // Case 2. Unwrapping WAVAX /wrapping AVAX
      // set a 1:1 rate when wrapping to/unwrapping wavax
      if (isUnwrappingWavaxToAvax || isWrappingAvaxToWavax) {
        setFromTokenAmount(amount)
        setToTokenAmount(amount)
        return
      }
      // Case 3. Swapping tokens.
      quoteCount.current += 1
      const currentCount = quoteCount.current

      setToTokenAmount(amount)
      // Calculate the To amount -> From amount quote using Newton's method
      // Newton's method is currently only available for intra-pool swap
      if (!poolSymbolForSwap) {
        return
      }
      try {
        const modifiedFromTokenSymbol =
          fromTokenSymbol === TokenSymbol.AVAX
            ? TokenSymbol.WAVAX
            : fromTokenSymbol
        const modifiedToTokenSymbol =
          toTokenSymbol === TokenSymbol.AVAX ? TokenSymbol.WAVAX : toTokenSymbol
        const assetX = assets[poolSymbolForSwap][modifiedFromTokenSymbol]
        const assetY = assets[poolSymbolForSwap][modifiedToTokenSymbol]
        const liabilityX =
          liabilities[poolSymbolForSwap][modifiedFromTokenSymbol]
        const liabilityY = liabilities[poolSymbolForSwap][modifiedToTokenSymbol]
        const tokenX = TOKENS[modifiedFromTokenSymbol]
        const tokenY = TOKENS[modifiedToTokenSymbol]

        let specifiedToAmount = strToWad(amount) // in WAD
        // round up to the smallest amount in tokenY dp
        specifiedToAmount = specifiedToAmount.add(
          BigNumber.from('10').pow(18 - tokenY.decimals),
        )

        let toTokenToFromTokenRateWad = WAD
        /** Third party avax (tpAvax): from amount calculation with newton method */
        // if using tpAvax pool, get rate from tpAvax contract
        if (POOLS[poolSymbolForSwap].isTpAvaxPool) {
          const tpAvaxToAvaxRate = await getTpAvaxToAvaxRateWad(
            poolSymbolForSwap,
            chainId,
            readOnlyProvider,
          )
          if (tpAvaxToAvaxRate) {
            // use tpAvaxToAvaxRate rate for swap: AVAX/WAVAX -> tpAVAX
            if (TOKENS[toTokenSymbol].isTpAvaxToken) {
              toTokenToFromTokenRateWad = tpAvaxToAvaxRate
            } else if (TOKENS[fromTokenSymbol].isTpAvaxToken) {
              // the inverse of the rate for the reverse direction: tpAVAX -> AVAX/WAVAX
              toTokenToFromTokenRateWad = safeWdiv(WAD, tpAvaxToAvaxRate)
            }
          }
        }
        /** @todo refactor code to make it more generic */
        const tokenConversionRateData =
          POOLS[poolSymbolForSwap].tokenConversionRateData
        if (tokenConversionRateData) {
          const tpTokenToTokenRateWad = await getAnkrEthToWetheRateWad(
            chainId,
            readOnlyProvider,
          )
          if (tpTokenToTokenRateWad) {
            if (toTokenSymbol === tokenConversionRateData.fromTokenSymbol) {
              toTokenToFromTokenRateWad = tpTokenToTokenRateWad
            } else if (
              fromTokenSymbol === tokenConversionRateData.fromTokenSymbol
            ) {
              toTokenToFromTokenRateWad = safeWdiv(WAD, tpTokenToTokenRateWad)
            }
          }
        }
        const newFromTokenAmountWad = solveForDeltaX(
          strToWad(assetX), // in WAD
          strToWad(assetY), // in WAD
          strToWad(liabilityX), // in WAD
          strToWad(liabilityY), // in WAD
          toTokenToFromTokenRateWad, // in WAD
          strToWad('1'), // in WAD
          specifiedToAmount,
          swapData[poolSymbolForSwap].haircutRateWad,
          swapData[poolSymbolForSwap].slippageParamKWad,
          swapData[poolSymbolForSwap].slippageParamNBN,
          swapData[poolSymbolForSwap].slippageParamC1BN,
          swapData[poolSymbolForSwap].slippageParamXThresholdBN,
        )

        // convert from WAD to tokenX native dp
        let newFromTokenAmountBN = newFromTokenAmountWad.div(
          BigNumber.from('10').pow(18 - tokenX.decimals),
        )
        // round up FROM token if it has less dp than TO token
        if (tokenX.decimals < tokenY.decimals) {
          newFromTokenAmountBN = newFromTokenAmountBN.add(1)
        }
        const newFromTokenAmountStr = utils.formatUnits(
          newFromTokenAmountBN,
          tokenX.decimals,
        )
        setFromTokenAmount(newFromTokenAmountStr)
        let quote: {
          target: SwapQuoteType
          market: SwapQuoteType
          errorReason?: string
        } = {
          target: null,
          market: null,
        }
        quote = await getSwapPotentialQuotesForMarketAndTarget(
          chainId,
          newFromTokenAmountStr,
          fromTokenSymbol,
          toTokenSymbol,
          {
            isRouter,
            pool:
              poolSymbolForSwap &&
              POOLS[poolSymbolForSwap].get(chainId, readOnlyProvider),
            router: router.get(chainId, readOnlyProvider),
            tokenAddressesPath,
            poolAddressesPath,
          },
        )
        if (quote.target && quote.market) {
          const { haircut } = quote.target
          const { potentialOutcome: marketPotentialOutcome } = quote.market
          const marketQuotedToTokenAmount = utils.formatUnits(
            marketPotentialOutcome,
            tokenY.decimals,
          )
          // Calculate price impact
          const newPriceImpactWAD = getPriceImpactWad(
            newFromTokenAmountStr, // target From token amount
            amount, // target To token amount
            INFINITESIMAL_SWAP_FROM_AMOUNT_FOR_MARKET, // market From token amount
            marketQuotedToTokenAmount, // market To token amount
          )
          // only update when currentCount is the latest quoteCount
          // so old quote that returned late will be ignored
          if (quoteCount.current === currentCount) {
            setFeeWad(haircut)
            setPriceImpactWAD(newPriceImpactWAD)
          }
        }
        if (quote.errorReason) {
          updateQuotationErrorType(quote.errorReason)
          setFromTokenAmount('')
        }
      } catch (err) {
        // if solveForDeltaX throws, it means the TO amount exceeds pool balance
        setQuotationErrorType(SwapQuotationErrorType.INSUFFICIENT_CASH)
        setFromTokenAmount('')
        return
      }
    },
    [
      assets,
      chainId,
      fromTokenSymbol,
      isRouter,
      isUnwrappingWavaxToAvax,
      isWrappingAvaxToWavax,
      liabilities,
      poolAddressesPath,
      poolSymbolForSwap,
      readOnlyProvider,
      resetAllAmount,
      swapData,
      toTokenSymbol,
      tokenAddressesPath,
      updateQuotationErrorType,
    ],
  )

  const updateFromTokenAmount = useCallback(
    async (amount: string) => {
      setQuotationErrorType(null)
      if (amount === '' || !fromTokenSymbol || !toTokenSymbol) {
        resetAllAmount()
        return
      }
      // Case 1. input From Amount is 0 (0.0 or 0.00, etc)
      // update From Amount accordingly. And update To Amount to ''
      if (Number(amount) === 0) {
        setFromTokenAmount(amount)
        setToTokenAmount('')
        return
      }
      // Case 2. Unwrapping WAVAX /wrapping AVAX
      // set a 1:1 rate when wrapping to/unwrapping wavax
      if (isUnwrappingWavaxToAvax || isWrappingAvaxToWavax) {
        setFromTokenAmount(amount)
        setToTokenAmount(amount)
        return
      }
      // Case 3. Swapping tokens
      // increment quoteCount everytime we get a quote from SC
      quoteCount.current += 1
      const currentCount = quoteCount.current

      setFromTokenAmount(amount)
      // quote the To amount
      const quote = await getSwapPotentialQuotesForMarketAndTarget(
        chainId,
        amount,
        fromTokenSymbol,
        toTokenSymbol,
        {
          isRouter,
          pool:
            poolSymbolForSwap &&
            POOLS[poolSymbolForSwap].get(chainId, readOnlyProvider),
          router: router.get(chainId, readOnlyProvider),
          tokenAddressesPath,
          poolAddressesPath,
        },
      )

      // if quote is successfull
      if (quote.target && quote.market) {
        const toTokenInstance = TOKENS[toTokenSymbol]
        const { potentialOutcome: targetPotentialOutcome, haircut } =
          quote.target
        const targetQuotedToTokenAmount = utils.formatUnits(
          targetPotentialOutcome,
          toTokenInstance.decimals,
        )
        const { potentialOutcome: marketPotentialOutcome } = quote.market
        const marketQuotedToTokenAmount = utils.formatUnits(
          marketPotentialOutcome,
          toTokenInstance.decimals,
        )
        // Calculate price impact
        const newPriceImpactWAD = getPriceImpactWad(
          amount, // target From token amount
          targetQuotedToTokenAmount, // target To token amount
          INFINITESIMAL_SWAP_FROM_AMOUNT_FOR_MARKET, // market From token amount
          marketQuotedToTokenAmount, // market To token amount
        )
        // only update when currentCount is the latest quoteCount
        // so old quote that returned late will be ignored
        if (quoteCount.current === currentCount) {
          setToTokenAmount(targetQuotedToTokenAmount)
          setFeeWad(haircut)
          setPriceImpactWAD(newPriceImpactWAD)
        }
      } else {
        // if quote fails because pool has insufficient To token or other reasons
        if (quoteCount.current === currentCount) {
          if (quote.errorReason) {
            updateQuotationErrorType(quote.errorReason)
          }
          setToTokenAmount('')
        }
      }
    },
    [
      fromTokenSymbol,
      toTokenSymbol,
      isUnwrappingWavaxToAvax,
      isWrappingAvaxToWavax,
      chainId,
      isRouter,
      poolSymbolForSwap,
      readOnlyProvider,
      tokenAddressesPath,
      poolAddressesPath,
      resetAllAmount,
      updateQuotationErrorType,
    ],
  )

  // Calculate isFromAmountExceedsUserBalance
  // whether from amount is greater than user balance
  const isFromAmountExceedsUserBalance = useMemo(() => {
    let computedIsFromAmountExceedsUserBalance = false
    if (fromTokenSymbol) {
      const userAmount = tokenAmounts[fromTokenSymbol]
      const token = TOKENS[fromTokenSymbol]
      if (
        isParsableString(fromTokenAmount, token.decimals, true) &&
        isParsableString(userAmount, token.decimals, true)
      ) {
        if (strToWad(fromTokenAmount).gt(strToWad(userAmount))) {
          computedIsFromAmountExceedsUserBalance = true
        }
      }
    }
    return computedIsFromAmountExceedsUserBalance
  }, [fromTokenAmount, fromTokenSymbol, tokenAmounts])

  // Calculate minimumReceived = toTokenAmount / (1 + slippage %)
  const minimumReceived = useMemo(() => {
    // do not calculate when wrapping to/unwrapping wavax
    if (isUnwrappingWavaxToAvax || isWrappingAvaxToWavax) {
      return constants.Zero
    }
    let computedMinimumReceived = constants.Zero
    try {
      // catch the case if values are not parsable
      if (toTokenSymbol && toTokenAmount)
        computedMinimumReceived = wadToNative(
          calculateMinimumReceived(
            strToWad(toTokenAmount),
            userPreference.slippage.swap,
          ),
          TOKENS[toTokenSymbol].decimals,
        )
    } catch (err) {
      Sentry.setContext('event', {
        name: 'calculate_swap_min_received',
        to_token_symbol: toTokenSymbol,
        from_token_symbol: fromTokenSymbol,
        to_token_amount: fromTokenAmount,
        from_token_amount: toTokenAmount,
      })
      Sentry.captureException(err)
      console.error(err)
    }
    return computedMinimumReceived
  }, [
    fromTokenAmount,
    fromTokenSymbol,
    toTokenAmount,
    toTokenSymbol,
    userPreference.slippage.swap,
    isWrappingAvaxToWavax,
    isUnwrappingWavaxToAvax,
  ])

  return (
    <SwapContext.Provider
      value={{
        poolSymbolForSwap,
        tokenAddressesPath,
        poolAddressesPath,
        tokenSymbolsPath,
        poolSymbolsPath,
        isRouter,
        minimumReceived,
        priceImpactWAD,
        fromTokenAmount,
        toTokenAmount,
        feeWad,
        fromTokenSymbol,
        toTokenSymbol,
        updateFromTokenSymbol,
        updateToTokenSymbol,
        switchTokensDirection,
        updateFromTokenAmount,
        updateToTokenAmount,
        resetAllAmount,
        isFromAmountExceedsUserBalance,
        isUnwrappingWavaxToAvax,
        isWrappingAvaxToWavax,
        quotationErrorType,
      }}
    >
      {children}
    </SwapContext.Provider>
  )
}
