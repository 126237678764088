import * as multicall from 'ethcall'
import { ethers } from 'ethers'
import React, { createContext, useContext, useState } from 'react'
import { ChainId, Network, NETWORKS } from '../config/networks'
import { useWeb3 } from './Web3Context'

const isInProductionEnv = process.env.REACT_APP_ENVIRONMENT === 'production'
/**
 * @return {ContextType} user selected network instance and network isSupported or not
 */
export type ContextType = {
  chainId: ChainId
  network: Network
  // true / false indicates whether the network is supported while undefined indicates it is not known yet.
  // null -> at the beginning
  // true -> supported
  // false -> unsupported
  isSupported: boolean | null
  readOnlyProvider: ethers.providers.JsonRpcProvider
  multicallProvider: multicall.Provider
  account: string | null
  setMockAccount: React.Dispatch<React.SetStateAction<string | null>>
}
const defaultChainId = isInProductionEnv ? ChainId.AVALANCHE : ChainId.FUJI
const initNetworkProps = {
  chainId: defaultChainId,
  network: NETWORKS[defaultChainId],
  isSupported: null,
  readOnlyProvider: NETWORKS[defaultChainId].readOnlyProvider,
  multicallProvider: NETWORKS[defaultChainId].multicallProvider,
  account: null,
} as ContextType

export const NetworkContext = createContext<ContextType>(initNetworkProps)
NetworkContext.displayName = 'NetworkContext'
export const useNetwork = (): ContextType => useContext(NetworkContext)
interface Props {
  children: React.ReactNode
}

export const NetworkProvider = ({ children }: Props): React.ReactElement => {
  // view as another account for development purpose
  const [mockAccount, setMockAccount] = useState<string | null>(null)
  // create network info and set info function
  const { chainId, account, isSupported } = useWeb3()
  // default network is AVALANCHE mainnet

  const multicallProvider = NETWORKS[chainId].multicallProvider
  const readOnlyProvider = NETWORKS[chainId].readOnlyProvider
  const network: Network = NETWORKS[chainId]

  return (
    <NetworkContext.Provider
      value={{
        chainId,
        network,
        isSupported,
        readOnlyProvider,
        multicallProvider,
        account: (!isInProductionEnv && mockAccount) || account,
        setMockAccount,
      }}
    >
      {children}
    </NetworkContext.Provider>
  )
}
