import React, { ReactElement, useMemo } from 'react'
import NftCard from '../../components/cards/NftCard/NftCard'
import nftUIData from '../../config/nftUIData'
import { useVePtp } from '../../contexts/VePtpContext'

interface Props {
  id: string | undefined | null
  variant?: 'small'
  disableActiveColor?: boolean
  disableActiveText?: boolean
  enableBgColor?: boolean
  padding?: string
}

function MemorizedNftCardContainer({
  id,
  variant,
  disableActiveColor,
  disableActiveText,
  enableBgColor,
  padding,
}: Props): ReactElement {
  const { nft } = useVePtp()
  // some modals may need to display memorizedEquippedNftData regardless of the change in nft.equippedId
  const nftData = useMemo(() => {
    return id ? nft.all[id] : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!nftData) return <></>
  return (
    <NftCard
      id={id}
      variant={variant}
      name={nftData.type}
      isEquipped
      nftImgSrc={nftData.imgSrc}
      abililty={nftUIData[nftData.type].getAbilityText({
        value: nftData.value,
        showActiveColor: !disableActiveColor,
        showActiveText: !disableActiveText,
      })}
      disableActiveColor={disableActiveColor}
      enableBgColor={enableBgColor}
      padding={padding}
    />
  )
}

export default MemorizedNftCardContainer
