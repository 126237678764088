import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { alpha, Box, useTheme } from '@mui/material'
import React from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import Modal from '../../../components/Modal/Modal'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import AppTokenInput from '../../../components/TokenInput/AppTokenInput'
import WarningMessage from '../../../components/WarningMessage/WarningMessage'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { useVePtpInput } from '../../../contexts/VePtpInputContext'
import { ScrollableBox } from '../../../globalStyles'
import useApprove from '../../../hooks/useApprove'
import useLockPtp from '../../../hooks/vePtp/locking/useLockPtp'
import useVeptpToReceive from '../../../hooks/vePtp/locking/useVeptpToReceive'
import { ApproveSpender } from '../../../interfaces/spender'
import LockPtpInfoBox from './LockPtpInfoBox'

interface ModalProps {
  isOpen: boolean
}

/**@todo replace with contract data */
function LockPtpModalContainer({ isOpen }: ModalProps) {
  const theme = useTheme()
  const {
    lockDayInputAmount,
    lockPtpInputAmount,
    actions: {
      lockDayInputAmountOnChange,
      lockPtpInputAmountOnChange,
      resetLockDayAndLockPtpAmount,
    },
  } = useVePtpInput()
  const { ptp } = useVePtp()
  const ptpBalance = ptp.balance
  const hasLockedPtp = ptp.hasLocked
  const lockedPtp = ptp.amount.locked
  const { toReceiveGtMaxCap } = useVeptpToReceive({
    lockDayInputAmount,
    lockPtpInputAmount,
  })

  const { handleClickApprove, isApproving, isApproved } = useApprove(
    TokenSymbol.PTP,
    false,
    ApproveSpender.VePtp,
    lockPtpInputAmount,
  )
  const { handleLockPtp, isWaiting: isWaitingToLockPtp } = useLockPtp()
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()

  const ltMinLockDay = Number(lockDayInputAmount) < ptp.lockTime.minDays

  const gtPtpBalance = strToWad(lockPtpInputAmount).gt(strToWad(ptpBalance))

  const hasZeroLockPtpInput = strToWad(lockPtpInputAmount).isZero()

  const hasZeroLockDayInput = strToWad(lockDayInputAmount).isZero()

  const handleModalClose = () => {
    modalDispatch(closeModal())
    resetLockDayAndLockPtpAmount()
  }

  const handleClickMaxLockPtpButton = () => {
    lockPtpInputAmountOnChange(ptpBalance)
    return
  }
  const handleClickMaxLockDayButton = () => {
    lockDayInputAmountOnChange(ptp.lockTime.maxDays.toString())
    return
  }

  const renderWarningMessage = (isMessageShown: boolean, message: string) => {
    if (!isMessageShown) return null
    return (
      <WarningMessage style={{ border: 'none' }} iconSize="14px" margin="sm">
        <AppTypography variant="body2" component="span">
          {message}
        </AppTypography>
      </WarningMessage>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      TopBarLabelComponent={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <TokenIcon margin="0 12px" tokenSymbol={TokenSymbol.PTP} size={22} />
          <AppTypography variant="h6">
            {!hasLockedPtp
              ? `Lock ${TokenSymbol.PTP}`
              : `Lock more ${TokenSymbol.PTP}`}
          </AppTypography>
        </Box>
      }
      width="650px"
    >
      <ScrollableBox>
        {/**PTP Input Amount */}
        <AppTokenInput
          onChange={lockPtpInputAmountOnChange}
          value={lockPtpInputAmount}
          style={{ padding: '0', marginTop: '8px' }}
        >
          <AppTokenInput.LabelItem
            leadingLabel={
              hasLockedPtp ? (
                <AppTokenInput.LabelItemInnerContainer
                  style={{ marginBottom: '8px' }}
                >
                  <AppTokenInput.Label>Locked:&nbsp;</AppTokenInput.Label>
                  <Box display="flex" alignItems="center">
                    <TooltipNum
                      rightSymbol={TokenSymbol.PTP}
                      amount={lockedPtp}
                    >
                      {getCommifiedFormat(lockedPtp, 2)} {TokenSymbol.PTP}
                    </TooltipNum>
                  </Box>
                </AppTokenInput.LabelItemInnerContainer>
              ) : (
                <></>
              )
            }
            trailingLabel={
              <AppTokenInput.LabelItemInnerContainer
                style={{ marginBottom: '8px' }}
              >
                <AppTokenInput.Label>Balance:&nbsp;</AppTokenInput.Label>
                <Box display="flex" alignItems="center">
                  <TooltipNum rightSymbol={TokenSymbol.PTP} amount={ptpBalance}>
                    {getCommifiedFormat(ptpBalance, 2)} {TokenSymbol.PTP}
                  </TooltipNum>
                </Box>
              </AppTokenInput.LabelItemInnerContainer>
            }
          />
          <AppTokenInput.InnerContainer
            bgcolor={alpha(theme.palette.primary[500], 0.2)}
            flexDirection="column"
            marginBottom="8px"
          >
            <Box display="flex" flexDirection="row">
              <AppTokenInput.InputField
                placeholder="0.0"
                disableUnderline
                textalign="left"
                noLeftPadding
                inputUnitLabel={TokenSymbol.PTP}
              />
              <AppTokenInput.ActionButton onClick={handleClickMaxLockPtpButton}>
                Max
              </AppTokenInput.ActionButton>
            </Box>
          </AppTokenInput.InnerContainer>
        </AppTokenInput>
        {/**Lock Day Input Amount */}
        {!hasLockedPtp && (
          <AppTokenInput
            onChange={lockDayInputAmountOnChange}
            value={lockDayInputAmount}
            sliderValue={lockDayInputAmount}
            onSliderChange={lockDayInputAmountOnChange}
            style={{ padding: '0', marginTop: '8px' }}
          >
            <AppTokenInput.InnerContainer
              bgcolor={alpha(theme.palette.primary[500], 0.2)}
              flexDirection="column"
            >
              <Box display="flex" flexDirection="row">
                <AppTokenInput.InputField
                  placeholder="0.0"
                  inputUnitLabel="days"
                  disableUnderline
                  textalign="left"
                  noLeftPadding
                />
                <AppTokenInput.ActionButton
                  onClick={handleClickMaxLockDayButton}
                >
                  Max
                </AppTokenInput.ActionButton>
              </Box>
              <AppTokenInput.Slider
                max={ptp.lockTime.maxDays}
                min={ptp.lockTime.minDays}
                sliderLabelType="day"
              />
            </AppTokenInput.InnerContainer>
          </AppTokenInput>
        )}
        {renderWarningMessage(
          gtPtpBalance,
          `${TokenSymbol.PTP} amount exceeds account balance`,
        )}
        {renderWarningMessage(
          toReceiveGtMaxCap,
          `${TokenSymbol.VEPTP} to receive exceeds max cap to earn`,
        )}
        {renderWarningMessage(
          ltMinLockDay && !hasZeroLockDayInput,
          `Your lock days are below min lock days (${ptp.lockTime.minDays} days).`,
        )}
        <LockPtpInfoBox />
        <Box display="flex">
          <AppButton
            onClick={handleModalClose}
            fullWidth
            customVariant="neutral"
          >
            Cancel
          </AppButton>
          {isApproved ? (
            <AppButton
              fullWidth
              disabled={
                hasZeroLockPtpInput ||
                hasZeroLockDayInput ||
                ltMinLockDay ||
                toReceiveGtMaxCap ||
                gtPtpBalance ||
                isWaitingToLockPtp
              }
              onClick={() => {
                // if users has already locked PTP, they are trying to lock more PTP.
                void handleLockPtp(
                  lockPtpInputAmount,
                  hasLockedPtp ? undefined : lockDayInputAmount,
                )
              }}
              hasSpinner={isWaitingToLockPtp}
            >
              Lock
            </AppButton>
          ) : (
            <AppButton
              customVariant="secondary"
              fullWidth
              onClick={() => void handleClickApprove()}
              disabled={
                hasZeroLockPtpInput ||
                hasZeroLockDayInput ||
                ltMinLockDay ||
                toReceiveGtMaxCap ||
                gtPtpBalance ||
                isApproving
              }
              hasSpinner={isApproving}
            >
              {isApproving ? 'Approving' : 'Approve'}
            </AppButton>
          )}
        </Box>
      </ScrollableBox>
    </Modal>
  )
}

export default LockPtpModalContainer
