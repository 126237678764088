import { CssBaseline } from '@mui/material'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { ThemeProvider } from 'styled-components'
import { WagmiConfig } from 'wagmi'
import AppRoutes from './AppRoutes'
import ErrorBoundary from './components/ErrorBoundary'
import theme from './config/theme'
import { wagmiClient } from './config/web3/wagmi'
import { BalanceProvider } from './contexts/BalanceContext'
import ErrorModalProvider from './contexts/ErrorModalContext'
import { ModalProvider } from './contexts/ModalContext'
import { MulticallDataProvider } from './contexts/MulticallDataContext'
import { NetworkProvider } from './contexts/NetworkContext'
import PoolInputProvider from './contexts/PoolInputContext'
import { PoolsProvider } from './contexts/PoolsContext'
import { SnackbarProvider } from './contexts/SnackbarContext'
import { TpYieldProvider } from './contexts/TpYieldContext'
import UserPreferenceProvider from './contexts/UserPreferenceContext'
import Web3Provider from './contexts/Web3Context'

function App(): React.ReactElement {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALTICS_ID as string, {
      testMode: process.env.REACT_APP_ENVIRONMENT !== 'production',
    })
  }, [])
  return (
    <WagmiConfig client={wagmiClient}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {/* use ThemeProvider from styled-components to access theme as style components from Material-ui are not working properly  */}
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <Web3Provider>
              <ErrorModalProvider>
                <NetworkProvider>
                  <MulticallDataProvider>
                    <ModalProvider>
                      <UserPreferenceProvider>
                        <BalanceProvider>
                          <TpYieldProvider>
                            <PoolInputProvider>
                              <PoolsProvider>
                                <AppRoutes />
                              </PoolsProvider>
                            </PoolInputProvider>
                          </TpYieldProvider>
                        </BalanceProvider>
                      </UserPreferenceProvider>
                    </ModalProvider>
                  </MulticallDataProvider>
                </NetworkProvider>
              </ErrorModalProvider>
            </Web3Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </WagmiConfig>
  )
}

export interface SentryFallbackParams {
  error: Error
  componentStack: string | null
  resetError: () => void
}
const fallback = ({
  error,
  componentStack,
  resetError,
}: SentryFallbackParams) => (
  <ErrorBoundary
    error={error}
    componentStack={componentStack}
    resetError={resetError}
  />
)
export default Sentry.withErrorBoundary(App, {
  fallback: fallback,
})
