import { alpha, Box, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import AppButton from '../../../components/buttons/AppButton/AppButton'

interface StakeAccordionDetailsContainerProps {
  active?: boolean
}

export const StakeAccordionContentContainer = styled(
  Box,
)<StakeAccordionDetailsContainerProps>`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 12px 60px;

    @media only screen and (max-width: ${theme.breakpoints.values.lg}px) {
      padding: 12px 20px;
    }
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      align-items: center;
    }
  `}
`

const TAB_HEIGHT = 24
const TAB_MARGIN = 2
const NUM_OF_TABS = 3
const MOBILE_TAB_WIDTH_PERCENTAGE = `${100 / NUM_OF_TABS}%`
const getTabLinePosition = (
  tabIndex: number,
  device?: 'mobile',
): number | string => {
  if (device === 'mobile')
    return `calc(${MOBILE_TAB_WIDTH_PERCENTAGE} * ${tabIndex})`
  return (TAB_HEIGHT + TAB_MARGIN * 2) * tabIndex
}

export const Animation = styled(Box)`
  ${({ theme }) => css`
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 2px;
      right: 0;
      top: 0;
      border-radius: 999px;
      transition: top 0.4s ease 0s;
    }
    &::before {
      height: ${NUM_OF_TABS * (TAB_HEIGHT + TAB_MARGIN * 2)}px;
      background-color: ${theme.palette.common.white};
      opacity: 0.3;
    }
    &::after {
      height: ${TAB_HEIGHT + TAB_MARGIN * 2}px;
      background-color: ${theme.palette.primary['A200']};
      top: ${getTabLinePosition(1)}px;
    }

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 2px;
        left: 0;
        right: unset;
        top: unset;
        bottom: 0;
        border-radius: 999px;
        transition: left 0.4s;
      }

      &::before {
        width: 100%;
      }
      &::after {
        width: ${MOBILE_TAB_WIDTH_PERCENTAGE};
        top: unset;
      }
    }
  `}
`

export const PoolStakeTab = styled(Typography).attrs(() => ({
  variant: 'caption2',
}))`
  ${({ theme }) => css`
    display: inline-flex;
    height: ${TAB_HEIGHT}px;
    margin: ${TAB_MARGIN}px;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    cursor: pointer;
    &.active {
      opacity: 1;
    }

    &:first-of-type.active ~ ${Animation}::after {
      top: 0;
    }
    &:nth-of-type(2).active ~ ${Animation}::after {
      top: ${getTabLinePosition(1)}px;
    }
    &:nth-of-type(3).active ~ ${Animation}::after {
      top: ${getTabLinePosition(2)}px;
    }

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: ${MOBILE_TAB_WIDTH_PERCENTAGE};

      &:first-of-type.active ~ ${Animation}::after {
        top: unset;
      }
      &:nth-of-type(2).active ~ ${Animation}::after {
        top: unset;
        left: ${getTabLinePosition(1, 'mobile')};
      }
      &:nth-of-type(3).active ~ ${Animation}::after {
        top: unset;
        left: ${getTabLinePosition(2, 'mobile')};
      }
    }
  `}
`

export const PoolStakeTabBarContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 80px;
    position: relative;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: row;
      width: 100%;
    }
  `}
`

export const ContentContainer = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 8px;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      margin-left: 0;
    }
  `}
`
export const Content = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    height: 100%;
    background-color: ${alpha(theme.palette.primary[500], 0.2)};
    border-radius: ${theme.borderRadius.sm};
    margin: 0 12px;
    flex: 0.45;

    @media only screen and (max-width: ${theme.breakpoints.values.lg}px) {
      flex: 0.5;
    }

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      flex: unset;
      width: 100%;
      margin: 8px 0;
    }
  `}
`

export const Item = styled(Box)`
  ${() => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `}
`

interface TextProps {
  $colored?: boolean
  $highlight?: boolean
  $alignItemsCenter?: boolean
}
export const Text = styled(Typography).attrs((props: TextProps) => ({
  variant: props.$highlight ? 'h5' : 'caption2',
}))<TextProps>`
  ${({ theme, $colored, $highlight, $alignItemsCenter }) => css`
    ${$alignItemsCenter &&
    css`
      display: inline-flex;
      align-items: center;
    `}
    ${$colored &&
    css`
      color: ${theme.palette.primary['A200']};
    `}

    ${$highlight &&
    css`
      line-height: 1;
      display: inline;
    `}
  `}
`
export const ButtonContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0.55;

    .info-message {
      opacity: 0.5;
      text-align: center;
    }
    @media only screen and (max-width: ${theme.breakpoints.values.lg}px) {
      flex: 0.5;
    }

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex: unset;
      width: 100%;
    }
  `}
`
export const StyledAppButton = styled(AppButton)`
  ${({ theme }) => css`
    border-radius: 999px;
    width: 75%;

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 100%;
    }
  `}
`
