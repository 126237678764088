import { MenuProps } from '@mui/material'
import { MenuItemProps } from 'material-ui'
import React, { ReactElement } from 'react'
import { Container, StyledMenuItem } from './AppMenu.element'

function AppMenu({
  className,
  children,
  anchorEl,
  open,
  onClose,
  disableScrollLock,
  ...otherProps
}: MenuProps): ReactElement {
  return (
    <Container
      disableScrollLock={disableScrollLock}
      className={className}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...otherProps}
    >
      {children}
    </Container>
  )
}

export default AppMenu

function AppMenuItem({ children, ...otherProps }: MenuItemProps) {
  return <StyledMenuItem {...otherProps}>{children}</StyledMenuItem>
}

AppMenu.MenuItem = AppMenuItem
