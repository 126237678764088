import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import { SeverityType } from '../../../contexts/SnackbarContext'

interface ContainerProps {
  severity: SeverityType
  variant?: 'filled' | 'outlined'
}
export const Container = styled(Box)<ContainerProps>`
  ${({ theme, severity, variant }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    border-radius: ${theme.borderRadius.sm};
    ${severity === 'success' &&
    css`
      background-color: ${theme.palette.success.main};
    `}
    ${severity === 'warning' &&
    css`
      background-color: ${variant === 'filled'
        ? theme.palette.error.main
        : theme.palette.common.black};
      color: ${theme.palette.error.main};
      border: 1px solid ${theme.palette.error.main};
    `}
    ${variant === 'filled' &&
    css`
      color: ${theme.palette.common.white};
    `}

    svg {
      margin-right: 10px;
    }
  `}
`
