import { useEffect, useState } from 'react'
import { getLockTimeLeftPercentageAndDayLeft } from '../../../utils/locking'

type Props = {
  unlockTimeInMilliseconds: number
  initialLockTimeInMilliseconds: number
}

function useLockTimeLeft({
  unlockTimeInMilliseconds,
  initialLockTimeInMilliseconds,
}: Props) {
  const initTimeLeftPercentageAnyDayLeft = getLockTimeLeftPercentageAndDayLeft(
    unlockTimeInMilliseconds,
    initialLockTimeInMilliseconds,
  )
  const [percentageLeft, setPercentageLeft] = useState<number>(
    initTimeLeftPercentageAnyDayLeft.timeLeftPercentage,
  )
  const [dayLeft, setDayLeft] = useState<number>(
    initTimeLeftPercentageAnyDayLeft.dayLeft,
  )

  useEffect(() => {
    const setTime = () => {
      const timeLeftPercentageAnyDayLeft = getLockTimeLeftPercentageAndDayLeft(
        unlockTimeInMilliseconds,
        initialLockTimeInMilliseconds,
      )

      setPercentageLeft(timeLeftPercentageAnyDayLeft.timeLeftPercentage)
      setDayLeft(timeLeftPercentageAnyDayLeft.dayLeft)
    }
    setTime()

    const interval = setInterval(setTime, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [initialLockTimeInMilliseconds, unlockTimeInMilliseconds])

  return { percentageLeft, dayLeft }
}

export default useLockTimeLeft
