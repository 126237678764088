import {
  getDpFormat,
  getPercentageFromTwoWAD,
  safeWdiv,
  strToWad,
  wmul,
} from '@hailstonelabs/big-number-utils'
import { BigNumber, utils } from 'ethers'
import cloneDeep from 'lodash.clonedeep'
import React, { createContext, ReactElement, useContext } from 'react'
import { PoolSymbol, poolSymbols } from '../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import { ChainId } from '../config/networks'
import {
  EMPTY_POOLSYMBOL_STR_BN,
  EMPTY_POOLSYMBOL_STR_STR,
} from '../constants/empty'
import {
  PoolSymbolStringStringType,
  TokenSymbolStringType,
} from '../interfaces/common'
import {
  EarnedBribeRewardType,
  getAnnualBribeIncentivesWad,
  getAprOfEachBribe,
  getBalanceOfEachBribe,
  getBribeIncentivesPerUserVotesPerNDays,
  getEarnedBribeRewards,
} from '../utils/voting'
import { useBalance } from './BalanceContext'
import { useMulticallData } from './MulticallDataContext'
import { useNetwork } from './NetworkContext'
import { useVePtp } from './VePtpContext'

export interface ContextType {
  total: {
    currentVote: string
    votedPercentage: string
    voteWeightOfEachAsset: {
      vePtp: PoolSymbolStringStringType
      percentage: PoolSymbolStringStringType
    }
    bribeBalanceOfEachAsset: {
      [id in PoolSymbol]?: {
        [id in TokenSymbol]?: { inToken: string }
      }
    }
  }
  weeklyBribeIncentives: {
    perVotedVeptp: {
      [id in PoolSymbol]?: {
        [id in TokenSymbol]?: { inToken: string; inUsd: string }
      }
    }
    per100kVeptp: {
      [id in PoolSymbol]?: {
        [id in TokenSymbol]?: { inToken: string; inUsd: string }
      }
    }
    perMaxVeptp: {
      [id in PoolSymbol]?: {
        [id in TokenSymbol]?: { inToken: string; inUsd: string }
      }
    }
  }
  bribeAprData: {
    user: {
      max: { [id in PoolSymbol]?: Partial<TokenSymbolStringType> }
    }
    average: {
      [id in PoolSymbol]?: Partial<TokenSymbolStringType>
    }
    annual: {
      percentage: string
    }
  }
  user: {
    remainingVote: {
      vePtp: string
      percentage: string
    }
    usedVote: {
      vePtp: string
      percentage: string
    }
    voteWeightOfEachAsset: {
      vePtp: PoolSymbolStringStringType
      percentage: PoolSymbolStringStringType
    }
    earnedBribeOfEachAsset: PoolSymbolStringStringType
    earnedBribeRewards: EarnedBribeRewardType[]
    hasEarnedBribeRewards: boolean
    earnedBribeRewardsTotalInUsd: string
  }
}

export const initialVotingDataContext = {
  total: {
    currentVote: '0.0',
    votedPercentage: '0.0',
    voteWeightOfEachAsset: {
      vePtp: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
      percentage: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
    },
    bribeBalanceOfEachAsset: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
  },
  weeklyBribeIncentives: {
    per100kVeptp: {},
    perMaxVeptp: {},
    perVotedVeptp: {},
  },
  bribeAprData: {
    user: { max: {} },
    average: {},
    annual: { percentage: '0.0' },
  },
  user: {
    remainingVote: {
      vePtp: '0.0',
      percentage: '0.0',
    },
    usedVote: {
      vePtp: '0.0',
      percentage: '0.0',
    },
    voteWeightOfEachAsset: {
      vePtp: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
      percentage: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
    },
    earnedBribeOfEachAsset: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
    earnedBribeRewards: getEarnedBribeRewards(
      cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
      ChainId.FUJI,
      cloneDeep(EMPTY_POOLSYMBOL_STR_BN),
    ),
    hasEarnedBribeRewards: false,
    earnedBribeRewardsTotalInUsd: '0',
  },
} as ContextType

export const VotingDataContext = createContext<ContextType>(
  initialVotingDataContext,
)
VotingDataContext.displayName = 'VotingDataContext'

export const useVotingData = (): ContextType => {
  return useContext(VotingDataContext)
}

interface Props {
  children: React.ReactNode
}

export function VotingDataProvider({ children }: Props): ReactElement {
  const {
    votingData: { withoutAccount, withAccount },
  } = useMulticallData()
  const { chainId } = useNetwork()
  const { vePtp, ptp } = useVePtp()
  const { tokenPrices } = useBalance()
  let newTotalData = initialVotingDataContext.total
  let newUserData = initialVotingDataContext.user
  const newWeeklyBribeIncentivesData =
    initialVotingDataContext.weeklyBribeIncentives
  const votingPowerWad = strToWad(vePtp.balance.total.current)
  const newBribeAprData = initialVotingDataContext.bribeAprData

  if (withoutAccount) {
    const totalVoteWeightOfEachAssetInVeptp = cloneDeep(
      EMPTY_POOLSYMBOL_STR_STR,
    )
    const totalVoteWeightOfEachAssetInPercentage = cloneDeep(
      EMPTY_POOLSYMBOL_STR_STR,
    )

    for (let i = 0; i < poolSymbols.length; i++) {
      const poolSymbol = poolSymbols[i]
      const voteInAssets = withoutAccount.weightOfEachAssetWads[poolSymbol]

      const assetTokenSymbols = Object.keys(voteInAssets)
      for (let j = 0; j < assetTokenSymbols.length; j++) {
        const assetTokenSymbol = assetTokenSymbols[j]
        const targetTotalWeightWad =
          withoutAccount.weightOfEachAssetWads[poolSymbol][assetTokenSymbol]
        totalVoteWeightOfEachAssetInVeptp[poolSymbol][assetTokenSymbol] =
          utils.formatEther(targetTotalWeightWad)
        totalVoteWeightOfEachAssetInPercentage[poolSymbol][assetTokenSymbol] =
          getPercentageFromTwoWAD(
            targetTotalWeightWad,
            withoutAccount.totalWeightWad,
          )
      }
    }

    newTotalData = {
      currentVote: utils.formatEther(withoutAccount.totalWeightWad),
      votedPercentage: getPercentageFromTwoWAD(
        withoutAccount.totalWeightWad,
        strToWad(vePtp.totalSupply),
      ),
      voteWeightOfEachAsset: {
        vePtp: totalVoteWeightOfEachAssetInVeptp,
        percentage: totalVoteWeightOfEachAssetInPercentage,
      },
      bribeBalanceOfEachAsset: getBalanceOfEachBribe(
        withoutAccount.bribeBalanceOfEachAssetBN,
      ),
    }
  }
  if (withAccount && withoutAccount) {
    const userVoteWeightOfEachAssetInVeptp = cloneDeep(EMPTY_POOLSYMBOL_STR_STR)
    const userVoteWeightOfEachAssetInPercentage = cloneDeep(
      EMPTY_POOLSYMBOL_STR_STR,
    )
    for (let i = 0; i < poolSymbols.length; i++) {
      const poolSymbol = poolSymbols[i]
      const voteInAssets = withAccount.voteOfEachAssetWads[poolSymbol]
      const assetTokenSymbols = Object.keys(voteInAssets)
      for (let j = 0; j < assetTokenSymbols.length; j++) {
        const assetTokenSymbol = assetTokenSymbols[j]
        const targetUserWeightWad =
          withAccount.voteOfEachAssetWads[poolSymbol][assetTokenSymbol]
        userVoteWeightOfEachAssetInVeptp[poolSymbol][assetTokenSymbol] =
          utils.formatEther(targetUserWeightWad)
        const userVoteWeightInPercentage = getPercentageFromTwoWAD(
          targetUserWeightWad,
          votingPowerWad,
        )
        // if user votes less than 0.01% then set it as 0.00
        userVoteWeightOfEachAssetInPercentage[poolSymbol][assetTokenSymbol] =
          strToWad(userVoteWeightInPercentage).lt(strToWad('0.01'))
            ? '0.00'
            : getDpFormat(userVoteWeightInPercentage, 2, 'off')
      }
      const earnedBribeRewards = getEarnedBribeRewards(
        withAccount.userEarnedBribeOfEachAsset,
        chainId,
        withoutAccount?.bribeBalanceOfEachAssetBN,
      )

      const earnedBribeRewardsTotalInUsd = utils.formatEther(
        earnedBribeRewards.reduce((acc, reward) => {
          return wmul(
            strToWad(tokenPrices[reward.bribeTokenSymbol]),
            strToWad(reward.value),
          ).add(acc)
        }, BigNumber.from(0)),
      )

      newUserData = {
        remainingVote: {
          vePtp: utils.formatEther(votingPowerWad.sub(withAccount.usedVoteWad)),
          percentage: getPercentageFromTwoWAD(
            votingPowerWad.sub(withAccount.usedVoteWad),
            votingPowerWad,
          ),
        },
        usedVote: {
          vePtp: utils.formatEther(withAccount.usedVoteWad),
          percentage: getPercentageFromTwoWAD(
            withAccount.usedVoteWad,
            votingPowerWad,
          ),
        },
        voteWeightOfEachAsset: {
          vePtp: userVoteWeightOfEachAssetInVeptp,
          percentage: userVoteWeightOfEachAssetInPercentage,
        },
        earnedBribeOfEachAsset: withAccount.userEarnedBribeOfEachAsset,
        earnedBribeRewards,
        hasEarnedBribeRewards: earnedBribeRewards.length > 0,
        earnedBribeRewardsTotalInUsd,
      }
    }
  } else {
    newUserData = initialVotingDataContext.user
  }

  /**
   * Bribe Rewards and APRs
   */
  if (
    withoutAccount?.weightOfEachAssetWads &&
    withoutAccount?.bribeTokenPerSecondOfEachAssetBN
  ) {
    newWeeklyBribeIncentivesData.per100kVeptp =
      getBribeIncentivesPerUserVotesPerNDays(
        withoutAccount.weightOfEachAssetWads,
        withoutAccount.bribeTokenPerSecondOfEachAssetBN,
        tokenPrices,
        '100K',
        7,
      )
    // calculate average bribe apr
    const annualBribeRewardsPer100kVeptp =
      getBribeIncentivesPerUserVotesPerNDays(
        withoutAccount.weightOfEachAssetWads,
        withoutAccount.bribeTokenPerSecondOfEachAssetBN,
        tokenPrices,
        '100K',
        365,
      )

    // calculate annual bribe apr
    const annualBribeRewards = getAnnualBribeIncentivesWad(
      withoutAccount?.bribeTokenPerSecondOfEachAssetBN,
      tokenPrices,
    )

    const annualBribeRewardsApr = getPercentageFromTwoWAD(
      annualBribeRewards,
      wmul(strToWad(tokenPrices.PTP), strToWad(ptp.total.amount.sum)),
    )

    newBribeAprData.annual.percentage = annualBribeRewardsApr

    const totalSupplyWad = strToWad(vePtp.totalSupply)
    if (!totalSupplyWad.isZero()) {
      const ptpToVeptpRatioWad = safeWdiv(
        strToWad(ptp.total.amount.sum),
        strToWad(vePtp.totalSupply),
      )
      // Assume vePtp is 100k
      const estimatedStakedPtpInUsd = utils.formatEther(
        wmul(
          wmul(strToWad('100000'), ptpToVeptpRatioWad),
          strToWad(tokenPrices.PTP),
        ),
      )

      newBribeAprData.average = getAprOfEachBribe(
        annualBribeRewardsPer100kVeptp,
        estimatedStakedPtpInUsd,
      )
    }
    /**
     * with accounts
     */
    if (withAccount?.voteOfEachAssetWads) {
      newWeeklyBribeIncentivesData.perVotedVeptp =
        getBribeIncentivesPerUserVotesPerNDays(
          withoutAccount.weightOfEachAssetWads,
          withoutAccount.bribeTokenPerSecondOfEachAssetBN,
          tokenPrices,
          'ACTUAL',
          7,
          withAccount.voteOfEachAssetWads,
          strToWad(vePtp.balance.total.current),
        )
      newWeeklyBribeIncentivesData.perMaxVeptp =
        getBribeIncentivesPerUserVotesPerNDays(
          withoutAccount.weightOfEachAssetWads,
          withoutAccount.bribeTokenPerSecondOfEachAssetBN,
          tokenPrices,
          'MAX',
          7,
          withAccount.voteOfEachAssetWads,
          strToWad(vePtp.balance.total.current),
        )

      // calculate user bribe apr
      const annualMaxBribeRewards = getBribeIncentivesPerUserVotesPerNDays(
        withoutAccount.weightOfEachAssetWads,
        withoutAccount.bribeTokenPerSecondOfEachAssetBN,
        tokenPrices,
        'MAX',
        365,
        withAccount.voteOfEachAssetWads,
        strToWad(vePtp.balance.total.current),
      )
      const userStakedOrLockedPtpInUsd = utils.formatEther(
        wmul(strToWad(ptp.amount.sum), strToWad(tokenPrices.PTP)),
      )
      newBribeAprData.user.max = getAprOfEachBribe(
        annualMaxBribeRewards,
        userStakedOrLockedPtpInUsd,
      )
    } else {
      newWeeklyBribeIncentivesData.perVotedVeptp = {}
      newWeeklyBribeIncentivesData.perMaxVeptp = {}
      newBribeAprData.user = { max: {} }
    }
  }

  return (
    <VotingDataContext.Provider
      value={{
        weeklyBribeIncentives: newWeeklyBribeIncentivesData,
        bribeAprData: newBribeAprData,
        total: newTotalData,
        user: newUserData,
      }}
    >
      {children}
    </VotingDataContext.Provider>
  )
}
