import { Call } from 'ethcall'
import { BigNumber, constants, utils } from 'ethers'
import cloneDeep from 'lodash.clonedeep'
import { Bribe } from '../../../../types/ethers-contracts'
import { POOLS, TOKENS, vePtp, voter } from '../../../config/contracts'
import BribeABI from '../../../config/contracts/abis/Bribe.json'
import { Contract } from '../../../config/contracts/Contract'
import { LpToken } from '../../../config/contracts/lpToken/LpToken'
import { poolSymbols } from '../../../config/contracts/pool/poolSymbol'
import { BRIBE_ABI } from '../../../config/contracts/wagmiAbis/Bribe'
import { ChainId } from '../../../config/networks'
import {
  EMPTY_POOLSYMBOL_STR_BN,
  EMPTY_POOLSYMBOL_STR_STR,
} from '../../../constants/empty'
import {
  PoolSymbolStringBigNumberType,
  PoolSymbolStringStringType,
} from '../../../interfaces/common'
import {
  CallbacksType,
  MulticallHelperSCReturnType,
} from '../../../utils/multicall'
import { DEPRECATED_GAUGES_POOLS } from '../../../config/contracts/pool'

export interface VotingDataType {
  ptpPerSecWad: BigNumber
  totalWeightWad: BigNumber
  weightOfEachAssetWads: PoolSymbolStringBigNumberType
  bribeTokenPerSecondOfEachAssetBN: PoolSymbolStringBigNumberType
  bribeBalanceOfEachAssetBN: PoolSymbolStringBigNumberType
}

export interface VotingDataWithAccountType {
  usedVoteWad: BigNumber
  voteOfEachAssetWads: PoolSymbolStringBigNumberType
  userEarnedBribeOfEachAsset: PoolSymbolStringStringType
}

export const fetchVotingData = (
  chainId: ChainId,
  account: string | null,
): {
  contractCalls: Call[]
  callbacks: CallbacksType<MulticallHelperSCReturnType>
  states: {
    withAccount: VotingDataWithAccountType
    withoutAccount: VotingDataType
  }
} => {
  /**
   * A. Create empty contractCalls and callbacks array
   */
  const contractCalls: Call[] = []
  const callbacks: CallbacksType<MulticallHelperSCReturnType> = []
  const states: {
    withAccount: VotingDataWithAccountType
    withoutAccount: VotingDataType
  } = {
    withoutAccount: {
      ptpPerSecWad: constants.Zero,
      totalWeightWad: constants.Zero,
      weightOfEachAssetWads: cloneDeep(EMPTY_POOLSYMBOL_STR_BN),
      bribeTokenPerSecondOfEachAssetBN: cloneDeep(EMPTY_POOLSYMBOL_STR_BN),
      bribeBalanceOfEachAssetBN: cloneDeep(EMPTY_POOLSYMBOL_STR_BN),
    },
    withAccount: {
      usedVoteWad: constants.Zero,
      voteOfEachAssetWads: cloneDeep(EMPTY_POOLSYMBOL_STR_BN),
      userEarnedBribeOfEachAsset: cloneDeep(EMPTY_POOLSYMBOL_STR_STR),
    },
  }
  const withBribeAssetAddresses: string[] = []
  const withBribeAssets: LpToken[] = []
  const handleVotingData = () => {
    const voterContract = voter.get(chainId)
    const vePtpContract = vePtp.get(chainId)
    if (vePtpContract && account) {
      contractCalls.push(vePtpContract.usedVote(account) as unknown as Call)
      callbacks.push((valueWad) => {
        states.withAccount.usedVoteWad = valueWad as BigNumber
      })
    }
    if (voterContract) {
      contractCalls.push(voterContract.ptpPerSec() as unknown as Call)
      callbacks.push((valueBN) => {
        states.withoutAccount.ptpPerSecWad = valueBN as BigNumber
      })
      contractCalls.push(voterContract.totalWeight() as unknown as Call)
      callbacks.push((valueBN) => {
        states.withoutAccount.totalWeightWad = valueBN as BigNumber
      })
      for (let i = 0; i < poolSymbols.length; i++) {
        const poolSymbol = poolSymbols[i]
        const pool = POOLS[poolSymbol]
        let assets = pool.getAssets()
        if (DEPRECATED_GAUGES_POOLS.includes(poolSymbol)) {
          assets = pool.getAssets('deprecatedMainPoolForGauageVoting')
        }
        for (let j = 0; j < assets.length; j++) {
          const assetBribe = assets[j].bribe
          if (assetBribe) {
            const assetAddress = assets[j].getAddress(chainId)
            if (assetAddress) {
              withBribeAssets.push(assets[j])
              withBribeAssetAddresses.push(assetAddress)
            }
            const bribeContract = new Contract<Bribe, typeof BRIBE_ABI>(
              assetBribe.bribeAddress,
              BribeABI,
              BRIBE_ABI,
            ).get(chainId)
            if (bribeContract) {
              contractCalls.push(bribeContract.tokenPerSec() as unknown as Call)
              callbacks.push((valueBN) => {
                states.withoutAccount.bribeTokenPerSecondOfEachAssetBN[
                  poolSymbol
                ][assetTokenSymbol] = valueBN as BigNumber
              })
              contractCalls.push(bribeContract.balance() as unknown as Call)
              callbacks.push((valueBN) => {
                states.withoutAccount.bribeBalanceOfEachAssetBN[poolSymbol][
                  assetTokenSymbol
                ] = valueBN as BigNumber
              })
            }
          }
          const assetTokenSymbol = assets[j].tokenSymbol
          const assetTokenAddress = assets[j].address[chainId]
          if (assetTokenAddress) {
            contractCalls.push(
              voterContract.weights(assetTokenAddress) as unknown as Call,
            )
            callbacks.push((valueWad) => {
              states.withoutAccount.weightOfEachAssetWads[poolSymbol][
                assetTokenSymbol
              ] = valueWad as BigNumber
            })
          }

          if (account) {
            if (assetTokenAddress) {
              contractCalls.push(
                voterContract.getUserVotes(
                  account,
                  assetTokenAddress,
                ) as unknown as Call,
              )
              callbacks.push((valueWad) => {
                states.withAccount.voteOfEachAssetWads[poolSymbol][
                  assetTokenSymbol
                ] = valueWad as BigNumber
              })
            }
          }
        }
      }
      if (account) {
        contractCalls.push(
          voterContract.pendingBribes(
            withBribeAssetAddresses,
            account,
          ) as unknown as Call,
        )
        callbacks.push((valueBNs) => {
          for (let h = 0; h < withBribeAssets.length; h++) {
            const withBribeAsset = withBribeAssets[h]
            if (withBribeAsset.bribe?.tokenSymbol) {
              states.withAccount.userEarnedBribeOfEachAsset[
                withBribeAsset.poolSymbol
              ][withBribeAsset.tokenSymbol] = utils.formatUnits(
                (valueBNs as BigNumber[])[h],
                TOKENS[withBribeAsset.bribe.tokenSymbol].decimals,
              )
            }
          }
        })
      }
    }
  }

  handleVotingData()
  return {
    contractCalls,
    callbacks,
    states,
  }
}
