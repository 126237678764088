import { Box } from '@mui/material'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import Spinner from '../Spinner/Spinner'

interface Props
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  disableSpinner?: boolean
}
function AppImage({ src, disableSpinner, ...otherProps }: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(true)
  const [imgWidth, setImgWidth] = useState(0)
  const imgRef = useRef<HTMLImageElement | null>(null)

  useEffect(() => {
    if (disableSpinner) return

    const imgEle = imgRef.current
    if (!src || !imgEle) return
    const handleOnLoad = () => {
      setIsLoading(false)
    }
    setImgWidth(imgEle.width)
    setIsLoading(true)
    imgEle.src = src
    imgEle.addEventListener('load', handleOnLoad)

    return () => {
      if (imgEle) {
        imgEle.removeEventListener('load', handleOnLoad)
      }
    }
  }, [disableSpinner, src])
  return (
    <>
      {!disableSpinner && isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin="0 auto"
          width={imgWidth * 0.4}
          height="100%"
          data-testid="spinner-wrapper"
        >
          <Spinner />
        </Box>
      )}
      <img
        style={{
          visibility: !disableSpinner && isLoading ? 'hidden' : 'visible',
        }}
        ref={imgRef}
        {...otherProps}
      />
    </>
  )
}

export default AppImage
