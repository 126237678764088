import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'
import PlatopiaImg from '../../assets/background/platopia_bg.png'
import AppButton from '../../components/buttons/AppButton/AppButton'
import { PageContainer } from '../../globalStyles'

// Common styles
export const RowToColumnContainer = styled(Box)`
  ${({ theme }) =>
    css`
      display: flex;
      flex-direction: row;

      @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    `}
`

export const ShrinkAndGrowContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex: 1;
      align-items: center;
    }
  `}
`
export const CTAButton = styled(AppButton)`
  ${() =>
    css`
      width: 200px;
    `}
`
// VePtpPageContainer
export const PlatopiaBackground = styled(Box)`
  ${({ theme }) => css`
    background-image: url(${PlatopiaImg});
    background-position: top;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 0;

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      &:before {
        background: rgba(14, 15, 40, 0.7);
      }
    }
  `}
`

export const MainBody = styled(Box)`
  ${() => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
    width: 100%;
    padding: 12px 16px 24px 16px;
    background: inherit;
    z-index: 10;
  `}
`
interface PlatopiaButtonProps {
  disabled?: boolean
  imageSize?: 'small' | 'large'
}
export const PlatopiaButton = styled(Box)<PlatopiaButtonProps>`
  ${({ disabled, imageSize = 'small' }: PlatopiaButtonProps) => css`
    position: relative;
    display: flex;
    align-items: center;
    background: linear-gradient(
      266.17deg,
      #0a1b52 56.45%,
      rgba(10, 27, 82, 0.8) 91.31%
    );
    padding: 2px 8px;
    cursor: pointer;

    // give some space to the img
    padding-left: 28px;
    margin-top: 8px;
    border-radius: 999px;
    border: 1px solid transparent;

    // for border
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-radius: inherit;
      margin: -3px;
      background: linear-gradient(
        99.19deg,
        rgba(0, 241, 82, 0.5) 10.12%,
        rgba(0, 118, 233, 0.5) 100%
      );
    }
    ${disabled &&
    css`
      cursor: unset;
    `}
    img {
      position: absolute;
      width: 26px;
      height: 27px;
      left: -2px;
      top: -7px;
    }

    ${imageSize === 'large' &&
    css`
      /* give some space to the img */
      padding-left: 50px;
      img {
        width: 48px;
        height: 47px;
        left: -3px;
        top: -14px;
      }
    `}
  `}
`
interface PTPStakeIconProps {
  enabled?: boolean
}
export const PTPStakeIcon = styled.img<PTPStakeIconProps>`
  ${({ theme, enabled }) => css`
    position: absolute;
    top: -90px;
    left: -50px;
    width: 252px;
    height: 161px;
    mix-blend-mode: luminosity;
    ${enabled &&
    css`
      mix-blend-mode: unset;
    `}
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 69px;
      max-height: 70px;
      top: 3px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
    }
  `}
`
export const StakeDetails = styled(Box)`
  ${() =>
    css`
      width: 100%;
    `}
`

interface StakeDetailItemProps {
  $isActive?: boolean
  $disableBackground?: boolean
  $noPadding?: boolean
  $noMargin?: boolean
}

export const StakeDetailItem = styled(Box)<StakeDetailItemProps>`
  ${({
    theme,
    $isActive,
    $disableBackground = false,
    $noPadding = false,
    $noMargin = false,
  }) =>
    css`
      margin: 20px 0;
      padding: 16px;
      background: ${alpha(theme.palette.primary[500], 0.2)};
      border-radius: 10px;

      ${$disableBackground &&
      css`
        background: none;
        border-radius: none;
      `}

      ${!$isActive &&
      css`
        opacity: 0.4;
      `}

     
      @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
        padding: 20px;
      }

      ${$noPadding &&
      css`
        padding: 0;
        @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
          padding: 0;
        }
      `}

      ${$noMargin &&
      css`
        margin: 0;
        @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
          margin: 0;
        }
      `}
    `}
`

export const HiddenContent = styled(Box)`
  ${() => css`
    grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
    grid-gap: 20px 4px;
    justify-content: center;
    transform-origin: left top;
  `}
`

export const ButtonWrapper = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    max-width: 320px;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 400px;
    }
  `}
`

interface StyledPageContainerProps {
  margin?: string
}

export const StyledPageContainer = styled(
  PageContainer,
)<StyledPageContainerProps>`
  ${({ theme, margin }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${alpha(theme.palette.primary[900], 1)};
    padding: 0;
    overflow: hidden;
    margin: ${margin ? margin : '0'};

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin: ${margin ? margin : '0'};
      background-color: ${alpha(theme.palette.primary[900], 0.7)};
    }
  `}
`

interface InfoContainerProps {
  $borderRoundPosition?: 'top' | 'bottom'
}

export const InfoContainer = styled(Box)<InfoContainerProps>`
  ${({ theme, $borderRoundPosition }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    background: ${alpha(theme.palette.primary[500], 0.2)};
    border-radius: 10px;
    padding: 16px;

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
    }

    ${$borderRoundPosition === 'top' &&
    css`
      border-radius: 10px 10px 0 0;
    `}

    ${$borderRoundPosition === 'bottom' &&
    css`
      border-radius: 0 0 10px 10px;
    `}
  `}
`
