import { Box, IconButton, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

interface MessageContainerStyledProps {
  $backgroundColor?: string
}

export const MessageContainer = styled(Box)<MessageContainerStyledProps>`
  ${({ theme, $backgroundColor }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.accents.yellow};
    border-radius: ${theme.borderRadius.sm};
    padding: 8px;
    margin: 12px 0;

    * {
      color: ${theme.palette.common.black};
    }

    ${$backgroundColor === 'warning' &&
    css`
      background-color: ${theme.palette.error.main};

      * {
        color: ${theme.palette.common.white};
      }
    `}
  `}
`
export const Topbar = styled(Box)`
  ${() => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .message__icon {
      margin-right: 4px;
      font-size: 24px;
    }
  `}
`

export const CloseButton = styled(IconButton)`
  ${() => css`
    position: absolute;
    right: 0;
    svg {
      font-size: 22px;
    }
  `}
`

export const Title = styled(Typography)``

export const Content = styled(Typography)`
  text-align: center;
`
