import {
  getCommifiedFormat,
  getSfFormat,
} from '@hailstonelabs/big-number-utils'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import TooltipText from '../../../components/TooltipText/TooltipText'
import { useModal } from '../../../contexts/ModalContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { Colorized } from '../../../globalStyles'
import useWalletConnection from '../../../hooks/wallet/useWalletConnection'
import { ModalId } from '../../../interfaces/Modal'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import { useVePtpContainer } from '../VePtpContainer'
import {
  CalculatorButton,
  Container,
  EarningAdditionalInfo,
  EarningAdditionalInfoItem,
  EarningProgress,
  TotalEarning,
} from './MyBalanceInfo.elements'

function MyBalanceInfo(): ReactElement {
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { vePtp } = useVePtp()
  const { isTabletSm } = useVePtpContainer()
  const { account } = useNetwork()
  const { openConnectToWalletModal } = useWalletConnection()
  const handleClickCalculator = () => {
    modalDispatch(openModal(ModalId.VEPTP_BOOSTER_CALCULATOR))
  }

  return (
    <Container $isActive={!!account}>
      <TotalEarning>
        <AppTypography
          variant={isTabletSm ? 'h5' : 'h4'}
          className="total-earning__title"
        >
          My balance
        </AppTypography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isTabletSm ? 'center' : 'flex-end'}
          width={isTabletSm ? '100%' : 'fit-content'}
        >
          <Box display="flex" alignItems="center">
            <Colorized variant="green">
              <Box display="flex" flexDirection="row" alignItems="center">
                <AppTypography variant="h4" data-testid="claimed-veptp">
                  <TooltipNum
                    amount={vePtp.balance.total.current}
                    rightSymbol="vePTP"
                  >
                    {showDashIfNecessary(
                      !account,
                      getCommifiedFormat(vePtp.balance.total.current),
                    )}
                  </TooltipNum>
                </AppTypography>
                <AppTypography variant="body1" marginLeft="8px">
                  vePTP
                </AppTypography>
              </Box>
            </Colorized>
            <InfoBox.Tooltip
              text={
                <TooltipText>
                  <TooltipText.Group
                    header="vePTP Balance"
                    transparent={false}
                    isBold={true}
                  >
                    <TooltipText.RewardItem
                      title="Lock"
                      value={getCommifiedFormat(vePtp.balance.locking, 2)}
                      isBold={false}
                    />
                    <TooltipText.RewardItem
                      title="Stake"
                      value={getCommifiedFormat(
                        vePtp.balance.staking.current,
                        2,
                      )}
                      isBold={false}
                    />
                  </TooltipText.Group>
                </TooltipText>
              }
              PopperProps={{
                style: {
                  minWidth: '200px',
                },
              }}
            />
          </Box>
          {isTabletSm && (
            <EarningProgress
              variant="determinate"
              value={vePtp.balancePercentage}
            />
          )}
        </Box>
      </TotalEarning>
      {/* the range for the value is 0 - 100 */}
      {!isTabletSm && (
        <EarningProgress
          variant="determinate"
          value={vePtp.balancePercentage}
        />
      )}
      <EarningAdditionalInfo>
        <EarningAdditionalInfoItem>
          <CalculatorButton
            onClick={account ? handleClickCalculator : openConnectToWalletModal}
          >
            Booster Calculator
            <CalculateOutlinedIcon />
          </CalculatorButton>
        </EarningAdditionalInfoItem>
        <EarningAdditionalInfoItem>
          <AppTypography component="span" transparent>
            Max vePTP to Earn:{' '}
            <TooltipNum amount={vePtp.maxCap.total.current}>
              {showDashIfNecessary(
                !account,
                getCommifiedFormat(
                  getSfFormat(vePtp.maxCap.total.current, 6),
                  0,
                ),
              )}
            </TooltipNum>
          </AppTypography>
          <InfoBox.Tooltip
            text={
              <TooltipText>
                <TooltipText.Group
                  header="Max vePTP To Earn"
                  transparent={false}
                  isBold={true}
                >
                  <TooltipText.RewardItem
                    title="Lock"
                    value={getCommifiedFormat(vePtp.maxCap.locking, 2)}
                    isBold={false}
                  />
                  <TooltipText.RewardItem
                    title="Stake"
                    value={getCommifiedFormat(vePtp.maxCap.staking.current, 2)}
                    isBold={false}
                  />
                </TooltipText.Group>
              </TooltipText>
            }
            PopperProps={{
              style: {
                minWidth: '200px',
              },
            }}
          />
        </EarningAdditionalInfoItem>
      </EarningAdditionalInfo>
    </Container>
  )
}

export default MyBalanceInfo
