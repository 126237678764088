import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'

interface IAirdropItemContainer {
  $disabled: boolean
}

export const AirdropItemContainer = styled(Box)<IAirdropItemContainer>`
  ${({ theme, $disabled = false }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${alpha(theme.palette.primary[500], 0.2)};
    height: 50px;
    padding: 12px;
    margin: 8px 0;
    border-radius: 10px;
    cursor: pointer;

    user-select: none;
    ${!$disabled &&
    css`
      &:hover {
        opacity: 0.8;
      }
    `}

    ${$disabled &&
    css`
      opacity: 0.5;
    `}
  `}
`
