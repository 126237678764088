import { Box } from '@mui/material'
import styled from 'styled-components'

export const Container = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 8px;
`

export const InfoItem = styled(Box)`
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  .tpYieldCard__info-title {
    text-align: right;
    margin-right: 8px;
  }
`
