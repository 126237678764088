import { Box } from '@mui/material'
import React, { ReactElement, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import AppMenu from '../../components/AppMenu/AppMenu'
import AppTypography from '../../components/AppTypography/AppTypography'
import AccordionButton from '../../components/buttons/AccordionButton/AccordionButton'
import AppCheckBox from '../../components/Input/AppCheckBox/AppCheckBox'
import TabBar from '../../components/TabBar/TabBar'
import TokenIcon from '../../components/TokenIcon/TokenIcon'
import {
  PoolGroup,
  POOL_GROUPS,
  POOL_GROUP_SYMBOL_KEYS,
} from '../../config/contracts/pool/PoolGroup'
import {
  PoolFilterSymbol,
  PoolGroupSymbol,
} from '../../config/contracts/pool/poolSymbol'
import { QUERY_PARAMS } from '../../constants'
import useBreakpoints from '../../hooks/useBreakpoints'
import { Container } from './PoolGroupSelectionTabBar.elements'

interface Props {
  currentPoolGroupSymbol: PoolGroupSymbol | null
  onSelectPoolGroupSymbol: (poolGroupSymbol: PoolGroupSymbol) => void
  filteredSubgroupPoolsCount: number
}

const INITIAL_POOL_SUBGROUP_QUERY_STR = Object.values(PoolFilterSymbol).join()
const POOL_FILTER_OPTIONS_COUNT = Object.values(PoolFilterSymbol).length

function PoolGroupSelectionTabBar({
  currentPoolGroupSymbol,
  onSelectPoolGroupSymbol,
  filteredSubgroupPoolsCount,
}: Props): ReactElement {
  const { isTabletSm } = useBreakpoints()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isChecked, setIsChecked] = useState<boolean[]>(
    new Array(POOL_FILTER_OPTIONS_COUNT).fill(true),
  )
  const [isAllChecked, setIsAllChecked] = useState<boolean>(true)
  const { pathname } = useLocation()
  const [anchorElementForMenu, setAnchorElementForMenu] =
    React.useState<HTMLDivElement | null>(null)

  const handleClickTab = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    poolGroupSymbol: PoolGroupSymbol,
  ): void => {
    e.preventDefault()
    if (poolGroupSymbol === currentPoolGroupSymbol) return
    onSelectPoolGroupSymbol(poolGroupSymbol)
    // update the query string when users click the tab. It will push a new state to the history.

    const initialPoolGroupQuery = {
      [QUERY_PARAMS.poolGroup]: poolGroupSymbol.toLowerCase(),
    }
    const initialPoolSubgroupQuery = {
      [QUERY_PARAMS.poolSubgroup]: INITIAL_POOL_SUBGROUP_QUERY_STR,
    }
    setSearchParams({
      ...initialPoolGroupQuery,
      ...(poolGroupSymbol !== PoolGroupSymbol.MAIN && initialPoolSubgroupQuery),
    })
  }

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setAnchorElementForMenu(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorElementForMenu(null)
  }

  /**
   * update the query string when users are coming from other pages to pool page.
   */
  useEffect(() => {
    const poolGroupSymbolFromQuery = searchParams.get(QUERY_PARAMS.poolGroup)
    if (poolGroupSymbolFromQuery) return

    if (currentPoolGroupSymbol) {
      const initialPoolGroupQuery = {
        [QUERY_PARAMS.poolGroup]: currentPoolGroupSymbol.toLowerCase(),
      }
      const initialSubgroupQuery = {
        [QUERY_PARAMS.poolSubgroup]: INITIAL_POOL_SUBGROUP_QUERY_STR,
      }
      setSearchParams(
        {
          ...initialPoolGroupQuery,
          ...(currentPoolGroupSymbol !== PoolGroupSymbol.MAIN &&
            initialSubgroupQuery),
        },
        // allow users to go back to where they come from
        { replace: true },
      )
    }
    // only run once when the page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Case 1: when users type the query via url
   * Case 2: when users go back to the previous query string
   */
  useEffect(() => {
    const poolGroupSymbolFromQuery = searchParams.get(QUERY_PARAMS.poolGroup)
    if (poolGroupSymbolFromQuery) {
      // the pool group symbol from the query string is case insensitve
      const targetPoolGroupSymbolFromQuery = POOL_GROUP_SYMBOL_KEYS.find(
        (key) => key.toLowerCase() === poolGroupSymbolFromQuery.toLowerCase(),
      )

      if (targetPoolGroupSymbolFromQuery !== currentPoolGroupSymbol) {
        // update the pool group symbol from the query string
        onSelectPoolGroupSymbol(
          targetPoolGroupSymbolFromQuery as PoolGroupSymbol,
        )
      }
    }
    // only run when searchParams change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const poolGroupQuery = searchParams.get(QUERY_PARAMS.poolGroup) || ''
  const poolSubgroupQuery = searchParams.get(QUERY_PARAMS.poolSubgroup) || ''
  let filterQueryArr = poolSubgroupQuery.split(',')

  //Update State for 'Option' Checkbox
  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const poolSubgroup = e.target.value
    const index = Number(e.target.id)

    if (e.target.checked) {
      filterQueryArr.push(poolSubgroup)
    } else {
      const indexOfObject = filterQueryArr.findIndex((element) => {
        return element === poolSubgroup
      })
      filterQueryArr.splice(indexOfObject, 1)
    }
    setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)))
    updateParams()
  }

  //Update State for 'All' Checkbox
  const handleAllCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setIsChecked(new Array(POOL_FILTER_OPTIONS_COUNT).fill(true))
      setIsAllChecked(true)
      filterQueryArr = Object.values(PoolFilterSymbol)
    } else {
      setIsChecked(new Array(POOL_FILTER_OPTIONS_COUNT).fill(false))
      setIsAllChecked(false)
      filterQueryArr = []
    }
    updateParams()
  }

  //Update PoolSubGroup Query For Filtering
  const updateParams = () => {
    const filterQueryStr = filterQueryArr.join()
    if (filterQueryStr) {
      searchParams.set(QUERY_PARAMS.poolSubgroup, filterQueryStr)
    } else {
      searchParams.delete(QUERY_PARAMS.poolSubgroup)
    }
    setSearchParams(searchParams)
  }

  //Reset Checkbox Once Pool Group/ Path Changed
  useEffect(() => {
    setIsChecked(new Array(POOL_FILTER_OPTIONS_COUNT).fill(true))
    setIsAllChecked(true)
  }, [poolGroupQuery, pathname])

  useEffect(() => {
    //If Either one Checkbox is unclicked, 'All' checkbox will be unclicked
    if (isChecked.includes(false)) {
      setIsAllChecked(false)
    }
    //If all Checkbox is clicked, 'All' checkbox will be clicked
    if (isChecked.every((v) => v == true)) {
      setIsAllChecked(true)
    }
  }, [isChecked])

  function getLabel(poolGroupSymbol: string, poolGroup: PoolGroup) {
    let poolGroupName = ''
    let subgroupPoolsCount = ''
    if (isTabletSm) {
      poolGroupName = poolGroup.name.replace(/ pool[s]?/i, '')
    } else {
      poolGroupName = poolGroup.name
      if (
        POOL_GROUPS[poolGroupSymbol as PoolGroupSymbol] !== POOL_GROUPS.MAIN &&
        currentPoolGroupSymbol === poolGroupSymbol
      ) {
        subgroupPoolsCount = `(${filteredSubgroupPoolsCount})`
      }
    }
    return `${poolGroupName} ${subgroupPoolsCount}`
  }

  return (
    <Container
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      {/* @TODO: use length of pool groups instead */}
      <TabBar numOfTabs={2} width="815px" height="45px">
        {Object.entries(POOL_GROUPS).map(([poolGroupSymbol, poolGroup]) => (
          <TabBar.Tab
            key={poolGroup.name}
            label={getLabel(poolGroupSymbol, poolGroup)}
            labelProps={{ variant: 'body2' }}
            activeLink={poolGroupSymbol === currentPoolGroupSymbol}
            onClick={(e) =>
              handleClickTab(e, poolGroupSymbol as PoolGroupSymbol)
            }
            buttonComponent={
              poolGroupSymbol !== PoolGroupSymbol.MAIN &&
              currentPoolGroupSymbol === poolGroupSymbol && (
                <Box onClick={handleClickMenu}>
                  <AccordionButton
                    data-testid="accordion-button"
                    active={!!anchorElementForMenu}
                    style={{
                      marginLeft: '8px',
                    }}
                  />
                </Box>
              )
            }
          />
        ))}
        {currentPoolGroupSymbol && (
          <AppMenu
            open={!!anchorElementForMenu}
            anchorEl={anchorElementForMenu}
            onClose={handleCloseMenu}
            MenuListProps={{ style: { minWidth: '240px' } }}
            disableAutoFocus
            disableAutoFocusItem
            disableScrollLock={false}
          >
            <AppMenu.MenuItem>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <AppCheckBox
                  onChange={handleAllCheckBox}
                  checked={isAllChecked}
                  label={
                    <AppTypography variant="h6">
                      All {POOL_GROUPS[currentPoolGroupSymbol].name}
                    </AppTypography>
                  }
                  style={{
                    margin: '0',
                    padding: '12px 0 12px 0',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                />
              </Box>
            </AppMenu.MenuItem>
            {Object.values(PoolFilterSymbol).map(
              (filteredPoolSymbol, index) => {
                return (
                  <AppMenu.MenuItem key={filteredPoolSymbol}>
                    <AppCheckBox
                      onChange={handleCheckBox}
                      value={filteredPoolSymbol}
                      id={index.toString()}
                      checked={isChecked[index]}
                      style={{
                        margin: '0',
                        padding: '12px 0 12px 0',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                      label={
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <TokenIcon
                            filteredPoolSymbol={filteredPoolSymbol}
                            size={25}
                            margin="0 10px 0 0"
                          />
                          <AppTypography variant="body1">
                            {filteredPoolSymbol} Pools
                          </AppTypography>
                        </Box>
                      }
                    />
                  </AppMenu.MenuItem>
                )
              },
            )}
          </AppMenu>
        )}
      </TabBar>
    </Container>
  )
}

export default PoolGroupSelectionTabBar
