import styled, { css } from 'styled-components'
import { getLottieScaledHeight, getLottieScaledWidth } from '../Lottie/Lottie'

interface ImageProps {
  data: string
  windowWidth: number
  top?: number | undefined
  right?: number | undefined
  bottom?: number | undefined
  left?: number | undefined
  width: number | undefined
  height: number | undefined
  zIndex?: number | undefined
}

export const Image = styled.img.attrs<ImageProps>(({ data }) => ({
  src: data,
}))<ImageProps>`
  position: absolute;
  width: ${({ windowWidth, width }) =>
    getLottieScaledWidth(windowWidth, width)}px;
  height: ${({ windowWidth, height }) =>
    getLottieScaledHeight(windowWidth, height)}px;
  ${({ zIndex }) =>
    zIndex != null
      ? css`
          z-index: ${zIndex};
        `
      : ''}
  ${({ windowWidth, top }) =>
    top != null
      ? css`
          top: ${getLottieScaledHeight(windowWidth, top)}px;
        `
      : ''}
  ${({ windowWidth, right }) =>
    right != null
      ? css`
          right: ${getLottieScaledWidth(windowWidth, right)}px;
        `
      : ''}
    ${({ windowWidth, bottom }) =>
    bottom != null
      ? css`
          bottom: ${getLottieScaledHeight(windowWidth, bottom)}px;
        `
      : ''}
      ${({ windowWidth, left }) =>
    left != null
      ? css`
          left: ${getLottieScaledHeight(windowWidth, left)}px;
        `
      : ''}
`
