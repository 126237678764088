export const PLATYPUS_ROUTER02_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'tokens', type: 'address[]' },
      { internalType: 'address', name: 'pool', type: 'address' },
    ],
    name: 'approveSpendingByPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'tokenPath', type: 'address[]' },
      { internalType: 'address[]', name: 'poolPath', type: 'address[]' },
      { internalType: 'uint256', name: 'fromAmount', type: 'uint256' },
    ],
    name: 'quotePotentialSwaps',
    outputs: [
      {
        internalType: 'uint256',
        name: 'potentialOutcome',
        type: 'uint256',
      },
      { internalType: 'uint256', name: 'haircut', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'tokenPath', type: 'address[]' },
      { internalType: 'address[]', name: 'poolPath', type: 'address[]' },
      { internalType: 'uint256', name: 'fromAmount', type: 'uint256' },
      {
        internalType: 'uint256',
        name: 'minimumToAmount',
        type: 'uint256',
      },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'swapTokensForTokens',
    outputs: [
      { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'haircut', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const
