import React, { ReactElement } from 'react'
import LottieElement from 'react-lottie'
import { PLATOPIA_DESKTOP_MAX_WIDTH } from '../../../config/theme'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { Container } from './Lottie.elements'

export const DESKTOP_BACKGROUND_RATIO = 1920 / 1080

/**
 * Calculate scaled width according to browser width
 * @param {number | undefined} pixel original size
 * @returns {number | undefined}
 */
export const getLottieScaledWidth = (
  windowWidth: number,
  pixel?: number | undefined,
): number | undefined => {
  if (pixel == null || windowWidth >= PLATOPIA_DESKTOP_MAX_WIDTH) {
    return pixel
  }

  return Math.floor((pixel / PLATOPIA_DESKTOP_MAX_WIDTH) * windowWidth)
}

/**
 * Calculate scaled height according to browser width
 * @param {number | undefined} pixel original size
 * @returns {number | undefined}
 */
export const getLottieScaledHeight = (
  windowWidth: number,
  pixel?: number | undefined,
): number | undefined => {
  if (pixel == null || windowWidth >= PLATOPIA_DESKTOP_MAX_WIDTH) {
    return pixel
  }

  return Math.floor(
    (pixel * windowWidth) /
      DESKTOP_BACKGROUND_RATIO /
      (PLATOPIA_DESKTOP_MAX_WIDTH / DESKTOP_BACKGROUND_RATIO),
  )
}

export interface LottieProps {
  /* Lottie animationData is defined as any */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data: any
  top?: number | undefined
  right?: number | undefined
  bottom?: number | undefined
  left?: number | undefined
  width?: number | undefined
  height?: number | undefined
  zIndex?: number | undefined
}

function Lottie({
  data,
  top,
  right,
  bottom,
  left,
  width,
  height,
  zIndex,
}: LottieProps): ReactElement {
  const { width: windowWidth } = useWindowDimensions()

  return (
    <Container
      windowWidth={windowWidth}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      zIndex={zIndex}
    >
      <LottieElement
        options={{
          loop: true,
          autoplay: true,
          /* Lottie animationData is defined as any */
          /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
          animationData: data,
        }}
        width={getLottieScaledWidth(windowWidth, width)}
        height={getLottieScaledHeight(windowWidth, height)}
        isStopped={false}
        isPaused={false}
      />
    </Container>
  )
}

export default Lottie
