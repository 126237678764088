import { alpha, Box, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import { WarningMessageSeverityType } from './WarningMessage'

interface StyledContainerProps {
  $severity: WarningMessageSeverityType
  $enableBackground?: boolean
  $margin?: 'sm' | 'none'
}

export const Message = styled(Typography).attrs(() => ({
  variant: 'body1',
}))<{ isLink?: boolean }>`
  text-align: left;
  ${({ isLink }) =>
    isLink &&
    css`
      text-decoration: underline;
    `}
`
export const WarningLink = styled.span`
  text-decoration: underline;
`

export const Container = styled(Box)<StyledContainerProps>`
  ${({ theme, $enableBackground, $margin }) =>
    css`
      margin-top: 20px;
      text-align: center;
      border: 1px solid #ffffff;
      opacity: 0.5;
      padding: 6px 10px;
      border-radius: ${theme.borderRadius.md};
      .warning__icon {
        margin-right: 8px;
      }

      ${$enableBackground &&
      css`
        border-radius: ${theme.borderRadius.sm};
        background: ${alpha(theme.palette.primary.main, 0.2)};
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
      `}

      ${$margin === 'sm' &&
      css`
        margin: 4px 0;
      `}

        ${$margin === 'none' &&
      css`
        margin-top: 0px;
      `}
    `}
`
