import { strToWad } from '@hailstonelabs/big-number-utils'
import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { BigNumber, utils } from 'ethers'
import { router, TOKENS } from '../../config/contracts'
import { POOLS } from '../../config/contracts/pool'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { PLATYPUS_ROUTER02_ABI } from '../../config/contracts/wagmiAbis/PlatypusRouter02'
import { POOL__ABI } from '../../config/contracts/wagmiAbis/Pool'
import { POOLAVAX_ABI } from '../../config/contracts/wagmiAbis/PoolAvax'
import { useModal } from '../../contexts/ModalContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useSwap } from '../../contexts/SwapContext'
import { useUserPreference } from '../../contexts/UserPreferenceContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { HexString } from '../../interfaces/common'
import { ModalId } from '../../interfaces/Modal'
import { getDeadline, isUserDeniedTransaction } from '../../utils/contract'
import useRevertReason from '../useRevertReason'

interface Props {
  handleSwap: () => Promise<void>
}

const useSwapToken = (): Props => {
  const { chainId, account } = useWeb3()
  const getRevertReason = useRevertReason()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { showMessage } = useSnackbar()
  const { userPreference } = useUserPreference()

  const {
    isRouter,
    tokenAddressesPath,
    poolAddressesPath,
    poolSymbolForSwap,
    minimumReceived,
    fromTokenAmount,
    toTokenAmount,
    fromTokenSymbol,
    toTokenSymbol,
    resetAllAmount,
  } = useSwap()
  /**
   * Handle Unstake Lp
   * @param tokenSymbol
   * @param selectedPoolInstance
   * @param oldMasterPlatypus optionally use old MP contract instead of the latest MP when migrating/need to interact with old MP.
   * @param stakedLpInTermsOfLpBN optionally provide a staked lp amount to replace the one from stakeLpContext.
   */
  const handleSwap = async () => {
    let poolAddress: HexString | undefined | null
    if (poolSymbolForSwap) {
      poolAddress = POOLS[poolSymbolForSwap].getAddress(chainId)
    }
    let writeContractResult: WriteContractResult | undefined

    if (fromTokenSymbol && toTokenSymbol && account) {
      const fromTokenAddress = TOKENS[fromTokenSymbol].getAddress(chainId)
      const toTokenAddress = TOKENS[toTokenSymbol].getAddress(chainId)
      modalDispatch(openModal(ModalId.SWAP_WAIT_FOR_CONFIRMATION))
      try {
        if (isRouter) {
          const routerAddress = router.getAddress(chainId)
          if (routerAddress) {
            const config = await prepareWriteContract({
              address: routerAddress,
              abi: PLATYPUS_ROUTER02_ABI,
              functionName: 'swapTokensForTokens',
              args: [
                tokenAddressesPath,
                poolAddressesPath,
                utils.parseUnits(
                  fromTokenAmount,
                  TOKENS[fromTokenSymbol].decimals,
                ),
                minimumReceived,
                account,
                BigNumber.from(
                  await getDeadline(userPreference.transactionDeadline),
                ),
              ],
              chainId,
            })
            writeContractResult = await writeContract(config)
          }
        } else {
          // swap from eth
          if (
            fromTokenSymbol === TokenSymbol.AVAX &&
            toTokenAddress &&
            poolAddress
          ) {
            const config = await prepareWriteContract({
              address: poolAddress,
              abi: POOLAVAX_ABI,
              functionName: 'swapFromETH',
              args: [
                toTokenAddress,
                minimumReceived,
                account,
                BigNumber.from(
                  await getDeadline(userPreference.transactionDeadline),
                ),
              ],
              chainId,
              overrides: {
                // AVAX decimals is 18
                value: strToWad(fromTokenAmount),
              },
            })
            writeContractResult = await writeContract(config)
            // swap to eth
          } else if (
            toTokenSymbol === TokenSymbol.AVAX &&
            fromTokenAddress &&
            poolAddress
          ) {
            const config = await prepareWriteContract({
              address: poolAddress,
              abi: POOLAVAX_ABI,
              functionName: 'swapToETH',
              args: [
                fromTokenAddress,
                utils.parseUnits(
                  fromTokenAmount,
                  TOKENS[fromTokenSymbol].decimals,
                ),
                minimumReceived,
                account,
                BigNumber.from(
                  await getDeadline(userPreference.transactionDeadline),
                ),
              ],
              chainId,
            })
            writeContractResult = await writeContract(config)
          } else if (fromTokenAddress && toTokenAddress && poolAddress) {
            const config = await prepareWriteContract({
              address: poolAddress,
              abi: POOL__ABI,
              functionName: 'swap',
              args: [
                fromTokenAddress,
                toTokenAddress,
                utils.parseUnits(
                  fromTokenAmount,
                  TOKENS[fromTokenSymbol].decimals,
                ),
                BigNumber.from(minimumReceived),
                account,
                BigNumber.from(
                  await getDeadline(userPreference.transactionDeadline),
                ),
              ],
              chainId,
            })
            writeContractResult = await writeContract(config)
          }
        }
        if (writeContractResult) {
          const { hash, wait } = writeContractResult
          await wait()
          modalDispatch(
            openModal(ModalId.TRANSACTION_SUBMITTED, {
              transactionHashes: [hash],
              tokenSymbols: [toTokenSymbol],
            }),
          )
          showMessage('Successfully swapped.')
        } else {
          showMessage('Transaction failed.', 'warning')
          modalDispatch(openModal(ModalId.UNSET))
        }
      } catch (err) {
        if (!isUserDeniedTransaction(err)) {
          Sentry.setContext('contract_call', {
            name: 'pool.swap',
            to_token_symbol: toTokenSymbol,
            from_token_symbol: fromTokenSymbol,
            to_token_amount: fromTokenAmount,
            from_token_amount: toTokenAmount,
          })
          Sentry.captureException(err)
        }
        const reason = await getRevertReason(err)
        showMessage(reason || 'Transaction failed.', 'warning')
        modalDispatch(openModal(ModalId.UNSET))
      }
    }
    resetAllAmount()
  }
  return { handleSwap }
}

export default useSwapToken
