import { ContractInterface } from 'ethers'
import {
  MasterPlatypusBaseV1,
  MasterPlatypusBaseV2,
  MasterPlatypusV1,
  MasterPlatypusV3,
  MasterPlatypusV4,
} from '../../../../types/ethers-contracts'
import { AddressInterface, Contract } from '../Contract'
import { MASTER_PLATYPUS_BASE_V1_ABI } from '../wagmiAbis/MasterPlatypusBaseV1'
import { MASTER_PLATYPUS_BASE_V2_ABI } from '../wagmiAbis/MasterPlatypusBaseV2'
import { MASTER_PLATYPUS_V1_ABI } from '../wagmiAbis/MasterPlatypusV1'
import { MASTER_PLATYPUS_V3_ABI } from '../wagmiAbis/MasterPlatypusV3'
import { MASTER_PLATYPUS_V4_ABI } from '../wagmiAbis/MasterPlatypusV4'

export enum MasterPlatypusId {
  MP1 = 'MP1',
  MP3 = 'MP3',
  MP4 = 'MP4',
  BASE_MPV1 = 'BASE_MPV1',
  BASE_MPV2 = 'BASE_MPV2',
}

export class MasterPlatypus extends Contract<
  | MasterPlatypusV1
  | MasterPlatypusV3
  | MasterPlatypusBaseV1
  | MasterPlatypusV4
  | MasterPlatypusBaseV2,
  | typeof MASTER_PLATYPUS_V1_ABI
  | typeof MASTER_PLATYPUS_V3_ABI
  | typeof MASTER_PLATYPUS_BASE_V1_ABI
  | typeof MASTER_PLATYPUS_V4_ABI
  | typeof MASTER_PLATYPUS_BASE_V2_ABI
> {
  readonly masterPlatypusId: MasterPlatypusId
  readonly isBoostedAprSupported: boolean
  constructor(
    masterPlatypusId: MasterPlatypusId,
    addresses: AddressInterface,
    abi: ContractInterface,
    wagmiAbi:
      | typeof MASTER_PLATYPUS_V1_ABI
      | typeof MASTER_PLATYPUS_V3_ABI
      | typeof MASTER_PLATYPUS_BASE_V1_ABI
      | typeof MASTER_PLATYPUS_V4_ABI
      | typeof MASTER_PLATYPUS_BASE_V2_ABI,
    isBoostedAprSupported: boolean,
  ) {
    super(addresses, abi, wagmiAbi)
    this.masterPlatypusId = masterPlatypusId
    this.isBoostedAprSupported = isBoostedAprSupported
  }
}
