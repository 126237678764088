import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import LaunchIcon from '@mui/icons-material/Launch'
import { Box } from '@mui/material'
import React from 'react'
import FujiIcon from '../../assets/networks/fuji.svg'
import AppTypography from '../../components/AppTypography/AppTypography'
import TransparentButton from '../../components/buttons/TransparentButton/TransparentButton'
import NetworkIcon from '../../components/icons/NetworkIcon/NetworkIcon'
import Modal from '../../components/Modal/Modal'
import { Network } from '../../config/networks'
import { WalletId, WALLETS } from '../../config/wallet'
import { useModal } from '../../contexts/ModalContext'
import { ScrollableBox } from '../../globalStyles'
import useCopyAddress from '../../hooks/useCopyAddress'
import useWalletConnection from '../../hooks/wallet/useWalletConnection'
import useWalletFilterList from '../../hooks/wallet/useWalletFilterList'
import { getShortenedAddress } from '../../utils/getShortenedAddress'
import { WalletItem } from '../ConnectToWallet/ConnectToWalletModalContainer/ConnectToWalletModalContainer'
import {
  ButtonsContainer,
  DisconnectButton,
  HeaderContainer,
} from './ConnectedWalletModalContainer.elements'

interface Props {
  isOpen: boolean
  network: Network
  account: string
}

function ConnectedWalletModalContainer({
  isOpen,
  network,
  account,
}: Props): React.ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const copyAddress = useCopyAddress()
  const { otherSupportedWalletIds } = useWalletFilterList()
  const { disconnectWallet, connectedWalletId, connectWallet } =
    useWalletConnection()

  const viewAddressOnExplorer = () => {
    const explorerUrl = `${
      network.blockExplorerUrls[0] || ''
    }address/${account}`
    window.open(explorerUrl, '_blank')
  }
  return (
    <Modal isOpen={isOpen} onClose={() => modalDispatch(closeModal())}>
      <HeaderContainer>
        <Box display="flex" flexDirection="row">
          <NetworkIcon iconPath={FujiIcon} margin="0 4px 0 0" />
          <AppTypography variant="caption">{network?.chainName}</AppTypography>
        </Box>
        <Box mt="8px">
          <AppTypography variant="h5">
            {getShortenedAddress(account)}
          </AppTypography>
        </Box>
      </HeaderContainer>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <ButtonsContainer>
          <TransparentButton
            fullWidth
            style={{ margin: '6px 0' }}
            onClick={() => void copyAddress(account)}
          >
            <AppTypography variant="caption2">Copy Address</AppTypography>
            <ContentCopyIcon />
          </TransparentButton>
          <TransparentButton
            fullWidth
            style={{ margin: '6px 0' }}
            onClick={viewAddressOnExplorer}
          >
            <AppTypography variant="caption2">View on Snowtrace</AppTypography>
            <LaunchIcon />
          </TransparentButton>
        </ButtonsContainer>
      </Box>
      {connectedWalletId && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          m="24px 0"
        >
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <AppTypography variant="caption2">Connected with</AppTypography>
            <AppTypography variant="caption2" sx={{ display: 'flex' }}>
              <img
                src={WALLETS[connectedWalletId].icon}
                alt={WALLETS[connectedWalletId].id}
                width="16px"
                height="16px"
                style={{ margin: '0 4px' }}
              />
              {WALLETS[connectedWalletId]?.name}
            </AppTypography>
          </Box>
          <DisconnectButton customVariant="neutral" onClick={disconnectWallet}>
            <AppTypography variant="caption3">Disconnect</AppTypography>
          </DisconnectButton>
        </Box>
      )}
      <ScrollableBox>
        {otherSupportedWalletIds.map((walletId: WalletId) => (
          <WalletItem
            key={walletId}
            onClick={() => connectWallet(walletId)}
            walletId={walletId}
            iconPath={WALLETS[walletId].icon}
          />
        ))}
      </ScrollableBox>
    </Modal>
  )
}

export default ConnectedWalletModalContainer
