import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { ActionId } from '../../config/action'
import { vePtp } from '../../config/contracts'
import { VEPTP_V3_ABI } from '../../config/contracts/wagmiAbis/VePtpV3'
import { useModal } from '../../contexts/ModalContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useVePtp } from '../../contexts/VePtpContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'
import { isUserDeniedTransaction } from '../../utils/contract'
import useRevertReason from '../useRevertReason'

interface Props {
  handleUnequipNft: () => Promise<void>
}

const useUnequipNft = (): Props => {
  const { chainId } = useWeb3()
  const getRevertReason = useRevertReason()
  const {
    nft: { equippedId, setEquippedId },
  } = useVePtp()
  const {
    modalDispatch,
    actions: { openModal, closeModal },
  } = useModal()
  const { showMessage } = useSnackbar()

  async function handleUnequipNft() {
    let writeContractResult: WriteContractResult | undefined

    const equippedNftId = equippedId
    try {
      const vePtpAddress = vePtp.address[chainId]
      if (!vePtpAddress) return
      const config = await prepareWriteContract({
        address: vePtpAddress,
        abi: VEPTP_V3_ABI,
        functionName: 'unstakeNft',
        chainId,
      })
      writeContractResult = await writeContract(config)
      if (writeContractResult) {
        const { wait, hash } = writeContractResult
        await wait()
        modalDispatch(
          openModal(ModalId.TRANSACTION_SUBMITTED, {
            transactionHashes: [hash],
          }),
        )
        showMessage(`Successfully Unequipped NFT #${equippedNftId || ''}.`)
        setEquippedId(null)
      } else {
        showMessage('Transaction failed.', 'warning')
        modalDispatch(closeModal())
      }
    } catch (err) {
      if (!isUserDeniedTransaction(err)) {
        Sentry.setContext('contract_call', {
          name: 'Unequip_nft',
          id: equippedNftId,
        })
        Sentry.captureException(err)
      }
      const reason = await getRevertReason(err, undefined, ActionId.UNEQUIP_NFT)
      showMessage(reason || 'Transaction failed.', 'warning')
      modalDispatch(closeModal())
    }
  }
  return { handleUnequipNft }
}

export default useUnequipNft
