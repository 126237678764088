import {
  getCommifiedFormat,
  getDpFormat,
} from '@hailstonelabs/big-number-utils'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import React, { ReactElement, useEffect, useState } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import Table from '../../../components/Table/Table'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useBalance } from '../../../contexts/BalanceContext'
import { useModal } from '../../../contexts/ModalContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { useVotingData } from '../../../contexts/VotingDataContext'
import { StyledDivider } from '../../../globalStyles'
import useClaimBribeRewards from '../../../hooks/voting/useClaimBribeRewards'
import useVote from '../../../hooks/voting/useVote'
import { ModalId } from '../../../interfaces/Modal'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import GaugeSelectionModal from './GaugeSelectionModal/GaugeSelectionModal'
import {
  ButtonsContainer,
  Container,
  FlexItem,
} from './UserVoteAllocationTable.elements'
import { useUserVoteAllocationTable } from './UserVoteAllocationTableContext'
import useUserVoteAllocationTableData from './useUserVoteAllocationTableData'
import VoteWaitForConfirmationModal from './VoteWaitForConfirmationModal/VoteWaitForConfirmationModal'

/**
 * Main Component
 */
function UserVoteAllocationTable(): ReactElement {
  const {
    voteWeightPercentageInputs,
    votedGaugeList,
    votedAndAddedGaugeList,
    editedGaugeList,
    isInEditMode,
    totalVoteWeightPercentageOfTheTable,
    exitEditMode,
    enterEditMode,
    resetVoteWeightPercentageInputs,
  } = useUserVoteAllocationTable()
  const { modalState } = useModal()
  const { vePtp } = useVePtp()
  const {
    user: { hasEarnedBribeRewards, earnedBribeRewardsTotalInUsd },
  } = useVotingData()
  const {
    handleVote,
    isDone: isSuccessfullyVoted,
    isWaiting: isVoting,
  } = useVote()
  const { handleClaim } = useClaimBribeRewards()
  const { account } = useNetwork()
  const [openGaugeSelectionModal, setOpenGaugeSelectionModal] = useState(false)
  const { tableHeadConfig, tableData } = useUserVoteAllocationTableData()
  const { isTokenPriceFetched } = useBalance()
  /**
   * Flags
   */
  const hasPreviouslyVotedGauges = votedGaugeList.length > 0
  const hasNoAccountConnected = !account
  const hasNoVePtp = !vePtp.balance.isMoreThanZero

  useEffect(() => {
    if (isSuccessfullyVoted) {
      exitEditMode()
    }
  }, [exitEditMode, isSuccessfullyVoted])

  return (
    <Container>
      <StyledDivider $colored margin="12px 0">
        <TokenIcon tokenSymbol={TokenSymbol.VEPTP} margin="0 8px 0 0" /> Your
        vote allocation
      </StyledDivider>
      <Table scrollableTable stickyLastColumn maxWidth="1000px" width="100%">
        <Table.Head>
          <Table.Row>
            {tableHeadConfig.map((headData, index) => (
              <Table.Data key={index} {...headData.style}>
                {headData.data}
              </Table.Data>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {votedAndAddedGaugeList.length > 0 ? (
            tableData.map((rowData) => (
              <Table.Row key={rowData.id} id={rowData.id}>
                {rowData.data.map((data, index) => (
                  <Table.Data key={index} {...tableHeadConfig[index]?.style}>
                    {data}
                  </Table.Data>
                ))}
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              {tableHeadConfig.map((_, index) => (
                <Table.Data key={index} {...tableHeadConfig[index]?.style}>
                  -
                </Table.Data>
              ))}
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {/** Extra Info */}
      <FlexItem>
        <AppTypography variant="body2" transparent>
          Earned bribe rewards will be automatically claimed on voting
        </AppTypography>
      </FlexItem>
      <FlexItem>
        <AppTypography variant="subtitle2">
          <AppTypography transparent component="span">
            Total claimable rewards&nbsp;
          </AppTypography>
          <AppTypography variant="h6" component="span">
            <TooltipNum amount={earnedBribeRewardsTotalInUsd} leftSymbol="$">
              $
              {showDashIfNecessary(
                !isTokenPriceFetched,
                getCommifiedFormat(earnedBribeRewardsTotalInUsd),
              )}
            </TooltipNum>
          </AppTypography>
        </AppTypography>
        {isInEditMode && (
          <AppTypography variant="subtitle2">
            <AppTypography transparent component="span">
              Total vote&nbsp;
            </AppTypography>
            <AppTypography variant="h6" component="span">
              {getDpFormat(totalVoteWeightPercentageOfTheTable.toString())}%
            </AppTypography>
          </AppTypography>
        )}
      </FlexItem>
      {/** CTA Buttons */}
      <FlexItem>
        <ButtonsContainer>
          <AppButton
            customVariant="neutral"
            onClick={() => setOpenGaugeSelectionModal(true)}
            disabled={hasNoAccountConnected || hasNoVePtp || isVoting}
          >
            <AddCircleOutlineIcon style={{ fontSize: '18px' }} />
            &nbsp;Add a gauge
          </AppButton>
          {!isInEditMode && (
            <AppButton
              customVariant="neutral"
              onClick={() => void handleClaim()}
              disabled={
                hasNoAccountConnected || hasNoVePtp || !hasEarnedBribeRewards
              }
            >
              Claim all rewards
            </AppButton>
          )}
        </ButtonsContainer>
        {isInEditMode && (
          <ButtonsContainer className="UserVoteAllocationTable__middle-buttons">
            <AppButton
              customVariant="neutral"
              onClick={() => {
                resetVoteWeightPercentageInputs()
              }}
              disabled={isVoting}
            >
              Reset all
            </AppButton>
            <AppButton
              customVariant="neutral"
              onClick={() => {
                exitEditMode()
                resetVoteWeightPercentageInputs()
              }}
              disabled={isVoting}
            >
              Cancel
            </AppButton>
          </ButtonsContainer>
        )}
        <ButtonsContainer>
          {hasPreviouslyVotedGauges && !isInEditMode ? (
            <AppButton style={{ minWidth: '120px' }} onClick={enterEditMode}>
              Modify Votes
            </AppButton>
          ) : (
            <AppButton
              style={{ minWidth: '120px' }}
              hasSpinner={isVoting}
              disabled={
                !isInEditMode ||
                // disable vote button when users didn't change their votes
                editedGaugeList.length <= 0 ||
                isVoting
              }
              onClick={() => void handleVote(voteWeightPercentageInputs)}
            >
              Vote
            </AppButton>
          )}
        </ButtonsContainer>
      </FlexItem>
      {openGaugeSelectionModal && (
        <GaugeSelectionModal
          isOpen={openGaugeSelectionModal}
          onClose={() => setOpenGaugeSelectionModal(false)}
        />
      )}
      {modalState.currentModalId === ModalId.VOTE_WAIT_FOR_CONFIRMATION && (
        <VoteWaitForConfirmationModal isOpen />
      )}
    </Container>
  )
}

export default UserVoteAllocationTable
