import { AirdropId } from '.'

interface Settings {
  //hasApr boolean determine whether the airdrop has apr
  hasApr: boolean
  //enabled boolean determine whether the airdrop is in active
  enabled: boolean
}

export class Airdrops {
  readonly id: AirdropId
  readonly name: string
  readonly icon: string
  readonly url: string
  readonly settings: Settings

  constructor(
    id: AirdropId,
    name: string,
    icon: string,
    url: string,
    settings: Settings,
  ) {
    this.id = id
    this.name = name
    this.icon = icon
    this.url = url
    this.settings = settings
  }
}
