import * as Sentry from '@sentry/react'
import { useSnackbar } from '../contexts/SnackbarContext'

function useCopyAddress(): (address: string) => Promise<void> {
  const { showMessage } = useSnackbar()
  const copyAddress = async (address: string) => {
    try {
      await navigator.clipboard.writeText(address)
      showMessage('Address copied to clipboard.')
    } catch (err) {
      Sentry.setContext('event', {
        name: 'copy_address_to_clipboard',
        address: address,
      })
      Sentry.captureException(err)
      showMessage('Unable to copy the address.', 'warning')
    }
  }

  return copyAddress
}

export default useCopyAddress
