import { ERC20 } from '../../../../types/ethers-contracts'
import { tokenIconPaths } from '../../../assets/tokens'
import ERC20ABI from '../abis/ERC20.json'
import { AddressInterface, Contract } from '../Contract'
import { PoolSymbol } from '../pool/poolSymbol'
import { ERC20_ABI } from '../wagmiAbis/ERC20'
import { SwapGroupSymbol, TokenSymbol } from './tokenSymbol'
export enum FetchPriceMethod {
  COINGECKO_COINMARKETCAP = 'COINGECKO_COINMARKETCAP',
  PLATYPUS = 'PLATYPUS',
}
interface PriceFetchingConfigType {
  method: FetchPriceMethod
  payload: unknown
}
export interface GeckoOrCmcPriceFetchingConfig extends PriceFetchingConfigType {
  method: FetchPriceMethod.COINGECKO_COINMARKETCAP
  payload: {
    geckoId: string
    // token was delisted/not supported if cmcId = 0
    cmcId: number
  }
}
export interface PlatypusPriceFetchingConfig extends PriceFetchingConfigType {
  method: FetchPriceMethod.PLATYPUS
  payload: {
    toTokenSymbol: TokenSymbol
    poolSymbol: PoolSymbol
  }
}

export class Token extends Contract<ERC20, typeof ERC20_ABI> {
  // swapGroupSymbol detemines toTokenSymbol in swapping
  readonly swapGroupSymbol: SwapGroupSymbol
  readonly priceFetchingConfig:
    | GeckoOrCmcPriceFetchingConfig
    | PlatypusPriceFetchingConfig
  readonly isTpAvaxToken: boolean
  readonly decimals: number
  readonly name: string
  readonly symbol: TokenSymbol
  readonly icon: string
  constructor(
    name: string,
    symbol: TokenSymbol,
    address: AddressInterface,
    decimals: number,
    swapGroupSymbol: SwapGroupSymbol,
    priceFetchingConfig:
      | GeckoOrCmcPriceFetchingConfig
      | PlatypusPriceFetchingConfig,
  ) {
    super(address, ERC20ABI, ERC20_ABI)
    this.symbol = symbol
    this.decimals = decimals
    this.name = name
    this.icon = tokenIconPaths[symbol]
    this.swapGroupSymbol = swapGroupSymbol
    this.isTpAvaxToken =
      this.swapGroupSymbol === SwapGroupSymbol.AVAX &&
      this.symbol !== TokenSymbol.WAVAX &&
      this.symbol !== TokenSymbol.AVAX
    this.priceFetchingConfig = priceFetchingConfig
  }
}
