import { alpha, Box, Input, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

export const InputUnitLabel = styled(Typography)`
  opacity: 0.5;
`
export const InputField = styled(Input)<{
  textalign: string
  inputunitlabel?: string
}>`
  &.MuiInput-root {
    width: 100%;
  }
  .MuiInput-input {
    outline: none;
    border: none;
    background: none;
    width: 100%;
    font-size: 1.125rem;
    padding: 0 15px;
    text-align: ${(props) => props.textalign};
    ${(props) =>
      props?.inputunitlabel &&
      css`
        padding-right: 5px;
      `}
  }
`
export const InputChildrenWrapper = styled(Box)`
  padding: 0 15px;
  margin: 8px 0;
`
export const InputContainer = styled(Box)`
  position: relative;
  border-radius: 10px;
  width: 100%;
  margin: 10px 0;
  min-height: 50px;
`

// styling for TokenInputLabel
export const Label = styled(Typography)`
  ${({ theme }) => css`
    font-weight: 400;
    color: ${alpha(theme.palette.common.white, 0.5)};

    svg {
      color: ${theme.palette.common.white};
    }
  `}
`
export const InputLabelContainer = styled(Box)`
  .input-label__trailing {
    text-align: right;
  }
`
