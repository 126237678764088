import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'

export const Container = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    border: 1px solid ${theme.palette.primary['A200']};
    background: ${alpha(theme.palette.primary['500'], 0.2)};
    border-radius: 20px;
    padding: 24px;
    margin-top: 24px;
  `}
`

export const Header = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;

    .MigrationCard__description {
      margin-top: 4px;
    }
    .MigrationCard__migrate-button {
      height: 40px;
      width: 160px;
      margin: auto 0;
      margin-left: 10px;
    }

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      .MigrationCard__migrate-button {
        margin: 0 auto;
        margin-top: 10px;
      }
    }
  `}
`
export const DataContainer = styled(Box)`
  ${({ theme }) => css`
    background: ${alpha(theme.palette.primary['500'], 0.2)};
    border-radius: 10px;
    margin-top: 12px;
    padding: 12px;
    display: grid;
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-gap: 4px 0;
  `}
`
