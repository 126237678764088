import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import WaitForConfirmationModal from '../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import { useModal } from '../../../contexts/ModalContext'
import MemorizedNftCardContainer from '../../MemorizedNftCardContainer/MemorizedNftCardContainer'
interface Props {
  isOpen: boolean
}

function ChangeNftWaitForConfirmationModal({ isOpen }: Props): ReactElement {
  const {
    modalDispatch,
    modalState,
    actions: { closeModal },
  } = useModal()
  const handleCloseModal = () => {
    modalDispatch(closeModal())
  }

  const currentNftId = modalState.nftData?.current?.id
  const changeToNftId = modalState.nftData?.changeTo?.id

  const isToEquipNft = Boolean(!currentNftId && changeToNftId)
  const isToUnequipNft = Boolean(
    (currentNftId && !changeToNftId) || currentNftId === changeToNftId,
  )
  const isToChangeNft = Boolean(
    currentNftId && changeToNftId && currentNftId !== changeToNftId,
  )

  let subtitle = ''
  if (isToEquipNft) subtitle = 'Equip NFT'
  if (isToUnequipNft) subtitle = 'Unequip NFT'
  if (isToChangeNft) subtitle = 'Change NFT'
  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="600px"
      subtitle={subtitle}
    >
      {isToChangeNft && (
        <>
          <MemorizedNftCardContainer
            id={currentNftId}
            variant="small"
            enableBgColor
            disableActiveColor
          />
          <Box display="flex" justifyContent="center">
            <KeyboardArrowDownRoundedIcon fontSize="large" />
          </Box>
        </>
      )}
      {isToUnequipNft && (
        <MemorizedNftCardContainer
          id={currentNftId}
          variant="small"
          enableBgColor
        />
      )}
      {!isToUnequipNft && changeToNftId && (
        <MemorizedNftCardContainer
          id={changeToNftId}
          variant="small"
          disableActiveText
          enableBgColor
        />
      )}
    </WaitForConfirmationModal>
  )
}

export default ChangeNftWaitForConfirmationModal
