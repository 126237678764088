import { Typography, TypographyProps } from '@mui/material'
import React, { ReactElement } from 'react'
import Modal from '../../../components/Modal/Modal'
import TabBar from '../../../components/TabBar/TabBar'
import { useModal } from '../../../contexts/ModalContext'
import {
  TabId,
  useVePtpBoosterCalculator,
} from '../../../contexts/VePtpBoosterCalculatorContext'
import { ScrollableBox } from '../../../globalStyles'
import CalculatedResult from './CalculatedResult'
import MyStakedAndLockedPtpContainer from './MyStakedAndLockedPtpContainer'
import MyStakedDepositContainer from './MyStakedDepositContainer'
import MyVePtpContainer from './MyVePtpContainer'
import TotalVePtpSupplyInput from './TotalVePtpSupplyInput'

interface Props {
  isOpen: boolean
}

function VePtpBoosterCalculatorModal({ isOpen }: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const handleModalClose = () => {
    modalDispatch(closeModal())
  }

  const {
    currentTabId,
    actions: { handleTabChange },
  } = useVePtpBoosterCalculator()

  const renderTabComponent = () => {
    switch (currentTabId) {
      case TabId.VEPTP:
        return <MyVePtpContainer />
      case TabId.PTP:
        return <MyStakedAndLockedPtpContainer />
      default:
        return <MyVePtpContainer />
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      TopBarLabelComponent={
        <Typography variant="h5" sx={{ width: '100%', textAlign: 'center' }}>
          Booster Calculator
        </Typography>
      }
    >
      <ScrollableBox>
        <MyStakedDepositContainer />
        <CalculatorTabBar
          currentTabId={currentTabId}
          onTabChange={handleTabChange}
        />
        {renderTabComponent()}
        <TotalVePtpSupplyInput />
      </ScrollableBox>
      <CalculatedResult />
    </Modal>
  )
}

export default VePtpBoosterCalculatorModal

const labelProps: TypographyProps = { variant: 'subtitle2' }

interface CalculatorTabBarProps {
  currentTabId: TabId
  onTabChange: (tabId: TabId) => void
}

function CalculatorTabBar({
  currentTabId,
  onTabChange,
}: CalculatorTabBarProps) {
  const handleClickTab = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    tabId: TabId,
  ) => {
    e.preventDefault()
    onTabChange(tabId)
  }
  return (
    <TabBar numOfTabs={2} width="230px" height="40px" margin="12px auto">
      <TabBar.Tab
        onClick={(e) => handleClickTab(e, TabId.VEPTP)}
        label={TabId.VEPTP}
        activeLink={currentTabId === TabId.VEPTP}
        labelProps={labelProps}
      />
      <TabBar.Tab
        onClick={(e) => handleClickTab(e, TabId.PTP)}
        label={TabId.PTP}
        activeLink={currentTabId === TabId.PTP}
        labelProps={labelProps}
      />
    </TabBar>
  )
}
