import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import ExportIcon from '../../../assets/icons/export-icon.svg'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import Modal from '../../../components/Modal/Modal'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { TP_YIELDS } from '../../../config/TpYield'
import { WalletId, WALLETS } from '../../../config/wallet'
import { ExternalLink } from '../../../globalStyles'
import useBreakpoints from '../../../hooks/useBreakpoints'
import useAddTokenToWallet from '../../../hooks/wallet/useAddTokenToWallet'
import { Item } from './PtpSelectTradeModal.elements'

interface PtpTradeModalProps {
  isOpen: boolean
  onClose: () => void
}
function PtpSelectTradeModal({
  isOpen,
  onClose,
}: PtpTradeModalProps): ReactElement {
  const addTokenToWallet = useAddTokenToWallet()
  const { isTabletSm } = useBreakpoints()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <AppTypography
        variant="h6"
        centerContent
        justifyContent="center"
        style={{ marginBottom: '8px' }}
      >
        Trade <TokenIcon tokenSymbol={TokenSymbol.PTP} margin="0 10px" />
        PTP
      </AppTypography>

      {Object.values(TP_YIELDS)
        .filter((tpYield) => tpYield.protocolTokenSymbol === TokenSymbol.PTP)
        .map((tpYield) => {
          return (
            <ExternalLink
              key={tpYield.symbol}
              href={tpYield.urls.swap}
              disableUnderline
            >
              <Item>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <img
                    src={tpYield.icon}
                    width="24px"
                    height="24px"
                    style={{ marginRight: '8px' }}
                  />
                  <AppTypography variant="body1">{tpYield.name}</AppTypography>
                </Box>
                <img src={ExportIcon} width="16px" height="16px" />
              </Item>
            </ExternalLink>
          )
        })}

      {!isTabletSm && (
        <>
          {/* spacer */}
          <Box style={{ marginBottom: '40px' }}></Box>
          <AppButton
            onClick={() => void addTokenToWallet(TokenSymbol.PTP)}
            customVariant="neutral"
            style={{ justifyContent: 'space-between', padding: '12px' }}
          >
            <AppTypography variant="caption2">
              Add PTP to MetaMask
            </AppTypography>
            <img
              src={WALLETS[WalletId.METAMASK].icon}
              width="20px"
              height="20px"
            />
          </AppButton>
        </>
      )}
    </Modal>
  )
}

export default PtpSelectTradeModal
