import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import React, { ReactElement } from 'react'
import AppTypography from '../../../../components/AppTypography/AppTypography'
import InfoBox from '../../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../../components/InfoBox/TooltipNum'
import { LP_TOKENS, POOLS } from '../../../../config/contracts'
import { TokenSymbol } from '../../../../config/contracts/token/tokenSymbol'
import { usePoolInput } from '../../../../contexts/PoolInputContext'
import { useStakeLpData } from '../../../../contexts/StakeLpDataContext'
import { useUnstakeLpContent } from '../../../../contexts/UnstakeLpContentContext'
import PoolAutoClaimInfoBoxItem from '../PoolAutoClaimInfoBoxItem/PoolAutoClaimInfoBoxItem'

interface Props {
  tokenSymbol: TokenSymbol
}
function PoolUnstakeModalInfoBox({ tokenSymbol }: Props): ReactElement {
  const { rewards } = useStakeLpData()
  const { selectedPoolSymbol } = usePoolInput()
  const actualRewardsEarned = POOLS[selectedPoolSymbol].getRewardsEarned(
    rewards,
    tokenSymbol,
  )
  const tokenSymbolForDisplay =
    LP_TOKENS[selectedPoolSymbol][tokenSymbol]?.tokenSymbolForDisplay ||
    tokenSymbol
  const { remainingStakedAmountStr, unstakeLpAmountStr } = useUnstakeLpContent()
  return (
    <InfoBox>
      <InfoBox.Item>
        <AppTypography variant="caption">Amount unstaking</AppTypography>
        <AppTypography variant="caption">
          <b>
            <TooltipNum rightSymbol={tokenSymbol} amount={unstakeLpAmountStr}>
              {getCommifiedFormat(unstakeLpAmountStr)} {tokenSymbolForDisplay}
            </TooltipNum>
          </b>
        </AppTypography>
      </InfoBox.Item>
      <InfoBox.Item>
        <AppTypography variant="caption">Remaining Staked</AppTypography>
        <AppTypography variant="caption">
          <b>
            <TooltipNum
              rightSymbol={tokenSymbol}
              amount={remainingStakedAmountStr}
            >
              {getCommifiedFormat(remainingStakedAmountStr)}{' '}
              {tokenSymbolForDisplay}
            </TooltipNum>
          </b>
        </AppTypography>
      </InfoBox.Item>

      <PoolAutoClaimInfoBoxItem actualRewardsEarned={actualRewardsEarned} />
    </InfoBox>
  )
}

export default PoolUnstakeModalInfoBox
