import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { BigNumber } from 'ethers'
import { MasterPlatypus } from '../../config/contracts/masterPlatypus/MasterPlatypus'
import { POOLS } from '../../config/contracts/pool'
import { PoolSymbol } from '../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { MASTER_PLATYPUS_V3_ABI } from '../../config/contracts/wagmiAbis/MasterPlatypusV3'
import { useModal } from '../../contexts/ModalContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'
import { isUserDeniedTransaction } from '../../utils/contract'
import useRevertReason from '../useRevertReason'
interface Props {
  handleStakeLp: (
    tokenSymbol: TokenSymbol,
    poolSymbol: PoolSymbol,
    masterPlatypus: MasterPlatypus,
    stakableLpInTermsOfLpBN: BigNumber,
    isTransactionSubmittedShown?: boolean,
  ) => Promise<string | undefined>
}

const useStakeLp = (): Props => {
  const { chainId } = useWeb3()

  const getRevertReason = useRevertReason()

  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { showMessage } = useSnackbar()

  /**
   * Handle Stake Lp
   * @param assetTokenSymbol
   * @param selectedPoolInstance
   * @param masterPlatypus
   * @param stakableLpInTermsOfLpBN
   */
  async function handleStakeLp(
    assetTokenSymbol: TokenSymbol,
    poolSymbol: PoolSymbol,
    masterPlatypus: MasterPlatypus,
    stakableLpInTermsOfLpBN: BigNumber,
    isTransactionSubmittedShown = true,
  ) {
    let transactionHash: string | undefined
    let writeContractResult: WriteContractResult | undefined
    try {
      const masterPlatypusAddress = masterPlatypus.address[chainId]
      const pid = POOLS[poolSymbol].assets[assetTokenSymbol]?.pids[chainId]
      if (!masterPlatypusAddress || pid === undefined) return
      modalDispatch(
        openModal(ModalId.POOL_STAKE_WAIT_FOR_CONFIRMATION, {
          tokenSymbols: [assetTokenSymbol],
          poolSymbols: [poolSymbol],
        }),
      )
      const pidBN = BigNumber.from(pid)

      const config = await prepareWriteContract({
        address: masterPlatypusAddress,
        abi: MASTER_PLATYPUS_V3_ABI,
        functionName: 'deposit',
        args: [pidBN, stakableLpInTermsOfLpBN],
        chainId,
      })
      writeContractResult = await writeContract(config)
      if (writeContractResult) {
        const { hash, wait } = writeContractResult
        await wait()
        transactionHash = hash
        if (isTransactionSubmittedShown) {
          modalDispatch(
            openModal(ModalId.TRANSACTION_SUBMITTED, {
              transactionHashes: [hash],
            }),
          )
        }
        showMessage(`Successfully staked ${assetTokenSymbol}.`)
      } else {
        showMessage('Transaction failed.', 'warning')
        modalDispatch(openModal(ModalId.UNSET))
      }
    } catch (err) {
      if (!isUserDeniedTransaction(err)) {
        Sentry.setContext('contract_call', {
          name: 'deposit_lp',
          token: assetTokenSymbol,
        })
        Sentry.captureException(err)
      }
      const reason = await getRevertReason(err)
      showMessage(reason || 'Transaction failed.', 'warning')
      modalDispatch(openModal(ModalId.UNSET))
    }
    return transactionHash
  }
  return { handleStakeLp }
}

export default useStakeLp
