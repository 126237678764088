import {
  getCommifiedFormat,
  getDpFormat,
  isParsableString,
  strToWad,
} from '@hailstonelabs/big-number-utils'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { alpha, Box, Typography, useTheme } from '@mui/material'
import { constants, utils } from 'ethers'
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import AppTokenInput from '../../../components/TokenInput/AppTokenInput'
import { LP_TOKENS, POOLS, TOKENS } from '../../../config/contracts'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { WAD_DECIMALS } from '../../../constants'
import { useBalance } from '../../../contexts/BalanceContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import { usePoolInput } from '../../../contexts/PoolInputContext'
import { usePools } from '../../../contexts/PoolsContext'
import { useUserPreference } from '../../../contexts/UserPreferenceContext'
import { useWeb3 } from '../../../contexts/Web3Context'
import useDebounce from '../../../hooks/useDebounce'
import {
  getMaxWithdrawablePercentage,
  lptokenBNToToken,
} from '../../../utils/pool'
import SelectTokenModalContainer from '../../SelectTokenModalContainer/SelectTokenModalContainer'
import {
  StyledSelectTokenButton,
  WithdrawInOtherTokenButton,
} from './PoolWithdrawalTokenInputsContainer.elements'

function PoolWithdrawalTokenInputsContainer(): ReactElement {
  const { chainId } = useWeb3()
  const { readOnlyProvider } = useNetwork()
  const { userPreference } = useUserPreference()
  const [clickedMax, setClickedMax] = useState(false)
  const theme = useTheme()
  const [openWithdrawInOtherTokenModal, setOpenWithdrawInOtherTokenModal] =
    useState(false)
  const {
    withdrawMaximumPercentage,
    withdrawLiquidity,
    selectedPoolSymbol,
    selectedAssetTokenSymbol,
    actions: {
      toggleIsWithdrawInOtherTokens,
      updateWithdrawMaximumPercentage,
      updateToTokenSymbolFromWithdrawLiquidity,
      updateTokenSymbolFromWithdrawLiquidity,
      updateWithdrawalPercentage,
    },
  } = usePoolInput()
  const { assets, liabilities, lpSuppliesBN } = usePools()
  const { lpTokenAmounts, tokenAmounts } = useBalance()
  // An amount that is used for UI
  const [withdrawalPercentageInputted, setWithdrawalPercentageInputted] =
    useState('')
  // An amount that is used to trigger updates
  const debouncedWithdrawalPercentageInputted = useDebounce(
    withdrawalPercentageInputted,
  )
  const filteredTokenSymbols = useMemo(() => {
    const pool = POOLS[selectedPoolSymbol]
    const filteredAssetsSymbols = pool
      .getAssets()
      .filter((asset) => {
        if (selectedAssetTokenSymbol) {
          return asset.tokenSymbol !== selectedAssetTokenSymbol
        }
        return false
      })
      .map((asset) => asset.tokenSymbol)
    return filteredAssetsSymbols
  }, [selectedPoolSymbol, selectedAssetTokenSymbol])
  let deposited = ''
  let balance = ''
  if (selectedAssetTokenSymbol && withdrawLiquidity.tokenSymbol) {
    const withdrawTokenDecimals = TOKENS[selectedAssetTokenSymbol].decimals
    balance = tokenAmounts[withdrawLiquidity.tokenSymbol]
    deposited = lptokenBNToToken(
      utils.parseUnits(
        lpTokenAmounts[selectedPoolSymbol][selectedAssetTokenSymbol],
        withdrawTokenDecimals,
      ),
      lpSuppliesBN[selectedPoolSymbol][selectedAssetTokenSymbol],
      utils.parseUnits(
        liabilities[selectedPoolSymbol][selectedAssetTokenSymbol],
        withdrawTokenDecimals,
      ),
      selectedAssetTokenSymbol,
    )
  }

  const toggleWithdrawInOtherTokens = () => {
    // reset toToken and withdrawalPercentage here instead of in an useEffect to make sure the update is right before the update of isWithdrawInOtherTokens.
    // Otherwise, users may see the old states on UI before the updated states come in.
    if (withdrawLiquidity.isWithdrawInOtherTokens) {
      // toggle from true to false
      updateToTokenSymbolFromWithdrawLiquidity(withdrawLiquidity.tokenSymbol)
    } else {
      // update token to uncheck token
      if (!selectedAssetTokenSymbol) return
      const uncheckedTokenSymbol =
        LP_TOKENS[selectedPoolSymbol][selectedAssetTokenSymbol]
          ?.tokenSymbolForUnchecked
      if (!uncheckedTokenSymbol) return
      updateTokenSymbolFromWithdrawLiquidity(uncheckedTokenSymbol)
      // toggle from false to true
      updateToTokenSymbolFromWithdrawLiquidity(null)
    }
    setWithdrawalPercentageInputted('')

    // toggle the state
    toggleIsWithdrawInOtherTokens()
  }
  const handleClickMaxButton = () => {
    setClickedMax(true)
    // get max lp token amount
    setWithdrawalPercentageInputted(withdrawMaximumPercentage)
  }

  const handleInputChange = useCallback(
    (value: string) => {
      setClickedMax(false)
      // if users press space bar, or empty input, or init
      if (value === '' || value === ' ') {
        setWithdrawalPercentageInputted('')
        return
      }

      // if users enter non number
      if (!isParsableString(value, WAD_DECIMALS, true)) {
        return
      }

      const percentWAD = strToWad(value)
      // check if value is from 0 to 100WAD or max wad
      if (
        percentWAD.gte(constants.Zero) &&
        percentWAD.lte(strToWad(withdrawMaximumPercentage))
      ) {
        setWithdrawalPercentageInputted(value)
      }
    },
    [withdrawMaximumPercentage],
  )
  const handleSelectToken = (
    // index: number,
    wantedTokenSymbol: TokenSymbol | null,
  ) => {
    // clear input
    if (wantedTokenSymbol) {
      handleInputChange('')
      updateToTokenSymbolFromWithdrawLiquidity(wantedTokenSymbol)
    }
  }

  useEffect(() => {
    // calculate getMaxWithdrawablePercentage
    const initMaxWithdrawablePercentage = async () => {
      try {
        if (
          withdrawLiquidity.tokenSymbol &&
          selectedAssetTokenSymbol &&
          withdrawLiquidity.toTokenSymbol
        ) {
          const _maxWithdrawablePercentage = await getMaxWithdrawablePercentage(
            chainId,
            withdrawLiquidity.tokenSymbol,
            lpTokenAmounts[selectedPoolSymbol][selectedAssetTokenSymbol],
            withdrawLiquidity.toTokenSymbol,
            assets[selectedPoolSymbol][withdrawLiquidity.toTokenSymbol],
            liabilities[selectedPoolSymbol][withdrawLiquidity.toTokenSymbol],
            POOLS[selectedPoolSymbol].get(chainId, readOnlyProvider),
          )
          updateWithdrawMaximumPercentage(_maxWithdrawablePercentage)
        }
      } catch (err) {
        console.error(err)
      }
    }
    void initMaxWithdrawablePercentage()
  }, [
    assets,
    chainId,
    liabilities,
    lpTokenAmounts,
    readOnlyProvider,
    selectedAssetTokenSymbol,
    selectedPoolSymbol,
    withdrawLiquidity.tokenSymbol,
    updateWithdrawMaximumPercentage,
    withdrawLiquidity.toTokenSymbol,
  ])

  // when debouncedWithdrawalPercentageInputted changes, update it to PoolInputContext
  useEffect(() => {
    updateWithdrawalPercentage(debouncedWithdrawalPercentageInputted)
  }, [debouncedWithdrawalPercentageInputted, updateWithdrawalPercentage])
  return (
    <>
      <AppTokenInput
        onChange={handleInputChange}
        value={
          clickedMax
            ? getDpFormat(withdrawalPercentageInputted)
            : withdrawalPercentageInputted
        }
      >
        <AppTokenInput.LabelItem
          leadingLabel={
            withdrawLiquidity.tokenSymbol === null ? (
              'Deposited: 0.0'
            ) : (
              <AppTokenInput.LabelItemInnerContainer>
                <AppTokenInput.Label>Deposited:&nbsp;</AppTokenInput.Label>
                <TooltipNum
                  amount={deposited}
                  rightSymbol={
                    withdrawLiquidity.tokenSymbolForDisplay || undefined
                  }
                >
                  {getCommifiedFormat(deposited)}&nbsp;
                  {withdrawLiquidity.tokenSymbolForDisplay}
                </TooltipNum>
              </AppTokenInput.LabelItemInnerContainer>
            )
          }
          trailingLabel={
            withdrawLiquidity.tokenSymbol === null ? (
              'Balance: 0.0'
            ) : (
              <AppTokenInput.LabelItemInnerContainer
                style={{ justifyContent: 'flex-end' }}
              >
                <AppTokenInput.Label>Balance:&nbsp;</AppTokenInput.Label>
                <TooltipNum
                  rightSymbol={withdrawLiquidity.tokenSymbol as string}
                  amount={balance}
                >
                  {getCommifiedFormat(balance)} {withdrawLiquidity.tokenSymbol}
                </TooltipNum>
              </AppTokenInput.LabelItemInnerContainer>
            )
          }
        />
        <AppTokenInput.InnerContainer
          bgcolor={
            withdrawLiquidity.toTokenSymbol
              ? alpha(theme.palette.primary[500], 0.2)
              : theme.palette.common.black
          }
          mt="12px"
          flexDirection="column"
        >
          {withdrawLiquidity.isWithdrawInOtherTokens && (
            <StyledSelectTokenButton
              token={withdrawLiquidity.toTokenSymbolForDisplay}
              onSelectToken={() => setOpenWithdrawInOtherTokenModal(true)}
            />
          )}
          <Box display="flex" flexDirection="row">
            <AppTokenInput.InputField
              placeholder="0.0"
              inputUnitLabel={
                withdrawLiquidity.isWithdrawInOtherTokens &&
                withdrawLiquidity.toTokenSymbol
                  ? `/${getDpFormat(withdrawMaximumPercentage)}%`
                  : withdrawLiquidity.isWithdrawInOtherTokens
                  ? '/0%'
                  : `/100%`
              }
              disableUnderline
              disabled={!withdrawLiquidity.toTokenSymbol}
              textalign="left"
              noLeftPadding
            />
            <AppTokenInput.ActionButton
              onClick={handleClickMaxButton}
              disabled={!withdrawLiquidity.toTokenSymbol}
            >
              Max
            </AppTokenInput.ActionButton>
          </Box>
          <AppTokenInput.Slider
            railWidth={
              withdrawLiquidity.toTokenSymbol
                ? `${withdrawMaximumPercentage}%`
                : '0%'
            }
            disabled={!withdrawLiquidity.toTokenSymbol}
          />
          {userPreference.withrawalInOtherTokens === 'On' && (
            <WithdrawInOtherTokenButton
              onClick={toggleWithdrawInOtherTokens}
              customVariant="unset"
            >
              {withdrawLiquidity.isWithdrawInOtherTokens ? (
                <>
                  <ArrowBackIcon />
                  <Typography variant="caption">
                    Withdraw in {withdrawLiquidity.tokenSymbolForDisplay}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="caption">
                    Withdraw in other tokens
                  </Typography>
                  <ArrowForwardIcon />
                </>
              )}
            </WithdrawInOtherTokenButton>
          )}
        </AppTokenInput.InnerContainer>
      </AppTokenInput>
      {openWithdrawInOtherTokenModal && (
        <SelectTokenModalContainer
          tokenSymbols={filteredTokenSymbols}
          isOpen
          onSelectToken={(value) => handleSelectToken(value.token)}
          onClose={() => setOpenWithdrawInOtherTokenModal(false)}
          currentSelectedToken={withdrawLiquidity.toTokenSymbol || undefined}
          requiredTokenSymbolForDisplay
        />
      )}
    </>
  )
}

export default PoolWithdrawalTokenInputsContainer
