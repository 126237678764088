import { strToWad, sum } from '@hailstonelabs/big-number-utils'
import { Call } from 'ethcall'
import { BigNumber, constants, utils } from 'ethers'
import cloneDeep from 'lodash.clonedeep'
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { LP_TOKENS, MASTER_PLATYPUS, POOLS, TOKENS } from '../config/contracts'
import { LpToken } from '../config/contracts/lpToken/LpToken'
import { MasterPlatypusId } from '../config/contracts/masterPlatypus'
import { PoolSymbol } from '../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import { PendingTokensType, UserInfoType } from '../interfaces/apr'
import {
  CallbacksType,
  executeCallBacks,
  MigrationDetectionContextMulticallSCReturnType,
} from '../utils/multicall'
import { lptokenBNToToken } from '../utils/pool'
import { useNetwork } from './NetworkContext'
import { usePools } from './PoolsContext'

type TokenSymbolToStringMapping = {
  [key in string]: string
}

export type MasterPlatypusMigrationDataProps = {
  isMigrationNeeded?: boolean
  oldMasterPlatypus: {
    stakedPidsBN?: BigNumber[]
    stakedLpInTermsOfToken?: TokenSymbolToStringMapping
    totalPendingPtp?: string
  }
}

const initialMasterPlatypusMigrationData: MasterPlatypusMigrationDataProps = {
  isMigrationNeeded: false,
  oldMasterPlatypus: {
    stakedPidsBN: undefined,
    stakedLpInTermsOfToken: undefined,
    totalPendingPtp: undefined,
  },
}

type MasterPlatypusMigrationDataType = {
  [id in MasterPlatypusId]?: MasterPlatypusMigrationDataProps
}

export type DelistedDataProps = {
  isWithdrawalNeeded?: boolean
  pendingPtp?: string
  lpAmountInTermsOfLp: {
    staked?: string
    stakable?: string
    total?: string
  }
  lpAmountInTermsOfToken: {
    staked?: string
  }
  usdcAssetBalanceOfUsdc?: string
}

const initalDelistedData: DelistedDataProps = {
  isWithdrawalNeeded: undefined,
  pendingPtp: undefined,
  lpAmountInTermsOfLp: {
    staked: undefined,
    stakable: undefined,
    total: undefined,
  },
  lpAmountInTermsOfToken: {
    staked: undefined,
  },
}

type DelistedDataType = {
  [id in PoolSymbol]?: { [id in TokenSymbol]?: DelistedDataProps }
}

export type ContextType = {
  updateAllMigrationData: () => Promise<void>
  updateAllDelistedData: () => Promise<void>
  isMigrationNeeded: boolean
  isDelistNeeded: boolean
  masterPlatypusMigrationData: MasterPlatypusMigrationDataType
  delistedData: DelistedDataType
}

export const initialValue: ContextType = {
  updateAllMigrationData: () => {
    return Promise.resolve()
  },
  updateAllDelistedData: () => {
    return Promise.resolve()
  },
  isMigrationNeeded: false,
  isDelistNeeded: false,
  masterPlatypusMigrationData: {
    [MasterPlatypusId.MP3]: { ...initialMasterPlatypusMigrationData },
    [MasterPlatypusId.BASE_MPV1]: { ...initialMasterPlatypusMigrationData },
  },
  delistedData: {
    [PoolSymbol.DEPRECATED_MAIN]: {
      [TokenSymbol.MIM]: {
        ...initalDelistedData,
      },
    },
    [PoolSymbol.DEPRECATED_ALT_USDC_UST]: {
      [TokenSymbol.USDC]: {
        ...initalDelistedData,
      },
      [TokenSymbol.UST]: {
        ...initalDelistedData,
      },
    },
    [PoolSymbol.FACTORY_USDC_MIMATIC]: {
      [TokenSymbol.USDC]: {
        ...initalDelistedData,
      },
      [TokenSymbol.MIMATIC]: {
        ...initalDelistedData,
      },
    },
    [PoolSymbol.FACTORY_USDC_MONEY]: {
      [TokenSymbol.USDC]: {
        ...initalDelistedData,
      },
      [TokenSymbol.MONEY]: {
        ...initalDelistedData,
      },
    },
  },
}

interface Props {
  children: React.ReactNode
}

const MigrationDetectionContext = createContext<ContextType>(initialValue)
MigrationDetectionContext.displayName = 'MigrationDetectionContext'

export const useMigrationDetection = (): ContextType => {
  return useContext(MigrationDetectionContext)
}

export const MigrationDetectionProvider = ({
  children,
}: Props): ReactElement => {
  const { account, chainId, multicallProvider } = useNetwork()
  const { isPoolFetched, liabilities, lpSuppliesBN } = usePools()
  const [
    masterPlatypusMigrationFromV3Data,
    setMasterPlatypusMigrationFromV3Data,
  ] = useState<MasterPlatypusMigrationDataProps>(
    cloneDeep(initialMasterPlatypusMigrationData),
  )
  const [
    masterPlatypusMigrationFromBaseV1Data,
    setMasterPlatypusMigrationFromBaseV1Data,
  ] = useState<MasterPlatypusMigrationDataProps>(
    cloneDeep(initialMasterPlatypusMigrationData),
  )
  const [mimDelistedDataInMainPoolData, setMimDelistedDataInMainPoolData] =
    useState<DelistedDataProps>(cloneDeep(initalDelistedData))
  const [
    maiDelistedDataInFactoryUsdcMiMaticPool,
    setMaiDelistedDataInFactoryUsdcMiMaticPool,
  ] = useState<DelistedDataProps>(cloneDeep(initalDelistedData))
  const [
    usdcDelistedDataInFactoryUsdcMiMaticPool,
    setUsdcDelistedDataInFactoryUsdcMiMaticPool,
  ] = useState<DelistedDataProps>(cloneDeep(initalDelistedData))
  const [ustDelistedDataInAltUsdcUstPool, setUstDelistedDataInAltUsdcUstPool] =
    useState<DelistedDataProps>(cloneDeep(initalDelistedData))
  const [
    usdcDelistedDataInAltUsdcUstPool,
    setUsdcDelistedDataInAltUsdcUstPool,
  ] = useState<DelistedDataProps>(cloneDeep(initalDelistedData))
  const [
    moneyDelistedDataInFactoryUsdcMoneyPool,
    setMoneyDelistedDataInFactoryUsdcMoneyPool,
  ] = useState<DelistedDataProps>(cloneDeep(initalDelistedData))
  const [
    usdcDelistedDataInFactoryUsdcMoneyPool,
    setUsdcDelistedDataInFactoryUsdcMoneyPool,
  ] = useState<DelistedDataProps>(cloneDeep(initalDelistedData))

  const fetchMasterPlatypusMigrationData = async () => {
    let contractCalls: Call[] = []
    let callbacks: CallbacksType<MigrationDetectionContextMulticallSCReturnType> =
      []
    const temMasterPlatypusMigrationFromV3Data: MasterPlatypusMigrationDataProps =
      cloneDeep(initialMasterPlatypusMigrationData)
    const temMasterPlatypusMigrationFromBaseV1Data: MasterPlatypusMigrationDataProps =
      cloneDeep(initialMasterPlatypusMigrationData)
    const stakedAssets: { [id in MasterPlatypusId]?: LpToken[] } = {}
    const totalPendingPtpWadInEachMPMigration: {
      [id in MasterPlatypusId]?: BigNumber
    } = {}
    // Check userInfo first then call pendingTokens if userInfo.amount > 0
    const fetchEachMasterPlatypusMigrationUserInfoData = (
      masterPlatypusId: MasterPlatypusId,
      assets: LpToken[],
      migrationData: MasterPlatypusMigrationDataProps,
    ) => {
      if (!account || !isPoolFetched) return
      const masterPlatypusContract =
        MASTER_PLATYPUS[masterPlatypusId].get(chainId)
      if (!masterPlatypusContract) return
      for (let i = 0; i < assets.length; i++) {
        const asset = assets[i]
        const tokenSymbol = asset.tokenSymbol
        // MIM no need to migrate since it's already delisted.
        if (!asset.isAvailable) continue
        // We cannot use (pid && account) since pid starts from 0 which is a falsy value.
        // The first tokenSymbol will be disappeared in this situation.
        if (account) {
          const pidBN = BigNumber.from(asset.pids[chainId])
          contractCalls.push(
            masterPlatypusContract.userInfo(pidBN, account) as unknown as Call,
          )
          callbacks.push((value) => {
            // check user did stake lp in masterPlatypus
            if ((value as UserInfoType).amount.gt(constants.Zero)) {
              if (migrationData.oldMasterPlatypus.stakedPidsBN) {
                migrationData.oldMasterPlatypus.stakedPidsBN.push(pidBN)
              } else {
                migrationData.oldMasterPlatypus.stakedPidsBN = [pidBN]
              }
              migrationData.isMigrationNeeded = true
              // Assign to stakedAssets for fetch pendingTokens
              if (stakedAssets[masterPlatypusId]) {
                stakedAssets[masterPlatypusId]?.push(asset)
              } else {
                stakedAssets[masterPlatypusId] = [asset]
              }
              // MasterPlatypus MP3 has more than one pool containing USDC etc.ALT_USDC_MONEY, ALT_USDC_FRAX.
              // We need to add them to one value in stakedLpInTermsOfToken[tokenSymbol]
              const stakedLpInTermsOfToken = lptokenBNToToken(
                (value as UserInfoType).amount,
                lpSuppliesBN[asset.poolSymbol][tokenSymbol],
                utils.parseUnits(
                  liabilities[asset.poolSymbol][tokenSymbol],
                  TOKENS[tokenSymbol].decimals,
                ),
                tokenSymbol,
              )
              if (
                migrationData.oldMasterPlatypus.stakedLpInTermsOfToken?.[
                  tokenSymbol
                ]
              ) {
                const prevStakedLpInTermsOfToken =
                  migrationData.oldMasterPlatypus.stakedLpInTermsOfToken?.[
                    tokenSymbol
                  ]
                // total token staked of each unique token instead of asset.
                migrationData.oldMasterPlatypus.stakedLpInTermsOfToken[
                  tokenSymbol
                ] = utils.formatEther(
                  strToWad(stakedLpInTermsOfToken).add(
                    strToWad(prevStakedLpInTermsOfToken),
                  ),
                )
              } else {
                migrationData.oldMasterPlatypus.stakedLpInTermsOfToken = {
                  ...migrationData.oldMasterPlatypus.stakedLpInTermsOfToken,
                  [tokenSymbol]: stakedLpInTermsOfToken,
                }
              }
            }
          })
        }
      }
    }
    fetchEachMasterPlatypusMigrationUserInfoData(
      MasterPlatypusId.MP3,
      [
        ...POOLS[PoolSymbol.DEPRECATED_MAIN].getAssets(),
        ...POOLS[PoolSymbol.ALT_AVAX_SAVAX].getAssets(),
        ...POOLS[PoolSymbol.ALT_BTCB_WBTCE].getAssets(),
        ...POOLS[PoolSymbol.ALT_USDC_FRAX].getAssets(),
        ...POOLS[PoolSymbol.ALT_USDC_MIM].getAssets(),
        ...POOLS[PoolSymbol.ALT_USDC_YUSD].getAssets(),
        ...POOLS[PoolSymbol.ALT_USDC_MONEY].getAssets(),
      ],
      temMasterPlatypusMigrationFromV3Data,
    )
    fetchEachMasterPlatypusMigrationUserInfoData(
      MasterPlatypusId.BASE_MPV1,
      [
        ...POOLS[PoolSymbol.FACTORY_USDC_H2O].getAssets(),
        ...POOLS[PoolSymbol.FACTORY_USDC_TSD].getAssets(),
        ...POOLS[PoolSymbol.FACTORY_USDC_USX].getAssets(),
      ],
      temMasterPlatypusMigrationFromBaseV1Data,
    )
    try {
      const values =
        await multicallProvider.tryAll<MigrationDetectionContextMulticallSCReturnType>(
          contractCalls,
        )
      executeCallBacks(values, callbacks)
    } catch (error) {
      console.debug(error)
    }
    // reset contractCalls and callbacks after fetch user info
    contractCalls = []
    callbacks = []
    const fetchEachMasterPlatypusMigrationPendingTokensData = (
      masterPlatypusId: MasterPlatypusId,
    ) => {
      if (!account || !isPoolFetched) return
      const masterPlatypusContract =
        MASTER_PLATYPUS[masterPlatypusId].get(chainId)
      if (!masterPlatypusContract) return
      const assets = stakedAssets[masterPlatypusId]
      if (assets) {
        assets.forEach((asset) => {
          if (account) {
            const pidBN = BigNumber.from(asset.pids[chainId])
            contractCalls.push(
              masterPlatypusContract.pendingTokens(
                pidBN,
                account,
              ) as unknown as Call,
            )
            callbacks.push((value) => {
              const prevTotalPendingPtpWad =
                totalPendingPtpWadInEachMPMigration[masterPlatypusId]
              if (prevTotalPendingPtpWad) {
                totalPendingPtpWadInEachMPMigration[masterPlatypusId] =
                  prevTotalPendingPtpWad.add(
                    (value as PendingTokensType).pendingPtp,
                  )
              } else {
                totalPendingPtpWadInEachMPMigration[masterPlatypusId] = (
                  value as PendingTokensType
                ).pendingPtp
              }
            })
          }
        })
      }
    }
    fetchEachMasterPlatypusMigrationPendingTokensData(MasterPlatypusId.MP1)
    fetchEachMasterPlatypusMigrationPendingTokensData(MasterPlatypusId.MP3)
    fetchEachMasterPlatypusMigrationPendingTokensData(
      MasterPlatypusId.BASE_MPV1,
    )
    try {
      const values =
        await multicallProvider.tryAll<MigrationDetectionContextMulticallSCReturnType>(
          contractCalls,
        )
      executeCallBacks(values, callbacks)
      temMasterPlatypusMigrationFromV3Data.oldMasterPlatypus.totalPendingPtp =
        utils.formatEther(
          totalPendingPtpWadInEachMPMigration[MasterPlatypusId.MP3] ||
            constants.Zero,
        )
      temMasterPlatypusMigrationFromBaseV1Data.oldMasterPlatypus.totalPendingPtp =
        utils.formatEther(
          totalPendingPtpWadInEachMPMigration[MasterPlatypusId.BASE_MPV1] ||
            constants.Zero,
        )
      setMasterPlatypusMigrationFromV3Data(temMasterPlatypusMigrationFromV3Data)
      setMasterPlatypusMigrationFromBaseV1Data(
        temMasterPlatypusMigrationFromBaseV1Data,
      )
    } catch (error) {
      console.debug(error)
    }
  }

  const fetchDelistedData = async () => {
    if (!account) return
    const contractCalls: Call[] = []
    const callbacks: CallbacksType<MigrationDetectionContextMulticallSCReturnType> =
      []
    const temMimDelistedDataInMainPool: DelistedDataProps =
      cloneDeep(initalDelistedData)
    const temUstDelistedDataInAltUsdcUstPool: DelistedDataProps =
      cloneDeep(initalDelistedData)
    const temUsdcDelistedDataInAltUsdcUstPool: DelistedDataProps =
      cloneDeep(initalDelistedData)
    const temUsdcDelistedDataInFactoryUsdcMiMaticPool: DelistedDataProps =
      cloneDeep(initalDelistedData)
    const temMaiDelistedDataInFactoryUsdcMiMaticPool: DelistedDataProps =
      cloneDeep(initalDelistedData)
    const temUsdcDelistedDataInFactoryUsdcMoneyPool: DelistedDataProps =
      cloneDeep(initalDelistedData)
    const temMoneyDelistedDataInFactoryUsdcMoneyPool: DelistedDataProps =
      cloneDeep(initalDelistedData)
    const fetchEachDelistedData = (
      poolSymbol: PoolSymbol,
      assetTokenSymbol: TokenSymbol,
      masterPlatypusId: MasterPlatypusId,
      isPendingRewardAvailable: boolean,
      delistData: DelistedDataProps,
    ) => {
      const lpToken = LP_TOKENS[poolSymbol][assetTokenSymbol]
      if (!lpToken) return
      const lpTokenDecimals = lpToken.decimals
      const lpTokenContract = lpToken.get(chainId)
      const masterPlatypusContract =
        MASTER_PLATYPUS[masterPlatypusId].get(chainId)
      const pid = lpToken.pids[chainId]
      if (
        poolSymbol === PoolSymbol.FACTORY_USDC_MIMATIC &&
        assetTokenSymbol === TokenSymbol.USDC
      ) {
        const usdcContract = TOKENS[TokenSymbol.USDC].get(chainId)
        const lpAddress = lpToken.getAddress(chainId)
        if (usdcContract && lpAddress) {
          contractCalls.push(
            usdcContract.balanceOf(lpAddress) as unknown as Call,
          )
          callbacks.push((value) => {
            delistData.usdcAssetBalanceOfUsdc = utils.formatUnits(
              value as BigNumber,
              lpTokenDecimals,
            )
          })
        }
      }
      if (
        lpTokenContract &&
        masterPlatypusContract &&
        // pid will be 0 so avoid use !Pid
        pid !== undefined
      ) {
        contractCalls.push(
          lpTokenContract.balanceOf(account) as unknown as Call,
        )
        callbacks.push((value) => {
          delistData.lpAmountInTermsOfLp.stakable = utils.formatUnits(
            value as BigNumber,
            lpTokenDecimals,
          )
          delistData.lpAmountInTermsOfToken.staked = lptokenBNToToken(
            value as BigNumber,
            lpSuppliesBN[poolSymbol][assetTokenSymbol],
            utils.parseUnits(
              liabilities[poolSymbol][assetTokenSymbol],
              TOKENS[assetTokenSymbol].decimals,
            ),
            assetTokenSymbol,
          )
        })
        /** @todo refactor if some deprecated asset provide non ptp rewards */
        if (isPendingRewardAvailable) {
          contractCalls.push(
            masterPlatypusContract.pendingTokens(
              pid,
              account,
            ) as unknown as Call,
          )
          callbacks.push((value) => {
            delistData.pendingPtp = utils.formatEther(
              (value as PendingTokensType).pendingPtp,
            )
          })
        }
        contractCalls.push(
          masterPlatypusContract.userInfo(pid, account) as unknown as Call,
        )
        callbacks.push((value) => {
          delistData.lpAmountInTermsOfLp.staked = utils.formatUnits(
            (value as UserInfoType).amount,
            lpTokenDecimals,
          )
          if (delistData.lpAmountInTermsOfLp.stakable) {
            delistData.lpAmountInTermsOfLp.total = sum(
              delistData.lpAmountInTermsOfLp.stakable,
              delistData.lpAmountInTermsOfLp.staked,
            )
            delistData.isWithdrawalNeeded = strToWad(
              delistData.lpAmountInTermsOfLp.total,
            ).gt(constants.Zero)
          } else {
            console.error(`delist data stakable is undefined`)
          }
        })
      }
    }
    fetchEachDelistedData(
      PoolSymbol.DEPRECATED_MAIN,
      TokenSymbol.MIM,
      MasterPlatypusId.MP1,
      true,
      temMimDelistedDataInMainPool,
    )
    fetchEachDelistedData(
      PoolSymbol.DEPRECATED_ALT_USDC_UST,
      TokenSymbol.USDC,
      MasterPlatypusId.MP3,
      true,
      temUsdcDelistedDataInAltUsdcUstPool,
    )
    fetchEachDelistedData(
      PoolSymbol.DEPRECATED_ALT_USDC_UST,
      TokenSymbol.UST,
      MasterPlatypusId.MP3,
      true,
      temUstDelistedDataInAltUsdcUstPool,
    )
    fetchEachDelistedData(
      PoolSymbol.FACTORY_USDC_MIMATIC,
      TokenSymbol.USDC,
      MasterPlatypusId.BASE_MPV1,
      true,
      temUsdcDelistedDataInFactoryUsdcMiMaticPool,
    )
    fetchEachDelistedData(
      PoolSymbol.FACTORY_USDC_MIMATIC,
      TokenSymbol.MIMATIC,
      MasterPlatypusId.BASE_MPV1,
      true,
      temMaiDelistedDataInFactoryUsdcMiMaticPool,
    )
    fetchEachDelistedData(
      PoolSymbol.FACTORY_USDC_MONEY,
      TokenSymbol.USDC,
      MasterPlatypusId.BASE_MPV1,
      true,
      temUsdcDelistedDataInFactoryUsdcMoneyPool,
    )
    fetchEachDelistedData(
      PoolSymbol.FACTORY_USDC_MONEY,
      TokenSymbol.MONEY,
      MasterPlatypusId.BASE_MPV1,
      true,
      temMoneyDelistedDataInFactoryUsdcMoneyPool,
    )
    try {
      const values =
        await multicallProvider.tryAll<MigrationDetectionContextMulticallSCReturnType>(
          contractCalls,
        )
      executeCallBacks(values, callbacks)
      setMimDelistedDataInMainPoolData(temMimDelistedDataInMainPool)
      setUstDelistedDataInAltUsdcUstPool(temUstDelistedDataInAltUsdcUstPool)
      setUsdcDelistedDataInAltUsdcUstPool(temUsdcDelistedDataInAltUsdcUstPool)
      setUsdcDelistedDataInFactoryUsdcMiMaticPool(
        temUsdcDelistedDataInFactoryUsdcMiMaticPool,
      )
      setMaiDelistedDataInFactoryUsdcMiMaticPool(
        temMaiDelistedDataInFactoryUsdcMiMaticPool,
      )
      setMoneyDelistedDataInFactoryUsdcMoneyPool(
        temMoneyDelistedDataInFactoryUsdcMoneyPool,
      )
      setUsdcDelistedDataInFactoryUsdcMoneyPool(
        temUsdcDelistedDataInFactoryUsdcMoneyPool,
      )
    } catch (error) {
      console.debug(error)
    }
  }

  useEffect(
    () => {
      void fetchMasterPlatypusMigrationData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, chainId, isPoolFetched],
  )

  useEffect(
    () => {
      void fetchDelistedData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, chainId],
  )

  return (
    <MigrationDetectionContext.Provider
      value={{
        updateAllDelistedData: fetchDelistedData,
        updateAllMigrationData: fetchMasterPlatypusMigrationData,
        isMigrationNeeded: !!(
          masterPlatypusMigrationFromV3Data.isMigrationNeeded ||
          masterPlatypusMigrationFromBaseV1Data.isMigrationNeeded ||
          strToWad(
            moneyDelistedDataInFactoryUsdcMoneyPool.lpAmountInTermsOfLp.staked,
          ).gt(constants.Zero) ||
          strToWad(
            usdcDelistedDataInFactoryUsdcMoneyPool.lpAmountInTermsOfLp.staked,
          ).gt(constants.Zero)
        ),
        isDelistNeeded: !!(
          mimDelistedDataInMainPoolData.isWithdrawalNeeded ||
          ustDelistedDataInAltUsdcUstPool.isWithdrawalNeeded ||
          usdcDelistedDataInAltUsdcUstPool.isWithdrawalNeeded ||
          maiDelistedDataInFactoryUsdcMiMaticPool.isWithdrawalNeeded ||
          usdcDelistedDataInFactoryUsdcMiMaticPool.isWithdrawalNeeded
        ),
        masterPlatypusMigrationData: {
          [MasterPlatypusId.MP3]: account
            ? masterPlatypusMigrationFromV3Data
            : initialValue.masterPlatypusMigrationData[MasterPlatypusId.MP3],
          [MasterPlatypusId.BASE_MPV1]: account
            ? masterPlatypusMigrationFromBaseV1Data
            : initialValue.masterPlatypusMigrationData[
                MasterPlatypusId.BASE_MPV1
              ],
        },
        delistedData: {
          [PoolSymbol.DEPRECATED_MAIN]: {
            [TokenSymbol.MIM]: mimDelistedDataInMainPoolData,
          },
          [PoolSymbol.DEPRECATED_ALT_USDC_UST]: {
            [TokenSymbol.UST]: ustDelistedDataInAltUsdcUstPool,
            [TokenSymbol.USDC]: usdcDelistedDataInAltUsdcUstPool,
          },
          [PoolSymbol.FACTORY_USDC_MIMATIC]: {
            [TokenSymbol.MIMATIC]: maiDelistedDataInFactoryUsdcMiMaticPool,
            [TokenSymbol.USDC]: usdcDelistedDataInFactoryUsdcMiMaticPool,
          },
          [PoolSymbol.FACTORY_USDC_MONEY]: {
            [TokenSymbol.MONEY]: moneyDelistedDataInFactoryUsdcMoneyPool,
            [TokenSymbol.USDC]: usdcDelistedDataInFactoryUsdcMoneyPool,
          },
        },
      }}
    >
      {children}
    </MigrationDetectionContext.Provider>
  )
}
