import { alpha, Box, LinearProgress } from '@mui/material'
import styled, { css } from 'styled-components'
import AppButton from '../../../components/buttons/AppButton/AppButton'

export const PtpLockingInfoItem = styled(Box)`
  display: flex;
  flex: 0.5;
  justify-content: center;
  padding: 0;
`

export const UnlockProgressWrapper = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${alpha(theme.palette.primary[900], 0.7)}};
    border-radius: 10px;
    padding: 3px 8px;
    text-align: center;
    min-width: 100px;

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 6px;
      min-width: 150px;
    }
  `}
`

export const UnlockProgress = styled(LinearProgress)`
  ${({ theme }) =>
    css`
      width: 100px;

      &.MuiLinearProgress-root {
        background-color: ${theme.palette.primary[800]};
        border-radius: 999px;
        margin: 4px 0;
      }
      .MuiLinearProgress-bar {
        background-color: ${theme.palette.primary[500]};
      }

      @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
        width: 100%;
      }
    `}
`

export const StyledButton = styled(AppButton)`
  min-width: unset;
  padding: 4px 8px;
`
