import {
  isParsableString,
  strToWad,
  wmul,
} from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from 'react'
import { TOKENS } from '../config/contracts'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import { ModalId } from '../interfaces/Modal'
import { TotalLockDays } from '../interfaces/vePTP'
import { getTotalLockDays } from '../utils/locking'
import { useModal } from './ModalContext'
import { useVePtp } from './VePtpContext'

export interface ContextType {
  lockDayInputAmount: string
  lockPtpInputAmount: string
  maxPtpLockCap: string
  myLockedPtpAmountIncludingInput: string
  totalLockDays: TotalLockDays
  actions: {
    lockDayInputAmountOnChange: (value: string) => void
    lockPtpInputAmountOnChange: (value: string) => void
    resetLockDayAndLockPtpAmount: () => void
  }
}

const intialVePtpInputContext: ContextType = {
  lockDayInputAmount: '',
  lockPtpInputAmount: '',
  maxPtpLockCap: '',
  myLockedPtpAmountIncludingInput: '',
  totalLockDays: {
    current: '',
    original: '',
  },
  actions: {
    lockDayInputAmountOnChange: () => {
      return
    },
    lockPtpInputAmountOnChange: () => {
      return
    },
    resetLockDayAndLockPtpAmount: () => {
      return
    },
  },
}

export const VePtpInputContext = createContext<ContextType>(
  intialVePtpInputContext,
)
VePtpInputContext.displayName = 'VePtpInputContext'

export const useVePtpInput = (): ContextType => {
  return useContext(VePtpInputContext)
}

interface Props {
  children: React.ReactNode
}

function VePtpInputProvider({ children }: Props): ReactElement {
  const [lockDayInputAmount, setLockDayInputAmount] = useState<string>('')
  const [lockPtpInputAmount, setLockPtpInputAmount] = useState<string>('')

  const { modalState } = useModal()
  const { ptp, vePtp } = useVePtp()

  const isWaitingConfirmation =
    modalState.currentModalId ===
      ModalId.LOCK_EXTEND_PTP_WAIT_FOR_CONFIRMATION ||
    modalState.currentModalId === ModalId.UNLOCK_PTP_WAIT_FOR_CONFIRMATION ||
    modalState.currentModalId === ModalId.LOCK_PTP_WAIT_FOR_CONFIRMATION

  const totalLockDays = getTotalLockDays(
    ptp.lockTime.unlockTimestamp,
    ptp.lockTime.initialTimestamp,
    lockDayInputAmount,
  )

  const resetLockDayAndLockPtpAmount = useCallback(() => {
    setLockPtpInputAmount('')
    if (modalState.currentModalId === ModalId.LOCK_PTP) {
      setLockDayInputAmount(ptp.lockTime.minDays.toString())
    }
    if (modalState.currentModalId === ModalId.EXTEND_LOCK_PTP) {
      setLockDayInputAmount('')
    }
  }, [modalState.currentModalId, ptp.lockTime.minDays])

  const lockPtpInputAmountOnChange = (value: string) => {
    if (value === '') {
      setLockPtpInputAmount('')
      return
    }
    // only accept Ptp d.p for the input
    if (
      value !== '' &&
      !isParsableString(value, TOKENS[TokenSymbol.PTP].decimals, true)
    )
      return

    setLockPtpInputAmount(value)
  }

  const lockDayInputAmountOnChange = (value: string) => {
    if (value === '') {
      setLockDayInputAmount('')
      return
    }
    // only accept integer for the input
    if (value !== '' && !isParsableString(value, 0, true)) return

    // maximum input value limit to maxLockDays
    if (Number(value) > ptp.lockTime.maxDays) return
    // lock day after extend cannot greater than max lock day
    if (modalState.currentModalId === ModalId.EXTEND_LOCK_PTP) {
      const lockdayAfterExtend = getTotalLockDays(
        ptp.lockTime.unlockTimestamp,
        ptp.lockTime.initialTimestamp,
        value,
      ).current
      if (Number(lockdayAfterExtend) > ptp.lockTime.maxDays) return
    }

    setLockDayInputAmount(value)
  }

  const myLockedPtpAmountIncludingInput = utils.formatEther(
    strToWad(ptp.amount.locked).add(strToWad(lockPtpInputAmount)),
  )

  const maxPtpLockCap = utils.formatEther(
    wmul(
      strToWad(vePtp.maxCapPerPtp.locking),
      strToWad(
        modalState.currentModalId === ModalId.EXTEND_LOCK_PTP
          ? ptp.amount.locked
          : myLockedPtpAmountIncludingInput,
      ),
    ),
  )

  //default lock day is the minLockDay for locking, 0 for extend locking
  useLayoutEffect(() => {
    if (!isWaitingConfirmation) {
      resetLockDayAndLockPtpAmount()
    }
  }, [isWaitingConfirmation, resetLockDayAndLockPtpAmount])

  return (
    <VePtpInputContext.Provider
      value={{
        lockDayInputAmount,
        lockPtpInputAmount,
        maxPtpLockCap,
        myLockedPtpAmountIncludingInput,
        totalLockDays,
        actions: {
          lockDayInputAmountOnChange,
          lockPtpInputAmountOnChange,
          resetLockDayAndLockPtpAmount,
        },
      }}
    >
      {children}
    </VePtpInputContext.Provider>
  )
}

export default VePtpInputProvider
