import { safeWdiv, strToWad, WAD } from '@hailstonelabs/big-number-utils'
import { BigNumber, constants } from 'ethers'
import { POOLS, TOKENS } from '../config/contracts'
import { PoolSymbol, poolSymbols } from '../config/contracts/pool/poolSymbol'
import {
  SwapGroupSymbol,
  TokenSymbol,
} from '../config/contracts/token/tokenSymbol'
import { ChainId } from '../config/networks'
import { HexString } from '../interfaces/common'

type GetTokenAndPoolAddressesPathType = {
  token: {
    symbol: TokenSymbol[]
    address: HexString[]
  }
  pool: {
    symbol: PoolSymbol[]
    address: HexString[]
  }
}
/**
 *
 * @param fromTokenSymbol
 * @param toTokenSymbol
 * @param chainId
 */
/** @todo need to reimplement when there are other router or poolPath >2 */
export const getTokenAndPoolPaths = (
  fromTokenSymbol: TokenSymbol,
  toTokenSymbol: TokenSymbol,
  chainId: ChainId,
): GetTokenAndPoolAddressesPathType | undefined => {
  if (
    TOKENS[fromTokenSymbol].swapGroupSymbol !==
    TOKENS[toTokenSymbol].swapGroupSymbol
  ) {
    throw new Error(
      `Cannot getTokenAndPoolPaths with two different swapGroupSymbol tokens (from:${fromTokenSymbol},to:${toTokenSymbol})`,
    )
  }
  const poolSymbolForIntraPoolSwap = poolSymbols.find((poolSymbol) =>
    POOLS[poolSymbol].availableTokensForSwapIncludes([
      fromTokenSymbol,
      toTokenSymbol,
    ]),
  )
  const fromTokenAddress = TOKENS[fromTokenSymbol].getAddress(chainId)
  const toTokenAddress = TOKENS[toTokenSymbol].getAddress(chainId)
  const poolAddress = poolSymbolForIntraPoolSwap
    ? POOLS[poolSymbolForIntraPoolSwap].address[chainId]
    : undefined
  if (
    poolSymbolForIntraPoolSwap &&
    fromTokenAddress &&
    toTokenAddress &&
    poolAddress
  ) {
    return {
      token: {
        symbol: [fromTokenSymbol, toTokenSymbol],
        address: [fromTokenAddress, toTokenAddress],
      },
      pool: {
        symbol: [poolSymbolForIntraPoolSwap],
        address: [poolAddress],
      },
    }
  }
  const routerTokenSymbol =
    TOKENS[fromTokenSymbol].swapGroupSymbol === SwapGroupSymbol.USD
      ? TokenSymbol.USDC
      : TokenSymbol.WAVAX
  const routerTokenAddress = TOKENS[routerTokenSymbol].getAddress(chainId)
  // Pool includes fromToken and routerToken
  const firstPoolSymbol = poolSymbols.find((poolSymbol) =>
    POOLS[poolSymbol].availableTokensForSwapIncludes([
      fromTokenSymbol,
      routerTokenSymbol,
    ]),
  )
  // Pool includes routerToken and toToken
  const lastPoolSymbol = poolSymbols.find((poolSymbol) =>
    POOLS[poolSymbol].availableTokensForSwapIncludes([
      routerTokenSymbol,
      toTokenSymbol,
    ]),
  )
  if (!firstPoolSymbol || !lastPoolSymbol) return undefined
  const firstPoolAddress = POOLS[firstPoolSymbol].getAddress(chainId)
  const lastPoolAddress = POOLS[lastPoolSymbol].getAddress(chainId)

  if (
    !fromTokenAddress ||
    !routerTokenAddress ||
    !toTokenAddress ||
    !firstPoolAddress ||
    !lastPoolAddress
  )
    return undefined
  return {
    token: {
      symbol: [fromTokenSymbol, routerTokenSymbol, toTokenSymbol],
      address: [fromTokenAddress, routerTokenAddress, toTokenAddress],
    },
    pool: {
      symbol: [firstPoolSymbol, lastPoolSymbol],
      address: [firstPoolAddress, lastPoolAddress],
    },
  }
}

/**
 * price impact formula is `toTokenAmount/fromTokenAmount*100% - 1` (remember to take care of the diff decimals of the 2 tokens)
 *
 * @param {string} fromTokenAmount
 * @param {string} toTokenAmount
 * @returns {BigNumber} percent in WAD
 */
export const getToFromRatioWad = (
  fromTokenAmount: string,
  toTokenAmount: string,
): BigNumber => {
  try {
    if (fromTokenAmount === '' || fromTokenAmount === '') {
      return BigNumber.from('0')
    }
    // change string to bigNumber
    const toTokenAmountWad = strToWad(toTokenAmount)
    const fromTokenAmountWad = strToWad(fromTokenAmount)
    if (toTokenAmountWad.eq('0') || fromTokenAmountWad.eq('0')) {
      return BigNumber.from('0')
    }
    // price impact formula is toTokenAmount/fromTokenAmount*100% - 1
    const percentWad = toTokenAmountWad
      .mul(WAD)
      .div(fromTokenAmountWad)
      .sub(WAD)
      .mul('100')

    return percentWad
  } catch {
    return BigNumber.from('0')
  }
}

/**
 * price impact formula = (market rate - quoted rate) / market rate * 100%
 * @param {string} targetfromTokenAmount
 * @param {string} targetToTokenAmount
 * @param {string} marketFromTokenAmount
 * @param {string} marketToTokenAmount
 * @returns {BigNumber} percent in WAD
 */
export const getPriceImpactWad = (
  targetfromTokenAmount: string,
  targetToTokenAmount: string,
  marketFromTokenAmount: string,
  marketToTokenAmount: string,
): BigNumber => {
  try {
    // rate = toAmount/fromAmount
    const targetToTokenAmountWad = strToWad(targetToTokenAmount)
    const targetFromTokenAmountWad = strToWad(targetfromTokenAmount)
    const marketToTokenAmountWad = strToWad(marketToTokenAmount)
    const marketFromTokenAmountWad = strToWad(marketFromTokenAmount)
    if (targetFromTokenAmountWad.eq('0') || marketFromTokenAmountWad.eq('0')) {
      return constants.Zero
    }
    const quotedRateWad = safeWdiv(
      targetToTokenAmountWad,
      targetFromTokenAmountWad,
    )
    const marketRateWad = safeWdiv(
      marketToTokenAmountWad,
      marketFromTokenAmountWad,
    )
    if (marketRateWad.isZero()) {
      return constants.Zero
    }
    // price impact formula = (market rate - quoted rate) / market rate * 100%
    const priceImpactWad = safeWdiv(
      marketRateWad.sub(quotedRateWad),
      marketRateWad,
    ).mul(100)
    if (priceImpactWad.isNegative()) {
      return constants.Zero
    }
    return priceImpactWad
  } catch {
    return constants.Zero
  }
}
