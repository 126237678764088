import React from 'react'
import { Container, Content, Title, Tooltip } from './TextWithToolTip.elements'

interface Props {
  title?: string | undefined
  content?: string | React.ReactElement | undefined
  tooltipText?: string | undefined | React.ReactElement
  className?: string | undefined
  textColor?: string | undefined
  fontSize?: string | undefined
  children?: React.ReactElement
}

const TextWithToolTip = ({
  title,
  content,
  tooltipText,
  className,
  textColor,
  fontSize,
  children,
}: Props): React.ReactElement => (
  <Container className={className}>
    <Title fontSize={fontSize} textColor={textColor} variant="caption3">
      {title}
    </Title>
    &nbsp;
    <Content fontSize={fontSize} textColor={textColor} variant="caption2">
      {content}
      &nbsp;
      {children}
    </Content>
    {tooltipText && <Tooltip text={tooltipText} />}
  </Container>
)

export default TextWithToolTip
