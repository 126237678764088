import React, { ReactElement, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom'
import UnsupportedNetwork from './components/errors/UnsupportedNetwork/UnsupportedNetwork'
import routes from './config/routes'
import { GeneralContextProvider } from './contexts/GeneralContext'
import { useModal } from './contexts/ModalContext'
import { useNetwork } from './contexts/NetworkContext'
import useBreakpoints from './hooks/useBreakpoints'
import useWalletConnection from './hooks/wallet/useWalletConnection'
import MainLayout from './layouts/MainLayout/MainLayout'

const navigateToSwapPage = (isIndexPathMatchedOnly?: boolean) => (
  <Route
    {...(isIndexPathMatchedOnly ? { index: true } : { path: '*' })}
    element={<Navigate to={routes.SWAP.path} />}
  />
)

function AppRoutes(): ReactElement {
  const { isSupported, account } = useNetwork()
  const {
    modalDispatch,
    actions: { openModal, closeModal },
  } = useModal()
  const { connectWallet } = useWalletConnection()
  const { isTabletSm } = useBreakpoints()
  // close the CONNECT_TO_WALLET modal if account connected
  useEffect(() => {
    if (account) {
      modalDispatch(closeModal())
    }
  }, [account, modalDispatch, openModal, closeModal])
  // Read and connect to the provider from localstorage when page first load
  useEffect(() => {
    connectWallet()
    // use empty dependency so it only run once when the page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Router>
      <GeneralContextProvider>
        <MainLayout>
          {isSupported === false ? (
            <UnsupportedNetwork />
          ) : (
            <>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Outlet />
                    </>
                  }
                >
                  <Route index element={<Navigate to={routes.SWAP.path} />} />
                  {/**@todo re-open once pool is relaunched */}
                  {navigateToSwapPage()}
                  <Route
                    path={routes.SWAP.path}
                    element={<routes.SWAP.Component />}
                  />
                  <Route
                    path={routes.POOL.path}
                    element={<routes.POOL.Component />}
                  />
                  <Route
                    path={routes.STAKE.path}
                    element={<routes.STAKE.Component />}
                  />

                  <Route
                    path={routes.GAUGE_VOTING.path}
                    element={<routes.GAUGE_VOTING.Component />}
                  />
                  {/**@todo re-open once USP feature is already */}
                  {/* <Route
                  path={routes.USP.path}
                  element={<routes.USP.Component />}
                /> */}
                  {/**@todo re-open once auction feature is already */}
                  {/* <Route
                  path={routes.AUCTION.path}
                  element={<routes.AUCTION.Component />}
                /> */}
                </Route>
                {/* <Route
                  path={routes.MIGRATION.path}
                  element={<routes.MIGRATION.Component />}
                /> */}
                <Route
                  path={routes.DEPRECATED_POOLS.path}
                  element={<routes.DEPRECATED_POOLS.Component />}
                />
                <Route
                  path={routes.PLATOPIA.path}
                  element={
                    // redirect to stake page on mobile
                    isTabletSm ? (
                      <Navigate to={routes.STAKE.path} />
                    ) : (
                      <routes.PLATOPIA.Component />
                    )
                  }
                />
                {/* Redirect */}
                <Route
                  path="/withdraw-mim"
                  element={<Navigate to={routes.DEPRECATED_POOLS.path} />}
                />
              </Routes>
            </>
          )}
        </MainLayout>
      </GeneralContextProvider>
    </Router>
  )
}

export default AppRoutes
