import { Box } from '@mui/material'
import React, { ReactElement, useMemo } from 'react'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import Modal from '../../../components/Modal/Modal'
import { useModal } from '../../../contexts/ModalContext'
import { useSwap } from '../../../contexts/SwapContext'
import useSwapToken from '../../../hooks/swap/useSwapToken'
import useUnwrapWavax from '../../../hooks/swap/useUnwrapWavax'
import useWrapAvax from '../../../hooks/swap/useWrapAvax'
import DisabledTokenInputsContainer from '../../SwapContainer/DisabledTokenInputsContainer'
import SwapInfoBoxContainer from '../../SwapContainer/SwapInfoBoxContainer'

interface Props {
  isOpen: boolean
}
function ConfirmSwapModalContainer({ isOpen }: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const { isWrappingAvaxToWavax, isUnwrappingWavaxToAvax } = useSwap()
  const { handleSwap } = useSwapToken()
  const { handleWrap } = useWrapAvax()
  const { handleUnwrap } = useUnwrapWavax()
  const [label, handleClick] = useMemo(() => {
    if (isWrappingAvaxToWavax) {
      return ['Wrap', handleWrap]
    }
    if (isUnwrappingWavaxToAvax) {
      return ['Unwrap', handleUnwrap]
    }
    return ['Swap', handleSwap]
  }, [
    isWrappingAvaxToWavax,
    isUnwrappingWavaxToAvax,
    handleWrap,
    handleUnwrap,
    handleSwap,
  ])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => modalDispatch(closeModal())}
      topBarLabel={`Confirm ${label}`}
      width="100%"
      maxWidth="500px"
      disableCloseBtn
    >
      <DisabledTokenInputsContainer />
      <SwapInfoBoxContainer />
      <Box display="flex" flexDirection="row" position="relative">
        <AppButton
          fullWidth
          customVariant="neutral"
          onClick={() => modalDispatch(closeModal())}
          margin="0 5px 0 0"
        >
          Cancel
        </AppButton>
        <AppButton
          onClick={() => void handleClick()}
          fullWidth
          margin="0 0 0 5px"
        >
          {label}
        </AppButton>
      </Box>
    </Modal>
  )
}

export default React.memo(ConfirmSwapModalContainer)
