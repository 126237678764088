import { utils } from 'ethers'
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { TOKENS } from '../config/contracts'
import { PoolSymbol } from '../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import { getLpAmountStrFromPercent, lptokenBNToToken } from '../utils/pool'
import { usePools } from './PoolsContext'
import { useStakeLpData } from './StakeLpDataContext'

type UnstakeLpContentContextType = {
  unstakeLpPercentage: string
  setUnstakeLpPercentage: React.Dispatch<React.SetStateAction<string>>
  unstakeLpAmountStr: string
  remainingStakedAmountStr: string
  resetStates: () => void
}
const UnstakeLpContentContext = createContext<UnstakeLpContentContextType>(
  {} as UnstakeLpContentContextType,
)

export const useUnstakeLpContent = (): UnstakeLpContentContextType => {
  return useContext(UnstakeLpContentContext)
}
useUnstakeLpContent.displayName = 'useUnstakeLpContent'

function UnstakeLpContentProvider({
  poolSymbol,
  assetTokenSymbol,
  children,
}: {
  poolSymbol: PoolSymbol
  assetTokenSymbol: TokenSymbol
  children: ReactElement
}): ReactElement {
  const [unstakeLpPercentage, setUnstakeLpPercentage] = useState('')
  const [unstakeLpAmountStr, setUnstakeLpAmountStr] = useState('')
  const { lpSuppliesBN, liabilities } = usePools()
  const { lp } = useStakeLpData()
  const stakedLpAmountStr = useMemo(() => {
    return lp.inTermsOfLp.staked[poolSymbol][assetTokenSymbol]
  }, [assetTokenSymbol, lp.inTermsOfLp.staked, poolSymbol])
  const [remainingStakedAmountStr, setRemainingStakedAmountStr] =
    useState(stakedLpAmountStr)

  const resetStates = () => {
    setUnstakeLpPercentage('')
    setUnstakeLpAmountStr('')
  }

  // update states when unstakeLpPercentage is changed
  useEffect(() => {
    if (!stakedLpAmountStr) return
    const token = TOKENS[assetTokenSymbol]
    const newUnstakeLpAmountStr = getLpAmountStrFromPercent(
      token,
      unstakeLpPercentage,
      stakedLpAmountStr,
    )
    const newUnstakeLpAmountBN = utils.parseUnits(
      newUnstakeLpAmountStr,
      token.decimals,
    )
    const liabilityBN = utils.parseUnits(
      liabilities[poolSymbol][assetTokenSymbol],
      token.decimals,
    )
    const stakedLpAmountBN = utils.parseUnits(stakedLpAmountStr, token.decimals)

    const newRemainingStakedLpAmountBN =
      stakedLpAmountBN.sub(newUnstakeLpAmountBN)
    const newRemainingStakedAmountStr = lptokenBNToToken(
      newRemainingStakedLpAmountBN,
      lpSuppliesBN[poolSymbol][assetTokenSymbol],
      liabilityBN,
      assetTokenSymbol,
    )
    setUnstakeLpAmountStr(
      utils.formatUnits(newUnstakeLpAmountBN, token.decimals),
    )
    setRemainingStakedAmountStr(newRemainingStakedAmountStr)
  }, [
    assetTokenSymbol,
    liabilities,
    lpSuppliesBN,
    poolSymbol,
    stakedLpAmountStr,
    unstakeLpPercentage,
  ])

  useEffect(() => {
    resetStates()
  }, [])
  return (
    <UnstakeLpContentContext.Provider
      value={{
        unstakeLpPercentage,
        setUnstakeLpPercentage,
        unstakeLpAmountStr,
        remainingStakedAmountStr,
        resetStates,
      }}
    >
      {children}
    </UnstakeLpContentContext.Provider>
  )
}

export default UnstakeLpContentProvider
