import { alpha } from '@mui/material'
import styled, { css } from 'styled-components'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'

export const DataTypography = styled(AppTypography).attrs(({ variant }) => ({
  variant: variant ? variant : 'body2',
}))``

export const RemainingPercentageLabel = styled(DataTypography)`
  display: none;
  font-size: 11px;
`
export const PercentageLabel = styled(DataTypography)``
export const RefreshButton = styled(AppButton)`
  display: none;
  min-width: unset;
  svg {
    font-size: 16px;
  }
  margin: 0;
  padding: 0;
`
export const MaxButton = styled(AppButton)`
  display: none;
  padding: 1px 3px;
  margin: 0;
  font-size: 8.5px;
  min-width: 45px;
  border-radius: 10px;
`

export const StyledInput = styled.input`
  background: unset;
  outline: unset;
  border: none;
  color: white;
  width: 40px;
  margin-right: 4px;
  border-radius: 0;
  font-size: inherit;
`
export const InputBackground = styled(DataTypography)`
  ${({ theme }) => css`
    background: ${alpha(theme.palette.primary.main, 0.3)};
    padding: 6px 4px;
    border-radius: ${theme.borderRadius.sm};
    margin-right: 6px;
    width: 75px;
    display: flex;
    justify-content: space-between;
    user-select: none;
  `}
`
export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &.UserVoteAllocationTable__input-wrapper--active,
    &:focus-within {
      ${RemainingPercentageLabel} {
        display: inline;
      }
      ${RefreshButton},${MaxButton} {
        display: flex;
      }

      ${PercentageLabel} {
        display: none;
      }

      ${InputBackground} {
        background: ${alpha(theme.palette.primary[900], 0.7)};
      }
    }
  `}
`

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: 8px;
    button {
      margin: 0;
      padding: 3px 8px;
    }
    &.UserVoteAllocationTable__middle-buttons {
      flex: 1;
      justify-content: space-between;
    }

    ${theme.breakpoints.down('md')} {
      width: 100%;
      button {
        width: 100% !important;
      }
    }
    ${theme.breakpoints.down('sm')} {
      flex-wrap: wrap;
    }
  `}
`
export const FlexItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px 0;
  flex-wrap: wrap;
  gap: 8px;
`

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    text-align: left;
    width: 100%;
    .Table__row .edited {
      border: 1px solid ${theme.palette.primary.main} !important;
    }
  `}
`
