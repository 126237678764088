import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import AppTypography from '../../../../components/AppTypography/AppTypography'
import InfoBox from '../../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../../components/InfoBox/TooltipNum'
import { RewardsTypeWithStrValue } from '../../../../config/contracts/pool/Pool'
import { Colorized } from '../../../../globalStyles'

interface Props {
  actualRewardsEarned: RewardsTypeWithStrValue
}

function PoolAutoClaimInfoBoxItem({
  actualRewardsEarned,
}: Props): ReactElement {
  return (
    <InfoBox.Item alignItems="flex-start">
      <InfoBox.Item>
        <AppTypography variant="caption">
          <Colorized>Auto Claim </Colorized>
        </AppTypography>
        <InfoBox.Tooltip text="This amount of token reward will be automatically claimed in this transaction." />
      </InfoBox.Item>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        {actualRewardsEarned.map((reward) => {
          return (
            <AppTypography variant="caption" key={reward.tokenSymbol}>
              <TooltipNum
                amount={reward.value}
                rightSymbol={reward.tokenSymbol}
              >
                <Colorized>
                  {getCommifiedFormat(reward.value)} {reward.tokenSymbol}
                </Colorized>
              </TooltipNum>
            </AppTypography>
          )
        })}
      </Box>
    </InfoBox.Item>
  )
}

export default PoolAutoClaimInfoBoxItem
