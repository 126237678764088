import {
  getDpFormat,
  getMillifiedFormat,
} from '@hailstonelabs/big-number-utils'
import React, { ReactElement, useState } from 'react'
import Slidable from '../../../components/animations/Slidable/Slidable'
import AccordionButton from '../../../components/buttons/AccordionButton/AccordionButton'
import TpYieldCard from '../../../components/cards/TpYieldCard/TpYieldCard'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { TP_YIELDS } from '../../../config/TpYield'
import { useBalance } from '../../../contexts/BalanceContext'
import { useTpYield } from '../../../contexts/TpYieldContext'
import { StyledDivider } from '../../../globalStyles'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import { HiddenContent } from './TpYieldInfoContainer.elements'

function TpYieldInfoContainer(): ReactElement {
  const [isToggleButtonActive, setIsToggleButtonActive] = useState(false)
  const { isTokenPriceFetched } = useBalance()
  const tpYieldData = useTpYield()
  return (
    <>
      <StyledDivider $colored style={{ margin: '10px 0 18px' }}>
        <TokenIcon
          style={{ transform: 'translateX(2px)' }}
          tokenSymbol={TokenSymbol.AVAX}
        />
        <TokenIcon margin="0 10px 0 0" tokenSymbol={TokenSymbol.PTP} />
        AVAX-PTP
        <AccordionButton
          onClick={() => setIsToggleButtonActive((prev) => !prev)}
          active={isToggleButtonActive}
        />
      </StyledDivider>
      {isToggleButtonActive && (
        <Slidable active={isToggleButtonActive} durationInSec={0.4}>
          <HiddenContent>
            {Object.values(TP_YIELDS)
              .filter(
                (tpYield) => tpYield.protocolTokenSymbol === TokenSymbol.PTP,
              )
              .map((tpYield) => {
                const tpYieldSymbol = tpYield.symbol
                // tpYield information
                const liquidity = tpYieldData
                  ? tpYieldData[tpYieldSymbol].liquidtyUsd
                  : '-'
                const formattedApr = `${
                  tpYieldData
                    ? getDpFormat(tpYieldData[tpYieldSymbol].apr, 1)
                    : '-'
                }%`
                return (
                  <TpYieldCard
                    key={tpYieldSymbol}
                    iconPath={tpYield.icon}
                    link={tpYield.urls.farm}
                    name={tpYield.name}
                    information={{
                      Liquidity: {
                        data: (
                          <TooltipNum
                            leftSymbol="$"
                            amount={liquidity}
                            format="commified"
                          >
                            $&nbsp;
                            {showDashIfNecessary(
                              !isTokenPriceFetched,
                              getMillifiedFormat(liquidity),
                            )}
                          </TooltipNum>
                        ),
                      },
                      APR: {
                        data: formattedApr,
                        Tooltip: 'Total APR from pool fee and token emission.',
                      },
                    }}
                  />
                )
              })}
          </HiddenContent>
        </Slidable>
      )}
    </>
  )
}

export default TpYieldInfoContainer
