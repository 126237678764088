import React, { ReactElement } from 'react'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { Image } from './PlatopiaImage.elements'

interface PlatopiaImageProps {
  data: string
  top?: number | undefined
  right?: number | undefined
  bottom?: number | undefined
  left?: number | undefined
  width: number | undefined
  height: number | undefined
  zIndex?: number | undefined
}

function PlatopiaImage({
  data,
  top,
  right,
  bottom,
  left,
  width,
  height,
  zIndex,
}: PlatopiaImageProps): ReactElement {
  const { width: windowWidth } = useWindowDimensions()

  return (
    <Image
      data={data}
      windowWidth={windowWidth}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      width={width}
      height={height}
      zIndex={zIndex}
    />
  )
}

export default PlatopiaImage
