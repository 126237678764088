import { tokenIconPaths, undefinedIconPath } from '../../../assets/tokens'
import { ChainId } from '../../networks'
import POOL_ABI from '../abis/Pool.json'
import POOL_AVAX_ABI from '../abis/PoolAvax.json'
import { MasterPlatypusId } from '../masterPlatypus'
import { TokenSymbol } from '../token/tokenSymbol'
import { POOL__ABI } from '../wagmiAbis/Pool'
import { POOLAVAX_ABI } from '../wagmiAbis/PoolAvax'
import { Pool } from './Pool'
import { POOL_GROUPS } from './PoolGroup'
import { PoolFilterSymbol, PoolSymbol, poolSymbols } from './poolSymbol'

export const POOLS: {
  [id in PoolSymbol]: Pool
} = {
  /* -------------------------------------------------------------------------- */
  /*                             Active Main Pools                              */
  /* -------------------------------------------------------------------------- */
  [PoolSymbol.MAIN]: new Pool({
    name: 'Main Pool',
    poolSymbol: PoolSymbol.MAIN,
    address: {
      [ChainId.AVALANCHE]: '0x5EE9008e49b922cAfef9Dde21446934547e42Ad6',
      [ChainId.FUJI]: '0x0F3876B7bBD753227fae5Eee1a4fB565fDdA4833',
    },
    icon: undefinedIconPath,
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_ABI,
    wagmiAbi: POOL__ABI,

    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  /* -------------------------------------------------------------------------- */
  /*                           Deprecated Main Pools                            */
  /* -------------------------------------------------------------------------- */
  /** stopped */
  [PoolSymbol.DEPRECATED_MAIN]: new Pool({
    name: 'Main Pool(deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_MAIN,
    address: {
      [ChainId.AVALANCHE]: '0x66357dCaCe80431aee0A7507e2E361B7e2402370',
      [ChainId.FUJI]: '0xA5AC3c1A10Ffd9F095F5D33ce6D11b85B56E8560',
    },
    icon: undefinedIconPath,
    // MIM LP in MAIN stayed in MasterPlatypusId.V1 but data and action will handle separately
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_ABI,
    wagmiAbi: POOL__ABI,

    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  // /** stopped */
  [PoolSymbol.DEPRECATED_MAIN2]: new Pool({
    name: 'Main Pool(deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_MAIN2,
    address: {
      [ChainId.AVALANCHE]: '0xbe52548488992Cc76fFA1B42f3A58F646864df45',
      [ChainId.FUJI]: '0x5f73a9abBcde061A53E5dE5D033d9aec4a5a9aeF',
    },
    icon: undefinedIconPath,
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_ABI,
    wagmiAbi: POOL__ABI,

    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  /* -------------------------------------------------------------------------- */
  /*                          Active Alt Pools                                  */
  /* -------------------------------------------------------------------------- */
  [PoolSymbol.ALT_BTCB_WBTCE]: new Pool({
    name: 'BTC Pool',
    poolSymbol: PoolSymbol.ALT_BTCB_WBTCE,
    address: {
      [ChainId.AVALANCHE]: '0xCEE2163c8d3c0D226659AA7D87a438C8d791684e',
      [ChainId.FUJI]: '0x27DF2aCf54126a0C9C3238E61794387bF3Ed0a00',
    },
    icon: tokenIconPaths[TokenSymbol.BTCb],
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_ABI,
    wagmiAbi: POOL__ABI,
    poolFilterSymbol: PoolFilterSymbol.BTC,
  }),
  [PoolSymbol.ALT_USDC_MIM]: new Pool({
    name: 'MIM Pool',
    poolSymbol: PoolSymbol.ALT_USDC_MIM,
    address: {
      [ChainId.AVALANCHE]: '0x860f7Ab2375087d5E16Af6843f85263A1B72888f',
      [ChainId.FUJI]: '0xFd6cD7870D6A4782eC9a1C80209E10dC8A630CC1',
    },
    icon: tokenIconPaths.MIM,
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_ABI,
    wagmiAbi: POOL__ABI,

    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  [PoolSymbol.ALT_USDC_FRAX]: new Pool({
    name: 'FRAX Pool',
    poolSymbol: PoolSymbol.ALT_USDC_FRAX,
    address: {
      [ChainId.AVALANCHE]: '0x2779ebcDb6C70d10174138f43892400e132F6dEB',
      [ChainId.FUJI]: '0x825D931C565069D7A1FBE72768627fd9be1b4c3A',
    },
    icon: tokenIconPaths.FRAX,
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_ABI,
    wagmiAbi: POOL__ABI,

    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  [PoolSymbol.ALT_AVAX_SAVAX]: new Pool({
    name: 'sAVAX Pool',
    poolSymbol: PoolSymbol.ALT_AVAX_SAVAX,
    address: {
      [ChainId.AVALANCHE]: '0xbBcabd0aB2d4d2788357a9b2F5C695aceA3e2E40',
      [ChainId.FUJI]: '0x5DAf1eF4185C957fAa6fA0cbeF782717D1540029',
    },
    icon: tokenIconPaths.sAVAX,
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_AVAX_ABI,
    wagmiAbi: POOLAVAX_ABI,
    poolFilterSymbol: PoolFilterSymbol.AVAX,
    availableTokensForSwap: [
      TokenSymbol.AVAX,
      TokenSymbol.WAVAX,
      TokenSymbol.SAVAX,
    ],
  }),
  [PoolSymbol.ALT_AVAX_YYAVAX]: new Pool({
    name: 'yyAVAX Pool',
    poolSymbol: PoolSymbol.ALT_AVAX_YYAVAX,
    address: {
      [ChainId.AVALANCHE]: '0x2C00cB6eE27aD73E95d54Ab5C49cB65C2F69BeC4',
      [ChainId.FUJI]: '0xD04EDe0Daf35ceB3549a4b8d9A02062A2c9e3D29',
    },
    icon: tokenIconPaths[TokenSymbol.YYAVAX],
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_AVAX_ABI,
    wagmiAbi: POOLAVAX_ABI,
    poolFilterSymbol: PoolFilterSymbol.AVAX,
    availableTokensForSwap: [
      TokenSymbol.AVAX,
      TokenSymbol.WAVAX,
      TokenSymbol.YYAVAX,
    ],
  }),
  [PoolSymbol.ALT_AVAX_ANKRAVAX]: new Pool({
    name: 'ankrAVAX Pool',
    poolSymbol: PoolSymbol.ALT_AVAX_ANKRAVAX,
    address: {
      [ChainId.AVALANCHE]: '0x5A2f12794Ebfb537b85b2630A39ee02c140c356E',
      [ChainId.FUJI]: '0x2066c9773af119e4871736CB804e60A7cB6622dd',
    },
    icon: tokenIconPaths[TokenSymbol.ANKRAVAX],
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_AVAX_ABI,
    wagmiAbi: POOLAVAX_ABI,

    poolFilterSymbol: PoolFilterSymbol.AVAX,
    availableTokensForSwap: [
      TokenSymbol.AVAX,
      TokenSymbol.WAVAX,
      TokenSymbol.ANKRAVAX,
    ],
  }),
  [PoolSymbol.ALT_USDC_YUSD]: new Pool({
    name: 'YUSD Pool',
    poolSymbol: PoolSymbol.ALT_USDC_YUSD,
    address: {
      [ChainId.AVALANCHE]: '0x5D473d944858f0D54a8539999d8fCCb7da2d2ce9',
      [ChainId.FUJI]: '0xD221BaaeE9E0A78b0567785c352FB119680a0829',
    },
    icon: tokenIconPaths.YUSD,
    masterPlatypusId: MasterPlatypusId.MP4,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  [PoolSymbol.ALT_ANKRETH_WETHE]: new Pool({
    name: 'ankrETH Pool',
    poolSymbol: PoolSymbol.ALT_ANKRETH_WETHE,
    address: {
      [ChainId.AVALANCHE]: '0x1f182E3d225603f9888B51f2CbCa687E044524F8',
      [ChainId.FUJI]: '0x705B49dd3Ddd3d6b6B22779687E707292ab4dBC8',
    },
    icon: tokenIconPaths.ankrETH,
    masterPlatypusId: MasterPlatypusId.MP4,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.ETH,
    tokenConversionRateData: {
      fromTokenSymbol: TokenSymbol.ANKRETH,
      toTokenSymbol: TokenSymbol.WETHe,
    },
  }),
  [PoolSymbol.ALT_USDC_MONEY]: new Pool({
    name: 'MONEY Pool',
    poolSymbol: PoolSymbol.ALT_USDC_MONEY,
    address: {
      [ChainId.AVALANCHE]: '0xcA0517f38A66Dd04c07c359E573f2ED737C8a592',
      [ChainId.FUJI]: '0xf27d681E17764f924C7C92Bd7E11135d1d2D004e',
    },
    icon: tokenIconPaths.MONEY,
    masterPlatypusId: MasterPlatypusId.MP4,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  /* -------------------------------------------------------------------------- */
  /*                      Deprecated Alt Pools                                  */
  /* -------------------------------------------------------------------------- */
  [PoolSymbol.DEPRECATED_ALT_BTCB_WBTCE]: new Pool({
    name: 'BTC Pool (deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_ALT_BTCB_WBTCE,
    address: {
      [ChainId.AVALANCHE]: '0x39dE4e02F76Dbd4352Ec2c926D8d64Db8aBdf5b2',
      [ChainId.FUJI]: '0xa593cb5D79C8Bb60CE1811Ada67Ce134ebE006D4',
    },
    icon: tokenIconPaths[TokenSymbol.BTCb],
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_ABI,
    wagmiAbi: POOL__ABI,
    poolFilterSymbol: PoolFilterSymbol.BTC,
  }),
  /** delisted */
  [PoolSymbol.DEPRECATED_ALT_USDC_UST]: new Pool({
    name: 'UST Pool (deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_UST,
    address: {
      [ChainId.AVALANCHE]: '0xe0D166DE15665bC4B7185B2e35E847E51316E126',
      [ChainId.FUJI]: '0x4313b8273E9174100650bc09a5D736410F2cc103',
    },
    icon: tokenIconPaths.UST,
    masterPlatypusId: MasterPlatypusId.MP3,
    abi: POOL_ABI,
    wagmiAbi: POOL__ABI,

    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  [PoolSymbol.DEPRECATED_ALT_USDC_MIM]: new Pool({
    name: 'MIM Pool (deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_MIM,
    address: {
      [ChainId.AVALANCHE]: '0x30C30d826be87Cd0A4b90855C2F38f7FcfE4eaA7',
      [ChainId.FUJI]: '0x4F04C88a6014f7aa4b0DCE23b69084357da6E8Ed',
    },
    icon: tokenIconPaths.MIM,
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_ABI,
    wagmiAbi: POOL__ABI,

    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  [PoolSymbol.DEPRECATED_ALT_USDC_FRAX]: new Pool({
    name: 'FRAX Pool (deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_FRAX,
    address: {
      [ChainId.AVALANCHE]: '0xB8E567fc23c39C94a1f6359509D7b43D1Fbed824',
      [ChainId.FUJI]: '0xf327Bc02A972Cd044091045EA5C4D03288A37301',
    },
    icon: tokenIconPaths.FRAX,
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_ABI,
    wagmiAbi: POOL__ABI,

    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  [PoolSymbol.DEPRECATED_ALT_AVAX_SAVAX]: new Pool({
    name: 'sAVAX Pool (deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_ALT_AVAX_SAVAX,
    address: {
      [ChainId.AVALANCHE]: '0x4658EA7e9960D6158a261104aAA160cC953bb6ba',
      [ChainId.FUJI]: '0xCd1a892c4d8d793B72CB18d0328A133140439135',
    },
    icon: tokenIconPaths.sAVAX,
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_AVAX_ABI,
    wagmiAbi: POOLAVAX_ABI,
    poolFilterSymbol: PoolFilterSymbol.AVAX,
    availableTokensForSwap: [
      TokenSymbol.AVAX,
      TokenSymbol.WAVAX,
      TokenSymbol.SAVAX,
    ],
  }),
  [PoolSymbol.DEPRECATED_ALT_AVAX_YYAVAX]: new Pool({
    name: 'yyAVAX Pool (deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_ALT_AVAX_YYAVAX,
    address: {
      [ChainId.AVALANCHE]: '0x8B4A45da5B0705Ae4f47EBeFC180C099345cF57e',
      [ChainId.FUJI]: '0xa4c54E9c3E29d3EF8678e28E32aB8917DD8A40a9',
    },
    icon: tokenIconPaths[TokenSymbol.YYAVAX],
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_AVAX_ABI,
    wagmiAbi: POOLAVAX_ABI,
    poolFilterSymbol: PoolFilterSymbol.AVAX,
    availableTokensForSwap: [
      TokenSymbol.AVAX,
      TokenSymbol.WAVAX,
      TokenSymbol.YYAVAX,
    ],
  }),
  [PoolSymbol.DEPRECATED_ALT_AVAX_ANKRAVAX]: new Pool({
    name: 'ankrAVAX Pool (deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_ALT_AVAX_ANKRAVAX,
    address: {
      [ChainId.AVALANCHE]: '0xDeD29DF6b2193B885F45B5F5027ed405291A96C1',
      [ChainId.FUJI]: '0xB58f0b4549cb3ED0905F3F760240415C3E3b0882',
    },
    icon: tokenIconPaths[TokenSymbol.ANKRAVAX],
    masterPlatypusId: MasterPlatypusId.MP4,
    abi: POOL_AVAX_ABI,
    wagmiAbi: POOLAVAX_ABI,

    poolFilterSymbol: PoolFilterSymbol.AVAX,
    availableTokensForSwap: [
      TokenSymbol.AVAX,
      TokenSymbol.WAVAX,
      TokenSymbol.ANKRAVAX,
    ],
  }),
  [PoolSymbol.DEPRECATED_ALT_USDC_TUSD]: new Pool({
    name: 'TUSD Pool (deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_TUSD,
    address: {
      [ChainId.AVALANCHE]: '0x13329C7905F1EE55c3C7D7Bfc26c1197c512c207',
      [ChainId.FUJI]: '0xc0D3C59de178D46EC95947b2C6a90933B51A6a88',
    },
    icon: tokenIconPaths.TUSD,
    masterPlatypusId: MasterPlatypusId.MP4,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  [PoolSymbol.DEPRECATED_ALT_USDC_YUSD]: new Pool({
    name: 'YUSD Pool (deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_YUSD,
    address: {
      [ChainId.AVALANCHE]: '0xC828D995C686AaBA78A4aC89dfc8eC0Ff4C5be83',
      [ChainId.FUJI]: '0x1cd2629cB7D97167b526B22D379C537A8a615ba8',
    },
    icon: tokenIconPaths.YUSD,
    masterPlatypusId: MasterPlatypusId.MP4,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  [PoolSymbol.DEPRECATED_ALT_ANKRETH_WETHE]: new Pool({
    name: 'ankrETH Pool (deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_ALT_ANKRETH_WETHE,
    address: {
      [ChainId.AVALANCHE]: '0xb3393f4e609c504da770ebc968540784cc4e016c',
      [ChainId.FUJI]: '0x78adBEE77F49307d6a8d981D3d9FD46514bA70F8',
    },
    icon: tokenIconPaths.ankrETH,
    masterPlatypusId: MasterPlatypusId.MP4,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.ETH,
    tokenConversionRateData: {
      fromTokenSymbol: TokenSymbol.ANKRETH,
      toTokenSymbol: TokenSymbol.WETHe,
    },
  }),
  [PoolSymbol.DEPRECATED_ALT_USDC_MONEY]: new Pool({
    name: 'MONEY Pool (deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_MONEY,
    address: {
      [ChainId.AVALANCHE]: '0x27912AE6Ba9a54219d8287C3540A8969FF35500B',
      [ChainId.FUJI]: '0x2fb103C6BA8C717bDBEbCF4993d719de4d45725b',
    },
    icon: tokenIconPaths.MONEY,
    masterPlatypusId: MasterPlatypusId.MP4,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  [PoolSymbol.DEPRECATED_ALT_USDC_AXLUSDC]: new Pool({
    name: 'axlUSDC Pool (deprecated)',
    poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_AXLUSDC,
    address: {
      [ChainId.AVALANCHE]: '0x81E63d0EEBA2D85609A6b206737e98e39B888F4C',
      [ChainId.FUJI]: '0xeF43Fc271AA539D3d19f2322F02E68cBEFB4480F',
    },
    icon: tokenIconPaths.axlUSDC,
    masterPlatypusId: MasterPlatypusId.MP4,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  /* -------------------------------------------------------------------------- */
  /*                      Deprecated Factory Pools                              */
  /* -------------------------------------------------------------------------- */
  // delisted
  [PoolSymbol.FACTORY_USDC_H2O]: new Pool({
    name: 'H2O Pool',
    poolSymbol: PoolSymbol.FACTORY_USDC_H2O,
    address: {
      [ChainId.AVALANCHE]: '0x233Ba46B01d2FbF1A31bDBc500702E286d6de218',
      [ChainId.FUJI]: '0xe005cA7A998f1B9E9E84eC6d8c8700d87a72E3aC',
    },
    icon: tokenIconPaths.H2O,
    masterPlatypusId: MasterPlatypusId.BASE_MPV2,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  /** delisted */
  [PoolSymbol.FACTORY_USDC_MONEY]: new Pool({
    name: 'MONEY Pool',
    poolSymbol: PoolSymbol.FACTORY_USDC_MONEY,
    address: {
      [ChainId.AVALANCHE]: '0x27912AE6Ba9a54219d8287C3540A8969FF35500B',
      [ChainId.FUJI]: '0x2fb103C6BA8C717bDBEbCF4993d719de4d45725b',
    },
    icon: tokenIconPaths.MONEY,
    masterPlatypusId: MasterPlatypusId.BASE_MPV1,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  /** delisted */
  [PoolSymbol.FACTORY_USDC_MIMATIC]: new Pool({
    name: 'MAI Pool',
    poolSymbol: PoolSymbol.FACTORY_USDC_MIMATIC,
    address: {
      [ChainId.AVALANCHE]: '0xF823a18070dFC733520D84bB57D4F7d3350854Ab',
      [ChainId.FUJI]: '0x858ecE5764693a2b440124a54FcA939e9745137E',
    },
    icon: tokenIconPaths.miMatic,
    masterPlatypusId: MasterPlatypusId.BASE_MPV1,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  [PoolSymbol.FACTORY_USDC_TSD]: new Pool({
    name: 'TSD Pool',
    poolSymbol: PoolSymbol.FACTORY_USDC_TSD,
    address: {
      [ChainId.AVALANCHE]: '0x91BB10D68C72d64a7cE10482b453153eEa03322C',
      [ChainId.FUJI]: '0xfF1cB0E4130EA58Ca43548DB3B6B4D235113A778',
    },
    icon: tokenIconPaths.TSD,
    masterPlatypusId: MasterPlatypusId.BASE_MPV2,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
  [PoolSymbol.FACTORY_USDC_USX]: new Pool({
    name: 'USX Pool',
    poolSymbol: PoolSymbol.FACTORY_USDC_USX,
    address: {
      [ChainId.AVALANCHE]: '0x89E9EFD9614621309aDA948a761D364F0236eDEA',
      [ChainId.FUJI]: '0x895450811Ebd084b01BDd601d92538Cf04715D41',
    },
    icon: tokenIconPaths.USX,
    masterPlatypusId: MasterPlatypusId.BASE_MPV2,
    wagmiAbi: POOL__ABI,
    abi: POOL_ABI,
    poolFilterSymbol: PoolFilterSymbol.USD,
  }),
}

export const LOWERCASE_AVAILABLE_LP_TOKEN_ADDRESS_LIST = {
  [ChainId.AVALANCHE]: poolSymbols.reduce((prev, poolSymbol) => {
    return [
      ...prev,
      ...POOLS[poolSymbol].getAssets().map(
        // the addresses in platypus exchange subgraph are lowercase.
        (asset) => (asset.address[ChainId.AVALANCHE] || '').toLowerCase(),
      ),
    ]
  }, [] as string[]),
}

// Setup lpToken sorting id
let sortingIdCounter = 1

for (const poolGroup of Object.values(POOL_GROUPS)) {
  for (const poolSymbol of poolGroup.poolSymbols) {
    const pool = POOLS[poolSymbol]
    const assets = pool.getAssets()
    assets.forEach((asset) => {
      asset.sortingId = sortingIdCounter
      sortingIdCounter++
    })
  }
}

export const DEPRECATED_GAUGES_POOLS = [
  PoolSymbol.DEPRECATED_MAIN,
  PoolSymbol.DEPRECATED_MAIN2,
  PoolSymbol.DEPRECATED_ALT_ANKRETH_WETHE,
  PoolSymbol.DEPRECATED_ALT_AVAX_ANKRAVAX,
  PoolSymbol.DEPRECATED_ALT_AVAX_SAVAX,
  PoolSymbol.DEPRECATED_ALT_AVAX_YYAVAX,
  PoolSymbol.DEPRECATED_ALT_BTCB_WBTCE,
  PoolSymbol.DEPRECATED_ALT_USDC_FRAX,
  PoolSymbol.DEPRECATED_ALT_USDC_MIM,
  PoolSymbol.DEPRECATED_ALT_USDC_MONEY,
  PoolSymbol.DEPRECATED_ALT_USDC_YUSD,
]

export const DELISTED_POOLS = [
  ...DEPRECATED_GAUGES_POOLS,
  PoolSymbol.FACTORY_USDC_H2O,
  PoolSymbol.FACTORY_USDC_MIMATIC,
  PoolSymbol.FACTORY_USDC_MONEY,
  PoolSymbol.FACTORY_USDC_TSD,
  PoolSymbol.FACTORY_USDC_USX,
]

for (const poolSym of DEPRECATED_GAUGES_POOLS) {
  for (const asset of POOLS[poolSym].getAssets(
    'deprecatedMainPoolForGauageVoting',
  )) {
    asset.sortingId = sortingIdCounter
    sortingIdCounter++
  }
}
