import { CheckboxProps, FormControlLabel } from '@mui/material'
import React, { ReactElement } from 'react'
import AppTypography from '../../AppTypography/AppTypography'
import { StyledCheckBox } from './AppCheckBox.elements'

interface Props extends CheckboxProps {
  $size?: number
  label?: string | ReactElement
}
function AppCheckBox({
  $size,
  label,
  onChange,
  style,
  ...otherProps
}: Props): ReactElement {
  return (
    <FormControlLabel
      control={
        <StyledCheckBox $size={$size} onChange={onChange} {...otherProps} />
      }
      label={
        typeof label === 'string' ? (
          <AppTypography variant="caption">{label}</AppTypography>
        ) : (
          label || ''
        )
      }
      style={{ ...style, userSelect: 'none' }}
      labelPlacement={typeof label === 'string' ? 'end' : 'start'}
    />
  )
}

export default AppCheckBox
