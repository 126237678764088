import {
  getParsableString,
  strToWad,
  wmul,
} from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import cloneDeep from 'lodash.clonedeep'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  TOKENS,
  TOKEN_SYMBOLS_FOR_DATA_FETCHING,
} from '../config/contracts/token'
import { FetchPriceMethod } from '../config/contracts/token/Token'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import { COIN_GECKO_API, COIN_MARKET_CAP_API, WAD_DECIMALS } from '../constants'
import {
  EMPTY_LP_TOKEN_APPROVALS,
  EMPTY_POOLSYMBOL_TOKENSYMBOL_STR,
  EMPTY_TOKENSYMBOL_STR,
  EMPTY_TOKEN_APPROVALS,
} from '../constants/empty'
import { usePoller } from '../hooks/usePoller'
import {
  PoolSymbolTokenSymbolStringType,
  TokenSymbolStringType,
} from '../interfaces/common'
import { ApproveSpender } from '../interfaces/spender'
import { useMulticallData } from './MulticallDataContext'

export interface ContextType {
  tokenAmounts: TokenSymbolStringType
  lpTokenAmounts: PoolSymbolTokenSymbolStringType
  tokenPrices: TokenSymbolStringType
  tokenPrices24hChange: TokenSymbolStringType
  tokenApprovals: {
    [ApproveSpender.VePtp]: {
      [TokenSymbol.PTP]: string
    }
    [ApproveSpender.WAVAX]: {
      [TokenSymbol.WAVAX]: string
    }
    [ApproveSpender.Router]: TokenSymbolStringType
    [ApproveSpender.Pool]: PoolSymbolTokenSymbolStringType
    [ApproveSpender.PlatypusTreasure]: {
      [TokenSymbol.USP]: string
    }
  }
  lpTokenApprovals: {
    [ApproveSpender.MasterPlatypus]: PoolSymbolTokenSymbolStringType
    [ApproveSpender.Pool]: PoolSymbolTokenSymbolStringType
    [ApproveSpender.EmergencyWithdraw]: PoolSymbolTokenSymbolStringType
  }
  isTokenPriceFetched: boolean
}

const initValue = {
  tokenAmounts: cloneDeep(EMPTY_TOKENSYMBOL_STR),
  lpTokenAmounts: cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_STR),
  tokenPrices: cloneDeep(EMPTY_TOKENSYMBOL_STR),
  tokenPrices24hChange: cloneDeep(EMPTY_TOKENSYMBOL_STR),
  tokenApprovals: cloneDeep(EMPTY_TOKEN_APPROVALS),
  lpTokenApprovals: cloneDeep(EMPTY_LP_TOKEN_APPROVALS),
  isTokenPriceFetched: false,
}

export const BalanceContext = createContext<ContextType>(initValue)
BalanceContext.displayName = 'BalanceContext'

export const useBalance = (): ContextType => {
  return useContext(BalanceContext)
}

interface Props {
  children: React.ReactNode
}

interface CoinGeckoReponse {
  [tokenSymbol: string]: {
    usd: number
    usd_24h_change: number
  }
}

interface CmcResponse {
  [cmcId: string]: {
    price: number
    percent_change_24h: number
  }
}

export const BalanceProvider = ({ children }: Props): ReactElement => {
  const { balanceData, isMulticallDataFetched, scPriceData } =
    useMulticallData()
  const [tokenPrices, setTokenPrices] = useState<TokenSymbolStringType>(
    initValue.tokenPrices,
  )
  const [tokenPricesFromGeckoOrCmc, setTokenPricesFromGeckoOrCmc] =
    useState<TokenSymbolStringType>(initValue.tokenPrices)
  const [tokenPrices24hChange, setTokenPrices24hChange] =
    useState<TokenSymbolStringType>(initValue.tokenPrices24hChange)
  const [isTokenPriceFetched, setIsTokenPriceFetched] = useState(false)
  let tokenAmounts = cloneDeep(EMPTY_TOKENSYMBOL_STR)
  let lpTokenAmounts = cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_STR)
  const tokenApprovals = cloneDeep(EMPTY_TOKEN_APPROVALS)
  const lpTokenApprovals = cloneDeep(EMPTY_LP_TOKEN_APPROVALS)
  if (balanceData) {
    tokenAmounts = balanceData.tokenAmounts
    lpTokenAmounts = balanceData.lpTokenAmounts
    const {
      uspForPlatypusTreasureApproval,
      ptpForVePtpApproval,
      lpTokenForEmergencyWithdrawApproval,
      tokenForPoolApprovals,
      lpTokenForMasterPlatypusApprovals,
      lpTokenForPoolApprovals,
      tokenForRouterApprovals,
      wavaxApproval,
    } = balanceData
    tokenApprovals.platypusTreasure.USP = uspForPlatypusTreasureApproval
    tokenApprovals.vePtp.PTP = ptpForVePtpApproval
    tokenApprovals.WAVAX.WAVAX = wavaxApproval
    lpTokenApprovals.emergencyWithdraw = lpTokenForEmergencyWithdrawApproval
    tokenApprovals.pool = tokenForPoolApprovals
    lpTokenApprovals.masterPlatypus = lpTokenForMasterPlatypusApprovals
    lpTokenApprovals.pool = lpTokenForPoolApprovals
    tokenApprovals.router = tokenForRouterApprovals
  }
  usePoller(
    () => {
      /** @TODO move fetch Price to Price context */
      const fetchPrice = async () => {
        const newTokenPrice = { ...EMPTY_TOKENSYMBOL_STR }
        const newTokenPrice24hChange = { ...EMPTY_TOKENSYMBOL_STR }
        try {
          const geckoIds: string[] = []
          const cmcIds: number[] = []
          for (let i = 0; i < TOKEN_SYMBOLS_FOR_DATA_FETCHING.length; i++) {
            const token = TOKENS[TOKEN_SYMBOLS_FOR_DATA_FETCHING[i]]
            if (
              token.priceFetchingConfig.method ===
              FetchPriceMethod.COINGECKO_COINMARKETCAP
            ) {
              const priceFetchingConfigPayload =
                token.priceFetchingConfig.payload
              geckoIds.push(priceFetchingConfigPayload.geckoId)
              cmcIds.push(priceFetchingConfigPayload.cmcId)
            }
          }
          const geckoResponse = await fetch(
            `${COIN_GECKO_API}?ids=${encodeURIComponent(
              geckoIds.join(','),
            )}&vs_currencies=usd&include_market_cap=true&include_24hr_change=true`,
          )
          /** @todo refactor cmc fallback */
          const geckoBody = (await geckoResponse.json()) as CoinGeckoReponse
          const allGeckoIdIncluded = Object.keys(geckoBody).every((geckoId) =>
            geckoIds.includes(geckoId),
          )

          if (allGeckoIdIncluded) {
            for (const sym of TOKEN_SYMBOLS_FOR_DATA_FETCHING) {
              const token = TOKENS[sym]
              if (
                token.priceFetchingConfig.method ===
                FetchPriceMethod.COINGECKO_COINMARKETCAP
              ) {
                const geckoId = token.priceFetchingConfig.payload.geckoId
                if (geckoBody[geckoId]) {
                  const { usd, usd_24h_change } = geckoBody[geckoId]
                  newTokenPrice[sym] = getParsableString(
                    String(usd),
                    WAD_DECIMALS,
                    true,
                  )
                  newTokenPrice24hChange[sym] = getParsableString(
                    String(usd_24h_change),
                    WAD_DECIMALS,
                    false,
                  )
                }
              }
            }
          } else {
            const cmcResponse = await fetch(
              `${COIN_MARKET_CAP_API}?id=${cmcIds.join(',')}`,
            )
            const cmcBody = (await cmcResponse.json()) as CmcResponse
            for (const sym of TOKEN_SYMBOLS_FOR_DATA_FETCHING) {
              const token = TOKENS[sym]
              if (
                token.priceFetchingConfig.method ===
                FetchPriceMethod.COINGECKO_COINMARKETCAP
              ) {
                const cmcId = token.priceFetchingConfig.payload.cmcId
                const cmcIdStr = cmcId.toString()
                if (cmcBody[cmcIdStr]) {
                  const { price, percent_change_24h } = cmcBody[cmcIdStr]
                  newTokenPrice[sym] = getParsableString(
                    //Fixed deciaml since cmc api will return token price with dp larger than 18, which is not parsable
                    String(price.toFixed(8)),
                    WAD_DECIMALS,
                    true,
                  )
                  newTokenPrice24hChange[sym] = getParsableString(
                    String(percent_change_24h),
                    WAD_DECIMALS,
                    false,
                  )
                }
              }
            }
          }
          setTokenPricesFromGeckoOrCmc(newTokenPrice)
          setTokenPrices24hChange(newTokenPrice24hChange)
          setIsTokenPriceFetched(true)
        } catch (err) {
          setIsTokenPriceFetched(false)
          console.error(err)
        }
      }
      void fetchPrice()
      // update every 60 seconds
    },
    [isMulticallDataFetched],
    120000, // 2 minutes
  )
  const aggregatePrice = useCallback(() => {
    let initTokenPrices: { [token in TokenSymbol]?: string } = {}
    // Push to initPricesInUsdWad
    initTokenPrices = { ...tokenPricesFromGeckoOrCmc }
    // Push platypus method Prices to initPricesInUsdWad
    for (const [tokenSymbolStr, token] of Object.entries(TOKENS)) {
      const tokenSymbol = tokenSymbolStr as TokenSymbol
      if (token.priceFetchingConfig.method !== FetchPriceMethod.PLATYPUS)
        continue
      const toTokenSymbol = token.priceFetchingConfig.payload.toTokenSymbol
      const platypusExchangeRate =
        scPriceData.withoutAccount?.exchangeRatesFromPlatypus[tokenSymbol]
      // if no platypusExchangeRate, then assign it to toTokenSymbol price
      const toTokenPrice = initTokenPrices[toTokenSymbol]
      initTokenPrices[tokenSymbol] = toTokenPrice
      if (!toTokenPrice || !platypusExchangeRate) continue
      initTokenPrices[tokenSymbol] = utils.formatEther(
        wmul(strToWad(toTokenPrice), strToWad(platypusExchangeRate)),
      )
    }
    setTokenPrices((prev) => ({ ...prev, ...initTokenPrices }))
  }, [
    scPriceData.withoutAccount?.exchangeRatesFromPlatypus,
    tokenPricesFromGeckoOrCmc,
  ])
  useEffect(() => {
    aggregatePrice()
  }, [aggregatePrice])
  return (
    <BalanceContext.Provider
      value={{
        tokenAmounts,
        lpTokenAmounts,
        tokenPrices,
        tokenPrices24hChange,
        tokenApprovals,
        lpTokenApprovals,
        isTokenPriceFetched,
      }}
    >
      {children}
    </BalanceContext.Provider>
  )
}
