import { Box, Typography } from '@mui/material'
import styled from 'styled-components'
import TextWithToolTipComponent from '../TextWithToolTip/TextWithToolTip'
import icon from './calculator-icon.svg'

export const Container = styled(Box)`
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  background: linear-gradient(
    180deg,
    rgba(4, 26, 32, 0.8) 0%,
    rgba(4, 26, 32, 0.48) 100%
  );
  border-radius: 10px;
`

export const CalculatorIcon = styled.img.attrs({
  src: icon,
})`
  width: 16px;
  height: 16px;
  margin-left: 6px;
  color: ${({ theme }) => theme.palette.platopia.main};
`

export const TextWithToolTip = styled(TextWithToolTipComponent).attrs({
  textColor: '#fff',
  fontSize: '0.875rem',
})``

export const CalculatorButton = styled(Box)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const CalculatorText = styled(Typography)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.platopia.main};
`
