import React from 'react'
import WaitForConfirmationModal from '../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import DisabledAppTokenInput from '../../../components/TokenInput/DisabledAppTokenInput'
import { useModal } from '../../../contexts/ModalContext'

/**
 * DeprecatedPoolWithdrawWaitForConfirmationModalContainer
 */
interface Props {
  isOpen: boolean
}

export function DeprecatedPoolWithdrawWaitForConfirmationModalContainer({
  isOpen,
}: Props): React.ReactElement {
  const {
    modalDispatch,
    modalState,
    actions: { closeModal },
  } = useModal()
  const { tokenSymbols, value: stakedLpinTermsOfLp } = modalState
  const tokenSymbol = tokenSymbols && tokenSymbols[0]
  const handleCloseModal = () => {
    modalDispatch(closeModal())
  }
  if (!tokenSymbol) return <></>
  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="600px"
      subtitle="Confirm Withdrawal"
    >
      <DisabledAppTokenInput
        tokenItems={[
          {
            tokenSymbol: tokenSymbol,
            value: stakedLpinTermsOfLp || '0.0',
          },
        ]}
      />
    </WaitForConfirmationModal>
  )
}
