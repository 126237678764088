import { Call } from 'ethcall'
import { BigNumber } from 'ethers'
import cloneDeep from 'lodash.clonedeep'
import { TOKENS } from '../../../config/contracts'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import {
  traderJoeAvaxPtpJlp,
  traderJoeAvaxPtpRewarder,
  traderJoeMasterChefV3,
} from '../../../config/contracts/tpYield'
import { ChainId } from '../../../config/networks'
import { TpYieldSymbols, TP_YIELDS } from '../../../config/TpYield'
import { TRADERJOE_AVAX_PTP_TPYIELD_PID } from '../../../constants'
import { EMPTY_TP_YIELD_LIQUIDTY_WAD } from '../../../constants/empty'
import { TpYieldLiquidtyWADType } from '../../../interfaces/TpYield'
import {
  CallbacksType,
  MulticallHelperSCReturnType,
} from '../../../utils/multicall'

export interface TpYieldDataType {
  traderJoeTpYieldAvaxPtpData: traderJoeTpYieldDataType
  tpYieldLiquidtyWAD: TpYieldLiquidtyWADType
}

interface traderJoeTpYieldDataType {
  traderJoeTpYieldJoePerSecWAD: null | BigNumber
  traderJoeTpYieldTotalAllocPointBN: null | BigNumber
  traderJoeTpYieldAllocPointBN: null | BigNumber
  traderJoeTpYieldPtpPerSecWAD: null | BigNumber
  traderJoeTpYieldLpSuppliesWAD: null | BigNumber
  traderJoeTpYieldLpStakedWAD: null | BigNumber
}
// we can pass chainId and account to the functions if necessary
export const fetchTpYieldData = (
  chainId: ChainId,
): {
  contractCalls: Call[]
  callbacks: CallbacksType<MulticallHelperSCReturnType>
  states: TpYieldDataType
} => {
  /**
   * A. Create empty contractCalls and callbacks array
   */
  const contractCalls: Call[] = []
  const callbacks: CallbacksType<MulticallHelperSCReturnType> = []
  /**
   * TpYield Data:
   * 1. traderJoeTpYield apr elements
   * 2. All TpYield liquidty
   */
  const states: TpYieldDataType = {
    traderJoeTpYieldAvaxPtpData: {
      traderJoeTpYieldJoePerSecWAD: null,
      traderJoeTpYieldTotalAllocPointBN: null,
      traderJoeTpYieldAllocPointBN: null,
      traderJoeTpYieldPtpPerSecWAD: null,
      traderJoeTpYieldLpSuppliesWAD: null,
      traderJoeTpYieldLpStakedWAD: null,
    },
    tpYieldLiquidtyWAD: cloneDeep(EMPTY_TP_YIELD_LIQUIDTY_WAD),
  }

  const handleTpYieldData = () => {
    /** TpYield */
    // TpYield liquildty fetching
    for (let k = 0; k < TpYieldSymbols.length; k++) {
      const tpYieldId = TpYieldSymbols[k]
      const lpAddress = TP_YIELDS[tpYieldId].lpAddress
      const ptpContract = TOKENS[TokenSymbol.PTP].get(chainId)
      const wavaxContract = TOKENS[TokenSymbol.WAVAX].get(chainId)
      if (ptpContract) {
        contractCalls.push(ptpContract.balanceOf(lpAddress) as unknown as Call)
        callbacks.push((balanceWAD) => {
          states.tpYieldLiquidtyWAD[tpYieldId].PTP = balanceWAD as BigNumber
        })
      }
      if (wavaxContract) {
        contractCalls.push(
          wavaxContract.balanceOf(lpAddress) as unknown as Call,
        )
        callbacks.push((balanceWAD) => {
          states.tpYieldLiquidtyWAD[tpYieldId].AVAX = balanceWAD as BigNumber
        })
      }
    }
    // TraderJoe TpYield APR fetching
    const traderJoeMasterChefV3Contract = traderJoeMasterChefV3.get(chainId)
    const traderJoeAvaxPtpJlpContract = traderJoeAvaxPtpJlp.get(chainId)
    const traderJoeAvaxPtpRewarderContract =
      traderJoeAvaxPtpRewarder.get(chainId)
    if (traderJoeMasterChefV3Contract) {
      contractCalls.push(
        traderJoeMasterChefV3Contract.joePerSec() as unknown as Call,
      )
      callbacks.push((value) => {
        states.traderJoeTpYieldAvaxPtpData.traderJoeTpYieldJoePerSecWAD =
          value as BigNumber
      })
      contractCalls.push(
        traderJoeMasterChefV3Contract.totalAllocPoint() as unknown as Call,
      )
      callbacks.push((value) => {
        states.traderJoeTpYieldAvaxPtpData.traderJoeTpYieldTotalAllocPointBN =
          BigNumber.from(value as number)
      })
      contractCalls.push(
        traderJoeMasterChefV3Contract.poolInfo(
          TRADERJOE_AVAX_PTP_TPYIELD_PID,
        ) as unknown as Call,
      )
      callbacks.push((value) => {
        states.traderJoeTpYieldAvaxPtpData.traderJoeTpYieldAllocPointBN =
          BigNumber.from((value as { allocPoint: number }).allocPoint)
      })
    }
    if (traderJoeAvaxPtpRewarderContract) {
      contractCalls.push(
        traderJoeAvaxPtpRewarderContract.tokenPerSec() as unknown as Call,
      )
      callbacks.push((value) => {
        states.traderJoeTpYieldAvaxPtpData.traderJoeTpYieldPtpPerSecWAD =
          value as BigNumber
      })
    }
    if (traderJoeAvaxPtpJlpContract) {
      contractCalls.push(
        traderJoeAvaxPtpJlpContract.totalSupply() as unknown as Call,
      )
      callbacks.push((value) => {
        states.traderJoeTpYieldAvaxPtpData.traderJoeTpYieldLpSuppliesWAD =
          value as BigNumber
      })
      const masterChefJoeV3Address = traderJoeMasterChefV3.getAddress(chainId)
      if (masterChefJoeV3Address) {
        contractCalls.push(
          traderJoeAvaxPtpJlpContract.balanceOf(
            masterChefJoeV3Address,
          ) as unknown as Call,
        )
        callbacks.push((value) => {
          states.traderJoeTpYieldAvaxPtpData.traderJoeTpYieldLpStakedWAD =
            value as BigNumber
        })
      }
    }
  }
  handleTpYieldData()
  return {
    contractCalls,
    callbacks,
    states,
  }
}
