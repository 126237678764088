import { PoolSymbol } from '../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'

export enum ModalId {
  UNSET = 'UNSET',
  CONNECT_TO_WALLET = 'CONNECT_TO_WALLET',
  CONNECTED_WALLET = 'CONNECTED_WALLET',
  SELECT_FROM_TOKEN = 'SELECT_FROM_TOKEN',
  SELECT_TO_TOKEN = 'SELECT_TO_TOKEN',
  CONFIRM_SWAP = 'CONFIRM_SWAP',
  SWAP_WAIT_FOR_CONFIRMATION = 'WAIT_FOR_CONFIRMATION',
  TRANSACTION_SUBMITTED = 'TRANSACTION_SUBMITTED',
  POOL_DEPOSIT_LIQUIDITY = 'POOL_DEPOSIT_LIQUIDITY',
  POOL_DEPOSIT_WAIT_FOR_CONFIRMATION = 'POOL_DEPOSIT_WAIT_FOR_CONFIRMATION',
  POOL_WITHDRAW_LIQUIDITY = 'POOL_WITHDRAW_LIQUIDITY',
  POOL_WITHDRAW_WAIT_FOR_CONFIRMATION = 'POOL_WITHDRAW_WAIT_FOR_CONFIRMATION',
  USER_PREFERENCE = 'USER_PREFERENCE',
  POOL_STAKE_LP = 'POOL_STAKE_LP',
  POOL_STAKE_WAIT_FOR_CONFIRMATION = 'POOL_STAKE_WAIT_FOR_CONFIRMATION',
  POOL_UNSTAKE_LP = 'POOL_UNSTAKE_LP',
  POOL_UNSTAKE_WAIT_FOR_CONFIRMATION = 'POOL_UNSTAKE_WAIT_FOR_CONFIRMATION',
  POOL_CLAIM_WAIT_FOR_CONFIRMATION = 'POOL_CLAIM_WAIT_FOR_CONFIRMATION',
  STAKE_PTP = 'STAKE_PTP',
  STAKE_WAIT_FOR_CONFIRMATION = 'STAKE_WAIT_FOR_CONFIRMATION',
  UNSTAKE_PTP = 'UNSTAKE_PTP',
  CONFIRM_UNSTAKE_PTP = 'CONFIRM_UNSTAKE_PTP',
  UNSTAKE_WAIT_FOR_CONFIRMATION = 'UNSTAKE_WAIT_FOR_CONFIRMATION',
  DEPREACATED_POOL_WITHDRAW_WAIT_FOR_CONFIRMATION = 'DEPREACATED_POOL_WITHDRAW_WAIT_FOR_CONFIRMATION',
  CLAIM_VEPTP_WAIT_FOR_CONFIRMATION = 'CLAIM_VEPTP_WAIT_FOR_CONFIRMATION',
  VEPTP_BOOSTER_CALCULATOR = 'VEPTP_BOOSTER_CALCULATOR',
  MIGRATION_WAIT_FOR_CONFIRMATION = 'MIGRATION_WAIT_FOR_CONFIRMATION',
  MIGRATION_COMPLETED = 'MIGRATION_COMPLETED',
  VOTE_WAIT_FOR_CONFIRMATION = 'VOTE_WAIT_FOR_CONFIRMATION',
  FEE_SHARING = 'FEE_SHARING',

  /**
   * PTP Locking related
   */
  LOCK_PTP = 'LOCK_PTP',
  LOCK_PTP_WAIT_FOR_CONFIRMATION = 'LOCK_PTP_WAIT_FOR_CONFIRMATION',
  LOCK_EXTEND_PTP_WAIT_FOR_CONFIRMATION = 'LOCK_EXTEND_PTP_WAIT_FOR_CONFIRMATION',
  UNLOCK_PTP_WAIT_FOR_CONFIRMATION = 'UNLOCK_PTP_WAIT_FOR_CONFIRMATION',
  EXTEND_LOCK_PTP = 'EXTEND_LOCK_PTP',

  /**
   * NFT related
   */
  CHANGE_NFT = 'CHANGE_NFT',
  CHANGE_NFT_WAIT_FOR_CONFIRMATION = 'CHANGE_NFT_WAIT_FOR_CONFIRMATION',
  EQUIP_NFT_CONFIRMATION = 'EQUIP_NFT_CONFIRMATION',
  UNEQUIP_NFT_CONFIRMATION = 'UNEQUIP_NFT_CONFIRMATION',
  CHANGE_NFT_CONFIRMATION = 'CHANGE_NFT_CONFIRMATION',

  /**
   * Airdrop related
   */
  SELECT_VEPTP_AIRDROP = 'SELECT_VEPTP_AIRDROP',
  UNLOCK_PTP = 'UNLOCK_PTP',
  CONFIRM_UNLOCK_PTP = 'CONFIRM_UNLOCK_PTP',

  /**
   * USP related
   */
  USP_BORROW = 'USP_BORROW',
  USP_BORROW_WAIT_FOR_CONFIRMATION = 'USP_BORROW_WAIT_FOR_CONFIRMATION',
  USP_REPAY_WAIT_FOR_CONFIRMATION = 'USP_REPAY_WAIT_FOR_CONFIRMATION',
  USP_LIQUIDATED_RECORD = 'USP_LIQUIDATED_RECORD',
  USP_REPAY = 'USP_REPAY',
}

export interface ModalNftDataState {
  current: { id: string } | null
  changeTo: { id: string } | null
}
export interface ModalState {
  currentModalId: ModalId
  transactionHashes?: string[]
  tokenSymbols?: TokenSymbol[]
  poolSymbols?: PoolSymbol[]
  lpTokenAddresses?: string[]
  value?: string
  /**
   * 1. Equipment => current is null and changeTo contains an object
   * 2. Unequipment => current contains an object and changeTo is null
   * 2. Change => both current and changeTo contain objects
   */
  nftData?: ModalNftDataState
}

export enum ModalActionType {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export interface ModalAction {
  type: ModalActionType
  payload: ModalState
}
