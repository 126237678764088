import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppTypography from '../../../components/AppTypography/AppTypography'
export const Warning = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${theme.palette.accents.yellow};
    text-align: center;
    .warning__icon {
      font-size: 48px;
      margin-bottom: 12px;
    }
    .warning__message {
      max-width: 400px;
    }
  `}
`
export const Subtitle = styled(AppTypography).attrs(() => ({
  variant: 'caption',
}))`
  ${({ theme }) => css`
    display: inline-block;
    width: 100%;
    text-align: center;
    color: ${alpha(theme.palette.common.white, 0.5)};
    margin: 8px 0;
  `}
`
