import {
  JoePair,
  MasterChefJoeV3,
  SimpleRewarder,
} from '../../../../types/ethers-contracts'
import { ChainId } from '../../networks'
import TraderJoeAvaxPtpJlpABI from '../abis/TraderJoe/JoePair.json'
import TraderJoeMasterChefV3ABI from '../abis/TraderJoe/MasterChefJoeV3.json'
import TraderJoeAvaxPtpRewarderABI from '../abis/TraderJoe/SimpleRewarder.json'
import { Contract } from '../Contract'
import { JOE_PAIR_ABI } from '../wagmiAbis/TraderJoe/JoePair'
import { MASTER_CHEF_JOE_V3_ABI } from '../wagmiAbis/TraderJoe/MasterChefJoeV3'
import { SIMPLE_REWARDER_ABI } from '../wagmiAbis/TraderJoe/SimpleRewarder'

export const traderJoeMasterChefV3 = new Contract<
  MasterChefJoeV3,
  typeof MASTER_CHEF_JOE_V3_ABI
>(
  {
    [ChainId.AVALANCHE]: '0x188bED1968b795d5c9022F6a0bb5931Ac4c18F00',
    [ChainId.FUJI]: undefined,
  },
  TraderJoeMasterChefV3ABI,
  MASTER_CHEF_JOE_V3_ABI,
)
export const traderJoeAvaxPtpJlp = new Contract<JoePair, typeof JOE_PAIR_ABI>(
  {
    [ChainId.AVALANCHE]: '0xCDFD91eEa657cc2701117fe9711C9a4F61FEED23',
    [ChainId.FUJI]: undefined,
  },
  TraderJoeAvaxPtpJlpABI,
  JOE_PAIR_ABI,
)

export const traderJoeAvaxPtpRewarder = new Contract<
  SimpleRewarder,
  typeof SIMPLE_REWARDER_ABI
>(
  {
    [ChainId.AVALANCHE]: '0x364996dc358926b9A86b1Ed601A33d5915fC86C8',
    [ChainId.FUJI]: undefined,
  },
  TraderJoeAvaxPtpRewarderABI,
  SIMPLE_REWARDER_ABI,
)
