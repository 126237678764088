import { Connector } from 'wagmi'
import { WalletId } from '.'
type Props = {
  id: WalletId
  connector: Connector
  name?: string
  icon: string
  isAddTokenSupported: boolean
  isMobileSupported: boolean
}
export class Wallet {
  readonly connector: Connector
  readonly name: string
  readonly id: WalletId
  readonly icon: string
  readonly isAddTokenSupported: boolean
  readonly isMobileSupported: boolean
  constructor({
    id,
    name,
    icon,
    connector,
    isAddTokenSupported,
    isMobileSupported,
  }: Props) {
    this.id = id
    this.connector = connector
    this.icon = icon
    this.name = name || connector.name
    this.isAddTokenSupported = isAddTokenSupported
    this.isMobileSupported = isMobileSupported
  }
}
