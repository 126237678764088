import React, { ReactElement } from 'react'
import { useModal } from '../../../contexts/ModalContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import { ExternalLink } from '../../../globalStyles'
import useWalletConnection from '../../../hooks/wallet/useWalletConnection'
import { ModalId } from '../../../interfaces/Modal'
import {
  CalculatorButton,
  CalculatorIcon,
  CalculatorText,
  Container,
  TextWithToolTip,
} from './BoostCalculator.elements'

function BoostCalculator(): ReactElement {
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { account } = useNetwork()
  const { openConnectToWalletModal } = useWalletConnection()
  const handleClickCalculator = () => {
    if (account) {
      modalDispatch(openModal(ModalId.VEPTP_BOOSTER_CALCULATOR))
    } else {
      openConnectToWalletModal()
    }
  }
  return (
    <Container>
      <TextWithToolTip
        title="vePTP boosts PTP APR"
        tooltipText={
          <>
            Your vePTP balance contributes to your boosted APR. Read more about
            it in our{' '}
            <ExternalLink href="https://cdn.platypus.finance/Platypus_Liquidity_Mining_Paper.pdf">
              paper
            </ExternalLink>
            .
          </>
        }
      />
      <CalculatorButton onClick={handleClickCalculator}>
        <CalculatorText>Booster Calculator</CalculatorText>
        <CalculatorIcon />
      </CalculatorButton>
    </Container>
  )
}

export default BoostCalculator
