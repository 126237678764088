import React, { ReactElement } from 'react'
import { ChainId, NETWORKS, SUPPORTED_CHAINS } from '../../../config/networks'
import { WALLETS } from '../../../config/wallet'
import { useSnackbar } from '../../../contexts/SnackbarContext'
import { useWeb3 } from '../../../contexts/Web3Context'
import AppAlert from '../../alerts/AppAlert/AppAlert'
import AppButton from '../../buttons/AppButton/AppButton'
import {
  ButtonsContainer,
  Container,
  StyledTypography,
} from './UnsupportedNetwork.elements'
function UnsupportedNetworkPage(): ReactElement {
  const { switchNetwork, activeWalletId } = useWeb3()
  const { showMessage } = useSnackbar()
  const handleSwitchNetwork = (chainId: ChainId) => {
    void switchNetwork(chainId, () => {
      if (activeWalletId)
        showMessage(
          `Please switch network in your ${WALLETS[activeWalletId].name} compatible device.`,
          'warning',
        )
    })
  }

  return (
    <Container
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt="46px"
      data-testid="unsupported-network"
    >
      <AppAlert message="Unsupported Network" severity="warning" />
      <StyledTypography variant="caption">
        Please choose a network below
      </StyledTypography>
      <ButtonsContainer
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {SUPPORTED_CHAINS.map((chainId) => (
          <AppButton
            key={chainId}
            fullWidth
            onClick={() => handleSwitchNetwork(chainId)}
            customVariant="secondary"
            data-testid="switch-network-button"
            margin="6px 0"
          >
            Switch to {NETWORKS[chainId].chainName}
          </AppButton>
        ))}
      </ButtonsContainer>
    </Container>
  )
}

export default UnsupportedNetworkPage
