import { Box } from '@mui/material'
import React, { ReactElement, useEffect, useRef } from 'react'
import MockAccountInput from '../../components/Input/MockAccountInput/MockAccountInput'
import PoweredByAvalancheLink from '../../components/PoweredByAvalancheLink/PoweredByAvalancheLink'
import UrlReminder from '../../components/UrlReminder/UrlReminder'
import NarbarContainer from '../../containers/NavbarContainer/NarbarContainer'
import SubmittedTransactionModalContainer from '../../containers/SwapModalsContainer/SubmittedTransactionModalContainer/SubmittedTransactionModalContainer'
import ChangeNftConfirmationModal from '../../containers/VePtpModalContainers/ChangeNftConfirmationModal/ChangeNftConfirmationModal'
import ChangeNftModal from '../../containers/VePtpModalContainers/ChangeNftModal/ChangeNftModal'
import ChangeNftWaitForConfirmationModal from '../../containers/VePtpModalContainers/ChangeNftWaitForConfirmationModal/ChangeNftWaitForConfirmationModal'
import { useModal } from '../../contexts/ModalContext'
import { ModalId } from '../../interfaces/Modal'
import { ChildrenContainer, Container, Header } from './MainLayout.elements'

interface Props {
  children: React.ReactNode
}

function MainLayout({ children }: Props): ReactElement {
  const { modalState } = useModal()
  const headerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (headerRef.current) {
      // this is for the position of the snackbar
      const offsetHeight = headerRef.current.offsetHeight
      document.body.style.setProperty(
        '--header-offset-height',
        `${offsetHeight.toString()}px`,
      )
    }
  }, [])
  return (
    <Container>
      <Header ref={headerRef}>
        <UrlReminder variant="bookmark" />
        <UrlReminder variant="pool-refund" isPerm />
        <NarbarContainer />
      </Header>
      <ChildrenContainer>{children}</ChildrenContainer>
      {/* a spacer for PoweredByAvalancheLink */}
      <Box height="60px" />
      {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
        <MockAccountInput />
      )}

      <PoweredByAvalancheLink />
      {modalState.currentModalId === ModalId.TRANSACTION_SUBMITTED && (
        <SubmittedTransactionModalContainer isOpen />
      )}

      {/* NFT related modals */}
      {modalState.currentModalId === ModalId.CHANGE_NFT && (
        <ChangeNftModal isOpen />
      )}
      {modalState.currentModalId ===
        ModalId.CHANGE_NFT_WAIT_FOR_CONFIRMATION && (
        <ChangeNftWaitForConfirmationModal isOpen />
      )}
      {modalState.currentModalId === ModalId.UNEQUIP_NFT_CONFIRMATION && (
        <ChangeNftConfirmationModal isOpen isToUnequip />
      )}
      {modalState.currentModalId === ModalId.CHANGE_NFT_CONFIRMATION && (
        <ChangeNftConfirmationModal isOpen />
      )}
      {modalState.currentModalId === ModalId.EQUIP_NFT_CONFIRMATION && (
        <ChangeNftConfirmationModal isOpen isToEquip />
      )}
    </Container>
  )
}

export default MainLayout
