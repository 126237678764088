import { useMemo } from 'react'
import {
  SWAP_AVAILABLE_TOKEN_SYMBOLS,
  TOKENS,
} from '../../config/contracts/token'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { useSwap } from '../../contexts/SwapContext'

function useFilterSwapToken(): {
  filteredToTokenSymbols: TokenSymbol[]
  filteredFromTokenSymbols: TokenSymbol[]
} {
  const { fromTokenSymbol, toTokenSymbol } = useSwap()
  const filteredToTokenSymbols = useMemo(() => {
    if (fromTokenSymbol && toTokenSymbol) {
      return SWAP_AVAILABLE_TOKEN_SYMBOLS
    } else if (fromTokenSymbol) {
      return SWAP_AVAILABLE_TOKEN_SYMBOLS.filter(
        (tokenSymbol) =>
          TOKENS[tokenSymbol].swapGroupSymbol ===
            TOKENS[fromTokenSymbol].swapGroupSymbol &&
          fromTokenSymbol !== TOKENS[tokenSymbol].symbol,
      )
    } else {
      return SWAP_AVAILABLE_TOKEN_SYMBOLS
    }
  }, [fromTokenSymbol, toTokenSymbol])

  const filteredFromTokenSymbols = useMemo(() => {
    if (fromTokenSymbol && toTokenSymbol) {
      return SWAP_AVAILABLE_TOKEN_SYMBOLS
    } else if (toTokenSymbol) {
      return SWAP_AVAILABLE_TOKEN_SYMBOLS.filter(
        (tokenSymbol) =>
          TOKENS[tokenSymbol].swapGroupSymbol ===
            TOKENS[toTokenSymbol].swapGroupSymbol &&
          toTokenSymbol !== TOKENS[tokenSymbol].symbol,
      )
    } else {
      return SWAP_AVAILABLE_TOKEN_SYMBOLS
    }
  }, [fromTokenSymbol, toTokenSymbol])
  return {
    filteredToTokenSymbols,
    filteredFromTokenSymbols,
  }
}

export { useFilterSwapToken }
