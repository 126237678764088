import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import ReportIcon from '../../../assets/icons/report.svg'
import { LP_TOKENS, POOLS } from '../../../config/contracts'
import { useUserVoteAllocationTable } from '../../../containers/GaugeVotingContainer/UserVoteAllocationTable/UserVoteAllocationTableContext'
import { useMulticallData } from '../../../contexts/MulticallDataContext'
import { useVotingData } from '../../../contexts/VotingDataContext'
import { getGaugeWithBribeWithoutVote } from '../../../utils/voting'
import AppTypography from '../../AppTypography/AppTypography'
import TokenIcon from '../../TokenIcon/TokenIcon'
import { Container } from './UnclaimedBribeAlert.elements'

function UnclaimedBribeAlert(): ReactElement {
  const { votedGaugeList } = useUserVoteAllocationTable()

  const {
    user: { earnedBribeOfEachAsset },
  } = useVotingData()

  const {
    votingData: { withoutAccount },
  } = useMulticallData()

  const earnedBribeWithoutVoteGaugeList = getGaugeWithBribeWithoutVote(
    earnedBribeOfEachAsset,
    votedGaugeList,
    withoutAccount?.bribeBalanceOfEachAssetBN,
  )

  if (earnedBribeWithoutVoteGaugeList.length === 0) return <></>

  return (
    <Container>
      <Box display="flex" alignItems="center">
        <img src={ReportIcon} width="25px" height="25px" />
        <AppTypography marginLeft="5px">
          To claim your pending bribes, please vote a small amount and then
          unvote for the following gauges:&nbsp;
        </AppTypography>
      </Box>
      <Box display="flex" alignItems="center">
        {/**@todo hide gauges whose rewarder are drained */}
        {earnedBribeWithoutVoteGaugeList.map((gauge) => (
          <Box
            display="flex"
            margin="0 4px"
            key={`${gauge.poolSymbol}-${gauge.assetTokenSymbol}`}
          >
            <TokenIcon tokenSymbol={gauge.assetTokenSymbol} margin="0 5px" />
            {
              LP_TOKENS[gauge.poolSymbol][gauge.assetTokenSymbol]
                ?.tokenSymbolForDisplay
            }{' '}
            ({POOLS[gauge.poolSymbol].name})
          </Box>
        ))}
      </Box>
    </Container>
  )
}

export default UnclaimedBribeAlert
