import { Box, LinearProgress, Typography } from '@mui/material'
import styled from 'styled-components'
import vePTPIcon from '../../../containers/PlatopiaContainer/assets/images/info/vePTP.png'
import TextWithToolTip from '../TextWithToolTip/TextWithToolTip'

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 480px;
`

export const VePtpIcon = styled.img.attrs({
  src: vePTPIcon,
})`
  width: 26px;
  height: 26px;
  margin: 8px;
`

export const DetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(4, 26, 32, 0.8) 0%,
    rgba(4, 26, 32, 0.48) 100%
  );
  border-radius: 10px;
`

export const VePtpContainer = styled(Box)`
  top: 3px;
  position: absolute;
  min-width: 225px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 5px;
`

export const VePtpOuterContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 41px;
`
export const EarningProgress = styled(LinearProgress)`
  border-radius: 3px 10px 0px 3px;
  width: 100%;
  height: 100%;
  &.MuiLinearProgress-root {
    background: rgb(4, 26, 32);
    background: linear-gradient(
      180deg,
      rgba(4, 26, 32, 0.4) 29%,
      rgba(4, 26, 32, 1) 100%
    );
  }
  .MuiLinearProgress-bar {
    background: rgb(0, 255, 230);
    background: linear-gradient(
      180deg,
      rgba(0, 255, 230, 1) 0%,
      rgba(0, 167, 255, 0.5) 100%
    );
  }
`

export const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 5px 10px 3px 3px;
  width: 100%;
`

export const VePtpTotalText = styled(Typography).attrs({
  variant: 'h6',
})`
  font-size: 1.25rem;
  line-height: 1.6;
  margin: 0px 6px 0px 0px;
`

export const VePtpText = styled(Typography).attrs({
  variant: 'caption2',
})`
  font-size: 0.8rem;
  line-height: 1;
  margin: 6px 0px;
`

export const SmallTextContainer = styled.div`
  padding: 0px 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  background: linear-gradient(
    180deg,
    rgba(4, 26, 32, 0.28) 0%,
    rgba(4, 26, 32, 0.168) 100%
  );
  border-radius: 3px 3px 10px 10px;
  width: 100%;
`

export const TotalPtpToolTip = styled(TextWithToolTip)`
  flex: 1;
  color: #999;
`

export const MaxVePtpToolTip = styled(TextWithToolTip)`
  flex: 1;
  color: #999;
  justify-content: flex-end;
`
