import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import AppTokenInput from '../../components/TokenInput/AppTokenInput'
import DisabledAppTokenInput from '../../components/TokenInput/DisabledAppTokenInput'
import { useSwap } from '../../contexts/SwapContext'

function DisabledTokenInputsContainer(): ReactElement {
  const { fromTokenAmount, toTokenAmount, fromTokenSymbol, toTokenSymbol } =
    useSwap()

  return (
    <>
      <DisabledAppTokenInput
        tokenItems={[
          {
            tokenSymbol: fromTokenSymbol,
            value: fromTokenAmount,
          },
        ]}
      />
      <Box style={{ position: 'relative', width: '100%', height: '24px' }}>
        <AppTokenInput.SwapDirectionButton disabled />
      </Box>
      <DisabledAppTokenInput
        tokenItems={[
          {
            tokenSymbol: toTokenSymbol,
            value: toTokenAmount,
          },
        ]}
      />
    </>
  )
}

export default React.memo(DisabledTokenInputsContainer)
