import {
  getCommifiedFormat,
  getSfFormat,
} from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useNetwork } from '../../../contexts/NetworkContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import ClaimableVePtpInfo from '../ClaimableVePtpInfo/ClaimableVePtpInfo'
import { useVePtpContainer } from '../VePtpContainer'
import { InfoContainer } from '../VePtpContainer.elements'
import { StakedPTPInfoItem } from './StakedPTPInfo.elements'

function StakedPTPInfo(): ReactElement {
  const { ptp, vePtp } = useVePtp()
  const { isTabletSm } = useVePtpContainer()
  const { account } = useNetwork()

  return (
    <>
      <InfoContainer $borderRoundPosition="top">
        <ClaimableVePtpInfo />
      </InfoContainer>
      <InfoContainer
        display="flex"
        flexDirection="row"
        alignItems={isTabletSm ? 'flex-start' : 'center'}
        marginTop="2px"
        $borderRoundPosition="bottom"
      >
        <StakedPTPInfoItem
          display="flex"
          flexDirection="row"
          alignItems="center"
          enabled={ptp.hasStaked}
          className="staked-ptp-info-item__staked-ptp"
        >
          <TokenIcon tokenSymbol={TokenSymbol.PTP} size={40} />
          <StakedPTPInfoItem
            display="flex"
            flexDirection="column"
            sx={{ marginLeft: '8px' }}
            noPadding
          >
            <AppTypography variant="h6">
              <TooltipNum
                amount={ptp.amount.staked}
                rightSymbol={TokenSymbol.PTP}
              >
                {showDashIfNecessary(
                  !account,
                  getCommifiedFormat(ptp.amount.staked),
                )}
              </TooltipNum>
            </AppTypography>
            <Box display="flex" flexDirection="row" alignItems="center">
              <AppTypography variant="caption" transparent>
                Staked PTP
              </AppTypography>
            </Box>
          </StakedPTPInfoItem>
        </StakedPTPInfoItem>
        <StakedPTPInfoItem
          display="flex"
          flexDirection="column"
          sx={{ textAlign: 'center' }}
          enabled={ptp.hasStaked}
          className="staked-ptp-info-item__veptp-mine-rate"
        >
          <AppTypography
            variant="h6"
            sx={{
              display: 'inline-flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            component="span"
          >
            <TooltipNum
              amount={vePtp.earningPerHour.current}
              rightSymbol={`${!isTabletSm ? 'vePTP' : ''}/ hour`}
            >
              {showDashIfNecessary(
                !account,
                getCommifiedFormat(
                  getSfFormat(vePtp.earningPerHour.current, 4),
                ),
              )}
            </TooltipNum>
            &nbsp;
            <AppTypography variant="h6">
              {!isTabletSm ? 'vePTP' : ''}/ hour
            </AppTypography>
          </AppTypography>
          <AppTypography
            variant="caption"
            className="staked-ptp-info-item__veptp-mine-rate-text"
          >
            <AppTypography variant="caption" component="span" transparent>
              vePTP Mine Rate
            </AppTypography>{' '}
            <InfoBox.Tooltip
              text={`Each staked PTP generates ${vePtp.generationRate.perSecond} vePTP per second (without any NFT effect).`}
            />
          </AppTypography>
        </StakedPTPInfoItem>
      </InfoContainer>
    </>
  )
}

export default StakedPTPInfo
