import { Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import WalletIcon from '../../../assets/icons/wallet-icon.svg'
import FujiIcon from '../../../assets/networks/fuji.svg'
import NetworkIcon from '../../../components/icons/NetworkIcon/NetworkIcon'
import { useModal } from '../../../contexts/ModalContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import useBreakpoints from '../../../hooks/useBreakpoints'
import useWalletConnection from '../../../hooks/wallet/useWalletConnection'
import { ModalId } from '../../../interfaces/Modal'
import { getShortenedAddress } from '../../../utils/getShortenedAddress'
import ConnectedWalletModalContainer from '../../ConnectedWalletModalContainer/ConnectedWalletModalContainer'
import ConnectToWalletModalContainer from '../ConnectToWalletModalContainer/ConnectToWalletModalContainer'
import {
  Container,
  NetworkName,
} from './ConnectToWalletButtonContainer.elements'

function ConnectToWalletButtonContainer(): ReactElement {
  const {
    modalDispatch,
    modalState,
    actions: { openModal },
  } = useModal()
  const { network, account } = useNetwork()
  const { isTabletLg } = useBreakpoints()

  const { openConnectToWalletModal } = useWalletConnection()
  const handleWalletClick = () => {
    if (!account) {
      openConnectToWalletModal()
      return
    }
    modalDispatch(openModal(ModalId.CONNECTED_WALLET))
  }

  return (
    <>
      <Container
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        bgcolor="primary.400"
        onClick={handleWalletClick}
        $hasAccount={!!account}
      >
        {account ? (
          <>
            {network?.chainName && (
              <>
                <NetworkIcon iconPath={FujiIcon} margin="0 4px 0 0" />
                <NetworkName variant="caption">
                  {network.chainShortName}
                </NetworkName>
              </>
            )}

            <Typography variant="caption">
              {!isTabletLg
                ? getShortenedAddress(account)
                : getShortenedAddress(account, 4, 2)}
            </Typography>
          </>
        ) : (
          <>
            <img
              src={WalletIcon}
              width="16px"
              height="16px"
              style={{ marginRight: '4px' }}
            />
            <Typography variant="caption">Connect Wallet</Typography>
          </>
        )}
      </Container>
      {modalState.currentModalId === ModalId.CONNECT_TO_WALLET && (
        <ConnectToWalletModalContainer isOpen />
      )}

      {modalState.currentModalId === ModalId.CONNECTED_WALLET &&
        network &&
        account && (
          <ConnectedWalletModalContainer
            isOpen
            network={network}
            account={account}
          />
        )}
    </>
  )
}

export default ConnectToWalletButtonContainer
