import { ChainId } from '../../../networks'
import { PoolSymbol } from '../../pool/poolSymbol'
import { TokenSymbol } from '../../token/tokenSymbol'
import { LpToken } from '../LpToken'

export const DEPRECATED_MAIN_LP_TOKENS2 = {
  [PoolSymbol.DEPRECATED_MAIN2]: {
    [TokenSymbol.USDTe]: new LpToken({
      isCollateral: false,
      addresses: {
        [ChainId.AVALANCHE]: '0xDEA7bf752Ef25301DbB2e9288338A1a9013EC194',
        [ChainId.FUJI]: '0xD01EcBf412186E70452Fb548C4d06d0562158018',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDTe,
      poolSymbol: PoolSymbol.DEPRECATED_MAIN2,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 30,
        [ChainId.FUJI]: 30,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0xa4FB934EC50E4a12c9478C62849196e2daF1e04E',
          [ChainId.FUJI]: '0xeb994F290466ceD9CAb423c123d87cCdBd0Ce495',
        },
      },
      color: '#79FFA7',
    }),
    [TokenSymbol.USDCe]: new LpToken({
      isCollateral: false,
      addresses: {
        [ChainId.AVALANCHE]: '0x6a1340A262BaE9b076a9f8862eBfabea25039e4e',
        [ChainId.FUJI]: '0xeA8D2b1b5c661aee8974Ad05D77170D825b40762',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDCe,
      poolSymbol: PoolSymbol.DEPRECATED_MAIN2,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 31,
        [ChainId.FUJI]: 31,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0xb0d18b3c604D05B607c89ab761c19f51Eb0286c2',
          [ChainId.FUJI]: '0x8f028e604A9151dE7F2151D3f1c28E2cCCdd879B',
        },
      },
      color: '#00A3FF',
    }),
    [TokenSymbol.DAIe]: new LpToken({
      isCollateral: false,
      addresses: {
        [ChainId.AVALANCHE]: '0x2E19709471FB25078dB982DD6b94A8Cb2C29E2Aa',
        [ChainId.FUJI]: '0xF3776BA08683659BE0A65F11670Aa4ab4B8a6E71',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.DAIe,
      poolSymbol: PoolSymbol.DEPRECATED_MAIN2,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 32,
        [ChainId.FUJI]: 32,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x29311498b56072adB9D9F9b49cc8B8399CE459F6',
          [ChainId.FUJI]: '0xC0CC49107045aeF4710615F5Ee7AcE3a53B392BA',
        },
      },
      color: '#F5AC37',
    }),
    [TokenSymbol.USDC]: new LpToken({
      isCollateral: false,
      addresses: {
        [ChainId.AVALANCHE]: '0x06f01502327De1c37076Bea4689a7e44279155e9',
        [ChainId.FUJI]: '0x80E9A4b2F795Fdb2Dc861e1C661dF0C6929a3E4e',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.DEPRECATED_MAIN2,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 33,
        [ChainId.FUJI]: 33,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0xC0073D0513D99E818249c78B276B6B042bf2Af92',
          [ChainId.FUJI]: '0x64dA43949b0C5C0c079F895A6713c6A4aC60c939',
        },
      },
      color: '#2775CA',
    }),
    [TokenSymbol.USDT]: new LpToken({
      isCollateral: false,
      addresses: {
        [ChainId.AVALANCHE]: '0x836648A8cE166Ba7CaFb27F0E6AD21d5C91b7774',
        [ChainId.FUJI]: '0x58877be0018b8AB9B8FCF29275380ef130D4012c',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDT,
      poolSymbol: PoolSymbol.DEPRECATED_MAIN2,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 34,
        [ChainId.FUJI]: 34,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x3652aF9b866B6981dbAEE8835fa259362Ea0280D',
          [ChainId.FUJI]: '0x9e816b4D351bBF8aD059504FBE7FBac839f06265',
        },
      },
      color: '#53AE94',
    }),
  },
}
