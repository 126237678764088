import { alpha, Box, useTheme } from '@mui/material'
import React, { ReactElement } from 'react'
import { Colorized } from '../../../globalStyles'
import AppImage from '../../AppImage/AppImage'
import AppTypography from '../../AppTypography/AppTypography'
import { NftContainer, NftIconWrapper } from './NftCard.elements'

interface Props {
  id?: string | null
  nftImgSrc: string
  name: string
  isEquipped?: boolean
  abililty: string | ReactElement
  variant?: 'small'
  enableBgColor?: boolean
  disableActiveColor?: boolean
  unequipmentText?: string | ReactElement
  padding?: string
}

function NftCard({
  id,
  nftImgSrc,
  name,
  isEquipped,
  abililty,
  variant,
  enableBgColor,
  disableActiveColor,
  unequipmentText,
  padding,
}: Props): ReactElement {
  const theme = useTheme()
  return (
    <NftContainer
      display="flex"
      flexDirection="row"
      alignItems="center"
      {...(enableBgColor && {
        bgcolor: alpha(theme.palette.primary[500], 0.2),
        padding: '22px 12px',
        borderRadius: '10px',
      })}
      {...(padding && {
        padding: padding,
      })}
    >
      <NftIconWrapper
        isEquippedWithNft={isEquipped}
        size={(variant === 'small' && '50px') || undefined}
        greyish={disableActiveColor}
      >
        <AppImage src={nftImgSrc} alt={name} />
      </NftIconWrapper>

      <Box
        display="flex"
        flexDirection="column"
        marginLeft="10px"
        alignItems="flex-start"
      >
        {!isEquipped && (
          <AppTypography variant="body2" transparent paddingRight="2px">
            {unequipmentText ||
              'No Equipment yet, equip an NFT to increase your mine production'}
          </AppTypography>
        )}
        {isEquipped && (
          <>
            <AppTypography
              variant={variant === 'small' ? 'body1' : 'h5'}
              fontWeight={700}
            >
              <Colorized variant={disableActiveColor ? 'white' : 'platopia'}>
                {name}
              </Colorized>
              {id && (
                <AppTypography variant="caption2" transparent marginLeft="4px">
                  #{id}
                </AppTypography>
              )}
            </AppTypography>
            {/* Skill */}
            <AppTypography
              variant={variant === 'small' ? 'caption2' : 'body2'}
              paddingRight="8px"
              textAlign="left"
            >
              {abililty}
            </AppTypography>

            {/** @todo unhide these details when we need them. */}
            {/* Info */}
            {/* <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginTop="2px"
          flexWrap="wrap"
        >
          <NftInfo>
            <NftInfoTitle>Level</NftInfoTitle>
            <NftInfoContent>2</NftInfoContent>
          </NftInfo>
          <NftInfo>
            <NftInfoTitle>Power</NftInfoTitle>
            <NftInfoContent>2500</NftInfoContent>
          </NftInfo>
          <NftInfo>
            <NftInfoTitle>Abilities</NftInfoTitle>
            <NftInfoContent>(5)</NftInfoContent>
          </NftInfo>
        </Box> */}
          </>
        )}
      </Box>
    </NftContainer>
  )
}

export default NftCard
