import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import React, { ReactElement } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { ptpFeatureInfoData } from '../../../constants/infoData'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { Colorized } from '../../../globalStyles'
import { ModalId } from '../../../interfaces/Modal'

function UnstakeOrUnlockModalInfoBox(): ReactElement {
  const { input, vePtp, ptp } = useVePtp()
  const { modalState } = useModal()

  const isUnlocking =
    modalState.currentModalId === ModalId.UNLOCK_PTP ||
    modalState.currentModalId === ModalId.CONFIRM_UNLOCK_PTP ||
    modalState.currentModalId === ModalId.UNLOCK_PTP_WAIT_FOR_CONFIRMATION

  const remainingVePtpBalance = isUnlocking
    ? vePtp.balance.staking.current
    : input.vePtpAmount.remaining

  function getRemainingVeptpToolTip() {
    if (isUnlocking) {
      return ptpFeatureInfoData.MY_REMAIN_VEPTP.tooltip.unlock
    }
    return ptpFeatureInfoData.MY_REMAIN_VEPTP.tooltip.unstake
  }

  return (
    <InfoBox>
      {/* To lose */}
      {(modalState.currentModalId === ModalId.UNLOCK_PTP ||
        modalState.currentModalId === ModalId.UNSTAKE_PTP) && (
        <InfoBox.Item paddingBottom="8px">
          <InfoBox.Item>
            <AppTypography variant="caption">
              {ptpFeatureInfoData.TO_LOSE.label}
            </AppTypography>
            <InfoBox.Tooltip
              text={
                isUnlocking
                  ? ptpFeatureInfoData.TO_LOSE.tooltip.unlock
                  : ptpFeatureInfoData.TO_LOSE.tooltip.unstake
              }
            />
          </InfoBox.Item>
          <InfoBox.Item>
            <Colorized
              variant={
                strToWad(
                  isUnlocking ? vePtp.balance.locking : input.vePtpAmount.burnt,
                ).gt(0)
                  ? 'red'
                  : 'inherit'
              }
            >
              <AppTypography variant="caption">
                <b>
                  <TooltipNum
                    amount={
                      isUnlocking
                        ? vePtp.balance.locking
                        : input.vePtpAmount.burnt
                    }
                    rightSymbol={TokenSymbol.VEPTP}
                    format="commified"
                  >
                    {getCommifiedFormat(
                      isUnlocking
                        ? vePtp.balance.locking
                        : input.vePtpAmount.burnt,
                      2,
                    )}{' '}
                    {TokenSymbol.VEPTP}
                  </TooltipNum>
                </b>
              </AppTypography>
            </Colorized>
          </InfoBox.Item>
        </InfoBox.Item>
      )}
      {/* My Remaining PTP Locked */}
      <InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            {ptpFeatureInfoData.MY_REMAIN_PTP_LOCKED.label}
          </AppTypography>
        </InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            <b>
              <TooltipNum
                amount={'0'}
                rightSymbol={TokenSymbol.PTP}
                format="commified"
              >
                {getCommifiedFormat(isUnlocking ? '0' : ptp.amount.locked, 2)}{' '}
                {TokenSymbol.PTP}
              </TooltipNum>
            </b>
          </AppTypography>
        </InfoBox.Item>
      </InfoBox.Item>
      {/* My Remaining PTP Staked */}
      <InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            {ptpFeatureInfoData.MY_REMAIN_PTP_STAKED.label}
          </AppTypography>
        </InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            <b>
              <TooltipNum
                rightSymbol={TokenSymbol.PTP}
                amount={
                  input.isExcess.unStake ? '0.0' : input.stakePtpTotal.unStake
                }
                format="commified"
              >
                {getCommifiedFormat(
                  input.isExcess.unStake ? '0.0' : input.stakePtpTotal.unStake,
                  2,
                )}{' '}
                {TokenSymbol.PTP}
              </TooltipNum>
            </b>
          </AppTypography>
        </InfoBox.Item>
      </InfoBox.Item>
      {/* My Remaining VEPTP */}
      <InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            {ptpFeatureInfoData.MY_REMAIN_VEPTP.label}
          </AppTypography>
          <InfoBox.Tooltip text={getRemainingVeptpToolTip()} />
        </InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            <b>
              <TooltipNum
                amount={remainingVePtpBalance}
                rightSymbol={TokenSymbol.VEPTP}
                format="commified"
              >
                {getCommifiedFormat(remainingVePtpBalance)} {TokenSymbol.VEPTP}
              </TooltipNum>
            </b>
          </AppTypography>
        </InfoBox.Item>
      </InfoBox.Item>
    </InfoBox>
  )
}

export default UnstakeOrUnlockModalInfoBox
