import { Box } from '@mui/material'
import styled, { css } from 'styled-components'

interface StakedPTPInfoItemProps {
  autoMarginLeft?: boolean
  enabled?: boolean
  noPadding?: boolean
}
export const StakedPTPInfoItem = styled(Box)<StakedPTPInfoItemProps>`
  ${({ autoMarginLeft, enabled, theme, noPadding }) => css`
    padding: ${noPadding ? '0' : '0 16px'};
    flex: 0.5;
    justify-content: center;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
    ${
      autoMarginLeft &&
      css`
        margin-left: auto;
      `
    }

    .staked-ptp-info-item__veptp-mine-rate-text{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      
    }
    
    ${
      enabled &&
      css`
        padding: 0;
        h5 {
          opacity: 1;
        }
      `
    }

  
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      text-align: left;
      padding: 0;
      
      &.staked-ptp-info-item__veptp-mine-rate, &.staked-ptp-info-item__staked-ptp {
        flex: 0.5;
      }
      .MuiTypography-caption{
        font-size: 12px;
      }
      .MuiTypography-h5{
        font-size: 18px;
      }
    }
    }
  `}
`
