import React, { ReactElement } from 'react'
import DefaultIcon from '../../assets/icons/wallet-icon.svg'
import { WALLETS } from '../../config/wallet'
import useWalletConnection from '../../hooks/wallet/useWalletConnection'

interface Props {
  className?: string
  margin?: string
  size?: number
  style?: React.CSSProperties
}

function DynamicWalletIcon({
  className,
  margin,
  size = 23,
  style,
}: Props): ReactElement {
  const { connectedWalletId } = useWalletConnection()
  let src
  if (connectedWalletId) {
    src = WALLETS[connectedWalletId].icon
  } else {
    src = DefaultIcon
  }

  return (
    <img
      className={className}
      width={size}
      height={size}
      style={{ ...style, margin: margin }}
      src={src}
    />
  )
}

export default DynamicWalletIcon
