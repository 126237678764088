import { ChainId } from '../../../networks'
import { PoolSymbol } from '../../pool/poolSymbol'
import { TokenSymbol } from '../../token/tokenSymbol'
import { LpToken } from '../LpToken'

export const DEPRECATED_ALT_LP_TOKENS = {
  [PoolSymbol.DEPRECATED_ALT_BTCB_WBTCE]: {
    [TokenSymbol.BTCb]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x209a0399A2905900C0d1a9a382fe23e37024dC84',
        [ChainId.FUJI]: '0x4A60445331099Bf187651e88cf48b0cfa11C768B',
      },
      decimals: 8,
      tokenSymbol: TokenSymbol.BTCb,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_BTCB_WBTCE,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.AVAX,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0x56a33bFDF90738cEC83f81391B2848D46C18995E',
            [ChainId.FUJI]: '0xba1053E59Df24089233Ed73Defb5BF00f964a0ed',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 18,
        [ChainId.FUJI]: 18,
      },
      color: '#F7931A',
    }),
    [TokenSymbol.WBTCe]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xc09c12093b037866Bf68C9474EcDb5113160fBcE',
        [ChainId.FUJI]: '0x21261BcE4a4e4b23cBdb9b6e8050586e64feAe5C',
      },
      decimals: 8,
      tokenSymbol: TokenSymbol.WBTCe,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_BTCB_WBTCE,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.AVAX,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0x47A1959e26D5A7199Dc0dE4fB5bAD9E0a5d66e31',
            [ChainId.FUJI]: '0x73deb1DA7976D43D398db7c231b61DBE4CD0F282',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 19,
        [ChainId.FUJI]: 19,
      },
      color: '#FFE5BD',
    }),
  },
  [PoolSymbol.DEPRECATED_ALT_USDC_FRAX]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x035D7D7F209B5d18e2AB5C2072E85B32e1D43760',
        [ChainId.FUJI]: '0x3823b6FCE2822F8fB6824b45286fBc52b5319eF8',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_FRAX,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 7,
        [ChainId.FUJI]: 7,
      },
      color: '#C909E8',
    }),
    [TokenSymbol.FRAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x6FD4b4c38ED80727EcD0d58505565F9e422c965f',
        [ChainId.FUJI]: '0x0304d26a8Ff4633D8bfC80a765D6AA136BBdc5df',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.FRAX,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_FRAX,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 6,
        [ChainId.FUJI]: 6,
      },
      color: '#FF988A',
    }),
  },
  [PoolSymbol.DEPRECATED_ALT_USDC_MIM]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x4E5704991b43C1D33b9Ccd1BC33B211bf068385A',
        [ChainId.FUJI]: '0x947F69657DD53b42EA0561b1aE075Acd451941fD',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_MIM,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 11,
        [ChainId.FUJI]: 11,
      },
      color: '#C971FF',
    }),
    [TokenSymbol.MIM]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xF01cEA00598d87Cb9792a01B040d04b0bd8Ca781',
        [ChainId.FUJI]: '0x910185a675681B9845F4074D382F2579983e01De',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.MIM,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_MIM,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 10,
        [ChainId.FUJI]: 10,
      },
      color: '#9695F8',
    }),
  },
  [PoolSymbol.DEPRECATED_ALT_USDC_UST]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xFC95481F79eC965A535Ed8cef4630e1dd308d319',
        [ChainId.FUJI]: '0xcc84D2827E1B070c33f6A2a00f8c3cF01d2618f3',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_UST,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 9,
        [ChainId.FUJI]: 9,
      },
      color: '#66CDAA',
    }),
    [TokenSymbol.UST]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xc7388D98Fa86B6639d71A0A6d410D5cDfc63A1d0',
        [ChainId.FUJI]: '0x5cf763aABf54b361ea7E788D18274fDB788819b4',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.UST,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_UST,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 8,
        [ChainId.FUJI]: 8,
      },
      color: '#FFFFF0',
    }),
  },
  [PoolSymbol.DEPRECATED_ALT_AVAX_SAVAX]: {
    [TokenSymbol.WAVAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xC73eeD4494382093C6a7C284426A9a00f6C79939',
        [ChainId.FUJI]: '0xCdF411c03BeE4B54c81F3d2D5A7dd7cc212788b2',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.WAVAX,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_AVAX_SAVAX,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.QI,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0x2BdAF5DFe9447d1637b66f9A33C2C09a05b7cEB3',
            [ChainId.FUJI]: '0x5906E52420235F33Cab23B16258b58995B491E05',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 12,
        [ChainId.FUJI]: 12,
      },
      color: '#E84142',
      // WAVAX asset will display as AVAX
      tokenSymbolForDisplay: TokenSymbol.AVAX,
      tokenSymbolForCheckbox: {
        tokenSymbolForChecked: TokenSymbol.WAVAX,
        tokenSymbolForUnchecked: TokenSymbol.AVAX,
      },
      bribe: {
        tokenSymbol: TokenSymbol.QI,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x901A1Ed26036E1405BC465217bc714Fa2168BD6A',
          [ChainId.FUJI]: '0x47eD249069B350A791c28B6379531aF9D771Bb9F',
        },
      },
    }),
    [TokenSymbol.SAVAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xA2A7EE49750Ff12bb60b407da2531dB3c50A1789',
        [ChainId.FUJI]: '0x3B0D4f6C39E077de082c51934f277907262Ee5e5',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.SAVAX,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_AVAX_SAVAX,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.QI,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0x92Af37486b547f98F4e0489017a2028A34f5e501',
            [ChainId.FUJI]: '0xD96631Ea4E3D3D2212FE31b7c0227D450771515B',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 13,
        [ChainId.FUJI]: 13,
      },
      color: '#21C9FF',
      bribe: {
        tokenSymbol: TokenSymbol.QI,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x99249a48a52c7b380644d3466c92ffc68F76047A',
          [ChainId.FUJI]: '0xa2c9959Fc61580295e8772633c3a5a72Ec2dEB87',
        },
      },
    }),
  },
  [PoolSymbol.DEPRECATED_ALT_AVAX_YYAVAX]: {
    [TokenSymbol.WAVAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x12141b8FD20b4bBdd5F4e911bF91575258A3eABD',
        [ChainId.FUJI]: '0x9E5dE753FB0Ce16a871CfDd60D1cCb5452e9522D',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.WAVAX,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_AVAX_YYAVAX,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 22,
        [ChainId.FUJI]: 22,
      },
      color: '#FF7461',
      // WAVAX asset will display as AVAX
      tokenSymbolForDisplay: TokenSymbol.AVAX,
      tokenSymbolForCheckbox: {
        tokenSymbolForChecked: TokenSymbol.WAVAX,
        tokenSymbolForUnchecked: TokenSymbol.AVAX,
      },
      bribe: {
        tokenSymbol: TokenSymbol.YYAVAX,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x2aD5fC90F95f236F5276E795A5A46381bFE5aa92',
          [ChainId.FUJI]: '0x45054722833eB99977EfdeD3156bc9B171859f63',
        },
      },
    }),
    [TokenSymbol.YYAVAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x3BEB0D3DB537b79D377131Ce81950B683d382Ec9',
        [ChainId.FUJI]: '0x4E25BFdfD613B275EF52f74234aa145337c4508C',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.YYAVAX,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_AVAX_YYAVAX,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 23,
        [ChainId.FUJI]: 23,
      },
      color: '#00F57B',
      bribe: {
        tokenSymbol: TokenSymbol.YYAVAX,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x3D0798Ffea1b441731f95F1D5287DC0Aee3b12A5',
          [ChainId.FUJI]: '0x152215406A8E237e3F4f38B03210aC4F956f13b6',
        },
      },
    }),
  },
  [PoolSymbol.DEPRECATED_ALT_AVAX_ANKRAVAX]: {
    [TokenSymbol.WAVAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xad589CFB73922700Dc46cA39CDF83B9618ac91E0',
        [ChainId.FUJI]: '0x7FEd42dB0CBA7E201e99bc3253F07aBD528F6A9a',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.WAVAX,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_AVAX_ANKRAVAX,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 29,
        [ChainId.FUJI]: 29,
      },
      /** @todo color */
      color: '#FF7461',
      // WAVAX asset will display as AVAX
      tokenSymbolForDisplay: TokenSymbol.AVAX,
      tokenSymbolForCheckbox: {
        tokenSymbolForChecked: TokenSymbol.WAVAX,
        tokenSymbolForUnchecked: TokenSymbol.AVAX,
      },
      bribe: {
        tokenSymbol: TokenSymbol.ANKR,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x623908104457d232F526a2d6e021e537Cb38229f',
          [ChainId.FUJI]: '0x2ca1ACc8fC3b2Ac215F2D79C2034b0c78FBAf534',
        },
      },
    }),
    [TokenSymbol.ANKRAVAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x542dd5F38fcb3AAb28d6418CF3e1d36329A79aC7',
        [ChainId.FUJI]: '0xacd95A7B902D9b422B7745B2B2f421FD3ddB5527',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.ANKRAVAX,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_AVAX_ANKRAVAX,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 28,
        [ChainId.FUJI]: 28,
      },
      color: '#00F57B',
      bribe: {
        tokenSymbol: TokenSymbol.ANKR,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x7b35229e2451220237f3121464713F3446E740F1',
          [ChainId.FUJI]: '0x5A6E101B2D8B8432Db3A8c80fCE58124Ae3d292e',
        },
      },
    }),
  },
  [PoolSymbol.DEPRECATED_ALT_USDC_YUSD]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x4b851118a4A4948799f24d0CBE17FA3dad09e2D5',
        [ChainId.FUJI]: '0x5EeCa3318E2c68E082D162a1cb3Aaf7600D15f32',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_YUSD,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.YETI,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0xfb45Dad8cDa679CaB275C56DEc8EcBDA38a3972e',
            [ChainId.FUJI]: '0xCD4f5Fe7B109A42d97cC3D36Dbedb8F9Ad831E49',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 17,
        [ChainId.FUJI]: 17,
      },
      color: '#5042DE',
      bribe: {
        tokenSymbol: TokenSymbol.YETI,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x9a4003DDDcf2C4ECd5e2916730Dee618a1Df9142',
          [ChainId.FUJI]: '0x5F13D0047C8Aa0a9B98DFfbCf5E4DFA7123BFfa1',
        },
      },
    }),
    [TokenSymbol.YUSD]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x7716307350c0819eD05C3e7f6c478b27CAED5361',
        [ChainId.FUJI]: '0x30b5E3d42BF09B04fbB8c6F3A7e18b1F789FEe61',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.YUSD,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_YUSD,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.YETI,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0x7fF30B2c83e707C3110e39Db521AF399E34cC73a',
            [ChainId.FUJI]: '0x1e49EE04ba5519B8370D953CaC56Bb0920f977ED',
          },
          tokenId: 0,
        },
      ],
      bribe: {
        tokenSymbol: TokenSymbol.YETI,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x933faf47D3bbf4955462Ddc8cb523209e09224f1',
          [ChainId.FUJI]: '0x70FC96Fa423F647DFb9Cd0697a481Af6b32e50b8',
        },
      },
      pids: {
        [ChainId.AVALANCHE]: 16,
        [ChainId.FUJI]: 16,
      },
      color: '#C6EAF1',
    }),
  },
  [PoolSymbol.DEPRECATED_ALT_USDC_MONEY]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x551C259Bf4D88edFdAbb04179342a73dAa759583',
        [ChainId.FUJI]: '0x7F81330B14c92f45A5b4A262FFC959D2D52655eC',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_MONEY,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.MORE,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0xba0431afD3E9C8667989C54884976a56B3a1cC50',
            [ChainId.FUJI]: '0xEC084B7b1179fa042bd49469E2aA020248caD14d',
          },
          tokenId: 0,
        },
      ],
      bribe: {
        tokenSymbol: TokenSymbol.MORE,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x0Cb7cAeeC467d69982c0FFc8369D1682fd1e5277',
          [ChainId.FUJI]: '0x2EB78ceFfC721EB73587DF1131daF5787e8256CF',
        },
      },
      pids: {
        [ChainId.AVALANCHE]: 21,
        [ChainId.FUJI]: 21,
      },
      color: '#00FFF0',
    }),
    [TokenSymbol.MONEY]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xE08947eE864Af325D9F98743B3b905875Ae0Ec99',
        [ChainId.FUJI]: '0x7188ff0d2124C95510a3F15c263e153b75B517bD',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.MONEY,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_MONEY,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.MORE,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0xc610De201d83dAfdEBbfACD9EEb306de0B89e56F',
            [ChainId.FUJI]: '0xd2c06ab241BF3748847118221c2372a1e123464E',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 20,
        [ChainId.FUJI]: 20,
      },
      bribe: {
        tokenSymbol: TokenSymbol.MORE,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x21eb980ECbA80eb1992c0d210B0fed26f1ACCb3f',
          [ChainId.FUJI]: '0x917d387A20404aAC4423fB9165E3C6852FF731E6',
        },
      },
      color: '#823CE8',
    }),
  },
  [PoolSymbol.DEPRECATED_ALT_USDC_AXLUSDC]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x7ECE2e39fC384d15e7004d84687dA67218A39a74',
        [ChainId.FUJI]: '0xBEF0395053FEd17949BE3De6361435d31c557b3D',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_AXLUSDC,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.USDC,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0xd015a2e0E90F0352A10CB3c2c6427dd3Bf3144Ba',
            [ChainId.FUJI]: '0xFD3Da83fC57ccDb615a0B071394c156e5B181FCd',
          },
          tokenId: 0,
        },
      ],
      bribe: {
        tokenSymbol: TokenSymbol.AXLUSDC,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x71484F1AA0937B13A50f9Ad3Ce56D80e666Ac0df',
          [ChainId.FUJI]: '0xc859B43e6af9d7741743b619c82d256D311cBFF4',
        },
      },
      pids: {
        [ChainId.AVALANCHE]: 27,
        [ChainId.FUJI]: 27,
      },
      /**@TODO replace with new chart color */
      color: '#4169E1',
    }),
    [TokenSymbol.AXLUSDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xC97d830Cc15B35D361985a48d8d9e52A56B0f94F',
        [ChainId.FUJI]: '0x89D484A095AecCbD33b597e7779cEC0e83947a1D',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.AXLUSDC,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_AXLUSDC,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.USDC,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0x79fD72E5FBd4aDef3674501Ce793286FFA5a84C6',
            [ChainId.FUJI]: '0x5bdc10D96a439d359942bf5fB6cCAfc911265174',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 26,
        [ChainId.FUJI]: 26,
      },
      bribe: {
        tokenSymbol: TokenSymbol.AXLUSDC,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0xA2E1A1987f341424cC27caAd0Be670C5718A3408',
          [ChainId.FUJI]: '0x1dae2D35bCb4342D1c8BB77eDed3287ae26D1aE5',
        },
      },
      /**@TODO replace with new chart color */
      color: '#F5F5DC',
    }),
  },
  [PoolSymbol.DEPRECATED_ALT_ANKRETH_WETHE]: {
    [TokenSymbol.ANKRETH]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xa63e0c77D92d9Ee15bBeB0c219D80748605b2Cf6',
        [ChainId.FUJI]: '0x368a8A922030c1c9b421EfF49C81c71577B8E2C1',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.ANKRETH,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_ANKRETH_WETHE,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      bribe: {
        tokenSymbol: TokenSymbol.ANKR,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0xFA989d2C16973bc286c3907bE2aeC971d7f80a61',
          [ChainId.FUJI]: '0x11CE2ca0fC04013670495badD9f6C61Bd8ffcC88',
        },
      },
      pids: {
        [ChainId.AVALANCHE]: 35,
        [ChainId.FUJI]: 35,
      },
      color: '#FFE81B',
    }),
    [TokenSymbol.WETHe]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x2CC7492Aa8846c834364d2039707c583d4077BCb',
        [ChainId.FUJI]: '0x25b711F13306036573281D21313B0d1bcAC4b9f3',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.WETHe,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_ANKRETH_WETHE,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      bribe: {
        tokenSymbol: TokenSymbol.ANKR,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0xE9dBc673EaD1a0B937765270091FECa8D6e99BD2',
          [ChainId.FUJI]: '0x896d77893d86c2c872253112ee5A0100b96Ce2D3',
        },
      },
      pids: {
        [ChainId.AVALANCHE]: 36,
        [ChainId.FUJI]: 36,
      },
      color: '#0E0E0E',
    }),
  },
  [PoolSymbol.DEPRECATED_ALT_USDC_TUSD]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xA551480Dc5399921F8a73e02EC327f2Fd7E5dDc0',
        [ChainId.FUJI]: '0x7FbA0d40Dd838290CdfAEdF0ce4f676a0d80f7E4',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_TUSD,
      /** @todo change to true after listing */
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.TUSD,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: undefined,
            [ChainId.FUJI]: undefined,
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 15,
        [ChainId.FUJI]: 15,
      },
      color: '#FF9FFB',
    }),
    [TokenSymbol.TUSD]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xc75b2b90079492922aF96bA53988D7b384158335',
        [ChainId.FUJI]: '0x252F99eeA5468De53205CE20f4288ebDDe6d2284',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.TUSD,
      poolSymbol: PoolSymbol.DEPRECATED_ALT_USDC_TUSD,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.TUSD,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: undefined,
            [ChainId.FUJI]: undefined,
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 14,
        [ChainId.FUJI]: 14,
      },
      color: '#FF9FFB',
    }),
  },
}
