import {
  getCommifiedFormat,
  getStringInput,
} from '@hailstonelabs/big-number-utils'
import { BigNumber } from 'ethers'
import React from 'react'
import useBreakpoints from '../../hooks/useBreakpoints'
import { NonSelectedSpan, StyledToolTip } from './InfoBox.element'

/**
 * @param {SafeBNFormat} amount a value in string or in WAD
 **/

interface Props {
  children?: React.ReactNode | undefined
  leftSymbol?: string
  rightSymbol?: string
  amount: string | BigNumber
  format?: 'exact' | 'commified'
}

/**
 *  @param {React.ReactNode | undefined} children
 *  @param {string} leftSymbol
 *  @param {string} rightSymbol
 *  @param {string | BigNumber} amount in wad str, or wad
 *  @param {'exact' | 'commified'} format default format = exact
 *  @returns {React.ReactElement}
 */

const TooltipNum = ({
  leftSymbol = '',
  children,
  rightSymbol = '',
  amount,
  format = 'exact',
}: Props): React.ReactElement => {
  const { isTabletSm } = useBreakpoints()
  const amountStr = getStringInput(amount)
  const formattedAmountStr =
    format === 'commified' ? getCommifiedFormat(amountStr) : amountStr
  const title = `${leftSymbol} ${formattedAmountStr} ${rightSymbol}`.trim()

  return (
    <StyledToolTip
      enterTouchDelay={0}
      arrow
      placement={isTabletSm ? 'top' : 'left'}
      title={title}
      PopperProps={{
        style: {
          // keep a large number next to a LeftSymbol
          wordBreak: leftSymbol ? 'break-all' : 'initial',
        },
      }}
    >
      <NonSelectedSpan>{children}</NonSelectedSpan>
    </StyledToolTip>
  )
}

export default TooltipNum
