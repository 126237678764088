import { Box, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

export const WalletLabel = styled(Typography)``

export const WalletItemContainer = styled(Box)`
  ${({ theme }) =>
    css`
      height: 50px;
      padding: 12px;
      margin: 8px 0;
      border-radius: ${theme.borderRadius.sm};
      cursor: pointer;

      user-select: none;
      &:hover {
        opacity: 0.8;
      }
    `}
`

const readButtonSize = '22px'
export const ReadButton = styled(Box)`
  ${({ theme }) => css`
    border: 2px solid #fff;
    width: ${readButtonSize};
    height: ${readButtonSize};
    border-radius: ${theme.borderRadius.rd};
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.1s ease;
    &:hover {
      opacity: 0.8;
    }
  `}
`
