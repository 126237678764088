import React, { ReactElement } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import NftCard from '../../../components/cards/NftCard/NftCard'
import nftUIData from '../../../config/nftUIData'
import { useModal } from '../../../contexts/ModalContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { ModalId } from '../../../interfaces/Modal'
import {
  CTAButton,
  RowToColumnContainer,
  ShrinkAndGrowContainer,
} from '../VePtpContainer.elements'

function NftEquipment(): ReactElement {
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const {
    nft: { all, equippedId },
  } = useVePtp()
  const equippedNftData =
    equippedId && all[equippedId]
      ? {
          imgSrc: all[equippedId].imgSrc,
          type: all[equippedId].type,
          value: all[equippedId].value,
        }
      : null
  const { account } = useNetwork()

  return (
    <RowToColumnContainer>
      <ShrinkAndGrowContainer flex={0.7}>
        <AppTypography variant="subtitle2">NFT Equipment</AppTypography>
        <NftCard
          id={equippedId}
          isEquipped={!!equippedNftData}
          nftImgSrc={(equippedNftData && equippedNftData.imgSrc) || ''}
          abililty={
            (equippedNftData &&
              nftUIData[equippedNftData.type].getAbilityText({
                value: equippedNftData.value,
                showActiveColor: true,
                showActiveText: true,
              })) ||
            ''
          }
          name={(equippedNftData && equippedNftData.type) || ''}
        />
      </ShrinkAndGrowContainer>
      <ShrinkAndGrowContainer
        flex={0.3}
        alignItems="center"
        justifyContent="center"
      >
        <CTAButton
          customVariant="neutral"
          onClick={() => modalDispatch(openModal(ModalId.CHANGE_NFT))}
          disabled={!account}
          data-testid="nft-veptp-button"
        >
          {equippedId ? 'Change NFT' : 'Equip NFT'}
        </CTAButton>
      </ShrinkAndGrowContainer>
    </RowToColumnContainer>
  )
}

export default NftEquipment
