import { Box, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import { tokenIconPaths } from '../../../assets/tokens'
import AppButton from '../../buttons/AppButton/AppButton'
import TextWithToolTipComponent from '../TextWithToolTip/TextWithToolTip'

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 480px;
  background: linear-gradient(
    180deg,
    rgba(4, 26, 32, 0.8) 0%,
    rgba(4, 26, 32, 0.48) 100%
  );
  margin-left: 10px;
  border-radius: 10px;
`

export const VePtpFormulaContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 11px 18px 11px 14px;
  justify-content: center;
  align-items: center;
`

export const TitleText = styled(Typography).attrs({
  variant: 'subtitle2',
})`
  font-size: 1rem;
  color: #fff;
`

export const StakedPtpSmallText = styled(Typography).attrs({
  variant: 'caption2',
})`
  font-size: 0.8rem;
  line-height: 1;
  color: #999;
  margin-top: 2px;
`

export const PtpIcon = styled.img.attrs({
  src: tokenIconPaths.PTP,
})`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 0 1px 0 white inset, 0 0 1px 0 white;
`

export const PtpStakeInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
`

export const StakedPtpContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 12px 14px 14px;
  border-radius: 5px 10px 10px 5px;
  background: linear-gradient(
    180deg,
    rgba(4, 26, 32, 0.35) 0%,
    rgba(4, 26, 32, 0.21) 100%
  );
`

export const StakeButton = styled(AppButton).attrs({
  customVariant: 'secondary',
})`
  ${({ theme }) => css`
    padding: 4px 16px;
    max-width: 100px;
    background-color: ${theme.palette.platopia.main};
    color: ${theme.palette.primary[900]};
    border-radius: 50px;
    &:enabled:hover {
      background: ${theme.palette.platopia.dark};
    }
  `}
`

export const UnstakeButton = styled(AppButton).attrs({
  customVariant: 'secondary',
})`
  ${({ theme }) => css`
    padding: 4px 16px;
    max-width: 100px;
    background-color: ${theme.palette.primary[300]};
    border-radius: 50px;
    &:enabled:hover {
      background: ${theme.palette.primary[200]};
    }
    &.Mui-disabled {
      border: unset;
      background-color: ${theme.palette.primary[300]};
      border-radius: 50px;
      opacity: 0.5;
    }
  `}
`

export const PtpContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 14px;
`

export const TextWithToolTip = styled(TextWithToolTipComponent)`
  color: #999;
`
