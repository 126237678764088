import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppTokenInput from '../../components/TokenInput/AppTokenInput'

// compound component is not working on Material UI styled component
export const StyledTokenInput = styled(AppTokenInput)``

export const InputFieldContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex: 1;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin: 5px 0 0;
      width: 100%;

      .MuiInput-input {
        text-align: left;
        padding-left: 0;
      }
    }
  `}
`

export const TokenInputInnerContainer = styled(AppTokenInput.InnerContainer)`
  ${({ theme }) => css`
    
    margin-top: 4px;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: unset;
      flex-direction: column;
      .app-token-input__select-token-button {
        width: 100%;
      },
    }
    `}
`
