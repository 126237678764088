import React, { ReactElement } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import WaitForConfirmationModal from '../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtpInput } from '../../../contexts/VePtpInputContext'
import LockPtpInfoBox from '../LockPtpModalContainer/LockPtpInfoBox'
import {
  LockInfoItem,
  LockItemContainer,
} from './ExtendPtpLockWaitForConfirmationModal.element'

interface Props {
  isOpen: boolean
}

function ExtendPtpLockWaitForConfirmationModal({
  isOpen,
}: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()

  const handleCloseModal = () => {
    modalDispatch(closeModal())
  }

  const { lockDayInputAmount } = useVePtpInput()

  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="600px"
      subtitle={`Extend ${TokenSymbol.PTP} Lock`}
    >
      <LockItemContainer>
        <LockInfoItem>
          <AppTypography
            variant="body1"
            centerContent
            justifyContent="flex-start"
          >
            Extend
          </AppTypography>
          <AppTypography variant="h6">
            {lockDayInputAmount}{' '}
            {Number(lockDayInputAmount) > 1 ? 'days' : 'day'}
          </AppTypography>
        </LockInfoItem>
      </LockItemContainer>
      <LockPtpInfoBox />
    </WaitForConfirmationModal>
  )
}

export default ExtendPtpLockWaitForConfirmationModal
