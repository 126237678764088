import { Box, Typography } from '@mui/material'
import React, { ReactElement, useMemo, useState } from 'react'
import { POOLS } from '../../config/contracts'
import { PoolSymbol } from '../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import useBreakpoints from '../../hooks/useBreakpoints'
import Slidable from '../animations/Slidable/Slidable'
import AppTypography from '../AppTypography/AppTypography'
import AccordionButton from '../buttons/AccordionButton/AccordionButton'
import TokenIcon from '../TokenIcon/TokenIcon'
import {
  ClaimAllRewardsButton,
  Container,
  ExtraRewardTokenIndicator,
  InnerContainer,
  Label,
  RewardList,
} from './PoolClaimAllRewards.elements'

export type BreakdownType = Record<
  PoolSymbol,
  {
    tokenSymbol: TokenSymbol
    value: string | React.ReactElement
  }[]
>
export type PoolClaimAllRewardsDataType = {
  sumUSD: string | ReactElement
  breakdown: BreakdownType
}
const MAX_REWARD_TOKENS_SHOWN = 5

interface Props {
  earningSymbols: TokenSymbol[]
  myDeposits: PoolClaimAllRewardsDataType
  myEarnings: PoolClaimAllRewardsDataType
  disabled?: boolean
  onClickClaimButton: () => Promise<void>
}
function PoolClaimAllRewards({
  earningSymbols,
  myDeposits,
  myEarnings,
  disabled,
  onClickClaimButton,
}: Props): ReactElement {
  const [showDetails, setShowDetails] = useState(false)
  const { isTabletLg } = useBreakpoints()
  const filteredEarningSymbols = useMemo(() => {
    return earningSymbols.slice(0, MAX_REWARD_TOKENS_SHOWN)
  }, [earningSymbols])

  const hasRewardsAvailable = earningSymbols.length > 0

  const renderSlidable = (breakdown: BreakdownType) => {
    return (
      <Slidable active={showDetails}>
        {/** keep order with poolSymbol */}
        {Object.values(PoolSymbol).map((poolSymbol) => {
          const data = breakdown[poolSymbol]
          if (!data) {
            return null
          }
          return (
            <Box display="flex" flexDirection="column" key={poolSymbol}>
              {/* Pool name */}
              {data.length > 0 && (
                <>
                  <AppTypography variant="caption3" transparent>
                    {POOLS[poolSymbol as PoolSymbol].name}
                  </AppTypography>
                  {/* Values for each pool */}
                  {data.map((item) => {
                    return (
                      <AppTypography
                        key={item.tokenSymbol}
                        variant="caption2"
                        centerContent
                        justifyContent="flex-start"
                        margin="2px 0"
                      >
                        <TokenIcon
                          tokenSymbol={item.tokenSymbol}
                          size={16}
                          margin="0 4px 0 8px"
                        />
                        {item.value}
                      </AppTypography>
                    )
                  })}
                </>
              )}
            </Box>
          )
        })}
      </Slidable>
    )
  }
  return (
    <Container>
      <Box
        display="flex"
        flexDirection={isTabletLg ? 'column' : 'row'}
        justifyContent="space-evenly"
        alignItems="flex-start"
        width="100%"
        position="relative"
      >
        {/* My total deposits */}
        <Box
          display="flex"
          flexDirection="column"
          width={isTabletLg ? '100%' : 'unset'}
          margin="8px 0"
        >
          <InnerContainer justifyContent="space-between">
            <InnerContainer>
              <Label variant="caption2" marginRight="8px" minWidth="80px">
                My Total Deposits:
              </Label>
              <AppTypography variant="subtitle2">
                {myDeposits.sumUSD}&nbsp;
              </AppTypography>
            </InnerContainer>
            {isTabletLg && hasRewardsAvailable && (
              <AccordionButton
                onClick={() => setShowDetails((prev) => !prev)}
                active={showDetails}
              />
            )}
          </InnerContainer>
          {renderSlidable(myDeposits.breakdown)}
        </Box>
        {/* My total earnings */}
        <Box display="flex" flexDirection="column" margin="8px 0">
          <InnerContainer>
            <InnerContainer>
              <Label variant="caption2" marginRight="8px" minWidth="80px">
                My Total Earnings:&nbsp;
              </Label>
              <AppTypography variant="subtitle2">
                {myEarnings.sumUSD}&nbsp;
              </AppTypography>
              {!isTabletLg && !showDetails && (
                <RewardList flexDirection="row" alignItems="center">
                  {
                    <>
                      {filteredEarningSymbols.map((symbol) => (
                        <TokenIcon
                          key={symbol}
                          tokenSymbol={symbol}
                          size={16}
                          margin="0 4px 0 0"
                        />
                      ))}
                      {earningSymbols.length > MAX_REWARD_TOKENS_SHOWN && (
                        <ExtraRewardTokenIndicator>
                          <AppTypography variant="caption2">
                            +{earningSymbols.length - MAX_REWARD_TOKENS_SHOWN}
                          </AppTypography>
                        </ExtraRewardTokenIndicator>
                      )}
                    </>
                  }
                </RewardList>
              )}

              <ClaimAllRewardsButton
                customVariant="neutral"
                disabled={disabled}
                onClick={(e) => {
                  // prevent parent elements from firing up this event
                  e.stopPropagation()
                  void onClickClaimButton()
                }}
                style={{ marginLeft: '10px' }}
              >
                <Typography variant="caption2">Claim</Typography>
              </ClaimAllRewardsButton>
            </InnerContainer>
            <Box position="absolute" right={0}>
              {!isTabletLg && hasRewardsAvailable && (
                <AccordionButton
                  onClick={() => setShowDetails((prev) => !prev)}
                  active={showDetails}
                />
              )}
            </Box>
          </InnerContainer>

          {renderSlidable(myEarnings.breakdown)}
        </Box>
      </Box>
    </Container>
  )
}

export default PoolClaimAllRewards
