export const TEST_YY_ORACLE_ABI = [
  {
    inputs: [],
    name: 'pricePerShare',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_rate', type: 'uint256' }],
    name: 'setRate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const
