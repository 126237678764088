import React, { ReactElement } from 'react'
import LoadingIcon from '../../assets/icons/loading-icon.svg'
import { Container } from './Spinner.elements'
interface Props {
  width?: number
}

function Spinner({ width = 40 }: Props): ReactElement {
  return (
    <Container data-testid="spinner">
      <img src={LoadingIcon} width={width} height={width} />
    </Container>
  )
}

export default Spinner
