import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  readContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { nft, vePtp } from '../../config/contracts'
import { NFT_ABI } from '../../config/contracts/wagmiAbis/Nft'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useVePtp } from '../../contexts/VePtpContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { isUserDeniedTransaction } from '../../utils/contract'
import useRevertReason from '../useRevertReason'

type ReturnType = {
  isApproving: boolean
  isApproved: boolean
  handleClickApprove: () => Promise<void>
}

/** @todo write test for this hook */

/**
 * @param {string?} nftId if not provided. always return isApproved=false.
 * @returns {ReturnType}
 */
const useNftApprove = (nftId?: string): ReturnType => {
  const { chainId } = useWeb3()
  const getRevertReason = useRevertReason()
  const [isApproving, setIsApproving] = useState(false)
  const [isNftIdApproved, setIsNftIdApproved] = useState(false)
  const { showMessage } = useSnackbar()
  const { nft: nftData } = useVePtp()
  const nftAddress = nft.address[chainId]

  // when nftId changes, reset isApproving to false
  useEffect(() => {
    setIsApproving(false)
  }, [nftId])

  // check if the individual nftId is approved
  useEffect(() => {
    const checkApproval = async () => {
      if (nftAddress && nftId) {
        const approvedAddress = await readContract({
          address: nftAddress,
          abi: NFT_ABI,
          functionName: 'getApproved',
          args: [BigNumber.from(nftId)],
          chainId,
        })
        setIsNftIdApproved(approvedAddress === vePtp.getAddress(chainId))
      }
    }

    if (!nftData.isVePtpApprovedForAllNfts) {
      void checkApproval()
    }
  }, [nftData.isVePtpApprovedForAllNfts, nftId, chainId, nftAddress])

  // if it is approved for all NFTs or if the individual nftId is approved
  const isApproved = nftData.isVePtpApprovedForAllNfts || isNftIdApproved

  const handleClickApprove = async () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'all_nfts_approval',
    })
    setIsApproving(true)
    let writeContractResult: WriteContractResult | undefined
    const vePtpAddress = vePtp.getAddress(chainId)
    if (!vePtpAddress || !nftAddress) return
    try {
      const config = await prepareWriteContract({
        address: vePtpAddress,
        abi: NFT_ABI,
        functionName: 'setApprovalForAll',
        args: [vePtpAddress, true],
        chainId,
      })
      writeContractResult = await writeContract(config)
      if (writeContractResult) {
        const { wait } = writeContractResult
        await wait()
        showMessage('NFTs approved. Ready for action.')
        setIsNftIdApproved(true)
      }
    } catch (err) {
      if (!isUserDeniedTransaction(err)) {
        Sentry.setContext('contract_call', {
          name: 'all_nfts_approval',
        })
        Sentry.captureException(err)
      }
      const reason = await getRevertReason(err)
      showMessage(reason || 'Transaction failed.', 'warning')
    } finally {
      setIsApproving(false)
    }
  }
  return { isApproved, isApproving, handleClickApprove }
}

export default useNftApprove
