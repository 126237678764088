import { changeDecimal } from '@hailstonelabs/big-number-utils'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { BigNumber, utils } from 'ethers'
import { ActionId } from '../../config/action'
import { POOLS, TOKENS } from '../../config/contracts'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { POOL__ABI } from '../../config/contracts/wagmiAbis/Pool'
import { POOLAVAX_ABI } from '../../config/contracts/wagmiAbis/PoolAvax'
import { useModal } from '../../contexts/ModalContext'
import { usePoolInput } from '../../contexts/PoolInputContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useUserPreference } from '../../contexts/UserPreferenceContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'
import { getDeadline } from '../../utils/contract'
import {
  getToLpTokenAmountByRate,
  getToLpTokenAmountForTpAvaxWithdrawal,
} from '../../utils/pool'
import {
  getAnkrEthToWetheRateWad,
  getTpAvaxToAvaxRateWad,
} from '../../utils/tpAvax'
import useTransaction from '../useTransaction'

type Props = {
  handleClickWithdraw: () => Promise<void>
}
const useWithdrawToken = (): Props => {
  const { userPreference } = useUserPreference()
  const { readOnlyProvider, account, chainId } = useWeb3()
  const { showMessage } = useSnackbar()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const {
    withdrawMinimumReceived,
    withdrawLiquidity,
    selectedPoolSymbol,
    actions: { clearWithdrawLiquidity },
  } = usePoolInput()
  const { checkIfUserDeniedTransaction } = useTransaction()
  const handleClickWithdraw = async () => {
    if (withdrawLiquidity.tokenSymbol) {
      const tokenInstance =
        withdrawLiquidity.tokenSymbol === TokenSymbol.AVAX
          ? TOKENS[TokenSymbol.WAVAX]
          : TOKENS[withdrawLiquidity.tokenSymbol]
      const tokenAddress = tokenInstance.getAddress(chainId)
      const poolAddress = POOLS[selectedPoolSymbol].getAddress(chainId)
      let writeContractResult: WriteContractResult | undefined

      try {
        const amount = utils.parseUnits(
          withdrawLiquidity.amount,
          tokenInstance.decimals,
        )
        if (account && poolAddress) {
          modalDispatch(openModal(ModalId.POOL_WITHDRAW_WAIT_FOR_CONFIRMATION))
          // withdraw in same token
          if (
            withdrawLiquidity.toTokenSymbol === withdrawLiquidity.tokenSymbol
          ) {
            // withdraw non ERC20 AVAX in tpAVAX pool
            if (withdrawLiquidity.tokenSymbol === TokenSymbol.AVAX) {
              const config = await prepareWriteContract({
                address: poolAddress,
                abi: POOLAVAX_ABI,
                functionName: 'withdrawETH',
                args: [
                  amount,
                  withdrawMinimumReceived,
                  account,
                  BigNumber.from(
                    await getDeadline(userPreference.transactionDeadline),
                  ),
                ],
                chainId,
              })
              writeContractResult = await writeContract(config)
            } else if (tokenAddress) {
              const config = await prepareWriteContract({
                address: poolAddress,
                abi: POOL__ABI,
                functionName: 'withdraw',
                args: [
                  tokenAddress,
                  amount,
                  withdrawMinimumReceived,
                  account,
                  BigNumber.from(
                    await getDeadline(userPreference.transactionDeadline),
                  ),
                ],
                chainId,
              })
              writeContractResult = await writeContract(config)
            }
          } else {
            if (withdrawLiquidity.toTokenSymbol) {
              const toTokenInstance =
                withdrawLiquidity.toTokenSymbol === TokenSymbol.AVAX
                  ? TOKENS[TokenSymbol.WAVAX]
                  : TOKENS[withdrawLiquidity.toTokenSymbol]
              const toTokenAddress = toTokenInstance.getAddress(chainId)
              if (tokenAddress && toTokenAddress) {
                /** Third party avax (tpAvax): withdrawFromOtherAsset */
                if (
                  POOLS[selectedPoolSymbol].isTpAvaxPool ||
                  POOLS[selectedPoolSymbol].tokenConversionRateData
                ) {
                  let toTokenAmountInTermsOfLp: null | BigNumber = null
                  let minimumToTokenAmountInTermsOfLp: null | BigNumber = null

                  // tpAvax and Avax
                  if (POOLS[selectedPoolSymbol].isTpAvaxPool) {
                    const tpAvaxToAvaxRate = await getTpAvaxToAvaxRateWad(
                      selectedPoolSymbol,
                      chainId,
                      readOnlyProvider,
                    )
                    if (tpAvaxToAvaxRate) {
                      toTokenAmountInTermsOfLp =
                        getToLpTokenAmountForTpAvaxWithdrawal(
                          withdrawLiquidity.tokenSymbol,
                          withdrawLiquidity.toTokenSymbol,
                          amount,
                          tpAvaxToAvaxRate,
                        )
                      // native decimals
                      minimumToTokenAmountInTermsOfLp =
                        getToLpTokenAmountForTpAvaxWithdrawal(
                          withdrawLiquidity.tokenSymbol,
                          withdrawLiquidity.toTokenSymbol,
                          withdrawMinimumReceived,
                          tpAvaxToAvaxRate,
                        )
                    }
                  }

                  /** @todo refactor code to make it more generic */
                  const tokenConversionRateData =
                    POOLS[selectedPoolSymbol].tokenConversionRateData
                  if (tokenConversionRateData) {
                    const tpTokenToTokenRateWad =
                      await getAnkrEthToWetheRateWad(chainId, readOnlyProvider)
                    if (tpTokenToTokenRateWad) {
                      const rateObj = {
                        ...tokenConversionRateData,
                        valueWad: tpTokenToTokenRateWad,
                      }
                      toTokenAmountInTermsOfLp = getToLpTokenAmountByRate(
                        withdrawLiquidity.tokenSymbol,
                        withdrawLiquidity.toTokenSymbol,
                        amount,
                        rateObj,
                      )
                      // native decimals
                      minimumToTokenAmountInTermsOfLp =
                        getToLpTokenAmountByRate(
                          withdrawLiquidity.tokenSymbol,
                          withdrawLiquidity.toTokenSymbol,
                          withdrawMinimumReceived,
                          rateObj,
                        )
                    }
                  }
                  if (
                    toTokenAmountInTermsOfLp &&
                    minimumToTokenAmountInTermsOfLp
                  ) {
                    // amount is in terms of lp token
                    const config = await prepareWriteContract({
                      address: poolAddress,
                      abi: POOL__ABI,
                      functionName: 'withdrawFromOtherAsset',
                      args: [
                        tokenAddress,
                        toTokenAddress,
                        changeDecimal(
                          tokenInstance.decimals,
                          toTokenInstance.decimals,
                          toTokenAmountInTermsOfLp,
                        ),
                        minimumToTokenAmountInTermsOfLp,
                        account,
                        BigNumber.from(
                          await getDeadline(userPreference.transactionDeadline),
                        ),
                      ],
                      chainId,
                    })
                    writeContractResult = await writeContract(config)
                  }
                } else {
                  const config = await prepareWriteContract({
                    address: poolAddress,
                    abi: POOL__ABI,
                    functionName: 'withdrawFromOtherAsset',
                    args: [
                      tokenAddress,
                      toTokenAddress,
                      changeDecimal(
                        tokenInstance.decimals,
                        toTokenInstance.decimals,
                        amount,
                      ),
                      BigNumber.from(withdrawMinimumReceived),
                      account,
                      BigNumber.from(
                        await getDeadline(userPreference.transactionDeadline),
                      ),
                    ],
                    chainId,
                  })
                  writeContractResult = await writeContract(config)
                }
              }
            }
          }
          if (writeContractResult) {
            const { hash, wait } = writeContractResult
            await wait()
            showMessage('Successfully withdrew.')
            modalDispatch(
              openModal(ModalId.TRANSACTION_SUBMITTED, {
                transactionHashes: [hash],
                tokenSymbols:
                  (withdrawLiquidity.toTokenSymbolForDisplay && [
                    withdrawLiquidity.toTokenSymbolForDisplay,
                  ]) ||
                  undefined,
              }),
            )
          } else {
            showMessage('Transaction failed.', 'warning')
            modalDispatch(openModal(ModalId.UNSET))
          }
        }
        clearWithdrawLiquidity()
      } catch (err) {
        await checkIfUserDeniedTransaction(
          err,
          undefined,
          {
            name: 'pool.withdraw / pool.withdrawFromOtherAsset',
            token_symbol: withdrawLiquidity.tokenSymbol,
            to_token_symbol: withdrawLiquidity.toTokenSymbol,
            amount: withdrawLiquidity.amount,
          },
          ActionId.POOL_WITHDRAWAL,
        )
        clearWithdrawLiquidity()
      }
    }
  }
  return { handleClickWithdraw }
}
export default useWithdrawToken
