import { getMinValue, strToWad, wmul } from '@hailstonelabs/big-number-utils'
import { BigNumber, utils } from 'ethers'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { PoolSymbol } from '../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import useVeptpToReceive from '../hooks/vePtp/locking/useVeptpToReceive'
import { getMaxVePtpToEarnWAD } from '../utils/vePtp'
import { usePools } from './PoolsContext'
import { useStakeLpData } from './StakeLpDataContext'
import { useVePtp } from './VePtpContext'

export enum TabId {
  VEPTP = 'vePTP',
  PTP = 'PTP',
}

interface MyStakedDepositType {
  amount: string
  assetTokenSymbol: TokenSymbol | null
  poolSymbol: PoolSymbol | null
}

interface MyStakedPtpType {
  amount: string
  stakingPeriod: string
}

interface MyLockedPtpType {
  amount: string
  lockDay: string
}

interface VePtpBoosterCalculatorContextType {
  myStakedDepositInput: MyStakedDepositType
  poolLiquidityInputAmount: string
  myVePtpInputAmount: string
  totalVePtpSupplyInputAmount: string
  myStakedPtpInput: MyStakedPtpType
  myLockedPtpInput: MyLockedPtpType
  estimatedVePtpBalance: string
  currentTabId: TabId
  actions: {
    // LP
    updateAmountFromMyStakedDepositInput: (amount: string) => void
    updateTargetTokenFromMyStakedDepositInput: (
      poolSymbol: PoolSymbol | null,
      assetTokenSymbol: TokenSymbol | null,
    ) => void
    updatePoolLiquidityInputAmount: (amount: string) => void
    resetAmountFromMyStakedDepositInput: () => void
    resetPoolLiquidityInputAmount: () => void
    // vePTP
    updateMyVePtpInput: (amount: string) => void
    updateTotalVePtpSupplyInput: (amount: string) => void
    resetMyVePtpInput: () => void
    resetTotalVePtpSupplyInput: () => void
    handleTabChange: (tabId: TabId) => void
    // PTP Staking
    updateAmountFromMyStakedPtpInput: (amount: string) => void
    updateStakingPeriodFromMyStakedPtpInput: (stakingPeriod: string) => void
    resetAmountFromMyStakedPtpInput: () => void
    // PTP Locking
    updateAmountFromMyLockedPtpInput: (amount: string) => void
    updateLockDayFromMyLockedPtpInput: (lockDay: string) => void
    resetAmountFromMyLockedPtpInput: () => void
  }
}
const VePtpBoosterCalculatorContext = createContext(
  {} as VePtpBoosterCalculatorContextType,
)
VePtpBoosterCalculatorContext.displayName = 'VePtpBoosterCalculatorContext'

export const useVePtpBoosterCalculator =
  (): VePtpBoosterCalculatorContextType => {
    return useContext(VePtpBoosterCalculatorContext)
  }
interface Props {
  children: React.ReactNode
}
function VePtpBoosterCalculatorProvider({ children }: Props): ReactElement {
  const { lp } = useStakeLpData()
  const { liabilities } = usePools()
  const { vePtp, ptp } = useVePtp()
  // inputs state
  const [myStakedDepositInput, setMyStakedDepositInput] =
    useState<MyStakedDepositType>({
      amount: '',
      assetTokenSymbol: null,
      poolSymbol: null,
    })
  const [poolLiquidityInputAmount, setPoolLiquidityInputAmount] =
    useState<string>('')
  const [myVePtpInputAmount, setMyVePtpInputAmount] = useState<string>('')
  const [totalVePtpSupplyInputAmount, setTotalVePtpSupplyInputAmount] =
    useState<string>('')
  const [myStakedPtpInput, setMyStakedPtpInput] = useState<MyStakedPtpType>({
    amount: '0',
    stakingPeriod: '0',
  })
  const [currentTabId, setCurrentTabId] = useState<TabId>(TabId.VEPTP)

  const handleTabChange = (tabId: TabId) => {
    setCurrentTabId(tabId)
  }

  // Ptp Locking
  const [myLockedPtpInput, setMyLockedPtpInput] = useState<MyLockedPtpType>({
    amount: '0',
    lockDay: '0',
  })
  const { veptpToReceive } = useVeptpToReceive({
    lockDayInputAmount: myLockedPtpInput.lockDay,
    lockPtpInputAmount: myLockedPtpInput.amount,
  })

  // LP
  const updateAmountFromMyStakedDepositInput = (amount: string) => {
    setMyStakedDepositInput((prev) => ({ ...prev, amount }))
  }
  const updateTargetTokenFromMyStakedDepositInput = (
    poolSymbol: PoolSymbol | null,
    assetTokenSymbol: TokenSymbol | null,
  ) => {
    setMyStakedDepositInput((prev) => ({
      ...prev,
      assetTokenSymbol,
      poolSymbol,
    }))
  }

  const updatePoolLiquidityInputAmount = (amount: string) => {
    setPoolLiquidityInputAmount(amount)
  }

  const resetAmountFromMyStakedDepositInput = useCallback(() => {
    if (
      myStakedDepositInput.assetTokenSymbol &&
      myStakedDepositInput.poolSymbol
    ) {
      updateAmountFromMyStakedDepositInput(
        lp.inTermsOfToken.total[myStakedDepositInput.poolSymbol][
          myStakedDepositInput.assetTokenSymbol
        ] || '0.0',
      )
    }

    // lp.total will be kept updating so we can't pass it as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myStakedDepositInput.assetTokenSymbol, myStakedDepositInput.poolSymbol])

  const resetPoolLiquidityInputAmount = useCallback(() => {
    if (
      myStakedDepositInput.assetTokenSymbol &&
      myStakedDepositInput.poolSymbol
    ) {
      updatePoolLiquidityInputAmount(
        liabilities[myStakedDepositInput.poolSymbol][
          myStakedDepositInput.assetTokenSymbol
        ],
      )
    }
    // liabilities will be kept updating so we can't pass it as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myStakedDepositInput.assetTokenSymbol, myStakedDepositInput.poolSymbol])

  // vePTP
  const updateMyVePtpInput = (amount: string) => {
    setMyVePtpInputAmount(amount)
  }

  const updateTotalVePtpSupplyInput = (amount: string) => {
    setTotalVePtpSupplyInputAmount(amount)
  }

  const resetMyVePtpInput = () => {
    updateMyVePtpInput(vePtp.balance.total.current)
  }
  const resetTotalVePtpSupplyInput = () => {
    setTotalVePtpSupplyInputAmount(vePtp.totalSupply)
  }

  const updateMyVePtpInputWithPtpAmount = (
    ptpAmount: string,
    stakingMonths: string,
  ) => {
    const vePtpPerHourWad = wmul(
      strToWad(vePtp.generationRate.perSecond),
      strToWad(ptpAmount),
    )
      .mul('60')
      .mul('60')

    const vePtpPerMonthWad = vePtpPerHourWad.mul('24').mul('31')
    const stakingPeriodWad = strToWad(stakingMonths)
    const vePtpInputAmountWad = wmul(vePtpPerMonthWad, stakingPeriodWad)
    setMyVePtpInputAmount(utils.formatEther(vePtpInputAmountWad))
  }

  // PTP Staking
  const updateAmountFromMyStakedPtpInput = (amount: string) => {
    setMyStakedPtpInput((prev) => ({ ...prev, amount }))
    updateMyVePtpInputWithPtpAmount(amount, myStakedPtpInput.stakingPeriod)
  }
  const updateStakingPeriodFromMyStakedPtpInput = (stakingPeriod: string) => {
    setMyStakedPtpInput((prev) => ({ ...prev, stakingPeriod }))
    updateMyVePtpInputWithPtpAmount(myStakedPtpInput.amount, stakingPeriod)
  }

  const resetAmountFromMyStakedPtpInput = () => {
    updateAmountFromMyStakedPtpInput(ptp.amount.staked)
  }

  // PTP Locking
  const updateAmountFromMyLockedPtpInput = (amount: string) => {
    setMyLockedPtpInput((prev) => ({ ...prev, amount }))
  }

  const updateLockDayFromMyLockedPtpInput = (lockDay: string) => {
    setMyLockedPtpInput((prev) => ({ ...prev, lockDay }))
  }

  const resetAmountFromMyLockedPtpInput = useCallback(() => {
    setMyLockedPtpInput({
      amount: ptp.amount.locked,
      lockDay: '',
    })
  }, [ptp.amount.locked])

  // Estimated Veptp Balance
  const estimatedMaxEarning = useMemo(() => {
    return utils.formatEther(
      // PTP Staking
      getMaxVePtpToEarnWAD(
        BigNumber.from(vePtp.maxCapPerPtp.staking),
        strToWad(myStakedPtpInput.amount),
      ),
    )
  }, [myStakedPtpInput.amount, vePtp.maxCapPerPtp.staking])

  const estimatedVePtpBalance = useMemo(() => {
    return utils.formatEther(
      (currentTabId === TabId.VEPTP
        ? strToWad(myVePtpInputAmount)
        : getMinValue(myVePtpInputAmount, estimatedMaxEarning)
      ).add(
        // PTP Locking
        strToWad(
          // User will not receive veptp when input lock day < min lock day
          Number(myLockedPtpInput.lockDay) < ptp.lockTime.minDays
            ? '0'
            : veptpToReceive,
        ),
      ),
    )
  }, [
    currentTabId,
    myVePtpInputAmount,
    estimatedMaxEarning,
    myLockedPtpInput.lockDay,
    ptp.lockTime.minDays,
    veptpToReceive,
  ])

  // always reset the current amount when users select a new tokenSymbol
  useEffect(() => {
    resetAmountFromMyStakedDepositInput()
    resetPoolLiquidityInputAmount()
  }, [resetAmountFromMyStakedDepositInput, resetPoolLiquidityInputAmount])

  // always reset lockPtpInput when switching between vePTP/PTP tab
  useEffect(() => {
    resetAmountFromMyLockedPtpInput()
  }, [currentTabId, resetAmountFromMyLockedPtpInput])

  return (
    <VePtpBoosterCalculatorContext.Provider
      value={{
        myStakedDepositInput,
        poolLiquidityInputAmount,
        myVePtpInputAmount,
        totalVePtpSupplyInputAmount,
        myStakedPtpInput,
        myLockedPtpInput,
        estimatedVePtpBalance,
        currentTabId,
        actions: {
          // LP
          updateAmountFromMyStakedDepositInput,
          updateTargetTokenFromMyStakedDepositInput,
          updatePoolLiquidityInputAmount,
          resetAmountFromMyStakedDepositInput,
          resetPoolLiquidityInputAmount,
          // vePTP
          updateMyVePtpInput,
          updateTotalVePtpSupplyInput,
          resetMyVePtpInput,
          resetTotalVePtpSupplyInput,
          handleTabChange,
          // PTP Staking
          updateAmountFromMyStakedPtpInput,
          updateStakingPeriodFromMyStakedPtpInput,
          resetAmountFromMyStakedPtpInput,
          // PTP Locking
          updateAmountFromMyLockedPtpInput,
          updateLockDayFromMyLockedPtpInput,
          resetAmountFromMyLockedPtpInput,
        },
      }}
    >
      {children}
    </VePtpBoosterCalculatorContext.Provider>
  )
}

export default VePtpBoosterCalculatorProvider
