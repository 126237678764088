import { strToWad } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import React, { ReactElement, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useSearchParams } from 'react-router-dom'
import AppTypography from '../../components/AppTypography/AppTypography'
import AppButton from '../../components/buttons/AppButton/AppButton'
import TokenIcon from '../../components/TokenIcon/TokenIcon'
import WarningMessage, {
  WarningMessageSeverityType,
} from '../../components/WarningMessage/WarningMessage'
import { PoolSymbol } from '../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { QUERY_PARAMS } from '../../constants'
import { useErrorModal } from '../../contexts/ErrorModalContext'
import { useModal } from '../../contexts/ModalContext'
import { useNetwork } from '../../contexts/NetworkContext'
import { useSwap } from '../../contexts/SwapContext'
import { useFilterSwapToken } from '../../hooks/swap/useFilterSwapToken'
import useApprove from '../../hooks/useApprove'
import useBreakpoints from '../../hooks/useBreakpoints'
import useAddTokenToWallet from '../../hooks/wallet/useAddTokenToWallet'
import useWalletConnection from '../../hooks/wallet/useWalletConnection'
import { SwapQuotationErrorType } from '../../interfaces/Error'
import { ModalId } from '../../interfaces/Modal'
import { ApproveSpender } from '../../interfaces/spender'
import { getTokenSymbol } from '../../utils/common'
import SelectTokenModalContainer from '../SelectTokenModalContainer/SelectTokenModalContainer'
import ConfirmSwapModalContainer from '../SwapModalsContainer/ConfirmSwapModalContainer/ConfirmSwapModalContainer'
import SwapWaitForConfirmationModalContainer from '../SwapModalsContainer/SwapWaitForConfirmationModalContainer/SwapWaitForConfirmationModalContainer'
import {
  AddTokenToWalletButton,
  Container,
  Shade,
} from './SwapContainer.elements'
import SwapInfoBoxContainer from './SwapInfoBoxContainer'
import TokenInputsContainer from './TokenInputsContainer'
interface OnSelectTokenType {
  token: TokenSymbol
}

function SwapContainer(): ReactElement {
  const {
    isRouter,
    poolSymbolForSwap,
    tokenSymbolsPath,
    fromTokenAmount,
    toTokenAmount,
    fromTokenSymbol,
    toTokenSymbol,
    updateFromTokenSymbol,
    updateToTokenSymbol,
    isFromAmountExceedsUserBalance,
    isUnwrappingWavaxToAvax,
    isWrappingAvaxToWavax,
    quotationErrorType,
  } = useSwap()
  const { account } = useNetwork()
  const { openConnectToWalletModal } = useWalletConnection()
  const { isTabletSm } = useBreakpoints()
  const addTokenToWallet = useAddTokenToWallet()
  const { openErrorModal } = useErrorModal()
  // update FROM/TO TokenSymbol from url query string
  const [searchParams, setSearchParams] = useSearchParams()
  const queryfrom = searchParams.get(QUERY_PARAMS.swapFrom)
  const queryTo = searchParams.get(QUERY_PARAMS.swapTo)
  useEffect(() => {
    if (queryfrom) {
      updateFromTokenSymbol(getTokenSymbol(queryfrom))
    }

    if (
      queryTo &&
      // only set TO token symbol if it is not the same as FROM
      getTokenSymbol(queryfrom) !== getTokenSymbol(queryTo)
      // check if they are in the same SwapGroupSymbol
    ) {
      updateToTokenSymbol(getTokenSymbol(queryTo))
    }
    // use empty dependency so it only run once when the page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // update search params after token selection
  useEffect(() => {
    const newSearchParams: Record<string, string> = {}
    if (fromTokenSymbol) {
      newSearchParams[QUERY_PARAMS.swapFrom] = fromTokenSymbol
    } else if (queryfrom) {
      // when users type wrong symbols in the URL, keep the query string in it
      newSearchParams[QUERY_PARAMS.swapFrom] = queryfrom
    }
    if (toTokenSymbol) {
      newSearchParams[QUERY_PARAMS.swapTo] = toTokenSymbol
    } else if (queryTo) {
      // when users type wrong symbols in the URL, keep the query string in it
      newSearchParams[QUERY_PARAMS.swapTo] = queryTo
    }
    setSearchParams(newSearchParams)
  }, [toTokenSymbol, fromTokenSymbol, queryfrom, queryTo, setSearchParams])
  let spender

  if (isUnwrappingWavaxToAvax) {
    spender = ApproveSpender.WAVAX
  } else if (isRouter) {
    spender = ApproveSpender.Router
  } else {
    spender = ApproveSpender.Pool
  }

  const { isApproved, isApproving, handleClickApprove } = useApprove(
    fromTokenSymbol,
    false,
    spender,
    fromTokenAmount,
    // avoid console.error in useApprove
    poolSymbolForSwap || PoolSymbol.DEPRECATED_MAIN,
  )
  const {
    modalState,
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { filteredToTokenSymbols, filteredFromTokenSymbols } =
    useFilterSwapToken()
  const handleOnSelectFromToken = (value: OnSelectTokenType) => {
    updateFromTokenSymbol(value.token)
  }

  const handleOnSelectToToken = (value: OnSelectTokenType) => {
    updateToTokenSymbol(value.token)
  }

  const hasSwapDetail =
    !!fromTokenSymbol && !!fromTokenAmount && !!toTokenSymbol && !!toTokenAmount
  const hasZeroInput =
    strToWad(fromTokenAmount).isZero() || strToWad(toTokenAmount).isZero()
  const showSwapInfo = hasSwapDetail && !hasZeroInput && !quotationErrorType
  const isReadyToSwap = showSwapInfo && !isFromAmountExceedsUserBalance

  const [warning, setWarning] = useState('')
  const [warningSeverity, setWarningSeverity] =
    useState<WarningMessageSeverityType>('warning')

  useEffect(() => {
    let newWarning = ''
    let newWarningSeverity = 'warning' as WarningMessageSeverityType
    let newErrorModalMessage = ''
    // TO or FROM token is not selected, both tokens selected but one amount is not specified
    if (
      fromTokenSymbol === null ||
      toTokenSymbol === null ||
      (fromTokenAmount === '' && toTokenAmount === '')
    ) {
      newWarning = 'Select Tokens and Enter Amounts'
      newWarningSeverity = 'info'
    }
    //FROM or TO amount is 0
    else if (
      (strToWad(fromTokenAmount).isZero() && toTokenAmount == '') ||
      (strToWad(toTokenAmount).isZero() && fromTokenAmount == '')
    ) {
      // avoid warning when calculate the amount
      newWarning = 'Enter a non-zero amount'
    } else if (quotationErrorType) {
      switch (quotationErrorType) {
        case SwapQuotationErrorType.PRICE_DEV:
          newWarning = 'Swapping is temporarily disabled due to price deviation'
          newErrorModalMessage = newWarning

          break
        case SwapQuotationErrorType.INSUFFICIENT_CASH:
          newWarning = `${
            toTokenSymbol ? toTokenSymbol : ''
          } amount exceeds pool balance`
          break
        case SwapQuotationErrorType.PAUSABLE_PAUSED:
          newWarning = 'Swapping is temporarily paused for this pool'
          newErrorModalMessage = newWarning

          break
      }
    } else if (isFromAmountExceedsUserBalance) {
      // AmountExceedsUserBalance
      newWarning = `${
        fromTokenSymbol ? fromTokenSymbol : ''
      } amount exceeds account balance`
    }
    if (newErrorModalMessage) {
      openErrorModal({
        message: newErrorModalMessage,
      })
    }
    setWarning(newWarning)
    setWarningSeverity(newWarningSeverity)
  }, [
    quotationErrorType,
    fromTokenAmount,
    fromTokenSymbol,
    isFromAmountExceedsUserBalance,
    openErrorModal,
    toTokenAmount,
    toTokenSymbol,
  ])
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        pt={isTabletSm ? '20px' : '64px'}
      >
        <Container
          position="relative"
          display="flex"
          flexDirection="column"
          allowToClickSwap={isReadyToSwap && isApproved}
        >
          <Shade />
          <TokenInputsContainer />

          {modalState.currentModalId === ModalId.SELECT_FROM_TOKEN && (
            <SelectTokenModalContainer
              tokenSymbols={filteredFromTokenSymbols}
              isOpen
              onSelectToken={handleOnSelectFromToken}
              currentSelectedToken={fromTokenSymbol || undefined}
            />
          )}
          {modalState.currentModalId === ModalId.SELECT_TO_TOKEN && (
            <SelectTokenModalContainer
              tokenSymbols={filteredToTokenSymbols}
              isOpen
              onSelectToken={handleOnSelectToToken}
              currentSelectedToken={toTokenSymbol || undefined}
            />
          )}
          {showSwapInfo && (
            <SwapInfoBoxContainer
              route={
                isRouter ? tokenSymbolsPath : [fromTokenSymbol, toTokenSymbol]
              }
            />
          )}

          {account ? (
            // a wallet is connected
            <>
              {/* Approve, Approving and Swap button */}
              {isReadyToSwap && (
                <Box display="flex" flexDirection="row">
                  {!isApproved ? (
                    <AppButton
                      onClick={() => void handleClickApprove()}
                      disabled={isApproved || isApproving || hasZeroInput}
                      fullWidth
                      customVariant="secondary"
                      margin="0"
                      hasSpinner={isApproving}
                    >
                      {isApproving ? (
                        <>Approving</>
                      ) : (
                        <>
                          Approve
                          {!isTabletSm && (
                            <>
                              <TokenIcon
                                tokenSymbol={fromTokenSymbol || undefined}
                                margin="0 4px 0 12px"
                              />{' '}
                              {fromTokenSymbol || ''}
                            </>
                          )}
                        </>
                      )}
                    </AppButton>
                  ) : (
                    <AppButton
                      onClick={() => {
                        ReactGA.event({
                          category: 'button',
                          action: 'click',
                          label: 'swap',
                        })
                        modalDispatch(openModal(ModalId.CONFIRM_SWAP))
                      }}
                      disabled={!isApproved || isFromAmountExceedsUserBalance}
                      fullWidth
                      margin="0"
                    >
                      {(isWrappingAvaxToWavax && 'Wrap') ||
                        (isUnwrappingWavaxToAvax && 'Unwrap') ||
                        'Swap'}
                    </AppButton>
                  )}
                </Box>
              )}

              {/* warning messages */}
              {!isReadyToSwap && warning && (
                <WarningMessage message={warning} severity={warningSeverity} />
              )}
            </>
          ) : (
            // no wallet is connected
            <AppButton fullWidth onClick={openConnectToWalletModal}>
              Connect Wallet
            </AppButton>
          )}

          {modalState.currentModalId === ModalId.CONFIRM_SWAP && (
            <ConfirmSwapModalContainer isOpen />
          )}
          {modalState.currentModalId === ModalId.SWAP_WAIT_FOR_CONFIRMATION && (
            <SwapWaitForConfirmationModalContainer isOpen />
          )}
        </Container>
      </Box>

      {!isTabletSm && toTokenSymbol && toTokenSymbol !== TokenSymbol.AVAX && (
        <AddTokenToWalletButton
          onClick={() => void addTokenToWallet(toTokenSymbol)}
        >
          <AppTypography variant="caption2">
            Add {toTokenSymbol} to Wallet
          </AppTypography>
        </AddTokenToWalletButton>
      )}
    </>
  )
}

export default React.memo(SwapContainer)
