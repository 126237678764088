import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import Modal from '../../../components/Modal/Modal'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { ScrollableBox } from '../../../globalStyles'
import useUnlockPtp from '../../../hooks/vePtp/locking/useUnlockPtp'
import useUnstakePtp from '../../../hooks/vePtp/useUnstakePtp'
import { ModalId } from '../../../interfaces/Modal'
import UnstakeOrUnlockModalInfoBox from '../UnstakeModalContainer/UnstakeOrUnlockModalInfoBox'
import { Warning } from './ConfirmUnstakeOrUnlockModalContainer.elements'
import UnstakeOrUnlockDisabledInputsContainer from './UnstakeOrUnlockDisabledInputsContainer'

interface Props {
  isOpen: boolean
}

function ConfirmUnstakeOrUnlockModalContainer({ isOpen }: Props): ReactElement {
  const {
    modalDispatch,
    modalState,
    actions: { closeModal },
  } = useModal()
  const handleModalClose = () => {
    modalDispatch(closeModal())
  }
  const { handleUnstakePtp } = useUnstakePtp()

  const { handleUnlockPtp } = useUnlockPtp()

  const { input, nft, vePtp, ptp } = useVePtp()

  const isInConfirmUnlockModal =
    modalState.currentModalId === ModalId.CONFIRM_UNLOCK_PTP
  const isInConfirmUnstakeModal =
    modalState.currentModalId === ModalId.CONFIRM_UNSTAKE_PTP

  function getWarningMessage() {
    if (!isInConfirmUnstakeModal && !isInConfirmUnlockModal) return ''
    if (
      nft.isHibernateNft &&
      !input.isUnstakedAllPtpWithEquippedNft &&
      input.unstakePtpAmount &&
      isInConfirmUnstakeModal
    ) {
      return `Due to the hibernate ability, you will lose your accumulated vePTP (${getCommifiedFormat(
        input.vePtpAmount.burnt,
        2,
      )} vePTP) and pending vePTP (${getCommifiedFormat(
        vePtp.pendingAmount,
        2,
      )} vePTP), after unstaking ${input.unstakePtpAmount} PTP.`
    } else {
      if (isInConfirmUnlockModal)
        return 'Do you confirm that after unlocking PTP, you will lose all of your accumulated vePTP!'
      if (isInConfirmUnstakeModal)
        return `Do you confirm that after unstaking PTP,  you will lose your accumulated vePTP (${getCommifiedFormat(
          input.vePtpAmount.burnt,
          2,
        )} vePTP) and pending vePTP (${getCommifiedFormat(
          vePtp.pendingAmount,
          2,
        )} vePTP)!`
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      width="100%"
      maxWidth="500px"
      disableCloseBtn
      disableTopBar
    >
      <Warning>
        <WarningAmberIcon className="warning__icon" />
        <AppTypography variant="h5">WARNING!</AppTypography>
        <AppTypography variant="caption" className="warning__message">
          {getWarningMessage()}
        </AppTypography>
      </Warning>
      <ScrollableBox>
        <UnstakeOrUnlockDisabledInputsContainer
          ptpAmount={
            isInConfirmUnstakeModal ? input.unstakePtpAmount : ptp.amount.locked
          }
          vePtpToLose={
            isInConfirmUnstakeModal
              ? input.vePtpAmount.burnt
              : vePtp.balance.locking
          }
        />
      </ScrollableBox>
      <UnstakeOrUnlockModalInfoBox />
      <Box display="flex" flexDirection="row" mt={1}>
        <AppButton onClick={handleModalClose} fullWidth customVariant="neutral">
          Cancel
        </AppButton>
        {isInConfirmUnstakeModal && (
          <AppButton onClick={() => void handleUnstakePtp()} fullWidth>
            Unstake
          </AppButton>
        )}
        {isInConfirmUnlockModal && (
          <AppButton onClick={() => void handleUnlockPtp()} fullWidth>
            Unlock
          </AppButton>
        )}
      </Box>
    </Modal>
  )
}

export default ConfirmUnstakeOrUnlockModalContainer
