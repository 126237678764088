import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppTypography from '../AppTypography/AppTypography'
import AppButton from '../buttons/AppButton/AppButton'

export const InnerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const Container = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    border-radius: ${theme.borderRadius.md};
    padding: 12px 40px;
    display: flex;
    flex-direction: column;
    background: radial-gradient(
        18% 28% at 0% 0%,
        rgba(50, 255, 68, 0.4) 0%,
        rgba(50, 255, 68, 0.24) 34.22%,
        rgba(12, 88, 255, 0) 100%
      ),
      radial-gradient(
        18% 28% at 100% 0%,
        #0050ff 0%,
        rgba(6, 45, 130, 0.51) 74.33%,
        rgba(6, 45, 130, 0) 100%
      ),
      linear-gradient(119.51deg, #0e0f28 19.73%, #000113 100%);
    border: 1px solid ${theme.palette.primary[500]};
    @media only screen and (max-width: ${theme.breakpoints.values.lg}px) {
      padding: 12px 24px;
    }

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding: 16px;
    }
  `}
`

export const ClaimAllRewardsButton = styled(AppButton)`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.sm};
    padding: 2px 10px;
    margin: 0;
  `}
`

/** @todo add to global styles later */
export const HideInMobile = styled.span`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      && {
        display: none;
      }
    }
  `}
`

export const RewardItem = styled(AppTypography)`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    margin: 4px 8px;
    margin-left: 0;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      && {
        margin: 4px 0;
      }
    }
  `}
`
export const RewardList = styled(Box)`
  ${() => css`
    display: flex;
    flex-wrap: wrap;
    margin: 0 8px 0 4px;
  `}
`

export const ExtraRewardTokenIndicator = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background: ${alpha(theme.palette.primary[500], 0.2)};
    border-radius: 999px;
    padding: 2px;
    height: 16px;
  `}
`
export const Label = styled(AppTypography)`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-right: 8px;
  min-width: 80px;
`
