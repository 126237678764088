import React from 'react'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'

// Ptp Lock & Stake
export const ptpFeatureInfoData = {
  TO_LOSE: {
    label: 'To lose',
    tooltip: {
      unstake: 'The amount of vePTP you will lose after unstaking your PTP.',
      unlock: 'The amount of vePTP you will lose after unlocking your PTP.',
    },
  },
  MY_REMAIN_PTP_LOCKED: {
    label: 'My Remaining PTP Locked',
  },
  MY_REMAIN_PTP_STAKED: {
    label: 'My Remaining PTP Staked',
  },
  MY_REMAIN_VEPTP: {
    label: 'My Remaining vePTP',
    tooltip: {
      unstake: 'Remaining vePTP from your locked and staked PTP.',
      unlock: 'Remaining vePTP from your staked PTP.',
    },
  },
}

// Ptp Lock
export const lockPtpInfoData = {
  UNLOCK_DATE: {
    label: 'Unlock Date',
    tooltip: 'The date you can unlock your PTP.',
  },
  TIME_LEFT: {
    label: 'Time Left',
    tooltip: 'The time left to unlock your PTP.',
  },
  TOTAL_LOCK: {
    label: 'Total Lock',
    tooltip: 'Sum of your locked PTP and PTP to lock.',
  },
  MULTIPLIER: {
    label: 'Multiplier',
  },
  MAX_CAP: {
    label: 'Max Cap',
  },
  TO_RECEIVE: {
    label: 'To Receive',
    tooltip: 'The amount of vePTP you will receive.',
  },
  EARNED: {
    label: 'Earned',
    tooltip:
      'The amount of vePTP you have already earned from your locked PTP.',
  },
  TOTAL_LOCK_DAYS: {
    label: 'Total Lock Days',
    tooltip: 'The total days you lock your PTP.',
  },
}

export const uspInfoData = {
  HEALTH_FACTOR: {
    label: 'Health Factor',
    toolTip: (
      <>
        The proportion of your collateral value at the liquidation limit against
        your borrowed {TokenSymbol.USP} value including accrued interest.
        <br />
        <br />
        The higher this factor is, the safer you are from liquidation. When this
        factor reaches 1, your collateral can be liquidated.
      </>
    ),
  },
  MIN_BORROW: {
    label: 'Min. borrow',
    toolTip: `The minimum amount of ${TokenSymbol.USP} you are required to borrow.`,
  },
  LQ_PENALITY: {
    label: 'Liquidation Penalty',
    toolTip: 'The fee paid by liquidators.',
  },
  STABILITY_FEE: {
    label: 'Stability Fee',
    toolTip: `The dynamic annual interest rate based on the coverage ratio of ${TokenSymbol.USP} in our main pool.`,
  },
  BORROW_FEE: {
    label: 'Borrow Fee',
    toolTip: `The fee added to your debt when you borrow ${TokenSymbol.USP}.`,
  },
  AMOUNT_TO_RECEIVE: {
    label: 'Amount to receive',
    toolTip: 'The amount you will receive after borrow fee.',
  },
  REPAY_AMOUNT: {
    label: 'Repay Amount (inc. interest)',
  },
  REMAIN_BORROW: {
    label: 'Remaining Borrow',
  },
  BORROWED: {
    label: 'Borrowed',
    toolTip: `The borrowed ${TokenSymbol.USP} of your position. This amount includes accrued interest.`,
  },
  MY_BORROW: {
    label: 'My Borrow',
    toolTip: (
      <>
        Sum of your borrowed {TokenSymbol.USP} and {TokenSymbol.USP} to borrow
        for this position.
        <br />
        <br />
        This amount includes accrued interest and borrow fee.
      </>
    ),
  },
}
