import { ChainId } from '../../../networks'
import { PoolSymbol } from '../../pool/poolSymbol'
import { TokenSymbol } from '../../token/tokenSymbol'
import { LpToken } from '../LpToken'
export const MAIN_LP_TOKENS = {
  /**@todo update mainnet asset address */
  /**@todo update pid */
  [PoolSymbol.MAIN]: {
    [TokenSymbol.USDTe]: new LpToken({
      isCollateral: false,
      addresses: {
        [ChainId.AVALANCHE]: '0xba347682Cc6175704bb78C55Ac3687ff272409A8',
        [ChainId.FUJI]: '0x317221Eb3dd2FADc5c5a92b61091b509518BBbA4',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDTe,
      poolSymbol: PoolSymbol.MAIN,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 40,
        [ChainId.FUJI]: 3,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0xb37dE12ab1cFD588c9c57Bae6e5675Ea72Db6591',
          [ChainId.FUJI]: '0x0000000000000000000000000000000000000000',
        },
      },
      color: '#79FFA7',
    }),
    [TokenSymbol.USDCe]: new LpToken({
      isCollateral: false,
      addresses: {
        [ChainId.AVALANCHE]: '0x6C9137388c63351089315067927b4D7dCA74460E',
        [ChainId.FUJI]: '0x706130A6FD4A1ABe18f335B1F498d6193A37D375',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDCe,
      poolSymbol: PoolSymbol.MAIN,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 38,
        [ChainId.FUJI]: 1,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x487154f024cb2C900d77388E94585Bbfe2f0f87D',
          [ChainId.FUJI]: '0x0000000000000000000000000000000000000000',
        },
      },
      color: '#00A3FF',
    }),
    [TokenSymbol.DAIe]: new LpToken({
      isCollateral: false,
      addresses: {
        [ChainId.AVALANCHE]: '0x2d566402f05A980eFa74B1815a99bCFf200Cd681',
        [ChainId.FUJI]: '0x1B03d8F9379f3eEf8894B4acCFb20C1C7A218129',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.DAIe,
      poolSymbol: PoolSymbol.MAIN,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 37,
        [ChainId.FUJI]: 0,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x8b530646F4116D136fA9c5fD52E222c8e3369Edc',
          [ChainId.FUJI]: '0x0000000000000000000000000000000000000000',
        },
      },
      color: '#F5AC37',
    }),
    [TokenSymbol.USDC]: new LpToken({
      isCollateral: false,
      addresses: {
        [ChainId.AVALANCHE]: '0x2469eB1F646f57c1987B65a1BF1cDC47856d05FC',
        [ChainId.FUJI]: '0xE6146f75C544B40101A1A5452Dd7ac433a8F72De',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.MAIN,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 39,
        [ChainId.FUJI]: 2,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0xC070307e2340f86B1BA5c8E4A0362958b3B95cDC',
          [ChainId.FUJI]: '0x0000000000000000000000000000000000000000',
        },
      },
      color: '#2775CA',
    }),
    [TokenSymbol.USDT]: new LpToken({
      isCollateral: false,
      addresses: {
        [ChainId.AVALANCHE]: '0xa6E3814743DfFbcd3F561A26FeCEc6Be25986b9a',
        [ChainId.FUJI]: '0x517F9daD2fCbBB3c7aB8012ea9198d6D21FAf165',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDT,
      poolSymbol: PoolSymbol.MAIN,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 41,
        [ChainId.FUJI]: 4,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0xE9006d1729CA8d0c0D2b2B3704E165fAd75150f2',
          [ChainId.FUJI]: '0xE9006d1729CA8d0c0D2b2B3704E165fAd75150f2',
        },
      },
      color: '#53AE94',
    }),
  },
}
