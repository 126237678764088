import { ChainId } from '../../networks'
import { PoolSymbol } from '../pool/poolSymbol'
import { TokenSymbol } from '../token/tokenSymbol'
import { LpToken } from './LpToken'
import { ALT_LP_TOKENS } from './pools/altPool'
import { DEPRECATED_MAIN_LP_TOKENS } from './pools/deprecatedMainPool'
import { DEPRECATED_MAIN_LP_TOKENS2 } from './pools/deprecatedMainPool2'
import { DEPRECATED_ALT_LP_TOKENS } from './pools/deprecatedAltPools'
import { FACTORY_LP_TOKENS } from './pools/factoryPool'
import { MAIN_LP_TOKENS } from './pools/mainPool'

export const LP_TOKENS: {
  [id in PoolSymbol]: {
    [id in TokenSymbol]?: LpToken
  }
} = {
  ...MAIN_LP_TOKENS,
  ...DEPRECATED_MAIN_LP_TOKENS,
  ...DEPRECATED_MAIN_LP_TOKENS2,
  ...DEPRECATED_ALT_LP_TOKENS,
  ...ALT_LP_TOKENS,
  ...FACTORY_LP_TOKENS,
}

export const collateralAssets: LpToken[] = Object.values(LP_TOKENS).reduce(
  (acc, lpTokensInEachPool) => [
    ...acc,
    ...Object.values(lpTokensInEachPool).filter(
      (lpToken) => lpToken.isCollateral,
    ),
  ],
  [] as LpToken[],
)

export const mapLpAddressToPoolSymbolTokenSymbol = (
  chainId: ChainId = ChainId.AVALANCHE,
) => {
  return Object.values(LP_TOKENS)
    .reduce(
      (prev, lpTokensInPool) => [...prev, ...Object.values(lpTokensInPool)],
      [] as LpToken[],
    )
    .filter((lpToken) => lpToken.isAvailable)
    .reduce(
      (prev, lpToken) => ({
        ...prev,
        [(lpToken.address[chainId] || '').toLocaleLowerCase()]: {
          poolSymbol: lpToken.poolSymbol,
          tokenSymbol: lpToken.tokenSymbol,
        },
      }),
      {} as {
        // id is lp token address in lowercase
        [id in string]: {
          poolSymbol: PoolSymbol
          tokenSymbol: TokenSymbol
        }
      },
    )
}
