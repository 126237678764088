import { ValueWithNftType } from './nft'

export interface BeforeAfterStat {
  before: string
  after: string
  isIncreased: boolean
}

export interface VePtpStatsDifference {
  vePtpTotalBalance?: BeforeAfterStat
  maxVePtpCapForStaking?: BeforeAfterStat
  vePtpEarningPerHour?: BeforeAfterStat
  retainPercentage?: BeforeAfterStat
}

export interface VePTPStatsWithoutAndWithNft {
  vePtpTotalBalance: ValueWithNftType<string>
  maxVePtpCapForStaking: ValueWithNftType<string>
  vePtpEarningPerHour: ValueWithNftType<string>
  retainPercentage: ValueWithNftType<string>
}

export enum PtpFeatureTabId {
  LOCK = 'Lock',
  STAKE = 'Stake',
}

export interface TotalLockDays {
  original: string
  // after extending PTP lock
  current: string
}
