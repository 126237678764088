import * as Sentry from '@sentry/react'
import {
  TokenSymbol,
  TokenSymbolKey,
} from '../config/contracts/token/tokenSymbol'
import { DateFormat } from '../interfaces/common'
import { RequestInitWithSentryEventName } from './common.types'

/**
 * Fetch api with try catch and sentry event and return string
 * if there is no sentryEventName in option, then won't send event to sentry.
 * @returns {Promise<string | null>}
 */

export async function safeFetchText(
  apiUrl: string,
  option: RequestInitWithSentryEventName = { method: 'GET' },
): Promise<string | null> {
  try {
    const res = await fetch(apiUrl, { ...option })
    const value = await res.text()
    if (res.ok) {
      return value
    }
    throw new Error(await res.text())
  } catch (err) {
    const { sentryEventName } = option
    if (sentryEventName) {
      Sentry.setContext('event', {
        name: sentryEventName,
      })
      Sentry.captureException(err)
    }
    console.error(err)
  }
  return null
}

/**
 * Fetch api with try catch and sentry event and return json
 * if there is no sentryEventName in option, then won't send event to sentry.
 * @returns {Promise<T | null>}
 */

export async function safeFetchJson<T>(
  apiUrl: string,
  option: RequestInitWithSentryEventName = { method: 'GET' },
): Promise<T | null> {
  try {
    const res = await fetch(apiUrl, { ...option })
    try {
      const value = (await res.json()) as T
      if (res.ok) {
        return value
      }
    } catch {
      throw new Error(`response is not json: ${await res.text()}`)
    }
    throw new Error(await res.text())
  } catch (err) {
    const { sentryEventName } = option
    if (sentryEventName) {
      Sentry.setContext('event', {
        name: sentryEventName,
      })
      Sentry.captureException(err)
    }
    console.error(err)
  }
  return null
}

/**
 * check if a tokenSymbol key or value is valid. If it is valid, return a TokenSymbol. Otherwise, return null.
 * @param {string | null} tokenSymbolKeyOrValue
 * @returns {TokenSymbol | null}
 */
export const getTokenSymbol = (
  tokenSymbolKeyOrValue: string | null,
): TokenSymbol | null => {
  if (tokenSymbolKeyOrValue) {
    // accept to as a key of TokenSymbol e.g USDTe
    if (Object.keys(TokenSymbol).includes(tokenSymbolKeyOrValue)) {
      return TokenSymbol[tokenSymbolKeyOrValue as TokenSymbolKey]
    }
    // accept to as TokenSymbol e.g USDT.e
    if (
      Object.values(TokenSymbol).includes(tokenSymbolKeyOrValue as TokenSymbol)
    ) {
      return tokenSymbolKeyOrValue as TokenSymbol
    }
  }
  return null
}

/**
 * @dev ordinal number system (e.g.: 01 -> 1st, 13 -> 13th)
 * @param n current day
 * @returns {string}
 */
export function ordinal(n: number | string): string {
  return `${+n}${
    ['st', 'nd', 'rd'][((((+n + 90) % 100) - 10) % 10) - 1] || 'th'
  }`
}

/**
 * @dev alternatively use day.js library, if necessary
 * @param {Date | string | number} date the locale date. Browsers may
 * @param {boolean} shortenMonth shorten the display of Month (i.e. January -> Jan if true)
 * @param {boolean} hideSecond hide the display time second (i.e. 10:03:35 PM -> 10:03 PM if true)
 * handle string date formats differently, best advise not to use them.
 * @returns {string} formated string output
 */
export function dateFormat(
  date: DateFormat,
  shortenMonth = true,
  hideSecond = false,
): string {
  const getTime = date instanceof Date ? date : new Date(date)
  const formatTime = `${getTime.toLocaleDateString('en-US', {
    day: '2-digit',
    year: 'numeric',
    month: `${shortenMonth ? 'short' : 'long'}`,
  })} ${getTime.toLocaleTimeString(
    'en-US',
    hideSecond
      ? {
          hour: '2-digit',
          minute: '2-digit',
        }
      : {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        },
  )}`

  // @dev split formatTime to get day, and remove comma
  const getDay = ordinal(formatTime.split(' ')[1].split(',')[0])
  const tempArr: string[] = formatTime.split(' ')
  tempArr[1] = getDay
  tempArr[2] += ','
  return tempArr.join(' ')
}
