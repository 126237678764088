import React, { ReactElement } from 'react'
import SettingIcon from '../../../assets/icons/setting-icon.svg'
import { useModal } from '../../../contexts/ModalContext'
import { ModalId } from '../../../interfaces/Modal'
import UserPreferenceModalContainer from '../UserPreferenceModalContainer/UserPreferenceModalContainer'
import { Container } from './UserPrefernceButtonContainer.elements'

function UserPrefernceButtonContainer(): ReactElement {
  const {
    modalState,
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const handleClickSetting = () => {
    modalDispatch(openModal(ModalId.USER_PREFERENCE))
  }
  return (
    <>
      <Container
        display="flex"
        flexDirection="row"
        alignContent="center"
        onClick={handleClickSetting}
      >
        <img src={SettingIcon} alt="User Preference" width={20} />
      </Container>
      {modalState.currentModalId === ModalId.USER_PREFERENCE && (
        <UserPreferenceModalContainer isOpen />
      )}
    </>
  )
}

export default UserPrefernceButtonContainer
