import { getSfFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { uniqueId } from 'lodash'
import React, { ReactElement } from 'react'
import { NftTypeId } from '../interfaces/nft'

/** @todo may move this file to another file */
const highlight = (strings: TemplateStringsArray, ...expressions: string[]) => {
  return (showActiveColor?: boolean): JSX.Element[] => {
    const styledJsxElements = expressions.reduce(
      (prev, expression, index) => {
        return [
          ...prev,
          <span
            key={uniqueId()}
            style={{ ...(showActiveColor && { color: '#00FFE6' }) }}
          >
            {expression}
          </span>,
          <span key={uniqueId()} style={{ opacity: 0.5 }}>
            {strings[index + 1]}
          </span>,
        ]
      },
      [
        <span key={uniqueId()} style={{ opacity: 0.5 }}>
          {strings[0]}
        </span>,
      ],
    )
    return styledJsxElements
  }
}

interface GetTextFnParams {
  value: string
  showActiveColor?: boolean
  showActiveText?: boolean
}
interface NftUIData {
  readonly name: string
  getAbilityText: ({
    value,
    showActiveColor,
    showActiveText,
  }: GetTextFnParams) => ReactElement
  getUnequipText: ({
    value,
    showActiveColor,
    showActiveText,
  }: GetTextFnParams) => ReactElement
}

const speedo: NftUIData = {
  name: NftTypeId.SPEEDO,
  getAbilityText({
    value,
    showActiveColor,
    showActiveText,
  }: GetTextFnParams): ReactElement {
    if (showActiveText) {
      return (
        <>
          {highlight`Your vePTP mining rate has been increased by ${value}%.`(
            showActiveColor,
          )}
        </>
      )
    }
    return (
      <>
        {highlight`Increase vePTP mining rate by ${value}%.`(showActiveColor)}
      </>
    )
  },
  getUnequipText({ value, showActiveColor }: GetTextFnParams): ReactElement {
    return (
      <>
        {highlight`The current ${this.name} effect that increases the vePTP mining rate by ${value}% will be gone.`(
          showActiveColor,
        )}
      </>
    )
  },
}

const pudgy: NftUIData = {
  name: NftTypeId.PUDGY,
  getAbilityText({
    value,
    showActiveColor,
    showActiveText,
  }: GetTextFnParams): ReactElement {
    if (showActiveText) {
      return (
        <>
          {highlight`Your max vePTP cap has been increased by ${value}%.`(
            showActiveColor,
          )}
        </>
      )
    }
    return (
      <>{highlight`Increase max vePTP cap by ${value}%.`(showActiveColor)}</>
    )
  },
  getUnequipText({ value, showActiveColor }: GetTextFnParams): ReactElement {
    return (
      <>
        {highlight`
        The ${this.name} effect that increases the max vePTP cap by ${value}% will be gone.`(
          showActiveColor,
        )}
      </>
    )
  },
}

const diligent: NftUIData = {
  name: NftTypeId.DILIGENT,
  getAbilityText({
    value,
    showActiveColor,
    showActiveText,
  }: GetTextFnParams): ReactElement {
    const formattedValue = getSfFormat(value, 4)
    if (showActiveText) {
      return (
        <>
          {highlight`Your vePTP mining rate has been increased by ${formattedValue} vePTP/hour.`(
            showActiveColor,
          )}
        </>
      )
    }
    return (
      <>
        {highlight`Increase vePTP mining rate by ${formattedValue} vePTP/hour.`(
          showActiveColor,
        )}
      </>
    )
  },
  getUnequipText({ value, showActiveColor }: GetTextFnParams): ReactElement {
    const formattedValue = getSfFormat(strToWad(value), 4)
    return (
      <>
        {highlight`The ${this.name} effect that increases the mining rate by ${formattedValue} vePTP/hour will be gone.`(
          showActiveColor,
        )}
      </>
    )
  },
}

const gifted: NftUIData = {
  name: NftTypeId.GIFTED,
  getAbilityText({
    value,
    showActiveColor,
    showActiveText,
  }: GetTextFnParams): ReactElement {
    if (showActiveText) {
      return (
        <>
          {highlight`You vePTP balance and max cap has been increased by ${value}`(
            showActiveColor,
          )}
        </>
      )
    }
    return (
      <>
        {highlight`Increase vePTP balance and max cap by ${value}.`(
          showActiveColor,
        )}
      </>
    )
  },
  getUnequipText({ value, showActiveColor }: GetTextFnParams): ReactElement {
    return (
      <>
        {highlight`The ${this.name} effect that increases your vePTP balance and max cap by ${value} will be gone.`(
          showActiveColor,
        )}
      </>
    )
  },
}

const hibernate: NftUIData = {
  name: NftTypeId.HIBERNATE,
  getAbilityText({
    value,
    showActiveColor,
    showActiveText,
  }: GetTextFnParams): ReactElement {
    if (showActiveText) {
      return (
        <>
          {highlight`Retain up to ${value}% of vePTP after partially unstaking PTP.`(
            showActiveColor,
          )}
        </>
      )
    }
    return (
      <>
        {highlight`Retain up to ${value}% of vePTP after partially unstaking PTP.`(
          showActiveColor,
        )}
      </>
    )
  },
  getUnequipText({ showActiveColor }: GetTextFnParams): ReactElement {
    return (
      <>
        {highlight`The Hibernate effect will be gone. All vePTP balance will be lost upon
        unstaking any amount of PTP.`(showActiveColor)}
      </>
    )
  },
}

const nftUIData: { [id in NftTypeId]: NftUIData } = {
  [NftTypeId.PUDGY]: pudgy,
  [NftTypeId.SPEEDO]: speedo,
  [NftTypeId.DILIGENT]: diligent,
  [NftTypeId.GIFTED]: gifted,
  [NftTypeId.HIBERNATE]: hibernate,
}

export default nftUIData
