import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { BigNumber } from 'ethers'
import { ActionId } from '../../config/action'
import { vePtp } from '../../config/contracts'
import { VEPTP_V3_ABI } from '../../config/contracts/wagmiAbis/VePtpV3'
import { useModal } from '../../contexts/ModalContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useVePtp } from '../../contexts/VePtpContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'
import { isUserDeniedTransaction } from '../../utils/contract'
import useRevertReason from '../useRevertReason'

interface Props {
  handleEquipNft: (nftId: string) => Promise<void>
}

const useEquipNft = (): Props => {
  const { chainId } = useWeb3()
  const getRevertReason = useRevertReason()
  const {
    modalDispatch,
    actions: { openModal, closeModal },
  } = useModal()
  const { showMessage } = useSnackbar()
  const { nft } = useVePtp()

  async function handleEquipNft(id: string) {
    let writeContractResult: WriteContractResult | undefined
    try {
      const vePtpAddress = vePtp.address[chainId]
      if (!vePtpAddress) return
      const config = await prepareWriteContract({
        address: vePtpAddress,
        abi: VEPTP_V3_ABI,
        functionName: 'stakeNft',
        args: [BigNumber.from(id)],
        chainId,
      })
      writeContractResult = await writeContract(config)
      if (writeContractResult) {
        const { hash, wait } = writeContractResult
        await wait()
        modalDispatch(
          openModal(ModalId.TRANSACTION_SUBMITTED, {
            transactionHashes: [hash],
          }),
        )
        nft.setEquippedId(id)
        showMessage(`Successfully equipped NFT #${id}.`)
      } else {
        showMessage('Transaction failed.', 'warning')
        modalDispatch(closeModal())
      }
    } catch (err) {
      if (!isUserDeniedTransaction(err)) {
        Sentry.setContext('contract_call', {
          name: 'equip_nft',
          id: id,
        })
        Sentry.captureException(err)
      }
      const reason = await getRevertReason(err, undefined, ActionId.EQUIP_NFT)
      showMessage(reason || 'Transaction failed.', 'warning')
      modalDispatch(closeModal())
    }
  }
  return { handleEquipNft }
}

export default useEquipNft
