import { alpha, Box } from '@mui/material'
import Icon from '@mui/material/Icon'
import styled, { css } from 'styled-components'

interface StyledIconProp {
  $isShrink?: boolean
}

interface StyledBoxProp {
  $isDimmed?: boolean
  $isExpanded?: boolean
}

export const Container = styled(Box)<StyledBoxProp>`
  ${({ theme, $isDimmed, $isExpanded }) => css`
    display: flex;
    flex-direction: column;
    background: ${alpha(theme.palette.primary[500], 0.2)};
    border: 1px solid ${alpha(theme.palette.primary[500], 0.2)};
    cursor: pointer;
    width: ${$isExpanded ? '150px' : 'fit-content'};
    margin-right: 6px;
    padding: 2px 8px;
    border-radius: 999px;

    ${$isExpanded &&
    css`
      margin-right: 6px;
      padding: 8px;
      border-radius: 10px;
      width: 150px;

      &:last-of-type {
        margin-right: 0;
      }
    `}

    ${$isDimmed &&
    css`
      opacity: 0.4;
      cursor: default;
    `}

    ${theme.breakpoints.down('md')} {
      margin-bottom: 10px;
      width: ${$isExpanded ? '49%' : 'fit-content'};
      margin-right: ${$isExpanded ? '0' : '6px'};
    }
  `}
`

export const StyledIcon = styled(Icon)<StyledIconProp>`
  ${({ $isShrink }) => css`
    display: flex;
    font-size: 24px;

    * {
      width: 100%;
      height: 100%;
    }

    ${$isShrink &&
    css`
      font-size: 16px;
    `}
  `}
`
