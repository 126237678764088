import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { Box, BoxProps } from '@mui/material'
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { PoolSymbol } from '../../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { PoolStakeStatusBar } from '../../../containers/PoolStakeLPContainers'
import { StatusType } from '../../../containers/PoolStakeLPContainers/PoolStakeStatusBar/PoolStakeStatusBar'
import { usePoolInput } from '../../../contexts/PoolInputContext'
import useBreakpoints from '../../../hooks/useBreakpoints'
import Slidable from '../../animations/Slidable/Slidable'
import AppTypography from '../../AppTypography/AppTypography'
import { AppButtonProps } from '../../buttons/AppButton/AppButton'
import TokenIcon from '../../TokenIcon/TokenIcon'
import {
  Container,
  Item,
  ItemList,
  ToggleDetailsButton,
  Wrapper,
} from './AuxiliaryPoolCard.elements'

interface ContextType {
  isDetailsShown: boolean
  setIsDetailsShown: React.Dispatch<React.SetStateAction<boolean>>
}
const AuxiliaryPoolCardContext = createContext<ContextType>({} as ContextType)
AuxiliaryPoolCardContext.displayName = 'AuxiliaryPoolCardContext'

const useAuxiliaryPoolCard = (): ContextType => {
  return useContext(AuxiliaryPoolCardContext)
}
interface Props {
  children?: React.ReactNode
  poolSymbol: PoolSymbol
}

function AuxiliaryPoolCard({ children, poolSymbol }: Props): ReactElement {
  const [isDetailsShown, setIsDetailsShown] = useState(false)
  const {
    actions: { updateSelectedPoolSymbol },
    selectedPoolSymbol,
  } = usePoolInput()
  return (
    <AuxiliaryPoolCardContext.Provider
      value={{ isDetailsShown, setIsDetailsShown }}
    >
      <Container
        {...(selectedPoolSymbol !== poolSymbol && {
          onClick: () => updateSelectedPoolSymbol(poolSymbol),
        })}
      >
        {children}
      </Container>
    </AuxiliaryPoolCardContext.Provider>
  )
}

export default AuxiliaryPoolCard

/** CardTitle */
interface CardTitleProps {
  icon: string
  title: string
}

function CardTitle({ icon, title }: CardTitleProps): ReactElement {
  return (
    <AppTypography
      variant="h6"
      centerContent
      justifyContent="center"
      style={{
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        width: '180px',
        // ensure the width is a fixed amount
        minWidth: '180px',
        // ensure the height is not changing during the animation
        height: '68px',
      }}
    >
      <TokenIcon iconPath={icon} margin="0 8px 0 0" size={36} />
      {title}
    </AppTypography>
  )
}

/** CardItemList */
interface CardItemListProps extends BoxProps {
  className?: string
  column?: boolean
}

function CardItemList({
  className,
  column,
  ...otherProps
}: CardItemListProps): ReactElement {
  const { isDetailsShown } = useAuxiliaryPoolCard()
  const prevWidth = useRef<number>(0)
  const cardItemListContainerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (cardItemListContainerRef.current) {
      prevWidth.current = cardItemListContainerRef.current?.offsetWidth
    }
  }, [isDetailsShown])
  // Place a spacer
  if (isDetailsShown) return <Box width={`${prevWidth.current}px`}></Box>
  return (
    <ItemList
      ref={cardItemListContainerRef}
      className={className}
      column={column}
      {...otherProps}
    ></ItemList>
  )
}

/** CardItem */
type CardItemDataType = { label: string; value: string | ReactElement }
interface CardItemProps {
  tokenSymbol: TokenSymbol
  data: {
    tvl: CardItemDataType
    apr: CardItemDataType
  }
  hasDeposit?: boolean
  poolStakeStatus?: { [key in StatusType]?: boolean }
}
function CardItem({
  tokenSymbol,
  data,
  hasDeposit,
  poolStakeStatus,
}: CardItemProps): ReactElement {
  return (
    <Item hasDeposit={hasDeposit}>
      <PoolStakeStatusBar responsive={false}>
        {poolStakeStatus?.['Please Stake'] && (
          <PoolStakeStatusBar.Status status="Please Stake" />
        )}
        {poolStakeStatus?.['Earning'] && (
          <PoolStakeStatusBar.Status status="Earning" />
        )}
        {poolStakeStatus?.['Booster+'] && (
          <PoolStakeStatusBar.Status status="Booster+" />
        )}
      </PoolStakeStatusBar>
      <TokenIcon tokenSymbol={tokenSymbol} size={36} margin="0 10px 0 0" />
      <Box display="flex" flexDirection="column">
        <AppTypography variant="subtitle2">{tokenSymbol}</AppTypography>
        <Box display="flex" flexDirection="row">
          <AppTypography variant="caption2" className="CardItem__text">
            <AppTypography variant="caption3" transparent whiteSpace="nowrap">
              {data.tvl.label}&nbsp;
            </AppTypography>
            {data.tvl.value}
          </AppTypography>
          <AppTypography variant="caption2" className="CardItem__text">
            <AppTypography variant="caption3" transparent whiteSpace="nowrap">
              {data.apr.label}&nbsp;
            </AppTypography>
            {data.apr.value}
          </AppTypography>
        </Box>
      </Box>
    </Item>
  )
}

/** CardToggleDetailsButton */
function CardToggleDetailsButton({
  ...otherProps
}: AppButtonProps): ReactElement {
  const { setIsDetailsShown, isDetailsShown } = useAuxiliaryPoolCard()
  const { isTabletSm } = useBreakpoints()
  return (
    <ToggleDetailsButton
      customVariant={isDetailsShown ? 'neutral' : 'secondary'}
      $active={isDetailsShown}
      {...otherProps}
      onClick={() => setIsDetailsShown((prev) => !prev)}
    >
      {isDetailsShown ? (
        <>
          {!isTabletSm && <>Close&nbsp;</>}
          <CloseIcon className="ToggleDetailsButton__close-icon" />
        </>
      ) : (
        <>
          {!isTabletSm && <>Details&nbsp;</>}
          <KeyboardArrowDownRoundedIcon className="ToggleDetailsButton__arrow-icon" />
        </>
      )}
    </ToggleDetailsButton>
  )
}

/** CardDetails */
interface CardDetailsProps {
  children: React.ReactNode
}
function CardDetails({ children }: CardDetailsProps): ReactElement {
  const { isDetailsShown } = useAuxiliaryPoolCard()
  if (!isDetailsShown) return <></>
  return (
    <Slidable active={isDetailsShown}>
      <Box padding="28px 12px 0">{children}</Box>
    </Slidable>
  )
}

/** CardWrapper */
function CardWrapper({ children, ...otherProps }: BoxProps): ReactElement {
  return <Wrapper {...otherProps}>{children}</Wrapper>
}

AuxiliaryPoolCard.Item = React.memo(CardItem)
AuxiliaryPoolCard.Title = React.memo(CardTitle)
AuxiliaryPoolCard.ItemList = React.memo(CardItemList)
AuxiliaryPoolCard.ToggleDetailsButton = React.memo(CardToggleDetailsButton)
AuxiliaryPoolCard.Details = React.memo(CardDetails)
AuxiliaryPoolCard.Wrapper = React.memo(CardWrapper)
