import cloneDeep from 'lodash.clonedeep'
import { useState } from 'react'
import { mapLpAddressToPoolSymbolTokenSymbol } from '../../config/contracts/lpToken'
import { mapAddressToTokenSymbol } from '../../config/contracts/token'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { ChainId } from '../../config/networks'
import { MEDIAN_BOOSTED_APR_API } from '../../constants'
import { EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_STR } from '../../constants/empty'
import { useNetwork } from '../../contexts/NetworkContext'
import { PoolSymbolTokenSymbolTokenSymbolStringType } from '../../interfaces/common'
import { usePoller } from '../usePoller'

interface MedianBoostedAprApiReturnType {
  // lpAddress: {rewardTokenAddress: number}
  [key: string]: { [key: string]: number }
}

function useMedianBoostedApr(): PoolSymbolTokenSymbolTokenSymbolStringType {
  const [medianAprs, setMedianAprs] =
    useState<PoolSymbolTokenSymbolTokenSymbolStringType>(
      cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_STR),
    )
  const { chainId } = useNetwork()

  usePoller(
    () => {
      const updateMedianAprs = async () => {
        try {
          const response = await fetch(MEDIAN_BOOSTED_APR_API, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          // some value is NaN in s3 function return
          const data = JSON.parse(
            (await response.text()).replaceAll('NaN', '0.0'),
          ) as MedianBoostedAprApiReturnType
          const newApr = cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_STR)
          for (const lpAddress in data) {
            const availableLpData =
              mapLpAddressToPoolSymbolTokenSymbol()[lpAddress.toLowerCase()]

            if (availableLpData) {
              const poolSymbol = availableLpData.poolSymbol
              const assetTokenSymbol = availableLpData.tokenSymbol
              const rewardTokenAddresses = data[lpAddress]
              for (const rewardTokenAddress in rewardTokenAddresses) {
                // the api is only for mainnet
                let rewardTokenSymbol =
                  mapAddressToTokenSymbol[ChainId.AVALANCHE][
                    rewardTokenAddress.toLowerCase()
                  ]
                // avax address is 0x0000000000000000000000000000000000000000
                if (
                  rewardTokenAddress ===
                  '0x0000000000000000000000000000000000000000'
                ) {
                  rewardTokenSymbol = TokenSymbol.AVAX
                }

                newApr[poolSymbol] = {
                  ...newApr[poolSymbol],
                  [assetTokenSymbol]: {
                    ...newApr[poolSymbol][assetTokenSymbol],
                    [rewardTokenSymbol]:
                      data[lpAddress][rewardTokenAddress].toString(),
                  },
                }
              }
            }
          }

          setMedianAprs(newApr)
        } catch (err) {
          console.error(err)
        }
      }
      void updateMedianAprs()
    },
    [chainId],
    300000, // 5 minutes
  )

  return medianAprs
}

export default useMedianBoostedApr
