import {
  getDpFormat,
  isParsableString,
  safeWdiv,
  sqrt,
  strToWad,
  WAD,
  wmul,
} from '@hailstonelabs/big-number-utils'
import { BigNumber, constants, utils } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { TOKENS } from '../config/contracts/token'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import { WAD_DECIMALS } from '../constants'
import { lptokenBNToToken } from './pool'

/**
 * @param {BigNumber} tokenPerSecondWad Token emission per second, in 18 d.p.
 * @param {BigNumber} poolAllocPointBN allocation points assigned to this pool
 * @param {BigNumber} totalAllocPointBN  total allocation points to all pool
 * @param {BigNumber} repartitionBN repartition for dialuting or non dialuting emission e.g. 300 means 30%
 * @param {number} numberOfDays Total token mission in numberOfDays
 * @returns {BigNumber}  Token reward of this pool in WAD, defualt annually
 */
export function getTokenEmissionWad(
  tokenPerSecondWad: BigNumber,
  poolAllocPointBN: BigNumber,
  totalAllocPointBN: BigNumber,
  repartitionBN: BigNumber,
  numberOfDays = 365,
): BigNumber {
  if (totalAllocPointBN.isZero()) {
    return constants.Zero
  }
  const totalTokenEmissionWad = tokenPerSecondWad
    .mul(60)
    .mul(60)
    .mul(24)
    .mul(numberOfDays)
  const poolTokenEmissionWad = totalTokenEmissionWad
    .mul(poolAllocPointBN)
    .div(totalAllocPointBN)
  const poolTokenEmissionAfterRepartitionWad = poolTokenEmissionWad
    .mul(repartitionBN)
    .div('1000')
  return poolTokenEmissionAfterRepartitionWad
}

/**
 * @param {BigNumber} lpAmount in native LP d.p.
 * @param {BigNumber} vePtpBalance in native vePTP d.p.
 * @returns {BigNumber} userfactor native dp same as the MasterPlatypus
 */
export function getUserFactor(
  lpAmount: BigNumber,
  vePtpBalance: BigNumber,
): BigNumber {
  // From SC: user.factor = Math.sqrt(user.amount * vePtp.balanceOf(msg.sender));
  // sqrt(lpAmount (native dp) * vePtpBalance (native dp))
  return sqrt(lpAmount.mul(vePtpBalance))
}

/**
 * Use this function to get the Base APR (passing the dialuting repartition),
 * or the average Boosted APR (passing the non-dialuting repartition),
 * @param {BigNumber} tokenPerSecondWad token emission per second, in WAD (18 d.p)
 * @param {BigNumber} poolAllocPointBN allocation points assigned to this pool, in BigNumber Int
 * @param {BigNumber} totalAllocPointBN  total allocation points to all pool, in BigNumber Int
 * @param {BigNumber} repartitionBN repartition for dialuting or non dialuting emission e.g. 300 means 30%
 * @param {BigNumber} poolTvlUsdWad pool TVL in USD, in WAD
 * @param {string} tokenPrice PTP price in USD, in WAD
 * @returns {BigNumber} Pool base APR % in WAD
 */
export function getEmissionAprPercent(
  tokenPerSecondWad: BigNumber,
  poolAllocPointBN: BigNumber,
  totalAllocPointBN: BigNumber,
  repartitionBN: BigNumber,
  poolTvlUsdWad: BigNumber,
  tokenPrice: string,
): BigNumber {
  // use 18 d.p because tokenPerSecond is in WAD
  if (!isParsableString(tokenPrice, WAD_DECIMALS, true)) {
    return BigNumber.from(0)
  }
  if (poolTvlUsdWad.isZero()) {
    return constants.Zero
  }

  const tokenPriceWad = strToWad(tokenPrice)
  const tokenAnnualReward = getTokenEmissionWad(
    tokenPerSecondWad,
    poolAllocPointBN,
    totalAllocPointBN,
    repartitionBN,
  )

  const annualRewardUsdWad = wmul(tokenPriceWad, tokenAnnualReward)
  const apr = safeWdiv(annualRewardUsdWad, poolTvlUsdWad)
  const aprPercent = apr.mul(100)
  return aprPercent
}

/**
 * Get the exact Boosted APR% of an LP, need to know his/her provided LP token amount, and vePTP balance
 * @param {BigNumber} rewardPerSecondWad reward token emission per second, in WAD
 * @param {BigNumber} poolAllocPoint allocation points assigned to this pool, in BigNumber Int
 * @param {BigNumber} totalAllocPoint  total allocation points to all pool, in BigNumber Int
 * @param {BigNumber} nonDialutingPoolRepartition repartition for non dialuting emission, in BigNumber Int. e.g. 300 means 30%
 * @param {BigNumber} userTvlUsd user TVL in USD, in WAD
 * @param {string} rewardTokenPrice reward token price in USD, in WAD
 * @param {BigNumber} userFactor MasterPlatypus userInfo's factors
 * @param {BigNumber} poolSumOfFactors MasterPlatypus poolInfo's sumOfFactors
 *
 * @returns {BigNumber} exact Boosted APR% of an LP, in WAD
 */
export function getExactBoostedAprPercent(
  rewardPerSecondWad: BigNumber,
  poolAllocPoint: BigNumber,
  totalAllocPoint: BigNumber,
  nonDialutingPoolRepartition: BigNumber,
  userTvlUsd: BigNumber,
  rewardTokenPrice: string,
  userFactor: BigNumber, // from MasterPlatypus userInfo's factors
  poolSumOfFactors: BigNumber, // from MasterPlatypus poolInfo's sumOfFactors
): BigNumber {
  // check if it's parsable to WAD because the token price will be converted to WAD
  if (!isParsableString(rewardTokenPrice, WAD_DECIMALS, true)) {
    return BigNumber.from(0)
  }
  if (userTvlUsd.isZero() || poolSumOfFactors.isZero()) {
    return constants.Zero
  }
  const rewardTokenPriceWad = strToWad(rewardTokenPrice)
  const rewardTokenAnnualReward = getTokenEmissionWad(
    rewardPerSecondWad,
    poolAllocPoint,
    totalAllocPoint,
    nonDialutingPoolRepartition,
  )

  const poolAnnualRewardUsdWad = wmul(
    rewardTokenPriceWad,
    rewardTokenAnnualReward,
  )
  const userAnnualRewardUsdWad = poolAnnualRewardUsdWad
    .mul(userFactor)
    .div(poolSumOfFactors)

  const apr = safeWdiv(userAnnualRewardUsdWad, userTvlUsd)
  const aprPercent = apr.mul(100)
  return aprPercent
}

/**
 *
 * @param {BigNumber} volumeWad in terms of WAD
 * @param {BigNumber} reserveUsdWad in terms of WAD
 * @param {BigNumber} haircutRateWad in terms of WAD
 * @returns {BigNumber} aprPercent in terms of WAD
 */
export const getHaircutFeeAprPercent = (
  volumeWad: BigNumber,
  reserveUsdWad: BigNumber,
  haircutRateWad = strToWad('0.0025'),
): BigNumber => {
  const dailyProfitWad = wmul(volumeWad, haircutRateWad)
  const yearlyProfitWad = dailyProfitWad.mul(BigNumber.from('365'))
  const apr = safeWdiv(yearlyProfitWad, reserveUsdWad)
  const aprPercent = apr.mul(100)
  return aprPercent
}

export const getEstimatedBoostedAprPercent = (
  vePtpBalance: string,
  lpAmount: string,
  tokenSymbol: TokenSymbol,
  lpSupplyBN: BigNumber,
  liabilityBN: BigNumber,
  poolSumOfFactorsBN: BigNumber,
  rewardPerSecBN: BigNumber,
  poolAdjustedAllocPointBN: BigNumber,
  totalAdjustedAllocPointBN: BigNumber,
  nonDialutingRepartitionBN: BigNumber,
  tokenPriceWAD: BigNumber,
  rewardTokenPrice: string,
  poolFactorMultiplier = WAD, // in WAD
): string => {
  let userTvlWAD = constants.Zero
  let lpAmountBN = constants.Zero
  const lpAmountTrimmed = getDpFormat(lpAmount, TOKENS[tokenSymbol].decimals)
  if (
    isParsableString(lpAmountTrimmed || '0', TOKENS[tokenSymbol].decimals, true)
  ) {
    userTvlWAD = strToWad(
      lptokenBNToToken(
        utils.parseUnits(lpAmountTrimmed || '0', TOKENS[tokenSymbol].decimals),
        lpSupplyBN,
        liabilityBN,
        tokenSymbol,
      ),
    )
    lpAmountBN = utils.parseUnits(
      lpAmountTrimmed || '0',
      TOKENS[tokenSymbol].decimals,
    )
  }
  const userTvlUsd = wmul(userTvlWAD, tokenPriceWAD)
  const vePtpBalanceBN = utils.parseUnits(
    vePtpBalance || '0',
    TOKENS.vePTP.decimals,
  )
  const userFactor = getUserFactor(lpAmountBN, vePtpBalanceBN)
  const poolFactor = poolSumOfFactorsBN
  // multiply PoolFactor by a multiplier to simulate the effect of different vePTP supply or pool TVL
  const estimatedPoolFactor = poolFactor.mul(poolFactorMultiplier).div(WAD)
  const aprWad = getExactBoostedAprPercent(
    rewardPerSecBN,
    poolAdjustedAllocPointBN,
    totalAdjustedAllocPointBN,
    nonDialutingRepartitionBN,
    userTvlUsd,
    rewardTokenPrice,
    userFactor,
    estimatedPoolFactor,
  )
  return formatEther(aprWad)
}
