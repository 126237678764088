import * as multicall from 'ethcall'
import { Contract, ContractInterface, ethers, utils } from 'ethers'
import { Fragment } from 'ethers/lib/utils'
import MoralisType from 'moralis'
import { CURRENT_TIMESTAMP_API } from '../constants'
import { MetaMaskError, WalletConnectError } from '../interfaces/Error'

/**
 *
 * @param {string} willingWaitingSecond
 * @returns {string} timestamp(now + willingWaitingSecond) in second
 */
export const getDeadline = async (
  willingWaitingSecond: string,
): Promise<string> => {
  const getDeadlineTimestamp = (timestampInSecond: number) =>
    (timestampInSecond + Number(willingWaitingSecond)).toFixed(0)

  try {
    const response = await fetch(CURRENT_TIMESTAMP_API)
    if (response.ok) {
      const timestampInSecond = (await response.json()) as number
      return getDeadlineTimestamp(timestampInSecond)
    } else {
      console.warn(
        'Timestamp api is currently not available. It is now using system time instead.',
      )
      return getDeadlineTimestamp(new Date().getTime() / 1000)
    }
  } catch (err) {
    return getDeadlineTimestamp(new Date().getTime() / 1000)
  }
}

/**
 *
 * @param {unknown} err error related to user call contract transaction
 * @returns {boolean} user is denied transaction or not
 */
export const isUserDeniedTransaction = (err: unknown): boolean => {
  if (
    (err as MetaMaskError).message ===
      'MetaMask Tx Signature: User denied transaction signature.' ||
    (err as MetaMaskError).message === 'User rejected the transaction.' ||
    (err as MetaMaskError).code === 4001 ||
    // WallectConnect: Metamask
    (err as WalletConnectError).message === 'User rejected the transaction' ||
    // WallectConnect: Trust wallet
    (err as WalletConnectError).message === 'User canceled'
  ) {
    return true
  } else {
    return false
  }
}

/**
 * @param {ethers.providers.Web3Provider | undefined} provider Web3Provider from useWeb3React
 * @param {string} address contract address
 * @param {ContractInterface} abi contract ABI
 * @param {boolean} readOnly contract has read-only access (i.e. constant calls)
 * @returns {Contract | null} Contract instance or null
 */
export const getContract = (
  address: string,
  abi: ContractInterface,
  readOnly: boolean,
  provider:
    | MoralisType.MoralisWeb3Provider
    | ethers.providers.JsonRpcProvider
    | null,
  isMulticall = false,
): Contract | null | multicall.Contract => {
  // check address valid or not
  if (!utils.isAddress(address)) return null
  if (isMulticall) {
    return new multicall.Contract(address, abi as Fragment[])
  }
  if (!readOnly) {
    // if readOnly is not true, then get writeable contract
    //  check library valid or not
    if (!provider) return null
    let signer: ethers.providers.JsonRpcSigner | null = null
    signer = provider.getSigner() as ethers.providers.JsonRpcSigner
    if (signer) {
      const contractInstance = new Contract(address, abi, signer)
      const connectedContract = contractInstance.connect(signer)
      return connectedContract
    } else {
      return null
    }
  } else {
    const contractInstance = new Contract(
      address,
      abi,
      provider as ethers.providers.JsonRpcProvider,
    )
    return contractInstance
  }
}

export const waitForTransaction = async (
  provider: ethers.providers.JsonRpcProvider,
  hash: string,
): Promise<void> => {
  const transactionReceipt = await provider.waitForTransaction(hash, 1)
  console.debug(transactionReceipt)
  if (transactionReceipt.status === 0) {
    throw new Error('Transaction reverted.')
  }
}
