import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import { Box } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import routes from '../../../config/routes'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useBenefitApr } from '../../../hooks/vePtp/useBenefitApr'
import { ModalId } from '../../../interfaces/Modal'
import VePtpBenefitAprTooltip from './VePtpBenefitAprTooltip'
import { VePtpBenefitCard } from './VePtpBenefitCard'

export const VePtpBenefitContainer = () => {
  const { isTabletSm } = useBreakpoints()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const navigate = useNavigate()
  const { input } = useVePtp()
  const { votingApr, total } = useBenefitApr()

  const vePtpBenefitData = {
    BOOST_APR: {
      id: '1',
      label: 'Boost APR',
      description: 'Use vePTP to boost your pool APR',
      topIcon: <CalculateOutlinedIcon />,
      isActive: true,
      aprValue: null,
      tooltip: null,
      action: () => modalDispatch(openModal(ModalId.VEPTP_BOOSTER_CALCULATOR)),
    },
    GAUGE_VOTING: {
      id: '2',
      label: 'Gauge Voting',
      description: 'Voting bribe rewards',
      topIcon: <ChevronRightIcon />,
      isActive: true,
      aprValue: votingApr,
      tooltip: null,
      action: () => navigate(routes.GAUGE_VOTING.path),
    },
    AIRDROPS: {
      id: '3',
      label: 'Airdrops',
      description: 'Official and third party campaign',
      topIcon: <ChevronRightIcon />,
      isActive: true,
      aprValue: total.totalAirdropAprWad,
      tooltip: <VePtpBenefitAprTooltip mode="airdrop" />,
      action: () => modalDispatch(openModal(ModalId.SELECT_VEPTP_AIRDROP)),
    },
    FEE_SHARING: {
      id: '4',
      label: 'Fee Sharing',
      description: 'Coming Soon...',
      topIcon: <MonetizationOnOutlinedIcon />,
      isActive: false,
      aprValue: null,
      tooltip: null,
      action: () => {
        {
          /**@todo add fee sharing feature*/
        }
      },
    },
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={
        input.isBenefitSectionCollapsed || !isTabletSm
          ? 'center'
          : 'space-between'
      }
      flexWrap={isTabletSm ? 'wrap' : 'nowrap'}
    >
      {Object.values(vePtpBenefitData).map((data) => {
        return (
          <VePtpBenefitCard
            key={data.id}
            label={data.label}
            description={data.description}
            topIcon={data.topIcon}
            isActive={data.isActive}
            aprValue={data.aprValue}
            tooltip={data.tooltip}
            action={data.action}
          />
        )
      })}
    </Box>
  )
}
