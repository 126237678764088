import React, { ReactElement } from 'react'
import { StakeLpDataProvider } from './StakeLpDataContext'
import VePtpProvider from './VePtpContext'
import { VotingDataProvider } from './VotingDataContext'

interface Props {
  children: React.ReactNode
}

export const GeneralContextProvider = ({ children }: Props): ReactElement => {
  //Router Location is accessible in below context provider:
  return (
    <VePtpProvider>
      <VotingDataProvider>
        <StakeLpDataProvider>{children}</StakeLpDataProvider>
      </VotingDataProvider>
    </VePtpProvider>
  )
}
