import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { BoxProps } from '@mui/material'
import React, { ReactElement } from 'react'
import { Container } from './AccordionButton.elements'
interface Props extends BoxProps {
  active?: boolean
  onClick?: () => void
}

function AccordionButton({
  active,
  onClick,
  ...otherProps
}: Props): ReactElement {
  return (
    <Container onClick={onClick} active={active} {...otherProps}>
      {active ? <CloseIcon /> : <KeyboardArrowDownRoundedIcon />}
    </Container>
  )
}

export default AccordionButton
