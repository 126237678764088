import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { BigNumber, utils } from 'ethers'
import { useState } from 'react'
import { TOKENS, vePtp } from '../../../config/contracts'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { VEPTP_V3_ABI } from '../../../config/contracts/wagmiAbis/VePtpV3'
import { useModal } from '../../../contexts/ModalContext'
import { useSnackbar } from '../../../contexts/SnackbarContext'
import { useVePtpInput } from '../../../contexts/VePtpInputContext'
import { useWeb3 } from '../../../contexts/Web3Context'
import { ModalId } from '../../../interfaces/Modal'
import { isUserDeniedTransaction } from '../../../utils/contract'
import useRevertReason from '../../useRevertReason'

type ReturnType = {
  handleLockPtp: (ptpAmount: string, lockDays?: string) => Promise<void>
  isWaiting: boolean
}

/**
 * This hook can let users to lock PTP or lock more PTP
 */
/** @todo refactor this code after merging main-usp branch as some codes are in main-usp branch */
function useLockPtp(): ReturnType {
  const { chainId } = useWeb3()
  const getRevertReason = useRevertReason()
  const { showMessage } = useSnackbar()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const [isWaiting, setIsWaiting] = useState(false)
  const {
    actions: { resetLockDayAndLockPtpAmount },
  } = useVePtpInput()
  /**
   * If lockDays is not provided, it means users try to add more PTP to the existing lock.
   */
  const handleLockPtp = async (ptpAmount: string, lockDays?: string) => {
    let writeContractResult: WriteContractResult | undefined

    setIsWaiting(true)
    try {
      const vePtpAddress = vePtp.getAddress(chainId)
      if (vePtpAddress) {
        const ptpAmountBn = utils.parseUnits(
          ptpAmount,
          TOKENS[TokenSymbol.PTP].decimals,
        )
        modalDispatch(openModal(ModalId.LOCK_PTP_WAIT_FOR_CONFIRMATION))
        if (lockDays) {
          // First time locking
          const config = await prepareWriteContract({
            address: vePtpAddress,
            abi: VEPTP_V3_ABI,
            functionName: 'lockPtp',
            args: [ptpAmountBn, BigNumber.from(lockDays)],
            chainId,
          })
          writeContractResult = await writeContract(config)
        } else {
          // No lock day means users try to add more PTP to the lock
          const config = await prepareWriteContract({
            address: vePtpAddress,
            abi: VEPTP_V3_ABI,
            functionName: 'addPtpToLock',
            args: [ptpAmountBn],
            chainId,
          })
          writeContractResult = await writeContract(config)
        }

        if (writeContractResult) {
          const { hash, wait } = writeContractResult
          await wait()
          modalDispatch(
            openModal(ModalId.TRANSACTION_SUBMITTED, {
              transactionHashes: [hash],
            }),
          )
          showMessage('Successfully Lock PTP.')
        } else {
          showMessage('Transaction failed.', 'warning')
          modalDispatch(openModal(ModalId.UNSET))
        }
      }
    } catch (err) {
      if (!isUserDeniedTransaction(err)) {
        Sentry.setContext('contract_call', {
          name: 'lock_ptp',
          amount: ptpAmount,
          lockDays,
        })
        Sentry.captureException(err)
      }
      const reason = await getRevertReason(err)
      showMessage(reason || 'Transaction failed.', 'warning')
      modalDispatch(openModal(ModalId.UNSET))
    }
    setIsWaiting(false)
    resetLockDayAndLockPtpAmount()
  }
  return { handleLockPtp, isWaiting }
}

export default useLockPtp
