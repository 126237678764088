import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'

export const Route = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid ${alpha(theme.palette.common.white, 0.2)};
    border-radius: ${theme.borderRadius.sm};
    padding: 12px;
    margin-top: 4px;
    flex-wrap: wrap;
  `}
`

export const RouteItem = styled(Box)`
  ${() => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1px 0;
    &:last-of-type {
      .SwapInfoBoxContainer__route-arrow {
        display: none;
      }
    }
  `}
`
