import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import { constants, utils } from 'ethers'
import React from 'react'
import ReactGA from 'react-ga'
import styled, { css } from 'styled-components'
import {
  EMERGENCY_WITHDRAWS,
  LP_TOKENS,
  POOLS,
} from '../../../config/contracts'
import {
  MasterPlatypusId,
  MASTER_PLATYPUS,
} from '../../../config/contracts/masterPlatypus'
import { PoolSymbol } from '../../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useMigrationDetection } from '../../../contexts/MigrationDetectionContext'
import useEmergencyWithdraw from '../../../hooks/migration/useEmergencyWithdraw'
import useUnstakeLp from '../../../hooks/pool/useUnstakeLp'
import useApprove from '../../../hooks/useApprove'
import { ApproveSpender } from '../../../interfaces/spender'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import AppTypography from '../../AppTypography/AppTypography'
import AppButton from '../../buttons/AppButton/AppButton'
import TooltipNum from '../../InfoBox/TooltipNum'
import TokenIcon from '../../TokenIcon/TokenIcon'
import PoolCard from '../PoolCard/PoolCard'
import {
  FixedWidthBox,
  Info,
  InfoButtonList,
  InfoItem,
  InfoItemList,
  InfoText,
  InfoToken,
} from '../PoolCard/PoolCard.elements'

/**
 * Styles
 */
const StyledInfoButtonList = styled(InfoButtonList)`
  ${({ theme }) => css`
    @media only screen and (min-width: ${theme.breakpoints.values.lg}px) {
      width: 350px;
    }
  `}
`

/**
 * DelistedPoolCard
 */

interface DelistedPoolCardProps {
  poolSymbol: PoolSymbol
  assetTokenSymbol: TokenSymbol
  masterPlatypusId: MasterPlatypusId
  withdrawButtonLabel?: string
}
export function DelistedPoolCard({
  poolSymbol,
  assetTokenSymbol,
  withdrawButtonLabel,
  masterPlatypusId,
}: DelistedPoolCardProps): React.ReactElement {
  const { handleEmergencyWithdraw } = useEmergencyWithdraw(
    poolSymbol,
    assetTokenSymbol,
  )
  const { handleUnstakeLp } = useUnstakeLp()
  const { updateAllDelistedData } = useMigrationDetection()
  const delistedData =
    useMigrationDetection().delistedData[poolSymbol]?.[assetTokenSymbol]
  const { handleClickApprove, isApproved, isApproving } = useApprove(
    assetTokenSymbol,
    true,
    ApproveSpender.EmergencyWithdraw,
    'all',
    poolSymbol,
  )
  const isUsdcBalanceZero = delistedData?.usdcAssetBalanceOfUsdc
    ? strToWad(delistedData.usdcAssetBalanceOfUsdc).eq(constants.Zero)
    : true
  const emergencyWithdraw = EMERGENCY_WITHDRAWS[poolSymbol]?.[assetTokenSymbol]
  if (!delistedData || !emergencyWithdraw) return <></>
  const hasZeroStaked = strToWad(
    delistedData.lpAmountInTermsOfLp.staked,
  ).isZero()
  const hasZeroStakable = strToWad(
    delistedData.lpAmountInTermsOfLp.stakable,
  ).isZero()
  const hasZeroDeposits = strToWad(
    delistedData.lpAmountInTermsOfLp.total,
  ).isZero()
  if (hasZeroDeposits) return <></>
  const handleClickUnstakeLp = async () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: `adhoc-${poolSymbol}-pool-${assetTokenSymbol}-unstake`,
    })
    if (
      delistedData.lpAmountInTermsOfLp.staked &&
      LP_TOKENS[poolSymbol][assetTokenSymbol]
    ) {
      await handleUnstakeLp(
        assetTokenSymbol,
        poolSymbol,
        MASTER_PLATYPUS[masterPlatypusId],
        utils.parseUnits(
          delistedData.lpAmountInTermsOfLp.staked,
          LP_TOKENS[poolSymbol][assetTokenSymbol]?.decimals,
        ),
      )
      void updateAllDelistedData()
    }
  }
  const handleClickWithdrawUsdcInFactoryUsdcMimatic = async () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: `adhoc-withdraw-${poolSymbol}-pool-${assetTokenSymbol}-in-usdc`,
    })
    await handleEmergencyWithdraw(TokenSymbol.USDC)
    void updateAllDelistedData()
  }
  const handleClickWithdrawMaiInFactoryUsdcMimatic = async () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: `adhoc-withdraw-${poolSymbol}-pool-${assetTokenSymbol}-in-mai`,
    })
    await handleEmergencyWithdraw(TokenSymbol.MIMATIC)
    void updateAllDelistedData()
  }
  const handleClickWithdraw = async () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: `adhoc-withdraw-${poolSymbol}-pool-${assetTokenSymbol}`,
    })
    await handleEmergencyWithdraw(emergencyWithdraw.toTokenSymbol)
    void updateAllDelistedData()
  }

  return (
    <PoolCard
      hasDeposit
      poolSymbol={poolSymbol}
      assetTokenSymbol={assetTokenSymbol}
    >
      <Info>
        <InfoItem>
          {/* Token symbol and icon */}
          <InfoToken>
            <FixedWidthBox>
              <TokenIcon
                tokenSymbol={assetTokenSymbol}
                // override img's styles in InfoItem component
                style={{ width: '50px', height: '50px' }}
              />
            </FixedWidthBox>
            <Box display="flex" alignItems="flex-start" flexDirection="column">
              <AppTypography variant="caption" transparent>
                {POOLS[poolSymbol].name}
              </AppTypography>
              <AppTypography variant="h6">{assetTokenSymbol}</AppTypography>
            </Box>
          </InfoToken>
        </InfoItem>
        <InfoItemList>
          {/* My Deposits */}
          {delistedData.lpAmountInTermsOfLp.total && (
            <InfoItem>
              <InfoText $light>My Deposits</InfoText>
              <InfoText variant="subtitle2">
                <TooltipNum
                  amount={delistedData.lpAmountInTermsOfLp.total || '0.0'}
                  rightSymbol={assetTokenSymbol as string}
                >
                  {showDashIfNecessary(
                    delistedData.lpAmountInTermsOfLp.total === undefined,
                    getCommifiedFormat(delistedData.lpAmountInTermsOfLp.total),
                  )}
                  &nbsp;
                  {assetTokenSymbol}
                </TooltipNum>
              </InfoText>
            </InfoItem>
          )}
        </InfoItemList>
        <StyledInfoButtonList>
          <AppButton
            customVariant="neutral"
            disabled={hasZeroStaked || hasZeroDeposits}
            onClick={() => void handleClickUnstakeLp()}
          >
            Unstake
          </AppButton>
          {isApproved ? (
            poolSymbol === PoolSymbol.FACTORY_USDC_MIMATIC &&
            assetTokenSymbol === TokenSymbol.USDC ? (
              <>
                {!isUsdcBalanceZero && (
                  <AppButton
                    customVariant="neutral"
                    onClick={() =>
                      void handleClickWithdrawUsdcInFactoryUsdcMimatic()
                    }
                    disabled={!isApproved || hasZeroStakable}
                  >
                    Withdraw in USDC
                  </AppButton>
                )}
                <AppButton
                  customVariant="neutral"
                  onClick={() =>
                    void handleClickWithdrawMaiInFactoryUsdcMimatic()
                  }
                  disabled={!isApproved || hasZeroStakable}
                >
                  Withdraw in {TokenSymbol.MIMATIC}
                </AppButton>
              </>
            ) : (
              <AppButton
                customVariant="neutral"
                onClick={() => void handleClickWithdraw()}
                disabled={!isApproved || hasZeroStakable}
              >
                {withdrawButtonLabel ? withdrawButtonLabel : 'Withdraw'}
              </AppButton>
            )
          ) : (
            <AppButton
              customVariant="neutral"
              onClick={() => {
                ReactGA.event({
                  category: 'button',
                  action: 'click',
                  label: `adhoc-approve-withdraw-${poolSymbol}-pool-${assetTokenSymbol}`,
                })
                void handleClickApprove()
              }}
              disabled={hasZeroStakable}
            >
              {isApproving ? <>Approving</> : <>Approve withdrawal</>}
            </AppButton>
          )}
        </StyledInfoButtonList>
      </Info>
    </PoolCard>
  )
}
