import {
  getCommifiedFormat,
  getDpFormat,
  getMillifiedFormat,
  strToWad,
  wmul,
} from '@hailstonelabs/big-number-utils'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Typography } from '@mui/material'
import React, { ReactElement, useMemo, useState } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import TransparentButton from '../../../components/buttons/TransparentButton/TransparentButton'
import DynamicWalletIcon from '../../../components/DynamicWalletIcon/DynamicWalletIcon'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import Sign from '../../../components/Sign/Sign'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import { TOKENS } from '../../../config/contracts'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { WALLETS } from '../../../config/wallet'
import { useBalance } from '../../../contexts/BalanceContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import useBreakpoints from '../../../hooks/useBreakpoints'
import useCopyAddress from '../../../hooks/useCopyAddress'
import useAddTokenToWallet from '../../../hooks/wallet/useAddTokenToWallet'
import useWalletConnection from '../../../hooks/wallet/useWalletConnection'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import TpYieldInfoContainer from '../TpYieldInfoContainer/TpYieldInfoContainer'
import {
  Container,
  PtpInfoContainer,
  PtpInfoItem,
  StyledAppButton,
} from './PoolPtpInfoContainer.elements'
import PtpSelectTradeModal from './PtpSelectTradeModal'

function PoolPtpInfoContainer(): ReactElement {
  return (
    <Container>
      <PtpInfo />
      <TpYieldInfoContainer />
    </Container>
  )
}
export default PoolPtpInfoContainer
function PtpInfo(): ReactElement {
  const { isMobileLg } = useBreakpoints()
  const [isTradeModalOpen, setIsTradeModalOpen] = useState(false)
  const { tokenPrices24hChange, tokenPrices, isTokenPriceFetched } =
    useBalance()
  const { ptp } = useVePtp()
  const isPositivePricePercentChanged = useMemo(() => {
    return +tokenPrices24hChange.PTP >= 0
  }, [tokenPrices24hChange])
  const { chainId } = useNetwork()
  const ptpAddress = TOKENS[TokenSymbol.PTP].address[chainId]
  const addTokenToWallet = useAddTokenToWallet()
  const { connectedWalletId } = useWalletConnection()
  const copyAddress = useCopyAddress()
  const ptpMarketCap = wmul(
    strToWad(tokenPrices.PTP),
    strToWad(ptp.total.circulatingSupply),
  )
  return (
    <>
      <PtpInfoContainer>
        {/* PTP token price */}
        <PtpInfoItem>
          <TokenIcon
            tokenSymbol={TokenSymbol.PTP}
            size={48}
            margin="0 10px 0 0"
          />
          <Box>
            <Box display="flex" alignItems="center">
              <Typography variant="h5">
                PTP&nbsp;&nbsp;$&nbsp;
                {showDashIfNecessary(
                  !isTokenPriceFetched,
                  getDpFormat(tokenPrices.PTP, 3),
                )}
                &nbsp;
              </Typography>
              {/* PTP token price percentage changed */}
              {isTokenPriceFetched && (
                <>
                  <Typography variant="body2">
                    <Sign positive={isPositivePricePercentChanged} colorized>
                      {getDpFormat(tokenPrices24hChange.PTP)}%
                    </Sign>
                  </Typography>
                  <InfoBox.Tooltip text="Percentage changed in 24hours." />
                </>
              )}
            </Box>
            <Box display="flex" alignItems="center" width="45%">
              {connectedWalletId &&
                WALLETS[connectedWalletId].isAddTokenSupported && (
                  <TransparentButton
                    customVariant="secondary"
                    sizeVariant="xs"
                    bordered
                    onClick={() => void addTokenToWallet(TokenSymbol.PTP)}
                  >
                    <AppTypography variant="caption3">Add</AppTypography>
                    <DynamicWalletIcon />
                  </TransparentButton>
                )}
              <TransparentButton
                customVariant="secondary"
                sizeVariant="xs"
                bordered
                onClick={() => void copyAddress(ptpAddress || '')}
              >
                <Typography variant="caption3">Copy</Typography>
                <ContentCopyIcon />
              </TransparentButton>
            </Box>
          </Box>
        </PtpInfoItem>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          flexWrap={isMobileLg ? 'wrap' : 'unset'}
          flex={1}
        >
          {/* PTP circulating supply */}
          <PtpInfoItem wrappable>
            <AppTypography
              variant="caption2"
              className="ptp-info-item__caption"
              transparent
            >
              Circulating Supply
            </AppTypography>
            <Typography variant="subtitle2">
              {showDashIfNecessary(
                !ptp.total.circulatingSupply,
                getCommifiedFormat(ptp.total.circulatingSupply),
              )}
              &nbsp;PTP
            </Typography>
          </PtpInfoItem>
          {/* PTP market cap*/}
          <PtpInfoItem wrappable>
            <AppTypography
              variant="caption2"
              className="ptp-info-item__caption"
              transparent
            >
              Market Cap
            </AppTypography>
            <Typography variant="subtitle2">
              <TooltipNum
                leftSymbol="$"
                amount={ptpMarketCap}
                format="commified"
              >
                $&nbsp;
                {showDashIfNecessary(
                  !isTokenPriceFetched,
                  getMillifiedFormat(ptpMarketCap),
                )}
              </TooltipNum>
            </Typography>
          </PtpInfoItem>
        </Box>
        <StyledAppButton
          onClick={() => setIsTradeModalOpen(true)}
          customVariant="secondary"
        >
          Trade
        </StyledAppButton>
      </PtpInfoContainer>
      {isTradeModalOpen && (
        <PtpSelectTradeModal
          isOpen
          onClose={() => setIsTradeModalOpen(false)}
        />
      )}
    </>
  )
}
