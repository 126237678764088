import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import preval from 'preval.macro'
import React from 'react'
import { createRoot } from 'react-dom/client'
import packageInfo from '../package.json'
import App from './App'
import reportWebVitals from './reportWebVitals'
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT || 'development',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  beforeSend(event) {
    // only send error occured in production or staging, not development
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      console.error('Exceptions caught by Sentry: ', event.exception?.values)
      return null
    }
    /** uncomment if we wanna show the report dialog for every error */
    // if (event.exception) {
    //   Sentry.showReportDialog({ eventId: event.event_id })
    // }
    return event
  },
})

// add build version and build time to global window variables
if (typeof window !== 'undefined') {
  const buildTimestamp = new Date(
    preval`module.exports = new Date().getTime();` as string | number | Date,
  )
  window.BUILD_VERSION = packageInfo.version
  window.BUILD_TIME = buildTimestamp
  // logging the build info so users can tell us which version they are using when they need technical support
  console.log(`Version: ${packageInfo.version}`)
  console.log(`Build time: ${buildTimestamp.toString()}`)
}

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
