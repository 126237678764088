import { useEffect, useMemo, useState } from 'react'
import { useVePtp } from '../contexts/VePtpContext'
import { getCountdownData } from '../utils/locking'

type Props = {
  targetTimestampInMilliseconds: number
}

export type CountdownData = {
  label: string
  maxValue: number
  value: number
}[]

function useCountdown({ targetTimestampInMilliseconds }: Props) {
  const { ptp } = useVePtp()
  const initialCountdownData = getCountdownData(
    [
      {
        label: 'd',
        maxValue: ptp.lockTime.maxDays + 1,
        value: 0,
      },
      {
        label: 'h',
        maxValue: 24,
        value: 0,
      },
      {
        label: 'm',
        maxValue: 60,
        value: 0,
      },
      {
        label: 's',
        maxValue: 60,
        value: 0,
      },
    ],
    targetTimestampInMilliseconds,
  )
  const [countdownData, setCountdownData] =
    useState<CountdownData>(initialCountdownData)

  useEffect(() => {
    const setTime = () => {
      setCountdownData((prev) => {
        const newCountdown = getCountdownData(
          prev,
          targetTimestampInMilliseconds,
          interval,
        )
        return newCountdown
      })
    }
    setTime()
    const interval = setInterval(setTime, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [targetTimestampInMilliseconds])

  const hasZeroDay = countdownData.find(
    (item) => item.label === 'd' && item.value === 0,
  )

  const filteredCountdownData = useMemo(() => {
    return countdownData.filter((item) => {
      if (hasZeroDay) {
        return !(item.label === 'd')
      } else {
        return !(item.label === 's')
      }
    })
  }, [countdownData, hasZeroDay])

  return { countdownData: filteredCountdownData }
}

export default useCountdown
