import { sum } from '@hailstonelabs/big-number-utils'
import { usdIconPath } from '../../../assets/tokens'
import {
  PoolSymbolStringStringStringType,
  TokenSymbolStringType,
} from '../../../interfaces/common'
import { TokenSymbol } from '../token/tokenSymbol'
import { PoolGroupSymbol, PoolSymbol } from './poolSymbol'

export const POOL_GROUP_SYMBOL_KEYS = Object.keys(PoolGroupSymbol)
export class PoolGroup {
  readonly poolSymbols: PoolSymbol[]
  readonly name: string
  readonly icon: string
  constructor(poolSymbols: PoolSymbol[], name: string, icon: string) {
    this.poolSymbols = poolSymbols
    this.name = name
    this.icon = icon
  }

  /**
   *
   * @param {PoolSymbolStringStringStringType} rewards rewards from StakeLpContext
   * @returns {TokenSymbolStringType} sumOfEachReward
   */
  getSumOfEachReward(
    rewards: PoolSymbolStringStringStringType,
  ): TokenSymbolStringType {
    return PoolGroup.getSumOfEachReward(this.poolSymbols, rewards)
  }
  static getSumOfEachReward(
    poolSymbols: PoolSymbol[],
    rewards: PoolSymbolStringStringStringType,
  ): TokenSymbolStringType {
    const sumOfEachReward: TokenSymbolStringType = {} as TokenSymbolStringType
    for (const poolSymbol of poolSymbols) {
      const rewardsPerPool = rewards[poolSymbol]
      for (const rewardsPerPoolKey in rewardsPerPool) {
        for (const tokenSymbolStr in rewardsPerPool[rewardsPerPoolKey]) {
          const tokenSymbol = tokenSymbolStr as TokenSymbol
          const newReward =
            rewardsPerPool[rewardsPerPoolKey][tokenSymbol] || '0.0'
          const prevReward = sumOfEachReward[tokenSymbol] || '0.0'
          sumOfEachReward[tokenSymbol] = sum(prevReward, newReward)
        }
      }
    }
    return sumOfEachReward
  }
}

// set pool order in ui (Main Pools, Alt Pools... list) by POOL_GROUPS
export const POOL_GROUPS: {
  [id in PoolGroupSymbol]: PoolGroup
} = {
  [PoolGroupSymbol.MAIN]: new PoolGroup(
    [PoolSymbol.MAIN],
    'Main Pool',
    usdIconPath,
  ),
  [PoolGroupSymbol.ALT]: new PoolGroup(
    [
      PoolSymbol.ALT_BTCB_WBTCE,
      PoolSymbol.ALT_AVAX_YYAVAX,
      PoolSymbol.ALT_AVAX_SAVAX,
      PoolSymbol.ALT_AVAX_ANKRAVAX,
      PoolSymbol.ALT_USDC_YUSD,
      PoolSymbol.ALT_USDC_FRAX,
      /** @todo Uncomment after listing */
      // PoolSymbol.ALT_USDC_TUSD,
      PoolSymbol.ALT_USDC_MIM,
      PoolSymbol.ALT_USDC_MONEY,
      // PoolSymbol.ALT_USDC_AXLUSDC,
      PoolSymbol.ALT_ANKRETH_WETHE,
    ],
    'Alt Pools',
    usdIconPath,
  ),
  // [PoolGroupSymbol.FACTORY]: new PoolGroup(
  //   [PoolSymbol.FACTORY_USDC_TSD, PoolSymbol.FACTORY_USDC_USX],
  //   'Factory Pools',
  //   usdIconPath,
  // ),
}
