// Actions for different smart contract write call. etc. equip NFT/unequip NFT...
export enum ActionId {
  EQUIP_NFT = 'EQUIP_NFT',
  UNEQUIP_NFT = 'UNEQUIP_NFT',
  UNSTAKE_PTP = 'UNSTAKE_PTP',
  UNLOCK_PTP = 'UNLOCK_PTP',
  POOL_WITHDRAWAL = 'POOL_WITHDRAWAL',
  POOL_DEPOSIT = 'POOL_DEPOSIT',
  BORROW_USP = 'BORROW_USP',
  REPAY_USP = 'REPAY_USP',
}
