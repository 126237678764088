// duration in second for a new block. may need to depend on the network.
export const BLOCK_TIME = 5000
export const NFT_SUBGRPAH_QUERY_SIZE = 100
export const USP_MY_LIQUIDATION_RECORD_QUERY_SIZE = 100
export const VOTING_CHART_LEGEND_SIZE = 10
export const MIN_WITHDRAWABLE_PERCENTAGE = '0.001'
export const MAX_LPTOKEN_SUPPLY_DIFFERENCE = '1'
export const AVAX_AMOUNT_RESERVED_FOR_GAS = '0.01'
export const INFINITESIMAL_SWAP_FROM_AMOUNT_FOR_MARKET = '0.1'
export const STAKE_PERIOD_LIST_INTERVAL = 3
export const CIRCULATING_SUPPLY_QUERY_FREQUENCY = 120000

// APIs
export const COIN_GECKO_API = 'https://api.coingecko.com/api/v3/simple/price'
export const COIN_MARKET_CAP_API =
  'https://yr3ejhev7i.execute-api.us-east-1.amazonaws.com/prod/cmc_price'
export const CURRENT_TIMESTAMP_API = 'https://api.platypus.finance/current_time'
export const PTP_CIRCULATING_SUPPLY_API =
  'https://api.platypus.finance/circulating_supply'
export const PTP_AVAX_PANGOLIN_APR_API =
  'https://api.pangolin.exchange/pangolin/apr2/58'
export const TRADER_JOE_EXCHANGE_SUBGRAPH_API =
  'https://api.thegraph.com/subgraphs/name/traderjoe-xyz/exchange'
export const MEDIAN_BOOSTED_APR_API =
  'https://8rehqf7xvk.execute-api.us-west-1.amazonaws.com/default/median_APR_MP4_v2'
export const PLATYPUS_EXCHANGE_SUBGRAPH_API =
  'https://api.thegraph.com/subgraphs/name/platypus-finance/platypus-exchange-v2'
export const PLATYPUS_MAINNET_NFT_SUBGRAPH_API =
  'https://api.thegraph.com/subgraphs/name/platypus-finance/platypus-nft'
export const PLATYPUS_TESTNET_NFT_SUBGRAPH_API =
  'https://api.thegraph.com/subgraphs/name/joshiedo/ptptest'
export const PLATYPUS_MAINNET_USP_SUBGRAPH_API =
  'https://api.thegraph.com/subgraphs/name/platypus-finance/platypus-stablecoin'
export const PLATYPUS_TESTNET_USP_SUBGRAPH_API =
  'https://api.thegraph.com/subgraphs/name/platypus-finance/platypus-stablecoin-fuji'
export const PLATYPUS_MAINNET_BLOCK_SUBGRAPH_API =
  'https://api.thegraph.com/subgraphs/name/platypus-finance/block'

export const SNOWTRACE_TESTNET_API = 'https://api-testnet.snowtrace.io/api'
export const SNOWTRACE_MAINNET_API = 'https://api.snowtrace.io/api'
// TpYield
export const TRADERJOE_AVAX_PTP_TPYIELD_PID = 28
// Query params
export const QUERY_PARAMS = {
  poolGroup: 'pool_group',
  swapFrom: 'from',
  swapTo: 'to',
  poolSubgroup: 'pool_sub_group',
}

// User Peference parameters
export const USER_PREFERENCE_INPUT_PRECISION = 6

// DS Maths
export const WAD_DECIMALS = 18

// Airdrop
export const AIRDROP_HUMMUS_AMOUNT = String(30_000_000)
export const AIRDROPS_PLATYPUS_URL = 'https://airdrop.platypus.finance'
export const AIRDROPS_HUMMUS_URL = 'https://airdrop.platypus.finance'
export const AIRDROPS_VECTOR_URL = 'https://vectorfinance.io/claim'
export const AIRDROPS_ECHIDNA_URL = 'https://airdrop.echidna.finance/'

// USP
export const COLLATERAL_DEPEG_THRESHOLD_PRICE = '0.98'
export const HEALTH_FACTOR_LOWER_BOUNDS = {
  healthy: 1 / 0.95,
  moderate: 1 / 0.98,
}
