import React, { ReactElement } from 'react'
import UserVoteAllocationTable from '../UserVoteAllocationTable/UserVoteAllocationTable'
import UserVotesSummary from '../UserVotesSummary/UserVotesSummary'
import { Container } from './UserVotesSection.elements'

function UserVotesSection(): ReactElement {
  return (
    <Container>
      <UserVotesSummary />
      <UserVoteAllocationTable />
    </Container>
  )
}

export default UserVotesSection
