import { Box } from '@mui/material'
import styled from 'styled-components'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: inherit;
  background: #040c15;
`

export const ChildrenContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: inherit;
`

export const Header = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 1000;
  background: inherit;
`
