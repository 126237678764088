import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { alpha, Box, useTheme } from '@mui/material'
import React, { ReactElement, useEffect } from 'react'
import { tokenIconPaths } from '../../../assets/tokens'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import Modal from '../../../components/Modal/Modal'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import AppTokenInput from '../../../components/TokenInput/AppTokenInput'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import useApprove from '../../../hooks/useApprove'
import useStakePtp from '../../../hooks/vePtp/useStakePtp'
import { ApproveSpender } from '../../../interfaces/spender'
import StakeModalInfoBox from './StakeModalInfoBox'
interface Props {
  isOpen: boolean
}

function StakeModalContainer({ isOpen }: Props): ReactElement {
  const theme = useTheme()
  const {
    input,
    ptp,
    actions: { updateStakePtpInputAmount, clearStakePtpInputAmount },
  } = useVePtp()
  const { isApproved, isApproving, handleClickApprove } = useApprove(
    TokenSymbol.PTP,
    false,
    ApproveSpender.VePtp,
    input.stakePtpAmount,
  )
  const { handleStakePtp } = useStakePtp()
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const handleModalClose = () => {
    modalDispatch(closeModal())
    clearStakePtpInputAmount()
  }

  const handleInputChange = (value: string) => {
    updateStakePtpInputAmount(value)
  }

  const handleClickMaxButton = () => {
    updateStakePtpInputAmount(ptp.balance)
  }
  const hasZeroInput = strToWad(input.stakePtpAmount).isZero()
  useEffect(() => {
    clearStakePtpInputAmount()
  }, [clearStakePtpInputAmount])
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      topBarLabel={
        <>
          Confirm Stake
          <TokenIcon margin="0 4px 0 8px" iconPath={tokenIconPaths.PTP} />
          PTP
        </>
      }
      width="650px"
      disableCloseBtn
    >
      <AppTokenInput onChange={handleInputChange} value={input.stakePtpAmount}>
        <AppTokenInput.LabelItem
          leadingLabel={
            <AppTokenInput.LabelItemInnerContainer>
              <AppTokenInput.Label>Staked:&nbsp;</AppTokenInput.Label>
              <TooltipNum
                rightSymbol={TokenSymbol.PTP}
                amount={ptp.amount.staked}
              >
                {getCommifiedFormat(ptp.amount.staked)} {TokenSymbol.PTP}
              </TooltipNum>
            </AppTokenInput.LabelItemInnerContainer>
          }
          trailingLabel={
            <AppTokenInput.LabelItemInnerContainer>
              <AppTokenInput.Label>Stakable:&nbsp;</AppTokenInput.Label>
              <TooltipNum rightSymbol={TokenSymbol.PTP} amount={ptp.balance}>
                {getCommifiedFormat(ptp.balance)} {TokenSymbol.PTP}
              </TooltipNum>
            </AppTokenInput.LabelItemInnerContainer>
          }
        />
        <AppTokenInput.InnerContainer
          bgcolor={alpha(theme.palette.primary[500], 0.2)}
          mt="12px"
        >
          <AppTokenInput.InputField
            placeholder="0.0"
            disableUnderline
            textalign="left"
            noLeftPadding
          />
          <AppTokenInput.ActionButton onClick={handleClickMaxButton}>
            Max
          </AppTokenInput.ActionButton>
        </AppTokenInput.InnerContainer>
      </AppTokenInput>
      <StakeModalInfoBox />
      <Box display="flex" flexDirection="row" mt={1}>
        <AppButton onClick={handleModalClose} fullWidth customVariant="neutral">
          Cancel
        </AppButton>
        {isApproved ? (
          <AppButton
            onClick={() => void handleStakePtp()}
            disabled={!isApproved || hasZeroInput || input.isExcess.stake}
            fullWidth
          >
            Stake
          </AppButton>
        ) : (
          <AppButton
            onClick={() => void handleClickApprove()}
            disabled={isApproved || hasZeroInput || isApproving}
            fullWidth
            customVariant="secondary"
            hasSpinner={isApproving}
          >
            {isApproving ? <>Approving</> : <>Approve</>}
          </AppButton>
        )}
      </Box>
    </Modal>
  )
}

export default StakeModalContainer
