import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import ExportIcon from '../../../assets/icons/export-icon.svg'
import ReportIcon from '../../../assets/icons/report.svg'
import AppTypography from '../../AppTypography/AppTypography'
import AppButton from '../../buttons/AppButton/AppButton'
import { Container } from './MigrationAlert.elements'

interface MigrationAlertProps {
  text: string | ReactElement
  btnText: string | ReactElement
  linkTo: string
}
function MigrationAlert({
  text,
  btnText,
  linkTo,
}: MigrationAlertProps): ReactElement {
  return (
    <Container>
      <img src={ReportIcon} />
      <AppTypography variant="subtitle2" className="MigrationAlert__text">
        {text}
      </AppTypography>
      <Link to={linkTo}>
        <AppButton className="MigrationAlert__button" fullWidth>
          {btnText}&nbsp;
          <img src={ExportIcon} width="16px" height="16px" />
        </AppButton>
      </Link>
    </Container>
  )
}

export default MigrationAlert
