import {
  getCommifiedFormat,
  safeWdiv,
  strToWad,
} from '@hailstonelabs/big-number-utils'
import { Typography } from '@mui/material'
import { BigNumber, constants, utils } from 'ethers'
import React, { ReactElement, useEffect, useState } from 'react'
import InfoBox from '../../components/InfoBox/InfoBox'
import TooltipNum from '../../components/InfoBox/TooltipNum'
import { usePoolInput } from '../../contexts/PoolInputContext'
import { usePools } from '../../contexts/PoolsContext'
import { useStakeLpData } from '../../contexts/StakeLpDataContext'
import { ExternalLink } from '../../globalStyles'
import { getDepositFee } from '../../utils/pool'

const infoData = {
  AMOUNT_DEPOSITING: {
    label: 'Amount depositing',
    tooltip: 'The amount you are depositing to the pool (after fee).',
  },
  MY_TOTAL_DEPOSITS: {
    label: 'My Total Deposits',
    tooltip: 'The total amount you would own in the pool after depositing.',
  },
  POOL_SHARE: {
    label: 'Pool Share',
    tooltip: 'The share of the pool you would own after depositing.',
  },
}

function PoolDepositInfoBox(): ReactElement {
  const { depositLiquidity, selectedPoolSymbol, selectedAssetTokenSymbol } =
    usePoolInput()
  const { assets, liabilities } = usePools()
  const { lp } = useStakeLpData()
  // amount depositing = current deposit - deposit fee
  // user total deposit = original deposit + amount depositing
  const [userTotalDepositWAD, setUserTotalDepositWAD] = useState(constants.Zero)

  const [amountDepositingInTokenWAD, setAmountDepositingInTokenWAD] = useState(
    constants.Zero,
  )
  const [feeWAD, setFeeWAD] = useState(constants.Zero)
  const [poolSharePercentStr, setPoolSharePercentStr] = useState('0.0')
  useEffect(() => {
    if (selectedAssetTokenSymbol) {
      let newUserTotalDepositWAD = constants.Zero
      // Get user original deposit (before adding the new deposit amount)
      const originalDepositInTokenWAD = strToWad(
        lp.inTermsOfToken.total[selectedPoolSymbol][selectedAssetTokenSymbol],
      )
      // Get original Pool Deposits
      const poolDepositsWAD = strToWad(
        liabilities[selectedPoolSymbol][selectedAssetTokenSymbol],
      )
      let newPoolDepositsWAD = poolDepositsWAD
      const currentDepositInToken = depositLiquidity.amount || '0.0'
      const currentDepositInTokenWAD = strToWad(currentDepositInToken)
      // calculcate fee
      const assetStr = assets[selectedPoolSymbol][selectedAssetTokenSymbol]
      const liabilityStr =
        liabilities[selectedPoolSymbol][selectedAssetTokenSymbol]
      const newFeeWAD = getDepositFee(
        strToWad(assetStr),
        strToWad(liabilityStr),
        strToWad(currentDepositInToken),
      )
      setFeeWAD(newFeeWAD)
      const newAmountDepositingInTokenWAD =
        currentDepositInTokenWAD.sub(newFeeWAD)
      setAmountDepositingInTokenWAD(newAmountDepositingInTokenWAD)

      // user total deposit = original deposit + amount depositing
      newUserTotalDepositWAD = originalDepositInTokenWAD.add(
        newAmountDepositingInTokenWAD,
      )
      setUserTotalDepositWAD(newUserTotalDepositWAD)
      // Add amount depositing to newPoolDepositsWAD
      newPoolDepositsWAD = newPoolDepositsWAD.add(newAmountDepositingInTokenWAD)

      const poolSharePercentWAD = newPoolDepositsWAD.gt(constants.Zero)
        ? safeWdiv(newUserTotalDepositWAD, newPoolDepositsWAD).mul(
            BigNumber.from(100),
          )
        : constants.Zero
      setPoolSharePercentStr(utils.formatEther(poolSharePercentWAD))
    }
  }, [
    selectedAssetTokenSymbol,
    depositLiquidity,
    assets,
    liabilities,
    lp.inTermsOfToken.total,
    selectedPoolSymbol,
  ])
  const feeToolTipMessage = (
    <>
      A deposit fee will apply when the pool&apos;s coverage ratio is larger
      than 100%. Learn more about it{' '}
      <ExternalLink href="https://docs.platypus.finance/platypus-finance-docs/our-innovative-concepts/fees/deposit-fee">
        here
      </ExternalLink>
      .
    </>
  )

  return (
    <InfoBox>
      {/* Amount depositing */}
      <InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">
            Amount depositing (after fee)
          </Typography>
          <InfoBox.Tooltip text={infoData.AMOUNT_DEPOSITING.tooltip} />
        </InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">
            <b>
              <TooltipNum
                amount={amountDepositingInTokenWAD}
                rightSymbol={
                  depositLiquidity.tokenSymbolForDisplay || undefined
                }
              >
                {getCommifiedFormat(amountDepositingInTokenWAD)}&nbsp;
                {depositLiquidity.tokenSymbolForDisplay}
              </TooltipNum>
            </b>
          </Typography>
        </InfoBox.Item>
      </InfoBox.Item>
      {/* Fee */}
      <InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">Fee</Typography>
          <InfoBox.Tooltip text={feeToolTipMessage} />
        </InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">
            <b>
              <TooltipNum
                amount={feeWAD}
                rightSymbol={
                  depositLiquidity.tokenSymbolForDisplay || undefined
                }
              >
                {getCommifiedFormat(feeWAD)}{' '}
                {depositLiquidity.tokenSymbolForDisplay}
              </TooltipNum>
            </b>
          </Typography>
        </InfoBox.Item>
      </InfoBox.Item>
      {/* My Deposits */}
      <InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">
            {infoData.MY_TOTAL_DEPOSITS.label}
          </Typography>
          <InfoBox.Tooltip text={infoData.MY_TOTAL_DEPOSITS.tooltip} />
        </InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">
            <b>
              <TooltipNum
                rightSymbol={
                  depositLiquidity.tokenSymbolForDisplay || undefined
                }
                amount={userTotalDepositWAD}
                format="commified"
              >
                {getCommifiedFormat(userTotalDepositWAD)}&nbsp;
                {depositLiquidity.tokenSymbolForDisplay}
              </TooltipNum>
            </b>
          </Typography>
        </InfoBox.Item>
      </InfoBox.Item>
      {/* Pool share */}
      <InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">{infoData.POOL_SHARE.label}</Typography>
          <InfoBox.Tooltip text={infoData.POOL_SHARE.tooltip} />
        </InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">
            <b>
              <TooltipNum amount={poolSharePercentStr} rightSymbol={'%'}>
                {getCommifiedFormat(poolSharePercentStr)} %
              </TooltipNum>
            </b>
          </Typography>
        </InfoBox.Item>
      </InfoBox.Item>
    </InfoBox>
  )
}

export default PoolDepositInfoBox
