import { getDpFormat, isParsableString } from '@hailstonelabs/big-number-utils'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Typography, useTheme } from '@mui/material'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import AppTokenInput from '../../../components/TokenInput/AppTokenInput'
import { TOKENS } from '../../../config/contracts'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useVePtpBoosterCalculator } from '../../../contexts/VePtpBoosterCalculatorContext'
import { useVePtp } from '../../../contexts/VePtpContext'

function TotalVePtpSupplyInput(): ReactElement {
  const theme = useTheme()
  const {
    totalVePtpSupplyInputAmount,
    actions: { resetTotalVePtpSupplyInput, updateTotalVePtpSupplyInput },
  } = useVePtpBoosterCalculator()
  const { vePtp } = useVePtp()
  const isResettable = useMemo(
    () => vePtp.totalSupply !== totalVePtpSupplyInputAmount,

    [totalVePtpSupplyInputAmount, vePtp.totalSupply],
  )
  const [isFormattedInitialValueShown, setIsFormattedInitialValueShown] =
    useState(true)
  const handleInputChange = (value: string) => {
    if (isFormattedInitialValueShown) {
      setIsFormattedInitialValueShown(false)
    }
    if (value === '') {
      updateTotalVePtpSupplyInput('')
      return
    }
    if (!isParsableString(value, TOKENS[TokenSymbol.VEPTP].decimals, true))
      return

    updateTotalVePtpSupplyInput(value)
  }

  const resetInput = () => {
    resetTotalVePtpSupplyInput()
    setIsFormattedInitialValueShown(true)
  }

  // only reset once
  useEffect(() => {
    resetTotalVePtpSupplyInput()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <AppTokenInput
      value={
        isFormattedInitialValueShown
          ? getDpFormat(totalVePtpSupplyInputAmount)
          : totalVePtpSupplyInputAmount
      }
      onChange={handleInputChange}
    >
      <AppTokenInput.InnerContainer
        bgcolor={theme.palette.common.black}
        flexDirection="column"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <TokenIcon tokenSymbol={TokenSymbol.VEPTP} />
          &nbsp;
          <Typography variant="caption">Total vePTP Supply</Typography>
        </Box>
        <Box display="flex" flexDirection="row" mt="16px">
          <AppTokenInput.InputField
            placeholder="0.0"
            disableUnderline
            textalign="left"
            noLeftPadding
          />
          <AppTokenInput.ActionButton
            onClick={resetInput}
            disabled={!isResettable}
          >
            <RefreshIcon />
          </AppTokenInput.ActionButton>
        </Box>
      </AppTokenInput.InnerContainer>
    </AppTokenInput>
  )
}

export default TotalVePtpSupplyInput
