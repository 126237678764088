import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'

interface TitleProps {
  $isSelected?: boolean
}
export const Title = styled(AppTypography)<TitleProps>`
  ${({ theme, $isSelected }) => css`
    font-weight: 700;
    text-transform: unset;
    ${$isSelected &&
    css`
      color: ${theme.palette.platopia.main};
    `}
  `}
`
export const CTAButton = styled(AppButton)`
  width: 80px;
  border-radius: 8px;
`

export const EmptyNftDisplay = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`
