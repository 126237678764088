import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { Box, Typography } from '@mui/material'
import { constants } from 'ethers'
import React, { ReactElement, useMemo, useState } from 'react'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import { LP_TOKENS, POOLS } from '../../../config/contracts'
import { useModal } from '../../../contexts/ModalContext'
import { useStakeLpData } from '../../../contexts/StakeLpDataContext'
import UnstakeLpContentProvider from '../../../contexts/UnstakeLpContentContext'
import useClaimRewards from '../../../hooks/pool/useClaimRewards'
import { ModalId } from '../../../interfaces/Modal'
import { getStakableLPTooltip, getStakedLPTooltip } from '../../../utils/pool'
import {
  PoolStakeWaitForConfirmationModalContainer,
  PoolUnstakeWaitForConfirmationModalContainer,
} from '../PoolStakeLPModalContainers'
import PoolStakeModalContainer from '../PoolStakeLPModalContainers/PoolStakeModalContainer/PoolStakeModalContainer'
import PoolUnstakeModalContainer from '../PoolStakeLPModalContainers/PoolUnstakeModalContainer/PoolUnstakeModalContainer'
import { usePoolStakeAccordion } from './PoolStakeAccordionContainer'
import {
  Animation,
  ButtonContainer,
  Content,
  ContentContainer,
  Item,
  PoolStakeTab,
  PoolStakeTabBarContainer,
  StakeAccordionContentContainer,
  StyledAppButton,
  Text,
} from './PoolStakeAccordionContentContainer.elements'
enum TabId {
  CLAIM = 'Claim',
  STAKE = 'Stake',
  UNSTAKE = 'Unstake',
}

const tabs = [TabId.CLAIM, TabId.STAKE, TabId.UNSTAKE]

function PoolStakeAccordionContentContainer(): ReactElement {
  const [currentTabId, setCurrentTabId] = useState(TabId.STAKE)
  const renderCurrentTabContent = (currentTabId: TabId) => {
    switch (currentTabId) {
      case TabId.CLAIM:
        return <ClaimContent />
      case TabId.UNSTAKE:
        return <UnstakeLpContent />
      default:
        return <StakeLpContent />
    }
  }
  return (
    <StakeAccordionContentContainer>
      <PoolStakeTabBarContainer>
        {tabs.map((tabId) => (
          <PoolStakeTab
            key={tabId}
            className={tabId === currentTabId ? 'active' : ''}
            onClick={() => setCurrentTabId(tabId)}
          >
            {tabId}
          </PoolStakeTab>
        ))}
        <Animation />
      </PoolStakeTabBarContainer>

      {renderCurrentTabContent(currentTabId)}
    </StakeAccordionContentContainer>
  )
}

export default React.memo(PoolStakeAccordionContentContainer)

export function ClaimContent(): ReactElement {
  const { assetTokenSymbol, poolSymbol } = usePoolStakeAccordion()
  const { handleClaimRewards } = useClaimRewards()
  const { rewards } = useStakeLpData()
  const rewardsEarned = useMemo(() => {
    const rewardsOfPool = POOLS[poolSymbol].getRewardsEarned(
      rewards,
      assetTokenSymbol,
    )
    return rewardsOfPool
  }, [rewards, poolSymbol, assetTokenSymbol])
  // sum of reward's value
  const sumOfRewardValueBN = rewardsEarned.reduce((prev, rewardOfPool) => {
    return prev.add(strToWad(rewardOfPool.value))
  }, constants.Zero)
  const isButtonDisabled = sumOfRewardValueBN.isZero()
  return (
    <ContentContainer>
      <Content>
        <Item
          style={{
            alignItems: rewardsEarned.length > 1 ? 'center' : 'flex-end',
          }}
        >
          <Text $alignItemsCenter>Earned</Text>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            {rewardsEarned.map((reward) => (
              <Text
                key={reward.tokenSymbol}
                margin={rewardsEarned.length > 1 ? '2px 0' : '0'}
              >
                <Text $highlight>
                  <TooltipNum
                    amount={reward.value}
                    rightSymbol={reward.tokenSymbol}
                  >
                    {getCommifiedFormat(reward.value)}
                  </TooltipNum>
                </Text>{' '}
                {reward.tokenSymbol}
              </Text>
            ))}
          </Box>
        </Item>
      </Content>
      <ButtonContainer>
        <StyledAppButton
          disabled={isButtonDisabled}
          onClick={() =>
            void handleClaimRewards({
              poolSymbols: [poolSymbol],
              assetTokenSymbol,
            })
          }
        >
          Claim
        </StyledAppButton>
      </ButtonContainer>
    </ContentContainer>
  )
}

function StakeLpContent(): ReactElement {
  /** @todo add assettokenSymbolForDisplay in usePoolStakeAccordion */
  const {
    assetTokenSymbol,
    poolSymbol,
    isPoolSymbolAndAssetTokenSymbolMatched,
  } = usePoolStakeAccordion()
  const tokenSymbolForDisplay =
    LP_TOKENS[poolSymbol][assetTokenSymbol]?.tokenSymbolForDisplay ||
    assetTokenSymbol
  const { lp } = useStakeLpData()
  const {
    modalDispatch,
    modalState,
    actions: { openModal },
  } = useModal()
  const handleStakeButton = () => {
    modalDispatch(
      openModal(ModalId.POOL_STAKE_LP, {
        tokenSymbols: [assetTokenSymbol],
      }),
    )
  }
  const hasZeroStakable = strToWad(
    lp.inTermsOfToken.stakable[poolSymbol][assetTokenSymbol],
  ).isZero()
  return (
    <ContentContainer>
      <Content>
        <Item>
          <Text $colored $alignItemsCenter>
            Staked{' '}
            <InfoBox.Tooltip text={getStakedLPTooltip(tokenSymbolForDisplay)} />
          </Text>
          <Text $colored>
            <TooltipNum
              amount={
                lp.inTermsOfToken.staked[poolSymbol][assetTokenSymbol] || '0.0'
              }
              rightSymbol={tokenSymbolForDisplay as string}
            >
              {getCommifiedFormat(
                lp.inTermsOfToken.staked[poolSymbol][assetTokenSymbol] || '0.0',
              )}
            </TooltipNum>{' '}
            {tokenSymbolForDisplay}
          </Text>
        </Item>
        <Item>
          <Text $alignItemsCenter>
            Stakable
            <InfoBox.Tooltip
              text={getStakableLPTooltip(tokenSymbolForDisplay)}
            />
          </Text>
          <Text>
            <Text $highlight>
              <TooltipNum
                amount={
                  lp.inTermsOfToken.stakable[poolSymbol][assetTokenSymbol] ||
                  '0.0'
                }
                rightSymbol={tokenSymbolForDisplay as string}
              >
                {getCommifiedFormat(
                  lp.inTermsOfToken.stakable[poolSymbol][assetTokenSymbol] ||
                    '0.0',
                )}
              </TooltipNum>
            </Text>{' '}
            {tokenSymbolForDisplay}
          </Text>
        </Item>
      </Content>
      <ButtonContainer>
        <Typography variant="caption3" className="info-message">
          Earned PTP will be automatically claimed on staking
        </Typography>
        <StyledAppButton disabled={hasZeroStakable} onClick={handleStakeButton}>
          Stake All
        </StyledAppButton>
      </ButtonContainer>
      {/* Modals */}
      {modalState.currentModalId === ModalId.POOL_STAKE_LP &&
        isPoolSymbolAndAssetTokenSymbolMatched && (
          <PoolStakeModalContainer isOpen />
        )}
      {modalState.currentModalId === ModalId.POOL_STAKE_WAIT_FOR_CONFIRMATION &&
        isPoolSymbolAndAssetTokenSymbolMatched && (
          <PoolStakeWaitForConfirmationModalContainer isOpen />
        )}
    </ContentContainer>
  )
}
function UnstakeLpContent(): ReactElement {
  const {
    assetTokenSymbol,
    poolSymbol,
    isPoolSymbolAndAssetTokenSymbolMatched,
  } = usePoolStakeAccordion()
  const tokenSymbolForDisplay =
    LP_TOKENS[poolSymbol][assetTokenSymbol]?.tokenSymbolForDisplay ||
    assetTokenSymbol
  const { lp } = useStakeLpData()
  const hasZeroStaked = strToWad(
    lp.inTermsOfToken.staked[poolSymbol][assetTokenSymbol],
  ).isZero()
  const {
    modalDispatch,
    modalState,
    actions: { openModal },
  } = useModal()
  const handleUnStakeButton = () => {
    modalDispatch(
      openModal(ModalId.POOL_UNSTAKE_LP, {
        tokenSymbols: [assetTokenSymbol],
      }),
    )
  }
  return (
    <UnstakeLpContentProvider
      assetTokenSymbol={assetTokenSymbol}
      poolSymbol={poolSymbol}
    >
      <ContentContainer>
        <Content>
          <Item>
            <Text $colored $alignItemsCenter>
              Stakable
              <InfoBox.Tooltip
                text={getStakableLPTooltip(tokenSymbolForDisplay)}
              />
            </Text>
            <Text $colored>
              <TooltipNum
                amount={
                  lp.inTermsOfToken.stakable[poolSymbol][assetTokenSymbol] ||
                  '0.0'
                }
                rightSymbol={tokenSymbolForDisplay as string}
              >
                {getCommifiedFormat(
                  lp.inTermsOfToken.stakable[poolSymbol][assetTokenSymbol] ||
                    '0.0',
                )}
              </TooltipNum>{' '}
              {tokenSymbolForDisplay}
            </Text>
          </Item>
          <Item>
            <Text $alignItemsCenter>
              Staked{' '}
              <InfoBox.Tooltip
                text={getStakedLPTooltip(tokenSymbolForDisplay)}
              />
            </Text>
            <Text>
              <Text $highlight>
                <TooltipNum
                  amount={
                    lp.inTermsOfToken.staked[poolSymbol][assetTokenSymbol] ||
                    '0.0'
                  }
                  rightSymbol={tokenSymbolForDisplay as string}
                >
                  {getCommifiedFormat(
                    lp.inTermsOfToken.staked[poolSymbol][assetTokenSymbol] ||
                      '0.0',
                  )}
                </TooltipNum>
              </Text>{' '}
              {tokenSymbolForDisplay}
            </Text>
          </Item>
        </Content>
        <ButtonContainer>
          <Typography variant="caption3" className="info-message">
            Earned PTP will be automatically claimed on unstaking
          </Typography>
          <StyledAppButton
            disabled={hasZeroStaked}
            onClick={handleUnStakeButton}
          >
            Unstake
          </StyledAppButton>
        </ButtonContainer>
        {/* Modals */}
        {modalState.currentModalId === ModalId.POOL_UNSTAKE_LP &&
          isPoolSymbolAndAssetTokenSymbolMatched && (
            <PoolUnstakeModalContainer isOpen />
          )}
        {modalState.currentModalId ===
          ModalId.POOL_UNSTAKE_WAIT_FOR_CONFIRMATION &&
          isPoolSymbolAndAssetTokenSymbolMatched && (
            <PoolUnstakeWaitForConfirmationModalContainer isOpen />
          )}
      </ContentContainer>
    </UnstakeLpContentProvider>
  )
}
