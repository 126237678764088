export enum NftTypeId {
  SPEEDO = 'Speedo',
  PUDGY = 'Pudgy',
  DILIGENT = 'Diligent',
  GIFTED = 'Gifted',
  HIBERNATE = 'Hibernate',
}

export interface ValueWithNftType<T> {
  original: T // original = original stats before the effect of the equipped NFT
  // generally using current(withNft) in UI, original(withoutNft) is for change NFT modal
  current: T // current = current stats with the effect of the currently equipped NFT
}

export interface PlatypusNFT {
  id: string // '0'
  name: string // 'Unnamed' or the user-given name
  owner: string // owner address
  imgSrc: string // image url
  type: NftTypeId // ability. example 'Diligent'
  value: string // power. example '7'
}
