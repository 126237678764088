import { getDynamicFormat } from '@hailstonelabs/big-number-utils'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { Box } from '@mui/material'
import React, { ReactElement, useMemo, useState } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import Message from '../../../components/Message/Message'
import Modal from '../../../components/Modal/Modal'
import nftUIData from '../../../config/nftUIData'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { Colorized } from '../../../globalStyles'
import useEquipNft from '../../../hooks/nft/useEquipNft'
import useNftApprove from '../../../hooks/nft/useNftApprove'
import useUnequipNft from '../../../hooks/nft/useUnequipNft'
import { ModalId } from '../../../interfaces/Modal'
import MemorizedNftCardContainer from '../../MemorizedNftCardContainer/MemorizedNftCardContainer'
import { ArrowRight } from './ChangeNftConfirmationModal.elements'
interface Props {
  isOpen: boolean
  isToUnequip?: boolean
  isToEquip?: boolean
}

function ChangeNftConfirmationModal({
  isOpen,
  isToUnequip,
  isToEquip,
}: Props): ReactElement {
  const {
    modalDispatch,
    modalState,
    actions: { closeModal, openModal },
  } = useModal()
  const {
    vePtp,
    nft: { all },
  } = useVePtp()
  const isToChange = !isToEquip && !isToUnequip
  const { handleUnequipNft } = useUnequipNft()
  const { handleEquipNft } = useEquipNft()
  const [showMessage, setShowMessage] = useState(true)
  // currentNftId does not exist if it is to equip NFT
  const currentNftId = modalState.nftData?.current?.id
  const currentNft = currentNftId ? all[currentNftId] : null
  // changeToNftId does not exist if it is to unequip NFT
  const changeToNftId = modalState.nftData?.changeTo?.id
  const changeToNft = changeToNftId ? all[changeToNftId] : null

  const { isApproved, isApproving, handleClickApprove } =
    useNftApprove(changeToNftId)

  const handleClickAction = () => {
    if (currentNftId && changeToNftId) {
      // For changing to another NFT
      modalDispatch(
        openModal(ModalId.CHANGE_NFT_WAIT_FOR_CONFIRMATION, {
          nftData: {
            current: { id: currentNftId },
            changeTo: { id: changeToNftId },
          },
        }),
      )
      void handleEquipNft(changeToNftId)
    } else if (currentNftId) {
      // For NFT unequipment
      modalDispatch(
        openModal(ModalId.CHANGE_NFT_WAIT_FOR_CONFIRMATION, {
          nftData: {
            current: { id: currentNftId },
            changeTo: null,
          },
        }),
      )
      void handleUnequipNft()
    } else if (changeToNftId) {
      // For NFT equipment
      modalDispatch(
        openModal(ModalId.CHANGE_NFT_WAIT_FOR_CONFIRMATION, {
          nftData: {
            current: null,
            changeTo: { id: changeToNftId },
          },
        }),
      )
      void handleEquipNft(changeToNftId)
    }
  }
  const handleModalClose = () => {
    modalDispatch(closeModal())
  }

  const stats = useMemo(
    () => vePtp.getBeforeAfterStatsDifference(currentNft, changeToNft),
    [changeToNft, currentNft, vePtp],
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      topBarLabel={`Confirm ${
        isToUnequip ? 'Unequip' : isToEquip ? 'Equip' : 'Change'
      } NFT`}
      width="500px"
      disableCloseBtn
    >
      {/* only unequipment or change of NFT has a warning message */}
      {showMessage && !isToEquip && currentNftId && (
        <Message
          onClose={() => setShowMessage(false)}
          message={nftUIData[all[currentNftId].type].getUnequipText({
            value: all[currentNftId].value,
          })}
        />
      )}
      <MemorizedNftCardContainer
        id={currentNftId}
        variant="small"
        enableBgColor
        disableActiveColor={!!changeToNftId}
      />
      {isToChange && (
        <Box display="flex" justifyContent="center">
          <KeyboardArrowDownRoundedIcon fontSize="large" />
        </Box>
      )}
      {changeToNftId && (
        <MemorizedNftCardContainer
          id={changeToNftId}
          variant="small"
          enableBgColor
          disableActiveText
        />
      )}
      <InfoBox>
        {stats.vePtpEarningPerHour && (
          <StatsItem
            isPowerUp={stats.vePtpEarningPerHour.isIncreased}
            title="vePTP mining rate (vePTP/hour)"
            before={
              <TooltipNum
                rightSymbol="vePTP/hour"
                amount={stats.vePtpEarningPerHour.before}
              >
                {getDynamicFormat(stats.vePtpEarningPerHour.before, 4)}
              </TooltipNum>
            }
            after={
              <TooltipNum
                rightSymbol="vePTP/hour"
                amount={stats.vePtpEarningPerHour.after}
              >
                {getDynamicFormat(stats.vePtpEarningPerHour.after, 4)}
              </TooltipNum>
            }
          />
        )}
        {stats.maxVePtpCapForStaking && (
          <StatsItem
            isPowerUp={stats.maxVePtpCapForStaking.isIncreased}
            title="max vePTP to mine (vePTP)"
            before={
              <TooltipNum
                rightSymbol="vePTP"
                amount={stats.maxVePtpCapForStaking.before}
              >
                {getDynamicFormat(stats.maxVePtpCapForStaking.before)}
              </TooltipNum>
            }
            after={
              <TooltipNum
                rightSymbol="vePTP"
                amount={stats.maxVePtpCapForStaking.after}
              >
                {getDynamicFormat(stats.maxVePtpCapForStaking.after)}
              </TooltipNum>
            }
          />
        )}
        {stats.vePtpTotalBalance && (
          <StatsItem
            isPowerUp={stats.vePtpTotalBalance.isIncreased}
            title="vePTP balance (vePTP)"
            before={
              <TooltipNum
                rightSymbol="vePTP"
                amount={stats.vePtpTotalBalance.before}
              >
                {getDynamicFormat(stats.vePtpTotalBalance.before)}
              </TooltipNum>
            }
            after={
              <TooltipNum
                rightSymbol="vePTP"
                amount={stats.vePtpTotalBalance.after}
              >
                {getDynamicFormat(stats.vePtpTotalBalance.after)}
              </TooltipNum>
            }
          />
        )}
        {stats.retainPercentage && (
          <StatsItem
            isPowerUp={stats.retainPercentage.isIncreased}
            title="Retain vePTP up to"
            // turn it into an integer. e.g. 10.0 => 10
            before={`${Number(stats.retainPercentage.before)}%`}
            after={`${Number(stats.retainPercentage.after)}%`}
          />
        )}
      </InfoBox>
      <Box display="flex" flexDirection="row" mt={1}>
        <AppButton onClick={handleModalClose} fullWidth customVariant="neutral">
          Cancel
        </AppButton>
        {!isApproved && (
          <AppButton
            onClick={() => void handleClickApprove()}
            disabled={isApproving}
            hasSpinner={isApproving}
            fullWidth
          >
            {isApproving ? 'Approving' : 'Approve'}
          </AppButton>
        )}
        {/* Unequip - should be approved already when users equip the nft */}
        {/* Change & Equip  - should require an approval */}
        {isApproved && (
          <AppButton onClick={handleClickAction} fullWidth>
            {isToUnequip ? 'Unequip' : isToEquip ? 'Equip' : 'Change'}
          </AppButton>
        )}
      </Box>
    </Modal>
  )
}

export default ChangeNftConfirmationModal

interface StatsItemProps {
  title: string | ReactElement
  before: string | ReactElement
  after: string | ReactElement
  isPowerUp: boolean
}
function StatsItem({
  title,
  before,
  after,
  isPowerUp,
}: StatsItemProps): ReactElement {
  return (
    <InfoBox.Item flexWrap="wrap">
      <InfoBox.Item>
        <AppTypography variant="caption">{title}</AppTypography>
      </InfoBox.Item>
      <InfoBox.Item alignSelf="flex-end">
        <AppTypography
          variant="caption"
          centerContent
          justifyContent="flex-start"
        >
          {before} <ArrowRight />
          <Colorized variant={isPowerUp ? 'green' : 'red'}>{after}</Colorized>
        </AppTypography>
      </InfoBox.Item>
    </InfoBox.Item>
  )
}
