import {
  getCommifiedFormat,
  getDpFormat,
  strToWad,
} from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import React, { ReactElement, useMemo } from 'react'
import AppTypography from '../../../../components/AppTypography/AppTypography'
import InfoBox from '../../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../../components/InfoBox/TooltipNum'
import WaitForConfirmationModal from '../../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import TokenIcon from '../../../../components/TokenIcon/TokenIcon'
import { LP_TOKENS, POOLS } from '../../../../config/contracts'
import { TokenSymbol } from '../../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../../contexts/ModalContext'
import { useVotingData } from '../../../../contexts/VotingDataContext'
import { Colorized } from '../../../../globalStyles'
import { getTotalEarnedBribeRewardsOfEachBribeToken } from '../../../../utils/voting'
import { useUserVoteAllocationTable } from '../UserVoteAllocationTableContext'

interface Props {
  isOpen: boolean
}

/**
 * This is for voting and reward claiming
 */
function VoteWaitForConfirmationModal({ isOpen }: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
    modalState: { lpTokenAddresses },
  } = useModal()
  const { editedGaugeList, voteWeightPercentageInputs } =
    useUserVoteAllocationTable()
  const {
    user: { earnedBribeRewards },
  } = useVotingData()
  const editedGaugesDataForDisplay = useMemo(() => {
    const outputData: {
      tokenSymbolForDisplay: TokenSymbol | undefined
      label: string
      value: string
    }[] = []
    let totalVoteWeightPercentageWad = strToWad('0')
    for (const gauge of editedGaugeList) {
      const { poolSymbol, assetTokenSymbol } = gauge
      const newVoteWeight =
        voteWeightPercentageInputs[poolSymbol]?.[assetTokenSymbol]
      if (newVoteWeight !== undefined) {
        const tokenSymbolForDisplay =
          LP_TOKENS[poolSymbol]?.[assetTokenSymbol]?.tokenSymbolForDisplay
        outputData.push({
          tokenSymbolForDisplay,
          label: `${tokenSymbolForDisplay || 'Unknown'} (${
            POOLS[poolSymbol].name
          })`,
          // clean up the value e.g. "020" becomes "20"
          value: utils.formatEther(strToWad(newVoteWeight)),
        })
        totalVoteWeightPercentageWad = totalVoteWeightPercentageWad.add(
          strToWad(newVoteWeight),
        )
      }
    }
    return {
      totalVoteWeightPercentage: utils.formatEther(
        totalVoteWeightPercentageWad,
      ),
      data: outputData,
    }
    // prevent from showing incorrect UI after the votes are completed
    // this variable only needs a snapshot of the edited gauges at the time when users click vote button
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // if there is no edited inputs, it is to claim rewards
  const isToVote = editedGaugesDataForDisplay.data.length > 0
  const handleCloseModal = () => {
    modalDispatch(closeModal())
  }
  const earnedBribeRewardsWithUniqueTokenSymbol =
    getTotalEarnedBribeRewardsOfEachBribeToken(
      earnedBribeRewards,
      isToVote ? lpTokenAddresses || [] : 'ALL',
    )
  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      subtitle={isToVote ? 'Adding Votes' : 'Claiming Earned Rewards'}
    >
      {isToVote ? (
        <>
          <WaitForConfirmationModal.ItemsContainer>
            {editedGaugesDataForDisplay.data.map((gauge) => (
              <WaitForConfirmationModal.Item key={gauge.label}>
                <AppTypography component="span" centerContent variant="inherit">
                  <TokenIcon
                    tokenSymbol={gauge.tokenSymbolForDisplay}
                    margin="0 8px 0 0"
                  />
                  {gauge.label}
                </AppTypography>
                <AppTypography component="span" variant="inherit">
                  {getDpFormat(gauge.value)}&nbsp;%
                </AppTypography>
              </WaitForConfirmationModal.Item>
            ))}
          </WaitForConfirmationModal.ItemsContainer>
          <InfoBox>
            <InfoBox.Item>
              <InfoBox.Item>
                <AppTypography variant="caption">
                  Total vote weight in percentage
                </AppTypography>
              </InfoBox.Item>
              <InfoBox.Item>
                <AppTypography variant="caption">
                  {getDpFormat(
                    editedGaugesDataForDisplay.totalVoteWeightPercentage,
                  )}
                  %
                </AppTypography>
              </InfoBox.Item>
            </InfoBox.Item>
            {earnedBribeRewardsWithUniqueTokenSymbol.length > 0 && (
              <InfoBox.Item alignItems="flex-start">
                <InfoBox.Item>
                  <AppTypography variant="caption">
                    <Colorized>Auto claim rewards</Colorized>
                  </AppTypography>
                </InfoBox.Item>
                <InfoBox.Item
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  {earnedBribeRewardsWithUniqueTokenSymbol.map((reward) => (
                    <AppTypography
                      variant="caption"
                      key={reward.bribeTokenSymbol}
                    >
                      <TooltipNum
                        amount={reward.value}
                        rightSymbol={reward.bribeTokenSymbol}
                      >
                        <Colorized>
                          {getCommifiedFormat(reward.value)}&nbsp;
                          {reward.bribeTokenSymbol}
                        </Colorized>
                      </TooltipNum>
                    </AppTypography>
                  ))}
                </InfoBox.Item>
              </InfoBox.Item>
            )}
          </InfoBox>
        </>
      ) : (
        // For claiming rewards
        <WaitForConfirmationModal.ItemsContainer>
          {earnedBribeRewardsWithUniqueTokenSymbol.map((reward) => (
            <WaitForConfirmationModal.Item key={reward.bribeTokenSymbol}>
              <AppTypography component="span" centerContent variant="inherit">
                <TokenIcon
                  tokenSymbol={reward.bribeTokenSymbol}
                  margin="0 8px 0 0"
                />
                {reward.bribeTokenSymbol}
              </AppTypography>
              <AppTypography component="span" variant="inherit">
                <TooltipNum amount={reward.value}>
                  {getCommifiedFormat(reward.value)}
                </TooltipNum>
              </AppTypography>
            </WaitForConfirmationModal.Item>
          ))}
        </WaitForConfirmationModal.ItemsContainer>
      )}
    </WaitForConfirmationModal>
  )
}

export default VoteWaitForConfirmationModal
