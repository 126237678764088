import React from 'react'
import {
  DeprecatedPoolsPage,
  GaugeVotingPage,
  // MigrationPage,
  PlatopiaPage,
  PoolPage,
  SwapPage,
  VePtpPage,
} from '.././pages'

enum Routes {
  SWAP = 'SWAP',
  POOL = 'POOL',
  STAKE = 'STAKE',
  // MIGRATION = 'MIGRATION',
  PLATOPIA = 'PLATOPIA',
  DEPRECATED_POOLS = 'DEPRECATED_POOLS',
  GAUGE_VOTING = 'GAUGE_VOTING',
  /**@todo re-open once USP feature is already */
  // USP = 'USP',
  /**@todo re-open once auction feature is already */
  // AUCTION = 'AUCTION',
}

enum ExternalLinks {
  NFT = 'nft',
  ABOUT = 'about',
  AIRDROP = 'airdrop',
  // DASHBOARD = 'dashboard',
  GOVERNANCE_FORUM = 'governance forum',
  SNAPSHOT = 'snapshot',
}

type RouteType = {
  [key in Routes]: {
    path: string
    name: string
    Component: React.FC
    visibility: 'navbar' | 'menu' | 'none'
  }
}

type ExternalLinkType = {
  [value in ExternalLinks]: {
    path: string
    name: string
  }
}

const routes: RouteType = {
  [Routes.SWAP]: {
    path: '/swap',
    name: 'Swap',
    Component: SwapPage,
    visibility: 'navbar',
  },
  [Routes.POOL]: {
    path: '/pool',
    name: 'Pool',
    Component: PoolPage,
    visibility: 'navbar',
  },
  [Routes.STAKE]: {
    path: '/stake',
    name: 'vePTP',
    Component: VePtpPage,
    visibility: 'navbar',
  },
  [Routes.GAUGE_VOTING]: {
    path: '/gauge-weight-voting',
    name: 'Gauges',
    Component: GaugeVotingPage,
    visibility: 'navbar',
  },
  /**@todo re-open once USP feature is already */
  // [Routes.USP]: {
  //   path: '/usp',
  //   name: 'USP',
  //   Component: UspPage,
  //   visibility: 'navbar',
  // },
  // [Routes.MIGRATION]: {
  //   path: '/migration',
  //   name: 'Migration',
  //   Component: MigrationPage,
  //   visibility: 'none',
  // },
  [Routes.PLATOPIA]: {
    path: '/platopia',
    name: 'Platopia',
    Component: PlatopiaPage,
    visibility: 'none',
  },
  [Routes.DEPRECATED_POOLS]: {
    path: '/deprecated-pools',
    name: 'Deprecated Pools',
    Component: DeprecatedPoolsPage,
    visibility: 'none',
  },
  /**@todo re-open once auction feature is already */
  // [Routes.AUCTION]: {
  //   path: '/auction',
  //   name: 'Auction',
  //   Component: AuctionPage,
  //   visibility: 'none',
  // },
}

export default routes

export const visibleRoutesOnNavbar = Object.values(routes).filter(
  (route) => route.visibility === 'navbar',
)
export const visibleRoutesInMenu = Object.values(routes).filter(
  (route) => route.visibility === 'menu',
)

export const allVisibleRoutes = [
  ...visibleRoutesOnNavbar,
  ...visibleRoutesInMenu,
]

export const externalLinksOnNavbar = {} as ExternalLinkType

export const externalLinksInMenu: ExternalLinkType = {
  [ExternalLinks.GOVERNANCE_FORUM]: {
    path: 'https://gov.platypus.finance/',
    name: 'Governance Forum',
  },
  [ExternalLinks.SNAPSHOT]: {
    path: 'https://snapshot.org/#/platypusdefi.eth',
    name: 'Snapshot',
  },
  [ExternalLinks.NFT]: {
    path: 'https://nft.platypus.finance/',
    name: 'NFT',
  },
  [ExternalLinks.ABOUT]: {
    path: 'https://platypus.finance/',
    name: 'About',
  },
  [ExternalLinks.AIRDROP]: {
    path: 'https://airdrop.platypus.finance/',
    name: 'Airdrop',
  },
  // [ExternalLinks.DASHBOARD]: {
  //   path: 'https://dashboard.platypus.finance/',
  //   name: 'Dashboard',
  // },
}

export const allExternalLinks = {
  ...externalLinksOnNavbar,
  ...externalLinksInMenu,
}
