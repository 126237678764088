import { AppBar, Box } from '@mui/material'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const CompanyLogo = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      position: absolute;
      /* padding of the parent container */
      top: 8px;
      left: 16px;
    }
  `}
`
const navbarItemStyles = css`
  margin: 0 7px;
  cursor: pointer;
`
export const NavbarItem = styled(Box)`
  ${navbarItemStyles}
`
export const NavbarLinkItem = styled(NavLink)`
  ${navbarItemStyles}
`
export const Trailing = styled(Box)`
  ${({ theme }) => css`
    margin-left: auto;
    ${NavbarItem} {
      &:last-of-type {
        margin-right: 0;
      }
    }
    @media only screen and (max-width: ${theme.breakpoints.values.lg}px) {
      margin-left: 0;
    }
  `}
`
interface ContainerProps {
  $bgColor?: string
  $isStickyMode?: boolean
}
export const Container = styled(AppBar)<ContainerProps>`
  ${({ theme, $bgColor, $isStickyMode }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: ${$bgColor || 'unset'};
    transition: all 0.3s ease;
    ${$isStickyMode &&
    css`
      // bottom border
      &::before {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          90deg,
          rgba(12, 88, 255, 0.5) 0%,
          #0076e9 48.29%,
          rgba(12, 88, 255, 0.5) 97.25%
        );
      }
    `}
    .MuiToolbar-root {
      width: 100%;
      padding: 16px 20px;
    }

    @media only screen and (max-width: ${theme.breakpoints.values.lg}px) {
      .MuiToolbar-root {
        min-height: 56px;
        padding: 8px 16px;
        justify-content: center;
      }
    }
  `}
`
export const MainNavbarItemList = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-weight: 700;
    font-size: 14px;

    ${NavbarItem}, ${NavbarLinkItem} {
      margin: 0 24px;

      padding: 5px 6px;
      position: relative;
      &:hover {
        opacity: 0.7;
      }
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        transition: width 0.3s ease;
      }
      &.active {
        &::before {
          background: linear-gradient(99.19deg, #00f152 10.12%, #0076e9 100%);
          width: 100%;
        }
      }

      @media only screen and (max-width: ${theme.breakpoints.values.lg}px) {
        margin: 0 12px;
      }
    }
  `}
`

export const MobileMenuButton = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    width: 32px;
    height: 32px;
    padding: 4px;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-left: 0;
    }
  `}
`
