import React, { ReactElement } from 'react'
import AppButton from '../../components/buttons/AppButton/AppButton'
import { ErrorModalOptions } from '../../contexts/ErrorModalContext'
import { Message, StyledModal, Title } from './ErrorModalContainer.elements'
interface Props {
  isOpen: boolean
  title?: string
  message?: string
  onCloseErrorModal: () => void
  options?: ErrorModalOptions
}

function ErrorModalContainer({
  isOpen,
  title,
  message,
  onCloseErrorModal,
  options,
}: Props): ReactElement {
  const handleClickButton = () => {
    onCloseErrorModal()
    if (options?.onClickButton) {
      options.onClickButton()
    }
  }
  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onCloseErrorModal}
      disableCloseBtn={!options?.enableCloseBtn}
      disableTopBar={!options?.enableCloseBtn}
      width="500px"
    >
      <Title>{title ? title : `Error`}</Title>
      <Message>{message}</Message>
      <AppButton
        margin="0"
        customVariant="negative"
        onClick={handleClickButton}
      >
        Okay
      </AppButton>
    </StyledModal>
  )
}

export default ErrorModalContainer
