import {
  getCommifiedFormat,
  getDpFormat,
  getMillifiedFormat,
  nativeToWAD,
  strToWad,
  wmul,
} from '@hailstonelabs/big-number-utils'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box } from '@mui/material'
import { BigNumber, constants, utils } from 'ethers'
import { default as React, ReactElement, useCallback, useMemo } from 'react'
import Dashable from '../../../components/Dashable/Dashable'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import { DataProps } from '../../../components/Table/Table.elements'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import { LP_TOKENS, POOLS, TOKENS } from '../../../config/contracts'
import { PoolSymbol } from '../../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useBalance } from '../../../contexts/BalanceContext'
import { useMulticallData } from '../../../contexts/MulticallDataContext'
import { useStakeLpData } from '../../../contexts/StakeLpDataContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { useVotingData } from '../../../contexts/VotingDataContext'
import { getTokenEmissionWad } from '../../../utils/aprCalculation'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import {
  getBribeIncentiveWadInTokenPerNDays,
  getProposedPoolWeightWad,
  getProposedTotalWeightWad,
} from '../../../utils/voting'
import {
  DataTypography,
  InputBackground,
  InputWrapper,
  MaxButton,
  PercentageLabel,
  RefreshButton,
  RemainingPercentageLabel,
  StyledInput,
} from './UserVoteAllocationTable.elements'
import {
  ACTIVE_INPUT_WRAPPER_CLASS_NAME,
  ACTIVE_ROW_CLASS_NAME,
  useUserVoteAllocationTable,
} from './UserVoteAllocationTableContext'
import { DEPRECATED_GAUGES_POOLS } from '../../../config/contracts/pool'

type TableDataType = [
  string | ReactElement,
  string | ReactElement,
  string | ReactElement,
  string | ReactElement,
  string | ReactElement,
  string | ReactElement,
  string | ReactElement,
  string | ReactElement,
]

type TableHeadConfig = {
  style?: DataProps
  data: ReactElement | string
}[]
type TableData = {
  id: string
  data: TableDataType
}[]
interface ReturnType {
  tableHeadConfig: TableHeadConfig
  tableData: TableData
}
function useUserVoteAllocationTableData(): ReturnType {
  const {
    voteWeightPercentageInputs,
    votedAndAddedGaugeList,
    isInEditMode,
    availableVoteWeightPercentageForFocusedInput,
    totalVoteWeightPercentageOfTheTable,
    updateVoteWeightPercentageInput,
    removeAddedGauge,
    updateAvailableVoteWeightPercentageForFocusedInput,
    clearActiveElementsStyles,
  } = useUserVoteAllocationTable()
  const { votingData, stakeLpData } = useMulticallData()
  const { tokenPrices, isTokenPriceFetched } = useBalance()
  const { vePtp } = useVePtp()
  const { apr } = useStakeLpData()
  const { user, total, weeklyBribeIncentives } = useVotingData()

  /**
   * Event handlers
   */
  const handleInputWrapperClick = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      poolSymbol: PoolSymbol,
      assetTokenSymbol: TokenSymbol,
    ) => {
      updateAvailableVoteWeightPercentageForFocusedInput(
        poolSymbol,
        assetTokenSymbol,
      )
      clearActiveElementsStyles()
      const targetInputWrapper = e.currentTarget
      // add active styles to the target row and input wrapper
      targetInputWrapper.classList.add(ACTIVE_INPUT_WRAPPER_CLASS_NAME)
      const targetRow = targetInputWrapper.closest('tr')
      targetRow?.classList.add(ACTIVE_ROW_CLASS_NAME)
    },
    [
      clearActiveElementsStyles,
      updateAvailableVoteWeightPercentageForFocusedInput,
    ],
  )

  const handleRefreshButtonClick = useCallback(
    (
      isNewGauge: boolean,
      poolSymbol: PoolSymbol,
      assetTokenSymbol: TokenSymbol,
      voteWeightOfTargetAssetInPercentageFromSC: string,
    ) => {
      if (!isNewGauge) {
        // a voted gauge
        updateVoteWeightPercentageInput(
          poolSymbol,
          assetTokenSymbol,
          voteWeightOfTargetAssetInPercentageFromSC,
        )
      } else {
        // a newly added gauge
        updateVoteWeightPercentageInput(poolSymbol, assetTokenSymbol, '')
      }
    },
    [updateVoteWeightPercentageInput],
  )
  /**
   * Table Head Config
   */
  const tableHeadConfig: TableHeadConfig = useMemo(
    () => [
      {
        style: { textAlign: 'center' },
        data: <DataTypography>#</DataTypography>,
      },
      {
        style: { textAlign: 'center' },
        data: <DataTypography>Pool</DataTypography>,
      },
      { data: 'Token' },
      {
        style: { textAlign: 'center' },
        data: (
          <>
            <DataTypography centerContent flexWrap="nowrap" whiteSpace="nowrap">
              <TokenIcon
                tokenSymbol={TokenSymbol.PTP}
                size={16}
                margin="0 4px 0 0"
              />
              {isInEditMode && 'Monthly'} PTP Emission
            </DataTypography>
            <DataTypography transparent whiteSpace="nowrap">
              {isInEditMode ? 'Current > Proposed' : 'Per month'}
            </DataTypography>
          </>
        ),
      },
      {
        style: { textAlign: 'center' },
        data: (
          <>
            <DataTypography centerContent flexWrap="nowrap" whiteSpace="nowrap">
              Bribe Rewards
            </DataTypography>
            <DataTypography centerContent>
              <DataTypography transparent component="span">
                Per week
              </DataTypography>
              <InfoBox.Tooltip text="The weekly bribe rewards you can earn on this gauge. This amount may vary based on your vote and the total vote of this gauge." />
            </DataTypography>
          </>
        ),
      },
      {
        style: { textAlign: 'center' },
        data: (
          <DataTypography whiteSpace="nowrap">
            Claimable
            <br />
            Rewards
          </DataTypography>
        ),
      },
      {
        style: { textAlign: 'center' },
        data: (
          <>
            <DataTypography centerContent flexWrap="nowrap" whiteSpace="nowrap">
              Vote Weights
            </DataTypography>
            <DataTypography transparent>In vePTP</DataTypography>
          </>
        ),
      },
      {
        style: { textAlign: 'center', minWidth: '160px' },
        data: (
          <>
            <DataTypography centerContent flexWrap="nowrap" whiteSpace="nowrap">
              Vote Weights
            </DataTypography>
            <DataTypography transparent>In&nbsp;percentage</DataTypography>
          </>
        ),
      },
    ],
    [isInEditMode],
  )
  /**
   * Table data
   */
  const tableData = useMemo(() => {
    const outputData: TableData = []
    const proposedTotalWeightWad = getProposedTotalWeightWad(
      strToWad(total.currentVote),
      strToWad(user.usedVote.vePtp),
      totalVoteWeightPercentageOfTheTable,
      strToWad(vePtp.balance.total.current),
    )
    votedAndAddedGaugeList.forEach((gauge) => {
      const isNewGauge = gauge.id === undefined
      const pool = POOLS[gauge.poolSymbol]
      const asset = LP_TOKENS[gauge.poolSymbol][gauge.assetTokenSymbol]
      const isVoted = strToWad(
        user.voteWeightOfEachAsset.vePtp[gauge.poolSymbol][
          gauge.assetTokenSymbol
        ],
      ).gt(constants.Zero)

      let voteWeightPercentageInput = ''
      let voteWeightOfTargetAsset = ''
      let isEdited = false
      const currentBribeRewardsPerWeek = {
        inToken:
          weeklyBribeIncentives.perVotedVeptp[gauge.poolSymbol]?.[
            gauge.assetTokenSymbol
          ]?.inToken || '0.0',
        inUsd:
          weeklyBribeIncentives.perVotedVeptp[gauge.poolSymbol]?.[
            gauge.assetTokenSymbol
          ]?.inUsd || '0.0',
      }
      const bribeTokenSymbol = asset?.bribe?.tokenSymbol
      if (!isInEditMode) {
        // use data from SC
        voteWeightOfTargetAsset =
          user.voteWeightOfEachAsset.vePtp[gauge.poolSymbol][
            gauge.assetTokenSymbol
          ] || ''
      }

      const targetBribeBalance = bribeTokenSymbol
        ? total.bribeBalanceOfEachAsset[gauge.poolSymbol]?.[asset.tokenSymbol]
            ?.inToken
        : '0'

      const isBribeBalanceDrained = strToWad(targetBribeBalance).eq(
        constants.Zero,
      )

      // for non edit mode / data refresh
      const voteWeightOfTargetAssetInPercentageFromSC =
        user.voteWeightOfEachAsset.percentage[gauge.poolSymbol][
          gauge.assetTokenSymbol
        ] || ''
      let proposedMonthlyPtpEmission =
        apr.ptpMonthlyEmission[gauge.poolSymbol][gauge.assetTokenSymbol]
      let proposedBribeRewardsPerWeek = currentBribeRewardsPerWeek
      if (isInEditMode) {
        voteWeightPercentageInput =
          voteWeightPercentageInputs[gauge.poolSymbol]?.[
            gauge.assetTokenSymbol
          ] || ''
        // calculate new vote weight, proposedMonthlyPtpEmission after user input
        // Vote Weight (column 8)
        const newVoteWeightOfTargetAssetWad = strToWad(
          vePtp.balance.total.current,
        )
          .mul(strToWad(voteWeightPercentageInput))
          .div(strToWad('100'))
        voteWeightOfTargetAsset = utils.formatEther(
          newVoteWeightOfTargetAssetWad,
        )

        const newPoolWeightWad = getProposedPoolWeightWad(
          strToWad(
            user.voteWeightOfEachAsset.vePtp[gauge.poolSymbol][
              gauge.assetTokenSymbol
            ],
          ),
          newVoteWeightOfTargetAssetWad,
          strToWad(
            total.voteWeightOfEachAsset.vePtp[gauge.poolSymbol][
              gauge.assetTokenSymbol
            ],
          ),
        )
        // Bribe Rewards Per day (column 5)
        if (votingData.withoutAccount && bribeTokenSymbol) {
          const proposedBribeRewardsPerWeekInTokenWad =
            getBribeIncentiveWadInTokenPerNDays(
              newPoolWeightWad,
              newVoteWeightOfTargetAssetWad,
              nativeToWAD(
                votingData.withoutAccount.bribeTokenPerSecondOfEachAssetBN[
                  gauge.poolSymbol
                ][gauge.assetTokenSymbol],
                TOKENS[bribeTokenSymbol].decimals,
              ),
              7,
            )
          proposedBribeRewardsPerWeek = {
            inToken: utils.formatEther(proposedBribeRewardsPerWeekInTokenWad),
            inUsd: utils.formatEther(
              wmul(
                proposedBribeRewardsPerWeekInTokenWad,
                strToWad(tokenPrices[bribeTokenSymbol]),
              ),
            ),
          }
        }

        // Proposed Monthly Ptp Emission (column 4)
        if (votingData.withoutAccount && stakeLpData.withoutAccount) {
          proposedMonthlyPtpEmission = utils.formatEther(
            getTokenEmissionWad(
              votingData.withoutAccount.ptpPerSecWad,
              newPoolWeightWad,
              proposedTotalWeightWad,
              pool.hasPtpBooster
                ? BigNumber.from(stakeLpData.withoutAccount.dilutingRepartition)
                : BigNumber.from('1000'),
              30,
            ),
          )
        }

        // check if the input is edited
        if (isNewGauge) {
          if (strToWad(voteWeightPercentageInput).gt(strToWad('0'))) {
            isEdited = true
          }
        } else {
          if (
            !strToWad(voteWeightPercentageInput).eq(
              strToWad(voteWeightOfTargetAssetInPercentageFromSC),
            )
          ) {
            isEdited = true
          }
        }
      }

      const rowId = `${isNewGauge ? 'new-' : ''}${gauge.poolSymbol}-${
        gauge.assetTokenSymbol
      }`
      // styling
      const row = document.getElementById(rowId)
      const allDataElements = row?.children
      if (allDataElements) {
        const lastDataElement = allDataElements[allDataElements.length - 1]
        if (isEdited) {
          // "Vote Weights" must be placed at the last column
          lastDataElement.classList.add('edited')
        } else {
          lastDataElement.classList.remove('edited')
        }
      }
      /**
       * add data
       */
      outputData.push({
        id: rowId,
        data: [
          /**
           * column 1
           */
          <>
            {!isNewGauge ? (
              <DataTypography>{gauge.id}</DataTypography>
            ) : (
              <Box display="flex" onClick={() => removeAddedGauge(gauge)}>
                <CancelOutlinedIcon style={{ fontSize: '16px' }} />
              </Box>
            )}
          </>,
          /**
           * column 2
           */
          <>
            <DataTypography>{pool.name.replace(' Pool', '')}</DataTypography>
          </>,
          /**
           * column 3
           */
          <>
            {asset ? (
              <DataTypography
                centerContent
                flexWrap="nowrap"
                whiteSpace="nowrap"
              >
                <TokenIcon
                  tokenSymbol={asset.tokenSymbolForDisplay}
                  size={16}
                  margin="0 4px 0 0"
                />
                {asset.tokenSymbolForDisplay}
              </DataTypography>
            ) : (
              'Unknown'
            )}
          </>,
          /**
           * column 4
           */
          <>
            <DataTypography>
              <TooltipNum
                format="commified"
                amount={
                  apr.ptpMonthlyEmission[gauge.poolSymbol][
                    gauge.assetTokenSymbol
                  ]
                }
              >
                {DEPRECATED_GAUGES_POOLS.includes(gauge.poolSymbol)
                  ? '-'
                  : getMillifiedFormat(
                      apr.ptpMonthlyEmission[gauge.poolSymbol][
                        gauge.assetTokenSymbol
                      ],
                    )}
              </TooltipNum>
              {isInEditMode &&
                !DEPRECATED_GAUGES_POOLS.includes(gauge.poolSymbol) && (
                  /* proposed monthly PTP emission */
                  <>
                    &nbsp;&gt;&nbsp;
                    <TooltipNum
                      format="commified"
                      amount={proposedMonthlyPtpEmission}
                    >
                      {getMillifiedFormat(proposedMonthlyPtpEmission)}
                    </TooltipNum>
                  </>
                )}
            </DataTypography>
          </>,
          /**
           * column 5
           */
          <>
            <Dashable
              showDash={
                !bribeTokenSymbol ||
                isBribeBalanceDrained ||
                DEPRECATED_GAUGES_POOLS.includes(gauge.poolSymbol)
              }
            >
              <>
                {' '}
                <DataTypography
                  centerContent
                  flexWrap="nowrap"
                  whiteSpace="nowrap"
                >
                  <TokenIcon
                    tokenSymbol={bribeTokenSymbol}
                    size={16}
                    margin="0 4px 0 0"
                  />
                  {getCommifiedFormat(currentBribeRewardsPerWeek.inToken)}
                  {isEdited &&
                    ` > ${getCommifiedFormat(
                      proposedBribeRewardsPerWeek.inToken,
                    )}`}{' '}
                  {bribeTokenSymbol}
                </DataTypography>
                {!isInEditMode && (
                  <DataTypography transparent variant="caption2">
                    ($
                    {showDashIfNecessary(
                      !isTokenPriceFetched,
                      getCommifiedFormat(currentBribeRewardsPerWeek.inUsd),
                    )}
                    )
                  </DataTypography>
                )}
              </>
            </Dashable>
          </>,
          /**
           * column 6
           */
          <>
            {bribeTokenSymbol &&
            isVoted &&
            !isBribeBalanceDrained &&
            !DEPRECATED_GAUGES_POOLS.includes(gauge.poolSymbol) ? (
              <>
                {' '}
                <DataTypography
                  centerContent
                  flexWrap="nowrap"
                  whiteSpace="nowrap"
                >
                  <TokenIcon
                    tokenSymbol={bribeTokenSymbol}
                    size={16}
                    margin="0 4px 0 0"
                  />
                  {getCommifiedFormat(
                    user.earnedBribeOfEachAsset[gauge.poolSymbol][
                      gauge.assetTokenSymbol
                    ],
                  )}{' '}
                  {bribeTokenSymbol}
                </DataTypography>
                <DataTypography transparent variant="caption2">
                  ($
                  {showDashIfNecessary(
                    !isTokenPriceFetched,
                    getCommifiedFormat(
                      wmul(
                        strToWad(tokenPrices[bribeTokenSymbol]),
                        strToWad(
                          user.earnedBribeOfEachAsset[gauge.poolSymbol][
                            gauge.assetTokenSymbol
                          ],
                        ),
                      ),
                    ),
                  )}
                  )
                </DataTypography>
              </>
            ) : (
              '-'
            )}
          </>,
          /**
           * column 7
           */
          <>
            <DataTypography>
              {getCommifiedFormat(voteWeightOfTargetAsset)}&nbsp;
              <DataTypography transparent component="span">
                vePTP
              </DataTypography>
            </DataTypography>
          </>,
          /**
           * column 8
           */
          <>
            {isInEditMode ? (
              <InputWrapper
                className="UserVoteAllocationTable__input-wrapper"
                onClick={(e) =>
                  handleInputWrapperClick(
                    e,
                    gauge.poolSymbol,
                    gauge.assetTokenSymbol,
                  )
                }
              >
                <InputBackground component="div">
                  <StyledInput
                    placeholder="0"
                    value={voteWeightPercentageInput}
                    onChange={(e) => {
                      updateVoteWeightPercentageInput(
                        gauge.poolSymbol,
                        gauge.assetTokenSymbol,
                        e.target.value,
                      )
                    }}
                  />
                  {/* only show when the input is focused and edited */}
                  {isEdited && (
                    <RefreshButton
                      customVariant="unset"
                      margin="0 0 0 8px"
                      // use onMouseDown instead of onClick to maintain styles on mobile
                      onMouseDown={() =>
                        handleRefreshButtonClick(
                          isNewGauge,
                          gauge.poolSymbol,
                          gauge.assetTokenSymbol,
                          voteWeightOfTargetAssetInPercentageFromSC,
                        )
                      }
                    >
                      <RefreshIcon />
                    </RefreshButton>
                  )}
                </InputBackground>
                {/* only show when the input is not focused */}
                <PercentageLabel>%</PercentageLabel>
                {/* only show when the input is focused */}
                <MaxButton
                  customVariant="neutral"
                  onClick={() => {
                    updateVoteWeightPercentageInput(
                      gauge.poolSymbol,
                      gauge.assetTokenSymbol,
                      availableVoteWeightPercentageForFocusedInput,
                    )
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <RemainingPercentageLabel>
                      {getDpFormat(
                        availableVoteWeightPercentageForFocusedInput,
                      )}
                      %
                    </RemainingPercentageLabel>
                    MAX
                  </Box>
                </MaxButton>
              </InputWrapper>
            ) : (
              <DataTypography>
                {voteWeightOfTargetAssetInPercentageFromSC}
                &nbsp;%
              </DataTypography>
            )}
          </>,
        ],
      })
    })

    return outputData
  }, [
    total.currentVote,
    total.bribeBalanceOfEachAsset,
    total.voteWeightOfEachAsset.vePtp,
    user.usedVote.vePtp,
    user.voteWeightOfEachAsset.vePtp,
    user.voteWeightOfEachAsset.percentage,
    user.earnedBribeOfEachAsset,
    totalVoteWeightPercentageOfTheTable,
    vePtp.balance.total,
    votedAndAddedGaugeList,
    weeklyBribeIncentives.perVotedVeptp,
    isInEditMode,
    apr.ptpMonthlyEmission,
    isTokenPriceFetched,
    tokenPrices,
    availableVoteWeightPercentageForFocusedInput,
    voteWeightPercentageInputs,
    votingData.withoutAccount,
    stakeLpData.withoutAccount,
    removeAddedGauge,
    handleInputWrapperClick,
    updateVoteWeightPercentageInput,
    handleRefreshButtonClick,
  ])

  return {
    tableHeadConfig,
    tableData,
  }
}

export default useUserVoteAllocationTableData
