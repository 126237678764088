import { Typography, TypographyProps } from '@mui/material'
import styled, { css } from 'styled-components'
import { AppTypographyProps } from './AppTypography'
interface StyledTypographyProps
  extends Omit<AppTypographyProps, keyof TypographyProps> {
  flexWrap?: 'wrap' | 'nowrap' | 'unset'
}
export const StyledTypography = styled(Typography).withConfig({
  shouldForwardProp: (prop) => !['centerContent', 'transparent'].includes(prop),
})<StyledTypographyProps>`
  ${({ transparent, flexWrap = 'wrap', centerContent }) => css`
    ${transparent &&
    css`
      opacity: 0.5;
    `}
    ${centerContent &&
    css`
      display: inline-flex;
      align-items: center;
      flex-wrap: ${flexWrap};
    `}
  `}
`
