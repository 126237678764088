import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { alpha, Box, useTheme } from '@mui/material'
import { constants } from 'ethers'
import React, { ReactElement, useEffect, useState } from 'react'
import { tokenIconPaths } from '../../../assets/tokens'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import Message from '../../../components/Message/Message'
import Modal from '../../../components/Modal/Modal'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import AppTokenInput from '../../../components/TokenInput/AppTokenInput'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { ScrollableBox } from '../../../globalStyles'
import { ModalId } from '../../../interfaces/Modal'
import { NftTypeId } from '../../../interfaces/nft'
import MemorizedNftCardContainer from '../../MemorizedNftCardContainer/MemorizedNftCardContainer'
import {
  NftCardWrapper,
  NftCardWrapperTitle,
} from './UnstakeModalContainer.elements'
import UnstakeOrUnlockModalInfoBox from './UnstakeOrUnlockModalInfoBox'

interface Props {
  isOpen: boolean
}

function UnstakeModalContainer({ isOpen }: Props): ReactElement {
  const theme = useTheme()
  const {
    input,
    vePtp,
    ptp,
    nft,
    actions: { updateUnstakePtpInputAmount, clearUnstakePtpInputAmount },
  } = useVePtp()
  const {
    modalDispatch,
    actions: { closeModal, openModal },
  } = useModal()
  const [showMessage, setShowMessage] = useState(true)
  const hibernateNftValue =
    nft.equippedId &&
    nft.all[nft.equippedId].type === NftTypeId.HIBERNATE &&
    nft.all[nft.equippedId].value
  const isInputValueGreaterThanZero = strToWad(input.unstakePtpAmount).gt(
    constants.Zero,
  )
  const warningMessage =
    // user owned hibernate Nft and input
    hibernateNftValue && !input.isUnstakedAllPtpWithEquippedNft
      ? isInputValueGreaterThanZero
        ? `Due to the hibernate ability, you will lose your accumulated vePTP (${getCommifiedFormat(
            input.vePtpAmount.burnt,
            2,
          )} vePTP) and pending vePTP (${getCommifiedFormat(
            vePtp.pendingAmount,
            2,
          )} vePTP), after unstaking ${input.unstakePtpAmount} PTP.`
        : `Due to the hibernate ability, you can retain up to ${hibernateNftValue}% vePTP, after partially unstaking PTP.`
      : `You will lose all of your accumulated vePTP (${getCommifiedFormat(
          vePtp.balance.staking.original,
          2,
        )} vePTP) and pending vePTP (${getCommifiedFormat(
          vePtp.pendingAmount,
          2,
        )} vePTP) from staking after unstaking PTP.`
  const handleModalClose = () => {
    modalDispatch(closeModal())
    clearUnstakePtpInputAmount()
  }

  const handleClickUnstake = () => {
    modalDispatch(openModal(ModalId.CONFIRM_UNSTAKE_PTP))
  }
  const handleInputChange = (value: string) => {
    updateUnstakePtpInputAmount(value)
  }

  const handleClickMaxButton = () => {
    updateUnstakePtpInputAmount(ptp.amount.staked)
  }

  useEffect(() => {
    clearUnstakePtpInputAmount()
  }, [clearUnstakePtpInputAmount])

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      topBarLabel={
        <>
          Confirm Unstake
          <TokenIcon margin="0 4px 0 8px" iconPath={tokenIconPaths.PTP} />
          PTP
        </>
      }
      width="650px"
      disableCloseBtn
    >
      <ScrollableBox>
        <AppTokenInput
          onChange={handleInputChange}
          value={input.unstakePtpAmount}
        >
          <AppTokenInput.LabelItem
            leadingLabel={
              <AppTokenInput.LabelItemInnerContainer>
                <AppTokenInput.Label>Staked:&nbsp;</AppTokenInput.Label>
                <TooltipNum
                  amount={ptp.amount.staked}
                  rightSymbol={TokenSymbol.PTP}
                >
                  {getCommifiedFormat(ptp.amount.staked)} {TokenSymbol.PTP}
                </TooltipNum>
              </AppTokenInput.LabelItemInnerContainer>
            }
            trailingLabel={
              <AppTokenInput.LabelItemInnerContainer>
                <AppTokenInput.Label>Stakable:&nbsp;</AppTokenInput.Label>
                <TooltipNum amount={ptp.balance} rightSymbol={TokenSymbol.PTP}>
                  {getCommifiedFormat(ptp.balance)} {TokenSymbol.PTP}
                </TooltipNum>
              </AppTokenInput.LabelItemInnerContainer>
            }
          />
          <AppTokenInput.InnerContainer
            bgcolor={alpha(theme.palette.primary[500], 0.2)}
            mt="12px"
          >
            <AppTokenInput.InputField
              placeholder="0.0"
              disableUnderline
              textalign="left"
              noLeftPadding
            />
            <AppTokenInput.ActionButton onClick={handleClickMaxButton}>
              Max
            </AppTokenInput.ActionButton>
          </AppTokenInput.InnerContainer>
        </AppTokenInput>
        {showMessage && (
          <Message
            style={{ marginBottom: 0 }}
            onClose={() => setShowMessage(false)}
            message={warningMessage}
          />
        )}
        <UnstakeOrUnlockModalInfoBox />
        {/* show nft warning if users try to unstake all staked PTP */}
        {input.isUnstakedAllPtpWithEquippedNft && (
          <NftCardWrapper>
            <NftCardWrapperTitle>
              Unstaking all staked PTP will also unequip your NFT.
            </NftCardWrapperTitle>
            <MemorizedNftCardContainer id={nft.equippedId} />
          </NftCardWrapper>
        )}
      </ScrollableBox>
      <Box display="flex" flexDirection="row" mt={1}>
        <AppButton onClick={handleModalClose} fullWidth customVariant="neutral">
          Cancel
        </AppButton>
        <AppButton
          onClick={handleClickUnstake}
          disabled={!isInputValueGreaterThanZero || input.isExcess.unStake}
          fullWidth
        >
          Unstake
        </AppButton>
      </Box>
    </Modal>
  )
}

export default UnstakeModalContainer
