import { Checkbox } from '@mui/material'
import styled, { css } from 'styled-components'

interface StyledCheckBoxProps {
  $size?: number
}
export const StyledCheckBox = styled(Checkbox)<StyledCheckBoxProps>`
  ${({ theme, $size }) => css`
    padding: 0;
    margin-right: 4px;
    .MuiSvgIcon-root {
      font-size: ${$size ? $size : '22px'};
    }

    &.Mui-checked .MuiSvgIcon-root {
      color: ${theme.palette.common.white};
    }
  `}
`
