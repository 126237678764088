import CloseIcon from '@mui/icons-material/Close'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import React, { ReactElement, useCallback, useEffect } from 'react'
import AppTypography from '../AppTypography/AppTypography'
import {
  CloseButton,
  Header,
  ModalBody,
  ModalContainer,
  TopbarConatiner,
} from './Modal.elements'

export type ModalBorderColorType = 'info' | 'warning' | undefined

export interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
  disableTopBar?: boolean
  disableCloseBtn?: boolean
  topBarLabel?: string | ReactElement
  TopBarLabelComponent?: ReactElement
  topBarSubtitle?: string | ReactElement
  width?: string | undefined
  maxWidth?: string | undefined
  maxHeight?: string | undefined
  className?: string | undefined
  borderColor?: ModalBorderColorType | undefined
}

function Modal({
  isOpen = false,
  onClose,
  children,
  disableTopBar,
  topBarLabel = '',
  TopBarLabelComponent,
  topBarSubtitle,
  width = '100%',
  maxWidth = '500px',
  maxHeight,
  className,
  disableCloseBtn,
  borderColor,
}: ModalProps): ReactElement {
  const handleClose = () => {
    onClose()
  }

  const handleEscKey = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Escape') {
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey)
    return () => {
      document.removeEventListener('keydown', handleEscKey)
    }
  }, [onClose, handleEscKey])

  return (
    <ModalContainer
      className={className}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      disableEnforceFocus
      disableAutoFocus
      BackdropProps={{
        timeout: 200,
      }}
    >
      <Fade in={isOpen}>
        <ModalBody
          className="Modal__body"
          width={width}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          borderColor={borderColor}
        >
          {!disableTopBar && (
            <>
              <Topbar
                onClose={onClose}
                leadingLabel={topBarLabel}
                LeadingLabelComponent={TopBarLabelComponent}
                disableCloseBtn={disableCloseBtn}
                margin={topBarSubtitle && '0'}
              />
              {topBarSubtitle && (
                <AppTypography
                  variant="caption2"
                  transparent
                  marginBottom="12px"
                >
                  {topBarSubtitle}
                </AppTypography>
              )}
            </>
          )}
          {children}
        </ModalBody>
      </Fade>
    </ModalContainer>
  )
}

export default Modal
interface TopbarProps {
  leadingLabel?: string | ReactElement
  LeadingLabelComponent?: ReactElement
  onClose: () => void
  disableCloseBtn?: boolean
  margin?: string
}
const Topbar = ({
  leadingLabel,
  LeadingLabelComponent,
  onClose,
  disableCloseBtn,
  margin,
}: TopbarProps): ReactElement => {
  return (
    <TopbarConatiner
      display="flex"
      flexDirection="row"
      justifyContent={disableCloseBtn ? 'center' : 'space-between'}
      alignItems="center"
      margin={margin ? margin : '0 0 12px'}
    >
      {LeadingLabelComponent ? (
        LeadingLabelComponent
      ) : (
        <Header variant="h6">{leadingLabel}</Header>
      )}
      {!disableCloseBtn && (
        <CloseButton
          size="small"
          onClick={onClose}
          style={{
            position:
              LeadingLabelComponent || leadingLabel ? 'absolute' : 'relative',
          }}
        >
          <CloseIcon />
        </CloseButton>
      )}
    </TopbarConatiner>
  )
}
