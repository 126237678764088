import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import { utils } from 'ethers'
import React, { ReactElement } from 'react'
import AppButton from '../../../../components/buttons/AppButton/AppButton'
import InfoBox from '../../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../../components/InfoBox/TooltipNum'
import Modal from '../../../../components/Modal/Modal'
import TokenIcon from '../../../../components/TokenIcon/TokenIcon'
import AppTokenInput from '../../../../components/TokenInput/AppTokenInput'
import DisabledAppTokenInput from '../../../../components/TokenInput/DisabledAppTokenInput'
import {
  LP_TOKENS,
  MASTER_PLATYPUS,
  POOLS,
  TOKENS,
} from '../../../../config/contracts'
import { useModal } from '../../../../contexts/ModalContext'
import { usePoolInput } from '../../../../contexts/PoolInputContext'
import { useStakeLpData } from '../../../../contexts/StakeLpDataContext'
import useStakeLp from '../../../../hooks/pool/useStakeLp'
import useApprove from '../../../../hooks/useApprove'
import { ApproveSpender } from '../../../../interfaces/spender'
import {
  getStakableLPTooltip,
  getStakedLPTooltip,
} from '../../../../utils/pool'
import PoolStakeModalInfoBox from './PoolStakeModalInfoBox'

interface Props {
  isOpen: boolean
}

function PoolStakeModalContainer({ isOpen }: Props): ReactElement {
  const {
    modalState,
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const handleModalClose = () => {
    modalDispatch(closeModal())
  }
  const { lp } = useStakeLpData()
  const { handleStakeLp } = useStakeLp()
  const { selectedPoolSymbol } = usePoolInput()
  const tokenSymbol = modalState.tokenSymbols && modalState.tokenSymbols[0]
  // whether the approve button is clicked
  const { isApproved, isApproving, handleClickApprove } = useApprove(
    tokenSymbol,
    true,
    ApproveSpender.MasterPlatypus,
    'all',
    selectedPoolSymbol,
  )
  if (!tokenSymbol) return <></>
  const handleClickStakeAll = () =>
    handleStakeLp(
      tokenSymbol,
      selectedPoolSymbol,
      MASTER_PLATYPUS[POOLS[selectedPoolSymbol].masterPlatypusId],
      utils.parseUnits(
        lp.inTermsOfLp.stakable[selectedPoolSymbol][tokenSymbol] || '0.0',
        TOKENS[tokenSymbol].decimals,
      ),
    )
  const tokenSymbolForDisplay =
    LP_TOKENS[selectedPoolSymbol][tokenSymbol]?.tokenSymbolForDisplay ||
    tokenSymbol
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      topBarLabel={
        <>
          Confirm Stake
          <TokenIcon margin="0 4px 0 8px" tokenSymbol={tokenSymbolForDisplay} />
          {tokenSymbolForDisplay}
        </>
      }
      disableCloseBtn
    >
      <DisabledAppTokenInput
        tokenItems={[
          {
            tokenSymbol: tokenSymbolForDisplay,
            value:
              lp.inTermsOfToken.stakable[selectedPoolSymbol][tokenSymbol] ||
              '0.0',
          },
        ]}
        leadingLabel={
          <AppTokenInput.LabelItemInnerContainer>
            <AppTokenInput.Label>Staked:&nbsp;</AppTokenInput.Label>
            <InfoBox.Item>
              <TooltipNum
                amount={
                  lp.inTermsOfToken.staked[selectedPoolSymbol][tokenSymbol] ||
                  '0.0'
                }
                rightSymbol={tokenSymbolForDisplay}
              >
                {getCommifiedFormat(
                  lp.inTermsOfToken.staked[selectedPoolSymbol][tokenSymbol] ||
                    '0.0',
                )}{' '}
                {tokenSymbolForDisplay}
              </TooltipNum>
              <InfoBox.Tooltip
                text={getStakedLPTooltip(tokenSymbolForDisplay)}
              />
            </InfoBox.Item>
          </AppTokenInput.LabelItemInnerContainer>
        }
        trailingLabel={
          <AppTokenInput.LabelItemInnerContainer
            style={{ justifyContent: 'flex-end' }}
          >
            <AppTokenInput.Label>Stakable:&nbsp;</AppTokenInput.Label>
            <InfoBox.Item>
              <TooltipNum
                amount={
                  lp.inTermsOfToken.stakable[selectedPoolSymbol][tokenSymbol] ||
                  '0.0'
                }
                rightSymbol={tokenSymbolForDisplay}
              >
                {getCommifiedFormat(
                  lp.inTermsOfToken.stakable[selectedPoolSymbol][tokenSymbol] ||
                    '0.0',
                )}{' '}
                {tokenSymbolForDisplay}
              </TooltipNum>
              <InfoBox.Tooltip
                text={getStakableLPTooltip(tokenSymbolForDisplay)}
                style={{ marginRight: 0 }}
              />
            </InfoBox.Item>
          </AppTokenInput.LabelItemInnerContainer>
        }
      />
      <PoolStakeModalInfoBox tokenSymbol={tokenSymbol} />
      <Box display="flex" flexDirection="row">
        <AppButton customVariant="neutral" fullWidth onClick={handleModalClose}>
          Cancel
        </AppButton>
        {isApproved ? (
          <AppButton
            onClick={() => void handleClickStakeAll()}
            disabled={!isApproved}
            fullWidth
          >
            Stake All
          </AppButton>
        ) : (
          <AppButton
            customVariant="secondary"
            fullWidth
            onClick={() => void handleClickApprove()}
            disabled={isApproved || isApproving}
            hasSpinner={isApproving}
          >
            {isApproving ? <>Approving</> : <>Approve</>}
          </AppButton>
        )}
      </Box>
    </Modal>
  )
}

export default PoolStakeModalContainer
