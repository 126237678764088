import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { Box, Toolbar, useTheme } from '@mui/material'
import clsx from 'clsx'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import PlatypusLightLogoMobile from '../../assets/companyLogos/platypus-light-mobile.svg'
import PlatypusLightLogo from '../../assets/companyLogos/platypus-light.svg'
import ExportIcon from '../../assets/icons/export-icon.svg'
import AppMenu from '../../components/AppMenu/AppMenu'
import AppTypography from '../../components/AppTypography/AppTypography'
import {
  allExternalLinks,
  allVisibleRoutes,
  externalLinksInMenu,
  externalLinksOnNavbar,
  visibleRoutesInMenu,
  visibleRoutesOnNavbar,
} from '../../config/routes'
import { ExternalLink } from '../../globalStyles'
import useBreakpoints from '../../hooks/useBreakpoints'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import useWindowScroll from '../../hooks/useWindowScroll'
import ConnectToWalletButtonContainer from '../ConnectToWallet/ConnectToWalletButtonContainer/ConnectToWalletButtonContainer'
import UserPrefernceButtonContainer from '../UserPreference/UserPreferenceButtonContainer/UserPrefernceButtonContainer'
import {
  CompanyLogo,
  Container,
  MainNavbarItemList,
  MobileMenuButton,
  NavbarItem,
  NavbarLinkItem,
  Trailing,
} from './NarbarContainer.elements'

function NarbarContainer(): ReactElement {
  const theme = useTheme()
  const { isTabletSm, isTabletLg } = useBreakpoints()
  const navbarContainerRef = useRef<HTMLDivElement>(null)
  const [, scrollY] = useWindowScroll()
  const { width } = useWindowDimensions()
  const [isStickyMode, setIsStickyMode] = useState(false)
  const location = useLocation()
  const [anchorElementForMenu, setAnchorElementForMenu] =
    React.useState<HTMLDivElement | null>(null)
  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setAnchorElementForMenu(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorElementForMenu(null)
  }

  useEffect(() => {
    // handle background change on scroll
    if (navbarContainerRef.current) {
      setIsStickyMode(scrollY > navbarContainerRef.current.offsetHeight)
    }
  }, [scrollY, width])

  return (
    <Container
      $bgColor={isStickyMode ? theme.palette.primary[800] : 'inherit'}
      $isStickyMode={isStickyMode}
      ref={navbarContainerRef}
    >
      <Toolbar>
        <Link to="/">
          <CompanyLogo>
            {isTabletLg ? (
              <img
                src={PlatypusLightLogoMobile}
                alt="Platypus logo"
                width="40px"
                height="40px"
              />
            ) : (
              <img src={PlatypusLightLogo} alt="Platypus logo" width="200px" />
            )}
          </CompanyLogo>
        </Link>
        {/* Main routes only show on desktop*/}
        {!isTabletSm && (
          <MainNavbarItemList>
            {visibleRoutesOnNavbar.map((route) => {
              return (
                <NavbarLinkItem
                  to={route.path}
                  key={route.name}
                  className={clsx(location.pathname === route.path && 'active')}
                >
                  {route.name}
                </NavbarLinkItem>
              )
            })}
            {Object.values(externalLinksOnNavbar).length > 0 &&
              Object.values(externalLinksOnNavbar).map((link) => (
                <NavbarItem key={link.name}>
                  <ExternalLink href={link.path} disableUnderline>
                    {link.name}
                  </ExternalLink>
                </NavbarItem>
              ))}
            <NavbarItem onClick={handleClickMenu}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                More <ArrowDropDownIcon fontSize="small" />
              </Box>
            </NavbarItem>
          </MainNavbarItemList>
        )}
        <Trailing display="flex" flexDirection="row" alignItems="center">
          {!isTabletSm && (
            <NavbarItem>
              <UserPrefernceButtonContainer />
            </NavbarItem>
          )}
          <NavbarItem>
            <ConnectToWalletButtonContainer />
          </NavbarItem>
          <NavbarItem
            // styles for mobile
            {...(isTabletSm && {
              position: 'absolute',
              right: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            })}
          >
            {isTabletSm && (
              <>
                <NavbarItem>
                  <UserPrefernceButtonContainer />
                </NavbarItem>
                <NavbarItem onClick={handleClickMenu}>
                  <MobileMenuButton>
                    <MenuRoundedIcon fontSize="small" />
                  </MobileMenuButton>
                </NavbarItem>
              </>
            )}
          </NavbarItem>
        </Trailing>

        {/* Pop up menu */}
        <AppMenu
          open={!!anchorElementForMenu}
          anchorEl={anchorElementForMenu}
          onClose={handleCloseMenu}
          MenuListProps={{ style: { minWidth: '220px' } }}
          disableAutoFocus
          disableAutoFocusItem
          disableScrollLock
        >
          {/* show more rotues on mobile */}
          {(isTabletSm ? allVisibleRoutes : visibleRoutesInMenu).map(
            (route) => (
              <NavLink to={route.path} key={route.name}>
                <AppMenu.MenuItem onClick={handleCloseMenu}>
                  {route.name}
                </AppMenu.MenuItem>
              </NavLink>
            ),
          )}

          {/* external links  */}
          {/* show all external links on mobile */}
          {Object.values(
            isTabletSm ? allExternalLinks : externalLinksInMenu,
          ).map((link) => {
            const isComingSoon = !link.path
            return (
              <ExternalLink
                key={link.name}
                {...(!isComingSoon && { href: link.path })}
                disableUnderline
              >
                <AppMenu.MenuItem
                  onClick={handleCloseMenu}
                  disabled={isComingSoon}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    {/* if its path is '', it will be transparent */}
                    <AppTypography variant="body1">{link.name}</AppTypography>
                    {/* if its path is not '', it shows the icon */}
                    {!isComingSoon && (
                      <img
                        src={ExportIcon}
                        width="18px"
                        height="18px"
                        style={{ marginLeft: '4px' }}
                      />
                    )}
                  </Box>
                </AppMenu.MenuItem>
              </ExternalLink>
            )
          })}
        </AppMenu>
      </Toolbar>
    </Container>
  )
}

export default NarbarContainer
