import { Box, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import InfoBox from '../../../components/InfoBox/InfoBox'

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface TextProps {
  textColor?: string | undefined
  fontSize?: string | undefined
}

export const Title = styled(Typography)<TextProps>`
  ${({ theme, textColor, fontSize }) => css`
    color: ${textColor != null ? textColor : '#999'};
    font-size: ${fontSize != null ? fontSize : '0.8125rem'};
    @media only screen and (max-width: ${theme.breakpoints.values.md - 1}px) {
      width: 100px;
      text-align: right;
    }
  `}
`

export const Content = styled(Typography)<TextProps>`
  ${({ textColor, fontSize }: TextProps) => css`
    color: ${textColor != null ? textColor : '#999'};
    font-size: ${fontSize != null ? fontSize : '0.8125rem'};
    display: inline-flex;
  `}
`

export const Tooltip = styled(InfoBox.Tooltip)`
  color: #ffffff;
`
