import { alpha, Box, List, ListItemButton } from '@mui/material'
import styled, { css } from 'styled-components'
import Input from '../../Input/Input'

export const FilterInput = styled(Input)`
  ${({ theme }) => css`
    background: ${alpha(theme.palette.primary[500], 0.2)};
    margin: 0 0 8px;
  `}
`

export const ItemList = styled(List)`
  max-height: 400px;
  overflow-y: scroll;
  /* revert the modal's left / right padding */
  margin-left: calc(-1 * var(--modal-body-padding));
  margin-right: calc(-1 * var(--modal-body-padding));
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`
interface ItemWrapperProps {
  $isSelected?: boolean
  $styleForSelectedItem?: { color?: string; transparent?: boolean }
}

export const ItemWrapper = styled(ListItemButton)<ItemWrapperProps>`
  ${({ $isSelected, $styleForSelectedItem }) => css`
    padding-left: var(--modal-body-padding);
    padding-right: var(--modal-body-padding);
    display: flex;
    flex-direction: row;
    ${$isSelected &&
    css`
      background-color: unset;
      ${(!$styleForSelectedItem || $styleForSelectedItem.transparent) &&
      css`
        opacity: 0.5;
      `}
      ${$styleForSelectedItem?.color &&
      css`
        color: ${$styleForSelectedItem.color};
      `}
    `}
  `}
`

export const ItemLeadingLabelWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding-right: 8px;
  margin: 4px 0;
`

export const ItemTrailingLabelWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  margin: 4px 0 4px auto;
`

export const BottomNoticeWrapper = styled(List)`
  padding: 0;
  /* revert the modal's left / right / bottom padding */
  margin-left: calc(-1 * var(--modal-body-padding));
  margin-right: calc(-1 * var(--modal-body-padding));
  margin-bottom: calc(-1 * var(--modal-body-padding-lg));
`
