import { getDpFormat } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import AppTypography from '../AppTypography/AppTypography'
import TokenIcon from '../TokenIcon/TokenIcon'

interface Props {
  children: React.ReactNode
  minWidth?: string
}

function TooltipText({ children, minWidth }: Props): ReactElement {
  return <div style={{ minWidth: minWidth }}>{children}</div>
}

export default TooltipText

interface TooltipTextMessageProps {
  message: string
}
function TooltipTextMessage({
  message,
}: TooltipTextMessageProps): ReactElement {
  return <>{message}</>
}

const TokenSymbolValues = Object.values(TokenSymbol)
interface TooltipTextRewardItemProps {
  title?: string
  value: string
  isBold?: boolean
  fullName?: string
  iconPath?: string
  isApr?: boolean
}

function TooltipTextRewardItem({
  title,
  value,
  isBold = true,
  iconPath,
  isApr = false,
}: TooltipTextRewardItemProps): ReactElement {
  const isTokenSymbol = TokenSymbolValues.includes(title as TokenSymbol)

  function getFormattedValue() {
    if (isApr) {
      if (value === '-') {
        return '-'
      } else {
        return getDpFormat(value ?? '0.0', 2, 'down', value !== '0.0') + '%'
      }
    }
    return value
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {isTokenSymbol || iconPath ? (
        <>
          <Box display="flex" flexDirection="row" alignItems="center">
            <TokenIcon
              iconPath={iconPath}
              tokenSymbol={
                !iconPath && title ? (title as TokenSymbol) : undefined
              }
              size={16}
              margin="0 4px 0 0"
            />

            {title}
          </Box>

          {getFormattedValue()}
        </>
      ) : (
        <>
          {isBold ? <b>{title}</b> : <span>{title}</span>}
          {isBold ? (
            <b>{getFormattedValue()}</b>
          ) : (
            <span>{getFormattedValue()}</span>
          )}
        </>
      )}
    </Box>
  )
}

interface TooltipTextGroupProps {
  header?: string
  children: React.ReactNode
  transparent?: boolean
  isBold?: boolean
}
function TooltipTextGroup({
  header,
  children,
  transparent = true,
  isBold = false,
}: TooltipTextGroupProps): ReactElement {
  return (
    <Box display="flex" flexDirection="column" margin="4px 0">
      {header && (
        <AppTypography transparent={transparent} variant="inherit">
          {isBold ? <b>{header}</b> : header}
        </AppTypography>
      )}
      {children}
    </Box>
  )
}

TooltipText.Message = React.memo(TooltipTextMessage)
TooltipText.RewardItem = React.memo(TooltipTextRewardItem)
TooltipText.Group = React.memo(TooltipTextGroup)
