import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'

export const Container = styled(Box)`
  ${({ theme }) => css`
    display: grid;
    justify-items: center;
    width: 100%;
    background: ${alpha(theme.palette.accents.yellow, 0.1)};
    border: 1px solid ${theme.palette.accents.yellow};
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 24px 24px 32px;
    grid-gap: 12px;
    .MigrationAlert__text {
      color: ${theme.palette.accents.yellow};
      padding: 0 24px;
      text-align: center;
    }

    .MigrationAlert__button {
      padding: 6px 32px;
      img {
        margin-left: 4px;
      }
    }
  `}
`
