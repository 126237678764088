import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import ExportIcon from '../../../assets/icons/export-icon.svg'
import { ExternalLink } from '../../../globalStyles'
import AppTypography from '../../AppTypography/AppTypography'
import AppButton from '../../buttons/AppButton/AppButton'
import InfoBox from '../../InfoBox/InfoBox'
import { Container, InfoItem } from './TpYieldCard.elements'
interface TpYieldCardProps {
  iconPath: string
  name: string | ReactElement
  link: string
  information: {
    [key in string]: {
      data: string | ReactElement
      Tooltip?: string | ReactElement
    }
  }
}

function TpYieldCard({
  iconPath,
  name,
  link,
  information,
}: TpYieldCardProps): ReactElement {
  return (
    <Container>
      {/* Left Container */}
      <Box
        display="flex"
        flexDirection="column"
        flex={0.5}
        alignItems="flex-end"
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src={iconPath} width="36px" height="36px" />
          <ExternalLink href={link} disableUnderline>
            <AppButton
              customVariant="neutral"
              style={{
                padding: '0px 18px',
                marginTop: '10px',
              }}
            >
              <AppTypography variant="caption2">Farm</AppTypography>
              <img
                src={ExportIcon}
                width="14px"
                height="14px"
                style={{ marginLeft: '4px' }}
              />
            </AppButton>
          </ExternalLink>
        </Box>
      </Box>
      {/* Right Container */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flex={0.5}
        ml="10px"
      >
        <AppTypography marginBottom="4px">{name}</AppTypography>
        {Object.entries(information).map((keyValueArr, idx) => (
          <InfoItem key={idx}>
            <AppTypography
              transparent
              variant="caption2"
              className="tpYieldCard__info-title"
            >
              {keyValueArr[0]}
            </AppTypography>
            <InfoBox.Item justifyContent="flex-start">
              <AppTypography variant="caption2">
                {keyValueArr[1].data}
              </AppTypography>
              {keyValueArr[1].Tooltip && (
                <InfoBox.Tooltip text={keyValueArr[1].Tooltip} />
              )}
            </InfoBox.Item>
          </InfoItem>
        ))}
      </Box>
    </Container>
  )
}

export default TpYieldCard
