import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { BigNumber } from 'ethers'
import { useState } from 'react'
import { MASTER_PLATYPUS } from '../../config/contracts'
import { MasterPlatypusId } from '../../config/contracts/masterPlatypus/MasterPlatypus'
import { POOLS } from '../../config/contracts/pool'
import { PoolSymbol } from '../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { MASTER_PLATYPUS_V3_ABI } from '../../config/contracts/wagmiAbis/MasterPlatypusV3'
import { useModal } from '../../contexts/ModalContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useStakeLpData } from '../../contexts/StakeLpDataContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'
import { isUserDeniedTransaction } from '../../utils/contract'
import useRevertReason from '../useRevertReason'
type RewardData = {
  transactionHash: string
  rewardTokenSymbols: TokenSymbol[]
}
type HandlerProps = {
  poolSymbols: PoolSymbol[]
  assetTokenSymbol?: TokenSymbol
  requiredTransactionSubmittedModal?: boolean
}
type Props = {
  isWaiting: boolean
  isDone: boolean
  handleClaimRewards: (props: HandlerProps) => Promise<RewardData | null>
}
const useClaimRewards = (): Props => {
  const { chainId } = useWeb3()
  const getRevertReason = useRevertReason()
  const [isWaiting, setIsWaiting] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const { rewards } = useStakeLpData()
  const { showMessage } = useSnackbar()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()

  /**
   * @param {PoolSymbol[]} poolSymbols if tokenSymbol is passed, it will always choose the first poolSymbol.
   * @param {TokenSymbol} assetTokenSymbol the token in which the user try to claim reward for. claim all pool asset if tokenSymbol is undefined
   * @param {boolean} requiredTransactionSubmittedModal determine
   */
  const handleClaimRewards = async ({
    poolSymbols,
    assetTokenSymbol,
    requiredTransactionSubmittedModal = true,
  }: HandlerProps) => {
    let rewardData: RewardData | null = null
    if (poolSymbols.length === 0) return rewardData
    let writeContractResult: WriteContractResult | undefined
    const masterPlatypusIdInEachPool = poolSymbols.reduce(
      (prev, poolSymbol) => {
        const curMasterPlatypusId = POOLS[poolSymbol].masterPlatypusId
        if (prev.includes(curMasterPlatypusId)) {
          return prev
        } else {
          return [...prev, curMasterPlatypusId]
        }
      },
      [] as MasterPlatypusId[],
    )
    if (masterPlatypusIdInEachPool.length != 1) {
      console.error(
        `There are ${masterPlatypusIdInEachPool.length} masterPlatypus!`,
      )
      return rewardData
    }
    if (!rewards) return rewardData
    const masterPlatypus = MASTER_PLATYPUS[masterPlatypusIdInEachPool[0]]
    // claim one pid pool or claim multi pid pools
    try {
      //claim one pid pool
      let pidsBN: BigNumber[] = []
      let rewardTokenSymbols: TokenSymbol[] = []
      if (assetTokenSymbol) {
        const pool = POOLS[poolSymbols[0]]
        const pid = pool.assets[assetTokenSymbol]?.pids[chainId]
        if (pid === undefined) return rewardData
        pidsBN = [BigNumber.from(pid)]
        rewardTokenSymbols =
          pool.assets[assetTokenSymbol]?.rewards.map(
            (rewardData) => rewardData.tokenSymbol,
          ) || []
      } else {
        //claim multi pid pools
        for (const poolSymbol of poolSymbols) {
          const pool = POOLS[poolSymbol]
          pidsBN = pidsBN.concat(
            pool.getPidsBNWithTargetMoreThanZero(
              chainId,
              pool.getTotalRewardsEarnedOfEachAsset(rewards),
            ),
          )
          rewardTokenSymbols = rewardTokenSymbols.concat(
            pool.getEarnedRewardTokenSymbols(
              pool.getTotalRewardsEarnedOfEachAsset(rewards),
            ),
          )
        }
      }
      // if no reward in selected pool, nothing happen.
      if (pidsBN.length === 0) return rewardData
      modalDispatch(
        openModal(ModalId.POOL_CLAIM_WAIT_FOR_CONFIRMATION, {
          tokenSymbols: assetTokenSymbol ? [assetTokenSymbol] : undefined,
          poolSymbols,
        }),
      )
      const masterPlatypusAddress = masterPlatypus.address[chainId]
      if (masterPlatypusAddress) {
        const config = await prepareWriteContract({
          address: masterPlatypusAddress,
          abi: MASTER_PLATYPUS_V3_ABI,
          functionName: 'multiClaim',
          args: [pidsBN],
          chainId,
        })
        writeContractResult = await writeContract(config)
        setIsWaiting(true)
        if (writeContractResult) {
          const { hash, wait } = writeContractResult
          await wait()
          if (requiredTransactionSubmittedModal) {
            modalDispatch(
              openModal(ModalId.TRANSACTION_SUBMITTED, {
                transactionHashes: [hash],
                tokenSymbols: Array.from(new Set(rewardTokenSymbols)),
              }),
            )
            showMessage(`Successfully claimed rewards.`)
          }
          setIsDone(true)
          rewardData = {
            transactionHash: hash,
            rewardTokenSymbols: rewardTokenSymbols,
          }
        } else {
          showMessage('Transaction failed.', 'warning')
          modalDispatch(openModal(ModalId.UNSET))
          return rewardData
        }
      }
    } catch (err) {
      if (!isUserDeniedTransaction(err)) {
        Sentry.setContext('contract_call', {
          name: 'claim_rewards',
          token: assetTokenSymbol,
        })
        Sentry.captureException(err)
      }
      const reason = await getRevertReason(err)
      showMessage(reason || 'Transaction failed.', 'warning')
      modalDispatch(openModal(ModalId.UNSET))
    } finally {
      setIsWaiting(false)
    }
    return rewardData
  }
  return { handleClaimRewards, isWaiting, isDone }
}

export default useClaimRewards
