import {
  getPercentageFromTwoWAD,
  isParsableString,
  strToWad,
  sum,
  wmul,
} from '@hailstonelabs/big-number-utils'
import { constants, utils } from 'ethers'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { TOKENS } from '../config/contracts'
import routes from '../config/routes'
import {
  CIRCULATING_SUPPLY_QUERY_FREQUENCY,
  PTP_CIRCULATING_SUPPLY_API,
} from '../constants'
import { Stage } from '../containers/PlatopiaContainer/settings'
import useNftSubgraph from '../hooks/nft/useNftSubgraph'
import { usePoller } from '../hooks/usePoller'
import { NftTypeId, PlatypusNFT, ValueWithNftType } from '../interfaces/nft'
import { PtpFeatureTabId, VePtpStatsDifference } from '../interfaces/vePTP'
import { safeFetchText } from '../utils/common'
import {
  getPlatopiaPopulation,
  getPlatopiaStage,
  getVePtpBalanceAfterUnstakingPtpWithHibernate,
  getVePtpBalancePerMaxPercentage,
  getVePtpStatsDifference,
  getVePtpStatsWithoutAndWithNftEffect,
} from '../utils/vePtp'
import { useBalance } from './BalanceContext'
import { useMulticallData } from './MulticallDataContext'
import { useNetwork } from './NetworkContext'

export interface ContextType {
  ptp: {
    lockTime: {
      maxDays: number
      minDays: number
      // in seconds
      unlockTimestamp: number
      // in seconds
      initialTimestamp: number
    }
    amount: {
      // sum of ptp staked amount, locked amount and balance
      sum: string
      locked: string
      staked: string
    }
    // max: 100
    percent: {
      available: string
      locked: string
      staked: string
    }
    hasStaked: boolean
    hasLocked: boolean
    balance: string
    price: string
    total: {
      circulatingSupply: string
      amount: {
        sum: string
        staked: string
        locked: string
      }
    }
  }
  input: {
    isExcess: {
      stake: boolean
      unStake: boolean
    }
    stakePtpAmount: string
    unstakePtpAmount: string
    stakePtpTotal: {
      stake: string
      unStake: string
    }
    isUnstakedAllPtpWithEquippedNft: boolean
    vePtpAmount: {
      remaining: string
      burnt: string
    }
    isBenefitSectionCollapsed: boolean
    isVePtpSectionCollapsed: boolean
    currentTabId: PtpFeatureTabId
  }
  vePtp: {
    balance: {
      isMoreThanZero: boolean
      total: ValueWithNftType<string>
      locking: string
      staking: ValueWithNftType<string>
    }
    earningPerHour: ValueWithNftType<string>
    maxCap: {
      total: ValueWithNftType<string>
      locking: string
      staking: ValueWithNftType<string>
    }
    maxCapPerPtp: {
      locking: string
      staking: string
    }
    retainPercentage: ValueWithNftType<string> // % of vePTP retain when partial unstake
    pendingAmount: string
    generationRate: { perSecond: string; perDay: string }
    // veptp per max cap
    balancePercentage: number
    totalSupply: string // global
    getBeforeAfterStatsDifference: (
      beforeNft: PlatypusNFT | null,
      afterNft: PlatypusNFT | null,
    ) => VePtpStatsDifference
  }
  nft: {
    equippedId: string | null
    setEquippedId: React.Dispatch<React.SetStateAction<string | null>>
    all: {
      [id in string]: PlatypusNFT
    }
    isVePtpApprovedForAllNfts: boolean
    population: number
    stage: Stage
    isHibernateNft: boolean
  }
  actions: {
    updateStakePtpInputAmount: (value: string) => void
    updateUnstakePtpInputAmount: (value: string) => void
    clearStakePtpInputAmount: () => void
    clearUnstakePtpInputAmount: () => void
    setIsBenefitSectionCollapsed: React.Dispatch<React.SetStateAction<boolean>>
    setIsVePtpSectionCollapsed: React.Dispatch<React.SetStateAction<boolean>>
    handleClickTab: (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      tabId: PtpFeatureTabId,
    ) => void
  }
}

export const initialVePtpContext = {
  /** @todo refactor to total & user info */
  ptp: {
    lockTime: {
      maxDays: 0,
      minDays: 0,
      unlockTimestamp: 0,
      initialTimestamp: 0,
    },
    amount: {
      // sum of ptp staked amount, locked amount and balance
      sum: '0.0',
      locked: '0.0',
      staked: '0.0',
    },
    // max: 100
    percent: {
      available: '100.0',
      locked: '0.0',
      staked: '0.0',
    },
    hasStaked: false,
    hasLocked: false,
    unlockable: false,
    balance: '0.0',
    price: '0.0',
    total: {
      stakedAndLockedAmount: '0',
      circulatingSupply: '0.0',
      amount: {
        sum: '0.0',
        staked: '0.0',
        locked: '0.0',
      },
    },
  },
  input: {
    isExcess: {
      stake: false,
      unStake: false,
    },
    stakePtpAmount: '',
    unstakePtpAmount: '',
    stakePtpTotal: {
      stake: '0.0',
      unStake: '0.0',
    },
    isUnstakedAllPtpWithEquippedNft: false,
    vePtpAmount: {
      remaining: '0.0',
      burnt: '0.0',
    },
    isBenefitSectionCollapsed: false,
    isVePtpSectionCollapsed: false,
    currentTabId: PtpFeatureTabId.LOCK,
  },
  vePtp: {
    balance: {
      isMoreThanZero: false,
      total: { original: '0.0', current: '0.0' },
      locking: '0.0',
      staking: { original: '0.0', current: '0.0' },
    },
    pendingAmount: '0.0',
    earningPerHour: { original: '0.0', current: '0.0' },
    maxCap: {
      total: { original: '0.0', current: '0.0' },
      locking: '0.0',
      staking: { original: '0.0', current: '0.0' },
    },
    maxCapPerPtp: {
      locking: '0',
      staking: '0',
    },
    retainPercentage: { original: '0.0', current: '0.0' },
    generationRate: { perSecond: '0.0', perDay: '0.0' },
    balancePercentage: 0,
    totalSupply: '0.0',
    getBeforeAfterStatsDifference: () => ({}),
  },
  nft: {
    equippedId: null,
    setEquippedId: () => {
      /* empty */
    },
    all: {},
    isVePtpApprovedForAllNfts: false,
    population: 0,
    stage: Stage.stage1,
    isHibernateNft: false,
  },
  actions: {
    updateStakePtpInputAmount: () => {
      /* empty */
    },
    updateUnstakePtpInputAmount: () => {
      /* empty */
    },
    clearStakePtpInputAmount: () => {
      /* empty */
    },
    clearUnstakePtpInputAmount: () => {
      /* empty */
    },
    setIsBenefitSectionCollapsed: () => {
      /* empty */
    },
    setIsVePtpSectionCollapsed: () => {
      /* empty */
    },
    handleClickTab: () => {
      /* empty */
    },
  },
} as ContextType

export const VePtpContext = createContext<ContextType>(initialVePtpContext)
VePtpContext.displayName = 'VePtpContext'

export const useVePtp = (): ContextType => {
  return useContext(VePtpContext)
}

interface Props {
  children: React.ReactNode
}

function VePtpProvider({ children }: Props): ReactElement {
  // for image preload
  const {
    veptpData: { withoutAccount, withAccount },
    isMulticallDataFetched,
  } = useMulticallData()

  const { account } = useNetwork()

  const preloadedNftIds = useRef<string[]>([])
  const { tokenPrices, tokenAmounts } = useBalance()
  const { fetchNfts } = useNftSubgraph()
  // inputs
  const [stakePtpInputAmount, setStakePtpInputAmount] = useState(
    initialVePtpContext.input.stakePtpAmount,
  )
  const [unstakePtpInputAmount, setUnstakePtpInputAmount] = useState(
    initialVePtpContext.input.unstakePtpAmount,
  )

  const [isBenefitSectionCollapsed, setIsBenefitSectionCollapsed] =
    useState(false)

  // ownedNfts: NFTs owned by the users, not equipped
  const [ownedNfts, setOwnedNfts] = useState<{ [id: string]: PlatypusNFT }>({})
  // equippedNft: the NFT that is equipped. null means no NFT is equipped
  const [equippedId, setEquippedId] = useState<string | null>(null)
  const [equippedNft, setEquippedNft] = useState<PlatypusNFT | null>(null)
  const [ptpCirculatingSupply, setPtpCirculatingSupply] = useState<string>('')
  const [isVePtpSectionCollapsed, setIsVePtpSectionCollapsed] = useState(false)

  const location = useLocation()
  const isNftDataRequired =
    location.pathname.includes(routes.STAKE.path) ||
    location.pathname.includes(routes.PLATOPIA.path)

  //Switch Tab between Stake & Lock
  const [currentTabId, setCurrentTabId] = useState<PtpFeatureTabId>(
    PtpFeatureTabId.LOCK,
  )

  //collapse benefit session account to the login status
  useEffect(() => {
    setIsBenefitSectionCollapsed(!!account)
  }, [account])

  //collapse veptp session account according to the login status
  useEffect(() => {
    setIsVePtpSectionCollapsed(!!account)
  }, [account])

  // update equippedNft when equippedId is set to null
  useEffect(() => {
    if (!equippedId) {
      setEquippedNft(null)
    }
  }, [equippedId])

  const fetchCirculatingSupply = async () => {
    const ptpCirculatingSupplyResult = await safeFetchText(
      PTP_CIRCULATING_SUPPLY_API,
    )
    if (ptpCirculatingSupplyResult) {
      setPtpCirculatingSupply(ptpCirculatingSupplyResult)
    }
  }

  const fetchNftData = async () => {
    // Equipped and owned NFT data
    // if an NFT is equipped. get its data
    const equippedNftIdBN = withAccount?.equippedNftIdBN
    if (equippedNftIdBN) {
      const equippedNftId = equippedNftIdBN.toString()
      setEquippedId(equippedNftId)
      const fetchedResult = await fetchNfts(equippedNftId)
      if (fetchedResult && Object.values(fetchedResult).length >= 1) {
        setEquippedNft(Object.values(fetchedResult)[0])
      }
    } else {
      setEquippedId(null)
    }
    // get owned (not equipped) NFTs.
    const nftsOwnedButNotEquipped = await fetchNfts()
    if (nftsOwnedButNotEquipped) {
      setOwnedNfts(nftsOwnedButNotEquipped)
    }
  }

  usePoller(
    () => {
      void fetchCirculatingSupply().catch((err) => console.error(err))
    },
    [isMulticallDataFetched],
    CIRCULATING_SUPPLY_QUERY_FREQUENCY,
  )

  usePoller(() => {
    if (isNftDataRequired) {
      void fetchNftData().catch((err) => console.error(err))
    }
  }, [isMulticallDataFetched, location.pathname])

  const updateStakePtpInputAmount = (value: string) => {
    if (!isParsableString(value, TOKENS.PTP.decimals, true) && value !== '')
      return
    setStakePtpInputAmount(value)
  }

  const clearStakePtpInputAmount = useCallback(() => {
    setStakePtpInputAmount('')
  }, [])

  const updateUnstakePtpInputAmount = (value: string) => {
    if (!isParsableString(value, TOKENS.PTP.decimals, true) && value !== '')
      return
    setUnstakePtpInputAmount(value)
  }

  const clearUnstakePtpInputAmount = useCallback(() => {
    setUnstakePtpInputAmount('')
  }, [])

  const handleClickTab = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    tabId: PtpFeatureTabId,
  ) => {
    e.preventDefault()
    setCurrentTabId(tabId)
  }
  const myTotalPtpAmountWad = strToWad(withAccount?.lockedPtp)
    .add(strToWad(withAccount?.stakedPtp))
    .add(strToWad(tokenAmounts.PTP))
  const myAvailablePtpAmountWad = strToWad(tokenAmounts.PTP)
  const myLockedPtpAmountWad = strToWad(withAccount?.lockedPtp)
  const myStakedPtpAmountWad = strToWad(withAccount?.stakedPtp)

  const newPtpData: ContextType['ptp'] = {
    hasStaked: !strToWad(withAccount?.stakedPtp).isZero(),
    hasLocked: !strToWad(withAccount?.lockedPtp).isZero(),
    balance: tokenAmounts.PTP,
    price: tokenPrices.PTP,
    total: {
      circulatingSupply: ptpCirculatingSupply,
      amount: {
        sum: withoutAccount?.ptpBalanceOfVePtpContract ?? '0',
        staked: utils.formatEther(
          strToWad(withoutAccount?.ptpBalanceOfVePtpContract ?? '0').sub(
            strToWad(withoutAccount?.totalLockedPtpAmount ?? '0'),
          ),
        ),
        locked: withoutAccount?.totalLockedPtpAmount ?? '0',
      },
    },
    lockTime: {
      maxDays: withoutAccount?.maxLockDays ?? 357,
      minDays: withoutAccount?.minLockDays ?? 7,
      unlockTimestamp: withAccount?.unlockTimestamp || 0,
      initialTimestamp: withAccount?.initialLockTimestamp || 0,
    },
    amount: {
      sum: utils.formatEther(myTotalPtpAmountWad),
      locked: withAccount?.lockedPtp || '0.0',
      staked: withAccount?.stakedPtp || '0.0',
    },
    percent: {
      available: getPercentageFromTwoWAD(
        myAvailablePtpAmountWad,
        myTotalPtpAmountWad,
      ),
      locked: getPercentageFromTwoWAD(
        myLockedPtpAmountWad,
        myTotalPtpAmountWad,
      ),
      staked: getPercentageFromTwoWAD(
        myStakedPtpAmountWad,
        myTotalPtpAmountWad,
      ),
    },
  }

  const vePtpStatsWithoutAndWithNftEffect =
    getVePtpStatsWithoutAndWithNftEffect({
      currentVePtpTotalBalance: utils.formatEther(
        withAccount?.vePtpBalanceWAD ?? constants.Zero,
      ),
      generationRatePerSecond: utils.formatEther(
        withoutAccount?.generationRatePerSecondWAD ?? constants.Zero,
      ),
      stakedPtpAmount: newPtpData.amount.staked,
      // nft only affect maxVePtpCapPerPtpFromStaking
      maxVePtpCapPerPtpForStaking:
        withoutAccount?.maxVePtpCapPerPtpFromStaking ?? '0',
      targetNft: equippedNft,
    })

  const generationRatePerSecondStr = utils.formatEther(
    withoutAccount?.generationRatePerSecondWAD ?? constants.Zero,
  )
  const maxVePtpCapPerPtpForStakingStr = withoutAccount
    ? withoutAccount.maxVePtpCapPerPtpFromStaking
    : ''

  const maxVePtpCapForLockingWad = wmul(
    strToWad(withoutAccount?.maxVePtpCapPerPtpFromLocking),
    strToWad(withAccount?.lockedPtp),
  )

  const getBeforeAfterStatsDifference = (
    beforeNft: PlatypusNFT | null,
    afterNft: PlatypusNFT | null,
  ): VePtpStatsDifference => {
    return getVePtpStatsDifference({
      generationRatePerSecond: generationRatePerSecondStr,
      maxVePtpCapPerPtpForStaking: maxVePtpCapPerPtpForStakingStr,
      originalVePtpTotalBalance:
        vePtpStatsWithoutAndWithNftEffect.vePtpTotalBalance.original,
      stakedPtpAmount: newPtpData.amount.staked,
      currentNft: beforeNft,
      nftToBeEquipped: afterNft,
    })
  }

  const newVePtpData: ContextType['vePtp'] = {
    generationRate: {
      perSecond: generationRatePerSecondStr,
      perDay: (Number(generationRatePerSecondStr) * 24 * 60 * 60).toString(),
    },
    totalSupply: utils.formatEther(
      withoutAccount?.totalVePtpSupplyWAD ?? constants.Zero,
    ),
    pendingAmount: utils.formatEther(
      withAccount?.pendingVePtpWAD ?? constants.Zero,
    ),
    earningPerHour: vePtpStatsWithoutAndWithNftEffect.vePtpEarningPerHour,
    retainPercentage: vePtpStatsWithoutAndWithNftEffect.retainPercentage,
    balancePercentage: getVePtpBalancePerMaxPercentage(
      vePtpStatsWithoutAndWithNftEffect.vePtpTotalBalance.current,
      sum(
        vePtpStatsWithoutAndWithNftEffect.maxVePtpCapForStaking.current,
        maxVePtpCapForLockingWad,
      ),
    ),
    getBeforeAfterStatsDifference,
    balance: {
      isMoreThanZero: strToWad(
        vePtpStatsWithoutAndWithNftEffect.vePtpTotalBalance.current,
      ).gt('0'),
      total: vePtpStatsWithoutAndWithNftEffect.vePtpTotalBalance,
      locking: withAccount?.vePtpBalanceFromLocking || '0.0',
      staking: {
        original: utils.formatEther(
          strToWad(
            vePtpStatsWithoutAndWithNftEffect.vePtpTotalBalance.original,
          ).sub(strToWad(withAccount?.vePtpBalanceFromLocking || '0.0')),
        ),
        current: utils.formatEther(
          strToWad(
            vePtpStatsWithoutAndWithNftEffect.vePtpTotalBalance.current,
          ).sub(strToWad(withAccount?.vePtpBalanceFromLocking || '0.0')),
        ),
      },
    },
    maxCapPerPtp: {
      locking: withoutAccount?.maxVePtpCapPerPtpFromLocking || '0',
      staking: withoutAccount?.maxVePtpCapPerPtpFromStaking || '0',
    },
    maxCap: {
      total: {
        current: utils.formatEther(
          strToWad(
            vePtpStatsWithoutAndWithNftEffect.maxVePtpCapForStaking.current,
          ).add(maxVePtpCapForLockingWad),
        ),
        original: utils.formatEther(
          strToWad(
            vePtpStatsWithoutAndWithNftEffect.maxVePtpCapForStaking.original,
          ).add(maxVePtpCapForLockingWad),
        ),
      },
      locking: utils.formatEther(maxVePtpCapForLockingWad),
      staking: vePtpStatsWithoutAndWithNftEffect.maxVePtpCapForStaking,
    },
  }

  const processedStakePtpInputAmount = stakePtpInputAmount
    ? stakePtpInputAmount
    : '0'

  const processedUnstakePtpInputAmount = unstakePtpInputAmount
    ? unstakePtpInputAmount
    : '0'

  // Combine ownedNfts, equippedNft into allNfts
  const allNfts = { ...ownedNfts }
  if (equippedNft && equippedId) {
    allNfts[equippedId] = equippedNft
  }

  const newNftData: ContextType['nft'] = {
    equippedId,
    setEquippedId,
    all: allNfts,
    isVePtpApprovedForAllNfts: withAccount?.isVePtpApprovedForAllNfts ?? false,
    population: getPlatopiaPopulation(strToWad(withAccount?.stakedPtp)),
    stage: getPlatopiaStage(newVePtpData.balancePercentage),
    isHibernateNft:
      equippedId && allNfts[equippedId]
        ? allNfts[equippedId].type === NftTypeId.HIBERNATE
        : false,
  }

  const input: ContextType['input'] = {
    isExcess: {
      stake: strToWad(processedStakePtpInputAmount).gt(
        strToWad(newPtpData.balance),
      ),
      unStake: strToWad(processedUnstakePtpInputAmount).gt(
        strToWad(withAccount?.stakedPtp),
      ),
    },
    stakePtpAmount: stakePtpInputAmount,
    unstakePtpAmount: unstakePtpInputAmount,
    stakePtpTotal: {
      stake: sum(
        strToWad(withAccount?.stakedPtp),
        processedStakePtpInputAmount,
      ),
      unStake: utils.formatEther(
        strToWad(withAccount?.stakedPtp).sub(
          strToWad(processedUnstakePtpInputAmount),
        ),
      ),
    },
    isUnstakedAllPtpWithEquippedNft: !!(
      strToWad(unstakePtpInputAmount).eq(strToWad(newPtpData.amount.staked)) &&
      newNftData.equippedId
    ),
    isBenefitSectionCollapsed,
    isVePtpSectionCollapsed,
    currentTabId,
    vePtpAmount: (() => {
      const isUnstakeMoreThanZero = strToWad(unstakePtpInputAmount).gt(
        constants.Zero,
      )
      let burntVePtpAmount = isUnstakeMoreThanZero
        ? newVePtpData.balance.staking.current
        : '0.0'
      let remainingVePtpAmount = isUnstakeMoreThanZero
        ? newVePtpData.balance.locking
        : newVePtpData.balance.total.current
      // logic for hibernate user
      if (
        equippedId &&
        newNftData.all[equippedId]?.type === NftTypeId.HIBERNATE &&
        isUnstakeMoreThanZero
      ) {
        const hibernateValue = newNftData.all[equippedId]?.value || ''
        const unstakePtpAmount = unstakePtpInputAmount
        const stakedPtpAmount = newPtpData.amount.staked
        const currntVePtpBalanceFromStaking =
          newVePtpData.balance.staking.current
        const data = getVePtpBalanceAfterUnstakingPtpWithHibernate({
          hibernateValue,
          unstakePtpAmount,
          stakedPtpAmount,
          vePtpBalance: currntVePtpBalanceFromStaking,
        })
        if (data) {
          // data.remaining is only for staking.
          remainingVePtpAmount = utils.formatEther(
            strToWad(data.remaining).add(
              strToWad(newVePtpData.balance.locking),
            ),
          )
          burntVePtpAmount = data.burnt
        }
      }
      return {
        remaining: remainingVePtpAmount,
        burnt: burntVePtpAmount,
      }
    })(),
  }

  useEffect(() => {
    const nftIds = Object.keys(newNftData.all)
    for (let i = 0; i < nftIds.length; i++) {
      const nftId = nftIds[i]
      // if id did not include in preloadedNftIds -> preload
      if (!preloadedNftIds.current.includes(nftId)) {
        const img = new Image()
        img.src = newNftData.all[nftId].imgSrc
        preloadedNftIds.current = [...preloadedNftIds.current, nftId]
      }
    }
  }, [newNftData.all])
  return (
    <VePtpContext.Provider
      value={{
        ptp: newPtpData,
        input,
        vePtp: newVePtpData,
        nft: newNftData,
        actions: {
          updateStakePtpInputAmount,
          clearStakePtpInputAmount,
          updateUnstakePtpInputAmount,
          clearUnstakePtpInputAmount,
          setIsBenefitSectionCollapsed,
          setIsVePtpSectionCollapsed,
          handleClickTab,
        },
      }}
    >
      {children}
    </VePtpContext.Provider>
  )
}

export default VePtpProvider
