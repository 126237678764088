import {
  getCommifiedFormat,
  getSfFormat,
  safeWdiv,
  strToWad,
  WAD,
} from '@hailstonelabs/big-number-utils'
import { SwapHoriz } from '@mui/icons-material'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import { Box } from '@mui/material'
import { constants, utils } from 'ethers'
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import AppTypography from '../../components/AppTypography/AppTypography'
import InfoBox from '../../components/InfoBox/InfoBox'
import { NonSelectedSpan } from '../../components/InfoBox/InfoBox.element'
import TooltipNum from '../../components/InfoBox/TooltipNum'
import TokenIcon from '../../components/TokenIcon/TokenIcon'
import { TOKENS } from '../../config/contracts/token'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { usePools } from '../../contexts/PoolsContext'
import { useSwap } from '../../contexts/SwapContext'
import useBreakpoints from '../../hooks/useBreakpoints'
import { Route, RouteItem } from './SwapInfoBoxContainer.elements'

interface Props {
  hideFee?: boolean
  paddingBottom?: string
  route?: TokenSymbol[]
}
function SwapInfoBoxContainer({
  hideFee = false,
  paddingBottom,
  route,
}: Props): ReactElement {
  const { isMobileLg } = useBreakpoints()
  const {
    minimumReceived,
    fromTokenAmount,
    toTokenAmount,
    fromTokenSymbol,
    toTokenSymbol,
    feeWad,
    priceImpactWAD,
    isRouter,
    poolSymbolForSwap,
    isWrappingAvaxToWavax,
    isUnwrappingWavaxToAvax,
  } = useSwap()
  // Default rate: 1[From token symbol] = x [To token symbol]
  const [isDefaultDirection, setIsDefaultDirection] = useState(true)
  const { swapData } = usePools()

  /** @todo maybe rename poolSymbolForSwap to poolSymbolForIntraSwap */
  // if poolSymbolForSwap is undefined, router is used.
  const haircutRateWad =
    poolSymbolForSwap &&
    swapData[poolSymbolForSwap] &&
    swapData[poolSymbolForSwap].haircutRateWad
  const haircutRateInPercentage =
    haircutRateWad && Number(utils.formatEther(haircutRateWad)) * 100
  const isHaircutRateInPercentageShown = !isRouter && !!haircutRateInPercentage

  const feeStr = utils.formatEther(feeWad)

  const infoData = useMemo(
    () => ({
      PRICE: {
        label: 'Rate (after fee)',
        tooltip: 'The rate offered to you for this transaction, after fee.',
      },
      FEE: {
        label: 'Fee',
        tooltip: isHaircutRateInPercentageShown
          ? `A ${haircutRateInPercentage}% transaction fee is charged. This fee is already reflected in the To amount displayed.`
          : 'This fee is already reflected in the To amount displayed.',
      },
      MINIMUM_RECEIVED: {
        label: 'Minimum Received',
        tooltip:
          "Your transaction will revert if you're unable to receive at least this amount due to slippage. Adjust this amount in the swap slippage tolerance % settings.",
      },
      PRICE_IMPACT: {
        label: 'Price Impact',
        tooltip:
          'The difference between the current market price and the quoted price due to trade size.',
      },
      ROUTE: {
        label: 'Route',
        tooltip: 'The following route is used for this trade.',
      },
    }),
    [haircutRateInPercentage, isHaircutRateInPercentageShown],
  )
  const exchangeRateWad = useMemo(() => {
    let computedExchangeRateWad = constants.One
    if (isDefaultDirection) {
      computedExchangeRateWad = safeWdiv(
        strToWad(toTokenAmount),
        strToWad(fromTokenAmount),
      )
    } else {
      computedExchangeRateWad = safeWdiv(
        strToWad(fromTokenAmount),
        strToWad(toTokenAmount),
      )
    }
    return computedExchangeRateWad
    // only update when the toTokenAmount is updated
    // eslint-disable-next-line
  }, [toTokenAmount, isDefaultDirection])

  const getPriceImpact = useCallback(() => {
    let color = 'unset'
    const displayValue = getCommifiedFormat(priceImpactWAD)
    const isGreaterThanOne = priceImpactWAD.gt(WAD)
    // use red color for high price impact (> 1%)
    if (isGreaterThanOne) {
      color = '#F44336'
    }
    return {
      displayValue,
      color,
    }
  }, [priceImpactWAD])

  const { displayValue: priceImpactValue, color: priceImpactColor } =
    getPriceImpact()

  const rate = useMemo(
    () => (
      <InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            {infoData.PRICE.label}
          </AppTypography>
          <InfoBox.Tooltip
            text={infoData.PRICE.tooltip}
            placement={isMobileLg ? 'top' : 'right'}
          />
        </InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            {/* display 6 s.f. For example (1 DAI.e: 1.00330 USDT.e) */}
            <b>
              1 {isDefaultDirection ? fromTokenSymbol : toTokenSymbol} ={' '}
              {getSfFormat(utils.formatEther(exchangeRateWad), 6)}{' '}
              {isDefaultDirection ? toTokenSymbol : fromTokenSymbol}
            </b>
          </AppTypography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsDefaultDirection(!isDefaultDirection)
            }}
          >
            <SwapHoriz style={{ margin: '0 0 0 6px', fontSize: '20px' }} />
          </Box>
        </InfoBox.Item>
      </InfoBox.Item>
    ),
    [
      exchangeRateWad,
      fromTokenSymbol,
      infoData.PRICE.label,
      infoData.PRICE.tooltip,
      isDefaultDirection,
      isMobileLg,
      toTokenSymbol,
    ],
  )

  /**
   * For AVAX <-> WAVAX
   */
  if (isWrappingAvaxToWavax || isUnwrappingWavaxToAvax) {
    return (
      <InfoBox paddingBottom={paddingBottom}>
        {rate}
        <InfoBox.Item>
          <InfoBox.Item>
            <AppTypography variant="caption">
              {toTokenSymbol} to be Received
            </AppTypography>
          </InfoBox.Item>
          <InfoBox.Item>
            <AppTypography variant="caption">
              {/* display 6 s.f. Show a Tooltip with the exact amount trimmed when hovering over it.  */}
              <b>
                {
                  <TooltipNum
                    rightSymbol={toTokenSymbol as string}
                    amount={toTokenAmount}
                  >
                    {getSfFormat(toTokenAmount, 6)}&nbsp;
                    {toTokenSymbol}
                  </TooltipNum>
                }
              </b>
            </AppTypography>
          </InfoBox.Item>
        </InfoBox.Item>
      </InfoBox>
    )
  }

  /**
   * Others
   */
  return (
    <InfoBox paddingBottom={paddingBottom}>
      {rate}
      {/* Price Impact */}
      <InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            {infoData.PRICE_IMPACT.label}
          </AppTypography>
          <InfoBox.Tooltip
            text={infoData.PRICE_IMPACT.tooltip}
            placement={isMobileLg ? 'top' : 'right'}
          />
        </InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            {/* display 2 d.p. Show a Tooltip with the exact amount trimmed when hovering over it. */}
            <b>
              {
                <TooltipNum rightSymbol={'%'} amount={priceImpactWAD}>
                  <NonSelectedSpan style={{ color: priceImpactColor }}>
                    {priceImpactValue} %
                  </NonSelectedSpan>
                </TooltipNum>
              }
            </b>
          </AppTypography>
        </InfoBox.Item>
      </InfoBox.Item>
      {!hideFee && (
        <InfoBox.Item>
          <InfoBox.Item>
            <AppTypography variant="caption">
              {infoData.FEE.label}
            </AppTypography>
            <InfoBox.Tooltip
              text={infoData.FEE.tooltip}
              placement={isMobileLg ? 'top' : 'right'}
            />
          </InfoBox.Item>
          <InfoBox.Item>
            <AppTypography variant="caption">
              {/* display 2 s.f. Show a Tooltip with the exact amount trimmed when hovering over it. If less than 0.01, show " < 0.01 " instead of 2 s.f. */}
              <b>
                {
                  <TooltipNum
                    rightSymbol={toTokenSymbol as string}
                    amount={feeStr}
                  >
                    {getCommifiedFormat(feeStr, 6)} {toTokenSymbol}
                  </TooltipNum>
                }
              </b>
            </AppTypography>
          </InfoBox.Item>
        </InfoBox.Item>
      )}
      <InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            {infoData.MINIMUM_RECEIVED.label}
          </AppTypography>
          <InfoBox.Tooltip
            text={infoData.MINIMUM_RECEIVED.tooltip}
            placement={isMobileLg ? 'top' : 'right'}
          />
        </InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            {/* display 6 s.f. Show a Tooltip with the exact amount trimmed when hovering over it.  */}
            <b>
              {
                <TooltipNum
                  rightSymbol={toTokenSymbol as string}
                  amount={utils.formatUnits(
                    minimumReceived,
                    TOKENS[toTokenSymbol as TokenSymbol].decimals,
                  )}
                >
                  {getSfFormat(
                    utils.formatUnits(
                      minimumReceived,
                      TOKENS[toTokenSymbol as TokenSymbol].decimals,
                    ),
                    6,
                  )}
                  &nbsp;
                  {toTokenSymbol}
                </TooltipNum>
              }
            </b>
          </AppTypography>
        </InfoBox.Item>
      </InfoBox.Item>
      {route && route.length > 0 && (
        <>
          <InfoBox.Item>
            <InfoBox.Item>
              <AppTypography variant="caption">
                {infoData.ROUTE.label}
              </AppTypography>
              <InfoBox.Tooltip
                text={infoData.ROUTE.tooltip}
                placement={isMobileLg ? 'top' : 'right'}
              />
            </InfoBox.Item>
          </InfoBox.Item>
          <Route>
            {route.map((tokenSymbol) => (
              <RouteItem key={tokenSymbol}>
                <TokenIcon
                  tokenSymbol={tokenSymbol}
                  margin="0 4px 0 0"
                  size={16}
                />
                <AppTypography variant="caption">{tokenSymbol}</AppTypography>
                <KeyboardArrowRightRoundedIcon
                  fontSize="small"
                  className="SwapInfoBoxContainer__route-arrow"
                />
              </RouteItem>
            ))}
          </Route>
        </>
      )}
    </InfoBox>
  )
}

export default React.memo(SwapInfoBoxContainer)
