/**
 * If showDash is true, it returns '-'
 * @param {boolean} showDash
 * @param {string} originalValue
 * @returns {string} either a '-' or the originalValue
 *
 */

const showDashIfNecessary = (
  showDash: boolean | null | undefined,
  originalValue: string,
): string => {
  if (showDash) return '-'
  return originalValue
}

export default showDashIfNecessary
