import {
  changeDecimal,
  getDpFormat,
  strToWad,
  wadToNative,
} from '@hailstonelabs/big-number-utils'
import * as Sentry from '@sentry/react'
import { BigNumber, constants, utils } from 'ethers'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { LP_TOKENS } from '../config/contracts'
import { POOLS } from '../config/contracts/pool'
import {
  PoolGroupSymbol,
  PoolSymbol,
} from '../config/contracts/pool/poolSymbol'
import { TOKENS } from '../config/contracts/token'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import {
  calculateMinimumReceived,
  getLpAmountStrFromPercent,
  getToLpTokenAmountByRate,
  getToLpTokenAmountForTpAvaxWithdrawal,
} from '../utils/pool'
import {
  getAnkrEthToWetheRateWad,
  getTpAvaxToAvaxRateWad,
} from '../utils/tpAvax'
import { useBalance } from './BalanceContext'
import { useNetwork } from './NetworkContext'
import { useUserPreference } from './UserPreferenceContext'

type DepositLiquidityInputType = {
  amount: string
  /** For displaying a correct symbol. Some part of the UI requires to show AVAX symbol.etc. Deposited in PoolDepositInfoBox */
  tokenSymbolForDisplay: TokenSymbol | null
  // token is actual token for user selected for deposit or withdraw, defualt is the same as selectedAssetSymbol. etc. when user uncheck withdraw in wavax then, actualToken will be avax and token will be wavax
  tokenSymbol: TokenSymbol | null
}

type WithdrawLiquidityInputType = {
  amount: string
  /** For displaying a correct symbol. Some part of the UI requires to show AVAX symbol.etc. Deposited in PoolDepositInfoBox */
  tokenSymbolForDisplay: TokenSymbol | null
  // token is actual token for user selected for deposit or withdraw, defualt is the same as selectedAssetSymbol. etc. when user uncheck withdraw in wavax then, actualToken will be avax and token will be wavax
  tokenSymbol: TokenSymbol | null
  toTokenSymbol: TokenSymbol | null
  toTokenSymbolForDisplay: TokenSymbol | null
  isWithdrawInOtherTokens: boolean
}

export interface ContextType {
  withdrawMaximumPercentage: string
  withdrawMinimumReceived: BigNumber
  withdrawFee: string
  withdrawTokenAmount: string
  depositLiquidity: DepositLiquidityInputType
  withdrawLiquidity: WithdrawLiquidityInputType
  selectedPoolGroupSymbol: PoolGroupSymbol
  selectedPoolSymbol: PoolSymbol
  /** For fetch data in deposit and withdraw modal. ex. user balance */
  selectedAssetTokenSymbol: TokenSymbol | null
  withdrawalPercentage: string
  actions: {
    initializeDepositLiquidity: (
      poolSymbol: PoolSymbol,
      assetTokenSymbol: TokenSymbol,
    ) => void
    initializeWithdrawLiquidity: (
      poolSymbol: PoolSymbol,
      assetTokenSymbol: TokenSymbol,
    ) => void
    updateWithdrawMaximumPercentage: (percentage: string) => void
    updateAmountFromDepositLiquidity: (amount: string) => void
    updateTokenSymbolFromDepositLiquidity: (tokenSymbol: TokenSymbol) => void
    updateTokenSymbolFromWithdrawLiquidity: (tokenSymbol: TokenSymbol) => void
    clearDepositLiquidity: () => void
    clearWithdrawLiquidity: () => void
    updateSelectedPoolGroupSymbol: (poolGroupSymbol: PoolGroupSymbol) => void
    updateSelectedPoolSymbol: (poolSymbol: PoolSymbol) => void
    updateSelectedAssetTokenSymbol: (
      assetTokenSymbol: TokenSymbol | null,
    ) => void
    updateToTokenSymbolFromWithdrawLiquidity: (
      toTokenSymbol: TokenSymbol | null,
      toTokenSymbolForDisplay?: TokenSymbol,
    ) => void
    updateWithdrawalPercentage: (percentage: string) => void
    toggleIsWithdrawInOtherTokens: () => void
  }
}
export const initalDepositLiquidityInput: DepositLiquidityInputType = {
  amount: '',
  tokenSymbol: null,
  tokenSymbolForDisplay: null,
}

export const initalWithdrawLiquidityInput: WithdrawLiquidityInputType = {
  amount: '',
  tokenSymbol: null,
  tokenSymbolForDisplay: null,
  toTokenSymbol: null,
  toTokenSymbolForDisplay: null,
  isWithdrawInOtherTokens: false,
}

const initalWithdrawQuotation = {
  fee: '0.0',
  tokenAmount: '0.0',
}
const intialPoolContext: ContextType = {
  withdrawMaximumPercentage: '',
  depositLiquidity: initalDepositLiquidityInput,
  withdrawLiquidity: initalWithdrawLiquidityInput,
} as ContextType
export const PoolInputContext = createContext<ContextType>(intialPoolContext)
PoolInputContext.displayName = 'PoolInputContext'

export const usePoolInput = (): ContextType => {
  return useContext(PoolInputContext)
}

interface Props {
  children: React.ReactNode
}

function PoolInputProvider({ children }: Props): ReactElement {
  const { readOnlyProvider, chainId } = useNetwork()
  const quoteCount = useRef<number>(0)
  const { lpTokenAmounts } = useBalance()
  const { userPreference } = useUserPreference()
  const [selectedPoolGroupSymbol, setSelectedPoolGroupSymbol] =
    useState<PoolGroupSymbol>(PoolGroupSymbol.MAIN)
  const [selectedPoolSymbol, setSelectedPoolSymbol] = useState<PoolSymbol>(
    PoolSymbol.MAIN,
  )
  const [selectedAssetTokenSymbol, setSelectedAssetTokenSymbol] =
    useState<TokenSymbol | null>(null)
  const [withdrawMaximumPercentage, setWithdrawMaximumPercentage] =
    useState<string>(intialPoolContext.withdrawMaximumPercentage)
  const [withdrawFee, setWithdrawFee] = useState<string>(
    initalWithdrawQuotation.fee,
  )
  const [withdrawTokenAmount, setWithdrawTokenAmount] = useState<string>(
    initalWithdrawQuotation.tokenAmount,
  )
  const [depositLiquidity, setDepositLiquidity] =
    useState<DepositLiquidityInputType>(initalDepositLiquidityInput)
  const [withdrawLiquidity, setWithdrawLiquidity] =
    useState<WithdrawLiquidityInputType>(initalWithdrawLiquidityInput)
  // 0 to 100 inclusive, BN string in WAD
  const [withdrawalPercentage, setWithdrawalPercentage] = useState<string>('0')
  /**
   * set withdraw fee and set withdraw amount
   * @param {BigNumber} lpAmount
   */
  const updateWithdrawQuotation = useCallback(
    async (lpAmount: BigNumber) => {
      quoteCount.current += 1
      const currentCount = quoteCount.current
      if (lpAmount.isZero()) {
        if (currentCount === quoteCount.current) {
          setWithdrawFee(initalWithdrawQuotation.fee)
          setWithdrawTokenAmount(initalWithdrawQuotation.tokenAmount)
          setWithdrawLiquidity((prevWithdrawLiquidity) => {
            return {
              ...prevWithdrawLiquidity,
              amount: '',
            }
          })
        }
        return
      }
      const pool = POOLS[selectedPoolSymbol].get(chainId, readOnlyProvider)
      let quotation = {
        fee: BigNumber.from('0'),
        amount: BigNumber.from('0'),
      }
      let decimals = 0
      if (withdrawLiquidity.tokenSymbol && pool) {
        let token = TOKENS[withdrawLiquidity.tokenSymbol]
        // withdrawLiquidity.token is AVAX which will determine as wAVAX in quotePotentialWithdraw.
        if (withdrawLiquidity.tokenSymbol === TokenSymbol.AVAX) {
          token = TOKENS[TokenSymbol.WAVAX]
        }
        const tokenAddress = token.getAddress(chainId)
        // token === toToken => normal withdraw
        if (withdrawLiquidity.tokenSymbol === withdrawLiquidity.toTokenSymbol) {
          decimals = token.decimals // use token's decimal
          try {
            if (tokenAddress) {
              quotation = await pool.quotePotentialWithdraw(
                tokenAddress,
                lpAmount,
              )
            }
          } catch (error) {
            Sentry.setContext('contract_call', {
              name: 'pool.quotePotentialWithdraw',
              token_symbol: token.symbol,
              lp_amount: utils.formatUnits(lpAmount, token.decimals),
            })
            Sentry.captureException(error)
            console.error(error)
          }
          // token !== toToken => withdraw from other asset
        } else {
          if (withdrawLiquidity.toTokenSymbol) {
            const toToken = TOKENS[withdrawLiquidity.toTokenSymbol]
            const toTokenAddress = toToken.getAddress(chainId)
            try {
              if (toTokenAddress && tokenAddress) {
                /** Third party avax (tpAvax): withdrawl quotation */
                // Check to token rate if PoolSymbol in third party Avax(tpAvax) pool
                if (POOLS[selectedPoolSymbol].isTpAvaxPool) {
                  const tpAvaxToAvaxRate = await getTpAvaxToAvaxRateWad(
                    selectedPoolSymbol,
                    chainId,
                    readOnlyProvider,
                  )
                  if (tpAvaxToAvaxRate) {
                    lpAmount = getToLpTokenAmountForTpAvaxWithdrawal(
                      withdrawLiquidity.tokenSymbol,
                      withdrawLiquidity.toTokenSymbol,
                      lpAmount,
                      tpAvaxToAvaxRate,
                    )
                  }
                }
                /** @todo refactor code to make it more generic */
                const tokenConversionRateData =
                  POOLS[selectedPoolSymbol].tokenConversionRateData
                if (tokenConversionRateData) {
                  const tpTokenToTokenRateWad = await getAnkrEthToWetheRateWad(
                    chainId,
                    readOnlyProvider,
                  )
                  if (tpTokenToTokenRateWad) {
                    const rateObj = {
                      ...tokenConversionRateData,
                      valueWad: tpTokenToTokenRateWad,
                    }
                    lpAmount = getToLpTokenAmountByRate(
                      withdrawLiquidity.tokenSymbol,
                      withdrawLiquidity.toTokenSymbol,
                      lpAmount,
                      rateObj,
                    )
                  }
                }
                quotation = await pool.quotePotentialWithdrawFromOtherAsset(
                  tokenAddress,
                  toTokenAddress,
                  changeDecimal(token.decimals, toToken.decimals, lpAmount),
                )
              }
            } catch (error) {
              Sentry.setContext('contract_call', {
                name: 'pool.quotePotentialWithdrawFromOtherAsset',
                token_symbol: token.symbol,
                to_token_symbol: toToken.symbol,
                lp_amount: utils.formatUnits(lpAmount, token.decimals),
              })
              Sentry.captureException(error)
            }

            decimals = toToken.decimals // use wanted token's decimal
          }
        }
        if (currentCount === quoteCount.current) {
          setWithdrawFee(utils.formatUnits(quotation.fee, decimals))
          setWithdrawTokenAmount(utils.formatUnits(quotation.amount, decimals))
        }
      }
    },
    [withdrawLiquidity, selectedPoolSymbol, chainId, readOnlyProvider],
  )

  const updateAmountFromDepositLiquidity = useCallback(
    (amount: string) => {
      setDepositLiquidity({ ...depositLiquidity, amount })
    },
    [depositLiquidity],
  )
  const initializeDepositLiquidity = (
    poolSymbol: PoolSymbol,
    assetTokenSymbol: TokenSymbol,
  ) => {
    const assetTokenSymbolForUnchecked =
      LP_TOKENS[poolSymbol][assetTokenSymbol]?.tokenSymbolForUnchecked
    if (assetTokenSymbolForUnchecked) {
      setDepositLiquidity({
        amount: '',
        tokenSymbol: assetTokenSymbolForUnchecked,
        tokenSymbolForDisplay: assetTokenSymbolForUnchecked,
      })
    }
  }

  const initializeWithdrawLiquidity = (
    poolSymbol: PoolSymbol,
    assetTokenSymbol: TokenSymbol,
  ) => {
    const assetTokenSymbolForUnchecked =
      LP_TOKENS[poolSymbol][assetTokenSymbol]?.tokenSymbolForUnchecked
    if (assetTokenSymbolForUnchecked) {
      setWithdrawLiquidity({
        amount: '',
        tokenSymbol: assetTokenSymbolForUnchecked,
        tokenSymbolForDisplay: assetTokenSymbolForUnchecked,
        toTokenSymbol: assetTokenSymbolForUnchecked,
        toTokenSymbolForDisplay: assetTokenSymbolForUnchecked,
        isWithdrawInOtherTokens: false,
      })
    }
  }

  const updateTokenSymbolFromDepositLiquidity = useCallback(
    (tokenSymbol: TokenSymbol) => {
      if (selectedAssetTokenSymbol) {
        setDepositLiquidity((prev) => ({
          ...prev,
          tokenSymbol,
          tokenSymbolForDisplay:
            LP_TOKENS[selectedPoolSymbol][selectedAssetTokenSymbol]
              ?.tokenSymbolForDisplay || null,
        }))
      }
    },
    [selectedAssetTokenSymbol, selectedPoolSymbol],
  )

  const updateTokenSymbolFromWithdrawLiquidity = useCallback(
    (tokenSymbol: TokenSymbol) => {
      if (selectedAssetTokenSymbol) {
        setWithdrawLiquidity((prev) => {
          return {
            ...prev,
            tokenSymbol,
            tokenSymbolForDisplay:
              LP_TOKENS[selectedPoolSymbol][selectedAssetTokenSymbol]
                ?.tokenSymbolForDisplay || tokenSymbol,
          }
        })
      }
    },
    [selectedAssetTokenSymbol, selectedPoolSymbol],
  )

  const clearDepositLiquidity = () => {
    setDepositLiquidity(initalDepositLiquidityInput)
  }
  const clearWithdrawLiquidity = () => {
    // clear MaximumPercentage avoid show again
    setWithdrawMaximumPercentage('')
    setWithdrawLiquidity(initalWithdrawLiquidityInput)
  }

  const updateSelectedPoolGroupSymbol = (poolGroupSymbol: PoolGroupSymbol) => {
    setSelectedPoolGroupSymbol(poolGroupSymbol)
  }

  const updateSelectedPoolSymbol = (poolSymbol: PoolSymbol) => {
    console.debug(`updateSelectedPoolSymbol: ${poolSymbol}`)
    setSelectedPoolSymbol(poolSymbol)
  }

  const updateSelectedAssetTokenSymbol = (
    assetTokenSymbol: TokenSymbol | null,
  ) => {
    console.debug(`updateSelectedAssetTokenSymbol: ${assetTokenSymbol || ''}`)
    setSelectedAssetTokenSymbol(assetTokenSymbol)
  }

  const updateToTokenFromWithdrawLiquidity = useCallback(
    (
      toTokenSymbol: TokenSymbol | null,
      toTokenSymbolForDisplay?: TokenSymbol,
    ) => {
      if (toTokenSymbol) {
        setWithdrawLiquidity((prev) => {
          return {
            ...prev,
            toTokenSymbol,
            toTokenSymbolForDisplay:
              toTokenSymbolForDisplay ||
              LP_TOKENS[selectedPoolSymbol][toTokenSymbol]
                ?.tokenSymbolForDisplay ||
              toTokenSymbol,
          }
        })
      } else {
        setWithdrawLiquidity((prev) => {
          return {
            ...prev,
            toTokenSymbol,
            toTokenSymbolForDisplay: toTokenSymbol,
          }
        })
      }
    },
    [selectedPoolSymbol],
  )

  /**
   * set withdraw percentage and set Withdraw Liquidity
   * @param {string} percentage
   */
  const updateWithdrawalPercentage = useCallback((percentage: string) => {
    setWithdrawalPercentage(percentage)
  }, [])

  const updateWithdrawMaximumPercentage = useCallback((percentage: string) => {
    setWithdrawMaximumPercentage(percentage)
  }, [])

  const toggleIsWithdrawInOtherTokens = useCallback(() => {
    setWithdrawLiquidity((prev) => ({
      ...prev,
      isWithdrawInOtherTokens: !prev.isWithdrawInOtherTokens,
    }))
  }, [])
  // Calculate minimumReceived = toTokenAmount / (1 + slippage %)
  let withdrawMinimumReceived = constants.Zero
  try {
    // catch the case if values are not parsable
    if (withdrawLiquidity.toTokenSymbol) {
      withdrawMinimumReceived = wadToNative(
        calculateMinimumReceived(
          strToWad(withdrawTokenAmount),
          userPreference.slippage.withdrawal,
        ),
        TOKENS[withdrawLiquidity.toTokenSymbol].decimals,
      )
    }
  } catch (err) {
    Sentry.setContext('event', {
      name: 'calculate_withdrawal_min_received',
      token_symbol: withdrawLiquidity.tokenSymbol,
      amount: withdrawTokenAmount,
    })
    Sentry.captureException(err)
    console.error(err)
  }

  /**
   * update withdrawLiquidity with withdrawalPercentage
   */
  useEffect(() => {
    if (selectedAssetTokenSymbol) {
      if (!lpTokenAmounts[selectedPoolSymbol][selectedAssetTokenSymbol]) return
      const token = TOKENS[selectedAssetTokenSymbol]
      const withdrawLPAmount = getLpAmountStrFromPercent(
        token,
        withdrawalPercentage === '' ? '0' : withdrawalPercentage,
        lpTokenAmounts[selectedPoolSymbol][selectedAssetTokenSymbol],
      )
      if (withdrawLPAmount !== withdrawLiquidity.amount) {
        // withdrawLPAmount > 0
        const decimals = TOKENS[selectedAssetTokenSymbol].decimals
        setWithdrawLiquidity((prevWithdrawLiquidity) => {
          return {
            ...prevWithdrawLiquidity,
            // trim off extra decimals
            amount: getDpFormat(withdrawLPAmount, decimals),
          }
        })
        void updateWithdrawQuotation(
          utils.parseUnits(withdrawLPAmount, decimals),
        )
      }
    }
    // cannot depend on withdrawLiquidity as it uses setWithdrawLiquidity.
    // updateWithdrawQuotation depends on withdrawLiquidity.
    // It will cause a loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    withdrawalPercentage,
    lpTokenAmounts,
    selectedAssetTokenSymbol,
    selectedPoolSymbol,
  ])
  return (
    <PoolInputContext.Provider
      value={{
        withdrawMaximumPercentage,
        withdrawMinimumReceived,
        withdrawFee,
        withdrawTokenAmount,
        depositLiquidity,
        withdrawLiquidity,
        selectedPoolGroupSymbol,
        selectedPoolSymbol,
        selectedAssetTokenSymbol,
        withdrawalPercentage,
        actions: {
          initializeDepositLiquidity,
          initializeWithdrawLiquidity,
          toggleIsWithdrawInOtherTokens,
          updateWithdrawMaximumPercentage,
          updateAmountFromDepositLiquidity,
          updateTokenSymbolFromDepositLiquidity,
          updateTokenSymbolFromWithdrawLiquidity,
          updateSelectedAssetTokenSymbol,
          clearDepositLiquidity,
          clearWithdrawLiquidity,
          updateSelectedPoolGroupSymbol,
          updateSelectedPoolSymbol,
          updateToTokenSymbolFromWithdrawLiquidity:
            updateToTokenFromWithdrawLiquidity,
          updateWithdrawalPercentage,
        },
      }}
    >
      {children}
    </PoolInputContext.Provider>
  )
}

export default PoolInputProvider
