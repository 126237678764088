import { BoxProps } from '@mui/material'
import {
  ArcElement,
  Chart as ChartJS,
  ChartData,
  Legend,
  Tooltip,
} from 'chart.js'
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Pie } from 'react-chartjs-2'
import { VOTING_CHART_LEGEND_SIZE } from '../../../constants'
import AppTypography from '../../AppTypography/AppTypography'
import {
  ColorIndicator,
  Container,
  CustomLegend,
  CustomTooltip,
  LegendLabel,
  StyledButton,
} from './PieChart.elements'

ChartJS.register(ArcElement, Tooltip, Legend)

type TooltipLabel = { title: string; subtitle: string }
type TooltipData = {
  left: string
  top: string
  opacity: string
} & TooltipLabel

interface Props extends BoxProps {
  data: {
    dataset: number[]
    colors: string[]
    labels?: TooltipLabel[]
  }
  disableLegend?: boolean
  disableHoverOffset?: boolean
}

function PieChart({
  data,
  disableLegend,
  disableHoverOffset,
  ...containerProps
}: Props): ReactElement {
  const customTooltipRef = useRef<HTMLDivElement>(null)
  const [isLegendFiltered, setIsLegendFiltered] = useState<boolean>(true)
  const [tooltipData, setTooltipData] = useState<TooltipData>({
    left: '0',
    top: '0',
    opacity: '0',
    title: '',
    subtitle: '',
  })
  const pieData: ChartData<'pie', number[], TooltipLabel> = {
    labels: data.labels,
    datasets: [
      {
        data: data.dataset,
        backgroundColor: data.colors,
        hoverBorderWidth: 2,
        hoverOffset: !disableHoverOffset ? 16 : 0,
        hoverBorderColor: 'unset',
        borderWidth: 0,
        borderColor: 'unset',
        animation: {
          duration: 800,
        },
      },
    ],
  }

  // update tooltip's position and visibility
  useEffect(() => {
    if (customTooltipRef.current) {
      customTooltipRef.current.style.left = tooltipData.left
      customTooltipRef.current.style.top = tooltipData.top
      customTooltipRef.current.style.opacity = tooltipData.opacity
    }
  }, [tooltipData])

  const filteredLegendData = useMemo(() => {
    if (!data.labels) return []
    if (isLegendFiltered) {
      return data.labels.slice(0, VOTING_CHART_LEGEND_SIZE)
    } else return data.labels
  }, [data.labels, isLegendFiltered])

  function getCollapseButtonLabel(): string {
    if (!data.labels) return ''
    const numberOfHiddenLabels =
      Number(data.labels.length) - Number(filteredLegendData.length)
    if (!isLegendFiltered) {
      return 'Show Less'
    } else {
      return `+${numberOfHiddenLabels} more`
    }
  }

  return (
    <Container {...containerProps}>
      {!disableLegend && data.labels && (
        <CustomLegend>
          {filteredLegendData.map((label, index) => {
            let color = data.colors[index]
            if (!color) {
              // a fallback value in case we didn't provide enough colors
              color = data.colors[index % data.colors.length]
            }
            return (
              <LegendLabel key={label.title}>
                <ColorIndicator color={color} />
                <AppTypography variant="caption2">{label.title}</AppTypography>
              </LegendLabel>
            )
          })}
          <StyledButton
            customVariant="secondary"
            onClick={() => setIsLegendFiltered((prev) => !prev)}
          >
            <AppTypography variant="caption3">
              {getCollapseButtonLabel()}
            </AppTypography>
          </StyledButton>
        </CustomLegend>
      )}
      {data.labels && (
        <CustomTooltip ref={customTooltipRef}>
          {tooltipData.title && (
            <AppTypography fontWeight="700" variant="body2">
              {tooltipData.title}
            </AppTypography>
          )}
          {tooltipData.subtitle && (
            <AppTypography variant="body2">
              {tooltipData.subtitle}
            </AppTypography>
          )}
        </CustomTooltip>
      )}
      <Pie
        data={pieData}
        options={{
          layout: {
            padding: 10,
          },
          plugins: {
            /**
             * legend
             */
            legend: {
              display: false,
            },
            /**
             * tooltip
             */
            tooltip: {
              enabled: false,
              external: (ctx) => {
                const tooltipModel = ctx.tooltip
                // on mouse leave
                if (tooltipModel.opacity === 0) {
                  if (tooltipData.opacity !== '0') {
                    setTooltipData((prev) => ({ ...prev, opacity: '0' }))
                  }
                } else {
                  // on hover
                  // position
                  const left = `${tooltipModel.x}px`
                  const top = `${tooltipModel.y}px`
                  // label data
                  let targetTitle = ''
                  let targetSubtitle = ''
                  if (tooltipModel.dataPoints[0].label) {
                    const label = tooltipModel.dataPoints[0]
                      .label as unknown as TooltipLabel

                    targetTitle = label.title
                    targetSubtitle = label.subtitle
                  }

                  // new state
                  const newTooltipData: TooltipData = {
                    title: targetTitle,
                    subtitle: targetSubtitle,
                    left,
                    top,
                    opacity: '1',
                  }

                  if (
                    JSON.stringify(newTooltipData) !==
                    JSON.stringify(tooltipData)
                  ) {
                    // update state
                    setTooltipData(newTooltipData)
                  }
                }
              },
            },
          },
        }}
      />
    </Container>
  )
}

export default PieChart
