import { strToWad } from '@hailstonelabs/big-number-utils'
import * as Sentry from '@sentry/react'
import { prepareWriteContract, writeContract } from '@wagmi/core'
import { constants } from 'ethers'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import {
  EMERGENCY_WITHDRAWS,
  LP_TOKENS,
  MASTER_PLATYPUS,
  platypusTreasure,
  router,
  vePtp,
  wavax,
} from '../config/contracts'
import { POOLS } from '../config/contracts/pool'
import { PoolSymbol } from '../config/contracts/pool/poolSymbol'
import { TOKENS } from '../config/contracts/token'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import { ERC20_ABI } from '../config/contracts/wagmiAbis/ERC20'
import { ChainId } from '../config/networks'
import { ContextType, useBalance } from '../contexts/BalanceContext'
import { useSnackbar } from '../contexts/SnackbarContext'
import { useWeb3 } from '../contexts/Web3Context'
import { HexString } from '../interfaces/common'
import { ApproveSpender } from '../interfaces/spender'
import { isUserDeniedTransaction } from '../utils/contract'
import useRevertReason from './useRevertReason'

type ReturnType = {
  isApproving: boolean
  isApproved: boolean
  handleClickApprove: () => Promise<string | undefined>
}

export const getAllowance = (
  tokenSymbol: TokenSymbol,
  spender: ApproveSpender,
  lpTokenApprovals: ContextType['lpTokenApprovals'],
  tokenApprovals: ContextType['tokenApprovals'],
  isLp: boolean,
  poolSymbol?: PoolSymbol,
): string => {
  let approvalAmount = '0.0'
  // EmergencyWithdraw
  if (spender === ApproveSpender.EmergencyWithdraw && poolSymbol) {
    approvalAmount = lpTokenApprovals.emergencyWithdraw[poolSymbol][tokenSymbol]
  }
  // swap, withdraw or deposit token to pool
  if (spender === ApproveSpender.Pool && poolSymbol) {
    approvalAmount = isLp
      ? lpTokenApprovals.pool[poolSymbol][tokenSymbol]
      : tokenApprovals.pool[poolSymbol][tokenSymbol]
  }
  if (spender === ApproveSpender.Router) {
    approvalAmount = tokenApprovals.router[tokenSymbol]
  }
  // stake lp
  if (spender === ApproveSpender.MasterPlatypus && poolSymbol) {
    approvalAmount = lpTokenApprovals.masterPlatypus[poolSymbol][tokenSymbol]
  }
  // stake ptp
  if (spender === ApproveSpender.VePtp && tokenSymbol === TokenSymbol.PTP) {
    approvalAmount = tokenApprovals.vePtp.PTP
  }
  // unwrap WAVAX
  if (spender === ApproveSpender.WAVAX && tokenSymbol === TokenSymbol.WAVAX) {
    approvalAmount = tokenApprovals.WAVAX.WAVAX
  }
  // repay USP
  if (
    spender === ApproveSpender.PlatypusTreasure &&
    tokenSymbol === TokenSymbol.USP
  ) {
    approvalAmount = tokenApprovals.platypusTreasure.USP
  }
  return approvalAmount
}
/**
 * getSpenderAddress
 * @param {string | null} chainId chainId from
 * @param {ApproveSpender} spender
 * @param {Pool} poolSymbol
 * @returns
 */
export const getSpenderAddress = (
  chainId: ChainId | null,
  spender: ApproveSpender,
  poolSymbol?: PoolSymbol,
  tokenSymbol?: TokenSymbol | null,
): HexString | null => {
  let spenderAddress = null
  switch (spender) {
    case ApproveSpender.Router:
      return router.getAddress(chainId)
    case ApproveSpender.MasterPlatypus:
      if (!poolSymbol) {
        throw new Error(
          'poolSymbol should not be null when spender is MasterPlatypus.',
        )
      }
      spenderAddress =
        MASTER_PLATYPUS[POOLS[poolSymbol].masterPlatypusId].getAddress(chainId)
      break
    case ApproveSpender.Pool:
      if (!poolSymbol) {
        throw new Error('poolSymbol is null when spender is Pool')
      }
      spenderAddress = POOLS[poolSymbol].getAddress(chainId)
      break
    case ApproveSpender.VePtp:
      spenderAddress = vePtp.getAddress(chainId)
      break
    case ApproveSpender.EmergencyWithdraw:
      if (!poolSymbol) {
        throw new Error(
          'poolSymbol should not be null when spender is EmergencyWithdraw.',
        )
      }
      if (!tokenSymbol) {
        throw new Error(
          'tokenSymbol should not be null when spender is EmergencyWithdraw.',
        )
      }
      spenderAddress =
        EMERGENCY_WITHDRAWS[poolSymbol]?.[tokenSymbol]?.getAddress(chainId) ||
        null
      break
    case ApproveSpender.WAVAX:
      spenderAddress = wavax.getAddress(chainId)
      break
    case ApproveSpender.PlatypusTreasure:
      spenderAddress = platypusTreasure.getAddress(chainId)
      break
    default:
      break
  }
  return spenderAddress
}
/**
 *
 * @param {TokenSymbol | null} tokenSymbol
 * @param {boolean} isLp
 * @param {ApproveSpender} spender
 * @param {string} inputAmount using in swap, deposit and withdraw token, stake ptp for compare user input
 * @param {PoolSymbol} poolSymbol Need poolSymbol when spender is MP or Pool
 * @returns {ReturnType}
 */
const useApprove = (
  tokenSymbol: TokenSymbol | null | undefined,
  isLp: boolean,
  spender: ApproveSpender,
  inputAmount: string | 'all',
  poolSymbol?: PoolSymbol,
): ReturnType => {
  const { chainId } = useWeb3()
  const getRevertReason = useRevertReason()
  const [isApproving, setIsApproving] = useState(false)
  const [isApprovalDone, setIsApprovalDone] = useState(false) // if approval is completed
  const { showMessage } = useSnackbar()
  const { tokenApprovals, lpTokenApprovals, lpTokenAmounts } = useBalance()
  // when tokenSymbol changes, reset isApproving to false
  useEffect(() => {
    setIsApproving(false)
    setIsApprovalDone(false)
  }, [tokenSymbol])
  useEffect(() => {
    if (
      [ApproveSpender.Pool, ApproveSpender.MasterPlatypus].includes(spender) &&
      !poolSymbol
    )
      console.error('You missed poolSymbol input!')
  })
  // check the approval status from useBalance() hook
  let isApproved = false
  let approvalAmount = '0.0'
  // automatically set isApproved to true because non ERC20, native token AVAX does not require approval
  if (tokenSymbol === TokenSymbol.AVAX && !isLp) {
    isApproved = true
  } else {
    if (tokenSymbol) {
      approvalAmount = getAllowance(
        tokenSymbol,
        spender,
        lpTokenApprovals,
        tokenApprovals,
        isLp,
        poolSymbol,
      )
      if (strToWad(approvalAmount).eq(constants.Zero)) {
        isApproved = false
      } else {
        // User enters input which needs to compare two amount
        if (inputAmount !== 'all') {
          // When the modal is opened without the inputAmount specified
          if (inputAmount === '') {
            // When user could approval partially (depost,withdraw,stakePTP): Check if approvalAmount > 0
            isApproved = tokenSymbol
              ? strToWad(approvalAmount).gt(constants.Zero)
              : false
          } else {
            isApproved = tokenSymbol
              ? strToWad(approvalAmount).gte(strToWad(inputAmount))
              : false
          }
        } else {
          // inputAmount === 'all', assuming it has to be an lp token
          /** @todo there may have non lp approve all situation in the future */
          if (isLp) {
            if (poolSymbol) {
              // When user need to approve all token/lp token (stake all lp): Check if approvalAmount > lpAmount balance
              isApproved = strToWad(approvalAmount).gte(
                strToWad(lpTokenAmounts[poolSymbol][tokenSymbol]),
              )
            }
          }
        }
      }
    }
  }

  // User approve successfully
  if (isApprovalDone) {
    isApproved = true
  }

  const handleClickApprove = async () => {
    let transactionHash: string | undefined
    if (spender === ApproveSpender.Pool && poolSymbol === undefined)
      throw 'spender id pool but have no poolsymbol'
    if (spender === ApproveSpender.MasterPlatypus && poolSymbol === undefined)
      throw 'spender id MasterPlatypus but have no poolsymbol'
    if (
      spender === ApproveSpender.EmergencyWithdraw &&
      poolSymbol === undefined &&
      tokenSymbol === undefined
    )
      throw 'spender id EmergencyWithdraw but have no poolsymboland tokenSymbol'
    const spenderAddress = getSpenderAddress(
      chainId,
      spender,
      poolSymbol,
      tokenSymbol,
    )
    if (tokenSymbol && spenderAddress) {
      let address: HexString | undefined
      if (isLp && poolSymbol) {
        const lpToken = LP_TOKENS[poolSymbol][tokenSymbol]
        address = lpToken?.address[chainId]
      } else {
        const token = TOKENS[tokenSymbol]
        address = token?.address[chainId]
      }
      if (address) {
        ReactGA.event({
          category: 'button',
          action: 'click',
          label: 'approve',
        })
        setIsApproving(true)
        try {
          const config = await prepareWriteContract({
            address,
            abi: ERC20_ABI,
            functionName: 'approve',
            args: [spenderAddress, constants.MaxUint256],
            chainId,
          })
          const { wait } = await writeContract(config)
          await wait()
          showMessage(`${tokenSymbol} approved. Ready for action.`)
          setIsApprovalDone(true)
        } catch (err) {
          if (!isUserDeniedTransaction(err)) {
            Sentry.setContext('contract_call', {
              name: 'infinite_approval_token',
              is_lp: isLp,
              spender: spender,
              token_symbol: tokenSymbol,
            })
            Sentry.captureException(err)
          }
          const reason = await getRevertReason(err)
          showMessage(reason || 'Transaction failed.', 'warning')
        } finally {
          setIsApproving(false)
        }
      }

      return transactionHash
    }
  }
  return { isApproved, isApproving, handleClickApprove }
}

export default useApprove
