interface IntervalArr {
  value: number
}

/** @todo add test case */
/**
 * Generate interval arr between max and min.
 * @param {number} max
 * @param {number} min
 * @param {number} length
 * @returns {Array<IntervalArr>} array with assigned length
 */
export function getNumberIntervalArr(
  min: number,
  max: number,
  length: number,
): Array<IntervalArr> {
  const interval = (max - min) / length
  const arr = Array.from({ length }, (_, i) => min + i * interval).map(
    (value) => {
      return { value: Math.floor(value) }
    },
  )

  return arr
}
