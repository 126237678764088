import React, { ReactElement } from 'react'
import WaitForConfirmationModal from '../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import DisabledAppTokenInput from '../../../components/TokenInput/DisabledAppTokenInput'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import StakeModalInfoBox from '../StakeModalContainer/StakeModalInfoBox'
interface Props {
  isOpen: boolean
}

function StakeWaitForConfirmationModalContainer({
  isOpen,
}: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const { input } = useVePtp()
  const handleCloseModal = () => {
    modalDispatch(closeModal())
  }

  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="600px"
      subtitle="Stake PTP"
    >
      <DisabledAppTokenInput
        tokenItems={[
          {
            value: input.stakePtpAmount,
            tokenSymbol: TokenSymbol.PTP,
          },
        ]}
      />

      <StakeModalInfoBox />
    </WaitForConfirmationModal>
  )
}

export default StakeWaitForConfirmationModalContainer
