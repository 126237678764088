import {
  getCommifiedFormat,
  isParsableString,
  strToWad,
} from '@hailstonelabs/big-number-utils'
import { alpha, Box, useTheme } from '@mui/material'
import { utils } from 'ethers'
import React, { ReactElement, useEffect, useState } from 'react'
import AppButton from '../../../../components/buttons/AppButton/AppButton'
import TooltipNum from '../../../../components/InfoBox/TooltipNum'
import Modal from '../../../../components/Modal/Modal'
import TokenIcon from '../../../../components/TokenIcon/TokenIcon'
import AppTokenInput from '../../../../components/TokenInput/AppTokenInput'
import {
  LP_TOKENS,
  MASTER_PLATYPUS,
  POOLS,
  TOKENS,
} from '../../../../config/contracts'
import { WAD_DECIMALS } from '../../../../constants'
import { useBalance } from '../../../../contexts/BalanceContext'
import { useModal } from '../../../../contexts/ModalContext'
import { usePoolInput } from '../../../../contexts/PoolInputContext'
import { useStakeLpData } from '../../../../contexts/StakeLpDataContext'
import { useUnstakeLpContent } from '../../../../contexts/UnstakeLpContentContext'
import useUnstakeLp from '../../../../hooks/pool/useUnstakeLp'
import useDebounce from '../../../../hooks/useDebounce'
import PoolUnstakeModalInfoBox from './PoolUnstakeModalInfoBox'

interface Props {
  isOpen: boolean
}
function PoolUnstakeModalContainer({ isOpen }: Props): ReactElement {
  const theme = useTheme()
  const [unstakeLpPercentageInputted, setUnstakeLpPercentageInputted] =
    useState('')
  const debouncedUnstakeLpPercentageInputted = useDebounce(
    unstakeLpPercentageInputted,
  )
  const {
    modalState,
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const { tokenAmounts } = useBalance()
  const { lp } = useStakeLpData()
  const { selectedPoolSymbol } = usePoolInput()
  const { setUnstakeLpPercentage, unstakeLpAmountStr, resetStates } =
    useUnstakeLpContent()
  const tokenSymbol = modalState.tokenSymbols && modalState.tokenSymbols[0]
  const { handleUnstakeLp } = useUnstakeLp()

  const handleModalClose = () => {
    resetStates()
    modalDispatch(closeModal())
  }

  const handleInputChange = (value: string) => {
    if (value === '') {
      setUnstakeLpPercentageInputted('')
      return
    }

    if (!isParsableString(value, WAD_DECIMALS, true)) {
      return
    }
    const percentageWad = strToWad(value)
    // max percentage is 100
    if (percentageWad.gt(strToWad('100'))) return
    setUnstakeLpPercentageInputted(value)
  }
  // For initial setup
  useEffect(() => {
    resetStates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // when debouncedUnstakeLpPercentageInputted changes, update it to UnstakeLpContentContext
  useEffect(() => {
    setUnstakeLpPercentage(debouncedUnstakeLpPercentageInputted)
  }, [debouncedUnstakeLpPercentageInputted, setUnstakeLpPercentage])

  if (!tokenSymbol) return <></>
  const handleClickUnstake = () =>
    handleUnstakeLp(
      tokenSymbol,
      selectedPoolSymbol,
      MASTER_PLATYPUS[POOLS[selectedPoolSymbol].masterPlatypusId],
      utils.parseUnits(unstakeLpAmountStr, TOKENS[tokenSymbol].decimals),
    )
  const tokenSymbolForDisplay =
    LP_TOKENS[selectedPoolSymbol][tokenSymbol]?.tokenSymbolForDisplay ||
    tokenSymbol
  const hasZeroInput = strToWad(unstakeLpPercentageInputted).isZero()
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      topBarLabel={
        <>
          Confirm Unstake
          <TokenIcon margin="0 4px 0 8px" tokenSymbol={tokenSymbolForDisplay} />
          {tokenSymbolForDisplay}
        </>
      }
      disableCloseBtn
    >
      <AppTokenInput
        onChange={(value) => handleInputChange(value)}
        value={unstakeLpPercentageInputted}
      >
        <AppTokenInput.LabelItem
          leadingLabel={
            <AppTokenInput.LabelItemInnerContainer>
              <AppTokenInput.Label>Staked:&nbsp;</AppTokenInput.Label>
              <TooltipNum
                amount={
                  lp.inTermsOfToken.staked[selectedPoolSymbol][tokenSymbol]
                }
                rightSymbol={tokenSymbolForDisplay}
              >
                {getCommifiedFormat(
                  lp.inTermsOfToken.staked[selectedPoolSymbol][tokenSymbol],
                )}
                &nbsp;
                {tokenSymbolForDisplay}
              </TooltipNum>
            </AppTokenInput.LabelItemInnerContainer>
          }
          trailingLabel={
            <AppTokenInput.LabelItemInnerContainer
              style={{ justifyContent: 'flex-end' }}
            >
              <AppTokenInput.Label>Balance:&nbsp;</AppTokenInput.Label>
              <TooltipNum
                amount={tokenAmounts[tokenSymbolForDisplay]}
                rightSymbol={tokenSymbolForDisplay}
              >
                {getCommifiedFormat(tokenAmounts[tokenSymbolForDisplay])}&nbsp;
                {tokenSymbolForDisplay}
              </TooltipNum>
            </AppTokenInput.LabelItemInnerContainer>
          }
        />

        <AppTokenInput.InnerContainer
          flexDirection="column"
          mt="12px"
          bgcolor={alpha(theme.palette.primary[500], 0.2)}
        >
          <Box display="flex" flexDirection="row">
            <AppTokenInput.InputField
              placeholder="0.0"
              inputUnitLabel="/100%"
              disableUnderline
              textalign="left"
              noLeftPadding
            />
            <AppTokenInput.ActionButton
              onClick={() => handleInputChange('100')}
            >
              Max
            </AppTokenInput.ActionButton>
          </Box>
          <AppTokenInput.Slider style={{ marginBottom: 0 }} />
        </AppTokenInput.InnerContainer>
      </AppTokenInput>
      <PoolUnstakeModalInfoBox tokenSymbol={tokenSymbol} />
      <Box display="flex" flexDirection="row">
        <AppButton customVariant="neutral" fullWidth onClick={handleModalClose}>
          Cancel
        </AppButton>
        <AppButton
          fullWidth
          disabled={hasZeroInput}
          onClick={() => void handleClickUnstake()}
        >
          Unstake
        </AppButton>
      </Box>
    </Modal>
  )
}

export default PoolUnstakeModalContainer
