import { strToWad } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import AppCheckBox from '../../../components/Input/AppCheckBox/AppCheckBox'
import Modal from '../../../components/Modal/Modal'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import { LP_TOKENS } from '../../../config/contracts'
import { useModal } from '../../../contexts/ModalContext'
import { usePoolInput } from '../../../contexts/PoolInputContext'
import useWithdrawToken from '../../../hooks/pool/useWithdrawToken'
import useApprove from '../../../hooks/useApprove'
import { ApproveSpender } from '../../../interfaces/spender'
import PoolWithdrawInfoBox from '../../PoolContainer/PoolWithdrawInfoBox'
import PoolWithdrawalTokenInputsContainer from './PoolWithdrawalTokenInputsContainer'

interface Props {
  isOpen: boolean
}
function PoolWithdrawalModalContainer({ isOpen }: Props): ReactElement {
  const { selectedPoolSymbol } = usePoolInput()
  const { handleClickWithdraw } = useWithdrawToken()
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const {
    withdrawLiquidity,
    withdrawalPercentage,
    selectedAssetTokenSymbol,
    actions: {
      updateTokenSymbolFromWithdrawLiquidity: updateTokenFromWithdrawLiquidity,
      updateToTokenSymbolFromWithdrawLiquidity:
        updateToTokenFromWithdrawLiquidity,
      clearWithdrawLiquidity,
    },
  } = usePoolInput()
  const { isApproved, isApproving, handleClickApprove } = useApprove(
    selectedAssetTokenSymbol,
    true,
    ApproveSpender.Pool,
    withdrawLiquidity.amount,
    selectedPoolSymbol,
  )
  const hasZeroInput = strToWad(withdrawalPercentage).isZero()
  const handleModalClose = () => {
    modalDispatch(closeModal())
    clearWithdrawLiquidity()
  }

  /* only show checkbox when the tokens are either AVAX or WAVAX. It will be hidden when users click "withdraw in other tokens". */
  const isCheckBoxShown =
    selectedAssetTokenSymbol &&
    LP_TOKENS[selectedPoolSymbol][selectedAssetTokenSymbol]
      ?.isMultiTokenCheckboxSupported &&
    !withdrawLiquidity.isWithdrawInOtherTokens

  const checkedTokenSymbol =
    selectedAssetTokenSymbol &&
    LP_TOKENS[selectedPoolSymbol][selectedAssetTokenSymbol]
      ?.tokenSymbolForChecked

  const uncheckedTokenSymbol =
    selectedAssetTokenSymbol &&
    LP_TOKENS[selectedPoolSymbol][selectedAssetTokenSymbol]
      ?.tokenSymbolForUnchecked

  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!checkedTokenSymbol || !uncheckedTokenSymbol) return
    if (e.target.checked) {
      updateTokenFromWithdrawLiquidity(checkedTokenSymbol)
      updateToTokenFromWithdrawLiquidity(checkedTokenSymbol, checkedTokenSymbol)
    } else {
      updateTokenFromWithdrawLiquidity(uncheckedTokenSymbol)
      updateToTokenFromWithdrawLiquidity(uncheckedTokenSymbol)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      topBarLabel={
        <>
          Confirm Withdraw
          <TokenIcon
            margin="0 4px 0 8px"
            tokenSymbol={withdrawLiquidity.tokenSymbol || undefined}
          />
          {withdrawLiquidity.tokenSymbol}
        </>
      }
      width="650px"
      disableCloseBtn
    >
      <PoolWithdrawalTokenInputsContainer />
      {isCheckBoxShown && (
        <AppCheckBox
          onChange={handleCheckBox}
          label={`Withdraw in ${checkedTokenSymbol || ''}`}
          style={{
            margin: '12px 0',
          }}
        />
      )}
      <PoolWithdrawInfoBox />
      <Box display="flex" flexDirection="row" mt={1}>
        <AppButton onClick={handleModalClose} fullWidth customVariant="neutral">
          Cancel
        </AppButton>
        {isApproved ? (
          <AppButton
            onClick={() => void handleClickWithdraw()}
            disabled={!isApproved || hasZeroInput}
            fullWidth
          >
            Withdraw
          </AppButton>
        ) : (
          <AppButton
            onClick={() => void handleClickApprove()}
            disabled={isApproved || isApproving || hasZeroInput}
            fullWidth
            customVariant="secondary"
            hasSpinner={isApproving}
          >
            {isApproving ? 'Approving' : 'Approve'}
          </AppButton>
        )}
      </Box>
    </Modal>
  )
}

export default PoolWithdrawalModalContainer
