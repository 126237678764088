import React from 'react'

interface DashableProps {
  showDash: boolean
  children: React.ReactNode
}

function Dashable({ showDash, children }: DashableProps) {
  return showDash ? <>-</> : <>{children}</>
}

export default Dashable
