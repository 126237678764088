import { Asset as AssetContract } from '../../../../types/ethers-contracts'
import { ChainId } from '../../networks'
import assetABI from '../abis/Asset.json'
import { AddressInterface, Contract } from '../Contract'
import { PoolSymbol } from '../pool/poolSymbol'
import { TokenSymbol } from '../token/tokenSymbol'
import { ASSET_ABI } from '../wagmiAbis/Asset'
type rewardType = {
  tokenSymbol: TokenSymbol
  // may add trader joe
  method: 'rewarder.pendingTokens' | 'masterPlatypus.pendingTokens'
  // rewarderAddress is undefined if reward method == 'masterPlatypus.pendingTokens'
  rewarderAddress?: AddressInterface
  // for multiRewarder
  tokenId?: number
}

type bribeType = {
  tokenSymbol: TokenSymbol
  bribeAddress: AddressInterface
}

type TokenSymbolForCheckbox = {
  tokenSymbolForChecked: TokenSymbol
  tokenSymbolForUnchecked: TokenSymbol
}
export class LpToken extends Contract<AssetContract, typeof ASSET_ABI> {
  // isCollateral
  readonly isCollateral: boolean
  // used in voting table, only for display in gauge voting asset
  sortingId?: number
  readonly decimals: number
  // Using tokenSymbolForDisplay for some ui
  // default value is tokenSymbol
  // etc. In avax-savax deposit modal, wavax need to display as avax for deposited data
  readonly tokenSymbolForDisplay: TokenSymbol
  // isMultiTokenCheckboxSupported determine same asset allow multi token deposit or withdraw
  // etc. In avax-savax, deposit and withdraw in avax or wavax is allowed for WAVAX Asset
  readonly isMultiTokenCheckboxSupported: boolean
  // default value is tokenSymbol
  // etc. In avax-savax pool, tokenSymbolForChecked is wavax for WAVAX Asset
  readonly tokenSymbolForChecked: TokenSymbol
  // default value is tokenSymbol
  // etc. In avax-savax pool, tokenSymbolForChecked is avax for WAVAX Asset
  readonly tokenSymbolForUnchecked: TokenSymbol
  readonly tokenSymbol: TokenSymbol
  readonly poolSymbol: PoolSymbol
  readonly isAvailable: boolean
  readonly color: string
  readonly pids: {
    [id in ChainId]: number
  }
  readonly rewards: rewardType[]
  readonly bribe?: bribeType
  constructor({
    addresses,
    decimals,
    tokenSymbol,
    poolSymbol,
    isAvailable,
    rewards,
    pids,
    tokenSymbolForDisplay,
    tokenSymbolForCheckbox,
    color,
    isCollateral = false,
    bribe,
  }: {
    addresses: AddressInterface
    decimals: number
    tokenSymbol: TokenSymbol
    poolSymbol: PoolSymbol
    isAvailable: boolean
    rewards: rewardType[]
    bribe?: bribeType
    pids: { [id in ChainId]: number }
    color: string
    isCollateral?: boolean
    tokenSymbolForDisplay?: TokenSymbol
    tokenSymbolForCheckbox?: TokenSymbolForCheckbox
  }) {
    super(addresses, assetABI, ASSET_ABI)
    this.isCollateral = isCollateral
    this.color = color
    this.decimals = decimals
    this.tokenSymbol = tokenSymbol
    this.poolSymbol = poolSymbol
    this.isAvailable = isAvailable
    this.pids = pids
    this.rewards = rewards
    this.tokenSymbolForDisplay = tokenSymbolForDisplay || tokenSymbol
    this.isMultiTokenCheckboxSupported = tokenSymbolForCheckbox ? true : false
    this.tokenSymbolForChecked =
      tokenSymbolForCheckbox?.tokenSymbolForChecked || tokenSymbol
    this.tokenSymbolForUnchecked =
      tokenSymbolForCheckbox?.tokenSymbolForUnchecked || tokenSymbol
    this.bribe = bribe
  }
}
