import { Box, Typography } from '@mui/material'
import styled, { css, keyframes } from 'styled-components'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import { poolCardSharedMediaQuery } from '../../../components/cards/PoolCard/PoolCard.elements'

export const StakeAccordionContainer = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 10px 0;
    ${poolCardSharedMediaQuery};

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: unset;
      padding-top: 0;
    }
  `}
`

export const StakeAccordionInnerContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      align-items: flex-start;
    }
  `}
`
// StakeBarInfo
const stakeAccordionAnimationDuration = '0.3s'

export const Title = styled(Typography)`
  ${({ theme }) => css`
    opacity: 0.5;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 100px;
      text-align: right;
    }
  `}
`

export const Content = styled(Typography)`
  ${() => css`
    display: inline-flex;
  `}
`
interface StakeAccordionSummaryProps {
  $variant?: 'disabled' | 'highlighted'
}
export const StakeAccordionSummary = styled(Box)<StakeAccordionSummaryProps>`
  ${({ theme, $variant }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${$variant === 'disabled' &&
    css`
      ${Title} {
        opacity: 0.5;
      }
    `}
    ${$variant === 'highlighted' &&
    css`
      ${Content} {
        opacity: 1;
        color: ${theme.palette.secondary.main};
      }
    `}
  `}
`
// PoolStakeButton
interface StakeButtonProps {
  $active?: boolean
  disabled?: boolean
}
const rotate = keyframes`
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(0);
  }

`
export const PoolStakeButton = styled(AppButton)<StakeButtonProps>`
  ${({ $active, theme }) => css`
    border-radius: 999px;
    margin: 0;
    padding: 2px 16px;
    min-width: unset;
    max-width: 100px;
    transition: all ${stakeAccordionAnimationDuration};
    &:disabled {
      border-radius: 999px;
    }

    svg {
      font-size: 20px;
    }

    .pool-stake-buttom__close-icon {
      transition: all ${stakeAccordionAnimationDuration};
      font-size: 18px;
    }
    ${$active &&
    css`
      max-width: 24px;
      padding: 1px;
      .pool-stake-buttom__close-icon {
        animation: ${rotate} ${stakeAccordionAnimationDuration} forwards;
      }
    `}

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      ${!$active &&
      css`
        padding: 8px 12px;
      `}
    }
  `}
`
