import {
  getCommifiedFormat,
  getDpFormat,
} from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import React from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useVePtp } from '../../../contexts/VePtpContext'
import useBreakpoints from '../../../hooks/useBreakpoints'
import PtpAllocationPieChart from '../PtpAllocationPieChart/PtpAllocationPieChart'
import { Circle } from './PtpAllocationInfo.element'

function PtpAllocationInfo() {
  const { isTabletSm } = useBreakpoints()
  const { ptp } = useVePtp()
  const totalPtpAmount = ptp.amount.sum
  const lockedPtpPercentage = ptp.percent.locked
  const lockedPtpAmount = ptp.amount.locked
  const stakedPtpPercentage = ptp.percent.staked
  const stakedPtpAmount = ptp.amount.staked
  const availablePtpPercentage = ptp.percent.available
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection={isTabletSm ? 'column' : 'row'}
    >
      <Box
        display="flex"
        flexDirection={isTabletSm ? 'column' : 'row'}
        alignItems="center"
      >
        <PtpAllocationPieChart />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <AppTypography variant="caption2">
            <TooltipNum amount={totalPtpAmount} rightSymbol={TokenSymbol.PTP}>
              Total {getCommifiedFormat(totalPtpAmount, 2)} {TokenSymbol.PTP}
            </TooltipNum>
          </AppTypography>
          <Box display="flex" alignItems="center">
            <Circle $color="darkBlue" />
            <AppTypography variant="caption2" transparent>
              Available: {getDpFormat(availablePtpPercentage, 1)}%
            </AppTypography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Circle $color="blue" />
          <AppTypography variant="caption2" transparent>
            Locked: {getDpFormat(lockedPtpPercentage, 1)}%&nbsp;
          </AppTypography>
          <AppTypography variant="caption2" transparent>
            <TooltipNum amount={lockedPtpAmount} rightSymbol={TokenSymbol.PTP}>
              ({getCommifiedFormat(lockedPtpAmount, 2)} {TokenSymbol.PTP})
            </TooltipNum>
          </AppTypography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Circle $color="green" />
          <AppTypography variant="caption2" transparent>
            Staked: {getDpFormat(stakedPtpPercentage, 1)}%&nbsp;
          </AppTypography>
          <AppTypography variant="caption2" transparent>
            <TooltipNum amount={stakedPtpAmount} rightSymbol={TokenSymbol.PTP}>
              ({getCommifiedFormat(stakedPtpAmount, 2)} {TokenSymbol.PTP})
            </TooltipNum>
          </AppTypography>
        </Box>
      </Box>
    </Box>
  )
}

export default PtpAllocationInfo
