import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'

export const Item = styled(Box)`
  ${({ theme }) => css`
    background: ${alpha(theme.palette.primary[500], 0.2)};
    padding: 12px;
    margin-bottom: 8px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${alpha(theme.palette.primary[500], 0.3)};
    }
  `}
`
