import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'

export const Container = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    padding: 0px;
    opacity: 1;
    margin: 0px;

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-wrap: wrap;
    }
  `}
`

interface BalanceDetailItemProps {
  flex?: string
}

export const BalanceDetailItem = styled(Box)<BalanceDetailItemProps>`
  ${({ theme, flex }) => css`
    display: flex;
    flex: ${flex ? flex : '1'};
    flex-direction: column;
    margin: 0px;
    background: ${alpha(theme.palette.primary.main, 0.2)};
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    min-width: 180px;

    &:first-child {
      border-radius: 10px 0 0 10px;
      margin-right: 3px;
      @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
        border-radius: 10px 0px 0px 0px;
      }
    }

    &:nth-child(2) {
      @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
        border-radius: 0px 10px 0px 0px;
      }
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
      margin-left: 3px;
      @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
        margin-top: 3px;
        margin-left: 0px;
        border-radius: 0px 0px 10px 10px;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      flex-wrap: wrap;
      min-width: 150px;
    }

    @media only screen and (max-width: ${theme.breakpoints.values.xs}px) {
      min-width: 100px;
    }
  `}
`
