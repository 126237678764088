// update TokenSymbol at the same time
export enum TokenSymbol {
  // USD assets
  USDTe = 'USDT.e',
  USDCe = 'USDC.e',
  DAIe = 'DAI.e',
  MIM = 'MIM',
  USDC = 'USDC',
  USDT = 'USDT',
  FRAX = 'FRAX',
  UST = 'UST',
  BUSD = 'BUSD',
  // this is deprecated
  MIMATIC = 'miMatic',
  USX = 'USX',
  TUSD = 'TUSD',
  YUSD = 'YUSD',
  TSD = 'TSD',
  H2O = 'H2O',
  MONEY = 'MONEY',
  // Rewarder
  YETI = 'YETI',
  TEDDY = 'TEDDY',
  MELT = 'MELT',
  MORE = 'MORE',
  // Governance Token
  PTP = 'PTP',
  // Yield Boost Token
  VEPTP = 'vePTP',
  // WAVAX-sAVAX pool
  SAVAX = 'sAVAX',
  WAVAX = 'WAVAX',
  QI = 'QI',
  // WAVAX-yyAVAX pool
  YYAVAX = 'yyAVAX',
  // WAVAX-ankrAVAX pool
  ANKRAVAX = 'ankrAVAX',
  ANKR = 'ANKR',
  // for display AVAX-PTP pool info
  AVAX = 'AVAX',
  JOE = 'JOE',
  // BTC
  BTCb = 'BTC.b',
  WBTCe = 'WBTC.e',
  HUM = 'HUM',
  USP = 'USP',
  AXLUSDC = 'axlUSDC',
  // ankrETH - WETH.e pool
  ANKRETH = 'ankrETH',
  WETHe = 'WETH.e',
}
export const tokenSymbols = Object.values(TokenSymbol)

export type TokenSymbolKey = keyof typeof TokenSymbol

/**
 * Determine toTokenSymbol in swapping
 */
export enum SwapGroupSymbol {
  USD = 'USD',
  AVAX = 'AVAX',
  BTC = 'BTC',
  ETH = 'ETH',
  UNAVAILABLE = 'UNAVAILABLE',
}
