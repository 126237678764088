import React, { createContext, ReactElement, useContext } from 'react'
import useLocalStorage from '../hooks/useLocalStorage'

export type SlippageType = 'swap' | 'withdrawal'
export type WithrawalInOtherTokensType = 'On' | 'Off'
interface UserPreferenceType {
  slippage: {
    swap: string
    withdrawal: string
  }
  withrawalInOtherTokens: WithrawalInOtherTokensType
  transactionDeadline: string
}
interface ContextType {
  userPreference: UserPreferenceType
  initialUserPreference: UserPreferenceType
  actions: {
    updateSlippage: (value: string, type: SlippageType) => void
    updateTransactionDeadline: (value: string) => void
    updateWithrawalInOtherTokens: (value: WithrawalInOtherTokensType) => void
    setValueToLocalStorage: (
      value:
        | UserPreferenceType
        | ((value: UserPreferenceType) => UserPreferenceType),
    ) => void
  }
}

const initialUserPreference: UserPreferenceType = {
  slippage: {
    swap: '0.01',
    withdrawal: '0.01',
  },
  withrawalInOtherTokens: 'Off',
  transactionDeadline: '60',
}
const UserPreferenceContext = createContext<ContextType>({
  userPreference: initialUserPreference,
} as ContextType)
interface Props {
  children: React.ReactNode
}
UserPreferenceContext.displayName = 'UserPreferenceContext'

export const useUserPreference = (): ContextType => {
  return useContext(UserPreferenceContext)
}
function UserPreferenceProvider({ children }: Props): ReactElement {
  // persist data
  const [storedValue, setValueToLocalStorage] =
    useLocalStorage<UserPreferenceType>({
      key: 'userPreference',
      initialValue: initialUserPreference,
    })
  const updateWithrawalInOtherTokens = (value: WithrawalInOtherTokensType) => {
    // when the value is empty, always save the default value to the local storage
    setValueToLocalStorage((prev) => ({
      ...prev,
      withrawalInOtherTokens: value,
    }))
  }
  const updateSlippage = (value: string, type: SlippageType) => {
    // // when the value is empty, always save the default value to the local storage
    setValueToLocalStorage((prev) => ({
      ...prev,
      slippage: {
        ...prev.slippage,
        [type]: value === '' ? initialUserPreference.slippage[type] : value,
      },
    }))
  }

  const updateTransactionDeadline = (value: string) => {
    // when the value is empty, always save the default value to the local storage
    setValueToLocalStorage((prev) => ({
      ...prev,
      transactionDeadline:
        value === '' ? initialUserPreference.transactionDeadline : value,
    }))
  }
  return (
    <UserPreferenceContext.Provider
      value={{
        userPreference: storedValue,
        initialUserPreference: initialUserPreference,
        actions: {
          updateSlippage,
          updateTransactionDeadline,
          updateWithrawalInOtherTokens,
          setValueToLocalStorage,
        },
      }}
    >
      {children}
    </UserPreferenceContext.Provider>
  )
}

export default UserPreferenceProvider
