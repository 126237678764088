import React, { ReactElement } from 'react'
import InfoBox from '../../../../components/InfoBox/InfoBox'
import WaitForConfirmationModal from '../../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import DisabledAppTokenInput from '../../../../components/TokenInput/DisabledAppTokenInput'
import { LP_TOKENS, POOLS } from '../../../../config/contracts'
import { TokenSymbol } from '../../../../config/contracts/token/tokenSymbol'
import { useMigrationDetection } from '../../../../contexts/MigrationDetectionContext'
import { useModal } from '../../../../contexts/ModalContext'
import { useStakeLpData } from '../../../../contexts/StakeLpDataContext'
import { useUnstakeLpContent } from '../../../../contexts/UnstakeLpContentContext'
import PoolAutoClaimInfoBoxItem from '../PoolAutoClaimInfoBoxItem/PoolAutoClaimInfoBoxItem'

interface Props {
  isOpen: boolean
  isDeprecatedPool?: boolean
}
/**
 * Handle Unstake Lp
 * @param isOpen
 * @param isDeprecatedPool: isDeprecatedPool determines data from useUnstakeLpContent (false/undefined) or useMigrationDetection (true)
 */
function PoolUnstakeWaitForConfirmationModalContainer({
  isOpen,
  isDeprecatedPool,
}: Props): ReactElement {
  const {
    modalDispatch,
    modalState,
    actions: { closeModal },
  } = useModal()
  const { rewards } = useStakeLpData()
  const tokenSymbol = modalState.tokenSymbols && modalState.tokenSymbols[0]
  const poolSymbol = modalState.poolSymbols && modalState.poolSymbols[0]
  const { unstakeLpAmountStr } = useUnstakeLpContent()
  const handleCloseModal = () => {
    modalDispatch(closeModal())
  }
  const { delistedData } = useMigrationDetection()
  if (!tokenSymbol || !poolSymbol) return <></>
  const tokenSymbolForDisplay =
    LP_TOKENS[poolSymbol][tokenSymbol]?.tokenSymbolForDisplay || tokenSymbol
  const unstakeLpAmount = isDeprecatedPool
    ? delistedData[poolSymbol]?.[tokenSymbol]?.lpAmountInTermsOfLp.staked ||
      '0.0'
    : unstakeLpAmountStr

  const rewardsOfPool = POOLS[poolSymbol].getRewardsEarned(rewards, tokenSymbol)
  let actualRewardsEarned = rewardsOfPool
  /** @todo refactor if some deprecated asset provide non ptp rewards */
  if (isDeprecatedPool) {
    if (delistedData[poolSymbol]?.[tokenSymbol]?.pendingPtp) {
      actualRewardsEarned = [
        {
          tokenSymbol: TokenSymbol.PTP,
          value: delistedData[poolSymbol]?.[tokenSymbol]?.pendingPtp || '0.0',
        },
      ]
    } else {
      actualRewardsEarned = []
    }
  }

  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="600px"
      subtitle={`Unstake ${tokenSymbolForDisplay}`}
    >
      <DisabledAppTokenInput
        tokenItems={[
          {
            tokenSymbol: tokenSymbolForDisplay,
            value: unstakeLpAmount,
          },
        ]}
      />
      <InfoBox>
        <PoolAutoClaimInfoBoxItem actualRewardsEarned={actualRewardsEarned} />
      </InfoBox>
    </WaitForConfirmationModal>
  )
}

export default PoolUnstakeWaitForConfirmationModalContainer
