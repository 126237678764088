import { BigNumber } from 'ethers'
/** @todo move contract related type to interfaces/smartContract */
import {
  PendingTokensType,
  PoolInfoType,
  UserInfoType,
  VePtpUserInfoSCType,
} from '../interfaces/apr'
import {
  TreasureSCCollateralSettings,
  TreasureSCMarketSettingReturn,
  TreasureSCPositionViewReturn,
} from '../interfaces/usp'

/**
 * Reason of as unknown as:  multicall provider didn't provide strongly-typed
 * Null value is considered as error call back
 */
export type MigrationDetectionContextMulticallSCReturnType =
  | PendingTokensType
  | UserInfoType
  | BigNumber
  | null

export type LockedPositionSCReturnType = {
  initialLockTime: BigNumber
  unlockTime: BigNumber
  ptpLocked: BigNumber
  vePtpAmount: BigNumber
}

export type MulticallHelperSCReturnType =
  | null
  | boolean
  | VePtpUserInfoSCType
  | BigNumber[]
  | number
  | BigNumber
  | { allocPoint: number }
  | PoolInfoType
  | UserInfoType
  | PendingTokensType
  | {
      tokenPerSec: BigNumber
      accTokenPerShare: BigNumber
      accTokenPerFactorShare: BigNumber
    }
  | LockedPositionSCReturnType
  | TreasureSCPositionViewReturn
  | TreasureSCMarketSettingReturn
  | TreasureSCCollateralSettings
  | {
      potentialOutcome: BigNumber
      haircut: BigNumber
    }
export type CallbacksType<T> = ((value: T) => void)[]
/** @TODO refactor multicall */
export type FetchDataHelperReturn<T, K> = {
  contractCalls: Promise<MulticallHelperSCReturnType>[]
  callbacks: CallbacksType<MulticallHelperSCReturnType>
  states: {
    withAccount: T
    withoutAccount: K
  }
}
export function executeCallBacks<T>(
  values: T[],
  callbacks: CallbacksType<T>,
): void {
  if (!values) return
  for (let i = 0; i < callbacks.length; i++) {
    const value = values[i]
    if (
      process.env.REACT_APP_SHOW_MULITCALL_ERROR === 'true' &&
      value === null
    ) {
      console.log('This callback value is null:', value, callbacks[i])
    }
    const callback = callbacks[i]
    if (callback && value !== null) {
      callback(value)
    }
  }
}
