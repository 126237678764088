import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppButton from '../../components/buttons/AppButton/AppButton'

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`

export const ButtonsContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    column-gap: 8px;
    align-items: center;
    justify-content: center;
    width: 210px;

    @media only screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 100%;
      flex-direction: row;
      row-gap: 8px;
    }
  `}
`
export const DisconnectButton = styled(AppButton)`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.rd};
    padding: 2px 14px;
  `}
`
