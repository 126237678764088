import { ActionId } from '../config/action'

export const EXCEED_LTV_WHILE_UNSTAKING_ERROR_MESSAGE =
  'Unstaking this amount will cause your position to go beyond the borrow limit. Please repay USP first.'
export const UNEQUIP_NFT_WHILE_VOTED_ERROR_MESSAGE =
  'Your vePTP are being used for voting. Please unvote first before unequipping.'
export const EQUIP_NFT_WHILE_VOTED_ERROR_MESSAGE =
  'Your vePTP are being used for voting. Please unvote first before equipping.'
export const UNSTAKE_PTP_WHILE_VOTED_ERROR_MESSAGE =
  'Your vePTP are being used for voting. Please unvote first before unstaking.'
export const UNLOCK_PTP_WHILE_VOTED_ERROR_MESSAGE =
  'Your vePTP are being used for voting. Please unvote first before unlocking.'
const POOL_WITHDRWAL_PAUSE_MESSAGE = 'Withdraw is paused.'
const POOL_DEPOSIT_PAUSE_MESSAGE = 'Deposit is paused.'
const BORROW_USP_PAUSE_MESSAGE = 'Borrow is paused.'
const REPAY_USP_PAUSE_MESSAGE = 'Repay is paused.'
export const getCustomErrorMessage = (
  reason: string | null,
  type?: ActionId,
) => {
  if (!reason) {
    return null
  }
  // Notion: https://www.notion.so/platypus-finance/custom-err-message-If-users-borrow-USP-unstake-is-not-allowed-2a3609f148354b409f8e34b275d0236a
  if (reason.includes('remaining amount exceeds LTV')) {
    return EXCEED_LTV_WHILE_UNSTAKING_ERROR_MESSAGE
  }
  if (reason.includes('not enough vote')) {
    // Notion: https://www.notion.so/platypus-finance/Custom-error-message-for-unequipping-NFT-while-voted-c1303bd11aff4339a0ed74158672630d
    switch (type) {
      case ActionId.UNEQUIP_NFT:
        return UNEQUIP_NFT_WHILE_VOTED_ERROR_MESSAGE
      case ActionId.EQUIP_NFT:
        return EQUIP_NFT_WHILE_VOTED_ERROR_MESSAGE
      // Notion: https://www.notion.so/platypus-finance/voting-custom-error-msg-for-unstaking-PTP-when-users-have-not-unvoted-7f3ba7445432401dab51d0db5f5aa016
      case ActionId.UNSTAKE_PTP:
        return UNSTAKE_PTP_WHILE_VOTED_ERROR_MESSAGE
      case ActionId.UNLOCK_PTP:
        return UNLOCK_PTP_WHILE_VOTED_ERROR_MESSAGE
      default:
        return reason
    }
  }
  if (reason === 'execution reverted: Pausable: paused') {
    switch (type) {
      case ActionId.POOL_DEPOSIT:
        return POOL_DEPOSIT_PAUSE_MESSAGE
      case ActionId.POOL_WITHDRAWAL:
        return POOL_WITHDRWAL_PAUSE_MESSAGE
      default:
        return reason
    }
  }
  if (reason === 'execution reverted') {
    switch (type) {
      case ActionId.BORROW_USP:
        return BORROW_USP_PAUSE_MESSAGE
      case ActionId.REPAY_USP:
        return REPAY_USP_PAUSE_MESSAGE
      default:
        return reason
    }
  }
  return reason
}
