import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import ExportIcon from '../../../assets/icons/export-icon.svg'
import AppTypography from '../../../components/AppTypography/AppTypography'
import Modal from '../../../components/Modal/Modal'
import { AirdropId, AIRDROPS } from '../../../config/airdrops'
import { useModal } from '../../../contexts/ModalContext'
import { AirdropItemContainer } from './VePtpAirdropsModal.element'

interface Props {
  isOpen: boolean
}

function VePtpAirdropsModal({ isOpen }: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()

  function handleClickAirdrop(airdropId: AirdropId) {
    if (!AIRDROPS[airdropId].settings.enabled) return
    window.open(AIRDROPS[airdropId].url)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => modalDispatch(closeModal())}
      TopBarLabelComponent={
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          alignItems="center"
        >
          <AppTypography variant="h6"> vePTP holder Airdrops</AppTypography>
        </Box>
      }
    >
      {Object.values(AirdropId).map((airdropId) => {
        return (
          <AirdropItemContainer
            key={airdropId}
            onClick={() => handleClickAirdrop(airdropId)}
            $disabled={!AIRDROPS[airdropId].settings.enabled}
          >
            <Box display="flex" alignItems="center">
              <img
                src={AIRDROPS[airdropId].icon}
                alt={AIRDROPS[airdropId].name}
                width={24}
              />
              <AppTypography variant="body1" marginLeft={1}>
                {AIRDROPS[airdropId].name}{' '}
                {!AIRDROPS[airdropId].settings.enabled && '(Coming Soon)'}
              </AppTypography>
            </Box>
            <img src={ExportIcon} width={28} />
          </AirdropItemContainer>
        )
      })}
    </Modal>
  )
}

export default VePtpAirdropsModal
