import { Menu, MenuItem } from '@mui/material'
import styled, { css } from 'styled-components'

export const Container = styled(Menu)`
  ${({ theme }) => css`
    .MuiPaper-root {
      background: ${theme.palette.primary[900]};
      border: 1px solid ${theme.palette.primary['A200']};
      box-shadow: 0px 0px 20px rgba(0, 145, 222, 0.2);
      border-radius: ${theme.borderRadius.md};
      transform: translate(20px, 14px) !important;
      padding: 8px 8px;

      @media only screen and (max-width: ${theme.breakpoints.values.lg}px) {
        transform: translate(0px, 14px) !important;
      }
    }
  `}
`

export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    border-radius: 10px;
  }
`
