import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import { Box } from '@mui/material'
import React, { createContext, ReactElement, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import DimmedPTPIcon from '../../assets/icons/ptp-dimmed.svg'
import RecoloredPTPIcon from '../../assets/icons/ptp-recolored-cropped.svg'
import PlatypusCharacter from '../../assets/images/graphic-character-001.png'
import { tokenIconPaths } from '../../assets/tokens'
import Slidable from '../../components/animations/Slidable/Slidable'
import AppTypography from '../../components/AppTypography/AppTypography'
import AccordionButton from '../../components/buttons/AccordionButton/AccordionButton'
import AppButton from '../../components/buttons/AppButton/AppButton'
import TabBar from '../../components/TabBar/TabBar'
import TokenIcon from '../../components/TokenIcon/TokenIcon'
import { useModal } from '../../contexts/ModalContext'
import { useNetwork } from '../../contexts/NetworkContext'
import VePtpBoosterCalculatorProvider from '../../contexts/VePtpBoosterCalculatorContext'
import { useVePtp } from '../../contexts/VePtpContext'
import VePtpInputProvider from '../../contexts/VePtpInputContext'
import { Shade, StyledDivider } from '../../globalStyles'
import useBreakpoints from '../../hooks/useBreakpoints'
import useWalletConnection from '../../hooks/wallet/useWalletConnection'
import { ModalId } from '../../interfaces/Modal'
import { PtpFeatureTabId } from '../../interfaces/vePTP'
import ClaimSharingFeeModal from '../VePtpModalContainers/ClaimSharingFeeModal/ClaimSharingFeeModal'
import ClaimVePtpWaitForConfirmationModalContainer from '../VePtpModalContainers/ClaimVePtpWaitForConfirmationModalContainer/ClaimVePtpWaitForConfirmationModalContainer'
import ConfirmUnstakeOrUnlockModalContainer from '../VePtpModalContainers/ConfirmUnstakeOrUnlockModalContainer/ConfirmUnstakeOrUnlockModalContainer'
import ExtendPtpLockModalContainer from '../VePtpModalContainers/ExtendPtpLockModalContainer/ExtendPtpLockModalContainer'
import ExtendPtpLockWaitForConfirmationModal from '../VePtpModalContainers/ExtendPtpLockWaitForConfirmationModal/ExtendPtpLockWaitForConfirmationModal'
import LockPtpModalContainer from '../VePtpModalContainers/LockPtpModalContainer/LockPtpModalContainer'
import LockPtpWaitForConfirmationModal from '../VePtpModalContainers/LockPtpWaitForConfirmationModal/LockPtpWaitForConfirmationModal'
import StakeModalContainer from '../VePtpModalContainers/StakeModalContainer/StakeModalContainer'
import StakeWaitForConfirmationModalContainer from '../VePtpModalContainers/StakeWaitForConfirmationModalContainer/StakeWaitForConfirmationModalContainer'
import UnlockPtpWaitForConfirmationModal from '../VePtpModalContainers/UnlockPtpWaitForConfirmationModal/UnlockPtpWaitForConfirmationModal'
import UnstakeModalContainer from '../VePtpModalContainers/UnstakeModalContainer/UnstakeModalContainer'
import UnstakeWaitForConfirmationModalContainer from '../VePtpModalContainers/UnstakeWaitForConfirmationModalContainer/UnstakeWaitForConfirmationModalContainer'
import VePtpAirdropsModal from '../VePtpModalContainers/VePtpAirdropsModal/VePtpAirdropsModal'
import VePtpBoosterCalculatorModal from '../VePtpModalContainers/VePtpBoosterCalculatorModal/VePtpBoosterCalculatorModal'
// import ClaimableFeeSharing from './ClaimableFeeSharing/ClaimableFeeSharing'
import MyBalanceInfo from './MyBalanceInfo/MyBalanceInfo'
import NftEquipment from './NftEquipment/NftEquipment'
import PtpAllocationInfo from './PtpAllocationInfo/PtpAllocationInfo'
import PtpLockingInfo from './PtpLockingInfo/PtpLockingInfo'
import StakedPTPInfo from './StakedPTPInfo/StakedPTPInfo'
import TotalBalanceInfo from './TotalBalanceInfo/TotalBalanceInfo'
import UnlockPtpModalContainer from './UnlockPtpModalContainer/UnlockPtpModalContainer'
import { VePtpBenefitContainer } from './VePtpBenefit/VePtpBenefitContainer'
import {
  ButtonWrapper,
  HiddenContent,
  MainBody,
  PlatopiaBackground,
  PlatopiaButton,
  PTPStakeIcon,
  StakeDetailItem,
  StakeDetails,
  StyledPageContainer,
} from './VePtpContainer.elements'

export interface ContextType {
  isTabletSm: boolean
}

const PLATOPIA_BG_HEIGHT = 220
const PLATOPIA_BG_HEIGHT_MOBILE = 100
const VePtpContainerContext = createContext<ContextType>({} as ContextType)
export const useVePtpContainer = (): ContextType => {
  return useContext(VePtpContainerContext)
}
VePtpContainerContext.displayName = 'VePtpContainerContext'

function VePtpContainer(): ReactElement {
  const navigate = useNavigate()
  const { account } = useNetwork()
  const { openConnectToWalletModal } = useWalletConnection()
  const {
    ptp,
    input,
    actions: {
      setIsBenefitSectionCollapsed,
      setIsVePtpSectionCollapsed,
      handleClickTab,
    },
  } = useVePtp()
  const { isTabletSm } = useBreakpoints()
  const {
    modalState,
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const handleClickStake = () => {
    modalDispatch(openModal(ModalId.STAKE_PTP))
  }
  const handleClickUnstake = () => {
    modalDispatch(openModal(ModalId.UNSTAKE_PTP))
  }

  const renderHeadline = (
    <>
      <AppTypography
        variant={isTabletSm ? 'h5' : 'h4'}
        mb={isTabletSm ? '4px' : '12px'}
      >
        Ways to Obtain vePTP
      </AppTypography>
    </>
  )

  return (
    <VePtpContainerContext.Provider value={{ isTabletSm }}>
      <VePtpInputProvider>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          pt={isTabletSm ? '50px' : '20px'}
          margin="0px 16px 16px"
          flexDirection="column"
        >
          {isTabletSm && (
            <PTPStakeIcon
              src={RecoloredPTPIcon}
              alt="PTP token"
              enabled={ptp.hasStaked}
            />
          )}
          <StyledPageContainer
            margin={!isTabletSm ? `0 0 4px 0` : `0 0 12px 0`}
          >
            <Shade />
            {/* Link to platopia */}
            {!isTabletSm && (
              <Box position="absolute" top={30} right={20} zIndex={1}>
                <PlatopiaButton
                  imageSize="large"
                  onClick={() => {
                    navigate('/platopia')
                  }}
                >
                  <img src={PlatypusCharacter} alt="platopia character" />
                  <AppTypography variant="subtitle1">To Platopia</AppTypography>
                  <KeyboardArrowRightRoundedIcon fontSize="small" />
                </PlatopiaButton>
              </Box>
            )}
            {/* Headline */}
            {isTabletSm ? (
              <Box
                top={0}
                position="absolute"
                width="100%"
                display="flex"
                justifyContent="center"
                height={PLATOPIA_BG_HEIGHT_MOBILE}
              >
                <Box
                  zIndex={1}
                  position="relative"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="flex-start"
                  height="100%"
                  paddingTop="20px"
                >
                  {renderHeadline}
                  <PlatopiaButton disabled>
                    <img src={PlatypusCharacter} alt="platopia character" />
                    <AppTypography fontSize={10}>
                      Please visit Playtopia from desktop
                    </AppTypography>
                  </PlatopiaButton>
                </Box>
                <PlatopiaBackground height={PLATOPIA_BG_HEIGHT_MOBILE} />
              </Box>
            ) : (
              <PlatopiaBackground height={PLATOPIA_BG_HEIGHT} />
            )}
            {/* MainBody */}
            <MainBody
              // give some space for platopia background
              marginTop={`${
                isTabletSm
                  ? PLATOPIA_BG_HEIGHT_MOBILE - 10
                  : PLATOPIA_BG_HEIGHT - 110
              }px`}
            >
              {!isTabletSm && (
                <StakeDetails
                  display="flex"
                  flexDirection="column"
                  position="relative"
                  justifyContent="center"
                  alignItems="flex-end"
                  marginBottom="20px"
                >
                  <PTPStakeIcon
                    src={ptp.hasStaked ? RecoloredPTPIcon : DimmedPTPIcon}
                    alt="PTP token"
                    enabled={ptp.hasStaked}
                  />
                  <Box width="70%">{renderHeadline}</Box>
                </StakeDetails>
              )}
              <StakeDetails>
                <TotalBalanceInfo />
                <StyledDivider $colored style={{ margin: '10px 0 18px' }}>
                  <b>Benefits</b>
                  <AccordionButton
                    onClick={() =>
                      setIsBenefitSectionCollapsed((prev) => !prev)
                    }
                    active={!input.isBenefitSectionCollapsed}
                  />
                </StyledDivider>
                <VePtpBenefitContainer />
              </StakeDetails>
            </MainBody>
          </StyledPageContainer>
          {/** @todo replace borderRadius with global variable */}
          <ButtonWrapper>
            {!account && (
              <AppButton fullWidth onClick={openConnectToWalletModal}>
                Connect Wallet
              </AppButton>
            )}
          </ButtonWrapper>
          <StyledPageContainer
            margin={!isTabletSm ? `4px 0 0 0` : `12px 0 0 0`}
          >
            <MainBody>
              <StyledDivider $colored style={{ margin: '10px 0 18px' }}>
                <TokenIcon
                  iconPath={tokenIconPaths.vePTP}
                  margin="0 4px 0 8px"
                />
                <b>My vePTP</b>
                <AccordionButton
                  onClick={() => setIsVePtpSectionCollapsed((prev) => !prev)}
                  active={input.isVePtpSectionCollapsed}
                  data-testid="accordion-button"
                />
              </StyledDivider>
              <MyBalanceInfo />
              <StakeDetailItem
                $disableBackground={true}
                $noPadding={true}
                $isActive={true}
                width="100%"
              >
                <PtpAllocationInfo />
              </StakeDetailItem>
              {/* {account && (
                <StakeDetailItem width="100%" $isActive $noMargin>
                  <ClaimableFeeSharing />
                </StakeDetailItem>
              )} */}
              {input.isVePtpSectionCollapsed && (
                <Slidable
                  active={input.isVePtpSectionCollapsed}
                  durationInSec={0.4}
                >
                  <HiddenContent>
                    <StakeDetailItem $isActive={!!account}>
                      <NftEquipment />
                    </StakeDetailItem>
                    <Box display="flex" justifyContent="center">
                      <TabBar numOfTabs={2} width="230px" height="40px">
                        <TabBar.Tab
                          onClick={(e) =>
                            handleClickTab(e, PtpFeatureTabId.LOCK)
                          }
                          label={PtpFeatureTabId.LOCK}
                          activeLink={
                            input.currentTabId === PtpFeatureTabId.LOCK
                          }
                          labelProps={{ variant: 'subtitle2' }}
                          data-testid="switch-lock-button"
                        />
                        <TabBar.Tab
                          onClick={(e) =>
                            handleClickTab(e, PtpFeatureTabId.STAKE)
                          }
                          label={PtpFeatureTabId.STAKE}
                          activeLink={
                            input.currentTabId === PtpFeatureTabId.STAKE
                          }
                          labelProps={{ variant: 'subtitle2' }}
                          data-testid="switch-stake-button"
                        />
                      </TabBar>
                    </Box>
                    {input.currentTabId === PtpFeatureTabId.LOCK && (
                      <StakeDetails>
                        <StakeDetailItem
                          $isActive={!!account}
                          $disableBackground={true}
                          $noPadding={true}
                        >
                          <PtpLockingInfo />
                        </StakeDetailItem>
                      </StakeDetails>
                    )}
                    {input.currentTabId === PtpFeatureTabId.STAKE && (
                      <>
                        <StakeDetails>
                          <StakeDetailItem
                            $isActive={!!account}
                            $disableBackground={true}
                            $noPadding={true}
                          >
                            <StakedPTPInfo />
                          </StakeDetailItem>
                        </StakeDetails>
                        {/* Action buttons */}
                        <Box
                          display="flex"
                          flexDirection="row"
                          sx={{ width: '100%' }}
                        >
                          <AppButton
                            fullWidth
                            onClick={handleClickUnstake}
                            customVariant="neutral"
                            disabled={!ptp.hasStaked}
                          >
                            Unstake
                          </AppButton>
                          <AppButton
                            onClick={handleClickStake}
                            fullWidth
                            disabled={!account}
                          >
                            Stake
                          </AppButton>
                        </Box>
                      </>
                    )}
                  </HiddenContent>
                </Slidable>
              )}
            </MainBody>
          </StyledPageContainer>
        </Box>
        {modalState.currentModalId === ModalId.STAKE_PTP && (
          <StakeModalContainer isOpen />
        )}
        {modalState.currentModalId === ModalId.STAKE_WAIT_FOR_CONFIRMATION && (
          <StakeWaitForConfirmationModalContainer isOpen />
        )}
        {modalState.currentModalId === ModalId.UNSTAKE_PTP && (
          <UnstakeModalContainer isOpen />
        )}
        {(modalState.currentModalId === ModalId.CONFIRM_UNSTAKE_PTP ||
          modalState.currentModalId === ModalId.CONFIRM_UNLOCK_PTP) && (
          <ConfirmUnstakeOrUnlockModalContainer isOpen />
        )}
        {modalState.currentModalId ===
          ModalId.UNSTAKE_WAIT_FOR_CONFIRMATION && (
          <UnstakeWaitForConfirmationModalContainer isOpen />
        )}
        {modalState.currentModalId ===
          ModalId.CLAIM_VEPTP_WAIT_FOR_CONFIRMATION && (
          <ClaimVePtpWaitForConfirmationModalContainer isOpen />
        )}
        {modalState.currentModalId === ModalId.VEPTP_BOOSTER_CALCULATOR && (
          <VePtpBoosterCalculatorProvider>
            <VePtpBoosterCalculatorModal isOpen />
          </VePtpBoosterCalculatorProvider>
        )}
        {modalState.currentModalId === ModalId.SELECT_VEPTP_AIRDROP && (
          <VePtpAirdropsModal isOpen />
        )}
        {modalState.currentModalId === ModalId.UNLOCK_PTP && (
          <UnlockPtpModalContainer isOpen />
        )}
        {modalState.currentModalId === ModalId.LOCK_PTP && (
          <LockPtpModalContainer isOpen />
        )}
        {modalState.currentModalId ===
          ModalId.LOCK_PTP_WAIT_FOR_CONFIRMATION && (
          <LockPtpWaitForConfirmationModal isOpen />
        )}
        {modalState.currentModalId ===
          ModalId.LOCK_EXTEND_PTP_WAIT_FOR_CONFIRMATION && (
          <ExtendPtpLockWaitForConfirmationModal isOpen />
        )}
        {modalState.currentModalId ===
          ModalId.UNLOCK_PTP_WAIT_FOR_CONFIRMATION && (
          <UnlockPtpWaitForConfirmationModal isOpen />
        )}
        {modalState.currentModalId === ModalId.EXTEND_LOCK_PTP && (
          <ExtendPtpLockModalContainer isOpen />
        )}
        {modalState.currentModalId === ModalId.FEE_SHARING && (
          <ClaimSharingFeeModal isOpen />
        )}
      </VePtpInputProvider>
    </VePtpContainerContext.Provider>
  )
}

export default VePtpContainer
