import { useCallback } from 'react'
import { ChainId } from '../../config/networks'
import { NFT_SUBGRPAH_QUERY_SIZE } from '../../constants'
import { useNetwork } from '../../contexts/NetworkContext'
import { NftTypeId, PlatypusNFT } from '../../interfaces/nft'
import fetchGraphql from '../../utils/fetchGraphql'

interface NftSubgraphReturnType {
  id: string // '0'
  name: string // 'Unnamed' or the user-given name
  chainOwner: string // owner address
  image: string // image url
  ability: string[] // array with 1 string. example [ 'Diligent' ]
  power: string[] // array with 1 string. example [ '7' ]
}

interface ReturnType {
  fetchNfts: (id?: string) => Promise<{ [id: string]: PlatypusNFT } | null>
}

/**
 * transform the type returned from subgraph to the type used in context
 * @param {NftSubgraphReturnType} data returned from subgraph
 */
function transformToPlatypusNFT(
  data: NftSubgraphReturnType,
  chainId: ChainId,
): PlatypusNFT {
  return {
    id: data.id,
    name: data.name,
    owner: data.chainOwner,
    // To avoid errors in develpment
    imgSrc:
      chainId === ChainId.AVALANCHE
        ? data.image
        : `https://nft.platypus.finance/api/platypus/${data.id}`,
    type: data.ability[0] as NftTypeId,
    value: data.power[0],
  }
}

/** @todo write test for this function */
function useNftSubgraph(): ReturnType {
  const { network, chainId, account } = useNetwork()
  let nftSubgraphApi: string | null = null
  if (network) {
    nftSubgraphApi = network.apis.nftSubgraph
  }

  /**
   * @async
   * @param {string?} id fetch only the NFT with this id. If not provided, fetch all NFTs owned by the user
   * @returns {{[id: string]: PlatypusNFT} | null}
   */
  const fetchNfts = useCallback(
    async (id?: string) => {
      try {
        if (!account || !nftSubgraphApi) return null
        let whereClause = ''

        if (id) {
          whereClause = `where: { tokenId: "${id}" }`
        } else {
          whereClause = `where: { chainOwner: "${account}" }`
        }

        const getQuery = (numberOfNftsSkipped: number) => {
          return `
        {
            nfts: platypusNFTs(
              orderBy: tokenId
              orderDirection: desc
              first: ${NFT_SUBGRPAH_QUERY_SIZE}
              skip: ${numberOfNftsSkipped}
              ${whereClause}
            ) {
              id
              name
              chainOwner
              image
              ability
              power
            }
          }
        `
        }

        // isEnded = has reached the end of the graphql result
        let isEnded = false
        let numberOfNftsSkipped = 0
        let totalNftData: NftSubgraphReturnType[] = []
        while (!isEnded) {
          // the response will either contain errors or data
          const { data, errors } = await fetchGraphql<{
            nfts: NftSubgraphReturnType[]
          }>(nftSubgraphApi, getQuery(numberOfNftsSkipped))

          if (errors) {
            throw new Error(errors.map((err) => err.message).toString())
          }

          if (data) {
            if (data.nfts.length === 0) {
              isEnded = true
              break
            } else {
              totalNftData = [...totalNftData, ...data.nfts]
              numberOfNftsSkipped += NFT_SUBGRPAH_QUERY_SIZE
              if (data.nfts.length < NFT_SUBGRPAH_QUERY_SIZE) {
                isEnded = true
                break
              }
            }
          } else {
            isEnded = true
            break
          }
        }

        if (totalNftData.length) {
          const nftsArr = totalNftData.map((nftData) =>
            transformToPlatypusNFT(nftData, chainId),
          )

          return nftsArr.reduce((acc, nft) => {
            return {
              ...acc,
              [nft.id]: nft,
            }
          }, {})
        }
      } catch (err) {
        console.error(err)
      }
      return null
    },
    [nftSubgraphApi, account, chainId],
  )

  return { fetchNfts }
}

export default useNftSubgraph
