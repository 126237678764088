import React, { ReactElement } from 'react'
import { filterPoolIconPaths } from '../../assets/filterPool'
import DefaultIcon from '../../assets/icons/platypus-white.svg'
import { PoolFilterSymbol } from '../../config/contracts/pool/poolSymbol'
import { TOKENS } from '../../config/contracts/token'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'

interface Props {
  tokenSymbol?: TokenSymbol | null
  filteredPoolSymbol?: PoolFilterSymbol | null
  className?: string
  margin?: string
  iconPath?: string
  size?: number
  style?: React.CSSProperties
}

/**
 *
 * Either @param {TokenSymbol} tokenSymbol
 * or @param {string} iconPath should be specified to get an icon path
 *
 */

function TokenIcon({
  tokenSymbol,
  filteredPoolSymbol,
  className,
  margin,
  iconPath,
  size = 23,
  style,
}: Props): ReactElement {
  let src
  if (tokenSymbol) {
    src = TOKENS[tokenSymbol].icon
  } else if (filteredPoolSymbol) {
    src = filterPoolIconPaths[filteredPoolSymbol]
  } else if (iconPath) {
    src = iconPath
  } else {
    src = DefaultIcon
  }

  return (
    <img
      className={className}
      width={size}
      height={size}
      style={{ ...style, margin: margin }}
      src={src}
    />
  )
}

export default TokenIcon
