import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import React, { ReactElement } from 'react'
import { Colorized, ExternalLink } from '../../globalStyles'
import useLocalStorage from '../../hooks/useLocalStorage'
import AppTypography from '../AppTypography/AppTypography'
import TokenIcon from '../TokenIcon/TokenIcon'
import { Container } from './UrlReminder.elements'

export interface UrlReminderProps {
  isPerm?: boolean
  variant: 'bookmark' | 'usp-repay' | 'pool-refund'
}

function UrlReminder({ isPerm, variant }: UrlReminderProps): ReactElement {
  const [isUrlReminderShown, setIsUrlReminderShown] = useLocalStorage({
    key: `isUrlReminderShown-${variant}`,
    initialValue: true,
  })
  const onClose = () => {
    setIsUrlReminderShown(false)
  }

  if (!isUrlReminderShown) return <></>

  const renderContent = () => {
    switch (variant) {
      case 'pool-refund':
        return (
          <>
            For LPs who have deposited in the old pools, please visit our &nbsp;
            <Colorized variant="yellow">
              <ExternalLink href="https://airdrop.platypus.finance/">
                withdrawal page
              </ExternalLink>
            </Colorized>
            &nbsp; to retrieve your funds.
          </>
        )
      case 'bookmark':
        return (
          <>
            <TokenIcon margin="0 4px 0 0" />
            Always make sure the URL is&nbsp;
            <Colorized variant="yellow">
              <b>https://app.platypus.finance</b>
            </Colorized>
            &nbsp;&nbsp;Bookmark it to be safe.&nbsp;
            <AppTypography
              variant="subtitle1"
              centerContent
              justifyContent="center"
              component="span"
            >
              (Ctrl+D or Cmd+D)&nbsp;
              <BookmarkBorderIcon />
            </AppTypography>
          </>
        )
      case 'usp-repay':
        return (
          <>
            The old main pool was&nbsp;
            <Colorized variant="yellow">
              <ExternalLink href="https://twitter.com/Platypusdefi/status/1626396538611310592">
                exploited
              </ExternalLink>
            </Colorized>
            , and so far we were able to recover some of the stolen funds. Old
            main pool LPs and USP holders at the time of the attack will be
            compensated. Please visit this&nbsp;
            <Colorized variant="yellow">
              <ExternalLink href="https://airdrop.platypus.finance/usp-compensation">
                page
              </ExternalLink>
            </Colorized>
            &nbsp;to see your compensation amount.
          </>
        )
      default:
        return <></>
    }
  }
  return (
    <Container isPerm={isPerm}>
      <AppTypography
        variant="subtitle1"
        justifyContent="center"
        textAlign="center"
        className="UrlReminder__text"
      >
        {renderContent()}
      </AppTypography>
      {!isPerm && (
        <IconButton className="UrlReminder__close-button" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </Container>
  )
}

export default UrlReminder
