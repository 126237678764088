import React, { ReactElement } from 'react'
import WaitForConfirmationModal from '../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import { useModal } from '../../../contexts/ModalContext'
import { ModalId } from '../../../interfaces/Modal'
import DisabledTokenInputsContainer from '../../SwapContainer/DisabledTokenInputsContainer'
import SwapInfoBoxContainer from '../../SwapContainer/SwapInfoBoxContainer'
interface Props {
  isOpen: boolean
}
function SwapWaitForConfirmationModalContainer({
  isOpen,
}: Props): ReactElement {
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  // Cancel the transaction
  const handleCloseModal = () => {
    modalDispatch(openModal(ModalId.UNSET))
  }
  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="100%"
    >
      <DisabledTokenInputsContainer />
      <SwapInfoBoxContainer paddingBottom="0" />
    </WaitForConfirmationModal>
  )
}

export default React.memo(SwapWaitForConfirmationModalContainer)
