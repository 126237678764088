import { getDpFormat, isParsableString } from '@hailstonelabs/big-number-utils'
import RefreshIcon from '@mui/icons-material/Refresh'
import { alpha, useTheme } from '@mui/material'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import AppTokenInput from '../../../components/TokenInput/AppTokenInput'
import { TOKENS } from '../../../config/contracts'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useVePtpBoosterCalculator } from '../../../contexts/VePtpBoosterCalculatorContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { StyledDivider } from '../../../globalStyles'

function MyVePtpContainer(): ReactElement {
  return (
    <>
      <StyledDivider $colored>
        <TokenIcon tokenSymbol={TokenSymbol.VEPTP} margin="0 4px 0 0" /> My
        vePTP
      </StyledDivider>
      <MyVePtpInput />
    </>
  )
}

export default MyVePtpContainer

function MyVePtpInput(): ReactElement {
  const theme = useTheme()
  const {
    myVePtpInputAmount,
    actions: { resetMyVePtpInput, updateMyVePtpInput },
  } = useVePtpBoosterCalculator()
  const { vePtp } = useVePtp()
  const isResettable = useMemo(
    () => vePtp.balance.total.current !== myVePtpInputAmount,

    [myVePtpInputAmount, vePtp.balance.total],
  )
  const [isFormattedInitialValueShown, setIsFormattedInitialValueShown] =
    useState(true)
  const handleInputChange = (value: string) => {
    if (isFormattedInitialValueShown) {
      setIsFormattedInitialValueShown(false)
    }
    if (value === '') {
      updateMyVePtpInput('')
      return
    }
    if (!isParsableString(value, TOKENS[TokenSymbol.VEPTP].decimals, true))
      return

    updateMyVePtpInput(value)
  }

  const resetInput = () => {
    resetMyVePtpInput()
    setIsFormattedInitialValueShown(true)
  }
  // only reset once
  useEffect(() => {
    resetMyVePtpInput()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <AppTokenInput
      value={
        isFormattedInitialValueShown
          ? getDpFormat(myVePtpInputAmount)
          : myVePtpInputAmount
      }
      onChange={handleInputChange}
      style={{ paddingTop: '12px' }}
    >
      <AppTokenInput.InnerContainer
        bgcolor={alpha(theme.palette.primary[500], 0.2)}
      >
        <AppTokenInput.InputField
          placeholder="0.0"
          disableUnderline
          textalign="left"
          noLeftPadding
        />
        <AppTokenInput.ActionButton
          onClick={resetInput}
          disabled={!isResettable}
        >
          <RefreshIcon />
        </AppTokenInput.ActionButton>
      </AppTokenInput.InnerContainer>
    </AppTokenInput>
  )
}
