import styled from 'styled-components'
import AppTypography from '../../../components/AppTypography/AppTypography'

export const DataTypography = styled(AppTypography).attrs(() => ({
  variant: 'body2',
}))``

export const Summary = styled.div`
  margin: 6px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 4px;
  flex-wrap: wrap;
`
export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`
