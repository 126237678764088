import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import {
  Box,
  BoxProps,
  ButtonProps,
  SliderProps,
  Typography,
} from '@mui/material'
import clsx from 'clsx'
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
} from 'react'
import DefaultIcon from '../../assets/icons/platypus-white.svg'
import { POOLS } from '../../config/contracts'
import { PoolSymbol } from '../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { getNumberIntervalArr } from '../../utils/getNumberIntervalArr'
import AppTypography from '../AppTypography/AppTypography'
import { InputLabel, LabelProps } from '../Input/Input'
import SelectItemButton from '../SelectItemButton/SelectItemButton'
import TokenIcon from '../TokenIcon/TokenIcon'
import {
  ActionButtonContainer,
  Label,
  LabelItemInnerContainer,
  NumberDisplayBox,
  NumberDisplayWrapper,
  StyledButton,
  StyledInnerContainer,
  StyledInput,
  StyledInputProps,
  StyledSlider,
  SwapDirectionIconContainer,
  TokenInputContainer,
} from './AppTokenInput.elements'

interface ContextType {
  value: string
  sliderValue?: string
  onChange?: (value: string) => void
  onSliderChange?: (value: string) => void
  disabled?: boolean
}
const TokenInputContext = createContext<ContextType>({} as ContextType)
TokenInputContext.displayName = 'TokenInputContext'

const useTokenInput = () => {
  return useContext(TokenInputContext)
}
interface Props {
  onChange?: (value: string) => void
  value: string
  disabled?: boolean
  className?: string
  children: React.ReactNode
  style?: React.CSSProperties
  onSliderChange?: (value: string) => void
  sliderValue?: string
}
function AppTokenInput({
  onChange,
  onSliderChange,
  sliderValue,
  value,
  disabled,
  className,
  children,
  style,
}: Props): ReactElement {
  return (
    <TokenInputContext.Provider
      value={{ onChange, value, disabled, onSliderChange, sliderValue }}
    >
      <TokenInputContainer
        display="flex"
        flexDirection="column"
        className={className}
        style={style}
      >
        {children}
      </TokenInputContainer>
    </TokenInputContext.Provider>
  )
}

export default AppTokenInput

interface IconWrapperProps {
  className?: string | undefined
  onClick?: () => void
  disabled?: boolean | undefined
  isMobile?: boolean | undefined
}
const SwapDirectionButton = ({
  className,
  onClick,
  disabled,
  isMobile,
}: IconWrapperProps): ReactElement => {
  return (
    <SwapDirectionIconContainer
      className={clsx(
        isMobile && 'swap-direction-button--mobile',
        className && className,
      )}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      disabled={disabled}
    >
      <KeyboardArrowDownRoundedIcon />
    </SwapDirectionIconContainer>
  )
}

const LabelItem = ({
  className,
  leadingLabel,
  trailingLabel,
  style,
}: LabelProps): ReactElement => {
  return (
    <InputLabel
      className={className}
      leadingLabel={leadingLabel}
      trailingLabel={trailingLabel}
      style={style}
    />
  )
}

interface ActionButtonProps extends ButtonProps {
  className?: string | undefined
  onClick?: () => void | undefined
  children?: React.ReactNode | undefined
  spacer?: boolean | undefined
}

const ActionButton = ({
  className,
  onClick,
  children,
  disabled,
  spacer,
  ...otherProps
}: ActionButtonProps): ReactElement => {
  return (
    <ActionButtonContainer>
      {!spacer && (
        <StyledButton
          className={className}
          onClick={disabled ? undefined : onClick}
          disabled={disabled}
          {...otherProps}
          customVariant="neutral"
        >
          {children && (
            <Typography variant="subtitle2" sx={{ display: 'flex' }}>
              {children}
            </Typography>
          )}
        </StyledButton>
      )}
    </ActionButtonContainer>
  )
}

function getLabel(
  token: TokenSymbol | null | undefined,
  isCollateral: boolean | undefined,
  poolSymbol: PoolSymbol | null | undefined,
) {
  let label = 'Select a Token'
  if (isCollateral && !token) {
    label = 'Select a collateral asset'
  }
  if (token) {
    label = token
  }
  if (token && poolSymbol) {
    const pool = POOLS[poolSymbol]
    label = `${token} (${pool.name.replace(' Pool', '')})`
  }
  return label
}

interface SelectTokenButtonProps {
  className?: string | undefined
  token?: TokenSymbol | null
  onSelectToken?: () => void | undefined
  disabled?: boolean | undefined
  iconPath?: string
  isCollateral?: boolean | undefined
  trailingLabel?: string
  poolSymbol?: PoolSymbol | null
}
const SelectTokenButton = ({
  className,
  token,
  onSelectToken,
  disabled,
  iconPath,
  isCollateral,
  trailingLabel,
  poolSymbol,
}: SelectTokenButtonProps) => {
  return (
    <SelectItemButton
      className={clsx(
        'app-token-input__select-token-button',
        className && className,
      )}
      onSelectItem={onSelectToken}
      disabled={disabled}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex">
          <SelectItemButton.ItemIconWrapper>
            {token ? (
              <TokenIcon tokenSymbol={token} />
            ) : (
              <TokenIcon iconPath={iconPath || DefaultIcon} />
            )}
          </SelectItemButton.ItemIconWrapper>
          <SelectItemButton.ItemLabel>
            {getLabel(token, isCollateral, poolSymbol)}
          </SelectItemButton.ItemLabel>
        </Box>
        {trailingLabel && token && <Box>{trailingLabel}</Box>}
      </Box>
    </SelectItemButton>
  )
}

interface InputFieldProps extends StyledInputProps {
  placeholder: string
  className?: string | undefined
  children?: React.ReactNode | undefined
  style?: React.CSSProperties | undefined
  readOnly?: 'readOnly' | undefined
  inputUnitLabel?: string | undefined
  textalign?: string
}
const InputField = ({
  style,
  className,
  children,
  placeholder,
  disableUnderline,
  readOnly,
  inputUnitLabel,
  disabled,
  textalign = 'right',
  noLeftPadding,
}: InputFieldProps): ReactElement => {
  const { value, onChange } = useTokenInput()
  return (
    <StyledInput
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      flexDirection="row-reverse"
      textalign={textalign}
      inputMode="decimal"
      bgColor=""
      disableUnderline={disableUnderline}
      inputUnitLabel={inputUnitLabel}
      readOnly={readOnly}
      disabled={disabled}
      style={style}
      noLeftPadding={noLeftPadding}
    >
      {children && children}
    </StyledInput>
  )
}
interface InnerContainerProps extends BoxProps {
  flexDirection?: 'column' | 'row'
}
const InnerContainer = ({
  children,
  flexDirection = 'row',
  ...otherProps
}: InnerContainerProps) => {
  return (
    <StyledInnerContainer flexDirection={flexDirection} {...otherProps}>
      {children}
    </StyledInnerContainer>
  )
}

function getLabelForSlider(
  min: number,
  max: number,
  sliderLabelType: 'day' | 'percentage',
) {
  if (sliderLabelType === 'percentage') {
    return Array(5)
      .fill(0)
      .map((_, index) => {
        return { value: index * 25 }
      })
  }
  return getNumberIntervalArr(min, max, 4).concat({ value: max })
}

interface AppSliderProps extends SliderProps {
  railWidth?: string
  sliderLabelType?: 'day' | 'percentage'
  onSliderChange?: (value: string) => void
  railColor?: SliderRailColorType
}

export enum SliderRailColorType {
  PRIMARY = 'primary',
  INFO = 'info',
  WARNING = 'warning',
}

const Slider = ({
  className,
  railWidth,
  max = 100,
  min = 0,
  sliderLabelType,
  disabled,
  railColor = SliderRailColorType.PRIMARY,
  ...otherProps
}: AppSliderProps) => {
  const { value, onChange, sliderValue, onSliderChange } = useTokenInput()

  const isMarkTransparent = (
    disabled: boolean | undefined,
    marksValue: number,
    max: number | undefined,
    sliderLabelType: 'day' | 'percentage',
  ) => {
    if (disabled || !max || !sliderValue) return true
    if (sliderLabelType === 'percentage') {
      const currentPercentage = (Number(sliderValue) / max) * 100
      return marksValue > currentPercentage
    }
    if (sliderLabelType === 'day') {
      return marksValue > Number(sliderValue)
    }
  }

  const handleSliderChange = (value: number | number[]) => {
    const isNumber = typeof value === 'number'
    const newValue = isNumber ? String(value) : ''
    // use onSliderChange when there is onSliderChange props
    if (onSliderChange) {
      onSliderChange(newValue)
    } else if (onChange) {
      onChange(newValue)
    }
  }

  useEffect(() => {
    // for styling slider marks
    const element = document.querySelector('.AppTokenInput__slider')
    if (element) {
      const markElements = element.querySelectorAll('.MuiSlider-mark')
      const disabledMarkElements = element.querySelectorAll(
        '.MuiSlider-mark.disabled',
      )
      // clean up already disabled marks
      if (disabledMarkElements.length > 0) {
        disabledMarkElements.forEach((disabledMarkElement) => {
          disabledMarkElement.classList.remove('disabled')
        })
      }
      const maxPercentage = element
        .getAttribute('data-rail-width')
        ?.split('%')[0]

      // total 5 marks, 0, 20, 40, 60, 80
      if (maxPercentage && markElements && maxPercentage !== '100') {
        const totalActiveInterval = Math.floor(parseFloat(maxPercentage) / 20)

        for (let i = totalActiveInterval + 1; i < markElements.length; i++) {
          markElements[i].classList.add('disabled')
        }
      }
    }
  }, [railWidth])

  return (
    <>
      <Box display="flex" flexDirection="column">
        <StyledSlider
          className={clsx('AppTokenInput__slider', className && className)}
          valueLabelDisplay="off"
          marks={getNumberIntervalArr(min, max, 4)}
          min={min}
          max={max}
          value={onSliderChange ? Number(sliderValue) : Number(value)}
          onChange={(_, value) => handleSliderChange(value)}
          $railWidth={railWidth}
          $railColor={railColor}
          data-rail-width={railWidth}
          disabled={disabled}
          {...otherProps}
          style={{ marginBottom: 0 }}
        />
        {sliderLabelType && (
          <NumberDisplayWrapper display="flex" justifyContent="space-between">
            {getLabelForSlider(min, max, sliderLabelType).map((mark) => {
              return (
                <NumberDisplayBox key={mark.value}>
                  <AppTypography
                    variant="caption"
                    transparent={isMarkTransparent(
                      disabled,
                      mark.value,
                      max,
                      sliderLabelType,
                    )}
                  >
                    {mark.value}
                    {sliderLabelType === 'day' && 'd'}
                    {sliderLabelType === 'percentage' && '%'}
                  </AppTypography>
                </NumberDisplayBox>
              )
            })}
          </NumberDisplayWrapper>
        )}
      </Box>
    </>
  )
}

AppTokenInput.LabelItem = React.memo(LabelItem)
AppTokenInput.LabelItemInnerContainer = React.memo(LabelItemInnerContainer)
AppTokenInput.Label = React.memo(Label)
AppTokenInput.ActionButton = React.memo(ActionButton)
AppTokenInput.SelectTokenButton = React.memo(SelectTokenButton)
AppTokenInput.InputField = React.memo(InputField)
AppTokenInput.SwapDirectionButton = React.memo(SwapDirectionButton)
AppTokenInput.InnerContainer = React.memo(InnerContainer)
AppTokenInput.Slider = React.memo(Slider)
