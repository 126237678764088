import {
  getCommifiedFormat,
  getDpFormat,
} from '@hailstonelabs/big-number-utils'
import React, { ReactElement } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import { useVePtp } from '../../../contexts/VePtpContext'
import { useVotingData } from '../../../contexts/VotingDataContext'
import { Colorized } from '../../../globalStyles'
import { Body, Container, Item } from './UserVotesSummary.elements'

function UserVotesSummary(): ReactElement {
  const { vePtp } = useVePtp()
  const { user } = useVotingData()
  return (
    <Container>
      <AppTypography variant="h6" marginBottom="12px">
        Your votes
      </AppTypography>
      <Body>
        <Item>
          <AppTypography variant="h5">Your voting power</AppTypography>
          <AppTypography variant="h4">
            <TooltipNum
              amount={vePtp.balance.total.current}
              rightSymbol="vePTP"
            >
              <Colorized>
                {getCommifiedFormat(vePtp.balance.total.current)}&nbsp;
                <AppTypography variant="body1" component="span">
                  vePTP
                </AppTypography>
              </Colorized>
            </TooltipNum>
          </AppTypography>
        </Item>
        <Item marginTop="8px">
          <AppTypography variant="subtitle1">
            <AppTypography component="span" transparent>
              Used votes:&nbsp;
            </AppTypography>
            <AppTypography component="span" centerContent>
              {getDpFormat(user.usedVote.percentage)}%
              <InfoBox.Tooltip text="The percentage of vePTP you used to vote." />
            </AppTypography>
          </AppTypography>
          <AppTypography variant="subtitle1">
            <AppTypography component="span" transparent>
              Remaining votes:&nbsp;
            </AppTypography>
            <AppTypography component="span" centerContent>
              <TooltipNum amount={user.remainingVote.vePtp} rightSymbol="vePTP">
                {getCommifiedFormat(user.remainingVote.vePtp)} vePTP
              </TooltipNum>
              <InfoBox.Tooltip text="The amount of vePTP you can use to vote." />
            </AppTypography>
          </AppTypography>
        </Item>
      </Body>
    </Container>
  )
}

export default UserVotesSummary
