import iceTowerImage from './assets/images/ice-tower.png'
import marketImage from './assets/images/market.png'
import market2Image from './assets/images/market2.png'
import marketBehindImage from './assets/images/marketBehind.png'
import marketBehind2Image from './assets/images/marketBehind2.png'
import snowFrontImage from './assets/images/snowFront.png'
import snowmanImage from './assets/images/snowman.png'
import cloudLeftData from './assets/lotties/cloud-left.json'
import cloudMiddleData from './assets/lotties/cloud-middle.json'
import cloudRightData from './assets/lotties/cloud-right.json'
import coinSnowData from './assets/lotties/coin-snow.json'
import marketFishData from './assets/lotties/market-fish.json'
import marketLeafData from './assets/lotties/market-leaf.json'
import platypusBuyerFishBasketData from './assets/lotties/platypus-buyer-fish-basket.json'
import platypusBuyerPumpkinBasketData from './assets/lotties/platypus-buyer-pumpkin-basket.json'
import platypusFireData from './assets/lotties/platypus-fire.json'
import platypusFishingData from './assets/lotties/platypus-fishing.json'
import platypusMainData from './assets/lotties/platypus-main.json'
import platypusSalesBoxData from './assets/lotties/platypus-sales-box.json'
import platypusWalkSalesCrabData from './assets/lotties/platypus-sales-crab.json'
import platypusSalesFishData from './assets/lotties/platypus-sales-fish.json'
import platypusSalesPumpkinData from './assets/lotties/platypus-sales-pumpkin.json'
import platypusSalesSleepData from './assets/lotties/platypus-sales-sleep.json'
import platypusSnow1Data from './assets/lotties/platypus-snow1.json'
import platypusSnow2Data from './assets/lotties/platypus-snow2.json'
import pondIceData from './assets/lotties/pond-ice.json'
import smokeBigData from './assets/lotties/smoke-big.json'
import windmillData from './assets/lotties/windmill.json'

export enum Stage {
  stage1 = 'STAGE_1',
  stage2 = 'STAGE_2',
  stage3 = 'STAGE_3',
  stage4 = 'STAGE_4',
  stage5 = 'STAGE_5',
}

const lottieSetting = {
  windmill: {
    key: 'windmill',
    left: 348,
    top: 149,
    width: 100,
    height: 100,
    data: windmillData,
    zIndex: 20,
  },
  smokeBig: {
    key: 'smokeBig',
    right: 48,
    top: 160,
    width: 75,
    height: 75,
    data: smokeBigData,
    zIndex: 20,
  },
  cloudMiddle: {
    key: 'cloudMiddle',
    left: 250,
    top: -35,
    width: 200,
    height: 200,
    data: cloudMiddleData,
    zIndex: 20,
  },
  cloudLeft: {
    key: 'cloudLeft',
    left: 0,
    top: -65,
    width: 200,
    height: 200,
    data: cloudLeftData,
    zIndex: 20,
  },
  cloudRight: {
    key: 'cloudRight',
    right: 0,
    top: -80,
    width: 300,
    height: 300,
    data: cloudRightData,
    zIndex: 20,
  },
  smokeSmall: {
    key: 'smokeSmall',
    left: 463,
    top: 173,
    width: 45,
    height: 45,
    data: smokeBigData,
    zIndex: 20,
  },
  platypusMain: {
    key: 'platypusMain',
    bottom: 30,
    left: 130,
    width: 90,
    height: 90,
    data: platypusMainData,
    zIndex: 100,
  },
  platypusSalesFish: {
    key: 'platypusSalesFish',
    left: 275,
    bottom: 172,
    width: 55,
    height: 55,
    data: platypusSalesFishData,
    zIndex: 20,
  },
  platypusSnow2: {
    key: 'platypusSnow2',
    left: 410,
    bottom: -40,
    width: 200,
    height: 200,
    data: platypusSnow2Data,
    zIndex: 100,
  },
  platypusSnow2ForStage2: {
    key: 'platypusSnow2ForStage2',
    left: 310,
    bottom: 30,
    width: 230,
    height: 230,
    data: platypusSnow2Data,
    zIndex: 50,
  },
  platypusBuyerPumpkinBasket: {
    key: 'platypusBuyerPumpkinBasket',
    right: 330,
    bottom: 190,
    width: 55,
    height: 55,
    data: platypusBuyerPumpkinBasketData,
    zIndex: 60,
  },
  platypusSalesPumpkin: {
    key: 'platypusSalesPumpkin',
    left: 177,
    bottom: 170,
    width: 55,
    height: 55,
    data: platypusSalesPumpkinData,
    zIndex: 20,
  },
  platypusSalesSleep: {
    key: 'platypusSalesSleep',
    right: 230,
    bottom: 220,
    width: 55,
    height: 55,
    data: platypusSalesSleepData,
    zIndex: 20,
  },
  platypusSalesBox: {
    key: 'platypusSalesBox',
    left: 430,
    bottom: 147,
    width: 70,
    height: 70,
    data: platypusSalesBoxData,
    zIndex: 20,
  },
  platypusFishing: {
    key: 'platypusFishing',
    right: 190,
    bottom: 110,
    width: 130,
    height: 130,
    data: platypusFishingData,
    zIndex: 60,
  },
  platypusSnow1: {
    key: 'platypusSnow1',
    right: 330,
    bottom: 100,
    width: 70,
    height: 70,
    zIndex: 70,
    data: platypusSnow1Data,
  },
  platypusFire: {
    key: 'platypusFire',
    right: 0,
    bottom: 80,
    width: 150,
    height: 150,
    zIndex: 20,
    data: platypusFireData,
  },
  platypusWalkSalesCrab: {
    key: 'platypusWalkSalesCrab',
    left: 363,
    bottom: 168,
    width: 50,
    height: 50,
    zIndex: 20,
    data: platypusWalkSalesCrabData,
  },
  platypusBuyerFishBasket: {
    key: 'platypusBuyerFishBasket',
    left: 290,
    bottom: 120,
    width: 60,
    height: 60,
    zIndex: 40,
    data: platypusBuyerFishBasketData,
  },
  marketLeaf: {
    key: 'marketLeaf',
    left: 163,
    bottom: 183,
    width: 70,
    height: 70,
    zIndex: 15,
    data: marketLeafData,
  },
  pondIce: {
    key: 'pondIce',
    right: 165,
    bottom: 48,
    width: 130,
    height: 130,
    zIndex: 20,
    data: pondIceData,
  },
  marketFish: {
    key: 'marketFish',
    left: 349,
    bottom: 207,
    width: 28,
    height: 28,
    zIndex: 20,
    data: marketFishData,
  },
  coinSnow: {
    key: 'coinSnow',
    left: 50,
    bottom: 120,
    width: 200,
    height: 200,
    zIndex: 120,
    data: coinSnowData,
  },
}

const imageSetting = {
  iceTower: {
    key: 'iceTower',
    left: 20,
    bottom: 15,
    width: 122,
    height: 226,
    data: iceTowerImage,
    zIndex: 100,
  },
  market: {
    key: 'market',
    left: 120,
    bottom: 130,
    width: 410,
    height: 143,
    data: marketImage,
    zIndex: 30,
  },
  market2: {
    key: 'market2',
    right: 205,
    bottom: 206,
    width: 245,
    height: 85,
    data: market2Image,
    zIndex: 30,
  },
  snowman: {
    key: 'snowman',
    right: 380,
    bottom: 60,
    width: 62,
    height: 69,
    data: snowmanImage,
    zIndex: 80,
  },
  marketBehind: {
    key: 'marketBehind',
    left: 105,
    bottom: 110,
    width: 410,
    height: 178,
    data: marketBehindImage,
    zIndex: 10,
  },
  marketBehind2: {
    key: 'marketBehind2',
    right: 205,
    bottom: 209,
    width: 80,
    height: 76,
    data: marketBehind2Image,
    zIndex: 10,
  },
  snowFront: {
    key: 'snowFront',
    left: 200,
    bottom: 80,
    width: 55,
    height: 26,
    zIndex: 50,
    data: snowFrontImage,
  },
}

export default {
  [Stage.stage1]: {
    lotties: [
      lottieSetting.cloudMiddle,
      lottieSetting.cloudLeft,
      lottieSetting.cloudRight,
      lottieSetting.coinSnow,
    ],
    images: [imageSetting.iceTower],
  },
  [Stage.stage2]: {
    lotties: [
      lottieSetting.windmill,
      lottieSetting.smokeBig,
      lottieSetting.cloudMiddle,
      lottieSetting.cloudLeft,
      lottieSetting.cloudRight,
      lottieSetting.smokeSmall,
      lottieSetting.platypusMain,
      lottieSetting.platypusFishing,
      lottieSetting.pondIce,
      lottieSetting.coinSnow,
    ],
    images: [imageSetting.iceTower, imageSetting.snowFront],
  },
  [Stage.stage3]: {
    lotties: [
      lottieSetting.windmill,
      lottieSetting.smokeBig,
      lottieSetting.cloudMiddle,
      lottieSetting.cloudLeft,
      lottieSetting.cloudRight,
      lottieSetting.smokeSmall,
      lottieSetting.platypusMain,
      lottieSetting.platypusSnow2ForStage2,
      lottieSetting.platypusFishing,
      lottieSetting.platypusFire,
      lottieSetting.pondIce,
      lottieSetting.coinSnow,
    ],
    images: [imageSetting.iceTower, imageSetting.snowFront],
  },
  [Stage.stage4]: {
    lotties: [
      lottieSetting.windmill,
      lottieSetting.smokeBig,
      lottieSetting.cloudMiddle,
      lottieSetting.cloudLeft,
      lottieSetting.cloudRight,
      lottieSetting.smokeSmall,
      lottieSetting.platypusMain,
      lottieSetting.platypusSalesFish,
      lottieSetting.platypusSnow2,
      lottieSetting.platypusSalesPumpkin,
      lottieSetting.platypusSalesBox,
      lottieSetting.platypusFishing,
      lottieSetting.platypusSnow1,
      lottieSetting.platypusFire,
      lottieSetting.platypusWalkSalesCrab,
      lottieSetting.platypusBuyerFishBasket,
      lottieSetting.marketLeaf,
      lottieSetting.pondIce,
      lottieSetting.marketFish,
      lottieSetting.coinSnow,
    ],
    images: [
      imageSetting.iceTower,
      imageSetting.market,
      imageSetting.marketBehind,
      imageSetting.snowFront,
    ],
  },
  [Stage.stage5]: {
    lotties: [
      lottieSetting.windmill,
      lottieSetting.smokeBig,
      lottieSetting.cloudMiddle,
      lottieSetting.cloudLeft,
      lottieSetting.platypusMain,
      lottieSetting.platypusSalesFish,
      lottieSetting.platypusSnow2,
      lottieSetting.platypusBuyerPumpkinBasket,
      lottieSetting.platypusSalesPumpkin,
      lottieSetting.platypusSalesSleep,
      lottieSetting.platypusSalesBox,
      lottieSetting.platypusFishing,
      lottieSetting.platypusSnow1,
      lottieSetting.platypusFire,
      lottieSetting.platypusWalkSalesCrab,
      lottieSetting.platypusBuyerFishBasket,
      lottieSetting.marketLeaf,
      lottieSetting.pondIce,
      lottieSetting.marketFish,
      lottieSetting.coinSnow,
    ],
    images: [
      imageSetting.iceTower,
      imageSetting.market,
      imageSetting.market2,
      imageSetting.snowman,
      imageSetting.marketBehind,
      imageSetting.marketBehind2,
      imageSetting.snowFront,
    ],
  },
}
