import { Box, Slider } from '@mui/material'
import styled, { css } from 'styled-components'
import AppButton from '../buttons/AppButton/AppButton'
import Input from '../Input/Input'
import { SliderRailColorType } from './AppTokenInput'

export interface StyledInputProps {
  disabled?: boolean
  disableUnderline?: boolean
  noLeftPadding?: boolean
}
export const StyledInput = styled(Input)<StyledInputProps>`
  ${({ disabled, disableUnderline, noLeftPadding }) => css`
    margin: 5px 10px 5px 0;
    min-height: 0;
    border-radius: 10px;
    ${!disableUnderline &&
    !disabled &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 85px;
        height: 1px;
        background-color: #fff;
        bottom: -3px;
        right: 0;
      }
    `}
    .MuiInput-input {
      font-size: 20px;
    }
    .input__children-wrapper {
      margin: 0;
      padding-left: 0;
    }
    ${noLeftPadding &&
    css`
      .MuiInput-input {
        padding-left: 0;
      }
    `}
  `}
`

const swapDirectionIconCSS = css`
  display: flex !important;
  &:hover {
    opacity: 1;
  }
`
interface SwapDirectionIconProps {
  disabled?: boolean
}

export const SwapDirectionIconContainer = styled(Box)<SwapDirectionIconProps>`
  ${({ theme, disabled }) => css`
    margin: 0 auto;
    cursor: pointer;
    position: absolute;
    bottom: -38px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 999px;
    border: 2px solid ${theme.palette.primary[900]};
    background: ${theme.palette.primary[800]};
    width: 52px;
    height: 52px;
    z-index: 1;
    &:hover {
      opacity: 0.8;
    }
    svg {
      font-size: 44px;
    }
    &.swap-direction-button--mobile {
      display: none;
    }

    ${disabled &&
    css`
      ${swapDirectionIconCSS}
      background: none;
      border: none;
      pointer-events: none;
      bottom: -13px;
      svg {
        font-size: 40px;
      }
    `}

    @media only screen and (max-width: ${theme.breakpoints.values.md}px ) {
      display: none;

      &.swap-direction-button--mobile {
        ${swapDirectionIconCSS}
        position: relative;
        left: 0;
        bottom: 0;
        transform: unset;
        border: 0;
        width: 32px;
        height: 32px;
        svg {
          font-size: 24px;
        }
      }
    }
  `}
`

export const ActionButtonContainer = styled(Box)`
  display: flex;
  min-width: 65px;
  justify-content: center;
  align-items: center;
  padding: 5px;
`
export const StyledButton = styled(AppButton)`
  text-transform: uppercase;
  margin: 0;
  max-height: 36px;
  padding: 4px 12px;
  * {
    font-weight: 700;
  }
`

export const TokenButtonsWrapper = styled(Box)`
  border-radius: 5px;
`
export const TokenInputContainer = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    border-radius: ${theme.borderRadius.md};
    padding: 6px 0;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding: 4px 0;
    }
  `}
`
interface StyledInnerContainerProps {
  flexDirection?: 'column' | 'row'
}
export const StyledInnerContainer = styled(Box)<StyledInnerContainerProps>`
  ${({ theme, flexDirection }) => css`
    display: flex;
    flex-direction: ${flexDirection};
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: ${theme.borderRadius.sm};
    height: 60px;
    padding: 12px 16px;

    ${flexDirection === 'column' &&
    css`
      height: unset;
      align-items: unset;
    `}
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding: 12px;
    }
  `}
`

interface StyledSliderProps {
  $railWidth?: string
  $railColor?: SliderRailColorType
}
export const StyledSlider = styled(Slider)<StyledSliderProps>`
  ${({ $railWidth, theme, $railColor }) => css`
    margin: 16px 0;
    width: 98%;
    align-self: center;
    /* act as background color of the rail */
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      background: #0000006e;
      right: 0;
      top: 50%;
      height: 4px;
      border-radius: 999px;
    }
    .MuiSlider-rail {
      width: ${$railWidth ? $railWidth : '100%'};
    }

    &.Mui-disabled {
      opacity: 0.3;
      .MuiSlider-mark {
        background-color: none;
        display: none;
      }
    }

    .MuiSlider-markActive {
      display: none;
    }

    ${
      $railColor === SliderRailColorType.PRIMARY &&
      css`
        .MuiSlider-track {
          color: ${theme.palette.secondary.main};
        }

        .MuiSlider-thumb {
          background: linear-gradient(
            to right,
            ${theme.palette.secondary.main},
            #0076e9
          );
        }
      `
    }

    ${
      $railColor === SliderRailColorType.INFO &&
      css`
        .MuiSlider-track {
          color: ${theme.palette.warning.main};
        }

        .MuiSlider-thumb {
          background: linear-gradient(
            to right,
            ${theme.palette.warning.main},
            #0076e9
          );
        }
      `
    }

    ${
      $railColor === SliderRailColorType.WARNING &&
      css`
        .MuiSlider-track {
          color: ${theme.palette.error.main};
        }

        .MuiSlider-thumb {
          background: linear-gradient(
            to right,
            ${theme.palette.error.main},
            #0076e9
          );
        }
      `
    }
    }
  `}
`
export const LabelItemInnerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.values.sm}px) {
      flex-direction: column;
    }
  `}
`

export const Label = styled.span`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.values.sm}px) {
      margin: 0;
    }
  `}
`

export const NumberDisplayBox = styled(Box)`
  translate: 8px;
`

export const NumberDisplayWrapper = styled(Box)`
  div: first-of-type {
    translate: -3px;
  }
`
