import {
  getCommifiedFormat,
  strToWad,
  sum,
  wmul,
} from '@hailstonelabs/big-number-utils'
import cloneDeep from 'lodash.clonedeep'
import React, { ReactElement, useMemo } from 'react'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import PoolClaimAllRewards, {
  BreakdownType,
  PoolClaimAllRewardsDataType,
} from '../../../components/PoolClaimAllReward/PoolClaimAllRewards'
import { LP_TOKENS, POOLS } from '../../../config/contracts'
import { MasterPlatypusId } from '../../../config/contracts/masterPlatypus'
import { PoolSymbol } from '../../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useBalance } from '../../../contexts/BalanceContext'
import { useModal } from '../../../contexts/ModalContext'
import { useMulticallData } from '../../../contexts/MulticallDataContext'
import { useSnackbar } from '../../../contexts/SnackbarContext'
import { useStakeLpData } from '../../../contexts/StakeLpDataContext'
import useClaimRewards from '../../../hooks/pool/useClaimRewards'
import usePoolCardInfo from '../../../hooks/pool/usePoolCardInfo'
import { TokenSymbolStringType } from '../../../interfaces/common'
import { ModalId } from '../../../interfaces/Modal'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import {
  DELISTED_POOLS,
  DEPRECATED_GAUGES_POOLS,
} from '../../../config/contracts/pool'

const poolSymbols = Object.keys(POOLS) as PoolSymbol[]
const emptyBreakdown = poolSymbols.reduce(
  (prev, current) => ({ ...prev, [current]: [] }),
  {} as BreakdownType,
)
function PoolClaimAllRewardsContainer(): ReactElement {
  const { rewards, myTotalUsd } = useStakeLpData()
  const { isMulticallDataFetchedWithAccount } = useMulticallData()
  const { tokenPrices, isTokenPriceFetched } = useBalance()
  const { showMessage } = useSnackbar()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { handleClaimRewards } = useClaimRewards()
  const getPoolCardInfo = usePoolCardInfo()
  const isButtonDisabled = useMemo(
    () => !myTotalUsd?.isMoreThanZero.earning,
    [myTotalUsd],
  )
  const [myDepositData, newMyEarningData] = useMemo<
    [PoolClaimAllRewardsDataType, PoolClaimAllRewardsDataType]
  >(() => {
    const newMyDepositData = {
      sumUSD: (
        <TooltipNum
          leftSymbol="$"
          amount={myTotalUsd?.inTermsOfUsd.deposit || '0.0'}
          format="commified"
        >
          $&nbsp;
          {showDashIfNecessary(
            !isMulticallDataFetchedWithAccount || !isTokenPriceFetched,
            getCommifiedFormat(myTotalUsd?.inTermsOfUsd.deposit || '0.0'),
          )}
        </TooltipNum>
      ),
      breakdown: cloneDeep(emptyBreakdown),
    } as PoolClaimAllRewardsDataType
    const newMyEarningData = {
      sumUSD: (
        <TooltipNum
          leftSymbol="$"
          amount={myTotalUsd?.inTermsOfUsd.earning || '0.0'}
          format="commified"
        >
          $&nbsp;
          {showDashIfNecessary(
            !isMulticallDataFetchedWithAccount || !isTokenPriceFetched,
            getCommifiedFormat(myTotalUsd?.inTermsOfUsd.earning || '0.0'),
          )}
        </TooltipNum>
      ),
      breakdown: cloneDeep(emptyBreakdown),
    } as PoolClaimAllRewardsDataType
    for (const poolSymbolStr in POOLS) {
      const poolSymbol = poolSymbolStr as PoolSymbol
      const pool = POOLS[poolSymbol]
      const availableAssets = DEPRECATED_GAUGES_POOLS.includes(poolSymbol)
        ? pool.getAssets('deprecatedMainPoolForGauageVoting')
        : pool.getAssets()
      /**
       * For my total earnings breakdown
       */
      const sumOfEachReward: TokenSymbolStringType = {} as TokenSymbolStringType
      for (const asset of availableAssets) {
        const assetTokenSymbol = asset.tokenSymbol
        /**
         * Calculation for my total earnings breakdown
         */
        if (rewards) {
          const assetRewards = rewards[poolSymbol][assetTokenSymbol]
          if (assetRewards) {
            for (const reward of asset.rewards) {
              const tokenSymbol = reward.tokenSymbol
              const newReward = assetRewards[tokenSymbol] || '0.0'
              const prevReward = sumOfEachReward[tokenSymbol] || '0.0'
              sumOfEachReward[tokenSymbol] = sum(prevReward, newReward)
            }
          }
        }

        /**
         * My total deposits breakdown
         */
        const resultData = getPoolCardInfo(poolSymbol, assetTokenSymbol)
        if (!resultData) continue
        const { myDeposits, myDepositsUSDWad } = resultData
        if (strToWad(myDeposits).isZero() && myDepositsUSDWad.isZero()) continue
        const tokenSymbol =
          LP_TOKENS[poolSymbol][assetTokenSymbol]?.tokenSymbolForDisplay ||
          assetTokenSymbol
        if (!DELISTED_POOLS.includes(poolSymbol)) {
          newMyDepositData.breakdown[poolSymbol].push({
            tokenSymbol: tokenSymbol,
            value: isTokenPriceFetched ? (
              <>
                {`${getCommifiedFormat(
                  myDeposits,
                )} ${tokenSymbol} ($${getCommifiedFormat(myDepositsUSDWad)})`}
              </>
            ) : (
              <>{`${getCommifiedFormat(myDeposits)} ${tokenSymbol}`}</>
            ),
          })
        }
      }
      /**
       * My total earnings breakdown
       */
      for (const entries of Object.entries(sumOfEachReward)) {
        const [tokenSymbolStr, value] = entries
        const tokenSymbol = tokenSymbolStr as TokenSymbol
        const earningUsd = wmul(
          strToWad(value),
          strToWad(tokenPrices[tokenSymbol]),
        )
        if (strToWad(value).isZero()) continue
        // Hide reward breakdown from deprecated pools
        if (!DELISTED_POOLS.includes(poolSymbol)) {
          newMyEarningData.breakdown[poolSymbol].push({
            tokenSymbol,
            value: isTokenPriceFetched ? (
              <>
                {`${getCommifiedFormat(
                  value,
                )} ${tokenSymbol} ($${getCommifiedFormat(earningUsd)})`}
              </>
            ) : (
              <>{`${getCommifiedFormat(value)} ${tokenSymbol}`}</>
            ),
          })
        }
      }
    }

    return [newMyDepositData, newMyEarningData]
  }, [
    getPoolCardInfo,
    isMulticallDataFetchedWithAccount,
    myTotalUsd?.inTermsOfUsd.deposit,
    myTotalUsd?.inTermsOfUsd.earning,
    rewards,
    tokenPrices,
    isTokenPriceFetched,
  ])
  //
  const handleClickClaim = async () => {
    const transactionHashes: string[] = []
    let allRewardTokenSymbols: TokenSymbol[] = []
    const masterPlatypusIds = Object.values(MasterPlatypusId)
    for (let i = 0; i < masterPlatypusIds.length; i++) {
      // mim rewards in MP1 should claim when user unstake in withdraw mim page
      if (masterPlatypusIds[i] === MasterPlatypusId.MP1) continue
      const poolSymbols = Object.values(POOLS)
        .filter((pool) => pool.masterPlatypusId === masterPlatypusIds[i])
        .map((pool) => pool.poolSymbol)
      const result = await handleClaimRewards({
        poolSymbols,
        requiredTransactionSubmittedModal: false,
      })
      if (result) {
        const { transactionHash, rewardTokenSymbols } = result
        transactionHashes.push(transactionHash)
        allRewardTokenSymbols = [
          ...rewardTokenSymbols,
          ...allRewardTokenSymbols,
        ]
      }
    }
    if (transactionHashes.length === 0) {
      showMessage('Transaction failed.', 'warning')
    } else {
      modalDispatch(
        openModal(ModalId.TRANSACTION_SUBMITTED, {
          transactionHashes: transactionHashes,
          tokenSymbols: Array.from(new Set(allRewardTokenSymbols)),
        }),
      )
      showMessage(`Successfully claimed rewards.`)
    }
  }
  return (
    <PoolClaimAllRewards
      earningSymbols={myTotalUsd?.earningSymbols || []}
      myDeposits={myDepositData}
      myEarnings={newMyEarningData}
      disabled={isButtonDisabled}
      onClickClaimButton={handleClickClaim}
    />
  )
}

export default PoolClaimAllRewardsContainer
