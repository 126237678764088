import { Call } from 'ethcall'
import { BigNumber, constants, utils } from 'ethers'
import cloneDeep from 'lodash.clonedeep'
import {
  BoostedMultiRewarderPerSec,
  MasterPlatypusV4,
  MultiRewarderPerSec,
} from '../../../../types/ethers-contracts'
import {
  MASTER_PLATYPUS,
  multicall3,
  POOLS,
  TOKENS,
} from '../../../config/contracts'
import BoostedMultiRewarderPerSec_ABI from '../../../config/contracts/abis/rewarder/BoostedMultiRewarderPerSec.json'
import MultiRewarderPerSec_ABI from '../../../config/contracts/abis/rewarder/MultiRewarderPerSec.json'
import { Contract } from '../../../config/contracts/Contract'
import { MasterPlatypusId } from '../../../config/contracts/masterPlatypus'
import { poolSymbols } from '../../../config/contracts/pool/poolSymbol'
import { mapAddressToTokenSymbol } from '../../../config/contracts/token'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { BOOSTED_MULTI_REWARDER_PER_SEC_ABI } from '../../../config/contracts/wagmiAbis/rewarder/BoostedMultiRewarderPerSec'
import { MULTI_REWARDER_PER_SEC_ABI } from '../../../config/contracts/wagmiAbis/rewarder/MultiRewarderPerSec'
import { ChainId } from '../../../config/networks'
import {
  EMPTY_POOLSYMBOL_STR_BN,
  EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_BN,
  EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_BOOLEAN,
  EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_STR,
} from '../../../constants/empty'
import {
  PendingTokensType,
  PoolInfoType,
  UserInfoType,
} from '../../../interfaces/apr'
import {
  PoolSymbolStringBigNumberType,
  PoolSymbolTokenSymbolTokenSymbolBigNumberType,
  PoolSymbolTokenSymbolTokenSymbolBooleanType,
  PoolSymbolTokenSymbolTokenSymbolStringType,
} from '../../../interfaces/common'
import {
  CallbacksType,
  MulticallHelperSCReturnType,
} from '../../../utils/multicall'
import { DEPRECATED_GAUGES_POOLS } from '../../../config/contracts/pool'

export interface StakeLpDataType {
  bonus: {
    tokenPerSecBNs: PoolSymbolTokenSymbolTokenSymbolBigNumberType
    dilutingRepartitions: PoolSymbolTokenSymbolTokenSymbolStringType
    nonDilutingRepartitions: PoolSymbolTokenSymbolTokenSymbolStringType
    isRewardersActive: PoolSymbolTokenSymbolTokenSymbolBooleanType
  }
  dilutingRepartition: string
  // for boosted apr
  nonDilutingRepartition: string
  poolSumOfFactorsBNs: PoolSymbolStringBigNumberType
}

export interface StakeLpDataWithAccountType {
  // rewards[PoolSymbol.MAIN][TokenSymbol.USDT]<pid>[TokenSymbol.PTP]<reward symbol>
  rewards: PoolSymbolTokenSymbolTokenSymbolStringType
  stakedLpInTermsOfLpBNs: PoolSymbolStringBigNumberType
  // for boosted apr
  userFactorBNs: PoolSymbolStringBigNumberType
}

// we can pass chainId and account to the functions if necessary
export const fetchStakeLpData = (
  chainId: ChainId,
  account: string | null,
): {
  contractCalls: Call[]
  callbacks: CallbacksType<MulticallHelperSCReturnType>
  states: {
    withAccount: StakeLpDataWithAccountType
    withoutAccount: StakeLpDataType
  }
} => {
  /**
   * A. Create empty contractCalls and callbacks array
   */
  const contractCalls: Call[] = []
  const callbacks: CallbacksType<MulticallHelperSCReturnType> = []
  /**
   * StakeLp Data:
   * 1. apr calculation
   * 2. ptp earning
   * 3. stakedLp
   */
  const states: {
    withAccount: StakeLpDataWithAccountType
    withoutAccount: StakeLpDataType
  } = {
    withoutAccount: {
      bonus: {
        tokenPerSecBNs: cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_BN),
        dilutingRepartitions: cloneDeep(
          EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_STR,
        ),
        isRewardersActive: cloneDeep(
          EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_BOOLEAN,
        ),
        nonDilutingRepartitions: cloneDeep(
          EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_STR,
        ),
      },
      dilutingRepartition: '0',
      nonDilutingRepartition: '0',
      poolSumOfFactorsBNs: cloneDeep(EMPTY_POOLSYMBOL_STR_BN),
    },
    withAccount: {
      rewards: cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_TOKENSYMBOL_STR),
      stakedLpInTermsOfLpBNs: cloneDeep(EMPTY_POOLSYMBOL_STR_BN),
      userFactorBNs: cloneDeep(EMPTY_POOLSYMBOL_STR_BN),
    },
  }
  const handleStakeLpData = () => {
    const masterPlatypusContractV4 =
      MASTER_PLATYPUS[MasterPlatypusId.MP4].get(chainId)
    if (masterPlatypusContractV4) {
      contractCalls.push(
        (
          masterPlatypusContractV4 as MasterPlatypusV4
        ).dilutingRepartition() as unknown as Call,
      )
      callbacks.push((value) => {
        if (value) {
          states.withoutAccount.dilutingRepartition = value.toString()
          states.withoutAccount.nonDilutingRepartition = BigNumber.from('1000')
            .sub(BigNumber.from(value))
            .toString()
        }
      })
    }
    for (let i = 0; i < poolSymbols.length; i++) {
      const poolSymbol = poolSymbols[i]
      const pool = POOLS[poolSymbol]
      const masterPlatypusContract =
        MASTER_PLATYPUS[pool.masterPlatypusId].get(chainId)
      if (masterPlatypusContract === null) continue
      const assets = DEPRECATED_GAUGES_POOLS.includes(poolSymbol)
        ? pool.getAssets('deprecatedMainPoolForGauageVoting')
        : pool.getAssets()
      for (let j = 0; j < assets.length; j++) {
        const assetTokenSymbol = assets[j].tokenSymbol
        const assetRewards = assets[j].rewards
        const pid = assets[j].pids[chainId]
        const pidBN = BigNumber.from(pid)
        // We cannot use (pid && account) since pid starts from 0 which is a falsy value.
        // The first tokenSymbol will be disappeared in this situation.
        // When pid didn't set, .poolInfo cause error
        if (pool.hasPtpBooster) {
          contractCalls.push(
            masterPlatypusContract.poolInfo(pidBN) as unknown as Call,
          )
          callbacks.push((value) => {
            states.withoutAccount.poolSumOfFactorsBNs[poolSymbol][
              assetTokenSymbol
            ] = (value as PoolInfoType).sumOfFactors
          })
        }

        if (account) {
          // When pid didn't set, .userInfo cause error
          contractCalls.push(
            masterPlatypusContract.userInfo(pidBN, account) as unknown as Call,
          )
          callbacks.push((value) => {
            states.withAccount.userFactorBNs[poolSymbol][assetTokenSymbol] = (
              value as UserInfoType
            ).factor
            states.withAccount.stakedLpInTermsOfLpBNs[poolSymbol][
              assetTokenSymbol
            ] = (value as UserInfoType).amount
          })
        }
        // ptp emission and other rewards
        for (let k = 0; k < assetRewards.length; k++) {
          const assetReward = assetRewards[k]
          const rewardMethod = assetReward.method
          const rewardTokenSymbol = assetReward.tokenSymbol
          const rewarderAddress = assetReward.rewarderAddress
          if (rewardMethod === 'rewarder.pendingTokens' && rewarderAddress) {
            const rewardTokenContract = TOKENS[rewardTokenSymbol].get(chainId)
            // Check for the balance of the rewarder
            let rewarderBalanceContractCall: Call | null = null
            // if rewardTokenSymbol is an ERC20 token, use balanceOf()
            if (rewardTokenContract && rewardTokenSymbol !== TokenSymbol.AVAX) {
              rewarderBalanceContractCall = rewardTokenContract.balanceOf(
                rewarderAddress[chainId] || '',
              ) as unknown as Call
            }
            // if rewardTokenSymbol is a native token (i.e. AVAX), use getEthBalance()
            const multicall3Contract = multicall3.get(chainId)
            if (
              !rewardTokenContract &&
              rewardTokenSymbol === TokenSymbol.AVAX &&
              multicall3Contract
            ) {
              rewarderBalanceContractCall = multicall3Contract.getEthBalance(
                rewarderAddress[chainId] || '',
              ) as unknown as Call
            }
            if (rewarderBalanceContractCall) {
              contractCalls.push(rewarderBalanceContractCall)
              callbacks.push((value) => {
                states.withoutAccount.bonus.isRewardersActive[poolSymbol][
                  assetTokenSymbol
                ] = {
                  ...states.withoutAccount.bonus.isRewardersActive[poolSymbol][
                    assetTokenSymbol
                  ],
                  [rewardTokenSymbol]: (value as BigNumber).gt(constants.Zero),
                }
              })
            }
            const rewarderContract = pool.hasBonusBooster
              ? new Contract<
                  BoostedMultiRewarderPerSec,
                  typeof BOOSTED_MULTI_REWARDER_PER_SEC_ABI
                >(
                  rewarderAddress,
                  BoostedMultiRewarderPerSec_ABI,
                  BOOSTED_MULTI_REWARDER_PER_SEC_ABI,
                ).get(chainId)
              : new Contract<
                  MultiRewarderPerSec,
                  typeof MULTI_REWARDER_PER_SEC_ABI
                >(
                  rewarderAddress,
                  MultiRewarderPerSec_ABI,
                  MULTI_REWARDER_PER_SEC_ABI,
                ).get(chainId)
            if (rewarderContract) {
              if (pool.hasBonusBooster) {
                contractCalls.push(
                  (
                    rewarderContract as BoostedMultiRewarderPerSec
                  ).dilutingRepartition() as unknown as Call,
                )
                callbacks.push((value) => {
                  if (value) {
                    states.withoutAccount.bonus.dilutingRepartitions[
                      poolSymbol
                    ][assetTokenSymbol] = {
                      ...states.withoutAccount.bonus.dilutingRepartitions[
                        poolSymbol
                      ][assetTokenSymbol],
                      [rewardTokenSymbol]: value.toString(),
                    }
                    states.withoutAccount.bonus.nonDilutingRepartitions[
                      poolSymbol
                    ][assetTokenSymbol] = {
                      ...states.withoutAccount.bonus.nonDilutingRepartitions[
                        poolSymbol
                      ][assetTokenSymbol],
                      [rewardTokenSymbol]: BigNumber.from('1000')
                        .sub(BigNumber.from(value))
                        .toString(),
                    }
                  }
                })
              }
              // assetReward.tokenId can be 0
              if (assetReward.tokenId !== undefined) {
                contractCalls.push(
                  rewarderContract.poolInfo(
                    assetReward.tokenId,
                  ) as unknown as Call,
                )
                // should still show reward claimable in the UI
                // but stop the APR (token per sec)
                callbacks.push((value) => {
                  states.withoutAccount.bonus.tokenPerSecBNs[poolSymbol][
                    assetTokenSymbol
                  ] = {
                    ...states.withoutAccount.bonus.tokenPerSecBNs[poolSymbol][
                      assetTokenSymbol
                    ],
                    [rewardTokenSymbol]: (
                      value as {
                        rewardToken: string
                        tokenPerSec: BigNumber
                        accTokenPerShare: BigNumber
                        accTokenPerFactorShare: BigNumber
                      }
                    ).tokenPerSec,
                  }
                })
              }
            }
          }
          if (rewardMethod === 'masterPlatypus.pendingTokens') {
            if (account) {
              // When pid didn't set, .pendingTokens cause error
              // ptp emission and other rewards
              contractCalls.push(
                masterPlatypusContract.pendingTokens(
                  pidBN,
                  account,
                ) as unknown as Call,
              )
              callbacks.push((value) => {
                const { pendingBonusTokens, pendingPtp, bonusTokenAddresses } =
                  value as PendingTokensType
                // rewarder rewards
                for (let g = 0; g < bonusTokenAddresses.length; g++) {
                  const bonusTokenAddress =
                    bonusTokenAddresses[g].toLocaleLowerCase()
                  // when bonusTokenSymbols[g] === avax, address is 0x0000000000000000000000000000000000000000
                  const bonusTokenSymbol =
                    constants.AddressZero === bonusTokenAddress
                      ? TokenSymbol.AVAX
                      : mapAddressToTokenSymbol[chainId][bonusTokenAddress]
                  const bonusTokenDecimals = TOKENS[bonusTokenSymbol].decimals
                  states.withAccount.rewards[poolSymbol][assetTokenSymbol] = {
                    ...states.withAccount.rewards[poolSymbol][assetTokenSymbol],
                    [bonusTokenSymbol]: utils.formatUnits(
                      pendingBonusTokens[g],
                      bonusTokenDecimals,
                    ),
                  }
                }
                // Ptp reward
                states.withAccount.rewards[poolSymbol][assetTokenSymbol] = {
                  ...states.withAccount.rewards[poolSymbol][assetTokenSymbol],
                  [rewardTokenSymbol]: utils.formatEther(pendingPtp),
                }
              })
            }
          }
        }
      }
    }
  }
  handleStakeLpData()
  return {
    contractCalls,
    callbacks,
    states,
  }
}
