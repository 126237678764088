import React, { ReactElement } from 'react'
import PoweredByAvalanche from '../../assets/icons/powered-by-avalanche-black-white.svg'
import { StyledExternalLink } from './PoweredByAvalancheLink.elements'
const PoweredByAvalancheLink = (): ReactElement => {
  return (
    <StyledExternalLink href="https://bridge.avax.network/">
      <img
        src={PoweredByAvalanche}
        alt="Powered By Avalanche"
        width="116px"
        height="40px"
      />
    </StyledExternalLink>
  )
}

export default PoweredByAvalancheLink
