import { strToWad } from '@hailstonelabs/big-number-utils'
import * as Sentry from '@sentry/react'
import { utils } from 'ethers'
import cloneDeep from 'lodash.clonedeep'
import { useCallback } from 'react'
import { mapLpAddressToPoolSymbolTokenSymbol } from '../../config/contracts/lpToken'
import { EMPTY_POOLSYMBOL_TOKENSYMBOL_STR } from '../../constants/empty'
import { useNetwork } from '../../contexts/NetworkContext'
import { fetchBlockNumberByTimestamp } from '../../utils/block'
import fetchGraphql from '../../utils/fetchGraphql'

export interface Fetch24HourVolumeOfAllTokensReturnType {
  [key: string]: { [key: string]: string }
}

interface TotalVolumeOfTokensResponseDataType {
  id: string
  totalTradeVolume: string
  token: {
    symbol: string
  }
  pool: {
    id: string
  }
}

interface FetchTotalVolumeOfTokensParam {
  blockNumber?: string
  lpTokenAddressList?: string[]
}
interface ReturnType {
  fetchTotalVolumeOfTokens: ({
    blockNumber,
    lpTokenAddressList,
  }: FetchTotalVolumeOfTokensParam) => Promise<
    [TotalVolumeOfTokensResponseDataType] | null
  >
  fetch24HourVolumeOfAllTokens: (
    targetTokens?: string[],
  ) => Promise<Fetch24HourVolumeOfAllTokensReturnType>
}
function useExchangeSubgraph(): ReturnType {
  const { network } = useNetwork()
  const subgraphApi = network.apis.exchangeSubgraph

  /**
   * @async
   * @param {string} blockNumber - when no block number is provided, it uses the latest block of the subgraph.
   * @param {string[]} lpTokenAddressList - when no symbols are provided, it gets all the tokens.
   * @returns {TotalVolumeOfTokensResponseDataType[]}
   */
  const fetchTotalVolumeOfTokens = useCallback(
    async ({
      blockNumber,
      lpTokenAddressList,
    }: FetchTotalVolumeOfTokensParam) => {
      try {
        let whereClause = ''
        if (lpTokenAddressList) {
          // transform from ["USDT.e", "DAI.e"] to '"USDT.e", "DAI.e"'
          const lpTokenAddressListInString = lpTokenAddressList
            .map((lpAddress) => `"${lpAddress}"`)
            .toString()
          whereClause = `where: {id_in: [${lpTokenAddressListInString}]}`
        }

        // the null string is for subgraph syntax
        let blockNumberFilter = 'null'
        if (blockNumber) {
          blockNumberFilter = `{number: ${blockNumber}}`
        }

        // token symbols in the subgraph are ERC20 token symbols.
        const query = `
        {
          assets: assets (block: ${blockNumberFilter}, ${whereClause}) {
            id
            totalTradeVolume
            token {
              symbol
            }
            pool {
              id
            }
          }
        }
      `
        // the response will either contain errors or data
        const { data, errors } = await fetchGraphql<{
          assets: [TotalVolumeOfTokensResponseDataType]
        }>(subgraphApi, query)

        if (errors) {
          throw new Error(errors.map((err) => err.message).toString())
        }
        if (data) {
          return data.assets
        }
      } catch (err) {
        console.error(err)
      }
      return null
    },
    [subgraphApi],
  )

  const fetch24HourVolumeOfAllTokens = useCallback(
    async (lpTokenAddressList?: string[]) => {
      try {
        const nowInSecond = Date.now() / 1000
        const yesterdayInSecond = nowInSecond - 86400
        // Get Block numbers
        const yesturdayBlockNumber = await fetchBlockNumberByTimestamp(
          Math.floor(yesterdayInSecond),
        )
        if (!yesturdayBlockNumber) return {}

        const lastestTotalVolumeOfAllTokens = await fetchTotalVolumeOfTokens({
          lpTokenAddressList,
        })
        const yesterdayTotalVolumeOfAllTokens = await fetchTotalVolumeOfTokens({
          blockNumber: yesturdayBlockNumber,
          lpTokenAddressList,
        })

        // only get 24hr volume when we have lastestTotalVolumeOfAllTokens & yesterdayTotalVolumeOfAllTokens
        if (lastestTotalVolumeOfAllTokens && yesterdayTotalVolumeOfAllTokens) {
          // change the data structure
          // from [{token: {symbol: 'MIM'}, totalTradeVolume: '1234', pool: {id: '1'}}, {token: {symbol: 'USDC.e'}, totalTradeVolume: '321', pool: {id: '1'}}]
          // to {[PoolSymbol]: {MIM: '1234', USDC.e: '321'}}
          const transformedLatestTotalVolOfAllTokens =
            lastestTotalVolumeOfAllTokens.reduce((formattedData, data) => {
              const lpTokenAddress = data.id
              // if lpTokenAddress not in mapLpAddressToPoolSymbolTokenSymbol pass
              const poolSymbolTokenSymbol =
                mapLpAddressToPoolSymbolTokenSymbol()[
                  lpTokenAddress.toLocaleLowerCase()
                ]
              if (!poolSymbolTokenSymbol) return formattedData
              // only available in main net
              const { poolSymbol, tokenSymbol } = poolSymbolTokenSymbol
              formattedData[poolSymbol] = {
                ...formattedData[poolSymbol],
                [tokenSymbol]: data.totalTradeVolume,
              }
              return formattedData
            }, {} as Fetch24HourVolumeOfAllTokensReturnType)

          /** @todo refactor the same logic in transformedLatestTotalVolOfAllTokens and transformedYtdTotalVolOfAllTokens */
          const transformedYtdTotalVolOfAllTokens =
            yesterdayTotalVolumeOfAllTokens.reduce((formattedData, data) => {
              const lpTokenAddress = data.id
              // if lpTokenAddress not in mapLpAddressToPoolSymbolTokenSymbol pass
              const poolSymbolTokenSymbol =
                mapLpAddressToPoolSymbolTokenSymbol()[
                  lpTokenAddress.toLocaleLowerCase()
                ]
              if (!poolSymbolTokenSymbol) return formattedData
              // only available in main net
              const { poolSymbol, tokenSymbol } = poolSymbolTokenSymbol
              formattedData[poolSymbol] = {
                ...formattedData[poolSymbol],
                [tokenSymbol]: data.totalTradeVolume,
              }
              return formattedData
            }, cloneDeep(EMPTY_POOLSYMBOL_TOKENSYMBOL_STR) as Fetch24HourVolumeOfAllTokensReturnType)
          // get 24hour volumes
          // use keys of transformedLatestTotalVolOfAllTokens (instead of transformedYtdTotalVolOfAllTokens)
          // to get all the keys that exist in latest. keys that don't exist in ytd will have totalVolume of zero
          const poolSymbols = Object.keys(transformedLatestTotalVolOfAllTokens)
          const volumeOfAllTokensIn24Hour = cloneDeep(
            transformedLatestTotalVolOfAllTokens,
          )

          for (const poolSymbol of poolSymbols) {
            const tokenSymbols = Object.keys(
              transformedLatestTotalVolOfAllTokens[poolSymbol],
            )
            for (const tokenSymbol of tokenSymbols) {
              const latestTotalVolumeWAD = strToWad(
                transformedLatestTotalVolOfAllTokens[poolSymbol][tokenSymbol],
              )
              const yesterdayTotalVolumeWAD = strToWad(
                transformedYtdTotalVolOfAllTokens[poolSymbol][tokenSymbol],
              )
              volumeOfAllTokensIn24Hour[poolSymbol][tokenSymbol] =
                utils.formatEther(
                  latestTotalVolumeWAD.sub(yesterdayTotalVolumeWAD),
                )
            }
          }
          return volumeOfAllTokensIn24Hour
        }
      } catch (err) {
        Sentry.setContext('event', {
          name: 'fetch_24_hour_volume_of_all_tokens',
          targetedTokenSymbols: lpTokenAddressList,
        })
        Sentry.captureException(err)
        console.error(err)
      }
      return {}
    },
    [fetchTotalVolumeOfTokens],
  )

  return { fetch24HourVolumeOfAllTokens, fetchTotalVolumeOfTokens }
}

export default useExchangeSubgraph
