import { strToWad } from '@hailstonelabs/big-number-utils'
import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { wavax } from '../../config/contracts'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { WAVAX_ABI } from '../../config/contracts/wagmiAbis/WAVAX'
import { useModal } from '../../contexts/ModalContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useSwap } from '../../contexts/SwapContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'
import { isUserDeniedTransaction } from '../../utils/contract'
import useRevertReason from '../useRevertReason'

interface Props {
  handleUnwrap: () => Promise<void>
}

/** @todo write test for this hook */
const useUnwrapWavax = (): Props => {
  const { chainId } = useWeb3()
  const getRevertReason = useRevertReason()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { showMessage } = useSnackbar()

  const {
    fromTokenAmount,
    toTokenAmount,
    fromTokenSymbol,
    toTokenSymbol,
    resetAllAmount,
  } = useSwap()

  /**
   * Handle unwrap
   */
  const handleUnwrap = async () => {
    const wavaxAddress = wavax.getAddress(chainId)
    if (fromTokenSymbol && toTokenSymbol && wavaxAddress) {
      let writeContractResult: WriteContractResult | undefined

      try {
        // check fromTokenSymbol & toTokenSymbol
        if (
          fromTokenSymbol !== TokenSymbol.WAVAX ||
          toTokenSymbol !== TokenSymbol.AVAX
        ) {
          throw new Error(
            `Wrong from/to TokenSymbol. from: ${fromTokenSymbol} to: ${toTokenSymbol}`,
          )
        }
        modalDispatch(openModal(ModalId.SWAP_WAIT_FOR_CONFIRMATION))
        const config = await prepareWriteContract({
          address: wavaxAddress,
          abi: WAVAX_ABI,
          functionName: 'withdraw',
          args: [strToWad(fromTokenAmount)],
          chainId,
        })
        writeContractResult = await writeContract(config)

        if (writeContractResult) {
          const { hash, wait } = writeContractResult
          await wait()
          modalDispatch(
            openModal(ModalId.TRANSACTION_SUBMITTED, {
              transactionHashes: [hash],
              tokenSymbols: [toTokenSymbol],
            }),
          )
          showMessage('Successfully unwrapped.')
        } else {
          showMessage('Transaction failed.', 'warning')
          modalDispatch(openModal(ModalId.UNSET))
        }
      } catch (err) {
        if (!isUserDeniedTransaction(err)) {
          Sentry.setContext('contract_call', {
            name: 'wavax.withdraw',
            to_token_symbol: toTokenSymbol,
            from_token_symbol: fromTokenSymbol,
            to_token_amount: fromTokenAmount,
            from_token_amount: toTokenAmount,
          })
          Sentry.captureException(err)
        }
        const reason = await getRevertReason(err)
        showMessage(reason || 'Transaction failed.', 'warning')
        modalDispatch(openModal(ModalId.UNSET))
      }
    }
    resetAllAmount()
  }
  return { handleUnwrap }
}

export default useUnwrapWavax
