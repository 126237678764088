import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import Snackbar from '../components/Snackbar/Snackbar'

export type SeverityType = 'success' | 'warning'

interface MessageInfoType {
  message: string
  severity: SeverityType
  key: number
}

export interface ContextType {
  showMessage: (message: string, severity?: SeverityType) => void
  setOpen: (value: React.SetStateAction<boolean>) => void
  open: boolean
  clearMessageInfo: () => void
  messageInfo: MessageInfoType | undefined
}
export const SnackbarContext = createContext<ContextType>({} as ContextType)
SnackbarContext.displayName = 'SnackbarContext'

export const useSnackbar = (): ContextType => {
  return useContext(SnackbarContext)
}

interface Props {
  children: React.ReactNode
}

const initialSnackPack: MessageInfoType[] = [] as MessageInfoType[]

export const SnackbarProvider = ({ children }: Props): ReactElement => {
  const [snackPack, setSnackPack] =
    useState<MessageInfoType[]>(initialSnackPack)
  const [open, setOpen] = useState(false)
  const [messageInfo, setMessageInfo] = useState<MessageInfoType | undefined>(
    undefined,
  )

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] })
      setSnackPack((prev) => prev.slice(1))
      setOpen(true)
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false)
    }
  }, [snackPack, messageInfo, open])

  const showMessage = (message: string, severity: SeverityType = 'success') => {
    setSnackPack((prev) => [
      ...prev,
      { message, severity, key: new Date().getTime() },
    ])
  }

  const clearMessageInfo = () => {
    setMessageInfo(undefined)
  }
  return (
    <SnackbarContext.Provider
      value={{ showMessage, setOpen, open, clearMessageInfo, messageInfo }}
    >
      {children}
      <Snackbar />
    </SnackbarContext.Provider>
  )
}
