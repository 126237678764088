import { ChainId } from '../../../networks'
import { PoolSymbol } from '../../pool/poolSymbol'
import { TokenSymbol } from '../../token/tokenSymbol'
import { LpToken } from '../LpToken'

export const FACTORY_LP_TOKENS = {
  [PoolSymbol.FACTORY_USDC_TSD]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x979702f708dd794A0e5E616E89C1656a2C55d681',
        [ChainId.FUJI]: '0x202041B737ebAB1049c3AA39e6E1B3D7E2Fc2Fb4',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.FACTORY_USDC_TSD,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 1,
        [ChainId.FUJI]: 1,
      },
      color: '#FF9FFB',
    }),
    [TokenSymbol.TSD]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x027A24Fa0168DA4fC7AF9Bf5331D42692889AFaa',
        [ChainId.FUJI]: '0xf47C8a0fB6dDc66D43cBA4C12cEf53d50a99B7aF',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.TSD,
      poolSymbol: PoolSymbol.FACTORY_USDC_TSD,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.TEDDY,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0xdd203321d1559BeD49b0C0Fb2Bf61fc91b4b575D',
            [ChainId.FUJI]: '0x490Dc75d1dCbe2b9f3Acf18a536B4cB21C4d055E',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 0,
        [ChainId.FUJI]: 0,
      },
      color: '#CFC6B1',
    }),
  },
  [PoolSymbol.FACTORY_USDC_H2O]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xd78d5f6A5bF62a88212203077D1A28F812307145',
        [ChainId.FUJI]: '0x1fe2ecE7D8631C1ec9f0B5ab7cFd5DFE57D6CFba',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.FACTORY_USDC_H2O,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 3,
        [ChainId.FUJI]: 3,
      },
      color: '#FA0575',
    }),
    [TokenSymbol.H2O]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x4f734D89531b6c9A1242C196297316E928AEeFBa',
        [ChainId.FUJI]: '0x0ACa9eF8B25B86C11BC7D3A9CC41F22636B379Bd',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.H2O,
      poolSymbol: PoolSymbol.FACTORY_USDC_H2O,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 2,
        [ChainId.FUJI]: 2,
      },
      color: '#76B4EE',
    }),
  },
  [PoolSymbol.FACTORY_USDC_MONEY]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x551C259Bf4D88edFdAbb04179342a73dAa759583',
        [ChainId.FUJI]: '0x7F81330B14c92f45A5b4A262FFC959D2D52655eC',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.FACTORY_USDC_MONEY,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.MORE,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0x032e09C819ed14314eD62Ea91c41151A228BdAb4',
            [ChainId.FUJI]: '0x7124b4Bc090A2a1A4E56E1d80799a52fbb6C86f4',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 5,
        [ChainId.FUJI]: 5,
      },
      color: '#00FFF0',
    }),
    [TokenSymbol.MONEY]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xE08947eE864Af325D9F98743B3b905875Ae0Ec99',
        [ChainId.FUJI]: '0x7188ff0d2124C95510a3F15c263e153b75B517bD',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.MONEY,
      poolSymbol: PoolSymbol.FACTORY_USDC_MONEY,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.MORE,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0x77286ac09F4e0b7E6D37B4B77cd2D0Fb6b49323D',
            [ChainId.FUJI]: '0xED89Bc9687bE8E22fF8FF1E417347E9E313EFa52',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 4,
        [ChainId.FUJI]: 4,
      },
      color: '#823CE8',
    }),
  },
  [PoolSymbol.FACTORY_USDC_MIMATIC]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x4bc279727585b80A2a6a64498Ae67E2db1D9fAdF',
        [ChainId.FUJI]: '0x53b0837c6c5FF5aEcAD672Af723Ae263A6621D18',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.FACTORY_USDC_MIMATIC,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 7,
        [ChainId.FUJI]: 7,
      },
      color: '#FF4500',
    }),
    [TokenSymbol.MIMATIC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xb49626bB9F93F9c6466d67dc80F2f33B4162E416',
        [ChainId.FUJI]: '0x3127750564d7bE9AB6592c1F2702e6F7DC523Adc',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.MIMATIC,
      poolSymbol: PoolSymbol.FACTORY_USDC_MIMATIC,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 6,
        [ChainId.FUJI]: 6,
      },
      color: '#FFFAFA',
    }),
  },
  [PoolSymbol.FACTORY_USDC_USX]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x6689C9Eb9a8A0Dd8BbD0935Ce0cD7FeD72E8667E',
        [ChainId.FUJI]: '0x9E9905cD73b03BeeDf1eB96a6Eac947D20E3E506',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.FACTORY_USDC_USX,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 9,
        [ChainId.FUJI]: 9,
      },
      color: '#FF5500',
    }),
    [TokenSymbol.USX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x2DebC993c5f9Cac766Efa3EdCb1b6e40aDb4F6E2',
        [ChainId.FUJI]: '0x84DA64Bffa2117Ac8c4c3E16214Eb474dA9706e1',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.USX,
      poolSymbol: PoolSymbol.FACTORY_USDC_USX,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 8,
        [ChainId.FUJI]: 8,
      },
      color: '#25EB0A',
    }),
  },
}
