import { ChainId } from '../../../networks'
import { PoolSymbol } from '../../pool/poolSymbol'
import { TokenSymbol } from '../../token/tokenSymbol'
import { LpToken } from '../LpToken'

export const ALT_LP_TOKENS = {
  [PoolSymbol.ALT_BTCB_WBTCE]: {
    [TokenSymbol.BTCb]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x00c06Ae49045f4c89e0aE9457f7d7eb02ddEAC02',
        [ChainId.FUJI]: '0xd65AFc6B7D12eb3ca399fd6034dd7b64B2E1f641',
      },
      decimals: 8,
      tokenSymbol: TokenSymbol.BTCb,
      poolSymbol: PoolSymbol.ALT_BTCB_WBTCE,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.AVAX,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0x56a33bFDF90738cEC83f81391B2848D46C18995E',
            [ChainId.FUJI]: '0xba1053E59Df24089233Ed73Defb5BF00f964a0ed',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 43,
        [ChainId.FUJI]: 18,
      },
      color: '#F7931A',
    }),
    [TokenSymbol.WBTCe]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xE46166d792a938FE9B30EDdad6957EdE0EEd7c92',
        [ChainId.FUJI]: '0x866E5570f0Ca2Db94E1A2104096cb58eA858Bdc2',
      },
      decimals: 8,
      tokenSymbol: TokenSymbol.WBTCe,
      poolSymbol: PoolSymbol.ALT_BTCB_WBTCE,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.AVAX,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0x47A1959e26D5A7199Dc0dE4fB5bAD9E0a5d66e31',
            [ChainId.FUJI]: '0x73deb1DA7976D43D398db7c231b61DBE4CD0F282',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 42,
        [ChainId.FUJI]: 19,
      },
      color: '#FFE5BD',
    }),
  },
  [PoolSymbol.ALT_USDC_FRAX]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xBA05bf8E40C3ac8896F4C83b819C669C10975d22',
        [ChainId.FUJI]: '0x592cbD009196D1dfc15b3025528c4d110362A98B',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.ALT_USDC_FRAX,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 45,
        [ChainId.FUJI]: 7,
      },
      color: '#C909E8',
    }),
    [TokenSymbol.FRAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xd60b7538ae0967015E821181D2F8c2C0ea007614',
        [ChainId.FUJI]: '0x943a47A850d9e2A18c62CE81561498D5Ec2d4830',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.FRAX,
      poolSymbol: PoolSymbol.ALT_USDC_FRAX,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 44,
        [ChainId.FUJI]: 6,
      },
      color: '#FF988A',
    }),
  },
  [PoolSymbol.ALT_USDC_MIM]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x8dA202C0d6183BDa6cfb84bd79f7703616A5A165',
        [ChainId.FUJI]: '0x0A92C5708B1Fcf6eA5805B7E92A62e8739B5001a',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.ALT_USDC_MIM,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 47,
        [ChainId.FUJI]: 11,
      },
      color: '#C971FF',
    }),
    [TokenSymbol.MIM]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xcC492bB9C266bC54cF76C8D89988607d4afdF463',
        [ChainId.FUJI]: '0xd1bb1c713B5A6466651f64c07BaB4120FAD3CC6D',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.MIM,
      poolSymbol: PoolSymbol.ALT_USDC_MIM,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 46,
        [ChainId.FUJI]: 10,
      },
      color: '#9695F8',
    }),
  },
  [PoolSymbol.ALT_AVAX_SAVAX]: {
    [TokenSymbol.WAVAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x5f606fbff0e1c72638f2Ab45A6EECD32f30381f1',
        [ChainId.FUJI]: '0x8591FDd6bA0e8b106D626bd1E82F4469fE7b63C0',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.WAVAX,
      poolSymbol: PoolSymbol.ALT_AVAX_SAVAX,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 49,
        [ChainId.FUJI]: 12,
      },
      color: '#E84142',
      // WAVAX asset will display as AVAX
      tokenSymbolForDisplay: TokenSymbol.AVAX,
      tokenSymbolForCheckbox: {
        tokenSymbolForChecked: TokenSymbol.WAVAX,
        tokenSymbolForUnchecked: TokenSymbol.AVAX,
      },
      // bribe: {
      //   tokenSymbol: TokenSymbol.QI,
      //   bribeAddress: {
      //     [ChainId.AVALANCHE]: '0x901A1Ed26036E1405BC465217bc714Fa2168BD6A',
      //     [ChainId.FUJI]: '0x47eD249069B350A791c28B6379531aF9D771Bb9F',
      //   },
      // },
    }),
    [TokenSymbol.SAVAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xcFe81bdc303cE5A96235aA54aaAaF953BBb4e8DD',
        [ChainId.FUJI]: '0xe3D3E3A88478fE69a3861873981D5f556E242064',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.SAVAX,
      poolSymbol: PoolSymbol.ALT_AVAX_SAVAX,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 48,
        [ChainId.FUJI]: 13,
      },
      color: '#21C9FF',
      // bribe: {
      //   tokenSymbol: TokenSymbol.QI,
      //   bribeAddress: {
      //     [ChainId.AVALANCHE]: '0x99249a48a52c7b380644d3466c92ffc68F76047A',
      //     [ChainId.FUJI]: '0xa2c9959Fc61580295e8772633c3a5a72Ec2dEB87',
      //   },
      // },
    }),
  },
  [PoolSymbol.ALT_AVAX_YYAVAX]: {
    [TokenSymbol.WAVAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xA8817bd85D3E40F76296e4FA04ADEC454488bA9e',
        [ChainId.FUJI]: '0xA5B20FE57Ed64811DcA6FeDD6b79CF9d60DD3e23',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.WAVAX,
      poolSymbol: PoolSymbol.ALT_AVAX_YYAVAX,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 53,
        [ChainId.FUJI]: 22,
      },
      color: '#FF7461',
      // WAVAX asset will display as AVAX
      tokenSymbolForDisplay: TokenSymbol.AVAX,
      tokenSymbolForCheckbox: {
        tokenSymbolForChecked: TokenSymbol.WAVAX,
        tokenSymbolForUnchecked: TokenSymbol.AVAX,
      },
      // bribe: {
      //   tokenSymbol: TokenSymbol.YYAVAX,
      //   bribeAddress: {
      //     [ChainId.AVALANCHE]: '0x2aD5fC90F95f236F5276E795A5A46381bFE5aa92',
      //     [ChainId.FUJI]: '0x45054722833eB99977EfdeD3156bc9B171859f63',
      //   },
      // },
    }),
    [TokenSymbol.YYAVAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x33EF6464596a9B63DE9c4808d1dE3c729389915B',
        [ChainId.FUJI]: '0xBf8f593157634E59e968b685E8DD7c6B74ebc1EB',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.YYAVAX,
      poolSymbol: PoolSymbol.ALT_AVAX_YYAVAX,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 52,
        [ChainId.FUJI]: 23,
      },
      color: '#00F57B',
      // bribe: {
      //   tokenSymbol: TokenSymbol.YYAVAX,
      //   bribeAddress: {
      //     [ChainId.AVALANCHE]: '0x3D0798Ffea1b441731f95F1D5287DC0Aee3b12A5',
      //     [ChainId.FUJI]: '0x152215406A8E237e3F4f38B03210aC4F956f13b6',
      //   },
      // },
    }),
  },
  [PoolSymbol.ALT_AVAX_ANKRAVAX]: {
    [TokenSymbol.WAVAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xdeD3913c28260980dCE65B32133be4F735b618fB',
        [ChainId.FUJI]: '0x46eFc55F0027710eBbd4a5FFaB28BB1b1A0432Bf',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.WAVAX,
      poolSymbol: PoolSymbol.ALT_AVAX_ANKRAVAX,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 55,
        [ChainId.FUJI]: 29,
      },
      /** @todo color */
      color: '#FF7461',
      // WAVAX asset will display as AVAX
      tokenSymbolForDisplay: TokenSymbol.AVAX,
      tokenSymbolForCheckbox: {
        tokenSymbolForChecked: TokenSymbol.WAVAX,
        tokenSymbolForUnchecked: TokenSymbol.AVAX,
      },
      // bribe: {
      //   tokenSymbol: TokenSymbol.ANKR,
      //   bribeAddress: {
      //     [ChainId.AVALANCHE]: '0x623908104457d232F526a2d6e021e537Cb38229f',
      //     [ChainId.FUJI]: '0x2ca1ACc8fC3b2Ac215F2D79C2034b0c78FBAf534',
      //   },
      // },
    }),
    [TokenSymbol.ANKRAVAX]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x4EEd15283aefDFBb143506AeE2e1D5DBe68Ef42e',
        [ChainId.FUJI]: '0xf5A1FbC977a57cfdACAd2FA095dCc70958A33Bf8',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.ANKRAVAX,
      poolSymbol: PoolSymbol.ALT_AVAX_ANKRAVAX,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 54,
        [ChainId.FUJI]: 28,
      },
      color: '#00F57B',
      // bribe: {
      //   tokenSymbol: TokenSymbol.ANKR,
      //   bribeAddress: {
      //     [ChainId.AVALANCHE]: '0x7b35229e2451220237f3121464713F3446E740F1',
      //     [ChainId.FUJI]: '0x5A6E101B2D8B8432Db3A8c80fCE58124Ae3d292e',
      //   },
      // },
    }),
  },
  [PoolSymbol.ALT_USDC_YUSD]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x858C93E40B7100959d7930bB1D830761C6247641',
        [ChainId.FUJI]: '0xd0E5a8Aa85761c665AD8A542E694D5F43F41cd5A',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.ALT_USDC_YUSD,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.YETI,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0xfb45Dad8cDa679CaB275C56DEc8EcBDA38a3972e',
            [ChainId.FUJI]: '0xCD4f5Fe7B109A42d97cC3D36Dbedb8F9Ad831E49',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 51,
        [ChainId.FUJI]: 17,
      },
      color: '#5042DE',
      // bribe: {
      //   tokenSymbol: TokenSymbol.YETI,
      //   bribeAddress: {
      //     [ChainId.AVALANCHE]: '0x9a4003DDDcf2C4ECd5e2916730Dee618a1Df9142',
      //     [ChainId.FUJI]: '0x5F13D0047C8Aa0a9B98DFfbCf5E4DFA7123BFfa1',
      //   },
      // },
    }),
    [TokenSymbol.YUSD]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xEF7d0Aa06ACcA5E799f39729c4F36eaE4B5b2817',
        [ChainId.FUJI]: '0x1ab174d5D991B2d0011f695730b771d709368621',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.YUSD,
      poolSymbol: PoolSymbol.ALT_USDC_YUSD,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.YETI,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0x7fF30B2c83e707C3110e39Db521AF399E34cC73a',
            [ChainId.FUJI]: '0x1e49EE04ba5519B8370D953CaC56Bb0920f977ED',
          },
          tokenId: 0,
        },
      ],
      // bribe: {
      //   tokenSymbol: TokenSymbol.YETI,
      //   bribeAddress: {
      //     [ChainId.AVALANCHE]: '0x933faf47D3bbf4955462Ddc8cb523209e09224f1',
      //     [ChainId.FUJI]: '0x70FC96Fa423F647DFb9Cd0697a481Af6b32e50b8',
      //   },
      // },
      pids: {
        [ChainId.AVALANCHE]: 50,
        [ChainId.FUJI]: 16,
      },
      color: '#C6EAF1',
    }),
  },
  [PoolSymbol.ALT_USDC_MONEY]: {
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x548DB891DFc90907C05C3097f20EB1883E71Dd84',
        [ChainId.FUJI]: '0x73A4e7a8BC816aEbBc6Ef2DD5B959c8BB563e7BB',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.ALT_USDC_MONEY,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.MORE,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0xba0431afD3E9C8667989C54884976a56B3a1cC50',
            [ChainId.FUJI]: '0xEC084B7b1179fa042bd49469E2aA020248caD14d',
          },
          tokenId: 0,
        },
      ],
      // bribe: {
      //   tokenSymbol: TokenSymbol.MORE,
      //   bribeAddress: {
      //     [ChainId.AVALANCHE]: '0x0Cb7cAeeC467d69982c0FFc8369D1682fd1e5277',
      //     [ChainId.FUJI]: '0x2EB78ceFfC721EB73587DF1131daF5787e8256CF',
      //   },
      // },
      pids: {
        [ChainId.AVALANCHE]: 59,
        [ChainId.FUJI]: 21,
      },
      color: '#00FFF0',
    }),
    [TokenSymbol.MONEY]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xf77DE8b81c2Babe0503DeD0145eE6466310bF3eb',
        [ChainId.FUJI]: '0xfaB628Fc8C0E8FCB59f72Ed0d1F9B6D3B4F2aeC8',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.MONEY,
      poolSymbol: PoolSymbol.ALT_USDC_MONEY,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
        {
          tokenSymbol: TokenSymbol.MORE,
          method: 'rewarder.pendingTokens',
          rewarderAddress: {
            [ChainId.AVALANCHE]: '0xc610De201d83dAfdEBbfACD9EEb306de0B89e56F',
            [ChainId.FUJI]: '0xd2c06ab241BF3748847118221c2372a1e123464E',
          },
          tokenId: 0,
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 58,
        [ChainId.FUJI]: 20,
      },
      // bribe: {
      //   tokenSymbol: TokenSymbol.MORE,
      //   bribeAddress: {
      //     [ChainId.AVALANCHE]: '0x21eb980ECbA80eb1992c0d210B0fed26f1ACCb3f',
      //     [ChainId.FUJI]: '0x917d387A20404aAC4423fB9165E3C6852FF731E6',
      //   },
      // },
      color: '#823CE8',
    }),
  },
  [PoolSymbol.ALT_ANKRETH_WETHE]: {
    [TokenSymbol.ANKRETH]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xbf4df0411b62665c15f5d6cBc8dbb0650efd6b08',
        [ChainId.FUJI]: '0x6f38cbDF63502BEEC33D4453ad17962658fa3a6A',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.ANKRETH,
      poolSymbol: PoolSymbol.ALT_ANKRETH_WETHE,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 56,
        [ChainId.FUJI]: 35,
      },
      color: '#FFE81B',
    }),
    [TokenSymbol.WETHe]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x17d2Acfe6d899145939E1Da9c2D85C34Df1871bE',
        [ChainId.FUJI]: '0x51FE2D7Ad832ebE5521AD838bC6e747A843e96a8',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.WETHe,
      poolSymbol: PoolSymbol.ALT_ANKRETH_WETHE,
      isAvailable: true,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 57,
        [ChainId.FUJI]: 36,
      },
      color: '#0E0E0E',
    }),
  },
}
