import { alpha, Box, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import InfoBox from '../../../components/InfoBox/InfoBox'
import Input from '../../../components/Input/Input'
import Modal from '../../../components/Modal/Modal'
import WarningMessage from '../../../components/WarningMessage/WarningMessage'
const radioButtonSize = '16px'
const Border = css`
  &::before,
  &::after {
    position: absolute;
    content: '';
    width: ${radioButtonSize};
    height: ${radioButtonSize};
    border-radius: inherit;
    z-index: 0;
    top: 0;
    left: 0;
  }
  &::before {
    opacity: 0.2;
    border: 1px solid #fff;
  }
  &::after {
    width: calc(${radioButtonSize} * 0.5);
    height: calc(${radioButtonSize} * 0.5);
    border-radius: inherit;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    z-index: 0;
    transition: all 0.1s ease-in-out;
  }
  &:focus-within {
    &::before {
      opacity: 0.4;
    }
  }

  &.active {
    &::after {
      background: #fff;
      transform: translate(-50%, -50%) scale(1);
    }
    &::before {
      opacity: 0.8;
    }
  }
`

export const SubHeader = styled(Typography).attrs(() => ({
  variant: 'caption',
}))`
  opacity: 0.5;
`
export const Unit = styled(Typography)`
  margin-left: 2px;
`

interface CustomOptionProps {
  $maxWidth?: string
}
export const CustomOption = styled(Input)<CustomOptionProps>`
  ${({ theme, $maxWidth }) => css`
    background-color: unset;
    max-width: ${$maxWidth || '52px'};
    margin: 0 2px 0 0;
    min-height: 24px;

    .MuiInput-input {
      padding: 2px 4px;
      text-align: right;
    }
    border: 1px solid ${alpha(theme.palette.text.primary, 0.4)};
    border-radius: ${theme.borderRadius.rd};
    &:focus-within {
      border-color: ${theme.palette.text.primary};
    }
  `}
`

export const RadioButton = styled(Box)`
  position: relative;
  width: ${radioButtonSize};
  height: ${radioButtonSize};
  border-radius: 999px;
  margin-right: 7px;
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }

  ${Border}
`

export const RadioButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:not(:last-of-type) {
    margin-right: 28px;
  }
`
export const RadioButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  * {
    font-size: 1rem !important;
  }
`

export const StyledModal = styled(Modal)`
  hr {
    margin: 8px 0;
    opacity: 0;
    width: 100%;
  }
`

export const Title = styled(Typography).attrs(() => ({
  variant: 'body1',
}))`
  margin: 4px 0;
`

export const StyledWarningMessage = styled(WarningMessage).attrs(
  ({ theme }) => ({
    color: theme.palette.accents.yellow,
  }),
)`
  justify-content: flex-start;
  margin: 5px 0;
  border: unset;
  padding: 0;
  opacity: 1;
  svg {
    width: 15px;
  }
`

export const StyledInfoBox = styled(InfoBox)`
  padding: 0;
`
