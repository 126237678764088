import { Box } from '@mui/material'
import styled, { keyframes } from 'styled-components'

const Rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
export const Container = styled(Box)`
  display: flex;
  img {
    animation: ${Rotate360} 2s linear infinite;
  }
`
