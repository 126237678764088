import React, { ReactElement } from 'react'
import WaitForConfirmationModal from '../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import DisabledAppTokenInput from '../../../components/TokenInput/DisabledAppTokenInput'
import { useModal } from '../../../contexts/ModalContext'
import { usePoolInput } from '../../../contexts/PoolInputContext'
import PoolDepositInfoBox from '../../PoolContainer/PoolDepositInfoBox'

interface Props {
  isOpen: boolean
}

function PoolDepositWaitForConfirmationModalContainer({
  isOpen,
}: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const {
    depositLiquidity,
    actions: { clearDepositLiquidity },
  } = usePoolInput()
  const handleCloseModal = () => {
    modalDispatch(closeModal())
    clearDepositLiquidity()
  }
  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="600px"
      subtitle="Adding Liquidity"
    >
      <DisabledAppTokenInput
        tokenItems={[
          {
            tokenSymbol: depositLiquidity.tokenSymbol,
            value: depositLiquidity.amount,
          },
        ]}
      />

      <PoolDepositInfoBox />
    </WaitForConfirmationModal>
  )
}

export default PoolDepositWaitForConfirmationModalContainer
