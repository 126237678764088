import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import DisabledAppTokenInput from '../../../components/TokenInput/DisabledAppTokenInput'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { ModalId } from '../../../interfaces/Modal'
import MemorizedNftCardContainer from '../../MemorizedNftCardContainer/MemorizedNftCardContainer'
import { Subtitle } from './ConfirmUnstakeOrUnlockModalContainer.elements'

interface Props {
  ptpAmount: string
  vePtpToLose: string
}

function UnstakeOrUnlockDisabledInputsContainer({
  ptpAmount,
  vePtpToLose,
}: Props): ReactElement {
  const { modalState } = useModal()
  const { input, nft } = useVePtp()

  const isInConfirmUnlockModal =
    modalState.currentModalId === ModalId.CONFIRM_UNLOCK_PTP

  return (
    <>
      <Box>
        <Subtitle variant="caption">
          {`Amount to ${isInConfirmUnlockModal ? 'unlock' : 'unstake'}`}
        </Subtitle>
        <DisabledAppTokenInput
          tokenItems={[
            {
              value: ptpAmount,
              tokenSymbol: TokenSymbol.PTP,
            },
          ]}
        />
        <Subtitle variant="caption">Amount to lose</Subtitle>
        <DisabledAppTokenInput
          tokenItems={[
            {
              value: `-${vePtpToLose}`,
              tokenSymbol: TokenSymbol.VEPTP,
            },
          ]}
        />
      </Box>
      {input.isUnstakedAllPtpWithEquippedNft && (
        <>
          <Subtitle variant="caption">NFT to unequip</Subtitle>
          <MemorizedNftCardContainer id={nft.equippedId} enableBgColor />
        </>
      )}
    </>
  )
}

export default UnstakeOrUnlockDisabledInputsContainer
