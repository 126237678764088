import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { Typography } from '@mui/material'
import React, { ReactElement, useState } from 'react'
import Modal from '../../components/Modal/Modal'
import TokenIcon from '../../components/TokenIcon/TokenIcon'
import { LP_TOKENS, TOKENS } from '../../config/contracts'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { useBalance } from '../../contexts/BalanceContext'
import { useModal } from '../../contexts/ModalContext'
import { useMulticallData } from '../../contexts/MulticallDataContext'
import { useNetwork } from '../../contexts/NetworkContext'
import { usePoolInput } from '../../contexts/PoolInputContext'
import showDashIfNecessary from '../../utils/showDashIfNecessary'
import {
  StyledInput,
  TokenBalance,
  TokenList,
  TokenListItem,
  TokenListItemIcon,
  TokenName,
} from './SelectTokenModalContainer.elements'

interface Props {
  tokenSymbols: TokenSymbol[] | null
  isOpen: boolean
  onSelectToken: (value: { token: TokenSymbol }) => void
  onClose?: () => void
  currentSelectedToken?: TokenSymbol | null
  disableBalance?: boolean
  /** In avax-savax, tokenSymbol is wavax and tokenSymbolForDisplay is avax when withraw savax to avax  */
  requiredTokenSymbolForDisplay?: boolean
}

function SelectTokenModalContainer({
  tokenSymbols,
  isOpen,
  onSelectToken,
  onClose,
  currentSelectedToken,
  disableBalance,
  requiredTokenSymbolForDisplay,
}: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const { chainId } = useNetwork()
  const { selectedPoolSymbol } = usePoolInput()
  const { tokenAmounts } = useBalance()
  const [filteredToken, setFilteredToken] = useState<string>('')
  const handleCloseModal = () => {
    if (onClose) {
      onClose()
    } else {
      modalDispatch(closeModal())
    }

    setFilteredToken('')
  }

  const handleOnSelectToken = (value: { token: TokenSymbol }) => {
    onSelectToken(value)
    handleCloseModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      topBarLabel="Select a token"
    >
      <StyledInput
        placeholder="Search Name or Paste Address"
        value={filteredToken}
        onChange={setFilteredToken}
      />
      <TokenList>
        {(tokenSymbols || [])
          .filter(
            (tokenSymbol) =>
              !filteredToken ||
              tokenSymbol.toUpperCase().includes(filteredToken.toUpperCase()) ||
              (TOKENS[tokenSymbol].address[chainId] || '')
                .toUpperCase()
                .includes(filteredToken.toUpperCase()),
          )
          .sort((tokenSymbolA, tokenSymbolB) => {
            const tokenBalanceA = strToWad(tokenAmounts[tokenSymbolA])
            const tokenBalanceB = strToWad(tokenAmounts[tokenSymbolB])
            if (tokenBalanceA.gt(tokenBalanceB)) {
              return -1
            }
            if (tokenBalanceA.lt(tokenBalanceB)) {
              return 1
            }
            return 0
          })
          .map((tokenSymbol) => {
            let tokenSymbolForDisplay = tokenSymbol
            /** In avax-savax, tokenSymbol is wavax and tokenSymbolForDisplay is avax when withraw savax to avax  */
            if (requiredTokenSymbolForDisplay) {
              tokenSymbolForDisplay =
                LP_TOKENS[selectedPoolSymbol][tokenSymbol]
                  ?.tokenSymbolForDisplay || tokenSymbol
            }
            return (
              <TokenItem
                tokenSymbol={tokenSymbolForDisplay}
                key={tokenSymbolForDisplay}
                handleOnSelectToken={() =>
                  handleOnSelectToken({ token: tokenSymbol })
                }
                selected={tokenSymbol === currentSelectedToken}
                disableBalance={disableBalance}
              />
            )
          })}
      </TokenList>
    </Modal>
  )
}

export default React.memo(SelectTokenModalContainer)

interface TokenItemProps {
  tokenSymbol: TokenSymbol
  handleOnSelectToken: (token: TokenSymbol) => void
  disableBalance?: boolean
  className?: string
  selected?: boolean
}
const TokenItem = ({
  tokenSymbol,
  handleOnSelectToken,
  disableBalance,
  className,
  selected,
}: TokenItemProps) => {
  const { isMulticallDataFetched } = useMulticallData()
  const { tokenAmounts } = useBalance()
  return (
    <TokenListItem
      key={tokenSymbol}
      onClick={() => handleOnSelectToken(tokenSymbol)}
      className={className}
      selected={selected}
    >
      <TokenListItemIcon>
        <TokenIcon tokenSymbol={tokenSymbol} />
      </TokenListItemIcon>
      <TokenName>
        <Typography variant="body1">{tokenSymbol}</Typography>
      </TokenName>
      {!disableBalance && (
        <TokenBalance>
          {showDashIfNecessary(
            !isMulticallDataFetched,
            getCommifiedFormat(tokenAmounts[tokenSymbol]),
          )}
        </TokenBalance>
      )}
    </TokenListItem>
  )
}
