import { getDpFormat, getDynamicFormat } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import TooltipText from '../../../components/TooltipText/TooltipText'
import { useBalance } from '../../../contexts/BalanceContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useBenefitApr } from '../../../hooks/vePtp/useBenefitApr'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import VePtpBenefitAprTooltip from '../VePtpBenefit/VePtpBenefitAprTooltip'
import { BalanceDetailItem, Container } from './TotalBalanceInfo.elements'

function TotalBalanceInfo(): ReactElement {
  const { vePtp, ptp } = useVePtp()
  const { isTabletSm } = useBreakpoints()
  const { total } = useBenefitApr()
  const { isTokenPriceFetched } = useBalance()

  return (
    <Container>
      <BalanceDetailItem flex={!isTabletSm ? '1.2' : '0.5'}>
        <Box
          display="flex"
          flexDirection={isTabletSm ? 'column' : 'row'}
          alignItems="center"
        >
          <AppTypography
            variant={!isTabletSm ? 'caption' : 'caption2'}
            transparent
          >
            Total Staked & Locked
          </AppTypography>
          <InfoBox.Tooltip
            text={
              <TooltipText minWidth="150px">
                <TooltipText.Group>
                  <TooltipText.RewardItem
                    title={'Staked'}
                    value={`${getDynamicFormat(ptp.total.amount.staked)} PTP`}
                    isBold={false}
                  />
                  <TooltipText.RewardItem
                    title={'Locked'}
                    value={`${getDynamicFormat(ptp.total.amount.locked)} PTP`}
                    isBold={false}
                  />
                </TooltipText.Group>
              </TooltipText>
            }
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <AppTypography variant="h4">
            <TooltipNum amount={ptp.total.amount.sum}>
              {getDynamicFormat(ptp.total.amount.sum)}
            </TooltipNum>
          </AppTypography>
          &nbsp;
          <AppTypography variant="subtitle1">PTP</AppTypography>
        </Box>
      </BalanceDetailItem>
      <BalanceDetailItem flex={!isTabletSm ? '0.9' : '0.5'}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <AppTypography variant="caption" transparent>
            Total vePTP supply
          </AppTypography>
          <InfoBox.Tooltip text={`Total vePTP balance of all users.`} />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <AppTypography variant="h4" component="span">
            <TooltipNum amount={vePtp.totalSupply}>
              {getDynamicFormat(vePtp.totalSupply)}
            </TooltipNum>
          </AppTypography>
          &nbsp;
          <AppTypography variant="subtitle1">vePTP</AppTypography>
        </Box>
      </BalanceDetailItem>
      <BalanceDetailItem flex={!isTabletSm ? '0.7' : '1'}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <AppTypography variant="caption" transparent>
            Total APR
          </AppTypography>
          <VePtpBenefitAprTooltip mode="all" />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <AppTypography variant="h4" component="span">
            <TooltipNum amount={total.totalBenefitAprWad}>
              {showDashIfNecessary(
                !isTokenPriceFetched,
                getDpFormat(total.totalBenefitAprWad, 1),
              )}
              %
            </TooltipNum>
          </AppTypography>
          &nbsp;
        </Box>
      </BalanceDetailItem>
    </Container>
  )
}

export default TotalBalanceInfo
