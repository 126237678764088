// set pool order in ui (My Total Deposit, My Total Earnings) by POOL_GROUPS
export enum PoolSymbol {
  /* ------------------------------ Main Pools ------------------------------ */
  MAIN = 'MAIN',
  DEPRECATED_MAIN = 'DEPRECATED_MAIN',
  DEPRECATED_MAIN2 = 'DEPRECATED_MAIN2',

  /* ------------------------------ Alt Pools ------------------------------ */
  ALT_AVAX_SAVAX = 'ALT_AVAX_SAVAX',
  ALT_AVAX_YYAVAX = 'ALT_AVAX_YYAVAX',
  ALT_AVAX_ANKRAVAX = 'ALT_AVAX_ANKRAVAX',
  ALT_USDC_YUSD = 'ALT_USDC_YUSD',
  ALT_USDC_FRAX = 'ALT_USDC_FRAX',
  ALT_USDC_MIM = 'ALT_USDC_MIM',
  ALT_BTCB_WBTCE = 'ALT_BTCB_WBTCE',
  ALT_USDC_MONEY = 'ALT_USDC_MONEY',
  ALT_ANKRETH_WETHE = 'ALT_ANKRETH_WETHE',

  /* ------------------------------ Deprecated Alt Pools ------------------------------ */
  DEPRECATED_ALT_AVAX_SAVAX = 'DEPRECATED_ALT_AVAX_SAVAX',
  DEPRECATED_ALT_AVAX_YYAVAX = 'DEPRECATED_ALT_AVAX_YYAVAX',
  DEPRECATED_ALT_AVAX_ANKRAVAX = 'DEPRECATED_ALT_AVAX_ANKRAVAX',
  DEPRECATED_ALT_USDC_YUSD = 'DEPRECATED_ALT_USDC_YUSD',
  DEPRECATED_ALT_USDC_FRAX = 'DEPRECATED_ALT_USDC_FRAX',
  DEPRECATED_ALT_USDC_MIM = 'DEPRECATED_ALT_USDC_MIM',
  DEPRECATED_ALT_USDC_UST = 'DEPRECATED_ALT_USDC_UST',
  DEPRECATED_ALT_BTCB_WBTCE = 'DEPRECATED_ALT_BTCB_WBTCE',
  DEPRECATED_ALT_USDC_MONEY = 'DEPRECATED_ALT_USDC_MONEY',
  DEPRECATED_ALT_USDC_TUSD = 'DEPRECATED_ALT_USDC_TUSD',
  DEPRECATED_ALT_USDC_AXLUSDC = 'DEPRECATED_ALT_USDC_AXLUSDC',
  DEPRECATED_ALT_ANKRETH_WETHE = 'DEPRECATED_ALT_ANKRETH_WETHE',

  /* ------------------------------ Factory Pools (All Deprecated) ------------------------------ */
  FACTORY_USDC_MONEY = 'FACTORY_USDC_MONEY',
  FACTORY_USDC_TSD = 'FACTORY_USDC_TSD',
  FACTORY_USDC_H2O = 'FACTORY_USDC_H2O',
  FACTORY_USDC_MIMATIC = 'FACTORY_USDC_MIMATIC',
  FACTORY_USDC_USX = 'FACTORY_USDC_USX',
}
export const poolSymbols = Object.values(PoolSymbol)

export enum PoolGroupSymbol {
  MAIN = 'MAIN',
  ALT = 'ALT',
  // FACTORY = 'FACTORY',
}

export enum PoolFilterSymbol {
  USD = 'USD',
  AVAX = 'AVAX',
  BTC = 'BTC',
  ETH = 'ETH',
}
