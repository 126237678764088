import queryString from 'query-string'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BoostCalculator from '../../components/platopia/BoostCalculator/BoostCalculator'
import Lottie from '../../components/platopia/Lottie/Lottie'
import NFTSummary from '../../components/platopia/NFTSummary/NFTSummary'
import PlatopiaImage from '../../components/platopia/PlatopiaImage/PlatopiaImage'
import PtpSummary from '../../components/platopia/PtpSummary/PtpSummary'
import VePtpSummary from '../../components/platopia/VePtpSummary/VePtpSummary'
import routes from '../../config/routes'
import { useModal } from '../../contexts/ModalContext'
import VePtpBoosterCalculatorProvider from '../../contexts/VePtpBoosterCalculatorContext'
import { useVePtp } from '../../contexts/VePtpContext'
import { ModalId } from '../../interfaces/Modal'
import ClaimVePtpWaitForConfirmationModalContainer from '../VePtpModalContainers/ClaimVePtpWaitForConfirmationModalContainer/ClaimVePtpWaitForConfirmationModalContainer'
import ConfirmUnstakeOrUnlockModalContainer from '../VePtpModalContainers/ConfirmUnstakeOrUnlockModalContainer/ConfirmUnstakeOrUnlockModalContainer'
import StakeModalContainer from '../VePtpModalContainers/StakeModalContainer/StakeModalContainer'
import StakeWaitForConfirmationModalContainer from '../VePtpModalContainers/StakeWaitForConfirmationModalContainer/StakeWaitForConfirmationModalContainer'
import UnstakeModalContainer from '../VePtpModalContainers/UnstakeModalContainer/UnstakeModalContainer'
import UnstakeWaitForConfirmationModalContainer from '../VePtpModalContainers/UnstakeWaitForConfirmationModalContainer/UnstakeWaitForConfirmationModalContainer'
import VePtpBoosterCalculatorModal from '../VePtpModalContainers/VePtpBoosterCalculatorModal/VePtpBoosterCalculatorModal'
import {
  AnimationContainer,
  BottomContainer,
  Container,
  StageBackground,
  TopContainer,
  WalkPlatypus1,
  WalkPlatypus2,
  WebAppButton,
  WebAppButtonBackground,
  WebAppButtonContainer,
  WebAppIcon,
} from './PlatopiaContainer.elements'
import settings, { Stage } from './settings'

const isInDevEnv = process.env.REACT_APP_ENVIRONMENT !== 'production'
function PlatopiaContainer(): ReactElement {
  const navigate = useNavigate()
  const {
    nft: { population: populationFromContext, stage: stageFromContext },
  } = useVePtp()
  const [positions, setPositions] = useState<Array<number>>([])
  const [stageFromQuery, setStageFromQuery] = useState(Stage.stage1)
  const [populationFromQuery, setPopulationFromQuery] = useState(0)

  // stage and population that are used in Platopia
  const stage = (isInDevEnv && stageFromQuery) || stageFromContext
  const population =
    (isInDevEnv && populationFromQuery) || populationFromContext
  const { modalState } = useModal()

  // handle population and stage from query for development environment
  useEffect(() => {
    if (!isInDevEnv) return
    const query = queryString.parse(location.search)
    const stageFromQuery = query['stage']
    const populationFromQuery = query['population']
    if (typeof stageFromQuery === 'string') {
      const stageKey = `stage${stageFromQuery}` as keyof typeof Stage
      const newStage = Stage[stageKey]
      if (newStage) {
        setStageFromQuery(newStage)
      }
    }
    if (typeof populationFromQuery === 'string') {
      const isNumber = !Number.isNaN(populationFromQuery)

      if (isNumber) {
        setPopulationFromQuery(Number(populationFromQuery))
      }
    }
    // use empty dependency so it only run once when the page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    Array.from(Array(Math.floor(population / 100)).keys()).map(() => {
      /* Delay the platypus randomly for at most 10 seconds so it won't walk in sync */
      setTimeout(() => {
        setPositions((currentPosition) => [
          ...currentPosition,
          /* Range from -40 to -100 */
          Math.random() * -60 - 40,
        ])
      }, Math.random() * 10000)
    })
  }, [population])

  const walkingPlatypus = useMemo(
    () =>
      positions.map((bottom, index) => {
        if (index % 2 === 0) {
          return (
            <WalkPlatypus1
              key={`walk-platypus-${index}`}
              bottom={bottom}
              /* Bottom range from -40 to -100. zIndex range from 45 to 70 */
              zIndex={Math.floor(45 + (-bottom - 40) / 2.1)}
            />
          )
        }

        return (
          <WalkPlatypus2
            key={`walk-platypus-${index}`}
            bottom={bottom}
            zIndex={Math.floor(45 + (-bottom - 40) / 2.1)}
          />
        )
      }),
    [positions],
  )

  return (
    <Container>
      <TopContainer>
        <VePtpSummary />
        <PtpSummary />
      </TopContainer>
      <AnimationContainer>
        <StageBackground stage={stage} />
        {settings[stage].lotties.map((setting) => (
          <Lottie {...setting} key={setting.key} />
        ))}
        {settings[stage].images.map((setting) => (
          <PlatopiaImage {...setting} key={setting.key} />
        ))}
        {walkingPlatypus}
        <WebAppButtonContainer>
          <WebAppButtonBackground>
            <WebAppButton onClick={() => navigate(routes.STAKE.path)}>
              <WebAppIcon />
              {`To Web App >`}
            </WebAppButton>
          </WebAppButtonBackground>
        </WebAppButtonContainer>
      </AnimationContainer>
      <BottomContainer>
        <NFTSummary population={population} />
        <BoostCalculator />
      </BottomContainer>
      {modalState.currentModalId === ModalId.STAKE_PTP && (
        <StakeModalContainer isOpen />
      )}
      {modalState.currentModalId === ModalId.STAKE_WAIT_FOR_CONFIRMATION && (
        <StakeWaitForConfirmationModalContainer isOpen />
      )}
      {modalState.currentModalId === ModalId.UNSTAKE_PTP && (
        <UnstakeModalContainer isOpen />
      )}
      {modalState.currentModalId === ModalId.CONFIRM_UNSTAKE_PTP && (
        <ConfirmUnstakeOrUnlockModalContainer isOpen />
      )}
      {modalState.currentModalId === ModalId.UNSTAKE_WAIT_FOR_CONFIRMATION && (
        <UnstakeWaitForConfirmationModalContainer isOpen />
      )}
      {modalState.currentModalId ===
        ModalId.CLAIM_VEPTP_WAIT_FOR_CONFIRMATION && (
        <ClaimVePtpWaitForConfirmationModalContainer isOpen />
      )}
      {modalState.currentModalId === ModalId.VEPTP_BOOSTER_CALCULATOR && (
        <VePtpBoosterCalculatorProvider>
          <VePtpBoosterCalculatorModal isOpen />
        </VePtpBoosterCalculatorProvider>
      )}
    </Container>
  )
}

export default PlatopiaContainer
