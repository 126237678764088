import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { alpha, Box, useTheme } from '@mui/material'
import React from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import Modal from '../../../components/Modal/Modal'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import AppTokenInput from '../../../components/TokenInput/AppTokenInput'
import WarningMessage from '../../../components/WarningMessage/WarningMessage'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useBalance } from '../../../contexts/BalanceContext'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { useVePtpInput } from '../../../contexts/VePtpInputContext'
import { ScrollableBox } from '../../../globalStyles'
import useExtendPtpLock from '../../../hooks/vePtp/locking/useExtendPtpLock'
import useVeptpToReceive from '../../../hooks/vePtp/locking/useVeptpToReceive'
import LockPtpInfoBox from '../LockPtpModalContainer/LockPtpInfoBox'

interface ModalProps {
  isOpen: boolean
}

function ExtendPtpLockModalContainer({ isOpen }: ModalProps) {
  const theme = useTheme()
  const {
    lockDayInputAmount,
    lockPtpInputAmount,
    totalLockDays,
    actions: { lockDayInputAmountOnChange, resetLockDayAndLockPtpAmount },
  } = useVePtpInput()

  const { ptp } = useVePtp()

  const { tokenAmounts } = useBalance()

  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()

  const hasZeroLockDayInput = strToWad(lockDayInputAmount).isZero()

  const { toReceiveGtMaxCap } = useVeptpToReceive({
    lockDayInputAmount,
    lockPtpInputAmount,
  })

  const { handleExtendPtpLock, isWaiting } = useExtendPtpLock()

  const handleModalClose = () => {
    modalDispatch(closeModal())
    resetLockDayAndLockPtpAmount()
  }

  const handleClickMaxLockDayButton = () => {
    lockDayInputAmountOnChange(
      (ptp.lockTime.maxDays - Number(totalLockDays.original)).toString(),
    )
  }
  const isMaxLockDaysReached =
    Number(totalLockDays.original) === ptp.lockTime.maxDays

  const renderWarningMessage = (isMessageShown: boolean, message: string) => {
    if (!isMessageShown) return null
    return (
      <WarningMessage style={{ border: 'none' }} iconSize="14px" margin="sm">
        <AppTypography variant="body2" component="span">
          {message}
        </AppTypography>
      </WarningMessage>
    )
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      TopBarLabelComponent={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <TokenIcon margin="0 12px" tokenSymbol={TokenSymbol.PTP} size={22} />
          <AppTypography variant="h6">{`Extend Lock`}</AppTypography>
        </Box>
      }
      width="650px"
    >
      <ScrollableBox>
        {/**Lock Extend Day Input Amount */}
        <AppTokenInput
          onChange={lockDayInputAmountOnChange}
          value={lockDayInputAmount}
          sliderValue={lockDayInputAmount}
          onSliderChange={lockDayInputAmountOnChange}
          style={{ padding: '0', marginTop: '8px' }}
        >
          <AppTokenInput.LabelItem
            leadingLabel={
              <AppTokenInput.LabelItemInnerContainer
                style={{ marginBottom: '8px' }}
              >
                <AppTokenInput.Label>Locked:&nbsp;</AppTokenInput.Label>
                <Box display="flex" alignItems="center">
                  <TooltipNum
                    rightSymbol={TokenSymbol.PTP}
                    amount={ptp.amount.locked}
                  >
                    {getCommifiedFormat(ptp.amount.locked, 2)} {TokenSymbol.PTP}
                  </TooltipNum>
                </Box>
              </AppTokenInput.LabelItemInnerContainer>
            }
            trailingLabel={
              <AppTokenInput.LabelItemInnerContainer
                style={{ marginBottom: '8px' }}
              >
                <AppTokenInput.Label>Balance:&nbsp;</AppTokenInput.Label>
                <Box display="flex" alignItems="center">
                  <TooltipNum
                    rightSymbol={TokenSymbol.PTP}
                    amount={tokenAmounts[TokenSymbol.PTP]}
                  >
                    {getCommifiedFormat(tokenAmounts[TokenSymbol.PTP], 2)}{' '}
                    {TokenSymbol.PTP}
                  </TooltipNum>
                </Box>
              </AppTokenInput.LabelItemInnerContainer>
            }
          />
          <AppTokenInput.InnerContainer
            bgcolor={alpha(theme.palette.primary[500], 0.2)}
            flexDirection="column"
          >
            <Box display="flex" flexDirection="row">
              <AppTokenInput.InputField
                placeholder="0.0"
                inputUnitLabel="days"
                disableUnderline
                textalign="left"
                noLeftPadding
              />
              <AppTokenInput.ActionButton onClick={handleClickMaxLockDayButton}>
                Max
              </AppTokenInput.ActionButton>
            </Box>
            <AppTokenInput.Slider
              max={ptp.lockTime.maxDays}
              min={0}
              sliderLabelType="day"
            />
          </AppTokenInput.InnerContainer>
        </AppTokenInput>

        {renderWarningMessage(
          isMaxLockDaysReached,
          `Your total lock days have reached max lock days (${ptp.lockTime.maxDays} days)`,
        )}
        {renderWarningMessage(
          toReceiveGtMaxCap,
          `${TokenSymbol.VEPTP} to receive exceeds max cap to earn`,
        )}
        {renderWarningMessage(
          Number(lockDayInputAmount) > ptp.lockTime.maxDays,
          `Input day exceeds max lock days (${ptp.lockTime.maxDays} days)`,
        )}
        {renderWarningMessage(
          Number(lockDayInputAmount) < ptp.lockTime.minDays &&
            !hasZeroLockDayInput,
          `Input day is below min lock days (${ptp.lockTime.minDays} days)`,
        )}
        <LockPtpInfoBox />
        <Box display="flex">
          <AppButton
            onClick={handleModalClose}
            fullWidth
            customVariant="neutral"
          >
            Cancel
          </AppButton>
          <AppButton
            disabled={hasZeroLockDayInput || isWaiting}
            fullWidth
            onClick={() => void handleExtendPtpLock(lockDayInputAmount)}
            hasSpinner={isWaiting}
          >
            Extend
          </AppButton>
        </Box>
      </ScrollableBox>
    </Modal>
  )
}

export default ExtendPtpLockModalContainer
