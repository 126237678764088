import {
  getDpFormat,
  getMillifiedFormat,
  strToWad,
} from '@hailstonelabs/big-number-utils'
import { Divider } from '@mui/material'
import { constants } from 'ethers'
import React, { ReactElement, useMemo } from 'react'
import ReactGA from 'react-ga'
import { useSearchParams } from 'react-router-dom'
import MigrationAlert from '../../components/alerts/MigrationAlert/MigrationAlert'
import AuxiliaryPoolCard from '../../components/cards/AuxiliaryPoolCard/AuxiliaryPoolCard'
import PoolCard from '../../components/cards/PoolCard/PoolCard'
import TooltipNum from '../../components/InfoBox/TooltipNum'
import TokenIcon from '../../components/TokenIcon/TokenIcon'
import { POOLS } from '../../config/contracts'
import { Pool } from '../../config/contracts/pool/Pool'
import { POOL_GROUPS } from '../../config/contracts/pool/PoolGroup'
import {
  PoolGroupSymbol,
  PoolSymbol,
} from '../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import routes from '../../config/routes'
import { QUERY_PARAMS } from '../../constants'
import { useBalance } from '../../contexts/BalanceContext'
import { useErrorModal } from '../../contexts/ErrorModalContext'
import { useMigrationDetection } from '../../contexts/MigrationDetectionContext'
import { useModal } from '../../contexts/ModalContext'
import { useMulticallData } from '../../contexts/MulticallDataContext'
import { useNetwork } from '../../contexts/NetworkContext'
import { usePoolInput } from '../../contexts/PoolInputContext'
import { usePools } from '../../contexts/PoolsContext'
import { useStakeLpData } from '../../contexts/StakeLpDataContext'
import { useVePtp } from '../../contexts/VePtpContext'
import { ExternalLink } from '../../globalStyles'
import usePoolCardInfo from '../../hooks/pool/usePoolCardInfo'
import useBreakpoints from '../../hooks/useBreakpoints'
import useWalletConnection from '../../hooks/wallet/useWalletConnection'
import { ModalId } from '../../interfaces/Modal'
import showDashIfNecessary from '../../utils/showDashIfNecessary'
import PoolDepositModalContainer from '../PoolModalContainers/PoolDepositModalContainer/PoolDepositModalContainer'
import PoolDepositWaitForConfirmationModalContainer from '../PoolModalContainers/PoolDepositWaitForConfirmationModalContainer/PoolDepositWaitForConfirmationModalContainer'
import PoolWithdrawalModalContainer from '../PoolModalContainers/PoolWithdrawalModalContainer/PoolWithdrawalModalContainer'
import PoolWithdrawWaitForConfirmationModalContainer from '../PoolModalContainers/PoolWithdrawWaitForConfirmationModalContainer/PoolWithdrawWaitForConfirmationModalContainer'
import {
  PoolClaimAllRewardsContainer,
  PoolStakeAccordionContainer,
} from '../PoolStakeLPContainers'
import { PoolClaimWaitForConfirmationModalContainer } from '../PoolStakeLPContainers/PoolStakeLPModalContainers'
import { Container, PoolCardList } from './PoolContainer.elements'
import PoolGroupSelectionTabBar from './PoolGroupSelectionTabBar'
import PoolPtpInfoContainer from './PoolPtpInfoContainer/PoolPtpInfoContainer'

const toolTipMessage = (
  <>
    The coverage ratio is the asset-to-liability ratio of a pool. It determines
    the swapping slippage, withdrawal and deposit fee in our protocol. Learn
    more about it in our{' '}
    <ExternalLink href="https://docs.platypus.finance/platypus-finance-docs/our-innovative-concepts/coverage-ratio">
      documentation
    </ExternalLink>
    .
  </>
)
function PoolContainer(): ReactElement {
  const { account } = useNetwork()
  const { openConnectToWalletModal } = useWalletConnection()
  const {
    modalDispatch,
    modalState,
    actions: { openModal },
  } = useModal()
  const { isPoolFetched } = usePools()
  const { lp, apr } = useStakeLpData()
  const { isMulticallDataFetchedWithAccount, isMulticallDataFetched } =
    useMulticallData()
  const { vePtp } = useVePtp()
  const { isTokenPriceFetched } = useBalance()
  const [searchParams] = useSearchParams()
  const { openErrorModal } = useErrorModal()
  const {
    selectedPoolGroupSymbol,
    actions: {
      updateSelectedPoolGroupSymbol,
      initializeDepositLiquidity,
      initializeWithdrawLiquidity,
    },
  } = usePoolInput()
  const { isTabletSm } = useBreakpoints()
  const { isDelistNeeded } = useMigrationDetection()
  const getPoolCardInfo = usePoolCardInfo()
  const handleClickAddLiquidity = (
    poolSymbol: PoolSymbol,
    assetTokenSymbol: TokenSymbol,
  ) => {
    initializeDepositLiquidity(poolSymbol, assetTokenSymbol)
    modalDispatch(openModal(ModalId.POOL_DEPOSIT_LIQUIDITY))
  }
  const poolSubgroupQuery = searchParams.get(QUERY_PARAMS.poolSubgroup) || ''
  const handleClickRemoveLiquidity = (
    poolSymbol: PoolSymbol,
    assetTokenSymbol: TokenSymbol,
  ) => {
    const hasStakableLp = strToWad(
      lp.inTermsOfToken.stakable[poolSymbol][assetTokenSymbol],
    ).gt(constants.Zero)
    // users have staked all lp
    if (!hasStakableLp) {
      openErrorModal({
        title: 'Please unstake to withdraw',
        message:
          'You have staked the token. Please unstake first to withdraw the corresponding token.',
      })
      return
    }
    initializeWithdrawLiquidity(poolSymbol, assetTokenSymbol)
    modalDispatch(openModal(ModalId.POOL_WITHDRAW_LIQUIDITY))
  }

  const hasOnlyOnePoolGroup = Object.keys(POOL_GROUPS).length === 1
  const hasVePtp = vePtp.balance.isMoreThanZero

  /**
   * For rendering cards
   */
  const renderAuxiliaryPoolCardItems = (pool: Pool) => {
    return pool.getAssets().map((asset) => {
      const myTotalApr = apr.total[pool.poolSymbol][asset.tokenSymbol]
      const sumOfBaseApr = apr.base.sum[pool.poolSymbol][asset.tokenSymbol]
      const {
        hasStakedLP,
        stakableLpWad,
        myDepositsUSDWad,
        myDeposits,
        poolDepositUsdAmount,
      } = getPoolCardInfo(pool.poolSymbol, asset.tokenSymbol)
      return (
        <AuxiliaryPoolCard.Item
          key={asset.tokenSymbol}
          poolStakeStatus={{
            'Please Stake': !stakableLpWad.isZero(),
            Earning: hasStakedLP,
            'Booster+': pool.hasPtpBooster && hasStakedLP && hasVePtp,
          }}
          hasDeposit={strToWad(myDeposits).gt(constants.Zero)}
          tokenSymbol={asset.tokenSymbolForDisplay}
          data={{
            apr: {
              label: account ? 'My APR' : 'Base APR',
              value: `${showDashIfNecessary(
                !isTokenPriceFetched ||
                  (account
                    ? !isMulticallDataFetchedWithAccount
                    : !isMulticallDataFetched),
                getDpFormat(account ? myTotalApr : sumOfBaseApr, 2),
              )}%`,
            },
            tvl: {
              label: account ? 'My Deposits' : 'Pool Deposits',
              value: (
                <TooltipNum
                  leftSymbol="$"
                  amount={account ? myDepositsUSDWad : poolDepositUsdAmount}
                  format="commified"
                >
                  $&nbsp;
                  {showDashIfNecessary(
                    !isTokenPriceFetched || !isPoolFetched,
                    getMillifiedFormat(
                      account ? myDepositsUSDWad : poolDepositUsdAmount,
                    ),
                  )}
                </TooltipNum>
              ),
            },
          }}
        />
      )
    })
  }

  const renderPoolCards = (pool: Pool) => {
    const poolSymbol = pool.poolSymbol
    return pool.getAssets().map((asset) => {
      const assetTokenSymbol = asset.tokenSymbol
      const tokenSymbolForDisplay = asset.tokenSymbolForDisplay
      const {
        isVolumeFetched,
        coverageRatio,
        hasStakedLP,
        stakableLpWad,
        myDepositsUSDWad,
        myDeposits,
        poolDepositAmount,
        poolDepositUsdAmount,
        volumeOfTokensIn24hrWad,
        volumeOfTokensIn24hrPriceWad,
      } = getPoolCardInfo(poolSymbol, assetTokenSymbol)
      return (
        <PoolCard
          poolSymbol={poolSymbol}
          assetTokenSymbol={assetTokenSymbol}
          hasDeposit={strToWad(myDeposits).gt(constants.Zero)}
          key={assetTokenSymbol}
          poolStakeStatus={{
            'Please Stake': !stakableLpWad.isZero(),
            Earning: hasStakedLP,
            'Booster+': pool.hasPtpBooster && hasStakedLP && hasVePtp,
          }}
        >
          <PoolCard.CardInfo
            tokenSymbol={tokenSymbolForDisplay}
            data={{
              coverageRatio: {
                amount: coverageRatio,
                isDashShown: !isPoolFetched,
                toolTipMessage: toolTipMessage,
              },
              poolDepositFiat: {
                amount: poolDepositUsdAmount,
                isDashShown: !isTokenPriceFetched || !isPoolFetched,
              },
              poolDeposit: {
                amount: poolDepositAmount,
                isDashShown: !isPoolFetched,
              },
              volumeOfTokensIn24hrFiat: {
                amount: volumeOfTokensIn24hrPriceWad,
                isDashShown: !isTokenPriceFetched || !isVolumeFetched,
              },
              volumeOfTokensIn24hr: {
                amount: volumeOfTokensIn24hrWad,
                isDashShown: !isVolumeFetched,
              },
              myDepositsFiat: {
                amount: myDepositsUSDWad,
                isDashShown:
                  !isTokenPriceFetched ||
                  !isPoolFetched ||
                  !isMulticallDataFetchedWithAccount,
              },
              myDeposits: {
                amount: myDeposits,
                isDashShown:
                  !isPoolFetched || !isMulticallDataFetchedWithAccount,
              },
            }}
            depositButtonProps={{
              onClick: () => {
                if (account) {
                  ReactGA.event({
                    category: 'button',
                    action: 'click',
                    label: 'deposit',
                  })
                  handleClickAddLiquidity(poolSymbol, assetTokenSymbol)
                } else {
                  openConnectToWalletModal()
                }
              },
            }}
            withdrawButtonProps={{
              disabled: strToWad(
                lp.inTermsOfToken.total[poolSymbol][assetTokenSymbol],
              ).isZero(),
              onClick: () => {
                ReactGA.event({
                  category: 'button',
                  action: 'click',
                  label: 'withdraw',
                })
                handleClickRemoveLiquidity(poolSymbol, assetTokenSymbol)
              },
            }}
          />

          {!isTabletSm && <Divider flexItem />}
          <PoolStakeAccordionContainer
            poolSymbol={poolSymbol}
            assetTokenSymbol={assetTokenSymbol}
            hasDeposit={strToWad(myDeposits).gt(constants.Zero)}
          />
        </PoolCard>
      )
    })
  }

  //filter pool by poolSubgroupQuery
  const filteredPoolSymbols = useMemo(() => {
    return POOL_GROUPS[selectedPoolGroupSymbol].poolSymbols.filter(
      (poolSymbol) => {
        const pool = POOLS[poolSymbol]
        return poolSubgroupQuery
          .toLowerCase()
          .includes(pool.poolFilterSymbol.toLowerCase())
      },
    )
  }, [poolSubgroupQuery, selectedPoolGroupSymbol])

  /**
   * Render Components
   */
  return (
    <>
      <Container>
        {/* {isMigrationNeeded && (
          <MigrationAlert
            text={
              <>
                To continue earning PTP emission on Platypus, you must migrate
                your funds to the new contract.
              </>
            }
            btnText="Go to migration page"
            linkTo={routes.MIGRATION.path}
          />
        )} */}
        {isDelistNeeded && (
          <MigrationAlert
            text={
              <>
                You have funds in the pools that have been delisted.
                <br />
                To withdraw your funds, you must go to the following page.
              </>
            }
            btnText="Go to deprecated pools page"
            linkTo={routes.DEPRECATED_POOLS.path}
          />
        )}
        <PoolPtpInfoContainer />
        <PoolClaimAllRewardsContainer />
        <PoolCardList>
          {hasOnlyOnePoolGroup ? (
            <>
              <TokenIcon margin="0 10px 0 0" />
              Pool
            </>
          ) : (
            <PoolGroupSelectionTabBar
              currentPoolGroupSymbol={selectedPoolGroupSymbol}
              onSelectPoolGroupSymbol={(poolGroupSymbol) =>
                updateSelectedPoolGroupSymbol(poolGroupSymbol)
              }
              filteredSubgroupPoolsCount={filteredPoolSymbols.length}
            />
          )}

          {/* PoolCards */}
          {selectedPoolGroupSymbol === PoolGroupSymbol.MAIN &&
            // only MAIN pool group directly shows PoolCards
            POOL_GROUPS[PoolGroupSymbol.MAIN].poolSymbols.map((poolSymbol) =>
              renderPoolCards(POOLS[poolSymbol]),
            )}

          {/* AuxiliaryPoolCards */}
          {filteredPoolSymbols.map((poolSymbol) => {
            const pool = POOLS[poolSymbol]
            return (
              <AuxiliaryPoolCard key={poolSymbol} poolSymbol={poolSymbol}>
                <AuxiliaryPoolCard.Wrapper>
                  <AuxiliaryPoolCard.Title icon={pool.icon} title={pool.name} />
                  {!isTabletSm && (
                    <AuxiliaryPoolCard.ItemList>
                      {renderAuxiliaryPoolCardItems(pool)}
                    </AuxiliaryPoolCard.ItemList>
                  )}
                  <AuxiliaryPoolCard.ToggleDetailsButton />
                </AuxiliaryPoolCard.Wrapper>
                {isTabletSm && (
                  <AuxiliaryPoolCard.ItemList column>
                    {renderAuxiliaryPoolCardItems(pool)}
                  </AuxiliaryPoolCard.ItemList>
                )}
                <AuxiliaryPoolCard.Details>
                  {renderPoolCards(pool)}
                </AuxiliaryPoolCard.Details>
              </AuxiliaryPoolCard>
            )
          })}
        </PoolCardList>
        {/* pool deposit */}
        {modalState.currentModalId === ModalId.POOL_DEPOSIT_LIQUIDITY && (
          <PoolDepositModalContainer isOpen />
        )}
        {modalState.currentModalId ===
          ModalId.POOL_DEPOSIT_WAIT_FOR_CONFIRMATION && (
          <PoolDepositWaitForConfirmationModalContainer isOpen />
        )}
        {/* pool withdrawal */}
        {modalState.currentModalId === ModalId.POOL_WITHDRAW_LIQUIDITY && (
          <PoolWithdrawalModalContainer isOpen />
        )}
        {modalState.currentModalId ===
          ModalId.POOL_WITHDRAW_WAIT_FOR_CONFIRMATION && (
          <PoolWithdrawWaitForConfirmationModalContainer isOpen />
        )}
        {/* pool claim */}
        {modalState.currentModalId ===
          ModalId.POOL_CLAIM_WAIT_FOR_CONFIRMATION && (
          <PoolClaimWaitForConfirmationModalContainer isOpen />
        )}
      </Container>
    </>
  )
}

export default PoolContainer
