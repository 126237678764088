import { safeWdiv, strToWad, wmul } from '@hailstonelabs/big-number-utils'
import React, { createContext, ReactElement, useContext, useState } from 'react'
import { TpYieldSymbol, TpYieldSymbols, TP_YIELDS } from '../config/TpYield'
import { usePoller } from '../hooks/usePoller'
import {
  TpYieldAprType,
  TpYieldDataType,
  TpYieldLiquidtyUsdType,
} from '../interfaces/TpYield'
import { getTpYieldLiquidtyUSD } from '../utils/pool'
import { useBalance } from './BalanceContext'
import { useMulticallData } from './MulticallDataContext'

export type ContextType = TpYieldDataType | null

interface Props {
  children: React.ReactNode
}

export const TpYieldContext = createContext<ContextType>(null)
TpYieldContext.displayName = 'TpYieldContext'

export const useTpYield = (): ContextType => {
  return useContext(TpYieldContext)
}

export const TpYieldProvider = ({ children }: Props): ReactElement => {
  const { tpYieldData, isMulticallDataFetched } = useMulticallData()
  const { tokenPrices, isTokenPriceFetched } = useBalance()
  const [tpYieldApr, setTpYieldApr] = useState<TpYieldAprType | null>(null)
  const [tpYieldLiquidtyUSD, setTpYieldLiquidtyUSD] =
    useState<TpYieldLiquidtyUsdType | null>(null)
  let returnData: ContextType = null
  usePoller(
    () => {
      const fetchTpYieldApr = async () => {
        if (tpYieldData) {
          const {
            traderJoeTpYieldAvaxPtpData: {
              traderJoeTpYieldJoePerSecWAD,
              traderJoeTpYieldTotalAllocPointBN,
              traderJoeTpYieldAllocPointBN,
              traderJoeTpYieldPtpPerSecWAD,
              traderJoeTpYieldLpSuppliesWAD,
              traderJoeTpYieldLpStakedWAD,
            },
            tpYieldLiquidtyWAD,
          } = tpYieldData
          const tpYieldLiquidtyUSD = getTpYieldLiquidtyUSD(
            tpYieldLiquidtyWAD,
            tokenPrices,
          )
          setTpYieldLiquidtyUSD({
            ...tpYieldLiquidtyUSD,
          })
          if (traderJoeTpYieldLpStakedWAD && traderJoeTpYieldLpSuppliesWAD) {
            const pangolinApr = await TP_YIELDS[
              TpYieldSymbol.PANGOLIN_AVAX_PTP
            ].getApr()
            const traderJoeApr = await TP_YIELDS[
              TpYieldSymbol.TRADERJOE_AVAX_PTP
            ].getApr({
              poolTvlUsdWad: wmul(
                safeWdiv(
                  traderJoeTpYieldLpStakedWAD,
                  traderJoeTpYieldLpSuppliesWAD,
                ),
                strToWad(tpYieldLiquidtyUSD[TpYieldSymbol.TRADERJOE_AVAX_PTP]),
              ),
              ptpPerSecWad: traderJoeTpYieldPtpPerSecWAD,
              joePerSecWad: traderJoeTpYieldJoePerSecWAD,
              poolAllocPointBN: traderJoeTpYieldAllocPointBN,
              totalAllocPointBN: traderJoeTpYieldTotalAllocPointBN,
              ptpPrice: tokenPrices.PTP,
              joePrice: tokenPrices.JOE,
            })
            setTpYieldApr({
              [TpYieldSymbol.PANGOLIN_AVAX_PTP]: pangolinApr,
              [TpYieldSymbol.TRADERJOE_AVAX_PTP]: traderJoeApr,
            })
          }
        }
      }
      if (isMulticallDataFetched && isTokenPriceFetched) {
        void fetchTpYieldApr()
      }
    },
    [isMulticallDataFetched, isTokenPriceFetched],
    120000, // 2 minutes
  )
  if (tpYieldApr && tpYieldData && tpYieldLiquidtyUSD) {
    returnData = {} as TpYieldDataType
    for (let index = 0; index < TpYieldSymbols.length; index++) {
      const tpYieldId = TpYieldSymbols[index]
      returnData[tpYieldId] = {
        apr: tpYieldApr[tpYieldId],
        liquidtyUsd: tpYieldLiquidtyUSD[tpYieldId],
      }
    }
  }
  return (
    <TpYieldContext.Provider value={returnData}>
      {children}
    </TpYieldContext.Provider>
  )
}
