import { strToWad } from '@hailstonelabs/big-number-utils'
import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { ActionId } from '../../config/action'
import { vePtp } from '../../config/contracts'
import { VEPTP_V3_ABI } from '../../config/contracts/wagmiAbis/VePtpV3'
import { useModal } from '../../contexts/ModalContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useVePtp } from '../../contexts/VePtpContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'
import { isUserDeniedTransaction } from '../../utils/contract'
import useRevertReason from '../useRevertReason'
type Props = {
  handleUnstakePtp: () => Promise<void>
}
const useUnstakePtp = (): Props => {
  const { chainId } = useWeb3()
  const getRevertReason = useRevertReason()
  const { showMessage } = useSnackbar()
  const {
    input,
    actions: { clearUnstakePtpInputAmount },
    nft,
  } = useVePtp()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const handleUnstakePtp = async () => {
    let writeContractResult: WriteContractResult | undefined

    if (input.unstakePtpAmount === '') return
    try {
      const vePtpAddress = vePtp.getAddress(chainId)
      if (vePtpAddress) {
        modalDispatch(openModal(ModalId.UNSTAKE_WAIT_FOR_CONFIRMATION))

        const config = await prepareWriteContract({
          address: vePtpAddress,
          abi: VEPTP_V3_ABI,
          functionName: 'withdraw',
          args: [strToWad(input.unstakePtpAmount)],
          chainId,
        })
        writeContractResult = await writeContract(config)
        if (writeContractResult) {
          const { hash, wait } = writeContractResult
          await wait()
          modalDispatch(
            openModal(ModalId.TRANSACTION_SUBMITTED, {
              transactionHashes: [hash],
            }),
          )
          showMessage('Successfully unstaked PTP.')
          // update equipped id to null when users unstake all staked ptp
          if (input.isUnstakedAllPtpWithEquippedNft) {
            nft.setEquippedId(null)
          }
        } else {
          showMessage('Transaction failed.', 'warning')
          modalDispatch(openModal(ModalId.UNSET))
        }
      }
    } catch (err) {
      if (!isUserDeniedTransaction(err)) {
        Sentry.setContext('contract_call', {
          name: 'withdraw_PTP',
        })
        Sentry.captureException(err)
      }
      const reason = await getRevertReason(err, undefined, ActionId.UNSTAKE_PTP)
      showMessage(reason || 'Transaction failed.', 'warning')
      modalDispatch(openModal(ModalId.UNSET))
    }
    clearUnstakePtpInputAmount()
  }
  return { handleUnstakePtp }
}

export default useUnstakePtp
