import {
  getCommifiedFormat,
  getMillifiedFormat,
} from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import { BigNumber } from 'ethers'
import React, { ReactElement } from 'react'
import { PoolSymbol } from '../../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { PoolStakeStatusBar } from '../../../containers/PoolStakeLPContainers'
import { StatusType } from '../../../containers/PoolStakeLPContainers/PoolStakeStatusBar/PoolStakeStatusBar'
import { usePoolInput } from '../../../contexts/PoolInputContext'
import useBreakpoints from '../../../hooks/useBreakpoints'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import AppButton from '../../buttons/AppButton/AppButton'
import InfoBox from '../../InfoBox/InfoBox'
import TooltipNum from '../../InfoBox/TooltipNum'
import TokenIcon from '../../TokenIcon/TokenIcon'
import {
  Container,
  FixedWidthBox,
  Info,
  InfoButtonList,
  InfoItem,
  InfoItemList,
  InfoText,
  InfoToken,
} from './PoolCard.elements'
interface Props {
  children: React.ReactNode
  hasDeposit?: boolean
  poolStakeStatus?: { [key in StatusType]?: boolean }
  poolSymbol: PoolSymbol
  assetTokenSymbol: TokenSymbol
}

function PoolCard({
  hasDeposit,
  children,
  poolStakeStatus,
  poolSymbol,
  assetTokenSymbol,
}: Props): ReactElement {
  const {
    actions: { updateSelectedPoolSymbol, updateSelectedAssetTokenSymbol },
    selectedPoolSymbol,
    selectedAssetTokenSymbol,
  } = usePoolInput()

  return (
    <Container
      hasDeposit={hasDeposit}
      onClick={() => {
        if (selectedPoolSymbol !== poolSymbol) {
          updateSelectedPoolSymbol(poolSymbol)
        }
        if (selectedAssetTokenSymbol !== assetTokenSymbol) {
          updateSelectedAssetTokenSymbol(assetTokenSymbol)
        }
      }}
    >
      <PoolStakeStatusBar>
        {poolStakeStatus?.['Please Stake'] && (
          <PoolStakeStatusBar.Status status="Please Stake" />
        )}
        {poolStakeStatus?.['Earning'] && (
          <PoolStakeStatusBar.Status status="Earning" />
        )}
        {poolStakeStatus?.['Booster+'] && (
          <PoolStakeStatusBar.Status status="Booster+" />
        )}
      </PoolStakeStatusBar>
      {children}
    </Container>
  )
}

export default PoolCard

type CardInfoDataType = {
  amount: string | BigNumber
  isDashShown?: boolean
  toolTipMessage?: string | ReactElement
}
type CardInfoData = {
  coverageRatio?: CardInfoDataType
  poolDepositFiat?: CardInfoDataType
  poolDeposit?: CardInfoDataType
  volumeOfTokensIn24hrFiat?: CardInfoDataType
  volumeOfTokensIn24hr?: CardInfoDataType
  myDepositsFiat?: CardInfoDataType
  myDeposits?: CardInfoDataType
}

type ButtonPropsType = {
  onClick: () => void
  disabled?: boolean
}
interface PoolCardInfoProps {
  tokenSymbol: TokenSymbol
  data: CardInfoData
  depositButtonProps?: ButtonPropsType
  withdrawButtonProps?: ButtonPropsType
  customButtons?: ReactElement[]
}
function PoolCardInfo({
  tokenSymbol,
  data: {
    coverageRatio,
    poolDepositFiat,
    poolDeposit,
    // volumeOfTokensIn24hrFiat,
    // volumeOfTokensIn24hr,
    myDepositsFiat,
    myDeposits,
  },
  depositButtonProps,
  withdrawButtonProps,
  customButtons,
}: PoolCardInfoProps): ReactElement {
  const { isMobileLg } = useBreakpoints()
  return (
    <Info>
      <InfoItem>
        {/* Token symbol and icon */}
        <InfoToken>
          <FixedWidthBox>
            <TokenIcon tokenSymbol={tokenSymbol} />
          </FixedWidthBox>
          <InfoText variant={isMobileLg ? 'body2' : 'h6'}>
            {tokenSymbol}
          </InfoText>
        </InfoToken>
        {/* Coverage Ratio */}
        {coverageRatio && (
          <Box display="flex" flex-direction="row" alignItems="center">
            <FixedWidthBox>
              <InfoText variant="caption3" $light>
                Coverage Ratio
              </InfoText>
            </FixedWidthBox>
            <InfoBox padding="0">
              <InfoBox.Item>
                <InfoText variant="body2">
                  {showDashIfNecessary(
                    coverageRatio.isDashShown,
                    getCommifiedFormat(coverageRatio.amount),
                  )}
                  %
                </InfoText>
                {coverageRatio.toolTipMessage && (
                  <InfoBox.Tooltip text={coverageRatio.toolTipMessage} />
                )}
              </InfoBox.Item>
            </InfoBox>
          </Box>
        )}
      </InfoItem>
      <InfoItemList>
        {/* Pool Deposits */}
        <InfoItem>
          {poolDepositFiat && (
            <>
              <InfoText $light>Pool Deposits</InfoText>
              <InfoText variant="subtitle2">
                <TooltipNum
                  leftSymbol="$"
                  amount={poolDepositFiat.amount}
                  format="commified"
                >
                  ${' '}
                  {showDashIfNecessary(
                    poolDepositFiat.isDashShown,
                    getMillifiedFormat(poolDepositFiat.amount),
                  )}
                </TooltipNum>
              </InfoText>
            </>
          )}
          {poolDeposit && (
            <>
              <InfoText $light>
                <TooltipNum
                  rightSymbol={tokenSymbol}
                  amount={poolDeposit.amount}
                >
                  {showDashIfNecessary(
                    poolDeposit.isDashShown,
                    getMillifiedFormat(poolDeposit.amount),
                  )}{' '}
                  {tokenSymbol}
                </TooltipNum>
              </InfoText>
            </>
          )}
        </InfoItem>
        {/* Volume (24H) */}
        {/* <InfoItem>
          {volumeOfTokensIn24hrFiat && (
            <>
              <InfoText $light>Volume (24H)</InfoText>
              <InfoText variant="subtitle2">
                <TooltipNum
                  leftSymbol="$"
                  amount={volumeOfTokensIn24hrFiat.amount}
                  format="commified"
                >
                  ${' '}
                  {showDashIfNecessary(
                    volumeOfTokensIn24hrFiat.isDashShown,
                    getMillifiedFormat(volumeOfTokensIn24hrFiat.amount),
                  )}
                </TooltipNum>
              </InfoText>
            </>
          )}
          {volumeOfTokensIn24hr && (
            <>
              <InfoText $light>
                <TooltipNum
                  rightSymbol={tokenSymbol}
                  amount={volumeOfTokensIn24hr.amount}
                >
                  {showDashIfNecessary(
                    volumeOfTokensIn24hr.isDashShown,
                    getMillifiedFormat(volumeOfTokensIn24hr.amount),
                  )}{' '}
                  {tokenSymbol}
                </TooltipNum>
              </InfoText>
            </>
          )}
        </InfoItem> */}
        {/* My Deposits */}
        <InfoItem>
          {myDepositsFiat && (
            <>
              <InfoText $light>My Deposits</InfoText>
              <InfoText variant="subtitle2">
                <TooltipNum
                  amount={myDepositsFiat.amount}
                  leftSymbol={'$'}
                  format="commified"
                >
                  $&nbsp;
                  {showDashIfNecessary(
                    myDepositsFiat.isDashShown,
                    getCommifiedFormat(myDepositsFiat.amount),
                  )}
                </TooltipNum>
              </InfoText>
            </>
          )}
          {myDeposits && (
            <>
              <InfoText $light>
                <TooltipNum
                  rightSymbol={tokenSymbol}
                  amount={myDeposits.amount}
                >
                  {showDashIfNecessary(
                    myDeposits.isDashShown,
                    getCommifiedFormat(myDeposits.amount),
                  )}{' '}
                  {tokenSymbol}
                </TooltipNum>
              </InfoText>
            </>
          )}
        </InfoItem>
      </InfoItemList>
      <InfoButtonList>
        {depositButtonProps && (
          <AppButton customVariant="neutral" {...depositButtonProps}>
            Deposit
          </AppButton>
        )}
        {withdrawButtonProps && (
          <AppButton customVariant="neutral" {...withdrawButtonProps}>
            Withdraw
          </AppButton>
        )}
        {customButtons}
      </InfoButtonList>
    </Info>
  )
}

PoolCard.CardInfo = React.memo(PoolCardInfo)
