import { Snackbar } from '@mui/material'
import styled from 'styled-components'

export const StyledSnackbar = styled(Snackbar)`
  &.MuiSnackbar-anchorOriginTopRight {
    /* from MainLayout */
    top: var(--header-offset-height);
  }
  .MuiSnackbarContent-root {
    padding: 0;
    min-width: 0px;
    flex-grow: unset;
  }
  .MuiSnackbarContent-message {
    padding: 0;
  }
`
