import {
  getCommifiedFormat,
  getDynamicFormat,
} from '@hailstonelabs/big-number-utils'
import React, { ReactElement } from 'react'
import { useVePtp } from '../../../contexts/VePtpContext'
import TooltipNum from '../../InfoBox/TooltipNum'
import {
  Container,
  DetailsContainer,
  EarningProgress,
  InfoContainer,
  MaxVePtpToolTip,
  SmallTextContainer,
  TotalPtpToolTip,
  VePtpContainer,
  VePtpIcon,
  VePtpOuterContainer,
  VePtpText,
  VePtpTotalText,
} from './VePtpSummary.elements'

function PtpSummary(): ReactElement {
  const { vePtp } = useVePtp()
  return (
    <Container>
      <DetailsContainer>
        <InfoContainer>
          <VePtpIcon />
          <VePtpOuterContainer>
            <EarningProgress
              variant="determinate"
              value={vePtp.balancePercentage}
            />
            <VePtpContainer>
              <VePtpTotalText>
                <TooltipNum
                  amount={vePtp.balance.total.current}
                  rightSymbol="vePTP"
                >
                  {getCommifiedFormat(vePtp.balance.total.current)}
                </TooltipNum>
              </VePtpTotalText>
              <VePtpText>vePTP</VePtpText>
            </VePtpContainer>
          </VePtpOuterContainer>
        </InfoContainer>
        <SmallTextContainer>
          <TotalPtpToolTip
            content={'Total vePTP supply:'}
            tooltipText={'Total vePTP balance of all users'}
          >
            <TooltipNum amount={vePtp.totalSupply}>
              &nbsp;{getDynamicFormat(vePtp.totalSupply)}
            </TooltipNum>
          </TotalPtpToolTip>

          <MaxVePtpToolTip
            content={'Max vePTP to Earn:'}
            tooltipText={`Max vePTP you can earn for staking is ${vePtp.maxCapPerPtp.staking} times of your staked PTP (without NFT ability).`}
          >
            <TooltipNum amount={vePtp.maxCap.total.current}>
              &nbsp;{getCommifiedFormat(vePtp.maxCap.staking.current)}
            </TooltipNum>
          </MaxVePtpToolTip>
        </SmallTextContainer>
      </DetailsContainer>
    </Container>
  )
}

export default PtpSummary
