import { ChainId } from '../../networks'
import { FetchPriceMethod, Token } from './Token'
import { SwapGroupSymbol, TokenSymbol } from './tokenSymbol'

export const TOKENS: {
  [id in TokenSymbol]: Token
} = {
  [TokenSymbol.MIMATIC]: new Token(
    'Mimatic',
    TokenSymbol.MIMATIC,
    {
      [ChainId.AVALANCHE]: '0x3B55E45fD6bd7d4724F5c47E0d1bCaEdd059263e',
      [ChainId.FUJI]: '0xA4F4032fa93E48a53fCE108608E4aFea81aC4f2a',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'mimatic',
        cmcId: 10238,
      },
    },
  ),
  // https://dforce.network/
  [TokenSymbol.USX]: new Token(
    'dForce USD',
    TokenSymbol.USX,
    {
      [ChainId.AVALANCHE]: '0x853ea32391AaA14c112C645FD20BA389aB25C5e0',
      [ChainId.FUJI]: '0xC1AF0511a95b1A9242316c568Bc585f2fbC60F52',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,

    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'token-dforce-usd',
        cmcId: 13080,
      },
    },
  ),
  [TokenSymbol.MORE]: new Token(
    'More Token',
    TokenSymbol.MORE,
    {
      [ChainId.AVALANCHE]: '0xd9D90f882CDdD6063959A9d837B05Cb748718A05',
      [ChainId.FUJI]: '0x2cDE4574B7FCcBD20bBFB3B9F036145978328640',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'more-token',
        cmcId: 17733,
      },
    },
  ),
  [TokenSymbol.MONEY]: new Token(
    'Moremoney USD',
    TokenSymbol.MONEY,
    {
      [ChainId.AVALANCHE]: '0x0f577433bf59560ef2a79c124e9ff99fca258948',
      [ChainId.FUJI]: '0xCD45a0C8D9B3B10c98183979AcD0e4dB333766D5',
    },
    18,
    SwapGroupSymbol.USD,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'moremoney-usd',
        cmcId: 18758,
      },
    },
  ),
  [TokenSymbol.TSD]: new Token(
    'Teddy Dollar',
    TokenSymbol.TSD,
    {
      [ChainId.AVALANCHE]: '0x4fbf0429599460d327bd5f55625e30e4fc066095',
      [ChainId.FUJI]: '0xfB8E6F182e16a32b7217C92C242eE5CC45Ef1b91',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'teddy-dollar',
        //TSD delisted for cmc
        cmcId: 0,
      },
    },
  ),
  [TokenSymbol.H2O]: new Token(
    'Defrost Finance H2O',
    TokenSymbol.H2O,
    {
      [ChainId.AVALANCHE]: '0x026187BdbC6b751003517bcb30Ac7817D5B766f8',
      [ChainId.FUJI]: '0x44fAB36c66e3487c6176979D9049324233760f0a',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'defrost-finance-h2o',
        cmcId: 15690,
      },
    },
  ),
  [TokenSymbol.TUSD]: new Token(
    'TrueUSD',
    TokenSymbol.TUSD,
    {
      [ChainId.AVALANCHE]: '0x1C20E891Bab6b1727d14Da358FAe2984Ed9B59EB',
      [ChainId.FUJI]: '0x43228dC94d4302534256d032Ac645c5509C25dC0',
    },
    18,
    /** @todo change to USD after listing */
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'true-usd',
        cmcId: 2563,
      },
    },
  ),
  [TokenSymbol.YUSD]: new Token(
    'YUSD Stablecoin',
    TokenSymbol.YUSD,
    {
      [ChainId.AVALANCHE]: '0x111111111111ed1D73f860F57b2798b683f2d325',
      [ChainId.FUJI]: '0x5Ac4634bBfb0956974c1f5E8e5187cfa4f632a9C',
    },
    18,
    SwapGroupSymbol.USD,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'yusd-stablecoin',
        cmcId: 19577,
      },
    },
  ),
  [TokenSymbol.TEDDY]: new Token(
    'Teddy',
    TokenSymbol.TEDDY,
    {
      [ChainId.AVALANCHE]: '0x094bd7B2D99711A1486FB94d4395801C6d0fdDcC',
      [ChainId.FUJI]: '0x447AAc4f033ae5f7B5a6C04726fB07E67Ef7F682',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'teddy',
        cmcId: 11893,
      },
    },
  ),
  [TokenSymbol.MELT]: new Token(
    'Defrost Finance Token',
    TokenSymbol.MELT,
    {
      [ChainId.AVALANCHE]: '0x47EB6F7525C1aA999FBC9ee92715F5231eB1241D',
      [ChainId.FUJI]: '0xe40EB399a7c501A30B165cf2e3505791C6a7Ea64',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'defrost-finance',
        cmcId: 15191,
      },
    },
  ),
  [TokenSymbol.ANKR]: new Token(
    'Ankr Network',
    TokenSymbol.ANKR,
    {
      [ChainId.AVALANCHE]: '0x20CF1b6E9d856321ed4686877CF4538F2C84B4dE',
      [ChainId.FUJI]: '0x44aaE9A6F472d6dB76AD9Dc9c1034A82c1158f84',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'ankr',
        cmcId: 3783,
      },
    },
  ),
  [TokenSymbol.YETI]: new Token(
    'Yeti Finance',
    TokenSymbol.YETI,
    {
      [ChainId.AVALANCHE]: '0x77777777777d4554c39223c354a05825b2e8faa3',
      [ChainId.FUJI]: '0x2183FBAB7fBB191942F69e85C3d8d3004679190d',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'yeti-finance',
        cmcId: 19576,
      },
    },
  ),
  [TokenSymbol.FRAX]: new Token(
    'Frax Stablecoin',
    TokenSymbol.FRAX,
    {
      [ChainId.AVALANCHE]: '0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64',
      [ChainId.FUJI]: '0xd428631E403ca0f3767f5F7bce41215264aCc36b',
    },
    18,
    SwapGroupSymbol.USD,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'frax',
        cmcId: 6952,
      },
    },
  ),
  [TokenSymbol.UST]: new Token(
    'Terra USD',
    TokenSymbol.UST,
    {
      [ChainId.AVALANCHE]: '0xb599c3590F42f8F995ECfa0f85D2980B76862fc1',
      [ChainId.FUJI]: '0x73B2c222AA1cba39c2844ad1f30E51e608C94868',
    },
    6,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'terrausd',
        cmcId: 7129,
      },
    },
  ),
  [TokenSymbol.MIM]: new Token(
    'Magic Internet Money',
    TokenSymbol.MIM,
    {
      [ChainId.AVALANCHE]: '0x130966628846bfd36ff31a822705796e8cb8c18d',
      [ChainId.FUJI]: '0x2788a9440de50007601571c2cBe036fbB798c49F',
    },
    18,
    SwapGroupSymbol.USD,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'magic-internet-money',
        cmcId: 162,
      },
    },
  ),
  [TokenSymbol.DAIe]: new Token(
    'Dai Stablecoin',
    TokenSymbol.DAIe,
    {
      [ChainId.AVALANCHE]: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
      [ChainId.FUJI]: '0x10072173E62E8c1f87DAB8d42bBF78Db69395101',
    },
    18,
    SwapGroupSymbol.USD,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'dai',
        cmcId: 4943,
      },
    },
  ),
  [TokenSymbol.USDCe]: new Token(
    'USD Coin',
    TokenSymbol.USDCe,
    {
      [ChainId.AVALANCHE]: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
      [ChainId.FUJI]: '0x24D1b13C3Da4D2DBC7c5F2DB7CA729FD7CcF73e6',
    },
    6,
    SwapGroupSymbol.USD,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'usd-coin',
        cmcId: 18852,
      },
    },
  ),
  [TokenSymbol.USDC]: new Token(
    'USD Coin',
    TokenSymbol.USDC,
    {
      [ChainId.AVALANCHE]: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
      [ChainId.FUJI]: '0x51e66C5a82d6e24E85f658Ec69F68B724Fd015D7',
    },
    6,
    SwapGroupSymbol.USD,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'usd-coin',
        cmcId: 3408,
      },
    },
  ),
  [TokenSymbol.USDTe]: new Token(
    'Tether USD',
    TokenSymbol.USDTe,
    {
      [ChainId.AVALANCHE]: '0xc7198437980c041c805a1edcba50c1ce5db95118',
      [ChainId.FUJI]: '0x59E2Ce9E18c9ce36e07f29C3F156EEee43c0956b',
    },
    6,
    SwapGroupSymbol.USD,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'tether',
        cmcId: 21763,
      },
    },
  ),
  [TokenSymbol.USDT]: new Token(
    'Tether USD',
    TokenSymbol.USDT,
    {
      [ChainId.AVALANCHE]: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
      [ChainId.FUJI]: '0xd12e0B43b9315316Ae2552864C1284A9ffE6dAC9',
    },
    6,
    SwapGroupSymbol.USD,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'tether',
        cmcId: 825,
      },
    },
  ),
  [TokenSymbol.PTP]: new Token(
    'PTP',
    TokenSymbol.PTP,
    {
      [ChainId.AVALANCHE]: '0x22d4002028f537599bE9f666d1c4Fa138522f9c8',
      [ChainId.FUJI]: '0xa9277506C2B8e31C9668f48E3b7C7A42Ca722Fc7',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'platypus-finance',
        cmcId: 16231,
      },
    },
  ),
  // cannot execute veptp.get
  [TokenSymbol.VEPTP]: new Token(
    'vePTP',
    TokenSymbol.VEPTP,
    {
      [ChainId.AVALANCHE]: '0x5857019c749147EEE22b1Fe63500F237F3c1B692',
      [ChainId.FUJI]: '0xC7Fd834cBDDAb9A7dB8a0Ae3eD883b7633807303',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'Voting Escrow PTP',
        cmcId: 0,
      },
    },
  ),
  [TokenSymbol.QI]: new Token(
    'BENQI',
    TokenSymbol.QI,
    {
      [ChainId.AVALANCHE]: '0x8729438eb15e2c8b576fcc6aecda6a148776c0f5',
      [ChainId.FUJI]: '0x4fDbC5aAd522D3C38Cd5bEf17Fd9E0Af714A7ac2',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'benqi',
        cmcId: 9288,
      },
    },
  ),
  [TokenSymbol.AVAX]: new Token(
    'AVAX',
    TokenSymbol.AVAX,
    {
      [ChainId.AVALANCHE]: undefined,
      [ChainId.FUJI]: undefined,
    },
    18,
    SwapGroupSymbol.AVAX,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'avalanche-2',
        cmcId: 5805,
      },
    },
  ),
  [TokenSymbol.ANKRAVAX]: new Token(
    'Ankr Staked AVAX',
    TokenSymbol.ANKRAVAX,
    {
      [ChainId.AVALANCHE]: '0xc3344870d52688874b06d844E0C36cc39FC727F6',
      [ChainId.FUJI]: '0x836Cb891A05e69C8ED3Ec32f6E84E51995A398C7',
    },
    18,
    SwapGroupSymbol.AVAX,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'ankr-staked-avax',
        /** @todo add cmcId may be 20666  */
        cmcId: 20666,
      },
    },
  ),
  [TokenSymbol.YYAVAX]: new Token(
    'YieldYak gAVAX',
    TokenSymbol.YYAVAX,
    {
      [ChainId.AVALANCHE]: '0xF7D9281e8e363584973F946201b82ba72C965D27',
      [ChainId.FUJI]: '0x9aab16F45706b22c5465a5F500e02Ab9468033D4',
    },
    18,
    SwapGroupSymbol.AVAX,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'yield-yak-avax',
        cmcId: 21775,
      },
    },
  ),
  [TokenSymbol.SAVAX]: new Token(
    'BENQI Liquid Staked AVAX',
    TokenSymbol.SAVAX,
    {
      [ChainId.AVALANCHE]: '0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE',
      [ChainId.FUJI]: '0xC667d5542F0b9Bff33A609dCB1c56275f7fbA475',
    },
    18,
    SwapGroupSymbol.AVAX,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'benqi-liquid-staked-avax',
        cmcId: 18523,
      },
    },
  ),
  [TokenSymbol.WAVAX]: new Token(
    'Wrapped AVAX',
    TokenSymbol.WAVAX,
    {
      [ChainId.AVALANCHE]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
      [ChainId.FUJI]: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
    },
    18,
    SwapGroupSymbol.AVAX,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'wrapped-avax',
        cmcId: 9462,
      },
    },
  ),
  [TokenSymbol.JOE]: new Token(
    'JOE',
    TokenSymbol.JOE,
    {
      [ChainId.AVALANCHE]: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
      [ChainId.FUJI]: undefined,
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'joe',
        cmcId: 11396,
      },
    },
  ),
  [TokenSymbol.BTCb]: new Token(
    'Bitcoin',
    TokenSymbol.BTCb,
    {
      [ChainId.AVALANCHE]: '0x152b9d0FdC40C096757F570A51E494bd4b943E50',
      [ChainId.FUJI]: '0x431af5299c661E5c404E0799D899AE6f544DBB5A',
    },
    8,
    SwapGroupSymbol.BTC,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        /** @todo may change coingecko id */
        geckoId: 'bitcoin',
        cmcId: 20721,
      },
    },
  ),
  [TokenSymbol.WBTCe]: new Token(
    'Wrapped BTC',
    TokenSymbol.WBTCe,
    {
      [ChainId.AVALANCHE]: '0x50b7545627a5162F82A992c33b87aDc75187B218',
      [ChainId.FUJI]: '0x36db5D63CBCf83d997779F2DC8A9dB63937255aD',
    },
    8,
    SwapGroupSymbol.BTC,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'wrapped-bitcoin',
        cmcId: 3717,
      },
    },
  ),
  [TokenSymbol.BUSD]: new Token(
    'Binance USD',
    TokenSymbol.BUSD,
    {
      [ChainId.AVALANCHE]: '0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39',
      [ChainId.FUJI]: '0x93a70590783E4BcD88601aBC5F2083efc073e6E6',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'binance-usd',
        cmcId: 4687,
      },
    },
  ),
  [TokenSymbol.AXLUSDC]: new Token(
    'Axelar USDC',
    TokenSymbol.AXLUSDC,
    {
      [ChainId.AVALANCHE]: '0xfaB550568C688d5D8A52C7d794cb93Edc26eC0eC',
      [ChainId.FUJI]: '0x2010197Cc7951C8dA1A2Bf3517114DE34838e9B6',
    },
    6,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'axlusdc',
        cmcId: 21420,
      },
    },
  ),
  [TokenSymbol.HUM]: new Token(
    'Hummus',
    TokenSymbol.HUM,
    {
      [ChainId.AVALANCHE]: undefined,
      [ChainId.FUJI]: undefined,
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'hummus',
        cmcId: 19625,
      },
    },
  ),
  [TokenSymbol.USP]: new Token(
    'USP',
    TokenSymbol.USP,
    {
      [ChainId.AVALANCHE]: '0xdaCDe03d7Ab4D81fEDdc3a20fAA89aBAc9072CE2',
      [ChainId.FUJI]: '0x2E12576A13d610508A320aeCe98c7fC9eeF17A67',
    },
    18,
    SwapGroupSymbol.UNAVAILABLE,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'platypus-usd',
        cmcId: 23423,
      },
    },
  ),
  [TokenSymbol.ANKRETH]: new Token(
    'Ankr Staked ETH',
    TokenSymbol.ANKRETH,
    {
      [ChainId.AVALANCHE]: '0x12d8ce035c5de3ce39b1fdd4c1d5a745eaba3b8c',
      [ChainId.FUJI]: '0xedb5Cd10b9F36d9AB72b1c06E3c9dEA3214F32f7',
    },
    18,
    SwapGroupSymbol.ETH,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'ankreth',
        cmcId: 8100,
      },
    },
  ),
  [TokenSymbol.WETHe]: new Token(
    'WETH',
    TokenSymbol.WETHe,
    {
      [ChainId.AVALANCHE]: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
      [ChainId.FUJI]: '0x387f2B64D1F7c28b920ae45675b0c7900417dfE8',
    },
    18,
    SwapGroupSymbol.ETH,
    {
      method: FetchPriceMethod.COINGECKO_COINMARKETCAP,
      payload: {
        geckoId: 'weth',
        cmcId: 2396,
      },
    },
  ),
}

// TOKEN_SYMBOLS_FOR_DATA_FETCHING -> Using for fetch Price, Balance, Appovals, assets and liability
/** @TODO split data required to Price required, balance required, Appovals required...  */
export const TOKEN_SYMBOLS_FOR_DATA_FETCHING = [
  TokenSymbol.USDTe,
  TokenSymbol.USDCe,
  TokenSymbol.DAIe,
  TokenSymbol.USDC,
  TokenSymbol.USDT,
  TokenSymbol.PTP,
  TokenSymbol.MIM,
  TokenSymbol.AVAX,
  TokenSymbol.WAVAX,
  TokenSymbol.JOE,
  TokenSymbol.FRAX,
  TokenSymbol.UST,
  TokenSymbol.SAVAX,
  TokenSymbol.QI,
  TokenSymbol.YETI,
  TokenSymbol.TEDDY,
  TokenSymbol.MELT,
  TokenSymbol.YUSD,
  TokenSymbol.MONEY,
  TokenSymbol.TSD,
  TokenSymbol.H2O,
  TokenSymbol.MORE,
  TokenSymbol.BTCb,
  TokenSymbol.WBTCe,
  TokenSymbol.TUSD,
  TokenSymbol.MIMATIC,
  TokenSymbol.USX,
  TokenSymbol.YYAVAX,
  TokenSymbol.BUSD,
  TokenSymbol.HUM,
  TokenSymbol.USP,
  TokenSymbol.AXLUSDC,
  TokenSymbol.ANKRAVAX,
  TokenSymbol.ANKR,
  TokenSymbol.ANKRETH,
  TokenSymbol.WETHe,
]

/**
 * Token symbols which are ready to swap
 */
export const SWAP_AVAILABLE_TOKEN_SYMBOLS = Object.values(
  SwapGroupSymbol,
).reduce((prev, swapGroupSymbol) => {
  if (swapGroupSymbol !== SwapGroupSymbol.UNAVAILABLE) {
    const sortedTokenSymbols = Object.values(TOKENS)
      .filter((token) => token.swapGroupSymbol === swapGroupSymbol)
      .map((token) => token.symbol)
      .sort()
    return [...prev, ...sortedTokenSymbols]
  }
  return [...prev]
}, [] as TokenSymbol[])

export const mapAddressToTokenSymbol = Object.values(ChainId).reduce(
  (prev1, chainId) => ({
    ...prev1,
    [chainId]: Object.values(TOKENS).reduce(
      (prev2, token) => ({
        ...prev2,
        [(token.address[chainId as ChainId] || '').toLocaleLowerCase()]:
          token.symbol,
      }),
      {} as {
        // id is token address in lowercase
        [id in string]: TokenSymbol
      },
    ),
  }),
  {} as {
    [id in ChainId]: {
      // id is token address in lowercase
      [id in string]: TokenSymbol
    }
  },
)

export const NATIVE_CHAIN_TOKEN = {
  [ChainId.AVALANCHE]: TokenSymbol.AVAX,
  [ChainId.FUJI]: TokenSymbol.AVAX,
}
