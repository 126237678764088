import { useEffect, useState } from 'react'
import { PLATOPIA_DESKTOP_MAX_WIDTH } from '../config/theme'

interface WindowDimension {
  width: number
  height: number
}

const getWindowDimensions = (): WindowDimension => ({
  width: window?.innerWidth || PLATOPIA_DESKTOP_MAX_WIDTH,
  height: window?.innerHeight || PLATOPIA_DESKTOP_MAX_WIDTH,
})

export default (): WindowDimension => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimension>(
    getWindowDimensions(),
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
