import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import Message from '../../../components/Message/Message'
import Modal from '../../../components/Modal/Modal'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import DisabledAppTokenInput from '../../../components/TokenInput/DisabledAppTokenInput'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { ScrollableBox } from '../../../globalStyles'
import { ModalId } from '../../../interfaces/Modal'
import UnstakeOrUnlockModalInfoBox from '../../VePtpModalContainers/UnstakeModalContainer/UnstakeOrUnlockModalInfoBox'

interface ModalProps {
  isOpen: boolean
}

function UnlockPtpModalContainer({ isOpen }: ModalProps) {
  const [showMessage, setShowMessage] = useState(true)
  const {
    modalDispatch,
    actions: { closeModal, openModal },
  } = useModal()

  const { vePtp, ptp } = useVePtp()

  const handleModalClose = () => {
    modalDispatch(closeModal())
  }

  const handleClickUnlock = () => {
    modalDispatch(openModal(ModalId.CONFIRM_UNLOCK_PTP))
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      topBarLabel={
        <>
          Confirm Unlock
          <TokenIcon margin="0 4px 0 8px" tokenSymbol={TokenSymbol.PTP} />
          PTP
        </>
      }
      width="650px"
      disableCloseBtn
    >
      <ScrollableBox>
        <DisabledAppTokenInput
          tokenItems={[
            {
              value: ptp.amount.locked,
              tokenSymbol: TokenSymbol.PTP,
            },
          ]}
        />
        {showMessage && (
          <Message
            style={{ marginBottom: 0 }}
            onClose={() => setShowMessage(false)}
            message={`You will lose all of your earned vePTP (${getCommifiedFormat(
              vePtp.balance.locking,
              2,
            )} vePTP), after unlocking PTP. `}
          />
        )}
        <UnstakeOrUnlockModalInfoBox />
      </ScrollableBox>
      <Box display="flex" flexDirection="row" mt={1}>
        <AppButton onClick={handleModalClose} fullWidth customVariant="neutral">
          Cancel
        </AppButton>
        <AppButton onClick={handleClickUnlock} fullWidth>
          Unlock
        </AppButton>
      </Box>
    </Modal>
  )
}

export default UnlockPtpModalContainer
