import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppTypography from '../../../components/AppTypography/AppTypography'

export const NftCardWrapperTitle = styled(AppTypography)`
  ${({ theme }) => css`
    margin-bottom: 16px;
    color: ${theme.palette.accents.yellow};
    text-align: center;
  `}
`
export const NftCardWrapper = styled(Box)`
  ${({ theme }) => css`
    background: ${alpha(theme.palette.accents.yellow, 0.1)};
    padding: 12px;
    border: 1px solid ${theme.palette.accents.yellow};
    border-radius: 10px;
  `}
`
