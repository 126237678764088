import { Box, Tooltip } from '@mui/material'
import styled, { css } from 'styled-components'

export const Container = styled(Box)<{ pad?: string; padBottom?: string }>`
  padding: ${(props) =>
    props.pad
      ? props.pad
      : `8px 0 ${props.padBottom ? props.padBottom : '16px'}`};
`
interface ItemProps {
  $fullWidth?: boolean
}
export const Item = styled(Box)<ItemProps>`
  ${({ $fullWidth }: ItemProps) => css`
    ${$fullWidth &&
    css`
      justify-content: flex-start;
      width: 100%;
    `}
  `}
`

interface StyledToolTipProps {
  iconsize?: string
}
export const StyledToolTip = styled(Tooltip)<StyledToolTipProps>`
  ${({ iconsize }: StyledToolTipProps) => css`
    &&.MuiSvgIcon-root {
      font-size: ${iconsize};
      margin: 0 5px;
    }
  `}
`

export const NonSelectedSpan = styled.span`
  all: unset;
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`
