import { strToWad } from '@hailstonelabs/big-number-utils'
import { BigNumber, constants, utils } from 'ethers'
import PangolinIcon from '../assets/icons/pangolin.svg'
import {
  PTP_AVAX_PANGOLIN_APR_API,
  TRADER_JOE_EXCHANGE_SUBGRAPH_API,
} from '../constants'
import {
  GetAprProps,
  tpYieldUrls,
  TraderJoeExcahngeSubgraphRes,
} from '../interfaces/TpYield'
import {
  getEmissionAprPercent,
  getHaircutFeeAprPercent,
} from '../utils/aprCalculation'
import { safeFetchJson } from '../utils/common'
import { TOKENS } from './contracts'
import { TokenSymbol } from './contracts/token/tokenSymbol'

// TP means Thrid party etc. TraderJoe or Pangolin
export enum TpYieldSymbol {
  TRADERJOE_AVAX_PTP = 'TRADERJOE_AVAX_PTP',
  PANGOLIN_AVAX_PTP = 'PANGOLIN_AVAX_PTP',
}
export const TpYieldSymbols = Object.values(TpYieldSymbol)
/**
 * TpYield shows protocolTokenSymbol (USP/PTP) yield framing in thrid party platforms etc. TraderJoe or Pangolin
 */
export class TpYield {
  readonly symbol: TpYieldSymbol
  readonly protocolTokenSymbol: TokenSymbol
  readonly name: string
  readonly icon: string
  readonly urls: tpYieldUrls
  readonly lpAddress: string
  readonly getApr: (option?: GetAprProps) => Promise<string>

  constructor(
    name: string,
    icon: string,
    urls: tpYieldUrls,
    lpAddress: string,
    getApr: (option?: GetAprProps) => Promise<string>,
    protocolTokenSymbol: TokenSymbol,
    symbol: TpYieldSymbol,
  ) {
    this.name = name
    this.icon = icon
    this.urls = urls
    this.lpAddress = lpAddress
    this.getApr = getApr
    this.protocolTokenSymbol = protocolTokenSymbol
    this.symbol = symbol
  }
}

export const TP_YIELDS: {
  [id in TpYieldSymbol]: TpYield
} = {
  [TpYieldSymbol.TRADERJOE_AVAX_PTP]: new TpYield(
    'TraderJoe',
    TOKENS.JOE.icon,
    {
      swap: 'https://traderjoexyz.com/trade?inputCurrency=AVAX&outputCurrency=0x22d4002028f537599be9f666d1c4fa138522f9c8',
      pool: 'https://traderjoexyz.com/pool/0x22d4002028f537599be9f666d1c4fa138522f9c8/AVAX#/',
      farm: 'https://v1.traderjoexyz.com/farm/0xCDFD91eEa657cc2701117fe9711C9a4F61FEED23-0x188bED1968b795d5c9022F6a0bb5931Ac4c18F00',
    },
    '0xcdfd91eea657cc2701117fe9711c9a4f61feed23',
    async (option) => {
      // TraderJoe Total APR: https://traderjoexyz.com/farm/0xCDFD91eEa657cc2701117fe9711C9a4F61FEED23-0x188bED1968b795d5c9022F6a0bb5931Ac4c18F00#/
      // TraderJoe Total Liquidity: https://traderjoexyz.com/pool/0x22d4002028f537599be9f666d1c4fa138522f9c8/AVAX#/
      if (!option) return '-'
      const {
        poolTvlUsdWad,
        ptpPerSecWad,
        joePerSecWad,
        poolAllocPointBN,
        totalAllocPointBN,
        ptpPrice,
        joePrice,
      } = option
      let poolAprWad: null | BigNumber = null
      let ptpAprWad: null | BigNumber = null
      let joeAprWad: null | BigNumber = null
      // pool apr
      const poolAprRes = await safeFetchJson<TraderJoeExcahngeSubgraphRes>(
        TRADER_JOE_EXCHANGE_SUBGRAPH_API,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: `
            {
              pairs( where:{id:"0xcdfd91eea657cc2701117fe9711c9a4f61feed23"}){
                  reserveUSD
                  hourData(first: 24,orderBy:date,orderDirection: desc){
                       volumeUSD
                  }
              }
            }
          `,
          }),
        },
      )
      if (poolAprRes?.data?.pairs) {
        const { hourData, reserveUSD } = poolAprRes.data.pairs[0]
        const volumeWad = hourData.reduce((acc, { volumeUSD }) => {
          return acc.add(strToWad(volumeUSD.substring(0, 18)))
        }, constants.Zero)
        const reserveUsdWad = strToWad(reserveUSD.substring(0, 18))
        poolAprWad = getHaircutFeeAprPercent(volumeWad, reserveUsdWad)
      }
      // ptp apr
      if (ptpPerSecWad && poolTvlUsdWad && ptpPrice) {
        ptpAprWad = getEmissionAprPercent(
          //https://snowtrace.io/address/0x364996dc358926b9a86b1ed601a33d5915fc86c8#readContract
          ptpPerSecWad,
          // ptp emission 100% in this pool
          BigNumber.from(1),
          BigNumber.from(1),
          // there is no boosted apr
          BigNumber.from(1000),
          poolTvlUsdWad,
          ptpPrice,
        )
      }

      // joe apr
      if (
        joePerSecWad &&
        poolAllocPointBN &&
        totalAllocPointBN &&
        poolTvlUsdWad &&
        joePrice
      ) {
        joeAprWad = getEmissionAprPercent(
          //https://snowtrace.io/address/0x364996dc358926b9a86b1ed601a33d5915fc86c8#readContract
          joePerSecWad,
          poolAllocPointBN,
          totalAllocPointBN,
          // there is no boosted apr
          BigNumber.from(1000),
          poolTvlUsdWad,
          joePrice,
        )
      }

      if (poolAprWad && ptpAprWad && joeAprWad) {
        return utils.formatEther(poolAprWad.add(ptpAprWad).add(joeAprWad))
      }
      return '-'
    },
    TokenSymbol.PTP,
    TpYieldSymbol.TRADERJOE_AVAX_PTP,
  ),
  [TpYieldSymbol.PANGOLIN_AVAX_PTP]: new TpYield(
    'Pangolin',
    PangolinIcon,
    {
      swap: 'https://app.pangolin.exchange/#/swap?outputCurrency=0x22d4002028f537599be9f666d1c4fa138522f9c8',
      pool: 'https://app.pangolin.exchange/#/add/AVAX/0x22d4002028f537599bE9f666d1c4Fa138522f9c8',
      farm: 'https://app.pangolin.exchange/#/png/0x22d4002028f537599bE9f666d1c4Fa138522f9c8/AVAX/2',
    },
    '0xa7a175a26bbcb1eaca6ff5318022e27e1df46fce',
    async () => {
      // Pangolin Total APR: https://app.pangolin.exchange/#/png/2
      // Pangolin Total Liquidity: https://info.pangolin.exchange/#/pair/0xa7a175a26bbcb1eaca6ff5318022e27e1df46fce
      const res = await safeFetchJson<{
        swapFeeApr: number
        stakingApr: number
        combinedApr: number
      }>(PTP_AVAX_PANGOLIN_APR_API)
      return res ? String(res.combinedApr) : '-'
    },
    TokenSymbol.PTP,
    TpYieldSymbol.PANGOLIN_AVAX_PTP,
  ),
}
