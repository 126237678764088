import {
  ANKRAVAX,
  AnkrEthOracle,
  Multicall3,
  Nft,
  PlatypusRouter02,
  PlatypusTreasure,
  PriceOracleGetter,
  SAVAX,
  TestYYOracle,
  VePtpV3,
  WAVAX,
  YYAVAX,
} from '../../../types/ethers-contracts'
import { ChainId } from '../networks'
import ANKRavaxABI from './abis/Ankr/ANKRAVAX.json'
import AnkrEthOracleABI from './abis/AnkrEthOracle.json'
import SavaxABI from './abis/Benqi/SAVAX.json'
import Multicall3ABI from './abis/Multicall3.json'
import NftABI from './abis/Nft.json'
import PlatypusRouter02ABI from './abis/PlatypusRouter02.json'
import PlatypusTreasureABI from './abis/PlatypusTreasure.json'
import PriceOracleGetterABI from './abis/PriceOracleGetter.json'
import VePtpABI from './abis/VePtpV3.json'
import WavaxABI from './abis/WAVAX.json'
import TestYYOracleABI from './abis/YieldYak/TestYYOracle.json'
import YYavaxABI from './abis/YieldYak/YYAVAX.json'
import { Contract } from './Contract'
import { EMERGENCY_WITHDRAWS } from './emergencyWithdraw'
import { LP_TOKENS } from './lpToken'
import { MASTER_PLATYPUS } from './masterPlatypus'
import { POOLS } from './pool'
import { TOKENS } from './token'
import { voter } from './voter'
import { ANKRAVAX_ABI } from './wagmiAbis/Ankr/ANKRAVAX'
import { ANKR_ETH_ORACLE_ABI } from './wagmiAbis/AnkrEthOracle'
import { SAVAX_ABI } from './wagmiAbis/Benqi/SAVAX'
import { MULTICALL3_ABI } from './wagmiAbis/Multicall3'
import { NFT_ABI } from './wagmiAbis/Nft'
import { PLATYPUS_ROUTER02_ABI } from './wagmiAbis/PlatypusRouter02'
import { PLATYPUS_TREASURE_ABI } from './wagmiAbis/PlatypusTreasure'
import { PRICE_ORACLE_GETTER_ABI } from './wagmiAbis/PriceOracleGetter'
import { VEPTP_V3_ABI } from './wagmiAbis/VePtpV3'
import { WAVAX_ABI } from './wagmiAbis/WAVAX'
import { TEST_YY_ORACLE_ABI } from './wagmiAbis/YieldYak/TestYYOracle'
import { YYAVAX_ABI } from './wagmiAbis/YieldYak/YYAVAX'

const vePtp = new Contract<VePtpV3, typeof VEPTP_V3_ABI>(
  {
    [ChainId.AVALANCHE]: '0x5857019c749147EEE22b1Fe63500F237F3c1B692',
    [ChainId.FUJI]: '0xC7Fd834cBDDAb9A7dB8a0Ae3eD883b7633807303',
  },
  VePtpABI,
  VEPTP_V3_ABI,
)

const nft = new Contract<Nft, typeof NFT_ABI>(
  {
    [ChainId.AVALANCHE]: '0x6a04a578247e15e3c038acf2686ca00a624a5aa0',
    [ChainId.FUJI]: '0x38df33886Db3592DEFF27564657414945A191258',
  },
  NftABI,
  NFT_ABI,
)

const router = new Contract<PlatypusRouter02, typeof PLATYPUS_ROUTER02_ABI>(
  {
    [ChainId.AVALANCHE]: '0x9B5CD7affc929e7E1357f6C24F10bBc74052A7Bd',
    [ChainId.FUJI]: '0x57904A778ca15Dc25a4857d1B9F38D9E6cBE5c2d',
  },
  PlatypusRouter02ABI,
  PLATYPUS_ROUTER02_ABI,
)

const platypusTreasure = new Contract<
  PlatypusTreasure,
  typeof PLATYPUS_TREASURE_ABI
>(
  {
    [ChainId.AVALANCHE]: '0x061da45081ACE6ce1622b9787b68aa7033621438',
    [ChainId.FUJI]: '0xc128CfB794621AEcdbD83C859B311824924D1369',
  },
  PlatypusTreasureABI,
  PLATYPUS_TREASURE_ABI,
)
const wavax = new Contract<WAVAX, typeof WAVAX_ABI>(
  {
    [ChainId.AVALANCHE]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    [ChainId.FUJI]: '0xd00ae08403b9bbb9124bb305c09058e32c39a48c',
  },
  WavaxABI,
  WAVAX_ABI,
)
const sAvax = new Contract<SAVAX, typeof SAVAX_ABI>(
  {
    [ChainId.AVALANCHE]: '0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE',
    [ChainId.FUJI]: '0xC667d5542F0b9Bff33A609dCB1c56275f7fbA475',
  },
  SavaxABI,
  SAVAX_ABI,
)

const yyAvax = new Contract<YYAVAX, typeof YYAVAX_ABI>(
  {
    [ChainId.AVALANCHE]: '0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE',
    [ChainId.FUJI]: '0x9aab16F45706b22c5465a5F500e02Ab9468033D4',
  },
  YYavaxABI,
  YYAVAX_ABI,
)

const ankrAvax = new Contract<ANKRAVAX, typeof ANKRAVAX_ABI>(
  {
    [ChainId.AVALANCHE]: '0xc3344870d52688874b06d844E0C36cc39FC727F6',
    [ChainId.FUJI]: '0x836Cb891A05e69C8ED3Ec32f6E84E51995A398C7',
  },
  ANKRavaxABI,
  ANKRAVAX_ABI,
)

// Since yyAvax has no pricePerShare state which is in testYYOracle contract
const testYYOracle = new Contract<TestYYOracle, typeof TEST_YY_ORACLE_ABI>(
  {
    // for testnet only
    [ChainId.AVALANCHE]: undefined,
    [ChainId.FUJI]: '0xf100881481031B1Ba92073230F8c84Bfbd8bd7ae',
  },
  TestYYOracleABI,
  TEST_YY_ORACLE_ABI,
)

const priceOracleGetter = new Contract<
  PriceOracleGetter,
  typeof PRICE_ORACLE_GETTER_ABI
>(
  {
    /** @TODO add mainnet */
    [ChainId.AVALANCHE]: undefined,
    [ChainId.FUJI]: '0x1cAA20909177edF60d9858D2D40311D4Aa49Fbc1',
  },
  PriceOracleGetterABI,
  PRICE_ORACLE_GETTER_ABI,
)
// multicall3 contract is for getting eth balance since ethcall package provides wrong address at the first data fetching
const multicall3 = new Contract<Multicall3, typeof MULTICALL3_ABI>(
  {
    [ChainId.AVALANCHE]: '0xcA11bde05977b3631167028862bE2a173976CA11',
    [ChainId.FUJI]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  Multicall3ABI,
  MULTICALL3_ABI,
)

const ankrEthOracle = new Contract<AnkrEthOracle, typeof ANKR_ETH_ORACLE_ABI>(
  {
    [ChainId.AVALANCHE]: '0xEf3C162450E1d08804493aA27BE60CDAa054050F',
    [ChainId.FUJI]: '0x22966Bc0EDC8bB73E48C7051Ea147143781a692B',
  },
  AnkrEthOracleABI,
  ANKR_ETH_ORACLE_ABI,
)
export {
  ankrAvax,
  yyAvax,
  MASTER_PLATYPUS,
  LP_TOKENS,
  TOKENS,
  vePtp,
  POOLS,
  EMERGENCY_WITHDRAWS,
  wavax,
  nft,
  sAvax,
  router,
  voter,
  testYYOracle,
  priceOracleGetter,
  platypusTreasure,
  multicall3,
  ankrEthOracle,
}
