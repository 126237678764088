import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import routes from '../../config/routes'
import PoolContainer from '../../containers/PoolContainer/PoolContainer'

function PoolPage(): React.ReactElement {
  useEffect(() => {
    ReactGA.pageview(routes.POOL.path)
  }, [])
  return (
    <>
      <PoolContainer />
    </>
  )
}

export default PoolPage
