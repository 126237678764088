import { Box, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import nfc1 from '../../../containers/PlatopiaContainer/assets/images/info/NFT.png'
import vePTPIcon from '../../../containers/PlatopiaContainer/assets/images/info/vePTP.png'
import AppButton from '../../buttons/AppButton/AppButton'
import populationIcon from './population.svg'

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(4, 26, 32, 0.8) 0%,
    rgba(4, 26, 32, 0.48) 100%
  );
`

export const VePtpIcon = styled.img.attrs({
  src: vePTPIcon,
})`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`

export const NFTContainer = styled(Box)`
  max-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  background: linear-gradient(
    180deg,
    rgba(4, 26, 32, 0.35) 0%,
    rgba(4, 26, 32, 0.21) 100%
  );
  margin-right: 4px;
  border-radius: 5px 10px 10px 5px;
`

export const NFTIcon = styled.img.attrs({
  src: nfc1,
})`
  width: 82px;
`

export const NFTInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12px 12px;
`

export const TitleText = styled(Typography).attrs({
  variant: 'subtitle2',
})`
  color: ${({ theme }) => theme.palette.platopia.main};
`

export const SmallText = styled(Typography).attrs({
  variant: 'body2',
})`
  color: #fff;
  opacity: 0.6;
  font-size: 0.8125rem;
`

export const ClaimContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: row;
  background: linear-gradient(
    180deg,
    rgba(4, 26, 32, 0.35) 0%,
    rgba(4, 26, 32, 0.21) 100%
  );
  padding: 14px;
  border-radius: 5px 10px 10px 5px;
  min-height: 81px;
  align-items: center;
`

export const ClaimInfoContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-right: 14px;
`

export const VePtpContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CTAButton = styled(AppButton).attrs({
  customVariant: 'secondary',
})`
  ${({ theme }) => css`
    flex: 0.5;
    padding: 6px 16px;
    max-width: 100px;
    background-color: ${({ theme }) => theme.palette.platopia.main};
    color: ${({ theme }) => theme.palette.primary[900]};
    border-radius: 50px;
    &:enabled:hover {
      background: ${({ theme }) => theme.palette.platopia.dark};
    }

    &.Mui-disabled {
      border: unset;
      border-radius: 50px;
      background-color: ${theme.palette.platopia.main};
      opacity: 0.5;
      color: ${theme.palette.primary[900]};
    }
  `}
`

export const PopulationContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(4, 26, 32, 0.35) 0%,
    rgba(4, 26, 32, 0.21) 100%
  );
  margin-right: 4px;
  padding: 11px;
`

export const PopulationTextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`

export const PopulationText = styled(Typography).attrs({
  variant: 'subtitle2',
})`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.platopia.main};
`

export const PopulationIcon = styled.img.attrs({
  src: populationIcon,
})`
  width: 16px;
  margin-right: 4px;
  color: ${({ theme }) => theme.palette.platopia.main};
`
