import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import routes from '../../config/routes'
import PlatopiaContainer from '../../containers/PlatopiaContainer/PlatopiaContainer'

function PlatopiaPage(): React.ReactElement {
  useEffect(() => {
    ReactGA.pageview(routes.PLATOPIA.path)
  }, [])

  return <PlatopiaContainer />
}

export default PlatopiaPage
