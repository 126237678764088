import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { BoxProps, PopperProps } from '@mui/material'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import useBreakpoints from '../../hooks/useBreakpoints'
import { Container, Item, StyledToolTip } from './InfoBox.element'

interface Props extends BoxProps {
  className?: string
  children?: React.ReactNode
  padding?: string
  paddingBottom?: string
}

function InfoBox({
  className,
  children,
  padding,
  paddingBottom,
  ...otherProps
}: Props): ReactElement {
  return (
    <Container
      className={clsx('info-box', className && className)}
      display="flex"
      flexDirection="column"
      pad={padding}
      padBottom={paddingBottom}
      {...otherProps}
    >
      {children}
    </Container>
  )
}

export default InfoBox

interface ItemProps extends BoxProps {
  fullWidth?: boolean
}
InfoBox.Item = function InfoBoxItem({
  className,
  children,
  fullWidth,
  ...otherProps
}: ItemProps): ReactElement {
  return (
    <Item
      className={className}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      $fullWidth={fullWidth}
      {...otherProps}
    >
      {children}
    </Item>
  )
}
interface InfoBoxToolTipProps extends Props {
  PopperProps?: Partial<PopperProps>
  text: string | ReactElement
  iconSize?: string
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}
InfoBox.Tooltip = function InfoBoxToolTip({
  className,
  PopperProps,
  text = 'tool tip',
  iconSize = '15px',
  placement,
  style,
}: InfoBoxToolTipProps): ReactElement {
  const { isTabletSm } = useBreakpoints()
  return (
    <StyledToolTip
      onClick={(e) => {
        e.stopPropagation()
      }}
      className={className}
      arrow
      placement={placement ? placement : isTabletSm ? 'bottom' : 'right'}
      title={text}
      iconsize={iconSize}
      // for mobile
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      // for Tooltip
      PopperProps={{
        modifiers: [
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: false,
              tether: false,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
        ],
        ...PopperProps,
      }}
      style={style}
    >
      <HelpOutlineOutlinedIcon data-testid="tooltip-icon" />
    </StyledToolTip>
  )
}
