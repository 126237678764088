import { PoolSymbol } from '../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import {
  ModalAction,
  ModalActionType,
  ModalId,
  ModalNftDataState,
} from '../../interfaces/Modal'

export const openModal = (
  modalId: ModalId,
  data?: {
    transactionHashes?: string[]
    tokenSymbols?: TokenSymbol[]
    poolSymbols?: PoolSymbol[]
    lpTokenAddresses?: string[]
    value?: string
    nftData?: ModalNftDataState
  },
): ModalAction => {
  return {
    type: ModalActionType.OPEN_MODAL,
    payload: {
      currentModalId: modalId,
      transactionHashes: data?.transactionHashes,
      lpTokenAddresses: data?.lpTokenAddresses,
      tokenSymbols: data?.tokenSymbols,
      poolSymbols: data?.poolSymbols,
      value: data?.value,
      nftData: data?.nftData,
    },
  }
}

export const closeModal = (): ModalAction => {
  return {
    type: ModalActionType.CLOSE_MODAL,
    payload: {
      currentModalId: ModalId.UNSET,
      transactionHashes: undefined,
      tokenSymbols: undefined,
      poolSymbols: undefined,
      value: undefined,
      nftData: undefined,
      lpTokenAddresses: undefined,
    },
  }
}
