import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { utils } from 'ethers'
import { useState } from 'react'
import { TOKENS, voter } from '../../config/contracts'
import { PoolSymbol } from '../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { VOTER_ABI } from '../../config/contracts/wagmiAbis/Voter'
import { useModal } from '../../contexts/ModalContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useVePtp } from '../../contexts/VePtpContext'
import { useVotingData } from '../../contexts/VotingDataContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'
import { isUserDeniedTransaction } from '../../utils/contract'
import { getLpTokenAddressesAndVePtpVoteDeltaWads } from '../../utils/voting'
import useRevertReason from '../useRevertReason'

type Props = {
  isWaiting: boolean
  isDone: boolean
  handleVote: (
    votingWeightPercentages: {
      [id in PoolSymbol]?: {
        [id in TokenSymbol]?: string
      }
    },
  ) => Promise<void>
}
const useVote = (): Props => {
  const { chainId } = useWeb3()
  const { vePtp } = useVePtp()
  const getRevertReason = useRevertReason()
  const [isWaiting, setIsWaiting] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const { showMessage } = useSnackbar()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { user } = useVotingData()
  const handleVote = async (
    proposedUserVotingWeightPercentages: {
      [id in PoolSymbol]?: {
        [id in TokenSymbol]?: string
      }
    },
  ) => {
    setIsDone(false)
    setIsWaiting(true)
    let writeContractResult: WriteContractResult | undefined

    try {
      const { lpTokenAddresses, vePtpVoteDeltaWads } =
        getLpTokenAddressesAndVePtpVoteDeltaWads(
          proposedUserVotingWeightPercentages,
          user.voteWeightOfEachAsset.vePtp,
          utils.parseUnits(vePtp.balance.total.current, TOKENS.vePTP.decimals),
          chainId,
        )
      const voterAddress = voter.getAddress(chainId)
      if (voterAddress) {
        modalDispatch(
          openModal(ModalId.VOTE_WAIT_FOR_CONFIRMATION, { lpTokenAddresses }),
        )
        // <<<<<<< HEAD
        const config = await prepareWriteContract({
          address: voterAddress,
          abi: VOTER_ABI,
          functionName: 'vote',
          args: [lpTokenAddresses, vePtpVoteDeltaWads],
          chainId,
        })
        writeContractResult = await writeContract(config)
        if (writeContractResult) {
          const { hash, wait } = writeContractResult
          await wait()
          // =======

          // logging tx data for creating multisig proposal
          // const txObject = {
          //   to: voterContract.address,
          //   data: voterContract.interface.encodeFunctionData('vote', [
          //     lpTokenAddresses,
          //     vePtpVoteDeltaWads,
          //   ]),
          // }
          // console.log('vote tx data')
          // console.log(txObject)
          // console.log('lp addresses:')
          // console.log(lpTokenAddresses)
          // console.log('vote delta:')
          // console.log(vePtpVoteDeltaWads.map((wad) => wad.toString()))

          // transaction = await voterContract.vote(
          //   lpTokenAddresses,
          //   vePtpVoteDeltaWads,
          // )
          // if (transaction) {
          //   await waitForTransaction(readOnlyProvider, transaction.hash)
          // >>>>>>> production
          modalDispatch(
            openModal(ModalId.TRANSACTION_SUBMITTED, {
              transactionHashes: [hash],
            }),
          )
          showMessage(`Successfully vote.`)
          setIsDone(true)
        } else {
          showMessage('Transaction failed.', 'warning')
          modalDispatch(openModal(ModalId.UNSET))
        }
      }
    } catch (err) {
      if (!isUserDeniedTransaction(err)) {
        Sentry.setContext('contract_call', {
          name: 'vote',
        })
        Sentry.captureException(err)
      }
      const reason = await getRevertReason(err)
      showMessage(reason || 'Transaction failed.', 'warning')
      modalDispatch(openModal(ModalId.UNSET))
    } finally {
      setIsWaiting(false)
    }
  }
  return { handleVote, isWaiting, isDone }
}

export default useVote
