import { useCallback } from 'react'
import { TOKENS } from '../../config/contracts'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useWeb3 } from '../../contexts/Web3Context'

function useAddTokenToWallet(): (tokenSymbol?: TokenSymbol) => Promise<void> {
  const { showMessage } = useSnackbar()
  const { connector, chainId } = useWeb3()
  const addTokenToMetaMask = useCallback(
    async (tokenSymbol?: TokenSymbol) => {
      const token = tokenSymbol ? TOKENS[tokenSymbol] : null
      const address = token?.address[chainId]
      if (token && connector && address) {
        await connector
          .watchAsset?.({
            address,
            decimals: token.decimals,
            symbol: token.symbol,
          })
          .catch(() => {
            showMessage(
              `Unable to add ${tokenSymbol || ''} to wallet.`,
              'warning',
            )
          })
      } else {
        showMessage(`Token not found`, 'warning')
      }
    },
    [chainId, connector, showMessage],
  )

  return addTokenToMetaMask
}

export default useAddTokenToWallet
