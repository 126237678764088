import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppTypography from '../../../components/AppTypography/AppTypography'
import { StyledLinearProgress } from '../../../globalStyles'
import { StakeDetailItem } from '../VePtpContainer.elements'

interface ContainerProps {
  $isActive?: boolean
}

export const Container = styled(StakeDetailItem)<ContainerProps>`
  ${({ theme, $isActive }) => css`
    display: flex;
    flex-direction: column;
    background: ${alpha(theme.palette.primary[500], 0.2)};
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    margin: 0px auto;

    ${!$isActive &&
    css`
      opacity: 0.4;
    `}

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `}
`

export const TotalEarning = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;

      .total-earning__title {
        margin-bottom: 8px;
      }
      h4,
      h5 {
        font-size: 24px;
      }
    }
  `}
`

export const EarningProgress = styled(StyledLinearProgress)`
  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
        width: 100%;
      }
    `}
`

export const EarningAdditionalInfoItem = styled(AppTypography).attrs(() => ({
  variant: 'subtitle1',
}))`
  ${() => css`
    display: inline-flex;
    align-items: center;
  `}
`

export const EarningAdditionalInfo = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column-reverse;
      ${EarningAdditionalInfoItem} {
        display: inline-flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
      }
    }
  `}
`

export const CalculatorButton = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${theme.palette.primary['A200']};
    svg {
      margin-left: 4px;
      font-size: 16px;
    }
  `}
`
