import React, { ReactElement } from 'react'
import SubmittedTransactionModal from '../../../components/Modal/SubmittedTransactionModal/SubmittedTransactionModal'
import { useModal } from '../../../contexts/ModalContext'

interface Props {
  isOpen: boolean
}

function SubmittedTransactionModalContainer({ isOpen }: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()

  return (
    <SubmittedTransactionModal isOpen={isOpen}>
      <SubmittedTransactionModal.AdditionalButton
        onClick={() => modalDispatch(closeModal())}
      >
        Done
      </SubmittedTransactionModal.AdditionalButton>
    </SubmittedTransactionModal>
  )
}

export default React.memo(SubmittedTransactionModalContainer)
