import { Slide, SlideProps, SnackbarCloseReason } from '@mui/material'
import React, { ReactElement } from 'react'
import { useTheme } from 'styled-components'
import { useSnackbar } from '../../contexts/SnackbarContext'
import AppAlert from '../alerts/AppAlert/AppAlert'
import { StyledSnackbar } from './Snackbar.elements'
interface Props {
  autoHideDuration?: number
}
function Snackbar({ autoHideDuration = 3000 }: Props): ReactElement {
  const { open, setOpen, clearMessageInfo, messageInfo } = useSnackbar()
  const handleClose = (
    _: Event | React.SyntheticEvent<HTMLInputElement, Event>,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }
  return (
    <StyledSnackbar
      key={messageInfo ? messageInfo.key : undefined}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      TransitionProps={{ onExited: clearMessageInfo }}
      message={
        messageInfo ? (
          <AppAlert
            message={messageInfo.message}
            severity={messageInfo.severity}
            // currently only warning has an outlined version
            variant={messageInfo.severity === 'warning' ? 'outlined' : 'filled'}
          />
        ) : undefined
      }
      TransitionComponent={SlideTransition}
    />
  )
}

export default Snackbar

const SlideTransition = (props: SlideProps) => {
  const theme = useTheme()
  return (
    <Slide
      style={{ borderRadius: theme.borderRadius.md }}
      {...props}
      direction="left"
    />
  )
}
