import { getSfFormat } from '@hailstonelabs/big-number-utils'
import React, { ReactElement } from 'react'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import AppTypography from '../AppTypography/AppTypography'
import TooltipNum from '../InfoBox/TooltipNum'
import TokenIcon from '../TokenIcon/TokenIcon'
import AppTokenInput from './AppTokenInput'
import { TokenItem, TokenItemList } from './DisabledAppTokenInput.elements'

interface Props {
  tokenItems: { value: string; tokenSymbol: TokenSymbol | null }[]
  leadingLabel?: string | React.ReactElement
  trailingLabel?: string | React.ReactElement
  disableBgColor?: boolean
}

function DisabledAppTokenInput({
  tokenItems,
  leadingLabel,
  trailingLabel,
  disableBgColor,
}: Props): ReactElement {
  return (
    <AppTokenInput value={''}>
      {(leadingLabel || trailingLabel) && (
        <AppTokenInput.LabelItem
          leadingLabel={leadingLabel}
          trailingLabel={trailingLabel}
          style={{ marginBottom: '4px' }}
        />
      )}
      <TokenItemList disableBgColor={disableBgColor}>
        {tokenItems.map((tokenItem) => (
          <TokenItem key={tokenItem.tokenSymbol}>
            <AppTypography
              variant="body1"
              centerContent
              justifyContent="flex-start"
            >
              <TokenIcon
                tokenSymbol={tokenItem.tokenSymbol as TokenSymbol}
                margin="0 8px 0 0"
              />
              {tokenItem.tokenSymbol}
            </AppTypography>
            <AppTypography variant="h6">
              <TooltipNum amount={tokenItem.value}>
                {getSfFormat(tokenItem.value, 6)}
              </TooltipNum>
            </AppTypography>
          </TokenItem>
        ))}
      </TokenItemList>
    </AppTokenInput>
  )
}

export default DisabledAppTokenInput
