import { useEffect, useState } from 'react'

function useWindowScroll(): [number, number] {
  const [scrollPosition, setScrollPosition] = useState<[number, number]>([0, 0])
  useEffect(() => {
    const getScrollPosition = () => {
      setScrollPosition([window.scrollX, window.scrollY])
    }
    window.addEventListener('scroll', getScrollPosition)

    return () => {
      window.removeEventListener('scroll', getScrollPosition)
    }
  }, [])
  return scrollPosition
}

export default useWindowScroll
