import { Box } from '@mui/material'
import styled, { css } from 'styled-components'

export const Background = styled(Box)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(0, 118, 231, 0) 0%,
      rgba(0, 118, 231, 0.7) 100%
    );
    flex: 1;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 200px;
      background: radial-gradient(
        35% 80% at 50% 100%,
        rgba(0, 255, 22, 0.35) 0%,
        rgba(12, 88, 255, 0) 90%
      );
    }
  `}
`
export const Container = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 840px;
    margin: 0 16px;

    .MigrationContainer__link {
      align-self: flex-start;
      color: ${theme.palette.primary['A200']};
      svg {
        font-size: 20px;
        margin-right: 4px;
      }
    }
  `}
`
export const Header = styled(Box)`
  ${() => css`
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .MigrationContainer__title {
      text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5), 0px 30px 80px #0c58ff,
        0px 100px 180px #00ff57;
      margin-bottom: 8px;
    }
  `}
`
export const MigrationCardContainer = styled(Box)`
  margin: 40px 0;
`
