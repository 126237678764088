import {
  getCommifiedFormat,
  getDpFormat,
  getPercentageFromTwoWAD,
  isParsableString,
  strToWad,
} from '@hailstonelabs/big-number-utils'
import RefreshIcon from '@mui/icons-material/Refresh'
import { alpha, Box, Typography, useTheme } from '@mui/material'
import React, { ReactElement, useMemo, useState } from 'react'
import DefaultIcon from '../../../assets/icons/platypus-white.svg'
import AppTypography from '../../../components/AppTypography/AppTypography'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import ItemSelectionModal from '../../../components/Modal/ItemSelectionModal/ItemSelectionModal'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import AppTokenInput from '../../../components/TokenInput/AppTokenInput'
import { POOLS } from '../../../config/contracts'
import { PoolSymbol } from '../../../config/contracts/pool/poolSymbol'
import { TOKENS } from '../../../config/contracts/token'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { usePools } from '../../../contexts/PoolsContext'
import { useStakeLpData } from '../../../contexts/StakeLpDataContext'
import { useVePtpBoosterCalculator } from '../../../contexts/VePtpBoosterCalculatorContext'
import { StyledDivider } from '../../../globalStyles'

function MyStakedDepositContainer(): ReactElement {
  return (
    <>
      <StyledDivider $colored>
        <TokenIcon iconPath={DefaultIcon} margin="0 4px 0 0" /> My Staked
        Deposit
      </StyledDivider>
      <MyStakedDepositInput />
      <PoolLiquidityInput />
      <MyStakedDepositInfoBox />
    </>
  )
}

export default MyStakedDepositContainer

/**
 * MyStakedDepositInput
 */
const getItemLabel = (
  assetTokenSymbol: TokenSymbol,
  poolName: string,
): string => {
  return `${assetTokenSymbol} (${poolName})`
}
function MyStakedDepositInput(): ReactElement {
  const theme = useTheme()
  const [isSelectTokenModalOpen, setIsSelectTokenModalOpen] = useState(false)
  const {
    myStakedDepositInput,
    actions: {
      updateTargetTokenFromMyStakedDepositInput,
      updateAmountFromMyStakedDepositInput,
      resetAmountFromMyStakedDepositInput,
    },
  } = useVePtpBoosterCalculator()
  const { lp } = useStakeLpData()
  const isResettable = useMemo(
    () =>
      myStakedDepositInput.assetTokenSymbol && myStakedDepositInput.poolSymbol
        ? lp.inTermsOfToken.total[myStakedDepositInput.poolSymbol][
            myStakedDepositInput.assetTokenSymbol
          ] || '0.0' !== myStakedDepositInput.amount
        : false,
    [
      myStakedDepositInput.assetTokenSymbol,
      myStakedDepositInput.poolSymbol,
      myStakedDepositInput.amount,
      lp.inTermsOfToken.total,
    ],
  )
  const [isFormattedInitialValueShown, setIsFormattedInitialValueShown] =
    useState(true)

  const assetOptions = useMemo(() => {
    let targetOptions: {
      id: string
      leadingLabel?: string | ReactElement
      value?: {
        poolSymbol: PoolSymbol
        assetTokenSymbol: TokenSymbol
      }
    }[] = []
    // follow the order in PoolSymbol
    for (const poolSymbol of Object.values(PoolSymbol)) {
      const pool = POOLS[poolSymbol]
      if (!pool.hasPtpBooster) {
        continue
      }
      const newOptions = pool
        .getAssets()
        .sort((a, b) =>
          // alphabetical order within the same pool
          a.tokenSymbol.localeCompare(b.tokenSymbol),
        )
        .map((asset) => {
          const label = getItemLabel(asset.tokenSymbol, pool.name)
          return {
            id: label,
            leadingLabel: (
              <Box display="flex" flexDirection="row" alignItems="center">
                <TokenIcon tokenSymbol={asset.tokenSymbol} margin="0 8px 0 0" />
                {label}
              </Box>
            ),
            value: {
              poolSymbol: poolSymbol as PoolSymbol,
              assetTokenSymbol: asset.tokenSymbol,
            },
          }
        })
      targetOptions = targetOptions.concat(newOptions)
    }

    return targetOptions
  }, [])
  const [assetOptionsFilterInput, setAssetOptionsFilterInput] =
    useState<string>('')

  const handleInputChange = (value: string) => {
    if (isFormattedInitialValueShown) {
      setIsFormattedInitialValueShown(false)
    }
    if (value === '') {
      updateAmountFromMyStakedDepositInput('')
      return
    }
    if (
      !myStakedDepositInput.assetTokenSymbol ||
      !isParsableString(
        value,
        TOKENS[myStakedDepositInput.assetTokenSymbol].decimals,
        true,
      )
    )
      return

    updateAmountFromMyStakedDepositInput(value)
  }

  const handleSelectTokenModalClose = () => {
    setIsSelectTokenModalOpen(false)
    setAssetOptionsFilterInput('')
  }

  const handleSelectToken = (
    selectedItem:
      | {
          poolSymbol: PoolSymbol
          assetTokenSymbol: TokenSymbol
        }
      | undefined,
  ) => {
    if (!selectedItem) return
    const { poolSymbol, assetTokenSymbol } = selectedItem

    updateTargetTokenFromMyStakedDepositInput(poolSymbol, assetTokenSymbol)
    setIsFormattedInitialValueShown(true)
    handleSelectTokenModalClose()
  }

  const resetInput = () => {
    resetAmountFromMyStakedDepositInput()
    setIsFormattedInitialValueShown(true)
  }
  return (
    <>
      <AppTokenInput
        value={
          isFormattedInitialValueShown
            ? getDpFormat(myStakedDepositInput.amount)
            : myStakedDepositInput.amount
        }
        onChange={handleInputChange}
        style={{ paddingTop: '12px' }}
      >
        <AppTokenInput.InnerContainer
          bgcolor={
            myStakedDepositInput.assetTokenSymbol
              ? alpha(theme.palette.primary[500], 0.2)
              : theme.palette.common.black
          }
          flexDirection="column"
        >
          <AppTokenInput.SelectTokenButton
            token={myStakedDepositInput.assetTokenSymbol}
            onSelectToken={() => setIsSelectTokenModalOpen(true)}
          />
          <Box display="flex" flexDirection="row" mt="16px">
            <AppTokenInput.InputField
              placeholder="0.0"
              disableUnderline
              textalign="left"
              disabled={!myStakedDepositInput.assetTokenSymbol}
              noLeftPadding
            />
            <AppTokenInput.ActionButton
              disabled={!myStakedDepositInput.assetTokenSymbol || !isResettable}
              onClick={resetInput}
            >
              <RefreshIcon />
            </AppTokenInput.ActionButton>
          </Box>
        </AppTokenInput.InnerContainer>
      </AppTokenInput>
      {isSelectTokenModalOpen && (
        <ItemSelectionModal
          isOpen={isSelectTokenModalOpen}
          topBarLabel="Select a Token"
          onClose={handleSelectTokenModalClose}
          filterInputProps={{
            placeholder: 'Search Name',
            value: assetOptionsFilterInput,
            onChange: (value) => setAssetOptionsFilterInput(value),
          }}
        >
          {assetOptions
            .filter((option) =>
              option.id
                .toLowerCase()
                .includes(assetOptionsFilterInput.toLowerCase()),
            )
            .map((option) => (
              <ItemSelectionModal.Item
                key={option.id}
                id={option.id}
                onClick={({ value }) => handleSelectToken(value)}
                value={option.value}
                isSelected={
                  myStakedDepositInput.assetTokenSymbol &&
                  myStakedDepositInput.poolSymbol
                    ? option.id ===
                      getItemLabel(
                        myStakedDepositInput.assetTokenSymbol,
                        POOLS[myStakedDepositInput.poolSymbol].name,
                      )
                    : false
                }
              >
                <ItemSelectionModal.ItemLeadingLabel>
                  <AppTypography component="span" variant="body1">
                    {option.leadingLabel}
                  </AppTypography>
                </ItemSelectionModal.ItemLeadingLabel>
              </ItemSelectionModal.Item>
            ))}
        </ItemSelectionModal>
      )}
    </>
  )
}

/**
 * PoolLiquidityInput
 */
function PoolLiquidityInput(): ReactElement {
  const theme = useTheme()
  const {
    myStakedDepositInput,
    poolLiquidityInputAmount,
    actions: { updatePoolLiquidityInputAmount, resetPoolLiquidityInputAmount },
  } = useVePtpBoosterCalculator()
  const { liabilities } = usePools()
  const isResettable = useMemo(
    () =>
      myStakedDepositInput.assetTokenSymbol && myStakedDepositInput.poolSymbol
        ? liabilities[myStakedDepositInput.poolSymbol][
            myStakedDepositInput.assetTokenSymbol
          ] !== poolLiquidityInputAmount
        : false,
    [
      myStakedDepositInput.assetTokenSymbol,
      myStakedDepositInput.poolSymbol,
      poolLiquidityInputAmount,
      liabilities,
    ],
  )
  const [isFormattedInitialValueShown, setIsFormattedInitialValueShown] =
    useState(true)

  const handleInputChange = (value: string) => {
    if (isFormattedInitialValueShown) {
      setIsFormattedInitialValueShown(false)
    }
    if (value === '') {
      updatePoolLiquidityInputAmount('')
      return
    }
    if (
      !myStakedDepositInput.assetTokenSymbol ||
      !isParsableString(
        value,
        TOKENS[myStakedDepositInput.assetTokenSymbol].decimals,
        true,
      )
    )
      return

    updatePoolLiquidityInputAmount(value)
  }

  const resetInput = () => {
    resetPoolLiquidityInputAmount()
    setIsFormattedInitialValueShown(true)
  }
  return (
    <AppTokenInput
      value={
        isFormattedInitialValueShown
          ? getDpFormat(poolLiquidityInputAmount)
          : poolLiquidityInputAmount
      }
      onChange={handleInputChange}
    >
      <AppTokenInput.InnerContainer
        bgcolor={theme.palette.common.black}
        flexDirection="column"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          {myStakedDepositInput.assetTokenSymbol ? (
            <TokenIcon tokenSymbol={myStakedDepositInput.assetTokenSymbol} />
          ) : (
            <TokenIcon iconPath={DefaultIcon} />
          )}
          &nbsp;
          <Typography variant="caption">Pool Liquidity</Typography>
        </Box>
        <Box display="flex" flexDirection="row" mt="16px">
          <AppTokenInput.InputField
            placeholder="0.0"
            disableUnderline
            textalign="left"
            disabled={!myStakedDepositInput.assetTokenSymbol}
            noLeftPadding
          />
          <AppTokenInput.ActionButton
            disabled={!myStakedDepositInput.assetTokenSymbol || !isResettable}
            onClick={resetInput}
          >
            <RefreshIcon />
          </AppTokenInput.ActionButton>
        </Box>
      </AppTokenInput.InnerContainer>
    </AppTokenInput>
  )
}

/**
 * MyStakedDepositInfoBox
 */
function MyStakedDepositInfoBox(): ReactElement {
  const { myStakedDepositInput, poolLiquidityInputAmount } =
    useVePtpBoosterCalculator()

  const myStakedDepositInputAmountWAD = strToWad(myStakedDepositInput.amount)
  const poolLiquidityInputAmountWAD = strToWad(poolLiquidityInputAmount)

  const poolSharePercent = getPercentageFromTwoWAD(
    myStakedDepositInputAmountWAD,
    poolLiquidityInputAmountWAD,
    strToWad('100'),
  )

  return (
    <InfoBox>
      <InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">Pool Share</Typography>
          <InfoBox.Tooltip text="The percentage share of the pool you would own" />
        </InfoBox.Item>
        <Typography variant="caption">
          <TooltipNum rightSymbol="%" amount={poolSharePercent}>
            {getCommifiedFormat(poolSharePercent)}%
          </TooltipNum>
        </Typography>
      </InfoBox.Item>
    </InfoBox>
  )
}
