import { alpha, Box, useTheme } from '@mui/material'
import React, { ReactElement } from 'react'
import WaitForConfirmationModal from '../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import DisabledAppTokenInput from '../../../components/TokenInput/DisabledAppTokenInput'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import MemorizedNftCardContainer from '../../MemorizedNftCardContainer/MemorizedNftCardContainer'
import UnstakeOrUnlockModalInfoBox from '../UnstakeModalContainer/UnstakeOrUnlockModalInfoBox'
interface Props {
  isOpen: boolean
}

function UnstakeWaitForConfirmationModalContainer({
  isOpen,
}: Props): ReactElement {
  const theme = useTheme()
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const { input, nft } = useVePtp()
  const handleCloseModal = () => {
    modalDispatch(closeModal())
  }

  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="600px"
      subtitle="Unstake PTP"
    >
      {!input.isUnstakedAllPtpWithEquippedNft && (
        <DisabledAppTokenInput
          tokenItems={[
            {
              value: input.unstakePtpAmount,
              tokenSymbol: TokenSymbol.PTP,
            },
          ]}
        />
      )}

      {input.isUnstakedAllPtpWithEquippedNft && (
        <Box
          bgcolor={alpha(theme.palette.primary[500], 0.2)}
          borderRadius="10px"
        >
          <DisabledAppTokenInput
            disableBgColor
            tokenItems={[
              {
                value: input.unstakePtpAmount,
                tokenSymbol: TokenSymbol.PTP,
              },
            ]}
          />
          <MemorizedNftCardContainer
            id={nft.equippedId}
            padding="0 12px 12px"
          />
        </Box>
      )}
      <UnstakeOrUnlockModalInfoBox />
    </WaitForConfirmationModal>
  )
}

export default UnstakeWaitForConfirmationModalContainer
