import {
  getCommifiedFormat,
  getSfFormat,
} from '@hailstonelabs/big-number-utils'
import React, { ReactElement } from 'react'
import { useModal } from '../../../contexts/ModalContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import useWalletConnection from '../../../hooks/wallet/useWalletConnection'
import { ModalId } from '../../../interfaces/Modal'
import TooltipNum from '../../InfoBox/TooltipNum'
import {
  Container,
  PtpContainer,
  PtpIcon,
  PtpStakeInfoContainer,
  StakeButton,
  StakedPtpContainer,
  StakedPtpSmallText,
  TextWithToolTip,
  TitleText,
  UnstakeButton,
  VePtpFormulaContainer,
} from './PtpSummary.elements'

function PtpSummary(): ReactElement {
  const { ptp, vePtp } = useVePtp()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { account } = useNetwork()
  const { openConnectToWalletModal } = useWalletConnection()
  const hasAccountWithNoStakedPtp = !ptp.hasStaked && !!account
  const handleClickStake = () => {
    if (account) {
      modalDispatch(openModal(ModalId.STAKE_PTP))
    } else {
      openConnectToWalletModal()
    }
  }
  const handleClickUnstake = () => {
    if (account) {
      modalDispatch(openModal(ModalId.UNSTAKE_PTP))
    } else {
      openConnectToWalletModal()
    }
  }
  return (
    <Container>
      <VePtpFormulaContainer>
        <TitleText>
          <TooltipNum
            amount={vePtp.earningPerHour.current}
            rightSymbol="vePTP / hour"
          >
            {`${getCommifiedFormat(
              getSfFormat(vePtp.earningPerHour.current, 4),
            )} vePTP / hour`}
          </TooltipNum>
        </TitleText>
        <TextWithToolTip
          content="vePTP Mine Rate"
          tooltipText={`Each staked PTP generates ${vePtp.generationRate.perSecond} vePTP per second (without any NFT effect).`}
        />
      </VePtpFormulaContainer>
      <StakedPtpContainer>
        <PtpStakeInfoContainer>
          <PtpContainer>
            <PtpIcon />
            <TitleText>
              <TooltipNum amount={ptp.amount.staked}>
                {getCommifiedFormat(ptp.amount.staked)}
              </TooltipNum>
            </TitleText>
          </PtpContainer>
          <StakedPtpSmallText>Staked PTP</StakedPtpSmallText>
        </PtpStakeInfoContainer>
        <StakeButton onClick={handleClickStake}>Stake</StakeButton>
        <UnstakeButton
          onClick={handleClickUnstake}
          disabled={hasAccountWithNoStakedPtp}
        >
          Unstake
        </UnstakeButton>
      </StakedPtpContainer>
    </Container>
  )
}

export default PtpSummary
