import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'

export const Shade = styled(Box)`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: -4.5%;
    left: 0;
    background: radial-gradient(40% 3.56% at 50% 5%, ${
      theme.palette.primary[500]
    } 0%, ${alpha(
    theme.palette.primary[700],
    0.51,
  )} 63.46%, rgba(6, 45, 130, 0) 100%);
  })
`}
`
interface ContainerProps {
  allowToClickSwap?: boolean
}
export const Container = styled(Box)<ContainerProps>`
  ${({ allowToClickSwap, theme }) => css`
    position: relative;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 24px 24px 32px 24px;
    border-radius: 20px;
    box-shadow: 0px 0px 20px -10px rgba(255, 255, 255, 0.5);

    ${allowToClickSwap &&
    css`
      box-shadow: 0px 0px 20px -10px rgba(255, 255, 255, 0.5),
        0px 30px 80px -60px #0c58ff, 0px 100px 180px -100px #00ff57;
    `}

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 400px;
      margin: 0 16px;
    }
    @media only screen and (max-width: ${theme.breakpoints.values.xs}px) {
      margin-bottom: 24px;
    }
  `}
`

export const AddTokenToWalletButton = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    color: ${theme.palette.primary['A200']};
    margin: 16px auto;
    background: ${alpha(theme.palette.primary[900], 0.7)};
    border: 1px solid ${alpha(theme.palette.primary.main, 0.2)};
    padding: 8px 16px;
    border-radius: ${theme.borderRadius.md};
    cursor: pointer;
  `}
`
