import { MetaMaskConnector } from '@wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import CoinbaseWalletLogo from '../../assets/icons/coinbase-wallet-logo.png'
import CoreWalletLogo from '../../assets/icons/corewallet-logo.png'
import MetaMaskLogo from '../../assets/icons/metamask-fox.svg'
import TrustWalletLogo from '../../assets/icons/trust-wallet-icon.svg'
import WalletConnectLogo from '../../assets/icons/walletconnect-logo.svg'
import XdefiLogo from '../../assets/icons/xdefi-logo.png'
import { NETWORKS } from '../networks'
import { Wallet } from './Wallet'

export enum WalletId {
  COREWALLET = 'corewallet',
  METAMASK = 'metamask',
  WALLETCONNECT = 'walletconnect',
  WALLETLINK = 'walletlink',
  XDEFIWALLET = 'xdefiwallet',
  TRUSTWALLET = 'trustwallet',
}

export const WALLETS: { [id in WalletId]: Wallet } = {
  [WalletId.COREWALLET]: new Wallet({
    id: WalletId.COREWALLET,
    name: 'Core',
    icon: CoreWalletLogo,
    connector: new InjectedConnector({
      chains: Object.values(NETWORKS),
      options: {
        name: 'Core Wallet',
        getProvider: () =>
          typeof window !== 'undefined' ? window.avalanche : undefined,
      },
    }),
    isAddTokenSupported: false,
    isMobileSupported: false,
  }),
  /**
   * @dev Metamask mobile version only works at Metamask browser, for injected web3.
   * It will not work on normal browsers (Chrome, Brave, and etc...) on Mobile.
   */
  [WalletId.METAMASK]: new Wallet({
    id: WalletId.METAMASK,
    name: 'MetaMask',
    icon: MetaMaskLogo,
    connector: new MetaMaskConnector({ chains: Object.values(NETWORKS) }),
    isAddTokenSupported: true,
    isMobileSupported: true,
  }),
  [WalletId.WALLETCONNECT]: new Wallet({
    id: WalletId.WALLETCONNECT,
    name: 'WalletConnect',
    icon: WalletConnectLogo,
    connector: new WalletConnectConnector({
      chains: Object.values(NETWORKS),
      options: {
        projectId: '1abc0c0279d55c5f175c9c464d2542d4',
        showQrModal: true,
      },
    }),
    isAddTokenSupported: false,
    isMobileSupported: true,
  }),
  [WalletId.WALLETLINK]: new Wallet({
    id: WalletId.WALLETLINK,
    name: 'Coinbase Wallet',
    icon: CoinbaseWalletLogo,
    connector: new CoinbaseWalletConnector({
      chains: Object.values(NETWORKS),
      options: {
        appName: 'Platypus Dapp',
      },
    }),
    isAddTokenSupported: false,
    isMobileSupported: true,
  }),
  [WalletId.XDEFIWALLET]: new Wallet({
    id: WalletId.XDEFIWALLET,
    name: 'XDEFI Wallet',
    icon: XdefiLogo,
    connector: new InjectedConnector({
      chains: Object.values(NETWORKS),
      options: {
        name: 'XDEFI Wallet',
        getProvider: () =>
          typeof window !== 'undefined' ? window.xfi?.ethereum : undefined,
      },
    }),
    isAddTokenSupported: true,
    isMobileSupported: false,
  }),
  [WalletId.TRUSTWALLET]: new Wallet({
    id: WalletId.TRUSTWALLET,
    name: 'Trust Wallet',
    icon: TrustWalletLogo,
    connector: new InjectedConnector({
      chains: Object.values(NETWORKS),
      options: {
        name: 'Trust Wallet',
        getProvider: () =>
          typeof window !== 'undefined' ? window.trustwallet : undefined,
      },
    }),
    isAddTokenSupported: true,
    isMobileSupported: false,
  }),
}
export { Wallet }
