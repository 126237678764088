import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'
import { UrlReminderProps } from './UrlReminder'

type StyledUrlReminderProps = Pick<UrlReminderProps, 'isPerm'>

export const Container = styled(Box).withConfig({
  shouldForwardProp: (prop) => !['isPerm'].includes(prop),
})<StyledUrlReminderProps>`
  ${({ theme, isPerm }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: ${isPerm
      ? alpha(theme.palette.primary[500], 0.85)
      : theme.palette.primary[500]};
    padding: 6px;

    svg {
      font-size: 20px;
    }
    .UrlReminder__text {
      padding: 0 26px;
    }
    .UrlReminder__close-button {
      position: absolute;
      right: 0;
      &:hover {
        background: transparent;
      }
      svg {
        font-size: 22px;
      }
    }
  `}
`
