import { alpha, Box, Theme, Typography } from '@mui/material'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

export const poolCardSharedMediaQuery = ({
  theme,
}: {
  theme: Theme
}): FlattenSimpleInterpolation => css`
  @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media only screen and (min-width: ${theme.breakpoints.values
      .md}px) and (max-width: ${theme.breakpoints.values.lg}px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media only screen and (min-width: ${theme.breakpoints.values.lg}px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`

interface PoolCardProps {
  hasDeposit?: boolean
}
export const Container = styled(Box)<PoolCardProps>`
  ${({ theme, hasDeposit }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${alpha(theme.palette.primary[900], 0.7)};
    box-shadow: 0px 0px 12px ${alpha(theme.palette.common.white, 0.3)};
    border-radius: ${theme.borderRadius.sm};
    margin-bottom: 24px;
    width: 100%;
    ${hasDeposit &&
    css`
      background: ${alpha(theme.palette.primary[500], 0.2)};
    `}

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 16px;
    }
  `}
`

interface InfoProps {
  hasDeposit?: boolean
}
export const Info = styled(Box)<InfoProps>`
  ${({ theme, hasDeposit }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    ${hasDeposit &&
    css`
      background: ${alpha(theme.palette.primary[500], 0.2)};
      box-shadow: 0px 0px 12px ${alpha(theme.palette.common.white, 0.2)};
    `}

    ${poolCardSharedMediaQuery}
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      padding-bottom: 16px;
      padding-top: 16px;
    }
    @media only screen and (min-width: ${theme.breakpoints.values
        .md}px) and (max-width: ${theme.breakpoints.values.lg}px) {
      padding-bottom: 12px;
      padding-top: 12px;
    }
    @media only screen and (min-width: ${theme.breakpoints.values.lg}px) {
      padding-bottom: 10px;
      padding-top: 12px;
    }
  `}
`

export const InfoButtonList = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 200px;
    button {
      flex: 1;
    }
    @media only screen and (max-width: ${theme.breakpoints.values.lg}px) {
      width: unset;
    }
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 100%;
    }
  `}
`
export const InfoItemList = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-around;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 100%;
    }
  `}
`
export const InfoItem = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 4px;
    &:first-of-type {
      margin-left: 0;
    }

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      align-items: center;
      margin: 0 0 14px;
      width: 100%;
    }
  `}
`
interface InfoText {
  $light?: boolean
}
export const InfoText = styled(Typography).attrs((props) => ({
  variant: props.variant ? props.variant : 'caption2',
}))<InfoText>`
  ${({ $light }) => css`
    opacity: ${$light ? 0.5 : 1};
  `}
`

export const InfoToken = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;
    img {
      width: 36px;
      height: 36px;
    }
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      img {
        width: 24px;
        height: 24px;
      }
    }
  `}
`
export const FixedWidthBox = styled(Box)`
  ${({ theme }) => css`
    width: 55px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    line-height: 1;
    margin-right: 8px;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: unset;
    }
  `}
`
