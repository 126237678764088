import React, { ReactElement } from 'react'
import WaitForConfirmationModal from '../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import DisabledAppTokenInput from '../../../components/TokenInput/DisabledAppTokenInput'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'

interface Props {
  isOpen: boolean
}

function ClaimVePtpWaitForConfirmationModalContainer({
  isOpen,
}: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const { vePtp } = useVePtp()
  const handleCloseModal = () => {
    modalDispatch(closeModal())
  }
  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="600px"
      subtitle="Claim"
    >
      <DisabledAppTokenInput
        tokenItems={[
          { value: vePtp.pendingAmount, tokenSymbol: TokenSymbol.VEPTP },
        ]}
      />
    </WaitForConfirmationModal>
  )
}

export default ClaimVePtpWaitForConfirmationModalContainer
