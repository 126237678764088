import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import ItemSelectionModal from '../../../components/Modal/ItemSelectionModal/ItemSelectionModal'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { dateFormat } from '../../../utils/common'

interface Props {
  isOpen: boolean
}

/**@todo replace with actual data */
const feeSharingData = [
  {
    id: '1',
    tokenSymbol: TokenSymbol.USDC,
    claimableAmount: '123',
    lastUpdate: 1684221195,
    isClaimed: false,
  },
  {
    id: '2',
    tokenSymbol: TokenSymbol.USDT,
    claimableAmount: '13',
    lastUpdate: 1684221195,
    isClaimed: true,
  },
]
const totalClaimableSharingFee = '12323'

function ClaimSharingFeeModal({ isOpen }: Props): ReactElement {
  const hasClaimableSharingFee = feeSharingData.length > 0

  return (
    <ItemSelectionModal
      width="400px"
      isOpen={isOpen}
      topBarLabel="Fee Sharing"
      topBarSubtitle={`Total available: ${
        feeSharingData.length
      }, Total value: ${getCommifiedFormat(
        strToWad(totalClaimableSharingFee),
      )}`}
    >
      {hasClaimableSharingFee &&
        feeSharingData.map((item) => {
          return (
            <ItemSelectionModal.Item
              key={item.id}
              id={item.id}
              style={item.isClaimed ? { opacity: '0.5' } : { opacity: '1' }}
            >
              <ItemSelectionModal.ItemLeadingLabel>
                <Box display="flex" alignItems="center">
                  <TokenIcon
                    tokenSymbol={item.tokenSymbol}
                    size={24}
                    margin="0 8px"
                  />
                  <Box display="flex" flexDirection="column">
                    <AppTypography variant="body1">
                      {item.tokenSymbol}
                    </AppTypography>
                    <AppTypography variant="caption2" transparent>
                      Update: {dateFormat(item.lastUpdate * 1000, true, true)}
                    </AppTypography>
                  </Box>
                </Box>
              </ItemSelectionModal.ItemLeadingLabel>
              <ItemSelectionModal.ItemTrailingLabel>
                <Box display="flex" flexDirection="column">
                  <AppTypography>
                    {getCommifiedFormat(strToWad(item.claimableAmount))}
                  </AppTypography>
                  {item.isClaimed && (
                    <AppTypography variant="caption2">Claimed</AppTypography>
                  )}
                </Box>
              </ItemSelectionModal.ItemTrailingLabel>
            </ItemSelectionModal.Item>
          )
        })}
      <Box padding="16px">
        <AppButton fullWidth>Claim All</AppButton>
      </Box>
    </ItemSelectionModal>
  )
}

export default ClaimSharingFeeModal
