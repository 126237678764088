export const PRICE_ORACLE_GETTER_ABI = [
  {
    inputs: [
      {
        internalType: 'address[]',
        name: '_assets',
        type: 'address[]',
      },
      {
        internalType: 'address[]',
        name: '_sources',
        type: 'address[]',
      },
      {
        internalType: 'address',
        name: '_fallbackOracle',
        type: 'address',
      },
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    constant: true,
    inputs: [
      {
        internalType: 'address',
        name: '_asset',
        type: 'address',
      },
    ],
    name: 'getAssetPrice',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
] as const
