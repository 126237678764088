import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../contexts/ModalContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import useBreakpoints from '../../../hooks/useBreakpoints'
import useCountdown from '../../../hooks/useCountDown'
import useLockTimeLeft from '../../../hooks/vePtp/locking/useLockTimeLeft'
import { ModalId } from '../../../interfaces/Modal'
import { dateFormat } from '../../../utils/common'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import { InfoContainer } from '../VePtpContainer.elements'
import {
  PtpLockingInfoItem,
  StyledButton,
  UnlockProgress,
  UnlockProgressWrapper,
} from './PtpLockingInfo.elements'

function PtpLockingInfo(): ReactElement {
  const { ptp } = useVePtp()
  const { isTabletSm } = useBreakpoints()
  const { account } = useNetwork()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()

  const hasLockedPtp = ptp.hasLocked
  const unlockTime = ptp.lockTime.unlockTimestamp
  const { countdownData } = useCountdown({
    targetTimestampInMilliseconds: unlockTime ? unlockTime * 1000 : 0,
  })
  const initialLockTime = ptp.lockTime.initialTimestamp
  const { percentageLeft } = useLockTimeLeft({
    unlockTimeInMilliseconds: unlockTime ? unlockTime * 1000 : 0,
    initialLockTimeInMilliseconds: initialLockTime ? initialLockTime * 1000 : 0,
  })
  const unlockable = hasLockedPtp && percentageLeft <= 0

  return (
    <>
      <InfoContainer $borderRoundPosition={hasLockedPtp ? 'top' : undefined}>
        <PtpLockingInfoItem flexDirection="row" alignItems="center">
          <TokenIcon tokenSymbol={TokenSymbol.PTP} size={40} />
          <PtpLockingInfoItem
            flexDirection="column"
            sx={{ marginLeft: '8px' }}
            alignItems="flex-start"
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              minWidth={isTabletSm ? '100px' : 'unset'}
            >
              <AppTypography variant="subtitle1" transparent>
                Lockable
              </AppTypography>
              <InfoBox.Tooltip text="The amount of PTP you can lock to earn vePTP instantly." />
            </Box>
            <Box display="flex">
              <AppTypography variant="h6">
                <TooltipNum amount={ptp.balance} rightSymbol={TokenSymbol.PTP}>
                  {showDashIfNecessary(
                    !account,
                    getCommifiedFormat(ptp.balance),
                  )}{' '}
                </TooltipNum>
              </AppTypography>
              <AppTypography variant="h6">
                &nbsp;{TokenSymbol.PTP}
              </AppTypography>
            </Box>
          </PtpLockingInfoItem>
        </PtpLockingInfoItem>
        <PtpLockingInfoItem
          flexDirection="column"
          sx={{ textAlign: 'center' }}
          alignItems="center"
        >
          <AppButton
            style={{ minWidth: '200px' }}
            onClick={() => modalDispatch(openModal(ModalId.LOCK_PTP))}
            disabled={!account || unlockable}
          >
            {hasLockedPtp ? 'Lock More' : ' Lock to get vePTP'}
          </AppButton>
        </PtpLockingInfoItem>
      </InfoContainer>
      {hasLockedPtp && (
        <InfoContainer
          marginTop="2px"
          $borderRoundPosition={hasLockedPtp ? 'bottom' : undefined}
        >
          <PtpLockingInfoItem flexDirection="row" alignItems="center">
            <PtpLockingInfoItem flexDirection="column" alignItems="center">
              <Box display="flex" flexDirection="row" alignItems="center">
                <AppTypography variant="subtitle1" transparent>
                  Locked
                </AppTypography>
                <InfoBox.Tooltip text="The amount of PTP you have locked to earn vePTP." />
              </Box>
              <Box display="flex">
                <AppTypography variant="h6">
                  <TooltipNum
                    amount={ptp.amount.locked}
                    rightSymbol={TokenSymbol.PTP}
                  >
                    {showDashIfNecessary(
                      !account,
                      getCommifiedFormat(ptp.amount.locked),
                    )}{' '}
                  </TooltipNum>
                </AppTypography>
                <AppTypography variant="h6">
                  &nbsp;{TokenSymbol.PTP}
                </AppTypography>
              </Box>

              {unlockable ? (
                <StyledButton
                  customVariant="neutral"
                  onClick={() =>
                    modalDispatch(openModal(ModalId.CONFIRM_UNLOCK_PTP))
                  }
                >
                  <AppTypography variant="caption3">Unlock</AppTypography>
                </StyledButton>
              ) : (
                <UnlockProgressWrapper>
                  <AppTypography variant="caption3">
                    {countdownData.map((item) => {
                      return `${item.value}${item.label} `
                    })}{' '}
                    to unlock
                  </AppTypography>
                  <UnlockProgress
                    variant="determinate"
                    value={percentageLeft}
                  />
                </UnlockProgressWrapper>
              )}
            </PtpLockingInfoItem>
          </PtpLockingInfoItem>
          <PtpLockingInfoItem
            flexDirection="column"
            sx={{ textAlign: 'center' }}
            alignItems="center"
          >
            <AppTypography variant="caption3" transparent>
              Unlock on {dateFormat(unlockTime * 1000, true, true)}
            </AppTypography>
            <AppButton
              customVariant="secondary"
              style={{ minWidth: '200px' }}
              disabled={!account}
              onClick={() => modalDispatch(openModal(ModalId.EXTEND_LOCK_PTP))}
            >
              Extend to get more
            </AppButton>
          </PtpLockingInfoItem>
        </InfoContainer>
      )}
    </>
  )
}

export default PtpLockingInfo
