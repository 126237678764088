import { PoolFilterSymbol } from '../../config/contracts/pool/poolSymbol'
import AVAX from './filteredPool-avax.svg'
import BTC from './filteredPool-btc.svg'
import ETH from './filteredPool-eth.svg'
import USD from './filteredPool-usd.svg'

export const filterPoolIconPaths: { [id in PoolFilterSymbol]: string } = {
  [PoolFilterSymbol.AVAX]: AVAX,
  [PoolFilterSymbol.USD]: USD,
  [PoolFilterSymbol.BTC]: BTC,
  [PoolFilterSymbol.ETH]: ETH,
}
