import styled, { css } from 'styled-components'
import { getLottieScaledHeight, getLottieScaledWidth } from './Lottie'

interface ContainerProps {
  windowWidth: number
  top?: number | undefined
  right?: number | undefined
  bottom?: number | undefined
  left?: number | undefined
  zIndex?: number | undefined
}

export const Container = styled.div<ContainerProps>`
  transform: unset;
  position: absolute;
  ${({ zIndex }) =>
    zIndex != null
      ? css`
          z-index: ${zIndex};
        `
      : ''}
  ${({ windowWidth, top }) =>
    top != null
      ? css`
          top: ${getLottieScaledHeight(windowWidth, top)}px;
        `
      : ''}
  ${({ windowWidth, right }) =>
    right != null
      ? css`
          right: ${getLottieScaledWidth(windowWidth, right)}px;
        `
      : ''}
    ${({ windowWidth, bottom }) =>
    bottom != null
      ? css`
          bottom: ${getLottieScaledHeight(windowWidth, bottom)}px;
        `
      : ''}
      ${({ windowWidth, left }) =>
    left != null
      ? css`
          left: ${getLottieScaledHeight(windowWidth, left)}px;
        `
      : ''}
`
