import { useCallback, useMemo } from 'react'
import { WalletId, WALLETS } from '../../config/wallet'
import { useModal } from '../../contexts/ModalContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'

interface ReturnType {
  connectWallet: (walletId?: WalletId) => void
  disconnectWallet: () => void
  connectedWalletId: WalletId | null
  openConnectToWalletModal: () => void
}

/**
 * @TODO currently there is a bug, if there are multiple injected wallet
 * available. Check PR 904 for more information.
 */
function useWalletConnection(): ReturnType {
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { connect, disconnect, activeWalletId } = useWeb3()

  const connectedWalletId = useMemo<WalletId | null>(() => {
    return activeWalletId || null
  }, [activeWalletId])
  const connectWallet = useCallback(
    (walletId?: WalletId) => {
      if (walletId) {
        connect(WALLETS[walletId])
      }
    },
    [connect],
  )

  const disconnectWallet = useCallback(() => {
    disconnect()
  }, [disconnect])

  const openConnectToWalletModal = useCallback(() => {
    modalDispatch(openModal(ModalId.CONNECT_TO_WALLET))
  }, [modalDispatch, openModal])

  return {
    connectWallet,
    disconnectWallet,
    connectedWalletId,
    openConnectToWalletModal,
  }
}

export default useWalletConnection
