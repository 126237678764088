import { Call } from 'ethcall'
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { usePoller } from '../../hooks/usePoller'
import {
  executeCallBacks,
  MulticallHelperSCReturnType,
} from '../../utils/multicall'
import { useNetwork } from '../NetworkContext'
import {
  BalanceDataType,
  fetchBalanceData,
  fetchPoolData,
  fetchSCPriceData,
  fetchStakeLpData,
  fetchTpYieldData,
  fetchVeptpData,
  fetchVotingData,
  PoolDataType,
  StakeLpDataType,
  StakeLpDataWithAccountType,
  TpYieldDataType,
  VePtpWithAccountType,
  VePtpWithoutAccountType,
  VotingDataType,
  VotingDataWithAccountType,
} from './helpers'
import { SCPriceWithoutAccountType } from './helpers/fetchSCPriceDataHelper'
import {
  UspDataWithAccountType,
  UspDataWithoutAccountType,
} from './helpers/fetchUspDataHelper'

export interface ContextType {
  stakeLpData: {
    withoutAccount?: StakeLpDataType
    withAccount?: StakeLpDataWithAccountType
  }
  votingData: {
    withoutAccount?: VotingDataType
    withAccount?: VotingDataWithAccountType
  }
  balanceData?: BalanceDataType
  poolData?: PoolDataType
  tpYieldData?: TpYieldDataType
  isMulticallDataFetched: boolean
  isMulticallDataFetchedWithAccount: boolean
  veptpData: {
    withoutAccount?: VePtpWithoutAccountType
    withAccount?: VePtpWithAccountType
  }
  uspData: {
    withoutAccount?: UspDataWithoutAccountType
    withAccount?: UspDataWithAccountType
  }
  scPriceData: {
    withoutAccount?: SCPriceWithoutAccountType
  }
}

interface Props {
  children: React.ReactNode
}

export const MulticallDataContext = createContext<ContextType>({
  stakeLpData: {
    withoutAccount: undefined,
    withAccount: undefined,
  },
  votingData: {
    withoutAccount: undefined,
    withAccount: undefined,
  },
  balanceData: undefined,
  poolData: undefined,
  tpYieldData: undefined,
  isMulticallDataFetched: false,
  isMulticallDataFetchedWithAccount: false,
  veptpData: {
    withoutAccount: undefined,
    withAccount: undefined,
  },
  uspData: {
    withoutAccount: undefined,
    withAccount: undefined,
  },
  scPriceData: {
    withoutAccount: undefined,
  },
})
MulticallDataContext.displayName = 'MulticallDataContext'

export const useMulticallData = (): ContextType => {
  return useContext(MulticallDataContext)
}

export const MulticallDataProvider = ({ children }: Props): ReactElement => {
  const { account, multicallProvider, chainId } = useNetwork()
  const [balanceData, setBalanceData] = useState<BalanceDataType | undefined>(
    undefined,
  )
  const [stakeLpData, setStakeLpData] = useState<StakeLpDataType | undefined>(
    undefined,
  )
  const [stakeLpDataWithAccount, setStakeLpDataWithAccount] = useState<
    StakeLpDataWithAccountType | undefined
  >(undefined)

  const [votingData, setVotingData] = useState<VotingDataType | undefined>(
    undefined,
  )
  const [votingDataWithAccount, setVotingDataWithAccount] = useState<
    VotingDataWithAccountType | undefined
  >(undefined)

  const [veptpWithAccount, setVePtpDataWithAccount] = useState<
    VePtpWithAccountType | undefined
  >(undefined)

  const [veptpWithoutAccount, setVePtpDataWithoutAccount] = useState<
    VePtpWithoutAccountType | undefined
  >(undefined)
  const [scPriceDataWithoutAccount, setSCPriceDataWithoutAccount] = useState<
    SCPriceWithoutAccountType | undefined
  >(undefined)

  const [uspWithAccount, setUspDataWithAccount] = useState<
    UspDataWithAccountType | undefined
  >(undefined)

  const [uspWithoutAccount, setUspDataWithoutAccount] = useState<
    UspDataWithoutAccountType | undefined
  >(undefined)

  const [
    isMulticallDataFetchedWithAccount,
    setIsMulticallDataFetchedWithAccount,
  ] = useState<boolean>(false)

  const [poolData, setPoolData] = useState<PoolDataType | undefined>(undefined)

  const [tpYieldData, setTpYieldData] = useState<TpYieldDataType | undefined>(
    undefined,
  )
  const [isMulticallDataFetched, setIsMulticallDataFetched] =
    useState<boolean>(false)
  const fetchMulticallData = async () => {
    /**
     * C. Pushing call and callback
     */
    const {
      contractCalls: stakeLpDataContractCalls,
      callbacks: stakeLpDataCallbacks,
      states: stakeLpDataStates,
    } = fetchStakeLpData(chainId, account)
    const {
      contractCalls: balanceDataContractCalls,
      callbacks: balanceDataCallbacks,
      states: balanceDataStates,
    } = fetchBalanceData(chainId, account)

    const {
      contractCalls: poolDataContractCalls,
      callbacks: poolDataCallbacks,
      states: poolDataStates,
    } = fetchPoolData(chainId)

    const {
      contractCalls: tpYieldDataContractCalls,
      callbacks: tpYieldDataCallbacks,
      states: tpYieldDataStates,
    } = fetchTpYieldData(chainId)

    const {
      contractCalls: votingDataContractCalls,
      callbacks: votingDataCallbacks,
      states: votingDataStates,
    } = fetchVotingData(chainId, account)

    const {
      contractCalls: veptpContractCalls,
      callbacks: veptpDataCallbacks,
      states: veptpDataStates,
    } = fetchVeptpData(chainId, account)

    const {
      contractCalls: scPriceDataContractCalls,
      callbacks: scPriceDataCallbacks,
      states: scPriceDataStates,
    } = fetchSCPriceData(chainId)
    const valueBNs =
      await multicallProvider.tryAll<MulticallHelperSCReturnType>([
        ...stakeLpDataContractCalls,
        ...balanceDataContractCalls,
        ...poolDataContractCalls,
        ...tpYieldDataContractCalls,
        ...votingDataContractCalls,
        ...veptpContractCalls,
        ...scPriceDataContractCalls,
      ] as unknown as Call[])
    executeCallBacks(valueBNs, [
      ...stakeLpDataCallbacks,
      ...balanceDataCallbacks,
      ...poolDataCallbacks,
      ...tpYieldDataCallbacks,
      ...votingDataCallbacks,
      ...veptpDataCallbacks,
      ...scPriceDataCallbacks,
    ])
    /**
     * D. Set data states
     */

    setPoolData({
      ...poolDataStates,
    })
    setTpYieldData({
      ...tpYieldDataStates,
    })
    setStakeLpData({
      ...stakeLpDataStates.withoutAccount,
    })
    setVotingData({
      ...votingDataStates.withoutAccount,
    })
    setVePtpDataWithoutAccount({
      ...veptpDataStates.withoutAccount,
    })

    setSCPriceDataWithoutAccount({
      ...scPriceDataStates.withoutAccount,
    })
    if (account) {
      setIsMulticallDataFetchedWithAccount(true)
      setBalanceData({
        ...balanceDataStates,
      })
      setStakeLpDataWithAccount({
        ...stakeLpDataStates.withAccount,
      })
      setVotingDataWithAccount({
        ...votingDataStates.withAccount,
      })
      setVePtpDataWithAccount({
        ...veptpDataStates.withAccount,
      })
    }
    setIsMulticallDataFetched(true)
  }
  // when chainId and account changed, show dash
  useEffect(() => {
    setIsMulticallDataFetchedWithAccount(false)
    setBalanceData(undefined)
    setStakeLpData(undefined)
    setStakeLpDataWithAccount(undefined)
    setVotingData(undefined)
    setVotingDataWithAccount(undefined)
    setPoolData(undefined)
    setTpYieldData(undefined)
    setIsMulticallDataFetched(false)
    setVePtpDataWithoutAccount(undefined)
    setVePtpDataWithAccount(undefined)
    setUspDataWithoutAccount(undefined)
    setUspDataWithAccount(undefined)
    setSCPriceDataWithoutAccount(undefined)
  }, [chainId, account])

  usePoller(() => {
    void fetchMulticallData().catch((err) => console.error(err))
  }, [account, chainId])

  return (
    <MulticallDataContext.Provider
      value={{
        votingData: {
          withAccount: votingDataWithAccount,
          withoutAccount: votingData,
        },
        stakeLpData: {
          withAccount: stakeLpDataWithAccount,
          withoutAccount: stakeLpData,
        },
        balanceData,
        poolData,
        tpYieldData,
        isMulticallDataFetched,
        isMulticallDataFetchedWithAccount,
        veptpData: {
          withAccount: veptpWithAccount,
          withoutAccount: veptpWithoutAccount,
        },
        uspData: {
          withAccount: uspWithAccount,
          withoutAccount: uspWithoutAccount,
        },
        scPriceData: {
          withoutAccount: scPriceDataWithoutAccount,
        },
      }}
    >
      {children}
    </MulticallDataContext.Provider>
  )
}
