import styled, { css } from 'styled-components'
import AppButton from '../../../components/buttons/AppButton/AppButton'

interface StyledAppButtonProps {
  $small?: boolean
}
export const StyledAppButton = styled(AppButton)<StyledAppButtonProps>`
  ${({ $small }: StyledAppButtonProps) => css`
    margin-left: 0;
    margin-right: 0;
    ${$small &&
    css`
      font-size: 14px;
    `}
  `}
`
