import { isParsableString, strToWad } from '@hailstonelabs/big-number-utils'
import { ReactNode, useCallback, useEffect, useState } from 'react'

export type SortingState = {
  columnIndex: number
  ascending?: boolean
}

interface Config {
  sortBy?: string | number
  cell: ReactNode
}
export type TableData = {
  id: string | number
  configs: Config[]
}[]
interface Table {
  tableData: TableData
  options?: {
    sortingState?: SortingState
  }
}

interface ReturnType {
  tableData: TableData
}
function useTable({ tableData, options }: Table): ReturnType {
  const [processedTableData, setProcessedTableData] = useState<TableData>([])
  const sorting = useCallback(
    ({ columnIndex, ascending }: SortingState) => {
      // check if the target column is existed
      if (!tableData[0]?.configs[columnIndex]) {
        return
      }
      const sortedTableData = [...tableData].sort((aRow, bRow) => {
        const aRowValueForSorting =
          aRow.configs[columnIndex]?.sortBy?.toString() || ''
        const bRowValueForSorting =
          bRow.configs[columnIndex]?.sortBy?.toString() || ''
        /**
         * For numbers
         */
        if (
          isParsableString(aRowValueForSorting, 18, false) &&
          isParsableString(bRowValueForSorting, 18, false)
        ) {
          const aRowTargetColWad = strToWad(aRowValueForSorting)
          const bRowTargetColWad = strToWad(bRowValueForSorting)

          if (aRowTargetColWad.gt(bRowTargetColWad)) {
            return ascending ? 1 : -1
          } else if (aRowTargetColWad.lt(bRowTargetColWad)) {
            return ascending ? -1 : 1
          } else {
            return 0
          }
        }
        /**
         * For strings
         */
        return ascending
          ? aRowValueForSorting.localeCompare(bRowValueForSorting)
          : aRowValueForSorting.localeCompare(bRowValueForSorting) * -1
      })
      setProcessedTableData(sortedTableData)
    },
    [tableData],
  )
  useEffect(() => {
    if (options?.sortingState) {
      sorting(options.sortingState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options?.sortingState?.columnIndex,
    options?.sortingState?.ascending,
    sorting,
  ])

  return {
    tableData: processedTableData.length > 0 ? processedTableData : tableData,
  }
}

export default useTable
