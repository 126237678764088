import { alpha, Box } from '@mui/material'
import styled, { css, keyframes } from 'styled-components'
import AppButton from '../../buttons/AppButton/AppButton'

export const Container = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    background: radial-gradient(
        40.36% 9.4% at 50% 100%,
        rgba(0, 80, 255, 0.5) 0%,
        rgba(4, 54, 163, 0.255) 73.81%,
        rgba(6, 45, 130, 0) 100%
      ),
      radial-gradient(
        24.05% 8.06% at 50% 0%,
        rgba(50, 255, 68, 0.25) 0%,
        rgba(50, 255, 68, 0.15) 34.22%,
        rgba(12, 88, 255, 0) 100%
      ),
      radial-gradient(
        50% 7.98% at 50% 0%,
        rgba(0, 80, 255, 0.5) 0%,
        rgba(6, 45, 130, 0.255) 78.5%,
        rgba(6, 45, 130, 0) 100%
      ),
      linear-gradient(119.51deg, #0e0f28 19.73%, #000113 100%);
    box-shadow: 0px 0px 12px ${alpha(theme.palette.common.white, 0.3)};
    border-radius: ${theme.borderRadius.md};
    margin-bottom: 24px;
    width: 100%;
    overflow: hidden;
    padding: 20px;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 16px;
    }
  `}
`
interface ItemProps {
  hasDeposit?: boolean
}
export const Item = styled(Box)<ItemProps>`
  ${({ theme, hasDeposit }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${alpha(theme.palette.primary[900], 0.7)};
    box-shadow: 0px 0px 12px ${alpha(theme.palette.common.white, 0.3)};
    border-radius: 10px;
    padding: 12px 8px;
    width: 100%;

    .CardItem__text {
      margin-right: 12px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    ${hasDeposit &&
    css`
      background: ${alpha(theme.palette.primary[500], 0.2)};
    `}
  `}
`

interface ItemListProps {
  column?: boolean
}
export const ItemList = styled(Box)<ItemListProps>`
  ${({ column }: ItemListProps) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    width: 100%;
    padding: 0 14px;
    grid-gap: 12px;

    ${column &&
    css`
      display: flex;
      flex-direction: column;
      margin: 24px 0 0;
      padding: 0;
    `}
  `}
`

interface ToggleDetailsButtonProps {
  $active?: boolean
  disabled?: boolean
}

const animationDuration = '0.3s'

const rotate = keyframes`
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(0);
  }

`
export const ToggleDetailsButton = styled(AppButton)<ToggleDetailsButtonProps>`
  ${({ $active, theme }) => css`
    border-radius: 999px;
    margin: 0;
    min-width: 100px;
    padding: 2px 0;
    transition: all ${animationDuration};
    &:disabled {
      border-radius: 999px;
    }

    svg {
      font-size: 20px;
    }

    .ToggleDetailsButton__close-icon {
      transition: all ${animationDuration};
      font-size: 18px;
    }
    ${$active &&
    css`
      .ToggleDetailsButton__close-icon {
        animation: ${rotate} ${animationDuration} forwards;
      }
    `}

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      min-width: 24px;
      min-height: 24px;
    }
  `}
`

export const Wrapper = styled(Box)`
  ${() => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`
