import { alpha, useTheme } from '@mui/material'
import React from 'react'
import Modal from '../../../components/Modal/Modal'
import { WalletId, WALLETS } from '../../../config/wallet'
import { useModal } from '../../../contexts/ModalContext'
import { ScrollableBox } from '../../../globalStyles'
import useWalletConnection from '../../../hooks/wallet/useWalletConnection'
import useWalletFilterList from '../../../hooks/wallet/useWalletFilterList'
import {
  WalletItemContainer,
  WalletLabel,
} from './ConnectToWalletModalContainer.elements'
interface Props {
  isOpen: boolean
}

function ConnectToWalletModalContainer({ isOpen }: Props): React.ReactElement {
  const { connectWallet } = useWalletConnection()
  const { supportedWalletIds } = useWalletFilterList()
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        modalDispatch(closeModal())
      }}
      topBarLabel="Connect Wallet"
      width="600px"
      disableCloseBtn
    >
      <ScrollableBox>
        {supportedWalletIds.map((walletId: WalletId) => (
          <WalletItem
            key={walletId}
            onClick={() => {
              connectWallet(walletId)
              walletId === WalletId.WALLETCONNECT ? (
                modalDispatch(closeModal())
              ) : (
                <></>
              )
            }}
            walletId={walletId}
            iconPath={WALLETS[walletId].icon}
          />
        ))}
      </ScrollableBox>
    </Modal>
  )
}

export default ConnectToWalletModalContainer
interface WalletItemProps {
  iconPath?: string
  iconWidth?: number
  walletId: WalletId
  onClick: (wallet: WalletId) => void
  active?: boolean
}
export const WalletItem = ({
  iconPath,
  iconWidth = 28,
  walletId,
  onClick,
}: WalletItemProps): React.ReactElement => {
  const theme = useTheme()
  const handleClickWallet = () => {
    onClick(walletId)
  }
  const walletName = WALLETS[walletId].name
  return (
    <WalletItemContainer
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={alpha(theme.palette.primary[500], 0.2)}
      onClick={handleClickWallet}
    >
      <WalletLabel variant="body1">{walletName}</WalletLabel>
      {iconPath && <img src={iconPath} alt={walletName} width={iconWidth} />}
    </WalletItemContainer>
  )
}
