export const PLATYPUS_TREASURE_ABI = [
  {
    inputs: [
      { internalType: 'uint256', name: 'currentPrice', type: 'uint256' },
    ],
    name: 'Liquidation_Exceed_Max_Price',
    type: 'error',
  },
  { inputs: [], name: 'Liquidation_Invalid_Auction_Id', type: 'error' },
  {
    inputs: [],
    name: 'Liquidation_Liquidator_Should_Take_All_Collateral',
    type: 'error',
  },
  { inputs: [], name: 'Liquidation_Paused', type: 'error' },
  {
    inputs: [],
    name: 'PlatypusTreasure_BorrowDisallowed',
    type: 'error',
  },
  { inputs: [], name: 'PlatypusTreasure_BorrowPaused', type: 'error' },
  { inputs: [], name: 'PlatypusTreasure_ExceedCap', type: 'error' },
  {
    inputs: [],
    name: 'PlatypusTreasure_ExceedCollateralFactor',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PlatypusTreasure_ExceedHalfRepayLimit',
    type: 'error',
  },
  { inputs: [], name: 'PlatypusTreasure_InvalidAmount', type: 'error' },
  {
    inputs: [],
    name: 'PlatypusTreasure_InvalidMarketSetting',
    type: 'error',
  },
  {
    inputs: [],
    name: 'PlatypusTreasure_InvalidMasterPlatypus',
    type: 'error',
  },
  { inputs: [], name: 'PlatypusTreasure_InvalidPid', type: 'error' },
  { inputs: [], name: 'PlatypusTreasure_InvalidRatio', type: 'error' },
  { inputs: [], name: 'PlatypusTreasure_InvalidToken', type: 'error' },
  {
    inputs: [],
    name: 'PlatypusTreasure_MinimumBorrowAmountNotSatisfied',
    type: 'error',
  },
  { inputs: [], name: 'PlatypusTreasure_NotLiquidable', type: 'error' },
  { inputs: [], name: 'PlatypusTreasure_ZeroAddress', type: 'error' },
  { inputs: [], name: 'Uint112_Overflow', type: 'error' },
  { inputs: [], name: 'Uint128_Overflow', type: 'error' },
  { inputs: [], name: 'Uint96_Overflow', type: 'error' },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'interest',
        type: 'uint256',
      },
    ],
    name: 'Accrue',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'contract ERC20',
        name: 'token',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'AddCollateral',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'contract ERC20',
        name: 'token',
        type: 'address',
      },
    ],
    name: 'AddCollateralToken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'contract ERC20',
        name: 'token',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'BadDebt',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'contract ERC20',
        name: 'collateral',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'uspAmount',
        type: 'uint256',
      },
    ],
    name: 'Borrow',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'contract ERC20',
        name: 'token',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'collateralAmount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'uspAmount',
        type: 'uint256',
      },
    ],
    name: 'BuyCollateral',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'contract ERC20',
        name: 'token',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'RemoveCollateral',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'contract ERC20',
        name: 'collateral',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'uspAmount',
        type: 'uint256',
      },
    ],
    name: 'Repay',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'contract ERC20',
        name: 'token',
        type: 'address',
      },
      {
        components: [
          { internalType: 'uint40', name: 'borrowCap', type: 'uint40' },
          {
            internalType: 'uint16',
            name: 'collateralFactor',
            type: 'uint16',
          },
          { internalType: 'uint16', name: 'borrowFee', type: 'uint16' },
          { internalType: 'bool', name: 'isStable', type: 'bool' },
          {
            internalType: 'uint16',
            name: 'liquidationThreshold',
            type: 'uint16',
          },
          {
            internalType: 'uint16',
            name: 'liquidationPenalty',
            type: 'uint16',
          },
          { internalType: 'uint16', name: 'auctionStep', type: 'uint16' },
          {
            internalType: 'uint16',
            name: 'auctionFactor',
            type: 'uint16',
          },
          {
            internalType: 'bool',
            name: 'liquidationPaused',
            type: 'bool',
          },
          { internalType: 'uint8', name: 'decimals', type: 'uint8' },
          { internalType: 'bool', name: 'isLp', type: 'bool' },
          {
            internalType: 'uint128',
            name: 'borrowedShare',
            type: 'uint128',
          },
          {
            internalType: 'contract IMasterPlatypusV4',
            name: 'masterPlatypus',
            type: 'address',
          },
          { internalType: 'uint8', name: 'pid', type: 'uint8' },
        ],
        indexed: false,
        internalType: 'struct PlatypusTreasure.CollateralSetting',
        name: 'setting',
        type: 'tuple',
      },
    ],
    name: 'SetCollateralToken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'contract ERC20',
        name: 'token',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'collateralAmount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'uspAmount',
        type: 'uint256',
      },
    ],
    name: 'StartAuction',
    type: 'event',
  },
  {
    inputs: [],
    name: 'activeAuctionLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'activeAuctions',
    outputs: [{ internalType: 'uint48', name: '', type: 'uint48' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
    ],
    name: 'addCollateral',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'auctions',
    outputs: [
      { internalType: 'uint128', name: 'uspAmount', type: 'uint128' },
      {
        internalType: 'uint128',
        name: 'collateralAmount',
        type: 'uint128',
      },
      { internalType: 'contract ERC20', name: 'token', type: 'address' },
      { internalType: 'uint48', name: 'index', type: 'uint48' },
      { internalType: 'uint40', name: 'startTime', type: 'uint40' },
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'uint96', name: 'startPrice', type: 'uint96' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
    ],
    name: 'availableUSP',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
      { internalType: 'uint256', name: '_borrowAmount', type: 'uint256' },
    ],
    name: 'borrow',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'id', type: 'uint256' },
      {
        internalType: 'uint256',
        name: 'maxCollateralAmount',
        type: 'uint256',
      },
      { internalType: 'uint256', name: 'maxPrice', type: 'uint256' },
      { internalType: 'address', name: 'who', type: 'address' },
      { internalType: 'bytes', name: 'data', type: 'bytes' },
    ],
    name: 'buyCollateral',
    outputs: [
      {
        internalType: 'uint256',
        name: 'bidCollateralAmount',
        type: 'uint256',
      },
      { internalType: 'uint256', name: 'uspAmount', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
      { internalType: 'uint256', name: '_startPrice', type: 'uint256' },
      { internalType: 'uint256', name: '_timeElapsed', type: 'uint256' },
    ],
    name: 'calculatePrice',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract ERC20', name: '', type: 'address' }],
    name: 'collateralSettings',
    outputs: [
      { internalType: 'uint40', name: 'borrowCap', type: 'uint40' },
      {
        internalType: 'uint16',
        name: 'collateralFactor',
        type: 'uint16',
      },
      { internalType: 'uint16', name: 'borrowFee', type: 'uint16' },
      { internalType: 'bool', name: 'isStable', type: 'bool' },
      {
        internalType: 'uint16',
        name: 'liquidationThreshold',
        type: 'uint16',
      },
      {
        internalType: 'uint16',
        name: 'liquidationPenalty',
        type: 'uint16',
      },
      { internalType: 'uint16', name: 'auctionStep', type: 'uint16' },
      { internalType: 'uint16', name: 'auctionFactor', type: 'uint16' },
      { internalType: 'bool', name: 'liquidationPaused', type: 'bool' },
      { internalType: 'uint8', name: 'decimals', type: 'uint8' },
      { internalType: 'bool', name: 'isLp', type: 'bool' },
      { internalType: 'uint128', name: 'borrowedShare', type: 'uint128' },
      {
        internalType: 'contract IMasterPlatypusV4',
        name: 'masterPlatypus',
        type: 'address',
      },
      { internalType: 'uint8', name: 'pid', type: 'uint8' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'collateralTokens',
    outputs: [{ internalType: 'contract ERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'collateralTokensLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'collectFee',
    outputs: [
      { internalType: 'uint256', name: 'feeCollected', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint8[]', name: 'actions', type: 'uint8[]' },
      { internalType: 'bytes[]', name: 'datas', type: 'bytes[]' },
    ],
    name: 'cook',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'currentInterestRate',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'feeTo',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint128', name: '_amount', type: 'uint128' }],
    name: 'fillUnbackedUsp',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getActiveAuctions',
    outputs: [{ internalType: 'uint48[]', name: '', type: 'uint48[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCollateralTokens',
    outputs: [
      { internalType: 'contract ERC20[]', name: '', type: 'address[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract IAsset', name: '_lp', type: 'address' }],
    name: 'getLPUnitPrice',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IUSP', name: '_usp', type: 'address' },
      {
        internalType: 'contract IPriceOracleGetter',
        name: '_oracle',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'contract IAsset',
            name: 'uspLp',
            type: 'address',
          },
          {
            internalType: 'uint32',
            name: 'mininumBorrowAmount',
            type: 'uint32',
          },
          { internalType: 'uint8', name: 'k', type: 'uint8' },
          { internalType: 'bool', name: 'borrowPaused', type: 'bool' },
          {
            internalType: 'bool',
            name: 'liquidationPaused',
            type: 'bool',
          },
          {
            internalType: 'uint16',
            name: 'kickIncentive',
            type: 'uint16',
          },
        ],
        internalType: 'struct PlatypusTreasure.MarketSetting',
        name: '_marketSetting',
        type: 'tuple',
      },
      { internalType: 'address', name: '_feeTo', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_user', type: 'address' },
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
      { internalType: 'bool', name: '_open', type: 'bool' },
    ],
    name: 'isSolvent',
    outputs: [
      { internalType: 'bool', name: 'solvent', type: 'bool' },
      { internalType: 'uint256', name: 'debtAmount', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'lastAccrued',
    outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'marketSetting',
    outputs: [
      { internalType: 'contract IAsset', name: 'uspLp', type: 'address' },
      {
        internalType: 'uint32',
        name: 'mininumBorrowAmount',
        type: 'uint32',
      },
      { internalType: 'uint8', name: 'k', type: 'uint8' },
      { internalType: 'bool', name: 'borrowPaused', type: 'bool' },
      { internalType: 'bool', name: 'liquidationPaused', type: 'bool' },
      { internalType: 'uint16', name: 'kickIncentive', type: 'uint16' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'oracle',
    outputs: [
      {
        internalType: 'contract IPriceOracleGetter',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pauseAllLiquidations',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pauseBorrow',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
    ],
    name: 'pauseLiquidations',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_user', type: 'address' },
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
    ],
    name: 'positionView',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'collateralAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'collateralUSD',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'borrowLimitUSP',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'liquidateLimitUSP',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'debtAmountUSP',
            type: 'uint256',
          },
          { internalType: 'uint256', name: 'debtShare', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'healthFactor',
            type: 'uint256',
          },
          { internalType: 'bool', name: 'liquidable', type: 'bool' },
        ],
        internalType: 'struct PlatypusTreasure.PositionView',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
    ],
    name: 'removeCollateral',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
      { internalType: 'uint256', name: '_repayAmount', type: 'uint256' },
    ],
    name: 'repay',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'resumeAllLiquidations',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
    ],
    name: 'resumeLiquidations',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint8', name: '_k', type: 'uint8' }],
    name: 'setInterestParam',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
      { internalType: 'uint40', name: '_borrowCap', type: 'uint40' },
      {
        internalType: 'uint16',
        name: '_collateralFactor',
        type: 'uint16',
      },
      { internalType: 'uint16', name: '_borrowFee', type: 'uint16' },
      { internalType: 'bool', name: '_isStable', type: 'bool' },
      {
        internalType: 'uint16',
        name: '_liquidationThreshold',
        type: 'uint16',
      },
      {
        internalType: 'uint16',
        name: '_liquidationPenalty',
        type: 'uint16',
      },
      { internalType: 'uint16', name: '_auctionStep', type: 'uint16' },
      { internalType: 'uint16', name: '_auctionFactor', type: 'uint16' },
      {
        internalType: 'contract IMasterPlatypusV4',
        name: '_masterPlatypus',
        type: 'address',
      },
    ],
    name: 'setLpCollateralToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint32',
        name: '_mininumBorrowAmount',
        type: 'uint32',
      },
    ],
    name: 'setMinimumBorrowAmount',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
      { internalType: 'uint40', name: '_borrowCap', type: 'uint40' },
      {
        internalType: 'uint16',
        name: '_collateralFactor',
        type: 'uint16',
      },
      { internalType: 'uint16', name: '_borrowFee', type: 'uint16' },
      { internalType: 'bool', name: '_isStable', type: 'bool' },
      {
        internalType: 'uint16',
        name: '_liquidationThreshold',
        type: 'uint16',
      },
      {
        internalType: 'uint16',
        name: '_liquidationPenalty',
        type: 'uint16',
      },
      { internalType: 'uint16', name: '_auctionStep', type: 'uint16' },
      { internalType: 'uint16', name: '_auctionFactor', type: 'uint16' },
    ],
    name: 'setRawCollateralToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint16', name: '_kickIncentive', type: 'uint16' },
    ],
    name: 'setkickIncentive',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_user', type: 'address' },
      { internalType: 'contract ERC20', name: '_token', type: 'address' },
      {
        internalType: 'address',
        name: '_incentiveReceiver',
        type: 'address',
      },
    ],
    name: 'startAuction',
    outputs: [{ internalType: 'uint256', name: 'auctionId', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalAuctions',
    outputs: [{ internalType: 'uint48', name: '', type: 'uint48' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalDebtAmount',
    outputs: [{ internalType: 'uint112', name: '', type: 'uint112' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalDebtShare',
    outputs: [{ internalType: 'uint128', name: '', type: 'uint128' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalFeeCollected',
    outputs: [{ internalType: 'uint112', name: '', type: 'uint112' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'unbackedUspAmount',
    outputs: [{ internalType: 'uint128', name: '', type: 'uint128' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'unpauseBorrow',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'usp',
    outputs: [{ internalType: 'contract IUSP', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const
