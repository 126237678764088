import { isParsableString, strToWad } from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { LP_TOKENS } from '../../../config/contracts'
import { PoolSymbol } from '../../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useVotingData } from '../../../contexts/VotingDataContext'

export const ACTIVE_INPUT_WRAPPER_CLASS_NAME =
  'UserVoteAllocationTable__input-wrapper--active'
export const ACTIVE_ROW_CLASS_NAME = 'Table__row--active'

export interface GaugeDataType {
  id?: number
  poolSymbol: PoolSymbol
  assetTokenSymbol: TokenSymbol
}

type VoteWeightPercentageInputsType = {
  [key in PoolSymbol]?: {
    [key in TokenSymbol]?: string
  }
}
interface ContextType {
  votedAndAddedGaugeList: GaugeDataType[]
  addedGaugeList: GaugeDataType[]
  votedGaugeList: GaugeDataType[]
  editedGaugeList: GaugeDataType[]
  isInEditMode: boolean
  availableVoteWeightPercentageForFocusedInput: string
  voteWeightPercentageInputs: VoteWeightPercentageInputsType
  totalVoteWeightPercentageOfTheTable: string
  addNewGauge: (gauge: GaugeDataType) => void
  removeAddedGauge: (gauge: GaugeDataType) => void
  enterEditMode: () => void
  exitEditMode: () => void
  updateAvailableVoteWeightPercentageForFocusedInput: (
    poolSymbol: PoolSymbol,
    assetTokenSymbol: TokenSymbol,
  ) => void
  updateVoteWeightPercentageInput: (
    poolSymbol: PoolSymbol,
    assetTokenSymbol: TokenSymbol,
    value: string,
  ) => void
  resetVoteWeightPercentageInputs: () => void
  clearActiveElementsStyles: () => void
}
const UserVoteAllocationTableContext = createContext<ContextType>(
  {} as ContextType,
)

UserVoteAllocationTableContext.displayName = 'UserVoteAllocationTableContext'

export const useUserVoteAllocationTable = (): ContextType => {
  return useContext(UserVoteAllocationTableContext)
}
interface Props {
  children: React.ReactNode
}
export function UserVoteAllocationTableProvider({
  children,
}: Props): ReactElement {
  const { user } = useVotingData()
  const [isInEditMode, setIsInEditMode] = useState(false)
  // contains all gauges' current inputs but it didn't know whether the inputs are edited or not.
  const [voteWeightPercentageInputs, setVoteWeightPercentageInputs] =
    useState<VoteWeightPercentageInputsType>(
      user.voteWeightOfEachAsset.percentage,
    )
  const [
    availableVoteWeightPercentageForFocusedInput,
    setAvailableVoteWeightPercentageForFocusedInput,
  ] = useState('0')
  const totalVoteWeightPercentageOfTheTable = useMemo(() => {
    let sumWad = strToWad('0')
    for (const poolPercentageInputs of Object.values(
      voteWeightPercentageInputs,
    )) {
      sumWad = sumWad.add(
        Object.values(poolPercentageInputs).reduce(
          (prev, current) => prev.add(strToWad(current)),
          strToWad('0'),
        ),
      )
    }
    return utils.formatEther(sumWad)
  }, [voteWeightPercentageInputs])
  /**
   * Gauge List
   */
  // newly added gauges
  const [addedGaugeList, setAddedGaugeList] = useState<GaugeDataType[]>([])
  // previously voted gauges from SC
  const votedGaugeList = useMemo(() => {
    const outputData: GaugeDataType[] = []
    for (const poolSymbolStr in user.voteWeightOfEachAsset.percentage) {
      const poolSymbol = poolSymbolStr as PoolSymbol
      const poolVoteWeightOfEachAssetInPercentage =
        user.voteWeightOfEachAsset.percentage[poolSymbol]
      for (const assetTokenSymbolStr in poolVoteWeightOfEachAssetInPercentage) {
        const assetTokenSymbol = assetTokenSymbolStr as TokenSymbol
        // if percentage is more than 0 then show it.
        if (
          strToWad(poolVoteWeightOfEachAssetInPercentage[assetTokenSymbol]).gt(
            strToWad('0'),
          )
        ) {
          const lpToken = LP_TOKENS[poolSymbol][assetTokenSymbol]
          outputData.push({
            // available lp tokens must have sorting ids
            // if -1 occurs, the implementation is not correct
            id: (lpToken && lpToken.sortingId) || -1,
            poolSymbol: poolSymbol,
            assetTokenSymbol: assetTokenSymbol,
          })
        }
      }
    }
    return outputData.sort((a, b) => {
      const aId = a.id as number
      const bId = b.id as number
      return aId - bId
    })
  }, [user.voteWeightOfEachAsset.percentage])
  // combined gauge list controlling which gauges are showed on the table
  const votedAndAddedGaugeList = useMemo(() => {
    return [...addedGaugeList, ...votedGaugeList]
  }, [addedGaugeList, votedGaugeList])

  // edited gauges mean users have changed their votes on gauges
  const editedGaugeList = useMemo(() => {
    return votedAndAddedGaugeList.filter((gauge) => {
      const { poolSymbol, assetTokenSymbol } = gauge
      const newVoteWeightPercentageWad = strToWad(
        voteWeightPercentageInputs[poolSymbol]?.[assetTokenSymbol],
      )
      const previousVoteWeightPercentageWad = strToWad(
        user.voteWeightOfEachAsset.percentage[poolSymbol]?.[assetTokenSymbol],
      )
      return !newVoteWeightPercentageWad.eq(previousVoteWeightPercentageWad)
    })
  }, [
    user.voteWeightOfEachAsset.percentage,
    voteWeightPercentageInputs,
    votedAndAddedGaugeList,
  ])
  /**
   * Actions
   */
  const updateAvailableVoteWeightPercentageForFocusedInput = useCallback(
    (poolSymbol: PoolSymbol, assetTokenSymbol: TokenSymbol) => {
      const currentVoteWeightPercentageInputWad = strToWad(
        voteWeightPercentageInputs[poolSymbol]?.[assetTokenSymbol],
      )
      setAvailableVoteWeightPercentageForFocusedInput(
        utils.formatEther(
          strToWad('100').sub(
            strToWad(totalVoteWeightPercentageOfTheTable).sub(
              currentVoteWeightPercentageInputWad,
            ),
          ),
        ),
      )
    },
    [totalVoteWeightPercentageOfTheTable, voteWeightPercentageInputs],
  )
  const updateVoteWeightPercentageInput = useCallback(
    (poolSymbol: PoolSymbol, assetTokenSymbol: TokenSymbol, value: string) => {
      // only accept 2 d.p for the input
      if (value !== '' && !isParsableString(value, 2, true)) {
        return
      }
      if (
        strToWad(value).gt(
          strToWad(availableVoteWeightPercentageForFocusedInput),
        )
      ) {
        return
      }
      setVoteWeightPercentageInputs((prev) => {
        return {
          ...prev,
          [poolSymbol]: { ...prev[poolSymbol], [assetTokenSymbol]: value },
        }
      })
    },
    [availableVoteWeightPercentageForFocusedInput],
  )

  const resetVoteWeightPercentageInputs = useCallback(() => {
    setVoteWeightPercentageInputs(user.voteWeightOfEachAsset.percentage)
  }, [user.voteWeightOfEachAsset.percentage])

  const addNewGauge = ({ poolSymbol, assetTokenSymbol }: GaugeDataType) => {
    setAddedGaugeList((prev) => [
      // always put the new added gauge at the top
      {
        assetTokenSymbol,
        poolSymbol,
      },
      ...prev,
    ])
    updateVoteWeightPercentageInput(poolSymbol, assetTokenSymbol, '')
  }
  const removeAddedGauge = useCallback(
    ({ poolSymbol, assetTokenSymbol }: GaugeDataType) => {
      setAddedGaugeList((prev) => {
        return prev.filter(
          (item) =>
            item.poolSymbol !== poolSymbol ||
            item.assetTokenSymbol !== assetTokenSymbol,
        )
      })
      updateVoteWeightPercentageInput(poolSymbol, assetTokenSymbol, '')
    },
    [updateVoteWeightPercentageInput],
  )

  const enterEditMode = useCallback(() => {
    setIsInEditMode(true)
    // initial values
    setVoteWeightPercentageInputs(user.voteWeightOfEachAsset.percentage)
  }, [user.voteWeightOfEachAsset.percentage])
  const exitEditMode = useCallback(() => {
    setAddedGaugeList([])
    setIsInEditMode(false)
  }, [])

  // reset all active styles
  const clearActiveElementsStyles = () => {
    const currentlyActiveInputWrapper = document.getElementsByClassName(
      ACTIVE_INPUT_WRAPPER_CLASS_NAME,
    )[0]
    if (currentlyActiveInputWrapper) {
      currentlyActiveInputWrapper.classList.remove(
        ACTIVE_INPUT_WRAPPER_CLASS_NAME,
      )
    }
    const currentlyActiveRow = document.getElementsByClassName(
      ACTIVE_ROW_CLASS_NAME,
    )[0]
    if (currentlyActiveRow) {
      currentlyActiveRow.classList.remove(ACTIVE_ROW_CLASS_NAME)
    }
  }
  // when users click away from the active row, remove its' styles
  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      const element = e.target as Element
      const tr = element.closest('tr')
      if (!tr?.classList.contains(ACTIVE_ROW_CLASS_NAME)) {
        clearActiveElementsStyles()
      }
    }
    document.body.addEventListener('click', clickHandler)
    return () => {
      document.body.removeEventListener('click', clickHandler)
    }
  }, [])
  return (
    <UserVoteAllocationTableContext.Provider
      value={{
        votedAndAddedGaugeList,
        addedGaugeList,
        votedGaugeList,
        editedGaugeList,
        voteWeightPercentageInputs,
        isInEditMode,
        availableVoteWeightPercentageForFocusedInput,
        totalVoteWeightPercentageOfTheTable,
        enterEditMode,
        exitEditMode,
        addNewGauge,
        removeAddedGauge,
        updateAvailableVoteWeightPercentageForFocusedInput,
        updateVoteWeightPercentageInput,
        resetVoteWeightPercentageInputs,
        clearActiveElementsStyles,
      }}
    >
      {children}
    </UserVoteAllocationTableContext.Provider>
  )
}
