import { BigNumber } from 'ethers'
import cloneDeep from 'lodash.clonedeep'
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { LOWERCASE_AVAILABLE_LP_TOKEN_ADDRESS_LIST } from '../config/contracts/pool'
import { PoolSymbol, poolSymbols } from '../config/contracts/pool/poolSymbol'
import { ChainId } from '../config/networks'
import {
  EMPTY_POOLSYMBOL_TOKENSYMBOL_BN,
  EMPTY_POOLSYMBOL_TOKENSYMBOL_STR,
} from '../constants/empty'
import useExchangeSubgraph, {
  Fetch24HourVolumeOfAllTokensReturnType,
} from '../hooks/pool/useExchangeSubgraph'
import { usePoller } from '../hooks/usePoller'
import {
  PoolSymbolTokenSymbolBigNumberType,
  PoolSymbolTokenSymbolStringType,
} from '../interfaces/common'
import {
  HAIRCUT_RATE_WAD,
  SLIPPAGE_PARAM_C1_BN,
  SLIPPAGE_PARAM_K_WAD,
  SLIPPAGE_PARAM_N_BN,
  SLIPPAGE_PARAM_X_THRESHOLD_BN,
} from '../utils/pool'
import { useMulticallData } from './MulticallDataContext'
import { PoolDataType } from './MulticallDataContext/helpers/fetchPoolDataHelper'
import { useNetwork } from './NetworkContext'

type SwapDataType = {
  [id in PoolSymbol]: {
    slippageParamKWad: BigNumber
    slippageParamNBN: BigNumber
    slippageParamC1BN: BigNumber
    slippageParamXThresholdBN: BigNumber
    haircutRateWad: BigNumber
  }
}
type VolumeOfTokensIn24hrType =
  | Fetch24HourVolumeOfAllTokensReturnType
  | undefined

export interface ContextType {
  assets: PoolSymbolTokenSymbolStringType
  liabilities: PoolSymbolTokenSymbolStringType
  lpSuppliesBN: PoolSymbolTokenSymbolBigNumberType
  swapData: SwapDataType
  isPoolFetched: boolean
  volumeOfTokensIn24hr?: VolumeOfTokensIn24hrType
}

export interface PoolDataStateType {
  swapData: SwapDataType
  liabilities: PoolSymbolTokenSymbolStringType
  assets: PoolSymbolTokenSymbolStringType
  lpSuppliesBN: PoolSymbolTokenSymbolBigNumberType
}

export const PoolsContext = createContext<ContextType>({} as ContextType)
PoolsContext.displayName = 'PoolsContext'

export const usePools = (): ContextType => {
  return useContext(PoolsContext)
}
interface Props {
  children: React.ReactNode
}

const initSwapData = poolSymbols.reduce((acc, curPoolSymbol) => {
  return {
    ...acc,
    [curPoolSymbol]: {
      slippageParamKWad: SLIPPAGE_PARAM_K_WAD,
      slippageParamNBN: SLIPPAGE_PARAM_N_BN,
      slippageParamC1BN: SLIPPAGE_PARAM_C1_BN,
      slippageParamXThresholdBN: SLIPPAGE_PARAM_X_THRESHOLD_BN,
      haircutRateWad: HAIRCUT_RATE_WAD,
    },
  }
}, {}) as SwapDataType

const initialPoolDataState = {
  newSwapData: initSwapData,
  newAssets: cloneDeep(
    EMPTY_POOLSYMBOL_TOKENSYMBOL_STR,
  ) as PoolSymbolTokenSymbolStringType,
  newLiabilities: cloneDeep(
    EMPTY_POOLSYMBOL_TOKENSYMBOL_STR,
  ) as PoolSymbolTokenSymbolStringType,
  newLpTotalSuppliesBN: cloneDeep(
    EMPTY_POOLSYMBOL_TOKENSYMBOL_BN,
  ) as PoolSymbolTokenSymbolBigNumberType,
}

export const PoolsProvider = ({ children }: Props): ReactElement => {
  const { poolData, isMulticallDataFetched } = useMulticallData()
  const { chainId } = useNetwork()

  // assets is token belongs to the pool contract
  const [volumeOfTokensIn24hr, setVolumeOfTokensIn24hr] =
    useState<VolumeOfTokensIn24hrType>(undefined)
  const { fetch24HourVolumeOfAllTokens } = useExchangeSubgraph()
  const [poolDataState, setPoolDataState] =
    useState<PoolDataType>(initialPoolDataState)

  useEffect(() => {
    if (poolData) {
      setPoolDataState(poolData)
    }
  }, [poolData])

  useEffect(() => {
    setVolumeOfTokensIn24hr(undefined)
  }, [chainId])

  usePoller(
    () => {
      // Snowtrace api has a rate limit of 5 calls per sec/IP
      const getNewVolumeOfTokensIn24hr = async () => {
        const newVolumeOfTokensIn24hr = await fetch24HourVolumeOfAllTokens(
          // only available in main net.
          LOWERCASE_AVAILABLE_LP_TOKEN_ADDRESS_LIST[ChainId.AVALANCHE],
        )
        setVolumeOfTokensIn24hr(newVolumeOfTokensIn24hr)
      }
      /** @todo maybe only when the current location is '/pool', we will fetch the data. do it after upgrading react-router to v6*/
      void getNewVolumeOfTokensIn24hr()
    },
    [chainId],
    300000, // 5 minutes
  )

  return (
    <PoolsContext.Provider
      value={{
        assets: poolDataState.newAssets,
        liabilities: poolDataState.newLiabilities,
        lpSuppliesBN: poolDataState.newLpTotalSuppliesBN,
        swapData: poolDataState.newSwapData,
        isPoolFetched: isMulticallDataFetched,
        volumeOfTokensIn24hr,
      }}
    >
      {children}
    </PoolsContext.Provider>
  )
}
