import { Box, IconButton } from '@mui/material'
import styled, { css } from 'styled-components'
import AppButton from '../../components/buttons/AppButton/AppButton'
import Lottie from '../../components/platopia/Lottie/Lottie'
import { PLATOPIA_DESKTOP_MAX_WIDTH } from '../../config/theme'
import Stage1BackgroundImage from './assets/images/background/stage1.jpg'
import Stage2BackgroundImage from './assets/images/background/stage2.jpg'
import Stage3BackgroundImage from './assets/images/background/stage3.jpg'
import webAppIcon from './assets/images/info/webAppIcon.png'
import platypusFishBasketData from './assets/lotties/platypus-walk-fish-basket.json'
import platypusWalkPumpkinBasketData from './assets/lotties/platypus-walk-pumpkin-basket.json'
import { Stage } from './settings'

export const Container = styled.div`
  position: relative;
  padding-top: 20px;
  padding-bottom: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #167db7 0%, #07171d 100%);
`

interface BackgroundProps {
  stage: Stage
}

export const StageBackground = styled.img.attrs(
  ({ stage }: BackgroundProps) => {
    let src = Stage3BackgroundImage
    switch (stage) {
      case Stage.stage1:
        src = Stage1BackgroundImage
        break
      case Stage.stage2:
        src = Stage2BackgroundImage
        break
      case Stage.stage3:
        src = Stage3BackgroundImage
        break
      default:
        src = Stage3BackgroundImage
    }

    return {
      src,
    }
  },
)<BackgroundProps>`
  width: 100%;
  max-width: ${PLATOPIA_DESKTOP_MAX_WIDTH}px;
  border-radius: 20px;
`

export const TopContainer = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`

export const BottomContainer = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 960px;
`

export const AnimationContainer = styled(Box)`
  z-index: 1;
  position: relative;
`

interface TransparentContainerProps {
  top?: string | undefined
  right?: string | undefined
  bottom?: string | undefined
  left?: string | undefined
  transform?: string | undefined
  minWidth?: string | undefined
}

export const TransparentContainer = styled(Box)<TransparentContainerProps>`
  position: absolute;
  ${({ top }) =>
    top != undefined
      ? css`
          top: ${top};
        `
      : ``}
  ${({ right }) =>
    right != undefined
      ? css`
          right: ${right};
        `
      : ``}
  ${({ bottom }) =>
    bottom != undefined
      ? css`
          bottom: ${bottom};
        `
      : ``}
  ${({ left }) =>
    left != undefined
      ? css`
          left: ${left};
        `
      : ``}
  ${({ transform }) =>
    transform != undefined
      ? css`
          transform: ${transform};
        `
      : ``}
  ${({ minWidth }) =>
    minWidth != undefined
      ? css`
          min-width: ${minWidth};
        `
      : ``}
  z-index: 500;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(4, 26, 32, 0.8) 0%,
    rgba(4, 26, 32, 0.48) 100%
  );
`

export const SettingButton = styled(IconButton).attrs({
  customVariant: 'unset',
})`
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 500;
  border-radius: 999px;
  background: linear-gradient(
    180deg,
    rgba(4, 26, 32, 0.8) 0%,
    rgba(4, 26, 32, 0.48) 100%
  );
  svg {
    font-size: 26px;
  }
`

export const WebAppButtonContainer = styled.div`
  position: absolute;
  right: 24px;
  bottom: 24px;
`

export const WebAppIcon = styled.img.attrs({
  src: webAppIcon,
})`
  width: 60px;
  position: absolute;
  left: -8px;
  top: -8px;
`

export const WebAppButtonBackground = styled(Box)`
  position: relative;
  background: linear-gradient(99.19deg, #00f152 10.12%, #0076e9 100%);
  border-radius: 24px;
`

export const WebAppButton = styled(AppButton).attrs({
  customVariant: 'unset',
  margin: '2px 2px 2px 0px !important',
})`
  padding: 6px 24px 6px 60px;
  background: linear-gradient(
    266.17deg,
    #0a1b52 56.45%,
    rgba(10, 27, 82, 0) 91.31%
  );
  border: 2px solid white;
  border-radius: 24px;
`

export const WalkPlatypus1 = styled(Lottie).attrs({
  left: 180,
  width: 330,
  height: 330,
  data: platypusFishBasketData,
})``

export const WalkPlatypus2 = styled(Lottie).attrs({
  left: 180,
  width: 330,
  height: 330,
  data: platypusWalkPumpkinBasketData,
})``

export const SettingIconContainer = styled.div`
  position: absolute;
  top: -66px;
  right: -62px;
  padding: 9px;
  background: linear-gradient(
    180deg,
    rgba(4, 26, 32, 0.8) 0%,
    rgba(4, 26, 32, 0.48) 100%
  );
  border-radius: 21px;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
`
