import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from 'react'
import ErrorModalContainer from '../containers/ErrorModalContainer/ErrorModalContainer'

export interface ErrorModalOptions {
  enableCloseBtn?: boolean
  onClickButton?: () => void
}
interface ErrorMessageType {
  title?: string
  message: string
  options?: ErrorModalOptions
}
interface ContextType {
  openErrorModal: ({ title, message }: ErrorMessageType) => void
}

const ErrorModalContext = createContext<ContextType>({} as ContextType)
ErrorModalContext.displayName = 'ErrorModalContext'

export const useErrorModal = (): ContextType => {
  return useContext(ErrorModalContext)
}

const initialError: ErrorMessageType = { title: '', message: '' }

interface Props {
  children: React.ReactNode
}

function ErrorModalProvider({ children }: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState(initialError)
  const [options, setOptions] = useState({} as ErrorModalOptions)
  const openErrorModal = useCallback(
    ({ title, message, options }: ErrorMessageType) => {
      setError({
        title,
        message,
      })
      setIsOpen(true)
      if (options) {
        setOptions((prev) => ({ ...prev, ...options }))
      }
    },
    [],
  )
  const handleCloseErrorModal = () => {
    setIsOpen(false)
    setError(initialError)
  }
  return (
    <ErrorModalContext.Provider value={{ openErrorModal }}>
      {children}
      {isOpen && (
        <ErrorModalContainer
          isOpen
          onCloseErrorModal={handleCloseErrorModal}
          title={error.title}
          message={error.message}
          options={options}
        />
      )}
    </ErrorModalContext.Provider>
  )
}

export default ErrorModalProvider
