import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useNetwork } from '../../../contexts/NetworkContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { Colorized } from '../../../globalStyles'
import useBreakpoints from '../../../hooks/useBreakpoints'
import useClaimVeptp from '../../../hooks/vePtp/useClaimVePtp'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import {
  CTAButton,
  RowToColumnContainer,
  ShrinkAndGrowContainer,
} from '../VePtpContainer.elements'
import { Circle } from './ClaimableVePtpInfo.elements'

function ClaimableVePtpInfo(): ReactElement {
  const { vePtp } = useVePtp()
  const { handleClaimVePtp } = useClaimVeptp()
  const { account } = useNetwork()
  const { isTabletSm } = useBreakpoints()

  return (
    <RowToColumnContainer width="100%">
      <ShrinkAndGrowContainer flex={0.5} alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isTabletSm ? 'center' : 'flex-start'}
        >
          <AppTypography
            variant="subtitle2"
            sx={{ display: 'inline-flex', alignItems: 'center' }}
          >
            Claimable vePTP
            <InfoBox.Tooltip text="You need to claim the vePTP. Only the claimed vePTP in your balance is effective for boosting the yield." />
          </AppTypography>
          <Colorized variant="green">
            <AppTypography variant="h5" sx={{ display: 'flex' }}>
              <Circle />
              <TooltipNum
                amount={vePtp.pendingAmount}
                rightSymbol={TokenSymbol.VEPTP}
              >
                {showDashIfNecessary(
                  !account,
                  getCommifiedFormat(vePtp.pendingAmount),
                )}
              </TooltipNum>
            </AppTypography>
          </Colorized>
        </Box>
      </ShrinkAndGrowContainer>
      <ShrinkAndGrowContainer flex={0.5} alignItems="center">
        <CTAButton
          customVariant="secondary"
          onClick={() => void handleClaimVePtp()}
          data-testid="claim-veptp-button"
          disabled={strToWad(vePtp.pendingAmount).isZero()}
        >
          Claim
        </CTAButton>
      </ShrinkAndGrowContainer>
    </RowToColumnContainer>
  )
}
export default ClaimableVePtpInfo
