import { safeWdiv, strToWad, sum, wmul } from '@hailstonelabs/big-number-utils'
import { BigNumber, constants, utils } from 'ethers'
import { Stage } from '../containers/PlatopiaContainer/settings'
import { NftTypeId, PlatypusNFT } from '../interfaces/nft'
import {
  VePtpStatsDifference,
  VePTPStatsWithoutAndWithNft,
} from '../interfaces/vePTP'

/** vePtp related formulas: https://www.notion.so/vePTP-page-cafae3ea9f4b4ea89c95022a79b1eafc */

/**
 * With the hibernate effect
 * % of PTP remain staking = (PTP staked amount - PTP amount to be unstaked )/ PTP staked amount
 * remaining vePTP balance = vePTP balance * hibernate% * % of PTP remain staking
 * amount of vePTP to burn = vePTP balance - remaining vePTP balance
 * @param {string} hibernateValue
 * @param {string} unstakePtpAmount
 * @param {string} stakedPtpAmount
 * @param {string} vePtpBalance
 * @returns {{remaining: string, burnt: string}|null}
 */

export const getVePtpBalanceAfterUnstakingPtpWithHibernate = (data: {
  hibernateValue: string
  unstakePtpAmount: string
  stakedPtpAmount: string
  vePtpBalance: string
}): {
  remaining: string
  burnt: string
} | null => {
  const { hibernateValue, unstakePtpAmount, stakedPtpAmount, vePtpBalance } =
    data
  const stakedPtpAmountWAD = strToWad(stakedPtpAmount)

  if (
    !hibernateValue ||
    !unstakePtpAmount ||
    stakedPtpAmountWAD.isZero() ||
    !vePtpBalance
  ) {
    return null
  }
  const hibernateValueBN = BigNumber.from(hibernateValue)
  const unstakePtpAmountWAD = strToWad(unstakePtpAmount)
  if (unstakePtpAmountWAD.eq(constants.Zero)) {
    return {
      remaining: vePtpBalance,
      burnt: '0.0',
    }
  }
  const vePtpBalanceWAD = strToWad(vePtpBalance)
  const remainStakingPtpRatioWad = safeWdiv(
    stakedPtpAmountWAD.sub(unstakePtpAmountWAD),
    stakedPtpAmountWAD,
  )
  const remainingVePTPbalanceRatioWad = remainStakingPtpRatioWad
    .mul(hibernateValueBN)
    .div(BigNumber.from('100'))
  const remainingVePTPBalanceWAD = wmul(
    remainingVePTPbalanceRatioWad,
    vePtpBalanceWAD,
  )
  const burntVePTPAmountWAD = vePtpBalanceWAD.sub(remainingVePTPBalanceWAD)
  return {
    remaining: utils.formatEther(remainingVePTPBalanceWAD),
    burnt: utils.formatEther(burntVePTPAmountWAD),
  }
}

/**
 * VePtpBalancePerMaxPercentage =  VePtpBalancePer*100 / MaxPercentage
 * @param {string} vePtpBalance // WAD str
 * @param {string} maxVePtpToEarn // WAD str
 * @returns {number}
 */
export const getVePtpBalancePerMaxPercentage = (
  vePtpBalance: string,
  maxVePtpToEarn: string,
): number => {
  const maxVePtpToEarnWAD = strToWad(maxVePtpToEarn)
  const vePtpBalanceWAD = strToWad(vePtpBalance)
  if (maxVePtpToEarnWAD.isZero()) {
    return 0
  } else {
    const percentage = Number(
      utils.formatEther(
        safeWdiv(vePtpBalanceWAD, maxVePtpToEarnWAD).mul(BigNumber.from('100')),
      ),
    )
    if (percentage > 100) return 100
    return percentage
  }
}

/**
 * Get Number of vePtp user can get in hour when user stakes PTP(userStakedPTPWAD)
 * generationRate(per second) * 60 * 60 * user staked PTP
 * @param {BigNumber} generationRatePerSecondWAD
 * @param {BigNumber} stakedPTPWAD
 * @returns {string}  generationRatePerHour
 */
export const getVePtpEarnPerHour = (
  generationRatePerSecondWAD: BigNumber,
  stakedPTPWAD: BigNumber,
): string => {
  // One hour = 60 mins and One min = 60 seconds
  const mineRatePerHourWAD = generationRatePerSecondWAD
    .mul(BigNumber.from('60'))
    .mul(BigNumber.from('60'))
  const vePtpMinePerHourWAD = wmul(mineRatePerHourWAD, stakedPTPWAD)

  return utils.formatEther(vePtpMinePerHourWAD)
}

/**
 * Get Maximum Amount of VePtp user can earn when user staked (userStakedPtpBN)
 * maxCap * user staked PTP
 * @param {BigNumber} maxVePtpCapPerPtpBN
 * @param {BigNumber} stakedPTPWAD
 * @returns {BigNumber} maxVePtpToEarn
 */
export const getMaxVePtpToEarnWAD = (
  maxVePtpCapPerPtpBN: BigNumber,
  stakedPTPWAD: BigNumber,
): BigNumber => {
  return maxVePtpCapPerPtpBN.mul(stakedPTPWAD)
}

/**
 * Get Stage based on current vePTP amount / max vePTP cap.
 * If it is 0-20%, then it’s Stage 1, 21-40% is Stage 2, ... 81-100% is Stage 5.
 * @param {number} percentage
 * @returns {Stage} stage
 */
export const getPlatopiaStage = (percentage: number): Stage => {
  const STEP = 20
  const MAX_PERCENTAGE = 100
  let stage = 1
  let upperBound = STEP

  while (upperBound < MAX_PERCENTAGE) {
    if (percentage > upperBound) {
      stage += 1
      upperBound += STEP
    } else {
      break
    }
  }

  return `STAGE_${stage}` as Stage
}

/**
 * Get Population from roundown(log(PTP staked) * 100)
 * @param {BigNumber} stakedPtpWAD
 * @returns {number} population
 */
export const getPlatopiaPopulation = (stakedPtpWAD: BigNumber): number => {
  if (stakedPtpWAD.lt(strToWad('1'))) {
    return 0
  }
  const population = Math.floor(
    Math.log(Number(utils.formatEther(stakedPtpWAD))) * 100,
  )

  return population > 0 ? population : 0
}

export interface GetVePtpStatsWithoutAndWithNftEffectArgs {
  originalVePtpTotalBalance?: string // optional. WAD str. original vePTP balance, without the effect of any NFT abilities
  currentVePtpTotalBalance?: string // optional. WAD str. current vePTP balance, with the effect of the targetNft ability
  generationRatePerSecond: string // WAD str. from SC
  stakedPtpAmount: string // WAD str
  maxVePtpCapPerPtpForStaking: string // WAD str
  targetNft: PlatypusNFT | null
}

/**
 * @param {Object} args
 * @param {string?} args.originalVePtpBalance // optional. WAD str. original vePTP balance, without the effect of any NFT abilities
 * @param {string?} args.currentVePtpBalance // optional. WAD str. current vePTP balance, with the effect of the targetNft ability
 * @param {string} args.generationRatePerSecond // WAD str. from SC
 * @param {string} args.stakedPtpAmount // WAD str
 * @param {string} args.maxVePtpCapPerPtp // BN str
 * @param {PlatypusNFT | null} args.targetNft
 * @returns {VePTPStatsWithoutAndWithNft}
 */
export function getVePtpStatsWithoutAndWithNftEffect({
  originalVePtpTotalBalance, //
  currentVePtpTotalBalance, // either one of originalVePtpBalance or currentVePtpBalance must be supplied
  generationRatePerSecond,
  stakedPtpAmount,
  maxVePtpCapPerPtpForStaking,
  targetNft,
}: GetVePtpStatsWithoutAndWithNftEffectArgs): VePTPStatsWithoutAndWithNft {
  const generationRatePerSecondWad = strToWad(generationRatePerSecond)
  const stakedPtpWad = strToWad(stakedPtpAmount)
  const maxVePtpCapPerPtpForStakingWad = strToWad(maxVePtpCapPerPtpForStaking)
  const vePtpBalanceWad = strToWad(
    originalVePtpTotalBalance || (currentVePtpTotalBalance as string),
  )

  const withoutNftStats: { [id in keyof VePTPStatsWithoutAndWithNft]: string } =
    {
      vePtpTotalBalance: utils.formatEther(vePtpBalanceWad),
      maxVePtpCapForStaking: utils.formatEther(
        wmul(stakedPtpWad, maxVePtpCapPerPtpForStakingWad),
      ),
      vePtpEarningPerHour: getVePtpEarnPerHour(
        generationRatePerSecondWad,
        stakedPtpWad,
      ),
      retainPercentage: '0.0',
    }

  // calculate the originalVePtpBalance if only the currentVePtpBalance is provided
  if (
    targetNft &&
    targetNft.type === NftTypeId.GIFTED &&
    !originalVePtpTotalBalance &&
    currentVePtpTotalBalance
  ) {
    const giftedBalance = strToWad(targetNft.value)
    withoutNftStats['vePtpTotalBalance'] = utils.formatEther(
      strToWad(currentVePtpTotalBalance).sub(giftedBalance),
    )
  }

  const withEquippedNftStats: {
    [id in keyof VePTPStatsWithoutAndWithNft]: string
  } = {
    ...withoutNftStats,
  }

  if (targetNft) {
    switch (targetNft.type) {
      case NftTypeId.SPEEDO: {
        // increase vePtpEarningPerHour by %
        const increasedPercentWad = strToWad(targetNft.value)
        const multiplierWad = safeWdiv(
          increasedPercentWad,
          strToWad('100'),
        ).add(strToWad('1'))
        withEquippedNftStats.vePtpEarningPerHour = utils.formatEther(
          wmul(strToWad(withoutNftStats.vePtpEarningPerHour), multiplierWad),
        )
        break
      }

      case NftTypeId.PUDGY: {
        // increase maxVePtpCap by %
        const increasedPercentWad = strToWad(targetNft.value)
        const multiplierWad = safeWdiv(
          increasedPercentWad,
          strToWad('100'),
        ).add(strToWad('1'))
        withEquippedNftStats.maxVePtpCapForStaking = utils.formatEther(
          wmul(strToWad(withoutNftStats.maxVePtpCapForStaking), multiplierWad),
        )
        break
      }

      case NftTypeId.DILIGENT: {
        // increase vePtpEarningPerHour by value
        const increasedAmountWad = strToWad(targetNft.value)
        withEquippedNftStats.vePtpEarningPerHour = sum(
          withoutNftStats.vePtpEarningPerHour,
          increasedAmountWad,
        )
        break
      }

      case NftTypeId.GIFTED: {
        // increase both maxVePtpCap and vePTP balance by x
        const increasedAmountWad = strToWad(targetNft.value)
        withEquippedNftStats.maxVePtpCapForStaking = sum(
          withoutNftStats.maxVePtpCapForStaking,
          increasedAmountWad,
        )
        withEquippedNftStats.vePtpTotalBalance = sum(
          withoutNftStats.vePtpTotalBalance,
          increasedAmountWad,
        )
        break
      }

      case NftTypeId.HIBERNATE: {
        // change retainPercent to x
        const retainPercentageWad = strToWad(targetNft.value)
        withEquippedNftStats.retainPercentage =
          utils.formatEther(retainPercentageWad)
        break
      }
      default:
        break
    }
  }

  return {
    vePtpTotalBalance: {
      original: withoutNftStats.vePtpTotalBalance,
      current: withEquippedNftStats.vePtpTotalBalance,
    },
    maxVePtpCapForStaking: {
      original: withoutNftStats.maxVePtpCapForStaking,
      current: withEquippedNftStats.maxVePtpCapForStaking,
    },
    vePtpEarningPerHour: {
      original: withoutNftStats.vePtpEarningPerHour,
      current: withEquippedNftStats.vePtpEarningPerHour,
    },
    retainPercentage: {
      original: withoutNftStats.retainPercentage,
      current: withEquippedNftStats.retainPercentage,
    },
  }
}

interface GetVePtpStatsDifferenceArgs {
  generationRatePerSecond: string
  maxVePtpCapPerPtpForStaking: string
  originalVePtpTotalBalance: string
  stakedPtpAmount: string
  currentNft: PlatypusNFT | null
  nftToBeEquipped: PlatypusNFT | null
}

/**
 * Return the difference between before stats and after stats. Used in comparing equip/unequip/change NFT.
 * @param {Object} args
 * @param {string} args.generationRatePerSecond // WAD str. from vePTP context
 * @param {string} args.maxVePtpCapPerPtp // WAD str. from vePTP context
 * @param {string} args.originalVePtpBalance // WAD str. from vePTP context
 * @param {string} args.stakedPtpAmount // WAD str. from vePTP context
 * @param {PlatypusNFT | null} args.currentNft
 * @param {PlatypusNFT | null} args.nftToBeEquipped
 * @returns {VePtpStatsDifference}
 */
export function getVePtpStatsDifference({
  generationRatePerSecond,
  /** @todo may br change to total */
  maxVePtpCapPerPtpForStaking,
  originalVePtpTotalBalance,
  stakedPtpAmount,
  currentNft,
  nftToBeEquipped,
}: GetVePtpStatsDifferenceArgs): VePtpStatsDifference {
  const beforeStats = getVePtpStatsWithoutAndWithNftEffect({
    originalVePtpTotalBalance,
    generationRatePerSecond,
    stakedPtpAmount,
    maxVePtpCapPerPtpForStaking,
    targetNft: currentNft,
  })

  const afterStats = getVePtpStatsWithoutAndWithNftEffect({
    originalVePtpTotalBalance,
    generationRatePerSecond,
    stakedPtpAmount,
    maxVePtpCapPerPtpForStaking,
    targetNft: nftToBeEquipped,
  })

  const differences: VePtpStatsDifference = {}

  // compare beforeStats and afterStats differences
  for (const key in beforeStats) {
    const statKey = key as keyof typeof beforeStats
    if (beforeStats[statKey].current !== afterStats[statKey].current) {
      differences[statKey] = {
        before: beforeStats[statKey].current,
        after: afterStats[statKey].current,
        isIncreased: strToWad(afterStats[statKey].current).gt(
          strToWad(beforeStats[statKey].current),
        ),
      }
    }
  }

  return differences
}
