import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppButton from '../AppButton/AppButton'

export interface ContainerProps {
  justifyContent?: 'center' | 'space-between'
  margin?: string
}

/**
 * xs = 19.5px
 * md = 33px
 */
export type Size = 'xs' | 'md'

export interface ButtonProps {
  sizeVariant?: Size
  bordered?: boolean
}

/**
 * @dev utility function to pass in the value
 * @param size `xs` | `md`
 * @returns string
 */
function getSize(size?: Size): string {
  if (!size) return '33px'

  switch (size) {
    case 'xs':
      return '19.5px'
    case 'md':
      return '33px'
    default:
      return '33px'
  }
}

/**
 * @dev utility function to determine svg file size
 * @param size `xs` | `md`
 * @returns string
 */
function getSizeSvg(size?: Size): string {
  if (!size) return '14px'

  switch (size) {
    case 'xs':
      return '10px'
    case 'md':
      return '14px'
    default:
      return '14px'
  }
}

/**
 * @notice currently there is no md value
 * @dev utility function to determine img file size
 * @param size `xs` | `md`
 * @returns string
 */
function getSizeImg(size?: Size): string {
  if (!size) return '12px'

  switch (size) {
    case 'xs':
      return '12px'
    default:
      return '12px'
  }
}

/**
 * @dev utility function to determin column-gap
 * @param size `xs` | `md`
 * @returns string
 */
function getSizeGap(size?: Size): string {
  if (!size) return '4px'

  switch (size) {
    case 'xs':
      return '2px'
    case 'md':
      return '4px'
    default:
      return '4px'
  }
}

export const StyledTransparentButton = styled(AppButton)`
  ${({ theme }) => css`
    height: ${(props: ButtonProps) => getSize(props.sizeVariant)};
    color: ${theme.palette.primary['A200']};
    background: ${alpha(theme.palette.primary[500], 0.2)};
    column-gap: ${(props: ButtonProps) => getSizeGap(props.sizeVariant)};
    border: ${(props: ButtonProps) =>
      props.bordered
        ? `1px solid ${alpha(theme.palette.primary[500], 0.2)}`
        : 'none'};
    &&:hover {
      background: ${alpha(theme.palette.primary[500], 0.1)};
    }
    svg {
      font-size: ${(props: ButtonProps) => getSizeSvg(props.sizeVariant)};
    }
    img {
      width: ${(props: ButtonProps) => getSizeImg(props.sizeVariant)};
      height: ${(props: ButtonProps) => getSizeImg(props.sizeVariant)};
    }
  `}
`

export const Container = styled(Box)`
  ${css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
    justify-content: ${(props: ContainerProps) =>
      props.justifyContent !== undefined ? props.justifyContent : 'center'};
    margin: ${(props: ContainerProps) => props.margin};
  `}
`
