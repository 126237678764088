import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import React, { ReactElement, useMemo } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import Dashable from '../../../components/Dashable/Dashable'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { lockPtpInfoData } from '../../../constants/infoData'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { useVePtpInput } from '../../../contexts/VePtpInputContext'
import { Colorized } from '../../../globalStyles'
import useCountdown from '../../../hooks/useCountDown'
import useLockTimeLeft from '../../../hooks/vePtp/locking/useLockTimeLeft'
import useVeptpToReceive from '../../../hooks/vePtp/locking/useVeptpToReceive'
import { ModalId } from '../../../interfaces/Modal'
import { dateFormat } from '../../../utils/common'
import { getNewUnlockTimeInMilliseconds } from '../../../utils/locking'
import { ArrowRight } from './LockPtpInfoBox.element'

function LockPtpInfoBox(): ReactElement {
  const { modalState } = useModal()
  const {
    lockPtpInputAmount,
    lockDayInputAmount,
    maxPtpLockCap,
    myLockedPtpAmountIncludingInput,
    totalLockDays,
  } = useVePtpInput()
  const { vePtp, ptp } = useVePtp()
  const { toReceiveGtMaxCap, veptpToReceive } = useVeptpToReceive({
    lockDayInputAmount,
    lockPtpInputAmount,
  })

  const lockDayInputAmountInMilliseconds =
    Number(lockDayInputAmount) * 24 * 60 * 60 * 1000

  // if LockPtpInfobox is not in LOCK_PTP modal, both Multiplier and Max Cap will be hidden
  const isInLockPtpModal = modalState.currentModalId === ModalId.LOCK_PTP

  const isLocking =
    modalState.currentModalId === ModalId.LOCK_PTP ||
    modalState.currentModalId === ModalId.LOCK_PTP_WAIT_FOR_CONFIRMATION

  const isInExtendLockPtpModal =
    modalState.currentModalId === ModalId.EXTEND_LOCK_PTP

  // if LockPtpInfobox is not in LOCK_EXTEND_PTP_WAIT_FOR_CONFIRMATION, total locked with be shown
  const isInExtendPtpConfirmationModal =
    modalState.currentModalId === ModalId.LOCK_EXTEND_PTP_WAIT_FOR_CONFIRMATION

  const isInConfirmationModal =
    isInExtendPtpConfirmationModal ||
    modalState.currentModalId === ModalId.LOCK_PTP_WAIT_FOR_CONFIRMATION

  const isExtendingLock =
    isInExtendLockPtpModal || isInExtendPtpConfirmationModal

  // This is used for calculating unlock date, new countdown and new lock time left
  const newUnlockTimeInMilliseconds = useMemo(() => {
    if (isLocking && !ptp.hasLocked) {
      // first time locking
      return lockDayInputAmountInMilliseconds + Date.now()
    }

    if (isExtendingLock) {
      // extend PTP lock
      return (
        lockDayInputAmountInMilliseconds + ptp.lockTime.unlockTimestamp * 1000
      )
    }
    // lock more PTP
    return ptp.lockTime.unlockTimestamp * 1000
  }, [
    isExtendingLock,
    isLocking,
    lockDayInputAmountInMilliseconds,
    ptp.hasLocked,
    ptp.lockTime.unlockTimestamp,
  ])

  const { countdownData: countdownDataForCurrentPosition } = useCountdown({
    targetTimestampInMilliseconds: getNewUnlockTimeInMilliseconds(
      ptp.lockTime.unlockTimestamp,
      ptp.hasLocked ? undefined : lockDayInputAmountInMilliseconds,
    ),
  })

  const { percentageLeft: percentageLeftCurrentPosition } = useLockTimeLeft({
    unlockTimeInMilliseconds: ptp.lockTime.unlockTimestamp * 1000,
    initialLockTimeInMilliseconds: ptp.lockTime.initialTimestamp * 1000,
  })

  const { countdownData: countdownDataForNewPosition } = useCountdown({
    targetTimestampInMilliseconds: newUnlockTimeInMilliseconds,
  })

  const { percentageLeft: percentageLeftForNewPosition } = useLockTimeLeft({
    unlockTimeInMilliseconds: newUnlockTimeInMilliseconds,
    initialLockTimeInMilliseconds: ptp.lockTime.initialTimestamp * 1000,
  })

  const MULTIPLIER_TOOLTIP = `Each locked PTP can earn ${vePtp.generationRate.perSecond} vePTP per second.`
  const MAX_CAP_TOOLTIP = `Max vePTP you can earn is ${
    vePtp.maxCapPerPtp.locking
  } times of your ${ptp.hasLocked ? 'locked PTP and' : ''} PTP to lock.`

  const hasZeroLockDayInput = strToWad(lockDayInputAmount).isZero()

  return (
    <InfoBox>
      {/* Unlock Date */}
      <InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            {lockPtpInfoData.UNLOCK_DATE.label}
          </AppTypography>
          <InfoBox.Tooltip text={lockPtpInfoData.UNLOCK_DATE.tooltip} />
        </InfoBox.Item>
        <InfoBox.Item>
          <Dashable
            showDash={isInLockPtpModal && !ptp.hasLocked && hasZeroLockDayInput}
          >
            <AppTypography variant="caption">
              {dateFormat(newUnlockTimeInMilliseconds, true, true)}
            </AppTypography>
          </Dashable>
        </InfoBox.Item>
      </InfoBox.Item>
      {isExtendingLock && (
        <InfoBox.Item>
          <InfoBox.Item>
            <AppTypography variant="caption">
              {lockPtpInfoData.TOTAL_LOCK_DAYS.label}
            </AppTypography>
            <InfoBox.Tooltip text={lockPtpInfoData.TOTAL_LOCK_DAYS.tooltip} />
          </InfoBox.Item>
          <InfoBox.Item>
            <AppTypography variant="caption" centerContent>
              {totalLockDays.original}
              {!hasZeroLockDayInput && (
                <>
                  <ArrowRight />
                  <AppTypography variant="caption">
                    <Colorized variant="green">
                      {totalLockDays.current}
                    </Colorized>
                  </AppTypography>
                </>
              )}
            </AppTypography>
          </InfoBox.Item>
        </InfoBox.Item>
      )}
      {/* Time Left */}
      <InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            {lockPtpInfoData.TIME_LEFT.label}
          </AppTypography>
          <InfoBox.Tooltip text={lockPtpInfoData.TIME_LEFT.tooltip} />
        </InfoBox.Item>
        <InfoBox.Item alignSelf="flex-end">
          <Dashable
            showDash={isInLockPtpModal && !ptp.hasLocked && hasZeroLockDayInput}
          >
            <AppTypography
              variant="caption"
              centerContent
              justifyContent="flex-start"
            >
              {/**countdownDataForCurrentPosition */}
              {ptp.hasLocked
                ? percentageLeftCurrentPosition <= 0
                  ? 'Unlockable'
                  : countdownDataForCurrentPosition.map((item) => {
                      return `${item.value}${item.label} `
                    })
                : `${lockDayInputAmount}d 0h 0m`}
              {/**countdownDataForNewPosition */}
              {isExtendingLock && !hasZeroLockDayInput && (
                <>
                  <ArrowRight />
                  {percentageLeftForNewPosition <= 0 ? (
                    'Unlockable'
                  ) : (
                    <Colorized
                      variant={
                        Number(lockDayInputAmount) > ptp.lockTime.maxDays ||
                        Number(lockDayInputAmount) < ptp.lockTime.minDays
                          ? 'red'
                          : 'green'
                      }
                    >
                      {countdownDataForNewPosition.map((item) => {
                        return `${item.value}${item.label} `
                      })}
                    </Colorized>
                  )}
                </>
              )}
            </AppTypography>
          </Dashable>
        </InfoBox.Item>
      </InfoBox.Item>
      {/* Total Lock */}
      {((ptp.hasLocked && isInLockPtpModal) || isInConfirmationModal) && (
        <InfoBox.Item>
          <InfoBox.Item>
            <AppTypography variant="caption">
              {lockPtpInfoData.TOTAL_LOCK.label}
            </AppTypography>
            <InfoBox.Tooltip text={lockPtpInfoData.TOTAL_LOCK.tooltip} />
          </InfoBox.Item>
          <InfoBox.Item>
            <AppTypography variant="caption">
              <TooltipNum
                amount={myLockedPtpAmountIncludingInput}
                rightSymbol={TokenSymbol.PTP}
              >
                {getCommifiedFormat(myLockedPtpAmountIncludingInput, 2)}
              </TooltipNum>{' '}
              {TokenSymbol.PTP}
            </AppTypography>
          </InfoBox.Item>
        </InfoBox.Item>
      )}
      {/* Multiplier */}
      {(isInExtendLockPtpModal || isInLockPtpModal) && (
        <InfoBox.Item marginTop="12px">
          <InfoBox.Item>
            <AppTypography variant="caption">
              {lockPtpInfoData.MULTIPLIER.label}
            </AppTypography>
            <InfoBox.Tooltip text={MULTIPLIER_TOOLTIP} />
          </InfoBox.Item>
          <InfoBox.Item>
            <AppTypography variant="caption">
              <TooltipNum
                amount={vePtp.generationRate.perDay}
                rightSymbol="vePTP/day"
              >
                {getCommifiedFormat(vePtp.generationRate.perDay, 2)}
              </TooltipNum>{' '}
              vePTP/day
            </AppTypography>
          </InfoBox.Item>
        </InfoBox.Item>
      )}
      {/* Max Cap */}
      {(isInExtendLockPtpModal || isInLockPtpModal) && (
        <InfoBox.Item>
          <InfoBox.Item>
            <AppTypography variant="caption">
              {lockPtpInfoData.MAX_CAP.label}
            </AppTypography>
            <InfoBox.Tooltip text={MAX_CAP_TOOLTIP} />
          </InfoBox.Item>
          <InfoBox.Item>
            <Dashable
              showDash={
                isExtendingLock
                  ? !lockDayInputAmount
                  : !lockPtpInputAmount || !lockDayInputAmount
              }
            >
              <AppTypography variant="caption">
                <TooltipNum
                  amount={maxPtpLockCap}
                  rightSymbol={TokenSymbol.VEPTP}
                >
                  {getCommifiedFormat(maxPtpLockCap, 2)}
                </TooltipNum>{' '}
                {TokenSymbol.VEPTP}
              </AppTypography>
            </Dashable>
          </InfoBox.Item>
        </InfoBox.Item>
      )}
      {/* Earned */}
      {ptp.hasLocked && isInLockPtpModal && (
        <InfoBox.Item>
          <InfoBox.Item>
            <AppTypography variant="caption">
              {lockPtpInfoData.EARNED.label}
            </AppTypography>
            <InfoBox.Tooltip text={lockPtpInfoData.EARNED.tooltip} />
          </InfoBox.Item>
          <InfoBox.Item>
            <Dashable showDash={!lockPtpInputAmount}>
              <AppTypography variant="caption">
                <TooltipNum
                  amount={vePtp.balance.locking}
                  rightSymbol={TokenSymbol.VEPTP}
                >
                  {getCommifiedFormat(vePtp.balance.locking, 2)}
                </TooltipNum>{' '}
                {TokenSymbol.VEPTP}
              </AppTypography>
            </Dashable>
          </InfoBox.Item>
        </InfoBox.Item>
      )}
      {/* To Receive */}
      <InfoBox.Item>
        <InfoBox.Item>
          <AppTypography variant="caption">
            {lockPtpInfoData.TO_RECEIVE.label}
          </AppTypography>
          <InfoBox.Tooltip text={lockPtpInfoData.TO_RECEIVE.tooltip} />
        </InfoBox.Item>
        <InfoBox.Item>
          <Dashable
            showDash={
              isExtendingLock
                ? !lockDayInputAmount
                : !lockPtpInputAmount || !lockDayInputAmount
            }
          >
            <Colorized variant={toReceiveGtMaxCap ? 'red' : 'green'}>
              <AppTypography variant="caption">
                <TooltipNum
                  amount={veptpToReceive}
                  rightSymbol={TokenSymbol.VEPTP}
                >
                  {getCommifiedFormat(veptpToReceive, 2)}
                </TooltipNum>{' '}
                {TokenSymbol.VEPTP}
              </AppTypography>
            </Colorized>
          </Dashable>
        </InfoBox.Item>
      </InfoBox.Item>
    </InfoBox>
  )
}

export default LockPtpInfoBox
