import styled, { css } from 'styled-components'
import AppTokenInput from '../../../components/TokenInput/AppTokenInput'
export const StyledInputField = styled(AppTokenInput.InputField)`
  ${() => css`
    .MuiInput-input {
      text-align: left;
      padding-left: 0;
    }
  `}
`
