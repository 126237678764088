import React, { ReactElement } from 'react'
import WaitForConfirmationModal from '../../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import DisabledAppTokenInput from '../../../../components/TokenInput/DisabledAppTokenInput'
import { LP_TOKENS } from '../../../../config/contracts'
import { useModal } from '../../../../contexts/ModalContext'
import { usePoolInput } from '../../../../contexts/PoolInputContext'
import { useStakeLpData } from '../../../../contexts/StakeLpDataContext'
import PoolStakeModalInfoBox from '../PoolStakeModalContainer/PoolStakeModalInfoBox'

interface Props {
  isOpen: boolean
  isMoneyPoolMigrationFromBaseV1?: boolean
}

function PoolStakeWaitForConfirmationModalContainer({
  isOpen,
  isMoneyPoolMigrationFromBaseV1 = false,
}: Props): ReactElement {
  const {
    modalDispatch,
    modalState,
    actions: { closeModal },
  } = useModal()
  let { selectedPoolSymbol } = usePoolInput()
  if (
    isMoneyPoolMigrationFromBaseV1 &&
    modalState.poolSymbols &&
    modalState.poolSymbols[0]
  ) {
    selectedPoolSymbol = modalState.poolSymbols[0]
  }
  const tokenSymbol = modalState.tokenSymbols && modalState.tokenSymbols[0]
  const handleCloseModal = () => {
    modalDispatch(closeModal())
  }
  const { lp } = useStakeLpData()
  if (!tokenSymbol) return <></>
  const tokenSymbolForDisplay =
    LP_TOKENS[selectedPoolSymbol][tokenSymbol]?.tokenSymbolForDisplay ||
    tokenSymbol
  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      subtitle={`Stake ${tokenSymbolForDisplay}`}
    >
      <DisabledAppTokenInput
        tokenItems={[
          {
            tokenSymbol: tokenSymbolForDisplay,
            value:
              lp.inTermsOfToken.stakable[selectedPoolSymbol][tokenSymbol] ||
              '0.0',
          },
        ]}
      />
      <PoolStakeModalInfoBox
        tokenSymbol={tokenSymbol}
        isMoneyPoolMigrationFromBaseV1={isMoneyPoolMigrationFromBaseV1}
      />
    </WaitForConfirmationModal>
  )
}

export default PoolStakeWaitForConfirmationModalContainer
