import React, { ReactElement, useMemo, useState } from 'react'
import PlatopiaExportIcon from '../../../assets/icons/export-icon-platopia-blue.svg'
import AppTypography from '../../../components/AppTypography/AppTypography'
import NftCard from '../../../components/cards/NftCard/NftCard'
import ItemSelectionModal from '../../../components/Modal/ItemSelectionModal/ItemSelectionModal'
import nftUIData from '../../../config/nftUIData'
import { externalLinksInMenu } from '../../../config/routes'
import { useVePtp } from '../../../contexts/VePtpContext'
import { Colorized, ExternalLink } from '../../../globalStyles'
import useNftChangeAction from '../../../hooks/nft/useNftChangeAction'
import { NftTypeId } from '../../../interfaces/nft'
import { CTAButton, EmptyNftDisplay } from './ChangeNftModal.elements'

interface NftDataType {
  id: string
  imgSrc: string
  name: string
  type: NftTypeId
  ability: ReactElement
}
interface Props {
  isOpen: boolean
}
function ChangeNftModal({ isOpen }: Props): ReactElement {
  const [nftFilterInput, setNftFilterInput] = useState('')
  const { handleNftChangeAction } = useNftChangeAction()
  const { nft } = useVePtp()
  const equippedNftId = nft.equippedId
  const originalNftData = useMemo(() => {
    const equippedNft = equippedNftId && nft.all[equippedNftId]
    const equippedNftData: NftDataType | null = equippedNft
      ? {
          id: equippedNft.id,
          imgSrc: equippedNft.imgSrc,
          name: equippedNft.name,
          type: equippedNft.type,
          ability: nftUIData[equippedNft.type].getAbilityText({
            value: equippedNft.value,
            showActiveColor: true,
            showActiveText: true,
          }),
        }
      : null

    const excludeEquippedNftData: NftDataType[] = Object.values(nft.all)
      .filter((innerNft) => innerNft.id !== equippedNftId)
      .sort((a, b) => {
        if (a.id > b.id) {
          return 1
        }
        if (a.id < b.id) {
          return -1
        }
        return 0
      })
      .map((innerNft) => ({
        id: innerNft.id,
        imgSrc: innerNft.imgSrc,
        name: innerNft.name,
        type: innerNft.type,
        ability: nftUIData[innerNft.type].getAbilityText({
          value: innerNft.value,
          showActiveColor: !!(equippedNftId === innerNft.id),
          showActiveText: !!(equippedNftId === innerNft.id),
        }),
      }))

    return equippedNftData
      ? [equippedNftData, ...excludeEquippedNftData]
      : [...excludeEquippedNftData]
  }, [nft.all, equippedNftId])

  const filteredNftData = useMemo(() => {
    if (!nftFilterInput) {
      return originalNftData
    }
    return originalNftData.filter((item) => {
      const lowerCaseNftFilterInput = nftFilterInput.toLowerCase()
      // item.subtitle is react element
      const parentProps = item.ability.props as {
        children: [{ props: { children: string } }]
      }

      let abilityString = ''
      if (Array.isArray(parentProps.children)) {
        // get content
        parentProps.children.forEach((child) => {
          abilityString += child.props.children
        })
      }

      return (
        item.id.includes(lowerCaseNftFilterInput) ||
        item.type.toLowerCase().includes(lowerCaseNftFilterInput) ||
        item.name.toLowerCase().includes(lowerCaseNftFilterInput) ||
        abilityString.toLowerCase().includes(lowerCaseNftFilterInput)
      )
    })
  }, [originalNftData, nftFilterInput])

  const hasNfts = originalNftData.length > 0
  const handleOnClickItem = (newId: string) => {
    handleNftChangeAction(equippedNftId, newId)
  }
  return (
    <ItemSelectionModal
      isOpen={isOpen}
      topBarLabel="Select an NFT"
      topBarSubtitle={`Total NFTs: ${originalNftData.length}`}
      {...(hasNfts && {
        filterInputProps: {
          value: nftFilterInput,
          placeholder: 'Search NFT',
          onChange: (value) => setNftFilterInput(value),
        },
      })}
    >
      {!hasNfts && (
        <EmptyNftDisplay>
          <AppTypography textAlign="center" variant="body1">
            You don&apos;t have any NFTs
            <br />
            Get some Platypus NFTs&nbsp;
            <ExternalLink href={externalLinksInMenu.nft.path} disableUnderline>
              <Colorized variant="platopia" style={{ display: 'inline-flex' }}>
                here&nbsp;
                <img
                  src={PlatopiaExportIcon}
                  width="16px"
                  height="16px"
                  style={{ alignSelf: 'center' }}
                />
              </Colorized>
            </ExternalLink>
          </AppTypography>
        </EmptyNftDisplay>
      )}
      {hasNfts &&
        filteredNftData.map((item) => {
          const isSelected = !!(equippedNftId === item.id)
          return (
            <ItemSelectionModal.Item
              key={item.id}
              id={item.id}
              styleForSelectedItem={{ transparent: false }}
              onClick={({ id }) => handleOnClickItem(id)}
              isSelected={isSelected}
            >
              <ItemSelectionModal.ItemLeadingLabel>
                <NftCard
                  id={item.id}
                  variant="small"
                  name={item.type}
                  isEquipped
                  nftImgSrc={item.imgSrc}
                  abililty={item.ability}
                  disableActiveColor={!isSelected}
                />
              </ItemSelectionModal.ItemLeadingLabel>
              <ItemSelectionModal.ItemTrailingLabel>
                <CTAButton customVariant="neutral">
                  {isSelected ? 'Unequip' : 'Equip'}
                </CTAButton>
              </ItemSelectionModal.ItemTrailingLabel>
            </ItemSelectionModal.Item>
          )
        })}
    </ItemSelectionModal>
  )
}

export default ChangeNftModal
