import { Box, Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../contexts/ModalContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import useBreakpoints from '../../../hooks/useBreakpoints'
import useAddTokenToWallet from '../../../hooks/wallet/useAddTokenToWallet'
import CheckCircleIcon from '../../icons/CheckCircleIcon'
import TokenIcon from '../../TokenIcon/TokenIcon'
import Modal from '../Modal'
import { StyledAppButton } from './SubmittedTransactionModal.elements'

interface Props {
  children?: React.ReactNode
  isOpen: boolean
  title?: string | ReactElement
  subtitle?: string | ReactElement
}

function SubmittedTransactionModal({
  children,
  isOpen,
  title,
  subtitle,
}: Props): ReactElement {
  const { isTabletSm } = useBreakpoints()
  const {
    modalState,
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const { network } = useNetwork()

  // explorer url from network hook e.g. https://cchain.explorer.avax-test.network/
  const explorerUrl = network?.blockExplorerUrls[0] || ''
  const transactionUrls = explorerUrl
    ? (modalState.transactionHashes || []).map(
        (hash) => `${explorerUrl}tx/${hash}`,
      )
    : []
  const addTokenToWallet = useAddTokenToWallet()

  return (
    <Modal
      isOpen={isOpen}
      disableCloseBtn
      onClose={() => modalDispatch(closeModal())}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <CheckCircleIcon margin="0 0 12px 0" />
        <Typography variant="h5">
          {title ? title : 'Transaction submitted'}
        </Typography>
        {subtitle && (
          <Typography
            variant="body1"
            style={{
              marginTop: '12px',
              textAlign: 'center',
              padding: '0 16px',
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column" mt={2}>
        {transactionUrls.map((transactionUrl) => {
          return (
            <StyledAppButton
              key={transactionUrl}
              customVariant="neutral"
              onClick={() => {
                window.open(transactionUrl, '_blank')
              }}
              $small
            >
              View On {network?.blockExplorerName}
            </StyledAppButton>
          )
        })}

        {modalState.tokenSymbols &&
          !isTabletSm &&
          modalState.tokenSymbols
            .filter((tokenSymbol) => tokenSymbol !== TokenSymbol.AVAX)
            .map((tokenSymbol) => (
              /** ignore token in non ERC20 AVAX */
              <StyledAppButton
                key={`${tokenSymbol}_add_to_wallet`}
                customVariant="neutral"
                $small
                onClick={() => void addTokenToWallet(tokenSymbol)}
              >
                Add
                <TokenIcon tokenSymbol={tokenSymbol} margin="0 4px 0 8px" />
                {tokenSymbol} to Wallet
              </StyledAppButton>
            ))}
        {children}
      </Box>
    </Modal>
  )
}
export default SubmittedTransactionModal

interface AdditionalButtonProps {
  children: React.ReactNode
  onClick: () => void
}
const AdditionalButton = ({
  onClick,
  children,
}: AdditionalButtonProps): ReactElement => {
  return (
    <StyledAppButton onClick={() => onClick()} fullWidth>
      {children}
    </StyledAppButton>
  )
}

SubmittedTransactionModal.AdditionalButton = AdditionalButton
