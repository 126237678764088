import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { voter } from '../../config/contracts'
import { VOTER_ABI } from '../../config/contracts/wagmiAbis/Voter'
import { useModal } from '../../contexts/ModalContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useVotingData } from '../../contexts/VotingDataContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'
import { isUserDeniedTransaction } from '../../utils/contract'
import useRevertReason from '../useRevertReason'
type Props = {
  handleClaim: () => Promise<void>
}
const useClaimBribeRewards = (): Props => {
  const { chainId } = useWeb3()
  const {
    user: { earnedBribeRewards },
  } = useVotingData()
  const getRevertReason = useRevertReason()
  const { showMessage } = useSnackbar()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const handleClaim = async () => {
    let writeContractResult: WriteContractResult | undefined

    try {
      const voterAddress = voter.getAddress(chainId)
      if (voterAddress) {
        modalDispatch(openModal(ModalId.VOTE_WAIT_FOR_CONFIRMATION))
        const config = await prepareWriteContract({
          address: voterAddress,
          abi: VOTER_ABI,
          functionName: 'claimBribes',
          args: [earnedBribeRewards.map((reward) => reward.lpTokenAddress)],
          chainId,
        })
        writeContractResult = await writeContract(config)

        if (writeContractResult) {
          const { hash, wait } = writeContractResult
          await wait()
          modalDispatch(
            openModal(ModalId.TRANSACTION_SUBMITTED, {
              transactionHashes: [hash],
            }),
          )
          showMessage(`Successfully claim bribe rewards.`)
        } else {
          showMessage('Transaction failed.', 'warning')
          modalDispatch(openModal(ModalId.UNSET))
        }
      }
    } catch (err) {
      if (!isUserDeniedTransaction(err)) {
        Sentry.setContext('contract_call', {
          name: 'claim bribe rewards',
        })
        Sentry.captureException(err)
      }
      const reason = await getRevertReason(err)
      showMessage(reason || 'Transaction failed.', 'warning')
      modalDispatch(openModal(ModalId.UNSET))
    }
  }
  return { handleClaim }
}

export default useClaimBribeRewards
