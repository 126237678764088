import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { BigNumber, utils } from 'ethers'
import ReactGA from 'react-ga'
import { ActionId } from '../../config/action'
import { TOKENS } from '../../config/contracts'
import { POOLS } from '../../config/contracts/pool'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { POOL__ABI } from '../../config/contracts/wagmiAbis/Pool'
import { POOLAVAX_ABI } from '../../config/contracts/wagmiAbis/PoolAvax'
import { useModal } from '../../contexts/ModalContext'
import { usePoolInput } from '../../contexts/PoolInputContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useUserPreference } from '../../contexts/UserPreferenceContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'
import { getDeadline } from '../../utils/contract'
import useTransaction from '../useTransaction'

interface Props {
  handleDepositToken: () => Promise<void>
}

const useDepositToken = (
  tokenSymbol: TokenSymbol | null | undefined,
): Props => {
  const { chainId, account } = useWeb3()
  const { userPreference } = useUserPreference()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { showMessage } = useSnackbar()
  const { depositLiquidity, selectedPoolSymbol } = usePoolInput()
  const { checkIfUserDeniedTransaction } = useTransaction()
  const handleDepositToken = async () => {
    if (tokenSymbol) {
      const tokenInstance = TOKENS[tokenSymbol]
      const poolAddress = POOLS[selectedPoolSymbol]?.address[chainId]
      const tokenAddress = tokenInstance.getAddress(chainId)
      let writeContractResult: WriteContractResult | undefined
      try {
        const amount = utils.parseUnits(
          depositLiquidity.amount,
          TOKENS[tokenSymbol].decimals,
        )

        if (account && poolAddress) {
          ReactGA.event({
            category: 'button',
            action: 'click',
            label: 'deposit',
          })
          modalDispatch(openModal(ModalId.POOL_DEPOSIT_WAIT_FOR_CONFIRMATION))
          if (tokenSymbol === TokenSymbol.AVAX) {
            const config = await prepareWriteContract({
              address: poolAddress,
              abi: POOLAVAX_ABI,
              functionName: 'depositETH',
              args: [
                account,
                BigNumber.from(
                  await getDeadline(userPreference.transactionDeadline),
                ),
              ],
              chainId,
              overrides: { value: amount },
            })
            writeContractResult = await writeContract(config)
          } else if (tokenAddress) {
            const config = await prepareWriteContract({
              address: poolAddress,
              abi: POOL__ABI,
              functionName: 'deposit',
              args: [
                tokenAddress,
                amount,
                account,
                BigNumber.from(
                  await getDeadline(userPreference.transactionDeadline),
                ),
              ],
              chainId,
            })
            writeContractResult = await writeContract(config)
          }
          if (writeContractResult) {
            const { hash, wait } = writeContractResult
            await wait()
            showMessage('Successfully deposited.')
            modalDispatch(
              openModal(ModalId.TRANSACTION_SUBMITTED, {
                transactionHashes: [hash],
              }),
            )
          } else {
            showMessage('Transaction failed.', 'warning')
            modalDispatch(openModal(ModalId.UNSET))
          }
        }
      } catch (err) {
        await checkIfUserDeniedTransaction(
          err,
          undefined,
          {
            name: 'pool.deposit',
            token_symbol: tokenSymbol,
            amount: depositLiquidity.amount,
          },
          ActionId.POOL_DEPOSIT,
        )
      }
    }
  }
  return { handleDepositToken }
}

export default useDepositToken
