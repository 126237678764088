import React, { ReactElement } from 'react'
import { useTheme } from 'styled-components'
import PieChart from '../../../components/charts/PieChart/PieChart'
import { useVePtp } from '../../../contexts/VePtpContext'

/**@todo replace hard code darkBlue color #122c7b with main header 2.0 color */

function PtpAllocationPieChart(): ReactElement {
  const { ptp } = useVePtp()
  const theme = useTheme()
  const colors: string[] = [
    theme.palette.primary['500'],
    theme.palette.secondary.main,
    '#122c7b',
  ]
  const dataset: number[] = [
    Number(ptp.percent.locked),
    Number(ptp.percent.staked),
    Number(ptp.percent.available),
  ]

  return (
    <PieChart
      width="60px"
      height="60px"
      disableHoverOffset
      data={{
        dataset: dataset,
        colors: colors,
      }}
    />
  )
}

export default PtpAllocationPieChart
