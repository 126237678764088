import React, { ReactElement, useEffect } from 'react'
import ReactGA from 'react-ga'
import routes from '../../config/routes'
import GaugeVotingContainer from '../../containers/GaugeVotingContainer/GaugeVotingContainer'
function GaugeVotingPage(): ReactElement {
  useEffect(() => {
    ReactGA.pageview(routes.GAUGE_VOTING.path)
  }, [])
  return <GaugeVotingContainer />
}

export default GaugeVotingPage
