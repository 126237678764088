import {
  getCommifiedFormat,
  getSfFormat,
  isParsableString,
  strToWad,
} from '@hailstonelabs/big-number-utils'
import { alpha, useTheme } from '@mui/material'
import { constants, utils } from 'ethers'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import TooltipNum from '../../components/InfoBox/TooltipNum'
import { TOKENS } from '../../config/contracts/token'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { AVAX_AMOUNT_RESERVED_FOR_GAS } from '../../constants'
import { useBalance } from '../../contexts/BalanceContext'
import { useModal } from '../../contexts/ModalContext'
import { useSwap } from '../../contexts/SwapContext'
import useDebounce from '../../hooks/useDebounce'
import { ModalId } from '../../interfaces/Modal'
import {
  InputFieldContainer,
  StyledTokenInput,
  TokenInputInnerContainer,
} from './TokenInputsContainer.elements'

interface Props {
  disabled?: boolean
}

function TokenInputsContainer({ disabled }: Props): ReactElement {
  const theme = useTheme()
  const { tokenAmounts } = useBalance()
  // true if an user has updated from amount. false if an user has updated to amount.
  const [userChangedFrom, setUserChangedFrom] = useState<boolean | null>(null)
  // from/to amount inputted in the text field
  const [toTokenAmountInputted, setToTokenAmountInputted] = useState('')
  const [fromTokenAmountInputted, setFromTokenAmountInputted] = useState('')
  // debounced from/to input amount
  const debouncedToAmountInputted = useDebounce(toTokenAmountInputted)
  const debouncedFromAmountInputted = useDebounce(fromTokenAmountInputted)
  const [displayedFromValue, setDisplayedFromValue] = useState('')
  const [displayedToValue, setDisplayedToValue] = useState('')
  const {
    // from/to amount quoted from swap context
    fromTokenAmount,
    toTokenAmount,
    fromTokenSymbol,
    toTokenSymbol,
    switchTokensDirection,
    updateFromTokenAmount,
    updateToTokenAmount,
    poolSymbolsPath,
  } = useSwap()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()

  // when debounced to/from amount changes, update it to swap context
  useEffect(() => {
    void updateToTokenAmount(debouncedToAmountInputted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedToAmountInputted])
  useEffect(() => {
    void updateFromTokenAmount(debouncedFromAmountInputted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFromAmountInputted])

  useEffect(() => {
    setFromTokenAmountInputted('')
    setToTokenAmountInputted('')
  }, [fromTokenSymbol, toTokenSymbol])

  const handleClickMaxButton = useCallback(() => {
    setUserChangedFrom(true)
    if (fromTokenSymbol != null) {
      let maxAmountWad = strToWad(tokenAmounts[fromTokenSymbol])
      // when fromToken is AVAX, reserve some AVAX for gas
      if (fromTokenSymbol === TokenSymbol.AVAX) {
        maxAmountWad = maxAmountWad.sub(strToWad(AVAX_AMOUNT_RESERVED_FOR_GAS))
        // avoid negative amount
        maxAmountWad = maxAmountWad.gt(constants.Zero)
          ? maxAmountWad
          : constants.Zero
      }
      setFromTokenAmountInputted(utils.formatEther(maxAmountWad))
    }
  }, [fromTokenSymbol, setFromTokenAmountInputted, tokenAmounts])

  const handleFromInputChange = (value: string) => {
    setUserChangedFrom(true)
    if (value === '') {
      setFromTokenAmountInputted('')
      return
    }
    /** check if value isParsableString, and dp is <= FROM token dp */
    if (!fromTokenSymbol) {
      return
    }
    const fromToken = TOKENS[fromTokenSymbol]
    if (isParsableString(value, fromToken.decimals, true)) {
      setFromTokenAmountInputted(value)
    }
  }

  const handleToInputChange = (value: string) => {
    // newton method not support cross pool
    if (poolSymbolsPath.length > 1) {
      return
    }
    setUserChangedFrom(false)
    if (value === '') {
      setToTokenAmountInputted('')
      return
    }
    /** check if value isParsableString, and dp is <= TO token dp */
    if (!toTokenSymbol) {
      return
    }
    const toToken = TOKENS[toTokenSymbol]
    if (isParsableString(value, toToken.decimals, true)) {
      setToTokenAmountInputted(value)
    }
  }

  /** Display exact num for user input value (not debounced, for instant update)
   *  Display 6 s.f. for the derived value */
  useEffect(() => {
    if (userChangedFrom === null) return
    if (userChangedFrom) {
      setDisplayedFromValue(fromTokenAmountInputted)
      setDisplayedToValue(getSfFormat(toTokenAmount, 6))
    } else {
      setDisplayedFromValue(getSfFormat(fromTokenAmount, 6))
      setDisplayedToValue(toTokenAmountInputted)
    }
  }, [
    fromTokenAmount,
    fromTokenAmountInputted,
    toTokenAmount,
    toTokenAmountInputted,
    userChangedFrom,
  ])
  return (
    <>
      <StyledTokenInput
        onChange={handleFromInputChange}
        value={displayedFromValue}
        disabled={disabled}
      >
        {!disabled && (
          <StyledTokenInput.LabelItem
            leadingLabel="From"
            trailingLabel={
              fromTokenSymbol === null || tokenAmounts === null ? (
                ''
              ) : (
                <>
                  Balance:{' '}
                  <TooltipNum amount={tokenAmounts[fromTokenSymbol]}>
                    {getCommifiedFormat(tokenAmounts[fromTokenSymbol])}
                  </TooltipNum>
                </>
              )
            }
          />
        )}
        <TokenInputInnerContainer
          bgcolor={
            fromTokenSymbol
              ? alpha(theme.palette.primary[500], 0.2)
              : theme.palette.common.black
          }
        >
          <StyledTokenInput.SelectTokenButton
            token={fromTokenSymbol}
            onSelectToken={() =>
              modalDispatch(openModal(ModalId.SELECT_FROM_TOKEN))
            }
            disabled={disabled}
          />
          <InputFieldContainer>
            <StyledTokenInput.InputField
              placeholder="0.0"
              disableUnderline
              disabled={!fromTokenSymbol}
              readOnly={disabled ? 'readOnly' : undefined}
            ></StyledTokenInput.InputField>
            {!disabled && (
              <StyledTokenInput.ActionButton
                onClick={handleClickMaxButton}
                disabled={!fromTokenSymbol}
              >
                Max
              </StyledTokenInput.ActionButton>
            )}
          </InputFieldContainer>
        </TokenInputInnerContainer>
        {!disabled && (
          <StyledTokenInput.SwapDirectionButton
            onClick={switchTokensDirection}
          />
        )}
      </StyledTokenInput>
      <StyledTokenInput.SwapDirectionButton
        onClick={switchTokensDirection}
        isMobile
      />
      <StyledTokenInput
        onChange={handleToInputChange}
        value={displayedToValue}
        disabled={disabled}
      >
        {!disabled && (
          <StyledTokenInput.LabelItem
            leadingLabel="To"
            trailingLabel={
              toTokenSymbol === null || tokenAmounts === null ? (
                ''
              ) : (
                <>
                  Balance:{' '}
                  <TooltipNum amount={tokenAmounts[toTokenSymbol]}>
                    {getCommifiedFormat(tokenAmounts[toTokenSymbol])}
                  </TooltipNum>
                </>
              )
            }
          />
        )}
        <TokenInputInnerContainer
          bgcolor={
            toTokenSymbol
              ? alpha(theme.palette.primary[500], 0.2)
              : theme.palette.common.black
          }
        >
          <StyledTokenInput.SelectTokenButton
            token={toTokenSymbol}
            onSelectToken={() =>
              modalDispatch(openModal(ModalId.SELECT_TO_TOKEN))
            }
            disabled={disabled}
          />
          <InputFieldContainer>
            <StyledTokenInput.InputField
              placeholder="0.0"
              disableUnderline
              disabled={!toTokenSymbol}
              readOnly={disabled ? 'readOnly' : undefined}
            ></StyledTokenInput.InputField>
          </InputFieldContainer>
        </TokenInputInnerContainer>
      </StyledTokenInput>
    </>
  )
}

export default React.memo(TokenInputsContainer)
