import * as Sentry from '@sentry/react'
import { WriteContractResult } from '@wagmi/core'
import { ContractTransaction } from 'ethers'
import { ActionId } from '../config/action'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import { useModal } from '../contexts/ModalContext'
import { useNetwork } from '../contexts/NetworkContext'
import { useSnackbar } from '../contexts/SnackbarContext'
import { ModalId } from '../interfaces/Modal'
import * as ContractUtils from '../utils/contract'
import useRevertReason from './useRevertReason'
type ReturnType = {
  waitForTransaction: (
    transaction: WriteContractResult | undefined,
    messages: {
      success: string
      fail?: string
    },
    configs?: {
      disableSubmittedTransactionModal?: boolean
      submittedTransactionModalProps?: {
        tokenSymbols?: TokenSymbol[]
      }
    },
  ) => Promise<string | undefined>
  openModalForTransactionHashes: (
    transactionHashes: string[] | undefined,
  ) => void
  checkIfUserDeniedTransaction: (
    err: unknown,
    transaction: ContractTransaction | undefined,
    sentryData: {
      [key: string]: string | null | undefined
      name: string
    },
    actionId?: ActionId,
  ) => Promise<void>
}

function useTransaction(): ReturnType {
  const { readOnlyProvider } = useNetwork()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { showMessage } = useSnackbar()
  const getRevertReason = useRevertReason()

  /**
   * It will open SubmittedTransactionModal and show snackbar message accordingly.
   * @param {WriteContractResult | undefined} transaction
   * @param {object} messages
   * @param {boolean} showSubmittedTransactionModal
   * @returns {string | undefined} transaction hash
   */
  const waitForTransaction = async (
    transaction: WriteContractResult | undefined,
    messages: {
      success: string
      fail?: string
    },
    configs?: {
      submittedTransactionModalProps?: {
        tokenSymbols?: TokenSymbol[]
      }
      disableSubmittedTransactionModal?: boolean
    },
  ) => {
    if (transaction) {
      await ContractUtils.waitForTransaction(readOnlyProvider, transaction.hash)
      if (!configs?.disableSubmittedTransactionModal) {
        modalDispatch(
          openModal(ModalId.TRANSACTION_SUBMITTED, {
            transactionHashes: [transaction.hash],
            ...configs?.submittedTransactionModalProps,
          }),
        )
      }

      showMessage(messages.success)
    } else {
      showMessage(messages.fail || 'Transaction failed.', 'warning')
      modalDispatch(openModal(ModalId.UNSET))
    }
    return transaction?.hash
  }
  /**
   * It checks whether the error occured is because a user denies the transaction.
   * @param {unknown} error
   * @param {ContractTransaction | undefined} transaction
   * @param {object} sentryData
   */
  const checkIfUserDeniedTransaction = async (
    error: unknown,
    transaction: ContractTransaction | undefined,
    sentryData: {
      name: string
      [key: string]: string | null | undefined
    },
    actionId?: ActionId,
  ) => {
    if (!ContractUtils.isUserDeniedTransaction(error)) {
      Sentry.setContext('contract_call', sentryData)
      Sentry.captureException(error)
    }
    const reason = await getRevertReason(error, transaction, actionId)
    showMessage(reason || 'Transaction failed.', 'warning')
    modalDispatch(openModal(ModalId.UNSET))
  }

  /**
   * It opens SubmittedTransactionModal and show all transaction hashes at once.
   * @param {string[] | undefined} transactionHashes
   */
  const openModalForTransactionHashes = (
    transactionHashes: string[] | undefined,
  ) => {
    modalDispatch(
      openModal(ModalId.TRANSACTION_SUBMITTED, {
        transactionHashes,
      }),
    )
  }
  return {
    waitForTransaction,
    openModalForTransactionHashes,
    checkIfUserDeniedTransaction,
  }
}

export default useTransaction
