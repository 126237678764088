import { alpha, Box, IconButton, Modal, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import { ModalBorderColorType } from './Modal'

const styleVariables = {
  '--modal-body-padding': '24px',
  '--modal-body-padding-lg': '32px',
}

interface ModalBodyProps {
  maxWidth?: string
  maxHeight?: string
  borderColor?: ModalBorderColorType
}
export const ModalBody = styled(Box)<ModalBodyProps>`
  ${({ theme, maxWidth, maxHeight, borderColor }) => css`
    ${styleVariables}
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    padding: var(--modal-body-padding) var(--modal-body-padding)
      var(--modal-body-padding-lg) var(--modal-body-padding);
    box-shadow: 0px 0px 20px ${alpha(theme.palette.primary['A200'], 0.2)};
    z-index: 9999;
    background: ${theme.palette.primary[900]};
    border: 1px solid ${theme.palette.primary['A200']};
    max-width: ${maxWidth};
    position: relative;
    max-height: ${maxHeight ? maxHeight : '90vh'};
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin: 16px;
    }
    @media only screen and (max-width: ${theme.breakpoints.values.sm}px) {
      margin: 12px;
      padding: 16px 16px 24px 16px;
    }

    ${borderColor === 'warning' &&
    css`
      border-color: ${theme.palette.error.main};
    `}
  `}
`

export const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`

// Modal Topbar
export const Header = styled(Typography)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`

export const CloseButton = styled(IconButton)`
  /* position: absolute; */
  right: 0;
`
export const TopbarConatiner = styled(Box)`
  position: relative;
`
