import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { Box, TypographyProps } from '@mui/material'
import React, { ReactElement } from 'react'
import {
  SelectItemLabel,
  StyledSelectItemButton,
} from './SelectItemButton.elements'

interface SelectItemButtonProps {
  children: React.ReactNode
  className?: string | undefined
  onSelectItem?: () => void | undefined
  disabled?: boolean | undefined
}
function SelectItemButton({
  children,
  className,
  onSelectItem,
  disabled,
}: SelectItemButtonProps): ReactElement {
  return (
    <StyledSelectItemButton
      className={className}
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      onClick={disabled ? undefined : onSelectItem}
      disabledPointer={!onSelectItem}
      disabled={disabled}
    >
      {children}
      {onSelectItem && (
        <KeyboardArrowDownRoundedIcon className="select-item-button__arrow-down" />
      )}
    </StyledSelectItemButton>
  )
}

export default SelectItemButton

interface ItemIconWrapperProps {
  children: React.ReactNode
  className?: string
}
SelectItemButton.ItemIconWrapper = function ItemIconWrapper({
  children,
  className,
}: ItemIconWrapperProps): ReactElement {
  return (
    <Box className={className} display="flex" marginRight="10px">
      {children}
    </Box>
  )
}

SelectItemButton.ItemLabel = function ItemLabel({
  children,
  ...otherProps
}: TypographyProps): ReactElement {
  return (
    <SelectItemLabel variant="body1" {...otherProps}>
      {children}
    </SelectItemLabel>
  )
}
