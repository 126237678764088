import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useTheme } from '@mui/material'
import React, { ReactElement } from 'react'
import { Container, Message } from './WarningMessage.elements'

export type WarningMessageSeverityType = 'info' | 'warning'

interface Props {
  message?: string
  className?: string
  children?: React.ReactNode
  severity?: WarningMessageSeverityType
  color?: string
  style?: React.CSSProperties
  iconSize?: string
  enableBackground?: boolean
  margin?: 'sm' | 'none'
}
/**
 * @param {ReactNode} [children] - Children first when message is specified as well.
 * @param {string} [message] - An alternative way to pass a warning message
 */
function WarningMessage({
  message,
  className,
  children,
  severity = 'warning',
  color,
  style,
  iconSize = '24px',
  enableBackground,
  margin,
}: Props): ReactElement {
  const theme = useTheme()
  return (
    <Container
      display="flex"
      justifyContent="center"
      alignItems="center"
      color={color ? color : theme.palette.text.primary}
      className={className}
      style={style}
      $severity={severity}
      $enableBackground={enableBackground}
      $margin={margin}
    >
      {severity !== 'info' && (
        <WarningAmberIcon
          className="warning__icon"
          sx={{
            color: color || theme.palette.text.primary,
            fontSize: iconSize,
          }}
        />
      )}
      <Message>{children ? children : message ? message : ''}</Message>
    </Container>
  )
}

export default React.memo(WarningMessage)
