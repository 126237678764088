import { Voter } from '../../../../types/ethers-contracts'
import { ChainId } from '../../networks'
import VoterABI from '../abis/Voter.json'
import { Contract } from '../Contract'
import { VOTER_ABI } from '../wagmiAbis/Voter'

export const voter = new Contract<Voter, typeof VOTER_ABI>(
  {
    [ChainId.AVALANCHE]: '0x1f6B6b505D199B9bd0a6642B8d44533a811598da',
    [ChainId.FUJI]: '0xdb8532aaCD06512ae8C895eA66Dc74e0d99fEbc1',
  },
  VoterABI,
  VOTER_ABI,
)
