import { getDpFormat } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import { BigNumber } from 'ethers'
import React from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import InfoBox from '../../../components/InfoBox/InfoBox'
import { useBalance } from '../../../contexts/BalanceContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { Colorized } from '../../../globalStyles'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import { Container, StyledIcon } from './VePtpBenefitCard.element'

interface VePtpBenefitCardProps {
  label: string
  description: string
  topIcon: React.ReactElement
  isActive: boolean
  /**@todo replace with  SafeBNFormat */
  aprValue: string | BigNumber | null
  tooltip: JSX.Element | undefined | null
  action: () => void
}

export const VePtpBenefitCard = ({
  label,
  description,
  topIcon,
  isActive,
  aprValue,
  tooltip,
  action,
}: VePtpBenefitCardProps) => {
  const { input } = useVePtp()
  const { isTokenPriceFetched } = useBalance()

  return (
    <Container
      $isExpanded={!input.isBenefitSectionCollapsed}
      $isDimmed={!isActive}
      onClick={action}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="2px"
      >
        <AppTypography
          variant="body2"
          marginRight={input.isBenefitSectionCollapsed ? '6px' : '0px'}
        >
          <Colorized variant="platopia">
            {input.isBenefitSectionCollapsed ? <>{label}</> : <b>{label}</b>}
          </Colorized>
        </AppTypography>
        <StyledIcon $isShrink={input.isBenefitSectionCollapsed}>
          <Colorized variant="platopia">{topIcon}</Colorized>
        </StyledIcon>
      </Box>
      {!input.isBenefitSectionCollapsed && (
        <Box display="flex" flexDirection="column">
          {aprValue && (
            <InfoBox.Item justifyContent="flex-start">
              <AppTypography variant="caption2">
                APR:{' '}
                {showDashIfNecessary(
                  !isTokenPriceFetched,
                  getDpFormat(aprValue, 1),
                )}
                %
              </AppTypography>
              {tooltip && tooltip}
            </InfoBox.Item>
          )}
          <AppTypography variant="caption2" transparent>
            {description}
          </AppTypography>
        </Box>
      )}
    </Container>
  )
}
