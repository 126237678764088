import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppButton from '../../../components/buttons/AppButton/AppButton'

export const Container = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    background: radial-gradient(
        18% 28% at 0% 0%,
        rgba(50, 255, 68, 0.4) 0%,
        rgba(50, 255, 68, 0.24) 34.22%,
        rgba(12, 88, 255, 0) 100%
      ),
      radial-gradient(
        18% 28% at 100% 0%,
        #0050ff 0%,
        rgba(6, 45, 130, 0.51) 74.33%,
        rgba(6, 45, 130, 0) 100%
      ),
      linear-gradient(119.51deg, #0e0f28 19.73%, #000113 100%);
    padding: 20px;
    border-radius: ${theme.borderRadius.md};
    border: 1px solid ${theme.palette.primary[500]};
    margin-bottom: 20px;
  `}
`

// PtpInfo
export const PtpInfoContainer = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      justify-content: center;
    }
  `}
`

interface PtpInfoItemProps {
  wrappable?: boolean
}
export const PtpInfoItem = styled(Box)<PtpInfoItemProps>`
  ${({ theme, wrappable }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${wrappable &&
    css`
      flex-wrap: wrap;

      @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
        margin: 8px 4px;
        flex-direction: column;
        justify-content: center;
      }
    `}

    margin: 0 4px;
    .ptp-info-item__caption {
      margin-right: 8px;

      @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
        margin-right: 0;
      }
    }
  `}
`

export const StyledAppButton = styled(AppButton)`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.rd};
    padding: 10px 26px;
  `}
`
