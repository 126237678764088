import { useMemo } from 'react'
import { WalletId, WALLETS } from '../../config/wallet'
import useBreakpoints from '../useBreakpoints'
import useWalletConnection from './useWalletConnection'

interface ReturnType {
  supportedWalletIds: WalletId[]
  otherSupportedWalletIds: WalletId[]
}

/**
 * @dev Returns a filtered walletId depends on window size.
 * @returns {ReturnType}
 */
function useWalletFilterList(): ReturnType {
  const { isTabletLg } = useBreakpoints()
  const { connectedWalletId } = useWalletConnection()

  /**
   * @dev when the user is not connected to the wallet
   * @returns {WalletId[]} filtered version of all wallets available
   * for mobile version. Desktop version returns ALL available walletId
   */
  const supportedWalletIds = useMemo<WalletId[]>(() => {
    const allWalletIds = Object.values(WalletId)
    return isTabletLg
      ? allWalletIds.filter(
          (walletId: WalletId) => WALLETS[walletId].isMobileSupported,
        )
      : allWalletIds
  }, [isTabletLg])

  /**
   * @dev when the user is connected to the wallet
   * @returns {WalletId[]} filtered version of all wallets available
   * EXCEPT connected walletId
   */
  const otherSupportedWalletIds = useMemo<WalletId[]>(() => {
    return supportedWalletIds.filter(
      (walletId) => connectedWalletId !== walletId,
    )
  }, [connectedWalletId, supportedWalletIds])

  return {
    supportedWalletIds,
    otherSupportedWalletIds,
  }
}

export default useWalletFilterList
