import React, { ReactElement } from 'react'
import { Colorized } from '../../globalStyles'
interface Props {
  children: React.ReactNode
  positive?: boolean
  colorized?: boolean
}

function Sign({ children, positive, colorized }: Props): ReactElement {
  const renderSignedChildren = () => {
    return (
      <>
        {positive && '+'}
        {children}
      </>
    )
  }
  return colorized ? (
    <Colorized variant={positive ? 'green' : 'red'}>
      {renderSignedChildren()}
    </Colorized>
  ) : (
    renderSignedChildren()
  )
}

export default Sign
