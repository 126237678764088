import {
  getPercentageFromTwoWAD,
  nativeToWAD,
  safeWdiv,
  strToWad,
  wmul,
} from '@hailstonelabs/big-number-utils'
import { BigNumber, constants, utils } from 'ethers'
import { LP_TOKENS, TOKENS } from '../config/contracts'
import { PoolSymbol } from '../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import { ChainId } from '../config/networks'
import { GaugeDataType } from '../containers/GaugeVotingContainer/UserVoteAllocationTable/UserVoteAllocationTableContext'
import {
  HexString,
  PoolSymbolStringBigNumberType,
  PoolSymbolStringStringType,
  TokenSymbolStringType,
} from '../interfaces/common'

export const checkSumOfPercentagesMoreThan100 = (
  percentages: {
    [id in PoolSymbol]?: {
      [id in TokenSymbol]?: string
    }
  },
): boolean => {
  let totalPercentageWad = constants.Zero
  const poolSymbols = Object.keys(percentages) as PoolSymbol[]
  poolSymbols.forEach((poolSymbol) => {
    const assetTokenSymbols = Object.keys(
      percentages[poolSymbol] || {},
    ) as TokenSymbol[]
    for (const assetTokenSymbol of assetTokenSymbols) {
      const percentage = percentages?.[poolSymbol]?.[assetTokenSymbol]
      if (percentage) {
        totalPercentageWad = totalPercentageWad.add(strToWad(percentage))
      }
    }
  })
  return totalPercentageWad.gt(strToWad('100'))
}
/**
 * Get Vote Delta to execute voter.vote function in SC
 * @param proposedUserVotingWeightPercentages sum of percentages cannot more than 100 percent
 * @param originalVotingWeightOfEachAssets
 * @param vePtpBalanceWad
 * @param chainId
 * @returns
 */
export const getLpTokenAddressesAndVePtpVoteDeltaWads = (
  proposedUserVotingWeightPercentages: {
    [id in PoolSymbol]?: {
      [id in TokenSymbol]?: string
    }
  },
  originalVotingWeightOfEachAssets: PoolSymbolStringStringType,
  vePtpBalanceWad: BigNumber,
  chainId: ChainId,
): { lpTokenAddresses: HexString[]; vePtpVoteDeltaWads: BigNumber[] } => {
  const lpTokenAddresses: HexString[] = []
  const vePtpVoteDeltaWads: BigNumber[] = []
  if (checkSumOfPercentagesMoreThan100(proposedUserVotingWeightPercentages)) {
    throw new Error('User has not enough proposed vote.')
  }
  const poolSymbols = Object.keys(
    proposedUserVotingWeightPercentages,
  ) as PoolSymbol[]
  poolSymbols.forEach((poolSymbol) => {
    const assetTokenSymbols = Object.keys(
      proposedUserVotingWeightPercentages[poolSymbol] || {},
    ) as TokenSymbol[]
    for (const assetTokenSymbol of assetTokenSymbols) {
      const lpToken = LP_TOKENS[poolSymbol][assetTokenSymbol]
      const lpTokenAddress = lpToken?.getAddress(chainId)
      if (lpTokenAddress) {
        const originalWeight =
          originalVotingWeightOfEachAssets[poolSymbol][assetTokenSymbol]
        const originalWeightWad = strToWad(originalWeight)
        const proposedWeightWad = vePtpBalanceWad
          .mul(
            strToWad(
              proposedUserVotingWeightPercentages[poolSymbol]?.[
                assetTokenSymbol
              ],
            ),
          )
          .div(strToWad('100'))
        if (proposedWeightWad.eq(originalWeightWad)) {
          continue
        }
        lpTokenAddresses.push(lpTokenAddress)
        vePtpVoteDeltaWads.push(proposedWeightWad.sub(originalWeightWad))
      }
    }
  })

  return { lpTokenAddresses, vePtpVoteDeltaWads }
}

/**
 * Get Bribe IncentiveWad with new user votes per N days
 * @param {BigNumber} poolWeightWad
 * @param {BigNumber} userVotesWad
 * @param {BigNumber} bribeTokenPerSecWad
 * @param {number} numberOfDays
 * @returns
 */
export const getBribeIncentiveWadInTokenPerNDays = (
  poolWeightWad: BigNumber,
  userVotesWad: BigNumber,
  bribeTokenPerSecWad: BigNumber,
  numberOfDays: number,
): BigNumber => {
  if (userVotesWad.eq(constants.Zero) || numberOfDays <= 0) {
    return constants.Zero
  }
  return wmul(
    safeWdiv(userVotesWad, userVotesWad.add(poolWeightWad)),
    bribeTokenPerSecWad,
  )
    .mul(60)
    .mul(60)
    .mul(24)
    .mul(numberOfDays)
}

/**
 * Get annual Bribe IncentiveWad
 * @param {PoolSymbolStringBigNumberType} bribeTokenPerSecondOfEachAssetBN
 * @param {TokenSymbolStringType} tokenPrices
 * @returns {BigNumber}
 */
export const getAnnualBribeIncentivesWad = (
  bribeTokenPerSecondOfEachAssetBN: PoolSymbolStringBigNumberType,
  tokenPrices: TokenSymbolStringType,
) => {
  let annualBribeIncentivesInUsdWad = constants.Zero
  Object.entries(bribeTokenPerSecondOfEachAssetBN).forEach(
    ([poolSymbolStr, bribeTokenPerSecondOfEachAssetBNInThisPool]) => {
      const poolSymbol = poolSymbolStr as PoolSymbol

      Object.entries(bribeTokenPerSecondOfEachAssetBNInThisPool).forEach(
        ([assetTokenSymbol, bribeTokenPerSecondBNInThisAsset]) => {
          const bribeTokenSymbol =
            LP_TOKENS[poolSymbol][assetTokenSymbol as TokenSymbol]?.bribe
              ?.tokenSymbol
          if (bribeTokenSymbol) {
            const bribeTokenPrice = tokenPrices[bribeTokenSymbol]

            const bribeIncentiveWadInUsdPerYear = wmul(
              nativeToWAD(
                bribeTokenPerSecondBNInThisAsset
                  .mul(60)
                  .mul(60)
                  .mul(24)
                  .mul(365),
                TOKENS[bribeTokenSymbol].decimals,
              ),
              strToWad(bribeTokenPrice),
            )

            annualBribeIncentivesInUsdWad = annualBribeIncentivesInUsdWad.add(
              bribeIncentiveWadInUsdPerYear,
            )
          }
        },
      )
    },
  )

  return annualBribeIncentivesInUsdWad
}

/**
 * Get Bribe Incentives per user vote (Max Veptp or 100000 Veptp/ actual votes) per N days
 * @param {PoolSymbolStringBigNumberType} poolWeightOfEachAssetWads
 * @param {PoolSymbolStringBigNumberType} bribeTokenPerSecondOfEachAssetBN
 * @param {TokenSymbolStringType} tokenPrices
 * @param { 'MAX' | '100K' | 'ACTUAL'} userVotes
 * @param {number} numberOfDays
 * @param {PoolSymbolStringBigNumberType | undefined} userVoteOfEachAssetWads : undefined if userVotes = '100k'
 * @param {BigNumber | undefined} vePtpBalanceWad : undefined if userVotes = '100k' || 'ACTUAL'
 * @returns
 */
export const getBribeIncentivesPerUserVotesPerNDays = (
  poolWeightOfEachAssetWads: PoolSymbolStringBigNumberType,
  bribeTokenPerSecondOfEachAssetBN: PoolSymbolStringBigNumberType,
  tokenPrices: TokenSymbolStringType,
  userVotes: 'MAX' | '100K' | 'ACTUAL',
  numberOfDays: number,
  userVoteOfEachAssetWads?: PoolSymbolStringBigNumberType,
  vePtpBalanceWad?: BigNumber,
): {
  [id in PoolSymbol]?: {
    [id in TokenSymbol]?: { inToken: string; inUsd: string }
  }
} => {
  const bribeIncentivesPerNDays: {
    [id in PoolSymbol]?: {
      [id in TokenSymbol]?: { inToken: string; inUsd: string }
    }
  } = {}

  if (numberOfDays > 0) {
    Object.entries(bribeTokenPerSecondOfEachAssetBN).forEach(
      ([poolSymbolStr, bribeTokenPerSecondOfEachAssetBNInThisPool]) => {
        const poolSymbol = poolSymbolStr as PoolSymbol
        bribeIncentivesPerNDays[poolSymbol] = {}
        Object.entries(bribeTokenPerSecondOfEachAssetBNInThisPool).forEach(
          ([assetTokenSymbol, bribeTokenPerSecondBNInThisAsset]) => {
            const bribeTokenSymbol =
              LP_TOKENS[poolSymbol][assetTokenSymbol as TokenSymbol]?.bribe
                ?.tokenSymbol
            if (bribeTokenSymbol) {
              let bribeIncentiveWadInTokenPerNDays = constants.Zero
              if (
                userVotes === 'MAX' &&
                userVoteOfEachAssetWads &&
                vePtpBalanceWad
              ) {
                bribeIncentiveWadInTokenPerNDays =
                  getBribeIncentiveWadInTokenPerNDays(
                    poolWeightOfEachAssetWads[poolSymbol][assetTokenSymbol].sub(
                      userVoteOfEachAssetWads[poolSymbol][assetTokenSymbol],
                    ),
                    vePtpBalanceWad,
                    nativeToWAD(
                      bribeTokenPerSecondBNInThisAsset,
                      TOKENS[bribeTokenSymbol].decimals,
                    ),
                    numberOfDays,
                  )
              } else if (userVotes === '100K') {
                bribeIncentiveWadInTokenPerNDays =
                  getBribeIncentiveWadInTokenPerNDays(
                    poolWeightOfEachAssetWads[poolSymbol][assetTokenSymbol],
                    strToWad('100000'),
                    nativeToWAD(
                      bribeTokenPerSecondBNInThisAsset,
                      TOKENS[bribeTokenSymbol].decimals,
                    ),
                    numberOfDays,
                  )
              } else if (userVotes === 'ACTUAL' && userVoteOfEachAssetWads) {
                bribeIncentiveWadInTokenPerNDays =
                  getBribeIncentiveWadInTokenPerNDays(
                    poolWeightOfEachAssetWads[poolSymbol][assetTokenSymbol].sub(
                      userVoteOfEachAssetWads[poolSymbol][assetTokenSymbol],
                    ),
                    userVoteOfEachAssetWads[poolSymbol][assetTokenSymbol],
                    nativeToWAD(
                      bribeTokenPerSecondBNInThisAsset,
                      TOKENS[bribeTokenSymbol].decimals,
                    ),
                    numberOfDays,
                  )
              }
              if (
                bribeIncentivesPerNDays &&
                bribeIncentivesPerNDays[poolSymbol]
              ) {
                bribeIncentivesPerNDays[poolSymbol] = {
                  ...bribeIncentivesPerNDays[poolSymbol],
                  [assetTokenSymbol]: {
                    inToken: utils.formatEther(
                      bribeIncentiveWadInTokenPerNDays,
                    ),
                    inUsd: utils.formatEther(
                      wmul(
                        bribeIncentiveWadInTokenPerNDays,
                        strToWad(tokenPrices[bribeTokenSymbol]),
                      ),
                    ),
                  },
                }
              }
            }
          },
        )
      },
    )
  }
  return bribeIncentivesPerNDays
}

/**
 * Get proposed Total weight Wad
 * @param {BigNumber} currentTotalWeightWad
 * @param {BigNumber} currentUserUsedVoteWad
 * @param {string} proposedUserTotalVotePercentage
 * @param {BigNumber} currentUserVePtpBalanceWad
 * @returns proposedTotalWeightWad
 */
export const getProposedTotalWeightWad = (
  currentTotalWeightWad: BigNumber,
  currentUserUsedVoteWad: BigNumber,
  proposedUserTotalVotePercentage: string,
  currentUserVePtpBalanceWad: BigNumber,
): BigNumber => {
  return currentTotalWeightWad
    .sub(currentUserUsedVoteWad)
    .add(
      currentUserVePtpBalanceWad
        .mul(strToWad(proposedUserTotalVotePercentage))
        .div(strToWad('100')),
    )
}

/**
 * Get proposed pool weight Wad
 * @param {BigNumber} currentUserVoteWeightWad
 * @param {BigNumber} proposedUserVoteWeightWad
 * @param {BigNumber} currentPoolWeightWad
 * @returns proposedPoolWeightWad
 */
export const getProposedPoolWeightWad = (
  currentUserVoteWeightWad: BigNumber,
  proposedUserVoteWeightWad: BigNumber,
  currentPoolWeightWad: BigNumber,
): BigNumber => {
  const voteDelta = proposedUserVoteWeightWad.sub(currentUserVoteWeightWad)
  return currentPoolWeightWad.add(voteDelta)
}

export type EarnedBribeRewardType = {
  lpTokenAddress: HexString
  bribeTokenSymbol: TokenSymbol
  value: string
}
/**
 * Transform earnedBribeOfEachAsset to list of EarnedBribeRewardType data
 * @param {PoolSymbolStringStringType} earnedBribeOfEachAsset
 * @param {ChainId} chainId
 * @returns {EarnedBribeRewardType[]}
 */
export const getEarnedBribeRewards = (
  earnedBribeOfEachAsset: PoolSymbolStringStringType,
  chainId: ChainId,
  bribeBalanceOfEachAssetBN: PoolSymbolStringBigNumberType,
): EarnedBribeRewardType[] => {
  const earnedBribesData: EarnedBribeRewardType[] = []
  Object.entries(earnedBribeOfEachAsset).forEach(
    ([poolSymbol, earnedBribeOfEachAssetsOfThePool]) => {
      Object.entries(earnedBribeOfEachAssetsOfThePool).forEach(
        ([assetSymbol, earnedBribeOfTheAsset]) => {
          const bribeBalance =
            bribeBalanceOfEachAssetBN[poolSymbol as PoolSymbol][assetSymbol]
          const isBribeBalanceDrained = bribeBalance && bribeBalance.isZero()

          if (
            strToWad(earnedBribeOfTheAsset).gt(constants.Zero) &&
            !isBribeBalanceDrained
          ) {
            const lpToken =
              LP_TOKENS[poolSymbol as PoolSymbol][assetSymbol as TokenSymbol]
            const bribeTokenSymbol = lpToken?.bribe?.tokenSymbol
            const lpTokenAddress = lpToken?.getAddress(chainId)
            if (bribeTokenSymbol && lpTokenAddress) {
              earnedBribesData.push({
                lpTokenAddress,
                bribeTokenSymbol,
                value: earnedBribeOfTheAsset,
              })
            }
          }
        },
      )
    },
  )
  return earnedBribesData
}

/**
 * For VoteWaitForConfirmationModal, get a list of total earned bribe rewards of each bribe token.
 * @param {EarnedBribeRewardType[]} earnedBribeRewards
 * @param {string[]|"ALL"} selectedLpTokenAddresses
 * @returns list of EarnedBribeRewardType without lpTokenAddress
 */
export const getTotalEarnedBribeRewardsOfEachBribeToken = (
  earnedBribeRewards: EarnedBribeRewardType[],
  selectedLpTokenAddresses: string[] | 'ALL',
): {
  bribeTokenSymbol: TokenSymbol
  value: string
}[] => {
  const earnedBribeRewardsObject: { [id in TokenSymbol]?: string } = {}
  earnedBribeRewards.forEach((earnedBribeReward) => {
    // Check selectedLpTokenAddresses includes the lptokenAddress or not (for vote bribe, only claim modified gauge rewards)
    // Check selectedLpTokenAddresses is ALL or not (for claim bribe rewards)
    if (
      selectedLpTokenAddresses.includes(earnedBribeReward.lpTokenAddress) ||
      selectedLpTokenAddresses === 'ALL'
    ) {
      if (earnedBribeRewardsObject[earnedBribeReward.bribeTokenSymbol]) {
        earnedBribeRewardsObject[earnedBribeReward.bribeTokenSymbol] =
          utils.formatEther(
            strToWad(
              earnedBribeRewardsObject[earnedBribeReward.bribeTokenSymbol],
            ).add(strToWad(earnedBribeReward.value)),
          )
      } else {
        earnedBribeRewardsObject[earnedBribeReward.bribeTokenSymbol] =
          earnedBribeReward.value
      }
    }
  })
  return Object.entries(earnedBribeRewardsObject).map(
    ([bribeTokenSymbol, value]) => ({
      bribeTokenSymbol: bribeTokenSymbol as TokenSymbol,
      value,
    }),
  )
}

type GetAprOfEachBribeReturnType = {
  [id in PoolSymbol]?: Partial<TokenSymbolStringType>
}
type BribeRewardsType = {
  [id in PoolSymbol]?: {
    [id in TokenSymbol]?: { inToken: string; inUsd: string }
  }
}
/**
 * Get apr of each bribe.
 * The formula is (annual bribe rewards in USD / total staked PTP in USD) * 100%
 * @param {BribeRewardsType} annualBribeRewards - a return value from getBribeIncentivesPerUserVotesPerNDays()
 * @param {string} stakedPtpInUsd
 * @returns {GetAprOfEachBribeReturnType}
 */
export const getAprOfEachBribe = (
  annualBribeRewards: BribeRewardsType,
  stakedPtpInUsd: string,
): GetAprOfEachBribeReturnType => {
  const stakedPtpInUsdWad = strToWad(stakedPtpInUsd)

  if (stakedPtpInUsdWad.lte(0)) return {}
  return Object.entries(annualBribeRewards).reduce(
    (prevBribeRewardsOfEachPool, [poolSymbol, rewardsOfEachBribeToken]) => {
      const newRewardsOfEachBribeToken = Object.entries(
        rewardsOfEachBribeToken,
      ).reduce(
        (
          prevRewardsOfEachBribeToken,
          [tokenSymbol, { inUsd: bribeRewardInUsd }],
        ) => {
          prevRewardsOfEachBribeToken[tokenSymbol as TokenSymbol] =
            getPercentageFromTwoWAD(
              strToWad(bribeRewardInUsd),
              stakedPtpInUsdWad,
            )
          return prevRewardsOfEachBribeToken
        },
        {} as Partial<TokenSymbolStringType>,
      )
      prevBribeRewardsOfEachPool[poolSymbol as PoolSymbol] =
        newRewardsOfEachBribeToken
      return prevBribeRewardsOfEachPool
    },
    {} as GetAprOfEachBribeReturnType,
  )
}

export const getBalanceOfEachBribe = (
  bribeBalanceOfEachAssetBN: PoolSymbolStringBigNumberType,
): {
  [id in PoolSymbol]?: {
    [id in TokenSymbol]?: { inToken: string }
  }
} => {
  const newBribeBalance: {
    [id in PoolSymbol]?: {
      [id in TokenSymbol]?: { inToken: string }
    }
  } = {}

  Object.entries(bribeBalanceOfEachAssetBN).forEach(
    ([poolSymbolStr, bribeBalanceOfEachAssetBNInThisPool]) => {
      const poolSymbol = poolSymbolStr as PoolSymbol
      newBribeBalance[poolSymbol] = {}
      Object.entries(bribeBalanceOfEachAssetBNInThisPool).forEach(
        ([assetTokenSymbol, bribeBalanceOfEachAssetBNInThisAsset]) => {
          const bribeTokenSymbol =
            LP_TOKENS[poolSymbol][assetTokenSymbol as TokenSymbol]?.bribe
              ?.tokenSymbol

          if (bribeTokenSymbol) {
            if (newBribeBalance && newBribeBalance[poolSymbol]) {
              newBribeBalance[poolSymbol] = {
                ...newBribeBalance[poolSymbol],
                [assetTokenSymbol]: {
                  inToken: utils.formatUnits(
                    bribeBalanceOfEachAssetBNInThisAsset,
                    TOKENS[bribeTokenSymbol].decimals,
                  ),
                },
              }
            }
          }
        },
      )
    },
  )

  return newBribeBalance
}

/**
 * Get gauge where user has earned bribe but has no vote.
 * @param {PoolSymbolStringStringType} earnedBribeOfEachAsset
 * @param {GaugeDataType[]} votedGaugeList
 * @returns {GaugeDataType[]}
 */
export const getGaugeWithBribeWithoutVote = (
  earnedBribeOfEachAsset: PoolSymbolStringStringType,
  votedGaugeList: GaugeDataType[],
  bribeBalanceOfEachAssetBN?: PoolSymbolStringBigNumberType,
): GaugeDataType[] => {
  const earnedBribeGaugeList: GaugeDataType[] = []
  if (!bribeBalanceOfEachAssetBN) return []
  Object.entries(earnedBribeOfEachAsset).forEach(
    ([earnedBribePoolSymbol, earnedBribeOfEachAssetsOfThePool]) => {
      Object.entries(earnedBribeOfEachAssetsOfThePool).forEach(
        ([earnedBribeAssetSymbol, earnedBribeOfTheAsset]) => {
          const bribeBalance =
            bribeBalanceOfEachAssetBN[earnedBribePoolSymbol as PoolSymbol][
              earnedBribeAssetSymbol
            ]
          const isBribeBalanceDrained = bribeBalance && bribeBalance.isZero()

          const isInVotedGaugeList = votedGaugeList.some(
            (votedGauge) =>
              votedGauge.assetTokenSymbol === earnedBribeAssetSymbol &&
              votedGauge.poolSymbol === earnedBribePoolSymbol,
          )
          if (
            strToWad(earnedBribeOfTheAsset).gt(constants.Zero) &&
            !isInVotedGaugeList &&
            !isBribeBalanceDrained
          ) {
            earnedBribeGaugeList.push({
              poolSymbol: earnedBribePoolSymbol as PoolSymbol,
              assetTokenSymbol: earnedBribeAssetSymbol as TokenSymbol,
            })
          }
        },
      )
    },
  )

  return earnedBribeGaugeList
}
