import { avalanche, avalancheFuji, Chain } from '@wagmi/core/chains'
import * as multicall from 'ethcall'
import { ethers } from 'ethers'
import {
  PLATYPUS_EXCHANGE_SUBGRAPH_API,
  PLATYPUS_MAINNET_NFT_SUBGRAPH_API,
  PLATYPUS_MAINNET_USP_SUBGRAPH_API,
  PLATYPUS_TESTNET_NFT_SUBGRAPH_API,
  PLATYPUS_TESTNET_USP_SUBGRAPH_API,
  SNOWTRACE_MAINNET_API,
} from '../constants'

export enum ChainId {
  // 43113
  FUJI = 43113,
  // 43114
  AVALANCHE = 43114,
}

export const readOnlyProviders: {
  [id in ChainId]: ethers.providers.StaticJsonRpcProvider
} = {
  [ChainId.FUJI]: new ethers.providers.StaticJsonRpcProvider(
    avalancheFuji.rpcUrls.default.http[0],
  ),
  [ChainId.AVALANCHE]: new ethers.providers.StaticJsonRpcProvider(
    avalanche.rpcUrls.default.http[0],
  ),
}

export const multicallProviders: {
  [id in ChainId]: multicall.Provider
} = {
  [ChainId.FUJI]: new multicall.Provider(),
  [ChainId.AVALANCHE]: new multicall.Provider(),
}

void multicallProviders[ChainId.AVALANCHE].init(
  readOnlyProviders[ChainId.AVALANCHE],
)
void multicallProviders[ChainId.FUJI].init(readOnlyProviders[ChainId.FUJI])

export interface Network extends Chain {
  chainId: ChainId
  chainName: string
  currencyName: string
  currencySymbol: string
  currencyDecimals: number
  blockExplorerUrls: string[]
  blockExplorerName: string
  chainShortName: string
  apis: {
    exchangeSubgraph: string
    nftSubgraph: string
    snowtrace: string
    uspSubgraph: string
  }
  readOnlyProvider: ethers.providers.StaticJsonRpcProvider
  multicallProvider: multicall.Provider
}

export const NETWORKS: {
  [id in ChainId]: Network
} = {
  [ChainId.FUJI]: {
    ...avalancheFuji,
    chainId: ChainId.FUJI,
    chainName: 'FUJI C-Chain',
    currencyName: 'Avalanche',
    currencySymbol: 'AVAX',
    currencyDecimals: 18,
    blockExplorerUrls: ['https://testnet.snowtrace.io/'],
    blockExplorerName: 'SnowTrace',
    chainShortName: 'FUJI',
    apis: {
      exchangeSubgraph:
        // use main net subgraph as no test net subgraph is available
        PLATYPUS_EXCHANGE_SUBGRAPH_API,
      nftSubgraph: PLATYPUS_TESTNET_NFT_SUBGRAPH_API,
      snowtrace: SNOWTRACE_MAINNET_API,
      uspSubgraph: PLATYPUS_TESTNET_USP_SUBGRAPH_API,
    },
    readOnlyProvider: readOnlyProviders[ChainId.FUJI],
    multicallProvider: multicallProviders[ChainId.FUJI],
  },
  [ChainId.AVALANCHE]: {
    ...avalanche,
    chainId: ChainId.AVALANCHE,
    chainName: 'Avalanche Network',
    currencyName: 'Avalanche',
    currencySymbol: 'AVAX',
    currencyDecimals: 18,
    blockExplorerUrls: ['https://snowtrace.io/'],
    blockExplorerName: 'SnowTrace',
    chainShortName: 'Mainnet',
    apis: {
      exchangeSubgraph: PLATYPUS_EXCHANGE_SUBGRAPH_API,
      nftSubgraph: PLATYPUS_MAINNET_NFT_SUBGRAPH_API,
      snowtrace: SNOWTRACE_MAINNET_API,
      uspSubgraph: PLATYPUS_MAINNET_USP_SUBGRAPH_API,
    },
    readOnlyProvider: readOnlyProviders[ChainId.AVALANCHE],
    multicallProvider: multicallProviders[ChainId.AVALANCHE],
  },
}

// Change SUPPORTED_CHAINS for interface
export const SUPPORTED_CHAINS =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? [ChainId.AVALANCHE] // for 'production'
    : [ChainId.FUJI, ChainId.AVALANCHE] // for 'staging' and 'development'

export const DEFAULT_CHAIN_ID =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? ChainId.AVALANCHE
    : ChainId.FUJI
