import { Box } from '@mui/material'
import React from 'react'
import { DelistedPoolCard } from '../../components/cards/DeprecatedPoolCard/DeprecatedPoolCard'
import WarningMessage from '../../components/WarningMessage/WarningMessage'
import { MasterPlatypusId } from '../../config/contracts/masterPlatypus'
import { PoolSymbol } from '../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { DeprecatedPoolWithdrawWaitForConfirmationModalContainer } from '../../containers/PoolModalContainers/DeprecatedPoolWithdrawWaitForConfirmationModalContainer/DeprecatedPoolWithdrawWaitForConfirmationModalContainer'
import { PoolUnstakeWaitForConfirmationModalContainer } from '../../containers/PoolStakeLPContainers/PoolStakeLPModalContainers'
import { useModal } from '../../contexts/ModalContext'
import { ModalId } from '../../interfaces/Modal'

function DeprecatedPoolsPage(): React.ReactElement {
  const { modalState } = useModal()
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: 'relative',
        width: '80%',
        maxWidth: '900px',
        margin: '20px auto',
      }}
    >
      <WarningMessage
        message="The following tokens are deprecated. Users can unstake and withdraw their deposited funds below."
        severity="warning"
        style={{ marginBottom: '24px', width: '100%' }}
      />
      {/* MAIN - MIM @todo may remove it later*/}
      <DelistedPoolCard
        poolSymbol={PoolSymbol.DEPRECATED_MAIN}
        assetTokenSymbol={TokenSymbol.MIM}
        masterPlatypusId={MasterPlatypusId.MP1}
      />

      {/* ALT_USDC_UST - USDC */}
      <DelistedPoolCard
        poolSymbol={PoolSymbol.DEPRECATED_ALT_USDC_UST}
        assetTokenSymbol={TokenSymbol.USDC}
        masterPlatypusId={MasterPlatypusId.MP3}
        withdrawButtonLabel={'Withdraw in UST'}
      />

      {/* ALT_USDC_UST - UST */}
      <DelistedPoolCard
        poolSymbol={PoolSymbol.DEPRECATED_ALT_USDC_UST}
        assetTokenSymbol={TokenSymbol.UST}
        masterPlatypusId={MasterPlatypusId.MP3}
      />

      {/* FACTORY_USDC_MIMATIC - USDC */}
      <DelistedPoolCard
        poolSymbol={PoolSymbol.FACTORY_USDC_MIMATIC}
        assetTokenSymbol={TokenSymbol.USDC}
        masterPlatypusId={MasterPlatypusId.BASE_MPV1}
      />

      {/* FACTORY_USDC_MIMATIC - MIMATIC */}
      <DelistedPoolCard
        poolSymbol={PoolSymbol.FACTORY_USDC_MIMATIC}
        assetTokenSymbol={TokenSymbol.MIMATIC}
        masterPlatypusId={MasterPlatypusId.BASE_MPV1}
      />
      {modalState.currentModalId ===
        ModalId.POOL_UNSTAKE_WAIT_FOR_CONFIRMATION && (
        <PoolUnstakeWaitForConfirmationModalContainer isOpen isDeprecatedPool />
      )}
      {modalState.currentModalId ===
        ModalId.DEPREACATED_POOL_WITHDRAW_WAIT_FOR_CONFIRMATION && (
        <DeprecatedPoolWithdrawWaitForConfirmationModalContainer isOpen />
      )}
    </Box>
  )
}

export default DeprecatedPoolsPage
