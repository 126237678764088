import { strToWad } from '@hailstonelabs/big-number-utils'
import { BigNumber, constants, ContractInterface, utils } from 'ethers'
import {
  Pool as PoolContract,
  PoolAvax as PoolAvaxContract,
} from '../../../../types/ethers-contracts'
import { PoolSymbolStringStringStringType } from '../../../interfaces/common'
import { ChainId } from '../../networks'
import { AddressInterface, Contract } from '../Contract'
import { LP_TOKENS } from '../lpToken'
import { LpToken } from '../lpToken/LpToken'
import { MasterPlatypusId, MASTER_PLATYPUS } from '../masterPlatypus'
import { TOKENS } from '../token'
import { SwapGroupSymbol, TokenSymbol } from '../token/tokenSymbol'
import { POOL__ABI } from '../wagmiAbis/Pool'
import { POOLAVAX_ABI } from '../wagmiAbis/PoolAvax'
import { PoolFilterSymbol, PoolSymbol } from './poolSymbol'
// TokenSymbol and reward amount
export type RewardsTypeWithStrValue = {
  tokenSymbol: TokenSymbol
  value: string
}[]

/**
 *  If the token convertion rate is 0.9. It means 1 fromToken can be converted to 0.9 toToken.
 */
type TokenConversionRateData = {
  fromTokenSymbol: TokenSymbol
  toTokenSymbol: TokenSymbol
}
export class Pool extends Contract<
  PoolContract | PoolAvaxContract,
  typeof POOL__ABI | typeof POOLAVAX_ABI
> {
  readonly tokenConversionRateData?: TokenConversionRateData
  readonly isTpAvaxPool: boolean
  readonly poolFilterSymbol: PoolFilterSymbol
  // In AVAX-SAVAX Pool, availableTokensForSwap not only assets(AVAX, SAVAX) but also includes WAVAX.
  readonly availableTokensForSwap: TokenSymbol[]
  readonly name: string
  readonly poolSymbol: PoolSymbol
  readonly assets: { [id in TokenSymbol]?: LpToken }
  readonly icon: string
  readonly masterPlatypusId: MasterPlatypusId
  readonly hasPtpBooster: boolean
  /** @todo hasBonusBooster move to lptoken config. since some pool only have one side bonus */
  readonly hasBonusBooster: boolean
  constructor({
    name,
    poolSymbol,
    address,
    icon,
    masterPlatypusId,
    abi,
    poolFilterSymbol,
    availableTokensForSwap,
    hasBonusBooster,
    tokenConversionRateData,
    wagmiAbi,
  }: {
    name: string
    poolSymbol: PoolSymbol
    address: AddressInterface
    icon: string
    masterPlatypusId: MasterPlatypusId
    abi: ContractInterface
    wagmiAbi: typeof POOL__ABI | typeof POOLAVAX_ABI
    poolFilterSymbol: PoolFilterSymbol
    availableTokensForSwap?: TokenSymbol[]
    hasBonusBooster?: boolean
    tokenConversionRateData?: TokenConversionRateData
  }) {
    super(address, abi, wagmiAbi)
    this.name = name
    this.icon = icon
    this.poolSymbol = poolSymbol
    this.assets = LP_TOKENS[poolSymbol]
    this.masterPlatypusId = masterPlatypusId
    this.hasPtpBooster = MASTER_PLATYPUS[masterPlatypusId].isBoostedAprSupported
    // Assume that if Ptp reward is Boosted then Bonus reward will be boosted too. Unless hasBonusBooster is defined.
    if (hasBonusBooster === undefined) {
      this.hasBonusBooster = this.hasPtpBooster
    } else {
      this.hasBonusBooster = hasBonusBooster
    }
    this.poolFilterSymbol = poolFilterSymbol
    this.availableTokensForSwap =
      availableTokensForSwap ||
      Object.values(this.assets)
        .filter((asset) => asset.isAvailable)
        .map((asset) => asset.tokenSymbol)
    this.isTpAvaxPool = this.availableTokensForSwap.some(
      (tokenSymbol) =>
        TOKENS[tokenSymbol].swapGroupSymbol === SwapGroupSymbol.AVAX,
    )
    this.tokenConversionRateData = tokenConversionRateData
  }
  /**
   * Check all tokenSymbols include in assets or not
   * if one of tokenSymbols is not included -> return false
   * All tokenSymbols included -> return true
   * @param tokenSymbols
   * @return {boolean}
   */
  availableTokensForSwapIncludes(tokenSymbols: TokenSymbol[]): boolean {
    return tokenSymbols.every((tokenSymbol) => {
      return this.availableTokensForSwap.includes(tokenSymbol)
    })
  }
  /**
   * not available = delisted
   * @param {'all' | 'isAvailable' | 'notAvailable' = 'isAvailable'} option default = isAvailable
   * @returns TokenSymbol[]
   */
  getAssets(
    option:
      | 'all'
      | 'isAvailable'
      | 'notAvailable'
      | 'deprecatedMainPoolForGauageVoting' = 'isAvailable',
  ): LpToken[] {
    if (option === 'all') {
      return Object.values(this.assets)
    }
    const assets: LpToken[] = []
    Object.values(this.assets).map((asset) => {
      if (option === 'deprecatedMainPoolForGauageVoting') {
        assets.push(asset)
      }
      if (option === 'isAvailable' && asset.isAvailable) {
        assets.push(asset)
      }
      if (option === 'notAvailable' && !asset.isAvailable) {
        assets.push(asset)
      }
    })
    return assets
  }

  /**
   *
   * Get pids (Big Number) with had ptp Earning/ ptp was staked
   * @param {ChainId} chainId chainId from useNetwork
   * @param {{[key in string]: string}} target :return only the pids where target etc.ptpEarning or StakedLp is non-zero.
   * @returns {Promise<BigNumber[]>}
   */
  getPidsBNWithTargetMoreThanZero(
    chainId: ChainId,
    target: {
      [id in TokenSymbol]?: string
    },
  ): BigNumber[] {
    const pidsBN: BigNumber[] = []
    // use for-loop and return only the pids with ptpEarningOrStakedLp > 0.
    Object.entries(this.assets).map(([tokenSymbol, asset]) => {
      if (strToWad(target[tokenSymbol as TokenSymbol]).gt(constants.Zero)) {
        pidsBN.push(BigNumber.from(asset.pids[chainId]))
      }
    })
    return pidsBN
  }

  /**
   *
   * Get Reward Token Symbols with reward more than 0
   * @param {{[key in TokenSymbol]: string}} rewards
   * @returns {TokenSymbol[]}
   */
  getEarnedRewardTokenSymbols(
    rewards: {
      [id in TokenSymbol]?: string
    },
  ): TokenSymbol[] {
    let rewardTokenSymbols: TokenSymbol[] = []
    // use for-loop and return only the pids with ptpEarningOrStakedLp > 0.
    Object.entries(this.assets).map(([tokenSymbol, asset]) => {
      if (strToWad(rewards[tokenSymbol as TokenSymbol]).gt(constants.Zero)) {
        rewardTokenSymbols = [
          ...rewardTokenSymbols,
          ...asset.rewards.map((rewardData) => rewardData.tokenSymbol),
        ]
      }
    })
    return rewardTokenSymbols
  }

  /**
   *
   * @param {PoolSymbolStringStringStringType} rewards rewards from StakeLpContext
   * @param {TokenSymbol} assetTokenSymbol
   * @returns a list of rewards with keys of TokenSymbol
   */
  getRewardsEarned(
    rewards: PoolSymbolStringStringStringType | undefined,
    assetTokenSymbol: TokenSymbol,
  ): RewardsTypeWithStrValue {
    if (!rewards) return []
    const targetRewards = rewards[this.poolSymbol][assetTokenSymbol]
    // if asset have not target rewards return []
    if (!targetRewards) return []
    return Object.entries(targetRewards).map((reward) => {
      const [tokenSymbol, value] = reward
      return {
        tokenSymbol: tokenSymbol as TokenSymbol,
        value,
      }
    })
  }

  /**
   * This function is mainly used for pool.getPidsBNWithTargetMoreThanZero() which only takes {[key in string]: string} as a target
   * It will sum all rewards for each asset. For example, UST has rewards of PTP and UST and it will get the sum of PTP and UST.
   * @param {PoolSymbolStringStringStringType} rewards rewards from StakeLpContext
   * @returns sum of rewards with a key of string (assetTokenSymbol)
   */
  getTotalRewardsEarnedOfEachAsset(rewards: PoolSymbolStringStringStringType): {
    [id: string]: string
  } {
    const rewardsOfAssets = rewards[this.poolSymbol]
    const totalRewardsEarned: { [id: string]: string } = {}
    for (const [assetTokenSymbol, rewards] of Object.entries(rewardsOfAssets)) {
      const sumOfRewards = Object.values(rewards).reduce(
        (prev, current) => prev.add(strToWad(current)),
        constants.Zero,
      )
      totalRewardsEarned[assetTokenSymbol] = utils.formatEther(sumOfRewards)
    }
    return totalRewardsEarned
  }
}
