export interface MetaMaskError {
  code: number
  data?: {
    code: number
    data: string
    // etc.message: "execution reverted: EXPIRED"
    message: string
  }
  message: string
}

export interface WalletConnectError {
  error?: {
    message: string
    code: number
  }
  message: string
}

export enum SwapQuotationErrorType {
  PRICE_DEV = 'PRICE_DEV',
  INSUFFICIENT_CASH = 'INSUFFICIENT_CASH',
  PAUSABLE_PAUSED = 'PAUSABLE_PAUSED',
}
