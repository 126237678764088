import { Components } from '@mui/material'
import { colors } from './palette'

const overrides: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      'html, body, #root': {
        minHeight: '100vh',
        letterSpacing: '0.15px',
      },
      a: {
        textDecoration: 'none',
        color: 'unset',
      },
      '#walletconnect-qrcode-modal': {
        a: {
          color: '#000',
        },
      },
      svg: {
        transform: 'unset !important',
        imageRendering: '-webkit-optimize-contrast',
      },
    },
  },

  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: colors.primary[800],
        maxWidth: '250px',
        fontSize: '14px',
        lineHeight: 1.5,
        padding: '0.8rem',
        fontWeight: 400,
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
      },
      arrow: {
        '&:before': {
          backgroundColor: colors.primary[800],
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      regular: {
        '@media (min-width: 600px)': {
          minHeight: '80px',
        },
      },
      gutters: {
        '@media (min-width: 600px)': {
          paddingLeft: '30px',
          paddingRight: '30px',
        },
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: {
        color: '#00F152',
      },
      thumb: {
        background: 'linear-gradient(to right, #00f152, #0076e9)',
        width: 20,
        height: 20,
        '&:hover, &.Mui-active': {
          boxShadow: '0 0 0 5px #00f15014',
        },
      },
      rail: {
        backgroundColor: '#007ae5',
        opacity: 0.5,
        height: 4,
        borderRadius: 999,
      },
      track: {
        height: 6,
        borderRadius: 999,
      },
      mark: {
        height: 4,
        width: 4,
        backgroundColor: '#00F152',
        opacity: 0.5,

        '&.disabled': {
          backgroundColor: '#fff',
        },
      },
      markActive: {
        backgroundColor: 'unset',
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderBottomWidth: '1px',
      },
      withChildren: {
        '&::before, &::after': {
          borderTopWidth: '1px',
        },
      },
      wrapper: {
        display: 'inline-flex',
      },
    },
  },
}
export default overrides
