import { getDpFormat } from '@hailstonelabs/big-number-utils'
import React from 'react'
import votingIcon from '../../../assets/icons/voting.svg'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipText from '../../../components/TooltipText/TooltipText'
import { AIRDROPS } from '../../../config/airdrops'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useBenefitApr } from '../../../hooks/vePtp/useBenefitApr'

interface VePtpBenefitAprTooltipProps {
  //mode all - it show all the apr break down,
  //mode airdrop - it only show airdrops apr break down,
  mode: 'airdrop' | 'all'
}

function VePtpBenefitAprTooltip({ mode }: VePtpBenefitAprTooltipProps) {
  const { isTabletSm } = useBreakpoints()
  const { airdropsApr, votingApr, total } = useBenefitApr()

  const isAllAprsShown = mode === 'all'

  return (
    <InfoBox.Tooltip
      text={
        <TooltipText>
          {isAllAprsShown && (
            <TooltipText.Group>
              <TooltipText.RewardItem
                title="Total APR for vePTP holder"
                value={getDpFormat(total.totalBenefitAprWad)}
                isApr
              />
            </TooltipText.Group>
          )}
          {isAllAprsShown && (
            <TooltipText.RewardItem
              title={'Bribe reward APR'}
              value={votingApr}
              isBold={false}
              iconPath={votingIcon}
              isApr
            />
          )}
          {!isAllAprsShown && (
            <TooltipText.Group>
              <TooltipText.RewardItem
                title="Airdrop"
                value={getDpFormat(total.totalAirdropAprWad)}
                isApr
              />
            </TooltipText.Group>
          )}
          <TooltipText.Group
            /**@todo reopen after hummus airdrop is ready */
            // header={isAllAprsShown ? 'Airdrop' : ''}
            header={''}
            transparent={isAllAprsShown ? true : false}
            isBold={isAllAprsShown ? false : true}
          >
            {Object.values(AIRDROPS)
              //hide airdrop not in apr calculation in the Tooltip
              .filter((airdrop) => {
                return airdrop.settings.hasApr
              })
              .map((airdrop) => {
                return (
                  <TooltipText.RewardItem
                    key={airdrop.id}
                    iconPath={airdrop.icon}
                    value={airdropsApr[airdrop.id]}
                    title={airdrop.name.replace(' airdrop', '')}
                    isApr
                  />
                )
              })}
          </TooltipText.Group>
          {isAllAprsShown && (
            <TooltipText.RewardItem
              title="Fee sharing"
              value="-"
              isBold={false}
            />
          )}
        </TooltipText>
      }
      placement={isTabletSm ? 'top' : 'right'}
      PopperProps={{
        style: {
          minWidth: isAllAprsShown ? '300px' : '250px',
        },
      }}
    />
  )
}

export default VePtpBenefitAprTooltip
