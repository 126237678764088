import CloseIcon from '@mui/icons-material/Close'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import React, { ReactElement } from 'react'
import {
  CloseButton,
  Content,
  MessageContainer,
  Title,
  Topbar,
} from './Message.elements'

interface Props {
  style?: React.CSSProperties
  className?: string
  severity?: 'warning'
  onClose?: () => void
  message?: string | ReactElement
  title?: string
  backgroundColor?: 'warning'
}

function Message({
  style,
  className,
  severity = 'warning',
  onClose,
  message,
  title = 'WARNING',
  backgroundColor,
}: Props): ReactElement {
  return (
    <MessageContainer
      style={style}
      className={className}
      $backgroundColor={backgroundColor}
    >
      <Topbar>
        {severity === 'warning' && (
          <>
            <WarningAmberIcon className="message__icon" />
            <Title variant="subtitle2">{title}</Title>
          </>
        )}
        {onClose && (
          <CloseButton onClick={onClose} data-testid="close-button">
            <CloseIcon />
          </CloseButton>
        )}
      </Topbar>
      {message && <Content variant="body1">{message}</Content>}
    </MessageContainer>
  )
}

export default Message
