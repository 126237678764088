import { strToWad, wmul } from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import { useMemo } from 'react'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { useVePtpInput } from '../../../contexts/VePtpInputContext'
import { ModalId } from '../../../interfaces/Modal'
import useLockTimeLeft from './useLockTimeLeft'

interface Props {
  lockDayInputAmount: string
  lockPtpInputAmount: string
}

function useVeptpToReceive({ lockDayInputAmount, lockPtpInputAmount }: Props) {
  const { maxPtpLockCap } = useVePtpInput()
  const { ptp, vePtp } = useVePtp()
  const { dayLeft } = useLockTimeLeft({
    unlockTimeInMilliseconds: ptp.lockTime.unlockTimestamp * 1000,
    initialLockTimeInMilliseconds: ptp.lockTime.initialTimestamp * 1000,
  })

  const { modalState } = useModal()

  const hasLockedPtp = ptp.hasLocked

  const isExtendingLock =
    modalState.currentModalId === ModalId.EXTEND_LOCK_PTP ||
    modalState.currentModalId === ModalId.LOCK_EXTEND_PTP_WAIT_FOR_CONFIRMATION

  const isInBoosterCalculator =
    modalState.currentModalId === ModalId.VEPTP_BOOSTER_CALCULATOR

  const inputDayForVeptpToReceive = useMemo(() => {
    /**For Booster Calculator */
    if (isInBoosterCalculator) return lockDayInputAmount
    if (hasLockedPtp && !isExtendingLock) {
      /**For lock more */
      return dayLeft.toString()
    }
    /**For first lock and extend lock */
    return lockDayInputAmount
  }, [
    dayLeft,
    hasLockedPtp,
    isExtendingLock,
    isInBoosterCalculator,
    lockDayInputAmount,
  ])

  const veptpToReceive = utils.formatEther(
    wmul(
      wmul(
        strToWad(vePtp.generationRate.perDay),
        strToWad(inputDayForVeptpToReceive),
      ),
      strToWad(isExtendingLock ? ptp.amount.locked : lockPtpInputAmount),
    ),
  )

  const toReceiveGtMaxCap = strToWad(veptpToReceive).gt(strToWad(maxPtpLockCap))

  return {
    veptpToReceive,
    toReceiveGtMaxCap,
  }
}

export default useVeptpToReceive
