import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import React, { ReactElement } from 'react'
import { SeverityType } from '../../../contexts/SnackbarContext'
import AppTypography from '../../AppTypography/AppTypography'
import { Container } from './AppAlert.elements'
interface AlertProps {
  message: string
  severity?: SeverityType
  variant?: 'filled' | 'outlined'
}
function AppAlert({
  message,
  severity = 'success',
  variant = 'filled',
}: AlertProps): ReactElement {
  return (
    <Container className="AppAlert" severity={severity} variant={variant}>
      {severity === 'success' && (
        <CheckCircleOutlineOutlinedIcon fontSize="small" />
      )}
      {severity === 'warning' && <ErrorOutlineOutlinedIcon fontSize="small" />}
      <AppTypography variant="body2">{message}</AppTypography>
    </Container>
  )
}

export default AppAlert
