import React, { ReactElement } from 'react'
import { ChainId } from '../../../config/networks'

interface Props {
  chainId?: ChainId
  className?: string
  margin?: string
  iconPath?: string
}

/**
 *
 * Either @param {ChainId} chainId
 * or @param {string} iconPath should be specified to get an icon path
 *
 */

function NetworkIcon({ className, margin, iconPath }: Props): ReactElement {
  const src = iconPath

  return (
    <img
      className={className}
      width={24}
      height={24}
      style={{ margin: margin }}
      src={src}
    />
  )
}

export default NetworkIcon
