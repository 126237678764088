import React, { ReactElement } from 'react'
import { useModal } from '../../../contexts/ModalContext'
import Modal, { ModalProps } from '../Modal'
import {
  BottomNoticeWrapper,
  FilterInput,
  ItemLeadingLabelWrapper,
  ItemList,
  ItemTrailingLabelWrapper,
  ItemWrapper,
} from './ItemSelectionModal.elements'

interface Props extends Partial<ModalProps> {
  children: React.ReactNode
  isOpen: boolean
  onClose?: () => void
  filterInputProps?: {
    placeholder?: string
    onChange: (value: string) => void
    value: string
  }
  bottomNotice?: React.ReactNode
}
function ItemSelectionModal({
  children,
  isOpen,
  onClose,
  topBarLabel,
  filterInputProps,
  bottomNotice,
  ...otherProps
}: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const handleCloseModal = () => {
    if (onClose) {
      onClose()
    } else {
      modalDispatch(closeModal())
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      topBarLabel={topBarLabel}
      {...otherProps}
    >
      {filterInputProps && (
        <FilterInput
          placeholder={filterInputProps.placeholder || 'Search Name'}
          value={filterInputProps.value}
          onChange={filterInputProps.onChange}
        />
      )}
      <ItemList>{children}</ItemList>
      <BottomNoticeWrapper>{bottomNotice}</BottomNoticeWrapper>
    </Modal>
  )
}

export default ItemSelectionModal

interface ModalItem<T, V> {
  id: T
  children: React.ReactNode
  onClick?: (target: { id: T; value?: V }) => void
  className?: string
  isSelected?: boolean
  styleForSelectedItem?: { color?: string; transparent?: boolean }
  style?: React.CSSProperties
  value?: V
}
function ModalItem<T, V>({
  id,
  className,
  children,
  isSelected,
  styleForSelectedItem,
  onClick,
  style,
  value,
}: ModalItem<T, V>): ReactElement {
  return (
    <ItemWrapper
      className={className}
      $isSelected={isSelected}
      $styleForSelectedItem={styleForSelectedItem}
      onClick={() => onClick && onClick({ id, value })}
      style={style}
    >
      {children}
    </ItemWrapper>
  )
}

interface ItemLeadingLabelProps {
  children: React.ReactNode
  className?: string
}
function ModalItemLeadingLabel({
  children,
}: ItemLeadingLabelProps): ReactElement {
  return <ItemLeadingLabelWrapper>{children}</ItemLeadingLabelWrapper>
}

interface ItemTrailingLabelProps {
  children: React.ReactNode
  className?: string
}
function ModalItemTrailingLabel({
  children,
}: ItemTrailingLabelProps): ReactElement {
  return <ItemTrailingLabelWrapper>{children}</ItemTrailingLabelWrapper>
}

ItemSelectionModal.Item = ModalItem
ItemSelectionModal.ItemLeadingLabel = ModalItemLeadingLabel
ItemSelectionModal.ItemTrailingLabel = ModalItemTrailingLabel
