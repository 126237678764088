import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import { Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import AppTypography from '../../../../components/AppTypography/AppTypography'
import InfoBox from '../../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../../components/InfoBox/TooltipNum'
import { LP_TOKENS, POOLS } from '../../../../config/contracts'
import { RewardsTypeWithStrValue } from '../../../../config/contracts/pool/Pool'
import { TokenSymbol } from '../../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../../contexts/ModalContext'
import { usePoolInput } from '../../../../contexts/PoolInputContext'
import { useStakeLpData } from '../../../../contexts/StakeLpDataContext'
import PoolAutoClaimInfoBoxItem from '../PoolAutoClaimInfoBoxItem/PoolAutoClaimInfoBoxItem'

interface Props {
  tokenSymbol: TokenSymbol
  rewardsEarned?: RewardsTypeWithStrValue
  isMoneyPoolMigrationFromBaseV1?: boolean
}
function PoolStakeModalInfoBox({
  tokenSymbol,
  rewardsEarned,
  isMoneyPoolMigrationFromBaseV1 = false,
}: Props): ReactElement {
  const { lp, rewards } = useStakeLpData()
  let { selectedPoolSymbol } = usePoolInput()
  const { modalState } = useModal()
  if (
    isMoneyPoolMigrationFromBaseV1 &&
    modalState.poolSymbols &&
    modalState.poolSymbols[0]
  ) {
    selectedPoolSymbol = modalState.poolSymbols[0]
  }
  const rewardsOfPool = POOLS[selectedPoolSymbol].getRewardsEarned(
    rewards,
    tokenSymbol,
  )
  const tokenSymbolForDisplay =
    LP_TOKENS[selectedPoolSymbol][tokenSymbol]?.tokenSymbolForDisplay ||
    tokenSymbol
  const actualRewardsEarned = rewardsEarned ? rewardsEarned : rewardsOfPool
  return (
    <InfoBox>
      <InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">Total Stake</Typography>
          <InfoBox.Tooltip
            text={`Total amount of your deposited ${tokenSymbolForDisplay} (as LP token) staked after the transaction.`}
          />
        </InfoBox.Item>
        <AppTypography variant="caption">
          <TooltipNum
            amount={
              lp.inTermsOfToken.total[selectedPoolSymbol][tokenSymbol] || '0.0'
            }
            rightSymbol={tokenSymbolForDisplay}
          >
            {getCommifiedFormat(
              lp.inTermsOfToken.total[selectedPoolSymbol][tokenSymbol] || '0.0',
            )}{' '}
            {tokenSymbolForDisplay}
          </TooltipNum>
        </AppTypography>
      </InfoBox.Item>
      <PoolAutoClaimInfoBoxItem actualRewardsEarned={actualRewardsEarned} />
    </InfoBox>
  )
}

export default PoolStakeModalInfoBox
