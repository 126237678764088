import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'

/** Using DIRECTORY_PATH for adding token icon in Metamask  */
const DIRECTORY_PATH = `${window.location.origin}/tokens`

// ZETH: `${DIRECTORY_PATH}/zeth.png`
// RENBTC: `${DIRECTORY_PATH}/renbtc.svg`,
// ETH: `${DIRECTORY_PATH}/eth.svg`,
// TUSD: `${DIRECTORY_PATH}/tusd.png`,
export const usdIconPath = `${DIRECTORY_PATH}/usd.svg`
export const undefinedIconPath = `${DIRECTORY_PATH}/undefined.svg`
export const tokenIconPaths: { [id in TokenSymbol]: string } = {
  [TokenSymbol.MIMATIC]: `${DIRECTORY_PATH}/miMatic.png`,
  [TokenSymbol.ANKR]: `${DIRECTORY_PATH}/ankr.svg`,
  [TokenSymbol.USX]: `${DIRECTORY_PATH}/usx.svg`,
  [TokenSymbol.MORE]: `${DIRECTORY_PATH}/more.png`,
  [TokenSymbol.DAIe]: `${DIRECTORY_PATH}/dai.svg`,
  [TokenSymbol.FRAX]: `${DIRECTORY_PATH}/frax.png`,
  [TokenSymbol.JOE]: `${DIRECTORY_PATH}/joe.png`,
  [TokenSymbol.MIM]: `${DIRECTORY_PATH}/mim.png`,
  [TokenSymbol.PTP]: `${DIRECTORY_PATH}/ptp.svg`,
  [TokenSymbol.USDC]: `${DIRECTORY_PATH}/usdc.svg`,
  [TokenSymbol.USDT]: `${DIRECTORY_PATH}/usdt.svg`,
  [TokenSymbol.USP]: `${DIRECTORY_PATH}/usp.svg`,
  [TokenSymbol.UST]: `${DIRECTORY_PATH}/ust.png`,
  [TokenSymbol.VEPTP]: `${DIRECTORY_PATH}/veptp.png`,
  [TokenSymbol.WAVAX]: `${DIRECTORY_PATH}/wavax.png`,
  [TokenSymbol.SAVAX]: `${DIRECTORY_PATH}/savax.png`,
  [TokenSymbol.YYAVAX]: `${DIRECTORY_PATH}/yyavax.png`,
  [TokenSymbol.ANKRAVAX]: `${DIRECTORY_PATH}/ankravax.png`,
  [TokenSymbol.AVAX]: `${DIRECTORY_PATH}/avax.svg`,
  [TokenSymbol.QI]: `${DIRECTORY_PATH}/qi.svg`,
  [TokenSymbol.YETI]: `${DIRECTORY_PATH}/yeti.png`,
  [TokenSymbol.YUSD]: `${DIRECTORY_PATH}/yusd.png`,
  [TokenSymbol.H2O]: `${DIRECTORY_PATH}/h2o.png`,
  [TokenSymbol.MONEY]: `${DIRECTORY_PATH}/money.png`,
  [TokenSymbol.USDTe]: `${DIRECTORY_PATH}/usdt.svg`,
  [TokenSymbol.USDCe]: `${DIRECTORY_PATH}/usdc.svg`,
  [TokenSymbol.TSD]: `${DIRECTORY_PATH}/tsd.png`,
  [TokenSymbol.MELT]: `${DIRECTORY_PATH}/melt.png`,
  [TokenSymbol.TEDDY]: `${DIRECTORY_PATH}/teddy.png`,
  [TokenSymbol.BTCb]: `${DIRECTORY_PATH}/btc.svg`,
  [TokenSymbol.WBTCe]: `${DIRECTORY_PATH}/wbtc.svg`,
  [TokenSymbol.TUSD]: `${DIRECTORY_PATH}/tusd.png`,
  [TokenSymbol.BUSD]: `${DIRECTORY_PATH}/busd.png`,
  [TokenSymbol.HUM]: `${DIRECTORY_PATH}/hum.png`,
  [TokenSymbol.AXLUSDC]: `${DIRECTORY_PATH}/axlusdc.svg`,
  [TokenSymbol.ANKRETH]: `${DIRECTORY_PATH}/ankreth.svg`,
  [TokenSymbol.WETHe]: `${DIRECTORY_PATH}/weth.png`,
}
