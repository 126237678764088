import { Box } from '@mui/material'
import React, { ReactElement, useMemo, useState } from 'react'
import AppTypography from '../../../../components/AppTypography/AppTypography'
import ItemSelectionModal from '../../../../components/Modal/ItemSelectionModal/ItemSelectionModal'
import TokenIcon from '../../../../components/TokenIcon/TokenIcon'
import { LP_TOKENS, POOLS } from '../../../../config/contracts'
import { POOL_GROUPS } from '../../../../config/contracts/pool/PoolGroup'
import { PoolSymbol } from '../../../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../../../config/contracts/token/tokenSymbol'
import { useUserVoteAllocationTable } from '../UserVoteAllocationTableContext'
import { DEPRECATED_GAUGES_POOLS } from '../../../../config/contracts/pool'

interface GaugeSelectionModalItemType {
  label: string
  poolSymbol: PoolSymbol
  assetTokenSymbol: TokenSymbol
  assetTokenSymbolForDisplay: TokenSymbol
}
interface Props {
  isOpen: boolean
  onClose: () => void
}

const getItemLabel = (
  poolSymbol: PoolSymbol,
  assetTokenSymbol: TokenSymbol,
) => {
  const pool = POOLS[poolSymbol]
  const asset = LP_TOKENS[poolSymbol][assetTokenSymbol]
  if (!asset) return 'Unknown'
  return `${asset.tokenSymbolForDisplay} (${pool.name})`
}
function GaugeSelectionModal({ isOpen, onClose }: Props): ReactElement {
  const [filterInput, setFilterInput] = useState('')
  const {
    addNewGauge,
    isInEditMode,
    enterEditMode,
    addedGaugeList,
    votedAndAddedGaugeList,
  } = useUserVoteAllocationTable()
  const items = useMemo(() => {
    const outputData: GaugeSelectionModalItemType[] = []
    for (const poolGroup of Object.values(POOL_GROUPS)) {
      for (const poolSymbol of poolGroup.poolSymbols) {
        const pool = POOLS[poolSymbol]
        const assets = pool.getAssets()
        assets.forEach((asset) => {
          outputData.push({
            label: getItemLabel(poolSymbol, asset.tokenSymbol),
            poolSymbol,
            assetTokenSymbol: asset.tokenSymbol,
            assetTokenSymbolForDisplay: asset.tokenSymbolForDisplay,
          })
        })
      }
    }

    for (const poolSymbol of DEPRECATED_GAUGES_POOLS) {
      const deprecatedPool = POOLS[poolSymbol]
      for (const asset of deprecatedPool.getAssets(
        'deprecatedMainPoolForGauageVoting',
      )) {
        /**
         * add data
         */
        outputData.push({
          label: getItemLabel(deprecatedPool.poolSymbol, asset.tokenSymbol),
          poolSymbol: deprecatedPool.poolSymbol,
          assetTokenSymbol: asset.tokenSymbol,
          assetTokenSymbolForDisplay: asset.tokenSymbolForDisplay,
        })
      }
    }

    return outputData
  }, [])

  const handleItemClick = (item: GaugeSelectionModalItemType | undefined) => {
    if (item) {
      const isAdded = addedGaugeList.some(
        (gauge) =>
          gauge.assetTokenSymbol === item.assetTokenSymbol &&
          gauge.poolSymbol === item.poolSymbol,
      )
      if (isAdded) return
      addNewGauge({
        assetTokenSymbol: item.assetTokenSymbol,
        poolSymbol: item.poolSymbol,
      })
      // enter edit mode
      if (!isInEditMode) {
        enterEditMode()
      }
      onClose()
    }
  }
  return (
    <ItemSelectionModal
      isOpen={isOpen}
      onClose={onClose}
      topBarLabel="Select a gauge"
      filterInputProps={{
        value: filterInput,
        onChange: setFilterInput,
      }}
    >
      {items
        .filter((item) =>
          item.label.toLowerCase().includes(filterInput.toLowerCase()),
        )
        .map((item) => {
          const isSelected = votedAndAddedGaugeList.some(
            (gauge) =>
              gauge.assetTokenSymbol === item.assetTokenSymbol &&
              gauge.poolSymbol === item.poolSymbol,
          )
          return (
            <ItemSelectionModal.Item
              key={item.label}
              id={item.label}
              onClick={({ value }) => !isSelected && handleItemClick(value)}
              value={item}
              isSelected={isSelected}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
              >
                <AppTypography centerContent variant="inherit">
                  <TokenIcon
                    tokenSymbol={item.assetTokenSymbolForDisplay}
                    margin="0 8px 0 0"
                  />
                  {item.label}
                </AppTypography>
                {isSelected && 'Added'}
              </Box>
            </ItemSelectionModal.Item>
          )
        })}
    </ItemSelectionModal>
  )
}

export default GaugeSelectionModal
