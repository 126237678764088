import { getDpFormat, strToWad } from '@hailstonelabs/big-number-utils'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { constants } from 'ethers'
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Slidable from '../../../components/animations/Slidable/Slidable'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import TooltipText from '../../../components/TooltipText/TooltipText'
import { LP_TOKENS, POOLS } from '../../../config/contracts'
import { PoolSymbol } from '../../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useBalance } from '../../../contexts/BalanceContext'
import { useMulticallData } from '../../../contexts/MulticallDataContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import { usePoolInput } from '../../../contexts/PoolInputContext'
import { useStakeLpData } from '../../../contexts/StakeLpDataContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import useBreakpoints from '../../../hooks/useBreakpoints'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import {
  Content,
  PoolStakeButton,
  StakeAccordionContainer,
  StakeAccordionInnerContainer,
  StakeAccordionSummary,
  Title,
} from './PoolStakeAccordionContainer.elements'
import PoolStakeAccordionContentContainer from './PoolStakeAccordionContentContainer'

type RewardDataType = {
  icons: ReactElement[]
  baseApr: {
    sum: string
    breakdown: { tokenSymbol: TokenSymbol; value: string }[]
  }
  medianBoostedApr: {
    sum: string
    breakdown: { tokenSymbol: TokenSymbol; value: string }[]
  }
  boostedApr: {
    breakdown: { tokenSymbol: TokenSymbol; value: string }[]
  }
  totalApr: string
}
interface ContextType {
  poolSymbol: PoolSymbol
  assetTokenSymbol: TokenSymbol
  isPoolSymbolAndAssetTokenSymbolMatched: boolean
}

const PoolStakeAccordionContext = createContext<ContextType>({} as ContextType)
PoolStakeAccordionContext.displayName = 'PoolStakeAccordionContext'

export const usePoolStakeAccordion = (): ContextType => {
  return useContext(PoolStakeAccordionContext)
}
interface Props {
  poolSymbol: PoolSymbol
  assetTokenSymbol: TokenSymbol
  hasDeposit?: boolean
}
function PoolStakeAccordionContainer({
  poolSymbol,
  assetTokenSymbol,
  hasDeposit,
}: Props): ReactElement {
  const tokenSymbolForDisplay =
    LP_TOKENS[poolSymbol][assetTokenSymbol]?.tokenSymbolForDisplay ||
    assetTokenSymbol
  const { account } = useNetwork()
  const [isStakeButtonClicked, setIsStakeButtonClicked] = useState(false)
  /** @todo add test for this behavior */
  useEffect(() => {
    setIsStakeButtonClicked(false)
  }, [account])

  const {
    vePtp: { balance },
  } = useVePtp()
  const toggleClickStake = () => {
    setIsStakeButtonClicked((prev) => !prev)
  }
  const {
    lp,
    apr: { base, medianBoosted, exactBoosted, total },
    isBonusRewardersActive,
  } = useStakeLpData()
  const { isMulticallDataFetched, isMulticallDataFetchedWithAccount } =
    useMulticallData()
  const { isTokenPriceFetched } = useBalance()
  const { selectedPoolSymbol, selectedAssetTokenSymbol } = usePoolInput()
  const hasStakedLP = strToWad(
    lp.inTermsOfToken.staked[poolSymbol][assetTokenSymbol],
  ).gt(constants.Zero)
  const hasVePtp = balance.isMoreThanZero
  const hasAccordionContent = hasDeposit && isStakeButtonClicked
  const assetRewards = useMemo(
    () => POOLS[poolSymbol].assets[assetTokenSymbol]?.rewards,
    [poolSymbol, assetTokenSymbol],
  )
  const pool = POOLS[poolSymbol]

  const rewardData = useMemo(() => {
    const data: RewardDataType = {
      icons: [],
      baseApr: { sum: base.sum[poolSymbol][assetTokenSymbol], breakdown: [] },
      totalApr: total[poolSymbol][assetTokenSymbol],
      medianBoostedApr: {
        sum: medianBoosted.sum[poolSymbol][assetTokenSymbol],
        breakdown: [],
      },
      boostedApr: {
        breakdown: [],
      },
    }
    if (assetRewards) {
      for (const assetReward of assetRewards) {
        // check isBonusRewardersActive
        const isBonusRewarderActive =
          isBonusRewardersActive?.[poolSymbol][assetTokenSymbol]?.[
            assetReward.tokenSymbol
          ]
        const isPtpReward =
          assetReward.method === 'masterPlatypus.pendingTokens'
        // if it is bonus rewarded (not ptp reward), only display it if it is active.
        if (isPtpReward || isBonusRewarderActive) {
          data.icons.push(
            <TokenIcon
              key={assetReward.tokenSymbol}
              tokenSymbol={assetReward.tokenSymbol}
              margin="0 4px 0 0"
              size={16}
            />,
          )
          const baseApr =
            base.each[poolSymbol][assetTokenSymbol]?.[
              assetReward.tokenSymbol
            ] || '0.0'
          data.baseApr.breakdown.push({
            tokenSymbol: assetReward.tokenSymbol,
            value: baseApr,
          })
          const boostedApr =
            exactBoosted.each[poolSymbol][assetTokenSymbol]?.[
              assetReward.tokenSymbol
            ]
          const medianBoostedApr =
            medianBoosted.each[poolSymbol][assetTokenSymbol]?.[
              assetReward.tokenSymbol
            ]
          if (medianBoostedApr) {
            data.medianBoostedApr.breakdown.push({
              tokenSymbol: assetReward.tokenSymbol,
              value: medianBoostedApr,
            })
          }
          if (boostedApr) {
            data.boostedApr.breakdown.push({
              tokenSymbol: assetReward.tokenSymbol,
              value: boostedApr,
            })
          }
        }
      }
    }
    return data
  }, [
    isBonusRewardersActive,
    assetRewards,
    assetTokenSymbol,
    poolSymbol,
    base,
    total,
    exactBoosted.each,
    medianBoosted.each,
    medianBoosted.sum,
  ])

  return (
    <PoolStakeAccordionContext.Provider
      value={{
        assetTokenSymbol,
        poolSymbol,
        isPoolSymbolAndAssetTokenSymbolMatched:
          selectedPoolSymbol === poolSymbol &&
          selectedAssetTokenSymbol === assetTokenSymbol,
      }}
    >
      <StakeAccordionContainer>
        <StakeAccordionInnerContainer>
          <PoolStakeAccordionSummary
            title="Reward"
            content={<>{rewardData.icons}</>}
            {...(!(pool.hasPtpBooster || pool.hasBonusBooster) && {
              style: { marginRight: '12px' },
            })}
          />
          <PoolStakeAccordionSummary
            {...(!(pool.hasPtpBooster || pool.hasBonusBooster) && {
              style: { marginRight: 'auto' },
            })}
            title="Base APR"
            content={`${showDashIfNecessary(
              !isMulticallDataFetched || !isTokenPriceFetched,
              getDpFormat(rewardData.baseApr.sum, 1),
            )}%`}
            tooltipText={
              <TooltipText>
                <TooltipText.Message
                  message={`Base APR of this pool for the users who have deposited and
                  staked ${tokenSymbolForDisplay}.`}
                />
                <TooltipText.Group header="Base APR">
                  {rewardData.baseApr.breakdown.map((baseAprBreakdown) => (
                    <TooltipText.RewardItem
                      key={baseAprBreakdown.tokenSymbol}
                      title={baseAprBreakdown.tokenSymbol}
                      value={baseAprBreakdown.value}
                      isApr
                    />
                  ))}
                </TooltipText.Group>
              </TooltipText>
            }
          />
          {(pool.hasPtpBooster || pool.hasBonusBooster) && (
            <>
              <PoolStakeAccordionSummary
                title="Median Boosted APR"
                /** @todo this data is not available for now. wait for data team */
                content={`${showDashIfNecessary(
                  !isMulticallDataFetched || !isTokenPriceFetched,
                  getDpFormat(rewardData.medianBoostedApr.sum, 1),
                )}%`}
                tooltipText={
                  <TooltipText>
                    <TooltipText.Message
                      message={`The median boosted APR of rewards emission for the users who have staked ${tokenSymbolForDisplay} and hold vePTP. It does not include the Base APR.`}
                    />
                    <TooltipText.Group header="Median Boosted APR">
                      {rewardData.medianBoostedApr.breakdown.map(
                        (medianBoostedAprBreakdown) => (
                          <TooltipText.RewardItem
                            key={medianBoostedAprBreakdown.tokenSymbol}
                            title={medianBoostedAprBreakdown.tokenSymbol}
                            value={medianBoostedAprBreakdown.value}
                            isApr
                          />
                        ),
                      )}
                    </TooltipText.Group>
                  </TooltipText>
                }
              />
              <PoolStakeAccordionSummary
                title="My Total APR"
                content={`${showDashIfNecessary(
                  !isMulticallDataFetchedWithAccount || !isTokenPriceFetched,
                  hasStakedLP ? getDpFormat(rewardData.totalApr, 1) : '0.0',
                )}%`}
                tooltipText={
                  <TooltipText>
                    <TooltipText.Group>
                      <TooltipText.RewardItem
                        title="My Total APR"
                        value={hasStakedLP ? rewardData.totalApr : '0.0'}
                        isApr
                      />
                    </TooltipText.Group>
                    <TooltipText.Group header="Base APR">
                      {rewardData.baseApr.breakdown.map((baseAprBreakdown) => (
                        <TooltipText.RewardItem
                          key={baseAprBreakdown.tokenSymbol}
                          title={baseAprBreakdown.tokenSymbol}
                          value={hasStakedLP ? baseAprBreakdown.value : '0.0'}
                          isApr
                        />
                      ))}
                    </TooltipText.Group>
                    <TooltipText.Group header="Boosted APR">
                      {rewardData.boostedApr.breakdown.map(
                        (boostedAprBreakdown) => (
                          <TooltipText.RewardItem
                            key={boostedAprBreakdown.tokenSymbol}
                            title={boostedAprBreakdown.tokenSymbol}
                            value={
                              hasStakedLP ? boostedAprBreakdown.value : '0.0'
                            }
                            isApr
                          />
                        ),
                      )}
                    </TooltipText.Group>
                  </TooltipText>
                }
                variant={hasStakedLP && hasVePtp ? 'highlighted' : 'disabled'}
              />
            </>
          )}
        </StakeAccordionInnerContainer>
        <PoolStakeButton
          customVariant="secondary"
          $active={hasAccordionContent}
          onClick={toggleClickStake}
          disabled={!hasDeposit}
          data-testid="pool-stake-button"
        >
          {hasAccordionContent ? (
            <CloseIcon className="pool-stake-buttom__close-icon" />
          ) : (
            <>
              Stake
              <KeyboardArrowDownRoundedIcon />
            </>
          )}
        </PoolStakeButton>
      </StakeAccordionContainer>
      {hasAccordionContent && (
        <Slidable active={hasAccordionContent}>
          <PoolStakeAccordionContentContainer />
        </Slidable>
      )}
    </PoolStakeAccordionContext.Provider>
  )
}

export default PoolStakeAccordionContainer

export interface PoolStakeAccordionSummaryProps {
  title?: string
  content?: string | React.ReactElement
  variant?: 'disabled' | 'highlighted'
  tooltipText?: string | React.ReactElement
  style?: React.CSSProperties
}

export function PoolStakeAccordionSummary({
  title,
  content,
  variant,
  tooltipText,
  style,
}: PoolStakeAccordionSummaryProps): ReactElement {
  const { isTabletSm } = useBreakpoints()
  return (
    <StakeAccordionSummary style={style} $variant={variant}>
      <Title variant="caption3">{title}</Title>&nbsp;
      <Content variant="caption2">{content}</Content>
      {tooltipText && (
        <InfoBox.Tooltip
          text={tooltipText}
          placement={isTabletSm ? 'top' : 'right'}
          PopperProps={{
            style: {
              width: '250px',
            },
          }}
        />
      )}
    </StakeAccordionSummary>
  )
}
