import styled, { css } from 'styled-components'
import { ExternalLink } from '../../globalStyles'

export const StyledExternalLink = styled(ExternalLink)`
  ${({ theme }) => css`
    position: fixed;
    bottom: 22px;
    right: 28px;
    z-index: 100;
    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: none;
    }
  `}
`
