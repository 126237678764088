import { strToWad, sum } from '@hailstonelabs/big-number-utils'
import { constants } from 'ethers'
import React, { ReactElement, useMemo } from 'react'
import WaitForConfirmationModal from '../../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import DisabledAppTokenInput from '../../../../components/TokenInput/DisabledAppTokenInput'
import { POOLS } from '../../../../config/contracts'
import { RewardsTypeWithStrValue } from '../../../../config/contracts/pool/Pool'
import { PoolGroup } from '../../../../config/contracts/pool/PoolGroup'
import { TokenSymbol } from '../../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../../contexts/ModalContext'
import { usePoolInput } from '../../../../contexts/PoolInputContext'
import { useStakeLpData } from '../../../../contexts/StakeLpDataContext'

interface Props {
  isOpen: boolean
}

function PoolClaimWaitForConfirmationModalContainer({
  isOpen,
}: Props): ReactElement {
  const {
    modalDispatch,
    modalState,
    actions: { closeModal },
  } = useModal()

  const tokenSymbol: TokenSymbol | 'all' =
    modalState.tokenSymbols && modalState.tokenSymbols[0]
      ? modalState.tokenSymbols[0]
      : 'all'
  const handleCloseModal = () => {
    modalDispatch(closeModal())
  }
  const { rewards } = useStakeLpData()
  const { selectedPoolSymbol } = usePoolInput()
  const allRewards = useMemo<RewardsTypeWithStrValue>(() => {
    if (rewards) {
      const sumOfEachReward: Record<string, string> = {}
      const sumOfEachRewardPerPoolGroup = PoolGroup.getSumOfEachReward(
        modalState.poolSymbols || [],
        rewards,
      )
      /**
       * add up the rewards
       */
      for (const [tokenSymbol, amount] of Object.entries(
        sumOfEachRewardPerPoolGroup,
      )) {
        if (sumOfEachReward[tokenSymbol]) {
          sumOfEachReward[tokenSymbol] = sum(
            sumOfEachReward[tokenSymbol],
            amount,
          )
        } else {
          sumOfEachReward[tokenSymbol] = amount
        }
      }
      /**
       * get reward data with value !== 0
       */
      return Object.entries(sumOfEachReward)
        .filter((data) => {
          const value = data[1]
          return strToWad(value).gt(constants.Zero)
        })
        .reduce((prev, current) => {
          const [tokenSymbol, value] = current
          return [...prev, { tokenSymbol, value }] as RewardsTypeWithStrValue
        }, [] as RewardsTypeWithStrValue)
    }
    return []
  }, [rewards, modalState.poolSymbols])
  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="600px"
      subtitle={'Claim Rewards'}
    >
      <DisabledAppTokenInput
        tokenItems={
          tokenSymbol === 'all'
            ? allRewards
            : POOLS[selectedPoolSymbol].getRewardsEarned(rewards, tokenSymbol)
        }
      />
    </WaitForConfirmationModal>
  )
}

export default PoolClaimWaitForConfirmationModalContainer
