import cloneDeep from 'lodash.clonedeep'
import { useEffect, useState } from 'react'

// reference: https://usehooks.com/useDebounce/
function useDebounce<T>(value: T, delay = 350): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value)
  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(cloneDeep(value))
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
