import echidnaLogo from '../../assets/airdrops/echidna.png'
import hummusLogo from '../../assets/airdrops/hummus.svg'
import vectorLogo from '../../assets/airdrops/vector.svg'
import ptpLogo from '../../assets/companyLogos/platypus-light-mobile.svg'
import {
  AIRDROPS_ECHIDNA_URL,
  AIRDROPS_HUMMUS_URL,
  AIRDROPS_PLATYPUS_URL,
  AIRDROPS_VECTOR_URL,
} from '../../constants'
import { Airdrops } from './Airdrops'

export enum AirdropId {
  PTP_OFFICIAL = 'ptpoffical',
  HUMMUS = 'hummus',
  VECTOR = 'vector',
  ECHIDNA = 'echidna',
}

export const AIRDROPS: { [id in AirdropId]: Airdrops } = {
  [AirdropId.PTP_OFFICIAL]: new Airdrops(
    AirdropId.PTP_OFFICIAL,
    'Platypus official airdrop',
    ptpLogo,
    AIRDROPS_PLATYPUS_URL,
    {
      hasApr: false,
      enabled: true,
    },
  ),
  [AirdropId.HUMMUS]: new Airdrops(
    AirdropId.HUMMUS,
    'Hummus airdrop',
    hummusLogo,
    AIRDROPS_HUMMUS_URL,
    {
      hasApr: true,
      enabled: true,
    },
  ),
  [AirdropId.VECTOR]: new Airdrops(
    AirdropId.VECTOR,
    'Vector airdrop',
    vectorLogo,
    AIRDROPS_VECTOR_URL,
    {
      hasApr: false,
      enabled: true,
    },
  ),
  [AirdropId.ECHIDNA]: new Airdrops(
    AirdropId.ECHIDNA,
    'Echidna airdrop',
    echidnaLogo,
    AIRDROPS_ECHIDNA_URL,
    {
      hasApr: false,
      enabled: true,
    },
  ),
}

export const airdrops = Object.values(AirdropId)
