import {
  getCommifiedFormat,
  getDpFormat,
  strToWad,
  WAD,
} from '@hailstonelabs/big-number-utils'
import { Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { constants, utils } from 'ethers'
import React, { ReactElement } from 'react'
import InfoBox from '../../components/InfoBox/InfoBox'
import TooltipNum from '../../components/InfoBox/TooltipNum'
import { TOKENS } from '../../config/contracts/token'
import { useBalance } from '../../contexts/BalanceContext'
import { usePoolInput } from '../../contexts/PoolInputContext'
import { usePools } from '../../contexts/PoolsContext'
import { ExternalLink } from '../../globalStyles'
import { lptokenBNToToken } from '../../utils/pool'

const infoData = {
  AMOUNT_WITHDRAWING: {
    label: 'Amount withdrawing (after fee)',
    tooltip: 'The amount you are expecting to get, after withdrawal fee.',
  },
  FEE: {
    label: 'Fee',
    tooltip: (
      <>
        A withdrawal fee will apply when the pool&apos;s coverage ratio is below
        100%. This fee is already included in the amount withdrawing displayed
        above. Please see our&nbsp;
        <ExternalLink href="https://docs.platypus.finance/platypus-finance-docs/our-innovative-concepts/fees/withdrawal-fee">
          whitepaper
        </ExternalLink>
        &nbsp;for the fee calculation.
      </>
    ),
  },
  MINIMUM_RECEIVED: {
    label: 'Minimum Received',
    tooltip: `The transaction will revert if you're unable to receive at least this amount due to slippage. Adjust this amount in the withdrawal slippage tolorance % settings.`,
  },
  MY_REMAINING_DEPOSIT: {
    label: 'My Remaining Deposit',
  },
  MY_REMAINING_SHARE: {
    label: 'My Remaining Pool Share',
  },
}
interface Props {
  isWaitForComfirmation?: boolean
}

function PoolWithdrawInfoBox({
  isWaitForComfirmation = false,
}: Props): ReactElement {
  const { lpTokenAmounts } = useBalance()
  const {
    withdrawMaximumPercentage,
    withdrawLiquidity,
    withdrawFee,
    withdrawTokenAmount,
    withdrawMinimumReceived,
    withdrawalPercentage,
    selectedPoolSymbol,
    selectedAssetTokenSymbol,
  } = usePoolInput()
  const { liabilities, lpSuppliesBN } = usePools()
  const minimumReceived = withdrawLiquidity.toTokenSymbol
    ? utils.formatUnits(
        withdrawMinimumReceived,
        TOKENS[withdrawLiquidity.toTokenSymbol].decimals,
      )
    : '0.0'
  let myRemainingDepositInToken = '0.0'
  let myRemainingSharePercent = '0.0'

  if (selectedAssetTokenSymbol) {
    const decimals = TOKENS[selectedAssetTokenSymbol].decimals
    try {
      if (withdrawLiquidity.amount) {
        const withdrawAmountInLpBN = utils.parseUnits(
          withdrawLiquidity.amount,
          decimals,
        )
        myRemainingDepositInToken = lptokenBNToToken(
          utils
            .parseUnits(
              lpTokenAmounts[selectedPoolSymbol][selectedAssetTokenSymbol],
              decimals,
            )
            .sub(withdrawAmountInLpBN),
          lpSuppliesBN[selectedPoolSymbol][selectedAssetTokenSymbol],
          utils.parseUnits(
            liabilities[selectedPoolSymbol][selectedAssetTokenSymbol],
            decimals,
          ),
          selectedAssetTokenSymbol,
        )
      }

      const myRemainingDepositInTokenBN = utils.parseUnits(
        myRemainingDepositInToken,
        decimals,
      )
      const poolDepositsBN = utils.parseUnits(
        liabilities[selectedPoolSymbol][selectedAssetTokenSymbol],
        decimals,
      )
      // remaining share % = my remaining deposit / pool deposits * 100%
      const myRemainingSharePercentBN = myRemainingDepositInTokenBN
        .mul(WAD)
        .mul('100')
        .div(poolDepositsBN.isZero() ? constants.One : poolDepositsBN)

      myRemainingSharePercent = utils.formatEther(myRemainingSharePercentBN)
    } catch (err) {
      const errorObj = {
        name: 'utils.parseUnits',
        tokenSymbol: withdrawLiquidity.tokenSymbol,
        amount: withdrawLiquidity.amount,
        myRemainingDepositInToken,
        poolDeposits: liabilities[selectedPoolSymbol][selectedAssetTokenSymbol],
        decimals: decimals,
        withdrawalPercentage,
        // in order to check lpAmount have correct decimals or not
        lpAmount: lpTokenAmounts[selectedPoolSymbol][selectedAssetTokenSymbol],
        allLpAmount: JSON.stringify(lpTokenAmounts),
      }
      Sentry.setContext('withdraw_info_calculation', errorObj)
      Sentry.captureException(err)
      console.error(err, errorObj)
    }
  }
  return (
    <InfoBox>
      {!isWaitForComfirmation && (
        <>
          {withdrawLiquidity.toTokenSymbolForDisplay &&
            withdrawMaximumPercentage !== '' &&
            strToWad(withdrawMaximumPercentage).lt(strToWad('100')) && (
              <InfoBox.Item>
                <InfoBox.Item>
                  <Typography variant="caption">
                    {strToWad(withdrawMaximumPercentage).isZero()
                      ? `*Withdrawal in ${withdrawLiquidity.toTokenSymbolForDisplay} is unavailable because the coverage ratio of ${withdrawLiquidity.toTokenSymbolForDisplay} is not greater than 100%.
                      `
                      : `*Max. ${
                          withdrawLiquidity.toTokenSymbolForDisplay
                        } withdrawal capped at ${getDpFormat(
                          withdrawMaximumPercentage,
                          2,
                        )}% of the deposit amount `}
                  </Typography>
                  <InfoBox.Tooltip text="The protocol does not allow withdrawal in other tokens of amount that lead to coverage ratio less than 1" />
                </InfoBox.Item>
              </InfoBox.Item>
            )}

          <InfoBox.Item>
            <InfoBox.Item>
              <Typography variant="caption">
                {infoData.AMOUNT_WITHDRAWING.label}
              </Typography>
              <InfoBox.Tooltip text={infoData.AMOUNT_WITHDRAWING.tooltip} />
            </InfoBox.Item>
            <InfoBox.Item>
              <Typography variant="caption">
                <b>
                  <TooltipNum
                    rightSymbol={
                      withdrawLiquidity.toTokenSymbolForDisplay || ''
                    }
                    amount={withdrawTokenAmount}
                  >
                    {getCommifiedFormat(withdrawTokenAmount)}{' '}
                    {withdrawLiquidity.toTokenSymbolForDisplay}
                  </TooltipNum>
                </b>
              </Typography>
            </InfoBox.Item>
          </InfoBox.Item>
        </>
      )}
      <InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">{infoData.FEE.label}</Typography>
          <InfoBox.Tooltip text={infoData.FEE.tooltip} />
        </InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">
            <b>
              <TooltipNum
                amount={withdrawFee}
                rightSymbol={withdrawLiquidity.toTokenSymbolForDisplay || ''}
              >
                {getCommifiedFormat(withdrawFee)}{' '}
                {withdrawLiquidity.toTokenSymbolForDisplay}
              </TooltipNum>
            </b>
          </Typography>
        </InfoBox.Item>
      </InfoBox.Item>
      <InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">
            {infoData.MINIMUM_RECEIVED.label}
          </Typography>
          <InfoBox.Tooltip text={infoData.MINIMUM_RECEIVED.tooltip} />
        </InfoBox.Item>
        <InfoBox.Item>
          <Typography variant="caption">
            <b>
              <TooltipNum
                amount={minimumReceived}
                rightSymbol={withdrawLiquidity.toTokenSymbolForDisplay || ''}
              >
                {getCommifiedFormat(minimumReceived)}{' '}
                {withdrawLiquidity.toTokenSymbolForDisplay}
              </TooltipNum>
            </b>
          </Typography>
        </InfoBox.Item>
      </InfoBox.Item>
      {!isWaitForComfirmation && (
        <>
          <hr style={{ width: '100%', opacity: '0' }} />
          <InfoBox.Item>
            <InfoBox.Item>
              <Typography variant="caption">
                {infoData.MY_REMAINING_DEPOSIT.label}
              </Typography>
            </InfoBox.Item>
            <InfoBox.Item>
              <Typography variant="caption">
                <b>
                  <TooltipNum
                    amount={myRemainingDepositInToken}
                    rightSymbol={
                      withdrawLiquidity.toTokenSymbolForDisplay || undefined
                    }
                  >
                    {getCommifiedFormat(myRemainingDepositInToken)}&nbsp;
                    {withdrawLiquidity.toTokenSymbolForDisplay}
                  </TooltipNum>
                </b>
              </Typography>
            </InfoBox.Item>
          </InfoBox.Item>
          <InfoBox.Item>
            <InfoBox.Item>
              <Typography variant="caption">
                {infoData.MY_REMAINING_SHARE.label}
              </Typography>
            </InfoBox.Item>
            <InfoBox.Item>
              <Typography variant="caption">
                <b>
                  <TooltipNum
                    amount={myRemainingSharePercent}
                    rightSymbol={'%'}
                  >
                    {getCommifiedFormat(myRemainingSharePercent)} %
                  </TooltipNum>
                </b>
              </Typography>
            </InfoBox.Item>
          </InfoBox.Item>
        </>
      )}
    </InfoBox>
  )
}

export default PoolWithdrawInfoBox
