import { useModal } from '../../contexts/ModalContext'
import { ModalId } from '../../interfaces/Modal'

type ReturnType = {
  handleNftChangeAction: (currentId: string | null, newId: string) => void
}
function useNftChangeAction(): ReturnType {
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const handleNftChangeAction = (currentId: string | null, newId: string) => {
    if (!currentId) {
      /**
       * 1. Equip an nft if users haven't yet selected any one
       * Equipment => current is null and changeTo contains an object
       */
      const modalNftData = {
        current: null,
        changeTo: {
          id: newId,
        },
      }

      modalDispatch(
        openModal(ModalId.EQUIP_NFT_CONFIRMATION, {
          nftData: modalNftData,
        }),
      )
      return
    } else if (newId === currentId) {
      /**
       * 2. Unequip the nft if users select the same one
       * Unequipment => current contains an object and changeTo is null
       */
      const modalNftData = {
        current: {
          id: newId,
        },
        changeTo: null,
      }
      modalDispatch(
        openModal(ModalId.UNEQUIP_NFT_CONFIRMATION, {
          nftData: modalNftData,
        }),
      )
      return
    } else {
      /**
       * 3. Change to another nft if users have already equipped one
       * Change => both current and changeTo contain objects
       */
      const modalNftData = {
        current: {
          id: currentId,
        },
        changeTo: {
          id: newId,
        },
      }
      modalDispatch(
        openModal(ModalId.CHANGE_NFT_CONFIRMATION, {
          nftData: modalNftData,
        }),
      )
      return
    }
  }
  return { handleNftChangeAction }
}

export default useNftChangeAction
