import {
  getPercentageFromTwoWAD,
  strToWad,
  wmul,
} from '@hailstonelabs/big-number-utils'
import { constants } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { AirdropId, AIRDROPS, airdrops } from '../../config/airdrops'
import { AIRDROP_HUMMUS_AMOUNT } from '../../constants'
import { useBalance } from '../../contexts/BalanceContext'
import { useVePtp } from '../../contexts/VePtpContext'
import { useVotingData } from '../../contexts/VotingDataContext'

export type AirdropStringType = {
  [id in AirdropId]: string
}

export const EMPTY_AIRDROP_STR = airdrops.reduce((acc, airdropId) => {
  return { ...acc, [airdropId]: '0.0' }
}, {}) as AirdropStringType

export const useBenefitApr = () => {
  const { ptp } = useVePtp()
  const {
    bribeAprData: { annual: votingApr },
  } = useVotingData()
  const { tokenPrices } = useBalance()
  const [airdropsApr, setAirdropsBenefitApr] =
    useState<AirdropStringType>(EMPTY_AIRDROP_STR)

  const hummusAirdropApr = getPercentageFromTwoWAD(
    wmul(strToWad(AIRDROP_HUMMUS_AMOUNT), strToWad(tokenPrices.HUM)).div('2'),
    wmul(strToWad(tokenPrices.PTP), strToWad(ptp.total.amount.sum)),
  )

  useEffect(() => {
    setAirdropsBenefitApr({
      ...EMPTY_AIRDROP_STR,
      [AirdropId.HUMMUS]: hummusAirdropApr,
    })
  }, [hummusAirdropApr])

  const total = useMemo(() => {
    let totalBenefitAprWad = constants.Zero
    let totalAirdropAprWad = constants.Zero

    totalAirdropAprWad = Object.entries(airdropsApr).reduce(
      (prev, [airdropId, airdropBenefitApr]) => {
        //filter those airdrop not in veptp benefit apr calculation
        if (!AIRDROPS[airdropId as AirdropId].settings.hasApr) {
          return prev
        }
        return strToWad(airdropBenefitApr).add(prev)
      },
      constants.Zero,
    )

    totalBenefitAprWad = totalAirdropAprWad.add(strToWad(votingApr.percentage))
    return {
      totalBenefitAprWad,
      totalAirdropAprWad,
    }
  }, [airdropsApr, votingApr.percentage])

  return {
    airdropsApr,
    votingApr: votingApr.percentage,
    total,
  }
}
