import { ContractInterface } from 'ethers'
import {
  EmergencyWithdraw01,
  EmergencyWithdraw02,
  EmergencyWithdraw03,
} from '../../../../types/ethers-contracts'
import { ChainId } from '../../networks'
import EmergencyWithdraw01ABI from '../abis/EmergencyWithdraw01.json'
import EmergencyWithdraw02ABI from '../abis/EmergencyWithdraw02.json'
import EmergencyWithdraw03ABI from '../abis/EmergencyWithdraw03.json'
import { AddressInterface, Contract } from '../Contract'
import { PoolSymbol } from '../pool/poolSymbol'
import { TokenSymbol } from '../token/tokenSymbol'
import { EMERGENCY_WITHDRAW_01_ABI } from '../wagmiAbis/EmergencyWithdraw01'
import { EMERGENCY_WITHDRAW_02_ABI } from '../wagmiAbis/EmergencyWithdraw02'
import { EMERGENCY_WITHDRAW_03_ABI } from '../wagmiAbis/EmergencyWithdraw03'

export enum EmergencyWithdrawId {
  EMERGENCY_WITHDRAW_01 = 'EMERGENCY_WITHDRAW_01',
  EMERGENCY_WITHDRAW_02 = 'EMERGENCY_WITHDRAW_02',
  EMERGENCY_WITHDRAW_03 = 'EMERGENCY_WITHDRAW_03',
}
export class EmergencyWithdraw extends Contract<
  EmergencyWithdraw01 | EmergencyWithdraw02 | EmergencyWithdraw03,
  | typeof EMERGENCY_WITHDRAW_01_ABI
  | typeof EMERGENCY_WITHDRAW_02_ABI
  | typeof EMERGENCY_WITHDRAW_03_ABI
> {
  readonly emergencyWithdrawId: EmergencyWithdrawId
  // Token for User got after EmergencyWithdraw
  readonly toTokenSymbol: TokenSymbol
  constructor(
    addresses: AddressInterface,
    abi: ContractInterface,
    wagmiAbi:
      | typeof EMERGENCY_WITHDRAW_01_ABI
      | typeof EMERGENCY_WITHDRAW_02_ABI
      | typeof EMERGENCY_WITHDRAW_03_ABI,
    emergencyWithdrawId: EmergencyWithdrawId,
    toTokenSymbol: TokenSymbol,
  ) {
    super(addresses, abi, wagmiAbi)
    this.emergencyWithdrawId = emergencyWithdrawId
    this.toTokenSymbol = toTokenSymbol
  }
}
export const EMERGENCY_WITHDRAWS: {
  [id in PoolSymbol]?: {
    [id in TokenSymbol]?: EmergencyWithdraw
  }
} = {
  [PoolSymbol.DEPRECATED_MAIN]: {
    [TokenSymbol.MIM]: new EmergencyWithdraw(
      {
        [ChainId.AVALANCHE]: '0x6c84f0580C8FfAb0c716c87E66Ab474E4BeA97d9',
        [ChainId.FUJI]: '0x8ca452807A9Cec08d8ac9D1C83AFf3EBd5Ea6E6E',
      },
      EmergencyWithdraw01ABI,
      EMERGENCY_WITHDRAW_01_ABI,
      EmergencyWithdrawId.EMERGENCY_WITHDRAW_01,
      TokenSymbol.MIM,
    ),
  },
  [PoolSymbol.DEPRECATED_ALT_USDC_UST]: {
    [TokenSymbol.USDC]: new EmergencyWithdraw(
      {
        [ChainId.AVALANCHE]: '0xefa5d088a58a2d4ee5504102c5ffde69301527b0',
        [ChainId.FUJI]: '0x7B05E8210304fDB5D59d82D740e32d5800A79341',
      },
      EmergencyWithdraw02ABI,
      EMERGENCY_WITHDRAW_02_ABI,
      EmergencyWithdrawId.EMERGENCY_WITHDRAW_02,
      TokenSymbol.UST,
    ),
    [TokenSymbol.UST]: new EmergencyWithdraw(
      {
        [ChainId.AVALANCHE]: '0xefa5d088a58a2d4ee5504102c5ffde69301527b0',
        [ChainId.FUJI]: '0x7B05E8210304fDB5D59d82D740e32d5800A79341',
      },
      EmergencyWithdraw02ABI,
      EMERGENCY_WITHDRAW_02_ABI,
      EmergencyWithdrawId.EMERGENCY_WITHDRAW_02,
      TokenSymbol.UST,
    ),
  },
  [PoolSymbol.FACTORY_USDC_MIMATIC]: {
    [TokenSymbol.USDC]: new EmergencyWithdraw(
      {
        [ChainId.AVALANCHE]: '0x1abB6Bf97506C9B4AC985F558C4Ee6Eeb9C11F1D',
        [ChainId.FUJI]: undefined,
      },
      EmergencyWithdraw03ABI,
      EMERGENCY_WITHDRAW_03_ABI,
      EmergencyWithdrawId.EMERGENCY_WITHDRAW_03,
      /** @todo should be usdc or mai */
      TokenSymbol.USDC,
    ),
    [TokenSymbol.MIMATIC]: new EmergencyWithdraw(
      {
        [ChainId.AVALANCHE]: '0x1abB6Bf97506C9B4AC985F558C4Ee6Eeb9C11F1D',
        [ChainId.FUJI]: undefined,
      },
      EmergencyWithdraw03ABI,
      EMERGENCY_WITHDRAW_03_ABI,
      EmergencyWithdrawId.EMERGENCY_WITHDRAW_03,
      TokenSymbol.MIMATIC,
    ),
  },
}
