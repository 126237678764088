import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import routes from '../../config/routes'
import SwapContainer from '../../containers/SwapContainer/SwapContainer'
import { SwapProvider } from '../../contexts/SwapContext'

function SwapPage(): React.ReactElement {
  useEffect(() => {
    ReactGA.pageview(routes.STAKE.path)
  }, [])
  return (
    <>
      <SwapProvider>
        <SwapContainer />
      </SwapProvider>
    </>
  )
}

export default SwapPage
