import { isParsableString, strToWad } from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import React, { ReactElement, useState } from 'react'
import { USER_PREFERENCE_INPUT_PRECISION } from '../../../constants'
import { useModal } from '../../../contexts/ModalContext'
import {
  SlippageType,
  useUserPreference,
  WithrawalInOtherTokensType,
} from '../../../contexts/UserPreferenceContext'
import {
  CustomOption,
  RadioButton,
  RadioButtonContainer,
  RadioButtonsContainer,
  StyledInfoBox,
  StyledModal,
  StyledWarningMessage,
  SubHeader,
  Title,
  Unit,
} from './UserPreferenceModalContainer.elements'

const swapPresetSettings = {
  slippage: {
    options: ['0.5', '1'],
    tooltip:
      'Your transaction will revert if the price changes unfavorably by more than this percentage due to slippage.',
  },
}
const withrawalInOtherTokensOptions: WithrawalInOtherTokensType[] = [
  'On',
  'Off',
]
const withdrawalPresetSettings = {
  slippage: {
    options: ['0.5', '1'],
    tooltip:
      'Your transaction will revert if the price changes unfavorably by more than this percentage due to slippage.',
  },
  withrawalInOtherTokens: {
    options: withrawalInOtherTokensOptions,
    tooltip: 'Enable withdrawal in tokens different from the deposited token',
  },
}
const transactionDeadlinePresetSettings = {
  tooltip:
    'Your transaction will revert if it has been pending for more than this time.',
}

const isIncludedInAList = (num: string, listOfNum: string[]) => {
  if (num !== '') {
    return listOfNum.findIndex((n) => compareTwoNums(n, num, 'eq')) > -1
  }
  return false
}

const isWithinRange = (
  num: string,
  lowerBound: string,
  higherBound: string,
) => {
  const parsedNum = strToWad(num)
  const parsedLowerBound = strToWad(lowerBound)
  const parsedHigherBound = strToWad(higherBound)
  return parsedNum.gte(parsedLowerBound) && parsedNum.lte(parsedHigherBound)
}
const compareTwoNums = (num1: string, num2: string, operator: 'lt' | 'eq') => {
  const parsedNum1 = strToWad(num1)
  const parsedNum2 = strToWad(num2)
  return parsedNum1[operator](parsedNum2)
}
const convertStringToPercentage = (value: string) => {
  return utils.formatEther(strToWad(value).mul(100))
}

const convertPercentageToFloatStringIfValid = (value: string) => {
  const isInputValid =
    isParsableString(value, USER_PREFERENCE_INPUT_PRECISION, true) ||
    value === ''
  if (isInputValid) {
    let processedValue = value
    if (value !== '') {
      processedValue = utils.formatEther(strToWad(value).div(100))
    }
    return processedValue
  }
}
const controlRadioButton = (ele: Element) => {
  const parent = ele.closest('.swap-info-box__trailing')

  if (parent) {
    const currentActiveEles = parent.querySelectorAll('.active')
    if (currentActiveEles.length > 0) {
      currentActiveEles.forEach((currentActiveEle) => {
        currentActiveEle.classList.remove('active')
      })
    }
  }
  ele.classList.toggle('active')
}

interface Props {
  isOpen: boolean
}

function UserPreferenceModalContainer({ isOpen }: Props): ReactElement {
  const {
    userPreference,
    initialUserPreference,
    actions: {
      updateSlippage,
      updateWithrawalInOtherTokens,
      updateTransactionDeadline,
    },
  } = useUserPreference()
  const [swapSlippage, setSwapSlippage] = useState(
    convertStringToPercentage(userPreference.slippage.swap),
  )
  const [withdrawalSlippage, setWithdrawalSlippage] = useState(
    convertStringToPercentage(userPreference.slippage.withdrawal),
  )
  const [transactionDeadline, setTransactionDeadline] = useState(
    userPreference.transactionDeadline,
  )
  const [isCustomSwapSlippageBtnChecked, setIsCustomSwapSlippageBtnChecked] =
    useState(false)
  const [
    isCustomWithdrawalSlippageBtnChecked,
    setIsCustomWithdrawalSlippageBtnChecked,
  ] = useState(false)
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const handleModalClose = () => {
    modalDispatch(closeModal())
  }
  const handleSlippageChange = (value: string, type: SlippageType) => {
    const isParsable = isParsableString(
      value,
      USER_PREFERENCE_INPUT_PRECISION,
      true,
    )
    let withinRange
    if (value === '') {
      withinRange = true
    } else {
      if (isParsable) {
        withinRange = isWithinRange(value, '0', '10000')
      }
    }
    if ((isParsable || value === '') && withinRange) {
      let processedValue = value
      if (value !== '') {
        processedValue = utils.formatEther(strToWad(value).div(100))
      }

      if (type === 'swap') {
        setIsCustomSwapSlippageBtnChecked(true)
        setSwapSlippage(value)
      }

      if (type === 'withdrawal') {
        setIsCustomWithdrawalSlippageBtnChecked(true)
        setWithdrawalSlippage(value)
      }
      updateSlippage(processedValue, type)
    }
  }

  const handleTransactionDeadlineChange = (value: string) => {
    const isParsable = isParsableString(
      value,
      USER_PREFERENCE_INPUT_PRECISION,
      true,
    )
    let withinRange
    if (value === '') {
      withinRange = true
    } else {
      if (isParsable) {
        withinRange = isWithinRange(value, '1', '10000')
      }
    }
    if ((isParsable || value == '') && withinRange) {
      setTransactionDeadline(value)
      updateTransactionDeadline(value)
    }
  }

  const handleSlippageButtonClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    option: string,
    type: SlippageType,
    isCustomBtnChecked = false,
  ) => {
    const ele = e.target as Element
    controlRadioButton(ele)
    if (type === 'swap') {
      setIsCustomSwapSlippageBtnChecked(isCustomBtnChecked)
    }
    if (type === 'withdrawal') {
      setIsCustomWithdrawalSlippageBtnChecked(isCustomBtnChecked)
    }
    const convertedValue = convertPercentageToFloatStringIfValid(option)
    if (convertedValue) {
      updateSlippage(convertedValue, type)
    }
  }
  return (
    <StyledModal
      isOpen={isOpen}
      topBarLabel="Settings"
      onClose={() => handleModalClose()}
      width="400px"
    >
      <SubHeader variant="caption">Swap</SubHeader>
      <StyledInfoBox>
        <StyledInfoBox.Item flexWrap="wrap">
          <StyledInfoBox.Item fullWidth>
            <Title>Slippage Tolerance</Title>
            <StyledInfoBox.Tooltip text={swapPresetSettings.slippage.tooltip} />
          </StyledInfoBox.Item>
          <StyledInfoBox.Item className="swap-info-box__trailing">
            <RadioButtonsContainer>
              {swapPresetSettings.slippage.options.map((option, index) => {
                return (
                  <RadioButtonContainer key={index}>
                    <RadioButton
                      className={
                        compareTwoNums(
                          convertStringToPercentage(
                            userPreference.slippage.swap,
                          ),
                          option,
                          'eq',
                        ) && !isCustomSwapSlippageBtnChecked
                          ? 'active'
                          : ''
                      }
                      onClick={(e) =>
                        handleSlippageButtonClick(e, option, 'swap')
                      }
                    />
                    {option}
                    <Unit>%</Unit>
                  </RadioButtonContainer>
                )
              })}
              <RadioButtonContainer>
                <RadioButton
                  className={
                    !isIncludedInAList(
                      convertStringToPercentage(userPreference.slippage.swap),
                      swapPresetSettings.slippage.options,
                    ) || isCustomSwapSlippageBtnChecked
                      ? 'active'
                      : ''
                  }
                  onClick={(e) => {
                    handleSlippageButtonClick(e, swapSlippage, 'swap', true)
                  }}
                />
                <CustomOption
                  placeholder={convertStringToPercentage(
                    initialUserPreference.slippage.swap,
                  )}
                  value={swapSlippage}
                  onChange={(value) => handleSlippageChange(value, 'swap')}
                  inputMode="decimal"
                ></CustomOption>
                <Unit>%</Unit>
              </RadioButtonContainer>
            </RadioButtonsContainer>
          </StyledInfoBox.Item>
        </StyledInfoBox.Item>
      </StyledInfoBox>
      {compareTwoNums(
        userPreference.slippage.swap || initialUserPreference.slippage.swap,
        initialUserPreference.slippage.swap,
        'lt',
      ) && <StyledWarningMessage message="Your transaction may fail!" />}
      <hr />
      <SubHeader>Withdrawal</SubHeader>
      <StyledInfoBox>
        <StyledInfoBox.Item flexWrap="wrap">
          <StyledInfoBox.Item fullWidth>
            <Title>Slippage Tolerance</Title>
            <StyledInfoBox.Tooltip
              text={withdrawalPresetSettings.slippage.tooltip}
            />
          </StyledInfoBox.Item>
          <StyledInfoBox.Item className="swap-info-box__trailing">
            <RadioButtonsContainer>
              {withdrawalPresetSettings.slippage.options.map(
                (option, index) => (
                  <RadioButtonContainer key={index}>
                    <RadioButton
                      className={
                        compareTwoNums(
                          convertStringToPercentage(
                            userPreference.slippage.withdrawal,
                          ),
                          option,
                          'eq',
                        ) && !isCustomWithdrawalSlippageBtnChecked
                          ? 'active'
                          : ''
                      }
                      onClick={(e) =>
                        handleSlippageButtonClick(e, option, 'withdrawal')
                      }
                    />
                    {option}
                    <Unit>%</Unit>
                  </RadioButtonContainer>
                ),
              )}
              <RadioButtonContainer>
                <RadioButton
                  className={
                    !isIncludedInAList(
                      convertStringToPercentage(
                        userPreference.slippage.withdrawal,
                      ),
                      withdrawalPresetSettings.slippage.options,
                    ) || isCustomWithdrawalSlippageBtnChecked
                      ? 'active'
                      : ''
                  }
                  onClick={(e) =>
                    handleSlippageButtonClick(
                      e,
                      withdrawalSlippage,
                      'withdrawal',
                      true,
                    )
                  }
                />
                <CustomOption
                  placeholder={convertStringToPercentage(
                    initialUserPreference.slippage.withdrawal,
                  )}
                  value={withdrawalSlippage}
                  onChange={(value) =>
                    handleSlippageChange(value, 'withdrawal')
                  }
                  inputMode="decimal"
                ></CustomOption>
                <Unit>%</Unit>
              </RadioButtonContainer>
            </RadioButtonsContainer>
          </StyledInfoBox.Item>
        </StyledInfoBox.Item>
      </StyledInfoBox>
      {compareTwoNums(
        userPreference.slippage.withdrawal ||
          initialUserPreference.slippage.withdrawal,
        initialUserPreference.slippage.withdrawal,
        'lt',
      ) && <StyledWarningMessage message="Your transaction may fail!" />}
      <StyledInfoBox mt="4px">
        <StyledInfoBox.Item flexWrap="wrap">
          <StyledInfoBox.Item fullWidth>
            <Title>Withdrawal In Other Tokens</Title>
            <StyledInfoBox.Tooltip
              text={withdrawalPresetSettings.withrawalInOtherTokens.tooltip}
            />
          </StyledInfoBox.Item>
          <StyledInfoBox.Item className="swap-info-box__trailing">
            <RadioButtonsContainer>
              {withdrawalPresetSettings.withrawalInOtherTokens.options.map(
                (option, index) => (
                  <RadioButtonContainer key={index}>
                    <RadioButton
                      className={
                        option === userPreference.withrawalInOtherTokens
                          ? 'active'
                          : ''
                      }
                      onClick={() => updateWithrawalInOtherTokens(option)}
                    />
                    {option}
                  </RadioButtonContainer>
                ),
              )}
            </RadioButtonsContainer>
          </StyledInfoBox.Item>
        </StyledInfoBox.Item>
      </StyledInfoBox>
      <hr />
      <SubHeader>Transaction</SubHeader>
      <StyledInfoBox>
        <StyledInfoBox.Item fullWidth>
          <Title>Transaction Deadline</Title>
          <StyledInfoBox.Tooltip
            text={transactionDeadlinePresetSettings.tooltip}
          />
        </StyledInfoBox.Item>
        <StyledInfoBox.Item className="swap-info-box__trailing">
          <RadioButtonsContainer>
            <CustomOption
              placeholder={initialUserPreference.transactionDeadline}
              value={transactionDeadline}
              onChange={(value) => handleTransactionDeadlineChange(value)}
              $maxWidth="64px"
              inputMode="decimal"
            ></CustomOption>
            <Unit>seconds</Unit>
          </RadioButtonsContainer>
        </StyledInfoBox.Item>
      </StyledInfoBox>
      {compareTwoNums(
        userPreference.transactionDeadline ||
          initialUserPreference.transactionDeadline,
        '30',
        'lt',
      ) && <StyledWarningMessage message="Your transaction may fail!" />}
    </StyledModal>
  )
}

export default UserPreferenceModalContainer
