import { PaletteOptions } from '@mui/material/styles'

export const colors = {
  primary: {
    100: '#8282a2',
    200: '#333968',
    300: '#00194c', // primary.light
    400: '#0b1334',
    500: '#2458F6', // primary.main
    600: '#3477F4',
    700: '#2C67F5',
    800: '#122C7B',
    900: '#0A1941',
    A200: '#2DCCFF',
    A600: 'linear-gradient(90deg, rgba(36, 88, 246, 0.5) 0%, #3477F4 48.29%, rgba(36, 88, 246, 0.5) 97.25%)',
    A700: 'linear-gradient(90.13deg, rgba(36, 88, 246, 0.5) 0.15%, rgba(18, 44, 123, 0.5) 99.93%)',
  },
  secondary: {
    500: '#00F642',
    main: '#32FF44',
    A500: 'linear-gradient(90deg, #00F642 0%, #2458F6 100%)',
  },
}

const palette: PaletteOptions = {
  mode: 'dark',
  common: { white: '#fff', black: '#021223' },
  success: {
    main: '#00C443',
  },
  error: {
    main: '#FF3838',
    dark: '#B71C1C',
  },
  platopia: {
    main: '#00ffe6',
    dark: '#00b2a0',
  },
  primary: colors.primary,
  secondary: colors.secondary,
  accents: {
    yellow: '#FCE83A',
  },

  background: { default: '#021223', paper: '#333968' },
}

export default palette
