import React, { ReactElement } from 'react'

interface Props {
  width?: number
  margin?: string
}

function CheckCircleIcon({ width = 38, margin = '0' }: Props): ReactElement {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: margin }}
    >
      <path
        d="M16.146 32.292C25.0632 32.292 32.292 25.0632 32.292 16.146C32.292 7.22881 25.0632 0 16.146 0C7.22881 0 0 7.22881 0 16.146C0 25.0632 7.22881 32.292 16.146 32.292Z"
        fill="white"
      />
      <path
        d="M11.5572 24.854C10.9732 24.854 10.4112 24.623 9.9982 24.208L5.1032 19.316C4.2462 18.456 4.2462 17.057 5.1032 16.199C5.9642 15.336 7.3612 15.338 8.2222 16.199L11.5562 19.531L22.4302 8.66099C23.2892 7.80099 24.6862 7.80099 25.5472 8.66099C26.4042 9.52099 26.4042 10.917 25.5472 11.779L13.1152 24.208C12.7012 24.622 12.1432 24.854 11.5572 24.854Z"
        fill="#5EB954"
      />
    </svg>
  )
}

export default CheckCircleIcon
