import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import React, { ReactElement } from 'react'
import nftUIData from '../../../config/nftUIData'
import { useModal } from '../../../contexts/ModalContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import useClaimVeptp from '../../../hooks/vePtp/useClaimVePtp'
import useWalletConnection from '../../../hooks/wallet/useWalletConnection'
import { ModalId } from '../../../interfaces/Modal'
import NftCard from '../../cards/NftCard/NftCard'
import TooltipNum from '../../InfoBox/TooltipNum'
import TextWithToolTip from '../TextWithToolTip/TextWithToolTip'
import {
  ClaimContainer,
  ClaimInfoContainer,
  Container,
  CTAButton,
  NFTContainer,
  PopulationContainer,
  PopulationIcon,
  PopulationText,
  PopulationTextContainer,
  SmallText,
  TitleText,
  VePtpContainer,
  VePtpIcon,
} from './NFTSummary.elements'

interface Props {
  population: number
}

function PtpSummary({ population }: Props): ReactElement {
  const { vePtp, nft, ptp } = useVePtp()
  const { handleClaimVePtp } = useClaimVeptp()
  const { account } = useNetwork()
  const { openConnectToWalletModal } = useWalletConnection()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const hasAccountWithNoStakedPtp = !ptp.hasStaked && !!account
  const handleClickClaim = () => {
    if (account) {
      void handleClaimVePtp()
    } else {
      openConnectToWalletModal()
    }
  }
  const equippedNftData =
    nft.equippedId && nft.all[nft.equippedId]
      ? {
          imgSrc: nft.all[nft.equippedId].imgSrc,
          type: nft.all[nft.equippedId].type,
          value: nft.all[nft.equippedId].value,
        }
      : null

  return (
    <Container>
      <NFTContainer>
        <NftCard
          id={nft.equippedId}
          variant="small"
          isEquipped={!!equippedNftData}
          nftImgSrc={(equippedNftData && equippedNftData.imgSrc) || ''}
          abililty={
            (equippedNftData &&
              nftUIData[equippedNftData.type].getAbilityText({
                value: equippedNftData.value,
                showActiveColor: true,
                showActiveText: true,
              })) ||
            ''
          }
          name={(equippedNftData && equippedNftData.type) || ''}
          unequipmentText="No NFT"
        />
      </NFTContainer>
      <CTAButton
        onClick={() => modalDispatch(openModal(ModalId.CHANGE_NFT))}
        disabled={hasAccountWithNoStakedPtp}
      >
        {nft.equippedId ? 'Change' : 'Equip'}
      </CTAButton>
      <PopulationContainer>
        <PopulationTextContainer>
          <PopulationIcon />
          <PopulationText>{population}</PopulationText>
        </PopulationTextContainer>
        <TextWithToolTip
          title="Population"
          tooltipText="Platopia population is proportional to the log of your staked PTP amount."
        />
      </PopulationContainer>
      <ClaimContainer>
        <ClaimInfoContainer>
          <VePtpContainer>
            <VePtpIcon />
            <TitleText>
              <TooltipNum amount={vePtp.pendingAmount}>
                {getCommifiedFormat(vePtp.pendingAmount)}
              </TooltipNum>
            </TitleText>
          </VePtpContainer>
          <SmallText>Claimable vePTP</SmallText>
        </ClaimInfoContainer>
        <CTAButton
          onClick={handleClickClaim}
          disabled={hasAccountWithNoStakedPtp}
        >
          Claim
        </CTAButton>
      </ClaimContainer>
    </Container>
  )
}

export default PtpSummary
