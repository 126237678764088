import { safeWdiv, strToWad, wmul } from '@hailstonelabs/big-number-utils'
import { BigNumber, constants, utils } from 'ethers'
import { PoolSymbol } from '../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { useBalance } from '../../contexts/BalanceContext'
import { usePools } from '../../contexts/PoolsContext'
import { useStakeLpData } from '../../contexts/StakeLpDataContext'

export type ReturnType = (
  poolSymbol: PoolSymbol,
  assetTokenSymbol: TokenSymbol,
) => {
  coverageRatio: string
  stakableLpWad: BigNumber
  myDepositsUSDWad: BigNumber
  poolDepositAmount: string
  poolDepositUsdAmount: string
  isVolumeFetched: boolean
  volumeOfTokensIn24hrPriceWad: BigNumber
  hasStakedLP: boolean
  volumeOfTokensIn24hrWad: BigNumber
  myDeposits: string
}
function usePoolCardInfo(): ReturnType {
  const { assets, liabilities, volumeOfTokensIn24hr } = usePools()
  const { lp } = useStakeLpData()
  const { tokenPrices } = useBalance()

  const getPoolCardInfo = (
    poolSymbol: PoolSymbol,
    assetTokenSymbol: TokenSymbol,
  ) => {
    const assetWad = strToWad(assets[poolSymbol][assetTokenSymbol]) // in WAD
    const liabilityWad = strToWad(liabilities[poolSymbol][assetTokenSymbol]) // in WAD
    const tokenPriceWad = strToWad(tokenPrices[assetTokenSymbol]) // in WAD
    const poolDepositUsdWad = wmul(liabilityWad, tokenPriceWad)
    const poolDepositUsdAmount = utils.formatEther(poolDepositUsdWad)
    const coverageRatioWad = safeWdiv(
      assetWad,
      liabilityWad.gt(constants.Zero) ? liabilityWad : strToWad('1'),
    )
    const myDeposits =
      lp.inTermsOfToken.total[poolSymbol][assetTokenSymbol] || '0.0'
    const stakableLpWad = strToWad(
      lp.inTermsOfToken.stakable[poolSymbol][assetTokenSymbol],
    )
    const coverageRatio = utils.formatEther(coverageRatioWad.mul('100'))
    const myDepositsUSDWad = wmul(strToWad(myDeposits), tokenPriceWad)
    const poolDepositAmount = liabilities[poolSymbol][assetTokenSymbol]

    // 24hr Volume
    // make sure volumeOfTokensIn24hr has poolSymbol
    const isVolumeFetched =
      !!volumeOfTokensIn24hr && !!volumeOfTokensIn24hr[poolSymbol]
    const volumeOfTokensIn24hrWad = isVolumeFetched
      ? strToWad(volumeOfTokensIn24hr[poolSymbol][assetTokenSymbol])
      : strToWad('0')
    const volumeOfTokensIn24hrPriceWad = wmul(
      volumeOfTokensIn24hrWad,
      tokenPriceWad,
    )
    const hasStakedLP = strToWad(
      lp.inTermsOfToken.staked[poolSymbol][assetTokenSymbol],
    ).gt(constants.Zero)

    return {
      coverageRatio,
      stakableLpWad,
      myDepositsUSDWad,
      poolDepositAmount,
      poolDepositUsdAmount,
      isVolumeFetched,
      volumeOfTokensIn24hrPriceWad,
      hasStakedLP,
      volumeOfTokensIn24hrWad,
      myDeposits,
    }
  }

  return getPoolCardInfo
}

export default usePoolCardInfo
