import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppButton from '../../buttons/AppButton/AppButton'

export const Container = styled(Box)`
  position: relative;
`
export const CustomTooltip = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    position: absolute;
    background: ${theme.palette.primary['700']};
    padding: 14px;
    border-radius: 4px;
    pointer-events: none;
  `}
`

export const CustomLegend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px 10px;
  margin: 10px 20px;
`

interface ColorIndicatorProps {
  color: string
}
export const ColorIndicator = styled.span<ColorIndicatorProps>`
  ${({ color }) => css`
    background: ${color};
    height: 12px;
    width: 12px;
    border-radius: 999px;
    margin-right: 3px;
  `}
`

export const LegendLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

/**@todo refactor with global button style */
export const StyledButton = styled(AppButton)`
  ${({ theme }) => css`
    background: ${alpha(theme.palette.primary[500], 0.2)};
    border-radius: 999px;
    border: 1px solid ${alpha(theme.palette.primary[500], 0.2)};
    width: 100%;
    color: ${theme.palette.primary['A200']};
    max-width: 210px;
    width: 50px;
    padding: 0;
    margin: 0;

    &:enabled:hover {
      background: ${alpha(theme.palette.primary[500], 0.2)};
    }

    &&:hover {
      background: ${alpha(theme.palette.primary[500], 0.1)};
    }
  `}
`
