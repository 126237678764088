import { CountdownData } from '../hooks/useCountDown'
import { TotalLockDays } from '../interfaces/vePTP'

/**
 * @param {number} unlockTime unlock time of current position
 * @param {number} lockDayInputAmountInMilliseconds lock day input in milliseconds
 * @param {boolean} inlcudeExtendTime the sum of unlock time and lock day input
 * @returns {number} return new unlock time in milli seconds
 */
export function getNewUnlockTimeInMilliseconds(
  unlockTime: number,
  lockDayInputAmountInMilliseconds?: number,
  inlcudeExtendTime?: boolean,
) {
  if (lockDayInputAmountInMilliseconds) {
    if (inlcudeExtendTime) {
      // it includes initial unlock time. This is for extending PTP lock
      return unlockTime * 1000 + lockDayInputAmountInMilliseconds
    }
    return Date.now() + lockDayInputAmountInMilliseconds
  }

  return unlockTime * 1000
}

/**
 * getlockTimeLeft
 * @param {number} unlockTimeInMilliseconds
 * @param {number} initialLockTimeInMilliseconds
 * @returns {number} timeLeftPercentage
 */
export const getLockTimeLeftPercentageAndDayLeft = (
  unlockTimeInMilliseconds: number,
  initialLockTimeInMilliseconds: number,
) => {
  const currentTime = new Date().getTime()
  const totalLockTime = unlockTimeInMilliseconds - initialLockTimeInMilliseconds
  const timeLeftInMilliseconds = unlockTimeInMilliseconds - currentTime
  const timeLeftPercentage = (timeLeftInMilliseconds / totalLockTime) * 100
  const dayLeft = timeLeftInMilliseconds / (24 * 60 * 60 * 1000)

  return {
    dayLeft,
    timeLeftPercentage: timeLeftPercentage >= 100 ? 100 : timeLeftPercentage,
  }
}

/**
 * getCountdownData
 * @param {CountdownData} prevCountdownData
 * @param {number} targetTimestampInMilliseconds
 * @param {NodeJS.Timeout} interval
 * @returns {CountdownData}  newCountdown
 */
export const getCountdownData = (
  prevCountdownData: CountdownData,
  targetTimestampInMilliseconds: number,
  interval?: NodeJS.Timeout,
): CountdownData => {
  const currentTime = new Date().getTime()
  // convert UTC time to local time
  const targetTimestampInLocalTime = new Date(
    targetTimestampInMilliseconds,
  ).getTime()

  const diffInMilliseconds = targetTimestampInLocalTime - currentTime
  const seconds =
    diffInMilliseconds > 0 ? Math.floor(diffInMilliseconds / 1000) : 0
  if (seconds < 0 && interval) {
    clearInterval(interval)
    return prevCountdownData
  }
  const minutes = seconds > 0 ? Math.floor(seconds / 60) : 0
  const hours = minutes > 0 ? Math.floor(minutes / 60) : 0
  const days = hours > 0 ? Math.floor(hours / 24) : 0
  const newCountdown = [...prevCountdownData]
  newCountdown[0].value = days % newCountdown[0].maxValue
  newCountdown[1].value = hours % newCountdown[1].maxValue
  newCountdown[2].value = minutes % newCountdown[2].maxValue
  newCountdown[3].value = seconds % newCountdown[3].maxValue
  return newCountdown
}
/**
 * @param {number} unlockTimestamp
 * @param {number} initialTimestamp
 * @param {string} extendLockDayInput
 * @returns original lock days before extending lock and current lock days after extending lock.
 */
export const getTotalLockDays = (
  unlockTimestamp: number,
  initialTimestamp: number,
  extendLockDayInput?: string,
): TotalLockDays => {
  let originalLockDays = 0
  let currentLockDays = 0
  if (unlockTimestamp > initialTimestamp) {
    const originalLockTime = unlockTimestamp - initialTimestamp
    originalLockDays = originalLockTime / (24 * 60 * 60)
    // if there is no lockDayInputAmount, currentLockDays becomes originalLockDays
    currentLockDays = extendLockDayInput
      ? originalLockDays + Number(extendLockDayInput)
      : originalLockDays
  }
  return {
    original: originalLockDays.toString(),
    current: currentLockDays.toString(),
  }
}
