import {
  getDpFormat,
  isParsableString,
  safeWdiv,
  strToWad,
} from '@hailstonelabs/big-number-utils'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import { alpha, Box, Typography, useTheme } from '@mui/material'
import { utils } from 'ethers'
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import ItemSelectionModal from '../../../components/Modal/ItemSelectionModal/ItemSelectionModal'
import SelectItemButton from '../../../components/SelectItemButton/SelectItemButton'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import AppTokenInput from '../../../components/TokenInput/AppTokenInput'
import WarningMessage from '../../../components/WarningMessage/WarningMessage'
import { TOKENS } from '../../../config/contracts'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { STAKE_PERIOD_LIST_INTERVAL } from '../../../constants'
import { useVePtpBoosterCalculator } from '../../../contexts/VePtpBoosterCalculatorContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { StyledDivider } from '../../../globalStyles'
import { PtpFeatureTabId } from '../../../interfaces/vePTP'

interface IsFormattedInitialValueShown {
  locked: boolean
  staked: boolean
}

function MyStakedAndLockedPtpContainer(): ReactElement {
  return (
    <>
      <StyledDivider $colored>
        <TokenIcon tokenSymbol={TokenSymbol.PTP} margin="0 4px 0 0" />
        My PTP
      </StyledDivider>
      <MyStakedPtpInput />
      <VePtpBalanceInfoBox />
    </>
  )
}

export default MyStakedAndLockedPtpContainer

function MyStakedPtpInput(): ReactElement {
  const theme = useTheme()
  const [isSelectPeroidModalOpen, setIsSelectPeroidModalOpen] = useState(false)
  const {
    myStakedPtpInput,
    myLockedPtpInput,
    actions: {
      updateAmountFromMyStakedPtpInput,
      resetAmountFromMyStakedPtpInput,
      updateStakingPeriodFromMyStakedPtpInput,
      resetTotalVePtpSupplyInput,
      updateAmountFromMyLockedPtpInput,
      updateLockDayFromMyLockedPtpInput,
      resetAmountFromMyLockedPtpInput,
    },
  } = useVePtpBoosterCalculator()
  const { ptp, vePtp } = useVePtp()

  const maxLockPeriod = Math.ceil(
    Number(
      utils.formatEther(
        safeWdiv(
          safeWdiv(
            strToWad(vePtp.maxCapPerPtp.staking),
            strToWad(vePtp.generationRate.perDay),
          ),
          strToWad('30'),
        ),
      ),
    ),
  )

  const stakingPeriodList = []
  for (let i = 0; i <= maxLockPeriod; i += STAKE_PERIOD_LIST_INTERVAL) {
    if (i > 0) {
      stakingPeriodList.push({
        id: i.toString(),
        leadingLabel: `${i} months ${
          maxLockPeriod === i ? '(max vePTP cap reached)' : ''
        }`,
      })
    }
  }

  const isStakingPeriodSelected = useMemo(
    () => myStakedPtpInput.stakingPeriod !== '0',
    [myStakedPtpInput.stakingPeriod],
  )

  const isLockDayEntered = myLockedPtpInput.lockDay !== ''

  const isResettable = useCallback(
    (ptpFeatureTabId: PtpFeatureTabId) => {
      if (ptpFeatureTabId === PtpFeatureTabId.STAKE)
        return ptp.amount.staked !== myStakedPtpInput.amount
      if (ptpFeatureTabId === PtpFeatureTabId.LOCK)
        return ptp.amount.locked !== myLockedPtpInput.amount
    },
    [
      myLockedPtpInput.amount,
      myStakedPtpInput.amount,
      ptp.amount.locked,
      ptp.amount.staked,
    ],
  )

  const [isFormattedInitialValueShown, setIsFormattedInitialValueShown] =
    useState<IsFormattedInitialValueShown>({
      locked: true,
      staked: true,
    })

  const handleStakedInputChange = (value: string) => {
    if (isFormattedInitialValueShown) {
      setIsFormattedInitialValueShown({
        ...isFormattedInitialValueShown,
        staked: false,
      })
    }
    if (value === '') {
      updateAmountFromMyStakedPtpInput('')
      return
    }
    if (!isParsableString(value, TOKENS[TokenSymbol.PTP].decimals, true)) return

    updateAmountFromMyStakedPtpInput(value)
  }

  const handleLockedInputChange = (value: string) => {
    if (isFormattedInitialValueShown) {
      setIsFormattedInitialValueShown({
        ...isFormattedInitialValueShown,
        locked: false,
      })
    }
    if (value === '') {
      updateAmountFromMyLockedPtpInput('')
      return
    }
    if (!isParsableString(value, TOKENS[TokenSymbol.PTP].decimals, true)) return

    updateAmountFromMyLockedPtpInput(value)
  }

  const handleSelectPeriod = (stakingPeriod: string) => {
    updateStakingPeriodFromMyStakedPtpInput(stakingPeriod)
  }

  const handleLockDayChange = (lockDay: string) => {
    if (lockDay === '') {
      updateLockDayFromMyLockedPtpInput('')
      return
    }
    // only accept integer for the input
    if (lockDay !== '' && !isParsableString(lockDay, 0, true)) return

    // maximum input value limit to maxLockDays
    if (Number(lockDay) > ptp.lockTime.maxDays) return
    updateLockDayFromMyLockedPtpInput(lockDay)
  }

  const resetInput = (ptpFeatureTabId: PtpFeatureTabId) => {
    setIsFormattedInitialValueShown({ locked: true, staked: true })
    if (ptpFeatureTabId === PtpFeatureTabId.LOCK) {
      resetAmountFromMyLockedPtpInput()
      return
    }
    if (ptpFeatureTabId === PtpFeatureTabId.STAKE) {
      resetAmountFromMyStakedPtpInput()
      return
    }
  }

  const handleClickMaxLockDayButton = () => {
    updateLockDayFromMyLockedPtpInput(ptp.lockTime.maxDays.toString())
    return
  }

  // only reset once
  useEffect(() => {
    resetAmountFromMyStakedPtpInput()
    resetTotalVePtpSupplyInput()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AppTokenInput
        value={
          isFormattedInitialValueShown.staked
            ? getDpFormat(myStakedPtpInput.amount)
            : myStakedPtpInput.amount
        }
        onChange={handleStakedInputChange}
        style={{ paddingTop: '12px' }}
      >
        <AppTokenInput.InnerContainer
          bgcolor={
            isStakingPeriodSelected
              ? alpha(theme.palette.primary[500], 0.2)
              : theme.palette.common.black
          }
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" mb="16px">
            <AppTokenInput.InputField
              placeholder="0.0"
              disableUnderline
              textalign="left"
              noLeftPadding
            />
            <AppTokenInput.ActionButton
              disabled={!isResettable(PtpFeatureTabId.STAKE)}
              onClick={() => resetInput(PtpFeatureTabId.STAKE)}
            >
              <RefreshIcon />
            </AppTokenInput.ActionButton>
          </Box>
          <SelectItemButton
            onSelectItem={() => setIsSelectPeroidModalOpen(true)}
          >
            <SelectItemButton.ItemIconWrapper>
              {isStakingPeriodSelected ? (
                <LockOutlinedIcon />
              ) : (
                <AccessTimeIcon />
              )}
            </SelectItemButton.ItemIconWrapper>
            <SelectItemButton.ItemLabel>
              {isStakingPeriodSelected
                ? `Stake for ${myStakedPtpInput.stakingPeriod} months`
                : 'Select a staking period'}
            </SelectItemButton.ItemLabel>
          </SelectItemButton>
        </AppTokenInput.InnerContainer>
      </AppTokenInput>
      {isSelectPeroidModalOpen && (
        <ItemSelectionModal
          isOpen={isSelectPeroidModalOpen}
          topBarLabel="Staking period"
          onClose={() => setIsSelectPeroidModalOpen(false)}
        >
          {stakingPeriodList.length > 0 &&
            stakingPeriodList.map((item) => {
              return (
                <ItemSelectionModal.Item
                  key={item.id}
                  id={item.id}
                  onClick={({ id }) => {
                    handleSelectPeriod(id)
                    setIsSelectPeroidModalOpen(false)
                  }}
                  isSelected={myStakedPtpInput.stakingPeriod === item.id}
                >
                  <ItemSelectionModal.ItemLeadingLabel>
                    <AppTypography component="span" variant="body1">
                      {item.leadingLabel}
                    </AppTypography>
                  </ItemSelectionModal.ItemLeadingLabel>
                </ItemSelectionModal.Item>
              )
            })}
        </ItemSelectionModal>
      )}
      <AppTokenInput
        value={
          isFormattedInitialValueShown.locked
            ? getDpFormat(myLockedPtpInput.amount)
            : myLockedPtpInput.amount
        }
        onChange={handleLockedInputChange}
        style={{ paddingTop: '12px' }}
      >
        <AppTokenInput.InnerContainer
          bgcolor={
            isLockDayEntered
              ? alpha(theme.palette.primary[500], 0.2)
              : theme.palette.common.black
          }
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" mb="16px">
            <AppTokenInput.InputField
              placeholder="0.0"
              disableUnderline
              textalign="left"
              noLeftPadding
            />
            <AppTokenInput.ActionButton
              disabled={!isResettable(PtpFeatureTabId.LOCK)}
              onClick={() => resetInput(PtpFeatureTabId.LOCK)}
            >
              <RefreshIcon />
            </AppTokenInput.ActionButton>
          </Box>
          <Box display="flex" marginBottom="8px">
            <SelectItemButton.ItemIconWrapper>
              {isLockDayEntered ? <LockOutlinedIcon /> : <AccessTimeIcon />}
            </SelectItemButton.ItemIconWrapper>
            <SelectItemButton.ItemLabel>
              {myLockedPtpInput.lockDay ? (
                <>
                  Lock for {myLockedPtpInput.lockDay}{' '}
                  {Number(myLockedPtpInput.lockDay) > 1 ? 'days' : 'day'}
                </>
              ) : (
                'Enter Lock Day'
              )}
            </SelectItemButton.ItemLabel>
          </Box>
          <AppTokenInput
            value={myLockedPtpInput.lockDay}
            onChange={handleLockDayChange}
            style={{ paddingTop: '12px' }}
            sliderValue={myLockedPtpInput.lockDay}
            onSliderChange={handleLockDayChange}
          >
            <AppTokenInput.InnerContainer
              bgcolor={alpha(theme.palette.primary[500], 0.2)}
              flexDirection="column"
            >
              <Box display="flex" flexDirection="row">
                <AppTokenInput.InputField
                  placeholder="0.0"
                  inputUnitLabel="days"
                  disableUnderline
                  textalign="left"
                  noLeftPadding
                />
                <AppTokenInput.ActionButton
                  onClick={handleClickMaxLockDayButton}
                >
                  Max
                </AppTokenInput.ActionButton>
              </Box>

              <AppTokenInput.Slider
                max={ptp.lockTime.maxDays}
                min={ptp.lockTime.minDays}
                sliderLabelType="day"
              />
            </AppTokenInput.InnerContainer>
            {Number(myLockedPtpInput.lockDay) < ptp.lockTime.minDays && (
              <WarningMessage
                style={{ border: 'none' }}
                iconSize="14px"
                margin="sm"
              >
                <AppTypography variant="body2" component="span">
                  Input day is below min lock days ({ptp.lockTime.minDays}{' '}
                  {Number(myLockedPtpInput.lockDay) > 0 ? 'days' : 'day'})
                </AppTypography>
              </WarningMessage>
            )}
          </AppTokenInput>
        </AppTokenInput.InnerContainer>
      </AppTokenInput>
    </>
  )
}

function VePtpBalanceInfoBox(): ReactElement {
  const { vePtp } = useVePtp()
  const { myStakedPtpInput, myLockedPtpInput, estimatedVePtpBalance } =
    useVePtpBoosterCalculator()

  const isStakingPeriodSelected = useMemo(
    () => myStakedPtpInput.stakingPeriod !== '0',
    [myStakedPtpInput.stakingPeriod],
  )

  const isLockDayEntered = myLockedPtpInput.lockDay !== ''

  return (
    <InfoBox>
      <InfoBox.Item>
        <Typography variant="caption">
          {isStakingPeriodSelected || isLockDayEntered
            ? 'Estimated vePTP balance'
            : 'Current vePTP balance'}
        </Typography>
        <Typography>
          <TooltipNum
            rightSymbol="vePTP"
            amount={
              isStakingPeriodSelected || isLockDayEntered
                ? estimatedVePtpBalance
                : vePtp.balance.total.current
            }
          >
            {isStakingPeriodSelected || isLockDayEntered
              ? getDpFormat(estimatedVePtpBalance)
              : getDpFormat(vePtp.balance.total.current)}{' '}
            vePTP
          </TooltipNum>
        </Typography>
      </InfoBox.Item>
    </InfoBox>
  )
}
