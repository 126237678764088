import { ChainId } from '../../../networks'
import { PoolSymbol } from '../../pool/poolSymbol'
import { TokenSymbol } from '../../token/tokenSymbol'
import { LpToken } from '../LpToken'

export const DEPRECATED_MAIN_LP_TOKENS = {
  [PoolSymbol.DEPRECATED_MAIN]: {
    [TokenSymbol.USP]: new LpToken({
      isCollateral: false,
      addresses: {
        [ChainId.AVALANCHE]: '0xa16bbab03B61810BA8633343D9FfC04B086506B5',
        [ChainId.FUJI]: '0x10f3A3D68D5806e356379e5DC567Ef0ba337DB64',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.USP,
      poolSymbol: PoolSymbol.DEPRECATED_MAIN,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 25,
        [ChainId.FUJI]: 25,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x0e86163b6A5FD46939d67455CC38d046ccCCb958',
          /** @TODO add fuji bribe if any */
          [ChainId.FUJI]: undefined,
        },
      },
      color: '#00AFB6',
    }),
    [TokenSymbol.USDTe]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x0D26D103c91F63052Fbca88aAF01d5304Ae40015',
        [ChainId.FUJI]: '0x3BAaB7d36F21f769FD4Dfd1045294F97E67265C5',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDTe,
      poolSymbol: PoolSymbol.DEPRECATED_MAIN,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 0,
        [ChainId.FUJI]: 0,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x4eac0Da0dc31a0fd5072F7742e840a8c9387b1b5',
          [ChainId.FUJI]: '0x3B69862f4176187cCfBA84C6592A407b8D399591',
        },
      },
      color: '#79FFA7',
    }),
    [TokenSymbol.USDCe]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x909B0ce4FaC1A0dCa78F8Ca7430bBAfeEcA12871',
        [ChainId.FUJI]: '0x8FD1Be8d3f4C050bAF9c22351617cB37dc945D4A',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDCe,
      poolSymbol: PoolSymbol.DEPRECATED_MAIN,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 1,
        [ChainId.FUJI]: 1,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x1eaffB61971992744223638B9f4a2FAB1c3F1072',
          [ChainId.FUJI]: '0x7702535663660BC7f72e5BE4Eb236f4C46Fc6CAa',
        },
      },
      color: '#00A3FF',
    }),
    [TokenSymbol.DAIe]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xc1Daa16E6979C2D1229cB1fd0823491eA44555Be',
        [ChainId.FUJI]: '0x77F2930a6555c8f131f1Aa9f5edb7cD93b83c976',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.DAIe,
      poolSymbol: PoolSymbol.DEPRECATED_MAIN,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 2,
        [ChainId.FUJI]: 2,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0xd9C3cd6e8F56b48132bBF2a519feD7d617f5561A',
          [ChainId.FUJI]: '0x074CF347d767d5779c7eb92bC2A8AA756bD2C35a',
        },
      },
      color: '#F5AC37',
    }),
    [TokenSymbol.USDC]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xAEf735B1E7EcfAf8209ea46610585817Dc0a2E16',
        [ChainId.FUJI]: '0x7DDAD1FBBab8Be8c13F5518eC1E0381ecdb66D32',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDC,
      poolSymbol: PoolSymbol.DEPRECATED_MAIN,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 4,
        [ChainId.FUJI]: 4,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0x7b655819d00B71c0F409A45317b0b7aaC9E612e6',
          [ChainId.FUJI]: '0xa36be7b560a1e24F9f8E75F2B6df512380c5C0cB',
        },
      },
      color: '#2775CA',
    }),
    [TokenSymbol.USDT]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0x776628A5C37335608DD2a9538807b9bba3869E14',
        [ChainId.FUJI]: '0x046535eA0299ec20db5284B93293dBFd601c64Aa',
      },
      decimals: 6,
      tokenSymbol: TokenSymbol.USDT,
      poolSymbol: PoolSymbol.DEPRECATED_MAIN,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 5,
        [ChainId.FUJI]: 5,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0xbb9a4E7C8172BbdDeed00066Ec8A007690cd1705',
          [ChainId.FUJI]: '0x618D4aEc0b7A5B7d92b732eAD0983f49260Fcf3a',
        },
      },
      color: '#53AE94',
    }),
    // [TokenSymbol.MIM]: new LpToken({
    //   addresses: {
    //     [ChainId.AVALANCHE]: '0x6220BaAd9D08Dee465BefAE4f82ee251cF7c8b82',
    //     [ChainId.FUJI]: '0x10392B4d2346f4305549e50f59232b9561410661',
    //   },
    //   decimals: 18,
    //   tokenSymbol: TokenSymbol.MIM,
    //   poolSymbol: PoolSymbol.DEPRECATED_MAIN,
    //   isAvailable: false,
    //   rewards: [
    //     {
    //       tokenSymbol: TokenSymbol.PTP,
    //       method: 'masterPlatypus.pendingTokens',
    //     },
    //   ],
    //   pids: {
    //     [ChainId.AVALANCHE]: 3,
    //     [ChainId.FUJI]: 3,
    //   },
    //   color: '#7FFFD4',
    // }),
    [TokenSymbol.BUSD]: new LpToken({
      addresses: {
        [ChainId.AVALANCHE]: '0xe23F8CCDeB4e8Ce5d9fE76782718cD85D12689C8',
        [ChainId.FUJI]: '0xF3BB5b0B9240B310bCb2D984bAC67d1Ca066abEE',
      },
      decimals: 18,
      tokenSymbol: TokenSymbol.BUSD,
      poolSymbol: PoolSymbol.DEPRECATED_MAIN,
      isAvailable: false,
      rewards: [
        {
          tokenSymbol: TokenSymbol.PTP,
          method: 'masterPlatypus.pendingTokens',
        },
      ],
      pids: {
        [ChainId.AVALANCHE]: 24,
        [ChainId.FUJI]: 24,
      },
      bribe: {
        tokenSymbol: TokenSymbol.PTP,
        bribeAddress: {
          [ChainId.AVALANCHE]: '0xF2c78269DDF9368BfDA204BC9C0d3782d96c6581',
          [ChainId.FUJI]: '0xcF8F50cD005b5BCfdfec62c342E8da136d48A8C6',
        },
      },
      color: '#FFC700',
    }),
  },
}
