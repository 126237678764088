import { alpha } from '@mui/material'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 95%;
    text-align: center;
    border: 1px solid ${theme.palette.primary['A200']};
    background: ${alpha(theme.palette.primary.main, 0.2)};
    border-radius: 20px;
    padding: 24px;
    margin: 0 20px 40px;
    max-width: 1000px;
  `}
`
