import styled, { css } from 'styled-components'

interface CircleProps {
  $color: 'blue' | 'green' | 'darkBlue'
}

/**@todo replace hard code darkBlue color #122c7b with main header 2.0 color */
export const Circle = styled.span<CircleProps>`
  ${({ theme, $color }) =>
    css`
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 999px;
      margin: auto 4px auto 0;

      ${$color === 'green' &&
      css`
        background: ${theme.palette.secondary.main};
      `}

      ${$color === 'blue' &&
      css`
        background: ${theme.palette.primary['500']};
      `}

      ${$color === 'darkBlue' &&
      css`
        background: #122c7b;
      `}
    `}
`
