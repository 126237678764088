import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppTypography from '../../AppTypography/AppTypography'

interface NftIconWrapperProps {
  isEquippedWithNft?: boolean
  greyish?: boolean
  size?: string
}

export const NftIconWrapper = styled(Box)<NftIconWrapperProps>`
  ${({ theme, isEquippedWithNft, greyish, size = '60px' }) => css`
    position: relative;
    min-width: ${size};
    max-width: ${size};
    height: ${size};
    border: 3px solid ${theme.palette.platopia.main};
    border-radius: 10px;
    // make sure the img inside follows the shape of the wrapper
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }

    ${!isEquippedWithNft &&
    css`
      border: 2px dashed ${theme.palette.common.white};
      opacity: 0.5;

      img {
        display: none;
      }
    `}

    ${isEquippedWithNft &&
    css`
      border-color: ${greyish
        ? theme.palette.common.white
        : theme.palette.platopia.main};
    `}
  `}
`

export const NftInfoTitle = styled(AppTypography).attrs(() => ({
  variant: 'body2',
}))`
  opacity: 0.5;
`
export const NftInfoContent = styled(AppTypography).attrs(() => ({
  variant: 'subtitle2',
}))`
  line-height: 0;
  margin-left: 4px;
`
export const NftInfo = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
`

export const NftContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
`
