export interface GraphqlResponseType<T> {
  errors?: [{ message: string }] | null
  data?: T | null
}

/** @todo write test for this function */

/**
 * @param {string} subgraphApi URL of the graphql API
 * @param {string} graphqlQuery valid graphql query string
 * @returns {T}
 */
async function fetchGraphql<T>(
  subgraphApi: string,
  graphqlQuery: string,
): Promise<GraphqlResponseType<T>> {
  try {
    const response = await fetch(subgraphApi, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: graphqlQuery,
      }),
    })
    return response.json() as Promise<GraphqlResponseType<T>>
  } catch (err) {
    console.error(err)
    return {
      errors: [{ message: (err as Error).message }],
    }
  }
}

export default fetchGraphql
