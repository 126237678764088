import { PLATYPUS_MAINNET_BLOCK_SUBGRAPH_API } from '../constants'
import fetchGraphql from './fetchGraphql'

export const fetchBlockNumberByTimestamp = async (timestamp: number) => {
  try {
    // token symbols in the subgraph are ERC20 token symbols.
    const query = `
        {
          blocks( where:{timestamp_lte:${timestamp}} orderBy: timestamp orderDirection: desc first: 1 ){
            number
          }
        }
      `
    // the response will either contain errors or data
    const { data, errors } = await fetchGraphql<{
      blocks: [{ number: string }]
    }>(PLATYPUS_MAINNET_BLOCK_SUBGRAPH_API as string, query)
    if (errors) {
      throw new Error(errors.map((err) => err.message).toString())
    }
    if (data) {
      return data.blocks[0].number
    }
  } catch (err) {
    console.error(err)
  }
  return null
}
