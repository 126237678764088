import React from 'react'
import { AppButtonProps } from '../AppButton/AppButton'
import {
  Container,
  StyledTransparentButton,
} from './TransparentButton.elements'

interface TransparentButtonProps extends AppButtonProps {
  children: React.ReactNode
  icon?: React.ReactNode
  padding?: string
  sizeVariant?: 'xs' | 'md'
  margin?: string
  justify?: 'center' | 'space-between'
  bordered?: boolean
}

function TransparentButton({
  children,
  icon,
  justify,
  margin,
  sizeVariant,
  bordered,
  ...otherProps
}: TransparentButtonProps): React.ReactElement {
  return (
    <StyledTransparentButton
      sizeVariant={sizeVariant}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      bordered={bordered}
      {...otherProps}
    >
      <Container margin={margin} justifyContent={justify}>
        {children} {icon && icon}
      </Container>
    </StyledTransparentButton>
  )
}

export default TransparentButton
