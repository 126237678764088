import { Box, Typography, useTheme } from '@mui/material'
import React, { ReactElement } from 'react'
import Spinner from '../../Spinner/Spinner'
import Modal from '../Modal'
import {
  Instruction,
  Item,
  ItemsContainer,
} from './WaitForConfirmationModal.elements'

interface Props {
  isOpen: boolean
  handleCloseModal: () => void
  children?: React.ReactNode
  title?: string
  subtitle?: string
  paragraph?: string
  width?: string
}

function WaitForConfirmationModal({
  isOpen,
  handleCloseModal,
  title = 'Waiting For Confirmation',
  subtitle,
  paragraph = 'Please confirm this transaction in your wallet',
  width,
  children,
}: Props): ReactElement {
  const theme = useTheme()
  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} width={width}>
      <Instruction
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        color="text.primary"
      >
        <Box marginBottom={1}>
          <Spinner />
        </Box>
        <Box />
        <Typography variant="h5">{title}</Typography>
        <Typography
          variant="caption"
          style={{ color: theme.palette.accents.yellow }}
        >
          {paragraph}
        </Typography>
      </Instruction>
      {subtitle && (
        <Typography
          variant="caption"
          style={{ marginBottom: '4px', opacity: 0.5, alignSelf: 'center' }}
        >
          {subtitle}
        </Typography>
      )}
      {children}
    </Modal>
  )
}

export default WaitForConfirmationModal

/**
 * Compound Components
 */
interface ModalItemsContainerProps {
  children: React.ReactNode
}
function ModalItemsContainer({ children }: ModalItemsContainerProps) {
  return <ItemsContainer>{children}</ItemsContainer>
}

interface ModalItemProps {
  children: React.ReactNode
}
function ModalItem({ children }: ModalItemProps) {
  return <Item>{children}</Item>
}

WaitForConfirmationModal.ItemsContainer = ModalItemsContainer
WaitForConfirmationModal.Item = ModalItem
