import type { Abi } from 'abitype'
import { BaseContract, ContractInterface, ethers, utils } from 'ethers'
import MoralisType from 'moralis'
import { HexString } from '../../interfaces/common'
import { getContract } from '../../utils/contract'
import { ChainId } from '../networks'
export type AddressInterface = { [id in ChainId]?: HexString }
export class Contract<T extends BaseContract, K extends Abi> {
  readonly address: AddressInterface
  readonly abi: ContractInterface
  readonly wagmiAbi: K

  constructor(address: AddressInterface, abi: ContractInterface, wagmiAbi: K) {
    this.address = address
    this.abi = abi
    this.wagmiAbi = wagmiAbi
  }
  /**
   *
   * @param {string | null} chainId chainId from useMoralis
   * @returns
   */
  getAddress(chainId: ChainId | null): HexString | null {
    if (!chainId) return null
    return this.address[chainId] || null
  }
  /**
   * @param {boolean} readOnly contract has read-only access (i.e. constant calls)
   * @param {MoralisType.MoralisWeb3Provider | null} provider Web3Provider from useMoralis
   * @param {string | null} chainId chainId from useMoralis
   * @returns {T | null}
   */
  get(
    chainId: ChainId | null,
    provider:
      | MoralisType.MoralisWeb3Provider
      | ethers.providers.JsonRpcProvider
      | null = null,
    readOnly = true,
  ): T | null {
    const isMulticall = readOnly && !provider
    if (!chainId) return null
    if (!isMulticall && !provider) return null
    const address = this.getAddress(chainId)
    if (address && utils.isAddress(address)) {
      return getContract(
        address,
        this.abi,
        readOnly,
        provider,
        isMulticall,
      ) as T
    }
    return null
  }
}
