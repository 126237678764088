import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'
import AppTypography from '../../AppTypography/AppTypography'

export const Instruction = styled(Box)`
  margin-bottom: 30px;
  span {
    line-height: 0.8;
    font-weight: 300;
  }
`
export const ItemsContainer = styled.div`
  ${({ theme }) => css`
    background: ${alpha(theme.palette.primary.main, 0.2)};
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 10px;
    padding: 12px;
    width: 100%;
  `}
`
export const Item = styled(AppTypography).attrs(() => ({
  component: 'div',
  variant: 'body1',
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4px;
`
