import { configureChains } from '@wagmi/core'
import { publicProvider } from '@wagmi/core/providers/public'
import { createClient } from 'wagmi'
import { NETWORKS } from '../networks'
import { WALLETS } from '../wallet'

const { provider } = configureChains(Object.values(NETWORKS), [
  publicProvider(),
])

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: Object.values(WALLETS).map((wallet) => wallet.connector),
  provider,
})
