import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'

export const Item = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 0 120px;
    text-align: center;

    ${theme.breakpoints.down('md')} {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  `}
`

export const Body = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: ${alpha(theme.palette.primary.main, 0.2)};
    border-radius: 10px;
    max-width: 664px;
    width: 100%;
  `}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
