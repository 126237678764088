import { Call } from 'ethcall'
import { BigNumber, constants, utils } from 'ethers'
import { nft, TOKENS, vePtp } from '../../../config/contracts'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { ChainId } from '../../../config/networks'
import { VePtpUserInfoSCType } from '../../../interfaces/apr'
import {
  CallbacksType,
  LockedPositionSCReturnType,
  MulticallHelperSCReturnType,
} from '../../../utils/multicall'

export interface VePtpDataType {
  withAccount: VePtpWithAccountType
  withoutAccount: VePtpWithoutAccountType
}

export interface VePtpWithAccountType {
  lockedPtp: string
  stakedPtp: string
  equippedNftIdBN: BigNumber | null
  vePtpBalanceWAD: BigNumber
  vePtpBalanceFromLocking: string
  pendingVePtpWAD: BigNumber
  isVePtpApprovedForAllNfts: boolean
  unlockTimestamp: number
  initialLockTimestamp: number
}

export interface VePtpWithoutAccountType {
  ptpBalanceOfVePtpContract: string
  totalLockedPtpAmount: string
  maxVePtpCapPerPtpFromLocking: string
  maxVePtpCapPerPtpFromStaking: string
  generationRatePerSecondWAD: BigNumber
  totalVePtpSupplyWAD: BigNumber
  maxLockDays: number
  minLockDays: number
}

export const fetchVeptpData = (chainId: ChainId, account: string | null) => {
  const states: VePtpDataType = {
    withAccount: {
      lockedPtp: '0.0',
      stakedPtp: '0.0',
      vePtpBalanceFromLocking: '0.0',
      equippedNftIdBN: null,
      vePtpBalanceWAD: constants.Zero,
      pendingVePtpWAD: constants.Zero,
      isVePtpApprovedForAllNfts: false,
      unlockTimestamp: 0,
      initialLockTimestamp: 0,
    },
    withoutAccount: {
      ptpBalanceOfVePtpContract: '0.0',
      totalLockedPtpAmount: '0.0',
      maxVePtpCapPerPtpFromLocking: '0.0',
      maxVePtpCapPerPtpFromStaking: '0.0',
      generationRatePerSecondWAD: constants.Zero,
      totalVePtpSupplyWAD: constants.Zero,
      maxLockDays: 0,
      minLockDays: 0,
    },
  }

  const contractCalls: Call[] = []

  const callbacks: CallbacksType<MulticallHelperSCReturnType> = []

  const handleVeptpData = () => {
    const vePtpContract = vePtp.get(chainId)
    const ptpContract = TOKENS[TokenSymbol.PTP].get(chainId)
    const nftContract = nft.get(chainId)
    const vePtpAddress = vePtp.getAddress(chainId)
    if (ptpContract && vePtpAddress) {
      contractCalls.push(ptpContract.balanceOf(vePtpAddress) as unknown as Call)
      callbacks.push((value) => {
        states.withoutAccount.ptpBalanceOfVePtpContract = utils.formatUnits(
          value as BigNumber,
          TOKENS[TokenSymbol.PTP].decimals,
        )
      })
    }

    if (vePtpContract) {
      if (account) {
        contractCalls.push(
          vePtpContract.getStakedPtp(account) as unknown as Call,
          vePtpContract.lockedPositions(account) as unknown as Call,
          vePtpContract.users(account) as unknown as Call,
          // this vePtp balance is current
          vePtpContract.balanceOf(account) as unknown as Call,
          vePtpContract.claimable(account) as unknown as Call,
        )
        callbacks.push(
          (value) => {
            states.withAccount.stakedPtp = utils.formatEther(value as BigNumber)
          },
          (value) => {
            const typedValue = value as LockedPositionSCReturnType
            states.withAccount.lockedPtp = utils.formatEther(
              typedValue.ptpLocked,
            )
            states.withAccount.vePtpBalanceFromLocking = utils.formatEther(
              typedValue.vePtpAmount,
            )
            states.withAccount.unlockTimestamp =
              typedValue.unlockTime.toNumber()
            states.withAccount.initialLockTimestamp =
              typedValue.initialLockTime.toNumber()
          },
          (value) => {
            // stakedNftId = 0 in userInfo means that no NFT is staked, need to be decreased by 1 to compensate the offset
            // equippedNftIdBN = stakedNftId - 1. or null if stakedNftId = 0
            // minus the offset of 1
            states.withAccount.equippedNftIdBN = (
              value as VePtpUserInfoSCType
            ).stakedNftId.sub(constants.One)
            // if it is orginally 0 (before last line), it means no NFT is equipped.
            if (states.withAccount.equippedNftIdBN.lt(constants.Zero)) {
              states.withAccount.equippedNftIdBN = null
            }
          },
          (value) => {
            states.withAccount.vePtpBalanceWAD = value as BigNumber
          },
          (value) => {
            states.withAccount.pendingVePtpWAD = value as BigNumber
          },
        )
      }
      contractCalls.push(
        vePtpContract.totalLockedPtp() as unknown as Call,
        vePtpContract.maxLockCap() as unknown as Call,
        vePtpContract.maxStakeCap() as unknown as Call,
        vePtpContract.minLockDays() as unknown as Call,
        vePtpContract.maxLockDays() as unknown as Call,
        vePtpContract.generationRate() as unknown as Call,
        vePtpContract.totalSupply() as unknown as Call,
      )
      callbacks.push(
        (value) => {
          states.withoutAccount.totalLockedPtpAmount = utils.formatEther(
            value as BigNumber,
          )
        },
        (value) => {
          states.withoutAccount.maxVePtpCapPerPtpFromLocking = (
            value as BigNumber
          ).toString()
        },
        (value) => {
          states.withoutAccount.maxVePtpCapPerPtpFromStaking = (
            value as BigNumber
          ).toString()
        },
        (value) => {
          states.withoutAccount.minLockDays = (value as BigNumber).toNumber()
        },
        (value) => {
          states.withoutAccount.maxLockDays = (value as BigNumber).toNumber()
        },
        (value) => {
          states.withoutAccount.generationRatePerSecondWAD = value as BigNumber
        },
        (value) => {
          states.withoutAccount.totalVePtpSupplyWAD = value as BigNumber
        },
      )
    }

    if (account) {
      // check NFT approval: isApprovedForAll
      if (nftContract && chainId && vePtp.getAddress(chainId)) {
        contractCalls.push(
          nftContract.isApprovedForAll(
            account,
            vePtp.getAddress(chainId) || '',
          ) as unknown as Call,
        )
        callbacks.push((value) => {
          states.withAccount.isVePtpApprovedForAllNfts = value as boolean
        })
      }
    }
  }

  handleVeptpData()

  return {
    contractCalls,
    callbacks,
    states,
  }
}
