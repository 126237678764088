import { Button } from '@mui/material'
import styled, { css, keyframes } from 'styled-components'
import { VariantType } from './AppButton'

const primaryButtonAnimation = keyframes`
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
`
interface StyledButtonType {
  margin?: string
  $customVariant?: VariantType
}
export const StyledButton = styled(Button)<StyledButtonType>`
  ${({ theme, margin, $customVariant = 'neutral' }) => css`
    color: ${theme.palette.common.white};
    background: unset;
    margin: ${margin ? margin : '6px 5px'};
    text-transform: unset;
    font-size: 1rem;
    font-weight: 600;
    border-radius: ${theme.borderRadius.md};
    &.Mui-disabled {
      border: 1px solid ${theme.palette.common.white};
      border-radius: ${theme.borderRadius.md};
      background: unset;
      opacity: 0.3;
      color: inherit;
      pointer-events: unset;
      cursor: not-allowed;
    }
    &:enabled:active {
      user-select: none;
    }
    ${!margin &&
    css`
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    `}
    ${$customVariant === 'primary' &&
    css`
      background: ${theme.palette.secondary['A500']};
      &:hover {
        background: linear-gradient(
          90deg,
          #00f152 0%,
          #0076e9 33%,
          #00f152 64%,
          #00f152 66%,
          #0076e9 100%
        );
        background-size: 300% 100%;
        animation: ${primaryButtonAnimation} 5s ease infinite;
        &.Mui-disabled {
          background: unset;
        }
      }
      &:enabled:active {
        background: #00bfb2;
      }
    `}
    ${$customVariant === 'secondary' &&
    css`
      background: ${theme.palette.primary[500]};
      &:enabled:hover {
        background: ${theme.palette.primary[600]};
      }
    `}

    ${$customVariant === 'neutral' &&
    css`
      background: unset;
      border: 1px solid ${theme.palette.common.white};
    `}

    ${$customVariant === 'negative' &&
    css`
      background: ${theme.palette.error.main};
      &:enabled:hover {
        background: ${theme.palette.error.dark};
      }
    `}
    ${$customVariant === 'unset' &&
    css`
      background: unset;
      border: unset;
      &:hover {
        background: unset;
      }
    `}
  `};
`
