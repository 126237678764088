import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { utils } from 'ethers'
import { EMERGENCY_WITHDRAWS, TOKENS } from '../../config/contracts'
import { EmergencyWithdrawId } from '../../config/contracts/emergencyWithdraw'
import { PoolSymbol } from '../../config/contracts/pool/poolSymbol'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { EMERGENCY_WITHDRAW_01_ABI } from '../../config/contracts/wagmiAbis/EmergencyWithdraw01'
import { EMERGENCY_WITHDRAW_02_ABI } from '../../config/contracts/wagmiAbis/EmergencyWithdraw02'
import { EMERGENCY_WITHDRAW_03_ABI } from '../../config/contracts/wagmiAbis/EmergencyWithdraw03'
import { useMigrationDetection } from '../../contexts/MigrationDetectionContext'
import { useModal } from '../../contexts/ModalContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'
import { isUserDeniedTransaction } from '../../utils/contract'
import useRevertReason from '../useRevertReason'

interface Props {
  handleEmergencyWithdraw: (toTokenSymbol: TokenSymbol) => Promise<void>
}

const useEmergencyWithdraw = (
  poolSymbol: PoolSymbol,
  assetTokenSymbol: TokenSymbol,
): Props => {
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const { showMessage } = useSnackbar()
  const delistedData =
    useMigrationDetection().delistedData[poolSymbol]?.[assetTokenSymbol]
  const { chainId } = useWeb3()
  const getRevertReason = useRevertReason()
  const handleEmergencyWithdraw = async (toTokenSymbol: TokenSymbol) => {
    let writeContractResult: WriteContractResult | undefined
    const emergencyWithdraw =
      EMERGENCY_WITHDRAWS[poolSymbol]?.[assetTokenSymbol]
    if (!emergencyWithdraw) return
    try {
      const emergencyWithdrawAddress = emergencyWithdraw.address[chainId]
      if (emergencyWithdrawAddress) {
        const lpAmountInTermsOfLpBN = utils.parseUnits(
          delistedData?.lpAmountInTermsOfLp.stakable || '0',
          TOKENS[assetTokenSymbol].decimals,
        )
        modalDispatch(
          openModal(ModalId.DEPREACATED_POOL_WITHDRAW_WAIT_FOR_CONFIRMATION, {
            tokenSymbols: [toTokenSymbol],
            value: delistedData?.lpAmountInTermsOfLp.stakable || '0',
          }),
        )
        if (
          emergencyWithdraw.emergencyWithdrawId ===
          EmergencyWithdrawId.EMERGENCY_WITHDRAW_01
        ) {
          const config = await prepareWriteContract({
            address: emergencyWithdrawAddress,
            abi: EMERGENCY_WITHDRAW_01_ABI,
            functionName: 'withdraw',
            args: [lpAmountInTermsOfLpBN],
            chainId,
          })
          writeContractResult = await writeContract(config)
        } else if (
          emergencyWithdraw.emergencyWithdrawId ===
          EmergencyWithdrawId.EMERGENCY_WITHDRAW_02
        ) {
          const config = await prepareWriteContract({
            address: emergencyWithdrawAddress,
            abi: EMERGENCY_WITHDRAW_02_ABI,
            functionName: 'withdraw',
            args: [
              lpAmountInTermsOfLpBN,
              assetTokenSymbol === TokenSymbol.USDC,
            ],
            chainId,
          })
          writeContractResult = await writeContract(config)
        } else if (
          emergencyWithdraw.emergencyWithdrawId ===
          EmergencyWithdrawId.EMERGENCY_WITHDRAW_03
        ) {
          if (assetTokenSymbol === TokenSymbol.MIMATIC) {
            const config = await prepareWriteContract({
              address: emergencyWithdrawAddress,
              abi: EMERGENCY_WITHDRAW_03_ABI,
              functionName: 'withdrawMai',
              args: [lpAmountInTermsOfLpBN, false],
              chainId,
            })
            writeContractResult = await writeContract(config)
          } else if (
            assetTokenSymbol === TokenSymbol.USDC &&
            toTokenSymbol &&
            delistedData?.usdcAssetBalanceOfUsdc
          ) {
            if (toTokenSymbol === TokenSymbol.MIMATIC) {
              const config = await prepareWriteContract({
                address: emergencyWithdrawAddress,
                abi: EMERGENCY_WITHDRAW_03_ABI,
                functionName: 'withdrawMai',
                args: [
                  utils.parseUnits(
                    delistedData?.lpAmountInTermsOfLp.stakable || '0',
                    TOKENS[TokenSymbol.USDC].decimals,
                  ),
                  true,
                ],
                chainId,
              })
              writeContractResult = await writeContract(config)
            } else if (toTokenSymbol === TokenSymbol.USDC) {
              const usdcAssetBalanceOfUsdcBN = utils.parseUnits(
                delistedData.usdcAssetBalanceOfUsdc,
                TOKENS[TokenSymbol.USDC].decimals,
              )
              const withdrawAmountBN = usdcAssetBalanceOfUsdcBN.lt(
                lpAmountInTermsOfLpBN,
              )
                ? usdcAssetBalanceOfUsdcBN
                : lpAmountInTermsOfLpBN
              const config = await prepareWriteContract({
                address: emergencyWithdrawAddress,
                abi: EMERGENCY_WITHDRAW_03_ABI,
                functionName: 'withdrawUsdc',
                args: [withdrawAmountBN],
                chainId,
              })
              writeContractResult = await writeContract(config)
            }
          }
        }
        if (writeContractResult) {
          const { hash, wait } = writeContractResult
          await wait()
          modalDispatch(
            openModal(ModalId.TRANSACTION_SUBMITTED, {
              transactionHashes: [hash],
            }),
          )
          showMessage(`Successfully withdrew ${assetTokenSymbol}.`)
        } else {
          showMessage('There is no Transaction.', 'warning')
          modalDispatch(openModal(ModalId.UNSET))
        }
      }
    } catch (err) {
      if (!isUserDeniedTransaction(err)) {
        Sentry.setContext('contract_call', {
          name: 'emergency_withdraw',
          token: assetTokenSymbol,
          amount: delistedData?.lpAmountInTermsOfLp.stakable || '0',
        })
        Sentry.captureException(err)
      }
      const reason = await getRevertReason(err)
      showMessage(reason || 'Transaction failed.', 'warning')
      modalDispatch(openModal(ModalId.UNSET))
    }
  }
  return { handleEmergencyWithdraw }
}

export default useEmergencyWithdraw
