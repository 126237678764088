import { ChainId } from '../../networks'
import MasterPlatypusBaseV1ABI from '../abis/MasterPlatypusBaseV1.json'
import MasterPlatypusBaseV2ABI from '../abis/MasterPlatypusBaseV2.json'
import MasterPlatypusV1ABI from '../abis/MasterPlatypusV1.json'
import MasterPlatypusV3ABI from '../abis/MasterPlatypusV3.json'
import MasterPlatypusV4ABI from '../abis/MasterPlatypusV4.json'
import { MASTER_PLATYPUS_BASE_V1_ABI } from '../wagmiAbis/MasterPlatypusBaseV1'
import { MASTER_PLATYPUS_BASE_V2_ABI } from '../wagmiAbis/MasterPlatypusBaseV2'
import { MASTER_PLATYPUS_V1_ABI } from '../wagmiAbis/MasterPlatypusV1'
import { MASTER_PLATYPUS_V3_ABI } from '../wagmiAbis/MasterPlatypusV3'
import { MASTER_PLATYPUS_V4_ABI } from '../wagmiAbis/MasterPlatypusV4'
import { MasterPlatypus, MasterPlatypusId } from './MasterPlatypus'

/**
 * Master Platypus
 * Fetching Data:
 * 1. Stake lp
 * -  lp Token approval on all MP (fetchBalanceData)
 *
 * 2. Display user deposit (staked)
 * -  lp Token own by on all MP (fetchBalanceData)
 *
 * 3. Apr calculation
 * - nonDialutingRepartition
 * - ptpPerSec
 * - dialutingRepartition
 * - totalAdjustedAllocPoint/totalAllocPoint
 * - poolInfo (poolAdjustedAllocPointBNs, poolSumOfFactorsBNs)
 * - userInfo (userFactorBNs, stakedLpInTermsOfLpBNs)
 *
 * 4. Rewards
 * - Ptp pendingTokens
 *
 * Action:
 * 1. stake/unstake lp -> .deposit(pidBN, stakableLpBN)/.withdraw(pidBN, stakedLpBN)
 * 2. claim/claim all rewards -> .multiClaim(pidsBN)
 * 3. MasterPlatypus migration -> .migrate(pidsBN)
 */

const MASTER_PLATYPUS: {
  [id in MasterPlatypusId]: MasterPlatypus
} = {
  [MasterPlatypusId.BASE_MPV1]: new MasterPlatypus(
    MasterPlatypusId.BASE_MPV1,
    {
      [ChainId.AVALANCHE]: '0x7125B4211357d7C3a90F796c956c12c681146EbB',
      [ChainId.FUJI]: '0x4889d30Ab6CBe27651d4769c3D2b47F1bC56BEB0',
    },
    MasterPlatypusBaseV1ABI,
    MASTER_PLATYPUS_BASE_V1_ABI,
    false,
  ),
  [MasterPlatypusId.BASE_MPV2]: new MasterPlatypus(
    MasterPlatypusId.BASE_MPV2,
    {
      [ChainId.AVALANCHE]: '0x2Cd5012b5f7cc09bfE0De6C44df32a92D2431232',
      [ChainId.FUJI]: '0x5Cb5714007E0F8C03158de8EA29607479d08A963',
    },
    MasterPlatypusBaseV2ABI,
    MASTER_PLATYPUS_BASE_V2_ABI,
    false,
  ),
  [MasterPlatypusId.MP1]: new MasterPlatypus(
    MasterPlatypusId.MP1,
    {
      [ChainId.AVALANCHE]: '0xB0523f9F473812FB195Ee49BC7d2ab9873a98044',
      [ChainId.FUJI]: '0x56E96aa4379B749Dd0667BB84a2014d91A39e9Ca',
    },
    MasterPlatypusV1ABI,
    MASTER_PLATYPUS_V1_ABI,
    true,
  ),
  [MasterPlatypusId.MP3]: new MasterPlatypus(
    MasterPlatypusId.MP3,
    {
      [ChainId.AVALANCHE]: '0x68c5f4374228BEEdFa078e77b5ed93C28a2f713E',
      [ChainId.FUJI]: '0xa4F389f51dc38EB91433F34ddA461D6e63B3080b',
    },
    MasterPlatypusV3ABI,
    MASTER_PLATYPUS_V3_ABI,
    true,
  ),
  [MasterPlatypusId.MP4]: new MasterPlatypus(
    MasterPlatypusId.MP4,
    {
      [ChainId.AVALANCHE]: '0xfF6934aAC9C94E1C39358D4fDCF70aeca77D0AB0',
      [ChainId.FUJI]: '0x7DB46A1b7D7f42072E3038d84193ab1F4E23f1B6',
    },
    MasterPlatypusV4ABI,
    MASTER_PLATYPUS_V4_ABI,
    true,
  ),
}

export { MasterPlatypusId, MASTER_PLATYPUS }
