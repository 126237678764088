import { TransactionRequest } from '@ethersproject/abstract-provider'
import { Deferrable } from '@ethersproject/properties'
import { ethers } from 'ethers'
import { ActionId } from '../config/action'
import { WalletId } from '../config/wallet'
import { useNetwork } from '../contexts/NetworkContext'
import { useWeb3 } from '../contexts/Web3Context'
import { MetaMaskError, WalletConnectError } from '../interfaces/Error'
import { getCustomErrorMessage } from '../utils/error'

type Props = (
  err: unknown,
  transaction?: ethers.ContractTransaction,
  type?: ActionId,
) => Promise<string | null>
const useRevertReason = (): Props => {
  const { readOnlyProvider } = useNetwork()
  const { activeWalletId } = useWeb3()
  /**
   * @param {unknown} err ether.js type or metamask type error
   * @param {ethers.ContractTransaction | undefined} transaction
   * @returns {Promise<string | null>}
   */
  const getRevertReason = async (
    err: unknown,
    transaction?: ethers.ContractTransaction,
    type?: ActionId,
  ): Promise<string | null> => {
    let reason: string | null = null
    // assume user only uses metamask wallet as injected wallet
    const isMetaMask = activeWalletId === WalletId.METAMASK
    /**
     * Revert in the beginning (without transaction)
     */
    // check err.data.message (user denied / Smart contract require revert)
    if (!transaction) {
      if (isMetaMask) {
        // check err.data.message (Smart contract require revert)
        reason = (err as MetaMaskError).data?.message || null
        if (!reason) {
          // user denied
          reason = (err as MetaMaskError).message || null
        }
      } else {
        reason = (err as WalletConnectError).error?.message || null
        if (!reason) {
          // user denied
          reason = (err as WalletConnectError).message || null
        }
      }
      return getCustomErrorMessage(reason, type)
    }
    /**
     * Revert in the middle (with transaction)
     */
    // Extract reverted reason from code by getting blockchain Transaction,
    // since some revert messages is not existed in err. etc. excess deadline
    if (!readOnlyProvider) return null
    let txResponse = null
    try {
      txResponse = await readOnlyProvider.getTransaction(transaction.hash)
    } catch (err) {
      console.error(err)
    }
    if (!txResponse) return null
    try {
      const code = await readOnlyProvider.call(
        txResponse as Deferrable<TransactionRequest>,
        txResponse.blockNumber,
      )
      reason = ethers.utils.toUtf8String('0x' + code.substring(138))
    } catch (callError) {
      reason = (callError as MetaMaskError).data?.message || null
    }
    return getCustomErrorMessage(reason, type)
  }
  return getRevertReason
}

export default useRevertReason
