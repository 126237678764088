import React, { ReactElement, useEffect } from 'react'
import ReactGA from 'react-ga'
import VePtpContainer from '../../containers/VePtpContainer/VePtpContainer'

function VePtpPage(): ReactElement {
  useEffect(() => {
    ReactGA.pageview('/stake')
  }, [])
  return <VePtpContainer />
}

export default VePtpPage
