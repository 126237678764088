import { alpha } from '@mui/material'
import styled, { css } from 'styled-components'
import AppTypography from '../../components/AppTypography/AppTypography'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    ${theme.breakpoints.down('md')} {
      padding: 20px 4px 0;
    }
  `}
`

export const Headline = styled(AppTypography).attrs(() => ({
  variant: 'h3',
}))`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.palette.common.white};
    text-shadow: 0px 0px 20px ${alpha(theme.palette.common.white, 0.5)},
      0px 30px 80px #0c58ff, 0px 100px 180px #00ff57;
  `}
`
export const Subtitle = styled(AppTypography).attrs(() => ({
  variant: 'h6',
}))`
  text-align: center;
  margin: 34px 10px 0px;
`
