/** reimplemetation of the Pool contract */
import {
  rayToWad,
  rpow,
  safeWdiv,
  strToWad,
  sum,
  WAD,
  wadToRay,
  wmul,
} from '@hailstonelabs/big-number-utils'
import { BigNumber, constants, utils } from 'ethers'
import { PlatypusRouter02, Pool, PoolAvax } from '../../types/ethers-contracts'
import { TOKENS } from '../config/contracts/token'
import { Token } from '../config/contracts/token/Token'
import { TokenSymbol } from '../config/contracts/token/tokenSymbol'
import { ChainId } from '../config/networks'
import { TpYieldSymbols } from '../config/TpYield'
import {
  INFINITESIMAL_SWAP_FROM_AMOUNT_FOR_MARKET,
  MIN_WITHDRAWABLE_PERCENTAGE,
} from '../constants'
import { TokenSymbolStringType } from '../interfaces/common'
import {
  TpYieldLiquidtyUsdType,
  TpYieldLiquidtyWADType,
} from '../interfaces/TpYield'

export const SLIPPAGE_PARAM_K_WAD = WAD.div(20000) // (1/20000) in WAD
export const SLIPPAGE_PARAM_N_BN = BigNumber.from('6')
export const SLIPPAGE_PARAM_C1_BN = BigNumber.from('366166321751524166') // ~ 0.366...
export const SLIPPAGE_PARAM_X_THRESHOLD_BN =
  BigNumber.from('313856847215592143') // ~ 0.313...
export const HAIRCUT_RATE_WAD = WAD.mul(4).div(10000) // 0.0004 in WAD

/**
 * Calculates the amount of LP tokens required to convert from one token to another, given a conversion rate.
 *
 * @param {TokenSymbol} fromTokenSymbol - The symbol of the token being converted from.
 * @param {TokenSymbol} toTokenSymbol - The symbol of the token being converted to.
 * @param {BigNumber} fromTokenAmountInTermsOfLp - The amount of the from token, in terms of LP tokens.
 * @param {{fromTokenSymbol: TokenSymbol, toTokenSymbol: TokenSymbol, valueWad: BigNumber}} conversionRate - The conversion rate object, containing the from token symbol, to token symbol, and conversion value.
 * @returns {BigNumber} The amount of LP tokens required to convert from the from token to the to token, based on the given conversion rate.
 * @throws {Error} If the conversion rate is not applicable to the specified from and to tokens.
 */
export const getToLpTokenAmountByRate = (
  fromTokenSymbol: TokenSymbol,
  toTokenSymbol: TokenSymbol,
  fromTokenAmountInTermsOfLp: BigNumber,
  conversionRate: {
    fromTokenSymbol: TokenSymbol
    toTokenSymbol: TokenSymbol
    valueWad: BigNumber
  },
) => {
  if (
    fromTokenSymbol === conversionRate.fromTokenSymbol &&
    toTokenSymbol === conversionRate.toTokenSymbol
  ) {
    return wmul(fromTokenAmountInTermsOfLp, conversionRate.valueWad)
  } else if (
    fromTokenSymbol === conversionRate.toTokenSymbol &&
    toTokenSymbol === conversionRate.fromTokenSymbol
  ) {
    return safeWdiv(fromTokenAmountInTermsOfLp, conversionRate.valueWad)
  } else {
    throw new Error(
      `Require from ${conversionRate.fromTokenSymbol} to ${conversionRate.toTokenSymbol} or from ${conversionRate.toTokenSymbol} to ${conversionRate.fromTokenSymbol}`,
    )
  }
}
/** Third party avax (tpAvax) updates required */
/**
 * Since avax and third party avax(tpAvax) is not 1 to 1,
 * so we convert to correct lp token amount in term of to token,
 * when user are withdrawing in other token
 * if fromTokenSymbol is tpAvax, toTokenSymbol is AVAX ->
 * toTokenAmountInTermsOfLp(AVAX) = fromTokenAmountInTermsOfLp(tpAvax) * tpAvaxToAvaxRate(tpAvaxPriceInTermsOfAvax)
 * if fromTokenSymbol is AVAX, toTokenSymbol is tpAvax ->
 * toTokenAmountInTermsOfLp(tpAvax) = fromTokenAmountInTermsOfLp(tpAvax) / tpAvaxToAvaxRate(tpAvaxPriceInTermsOfAvax)
 * @param {TokenSymbol} fromTokenSymbol
 * @param {TokenSymbol} toTokenSymbol
 * @param {BigNumber} fromTokenAmountInTermsOfLp, in Native decimals
 * @param {BigNumber} tpAvaxToAvaxRateWad, in WAD
 * @returns toTokenAmountInTermsOfLp, in Native decimals
 */
export const getToLpTokenAmountForTpAvaxWithdrawal = (
  fromTokenSymbol: TokenSymbol,
  toTokenSymbol: TokenSymbol,
  fromTokenAmountInTermsOfLp: BigNumber,
  tpAvaxToAvaxRateWad: BigNumber,
): BigNumber => {
  if (
    TOKENS[fromTokenSymbol].isTpAvaxToken &&
    toTokenSymbol === TokenSymbol.WAVAX
  ) {
    return wmul(fromTokenAmountInTermsOfLp, tpAvaxToAvaxRateWad)
  } else if (
    fromTokenSymbol === TokenSymbol.AVAX &&
    TOKENS[toTokenSymbol].isTpAvaxToken
  ) {
    return safeWdiv(fromTokenAmountInTermsOfLp, tpAvaxToAvaxRateWad)
  } else {
    throw new Error('Require from tpAvax to WAVAX or from AVAX to tpAvax')
  }
}

/**
 * @param {BigNumber} lpTokenAmountBN amount of LP token, in token native decimals
 * @param {TokenSymbol} tokenSymbol
 * @returns {string} amount of token, in string
 */
export const lptokenBNToToken = (
  lpTokenAmountBN: BigNumber,
  lpSupplyBN: BigNumber,
  liabilityBN: BigNumber,
  tokenSymbol: TokenSymbol,
): string => {
  // amount of token = lpTokenAmount * (asset.liability() / asset.totalSupply())
  const decimals = TOKENS[tokenSymbol].decimals
  if (!lpSupplyBN.isZero()) {
    return utils.formatUnits(
      lpTokenAmountBN.mul(liabilityBN).div(lpSupplyBN),
      decimals,
    )
  } else {
    return '0.0'
  }
}

/**
 * Get Maximum Withdrawable Percent for Withdraw use.
 * @param {TokenSymbol} initialTokenSymbol
 * @param {TokenSymbol} wantedTokenSymbol
 * @param {string} wantedTokenAsset
 * @param {string} wantedTokenLiability
 * @returns {string}
 */
/** @todo hook up this function in order to remove arg. */
export const getMaxWithdrawablePercentage = async (
  chainId: ChainId,
  initialTokenSymbol: TokenSymbol,
  initialLPTokenBalance: string,
  wantedTokenSymbol: TokenSymbol,
  wantedTokenAsset: string,
  wantedTokenLiability: string,
  pool: Pool | PoolAvax | null,
): Promise<string> => {
  // situation 1. initialTokenSymbol === wantedTokenSymbol -> 100
  if (initialTokenSymbol === wantedTokenSymbol) {
    return '100'
  } else {
    const assetBn = strToWad(wantedTokenAsset) // in WAD
    const liabilityBn = strToWad(wantedTokenLiability) // in WAD
    const coverageRatio = safeWdiv(
      assetBn,
      liabilityBn.gt(constants.Zero) ? liabilityBn : strToWad('1'),
    )
    // situation 2. initialTokenSymbol ！== wantedTokenSymbol
    // 2a.if coverage ratio < 100%, then not available (0)
    if (coverageRatio.lt(strToWad('1'))) {
      return '0'
    } else {
      // In AVAX-SAVAX pool, TokenSymbol is AVAX which will determine as wAVAX in getMaxWithdrawablePercentage.
      if (wantedTokenSymbol === TokenSymbol.AVAX) {
        wantedTokenSymbol = TokenSymbol.WAVAX
      }
      if (initialTokenSymbol === TokenSymbol.AVAX) {
        initialTokenSymbol = TokenSymbol.WAVAX
      }

      // 2b. if coverage ratio > 100%, then calculate max percent (by smart contract)
      const wantedTokenAddress = TOKENS[wantedTokenSymbol].getAddress(chainId)
      const initialTokenAddress = TOKENS[initialTokenSymbol].getAddress(chainId)
      let maxWithdrawablePercentage = '0'
      if (pool && wantedTokenAddress && initialTokenAddress) {
        const maxWithdrawableLPAmountBN: BigNumber =
          await pool.quoteMaxInitialAssetWithdrawable(
            initialTokenAddress,
            wantedTokenAddress,
          )
        const initialLPTokenBalanceBN = utils.parseUnits(
          initialLPTokenBalance,
          TOKENS[initialTokenSymbol].decimals,
        )
        const maxWithdrawablePercentageBN = maxWithdrawableLPAmountBN
          .mul(WAD)
          .mul('100')
          .div(initialLPTokenBalanceBN)
        // if percentage < 0.001% (0.00001)
        if (
          maxWithdrawablePercentageBN.gt(strToWad(MIN_WITHDRAWABLE_PERCENTAGE))
        ) {
          // avoid maxWithdrawablePercentage > 100
          if (maxWithdrawablePercentageBN.gt(WAD.mul('100'))) {
            maxWithdrawablePercentage = '100'
          } else {
            maxWithdrawablePercentage = utils.formatEther(
              maxWithdrawablePercentageBN,
            )
          }
        }
      }
      return maxWithdrawablePercentage
    }
  }
}

/**
 * Convert from percent to withdraw / unstake LP amount
 * @param {Token} token
 * @param {string} withdrawalPercentage
 * @param {string} totalLPAmount
 * @returns {string}
 */
export const getLpAmountStrFromPercent = (
  token: Token,
  withdrawalPercentage: string,
  totalLPAmount: string,
): string => {
  const percentWAD = strToWad(withdrawalPercentage)
  const ratioWAD = percentWAD.div('100')

  let withdrawLPAmountBN = utils.parseUnits(totalLPAmount, token.decimals)
  withdrawLPAmountBN = wmul(withdrawLPAmountBN, ratioWAD)

  // convert from percent to LP amount
  const withdrawLPAmount = utils.formatUnits(withdrawLPAmountBN, token.decimals)
  return withdrawLPAmount
}

/**
 * Calculate the minimum amount received
 * @param {BigNumber} amount, TO token amount in WAD
 * @param {string} slippage, 0 to 1 in string
 * @returns {BigNumber} minimum received amount, in WAD
 */
export const calculateMinimumReceived = (
  amount: BigNumber,
  slippage: string,
): BigNumber => {
  const factor = safeWdiv(WAD, strToWad(slippage).add(WAD))
  return wmul(amount, factor)
}

/**
 * see paper Definition 4.1.3 (Coverage Ratio)
 * @param {BigNumber} cash in WAD
 * @param {BigNumber} liability in WAD
 * @returns {BigNumber} coverage ratio in WAD
 */
export function getCoverageRatio(
  cash: BigNumber,
  liability: BigNumber,
): BigNumber {
  return safeWdiv(cash, liability)
}

/** @todo test case for this function */
/**
 * see paper Definition 7.2 (Deposit Fee)
 * @param {BigNumber} cash in WAD
 * @param {BigNumber} liability in WAD
 * @param {BigNumber} amount in WAD
 * @param {BigNumber} k in WAD
 * @param {BigNumber} n in BigNumber Int
 * @param {BigNumber} c1 in WAD
 * @param {BigNumber} xThreshold in WAD
 * @returns {BigNumber} The final fee to be applied, in WAD
 */
export function getDepositFee(
  cash: BigNumber,
  liability: BigNumber,
  amount: BigNumber,
  k: BigNumber = SLIPPAGE_PARAM_K_WAD,
  n: BigNumber = SLIPPAGE_PARAM_N_BN,
  c1: BigNumber = SLIPPAGE_PARAM_C1_BN,
  xThreshold: BigNumber = SLIPPAGE_PARAM_X_THRESHOLD_BN,
): BigNumber {
  if (liability.isZero()) {
    return constants.Zero
  }

  const covBefore = getCoverageRatio(cash, liability)
  if (covBefore.lte(WAD)) {
    return constants.Zero
  }

  const covAfter = getCoverageRatio(cash.add(amount), liability.add(amount))

  const slippageBefore = slippageFunc(covBefore, k, n, c1, xThreshold)
  const slippageAfter = slippageFunc(covAfter, k, n, c1, xThreshold)

  // (Li + Di) * g(cov_after) - Li * g(cov_before)
  const term1 = wmul(liability.add(amount), slippageAfter)
  const term2 = wmul(liability, slippageBefore)

  return term1.sub(term2)
}

/**
 * see paper Definition 5.1.2 (Price Slippage Curve)
 * @param {BigNumber} r coverage ratio, in WAD
 * @param {BigNumber} k in WAD
 * @param {BigNumber} n in BigNumber Int
 * @param {BigNumber} c1 in WAD
 * @param {BigNumber} xThreshold in WAD
 * @returns {BigNumber} k / (r ** n) in WAD
 */
export function slippageFunc(
  r: BigNumber,
  k: BigNumber = SLIPPAGE_PARAM_K_WAD,
  n: BigNumber = SLIPPAGE_PARAM_N_BN,
  c1: BigNumber = SLIPPAGE_PARAM_C1_BN,
  xThreshold: BigNumber = SLIPPAGE_PARAM_X_THRESHOLD_BN,
): BigNumber {
  if (r.lte(xThreshold)) {
    return c1.sub(r)
  } else {
    const x_pow_n = rayToWad(rpow(wadToRay(r), n)) // r ** n
    return safeWdiv(k, x_pow_n) // k / (r ** n)
  }
}

/**
 * see paper Definition 5.1.2 (Price Slippage Curve)
 * @param {BigNumber} r coverage ratio, in WAD
 * @param {BigNumber} k in WAD
 * @param {BigNumber} n in BigNumber Int
 * @param {BigNumber} c1 in WAD
 * @param {BigNumber} xThreshold in WAD
 * @returns {BigNumber} - n * k / (r ** (n+1)) in WAD
 */
export function slippageFuncDerivative(
  r: BigNumber,
  k: BigNumber = SLIPPAGE_PARAM_K_WAD,
  n: BigNumber = SLIPPAGE_PARAM_N_BN,
  c1: BigNumber = SLIPPAGE_PARAM_C1_BN,
  xThreshold: BigNumber = SLIPPAGE_PARAM_X_THRESHOLD_BN,
): BigNumber {
  const slippageFuncValue = slippageFunc(r, k, n, c1, xThreshold) // k / (r ** n)
  const val = slippageFuncValue.mul(-1).mul(n) // - n * k / (r ** n)
  return safeWdiv(val, r) // - n * k / (r ** (n+1)) in WAD
}

/**
 * Definition 5.1.3a (Swapping Slippage) -S_i or -S_j
 * The original definition always have S_i or S_j <= 0,
 * this function will instead return -S_i or -S_j, which is positive
 * @param {BigNumber} cash in WAD
 * @param {BigNumber} liability in WAD
 * @param {BigNumber} cashChange in WAD
 * @param {boolean} addCash boolean, if the cash increase (swap from/deposit) or decrease (swap to/withdraw)
 * @param {BigNumber} k in WAD
 * @param {BigNumber} n in BigNumber Int
 * @param {BigNumber} c1 in WAD
 * @param {BigNumber} xThreshold in WAD
 * @returns {BigNumber} negative slippage -S_i in WAD
 */
export function slippage(
  cash: BigNumber,
  liability: BigNumber,
  cashChange: BigNumber,
  addCash: boolean,
  k: BigNumber = SLIPPAGE_PARAM_K_WAD,
  n: BigNumber = SLIPPAGE_PARAM_N_BN,
  c1: BigNumber = SLIPPAGE_PARAM_C1_BN,
  xThreshold: BigNumber = SLIPPAGE_PARAM_X_THRESHOLD_BN,
): BigNumber {
  const covBefore = getCoverageRatio(cash, liability)
  let covAfter

  if (addCash) {
    covAfter = getCoverageRatio(cash.add(cashChange), liability)
  } else {
    covAfter = getCoverageRatio(cash.sub(cashChange), liability)
  }

  if (covAfter.eq(covBefore)) {
    return BigNumber.from(0)
  }

  const slippageBefore = slippageFunc(covBefore, k, n, c1, xThreshold)
  const slippageAfter = slippageFunc(covAfter, k, n, c1, xThreshold)

  if (covBefore.gt(covAfter)) {
    return safeWdiv(slippageAfter.sub(slippageBefore), covBefore.sub(covAfter))
  } else {
    return safeWdiv(slippageBefore.sub(slippageAfter), covAfter.sub(covBefore))
  }
}

/**
 * Definition 5.1.3b (Swapping Slippage)
 * @param si the -S_i returned from slippage, in WAD
 * @param sj the -S_j returned from slippage, in WAD
 * @returns 1 + (-S_i) - (-S_j)
 */
export function swappingSlippage(si: BigNumber, sj: BigNumber): BigNumber {
  return WAD.add(si).sub(sj)
}
export type SwapQuoteType = {
  potentialOutcome: BigNumber
  haircut: BigNumber
} | null

/**
 * @param {Provider} multicallProvider
 * @param {string | null} chainId
 * @param {string} fromTokenAmount
 * @param {TokenSymbol} fromSymbol
 * @param {TokenSymbol} toSymbol
 * @param {Object} options
 * @returns {{target: SwapQuoteType,market: SwapQuoteType}} return {target: null, market: null} if the quote fails
 */
export const getSwapPotentialQuotesForMarketAndTarget = async (
  chainId: ChainId | null,
  fromTokenAmount: string,
  fromSymbol: TokenSymbol,
  toSymbol: TokenSymbol,
  options: {
    isRouter: boolean
    pool?: Pool | PoolAvax | null
    router?: PlatypusRouter02 | null
    tokenAddressesPath?: string[]
    poolAddressesPath?: string[]
  },
): Promise<{
  target: SwapQuoteType
  market: SwapQuoteType
  errorReason?: string
}> => {
  const { isRouter, pool, router, tokenAddressesPath, poolAddressesPath } =
    options
  const fromTokenInstance =
    fromSymbol === TokenSymbol.AVAX
      ? TOKENS[TokenSymbol.WAVAX]
      : TOKENS[fromSymbol]
  const toTokenInstance =
    toSymbol === TokenSymbol.AVAX ? TOKENS[TokenSymbol.WAVAX] : TOKENS[toSymbol]
  try {
    const fromTokenAddress = fromTokenInstance.getAddress(chainId)
    const toTokenAddress = toTokenInstance.getAddress(chainId)
    if (isRouter && router && tokenAddressesPath && poolAddressesPath) {
      // router quote
      // Target Quote
      const targetPromise = router.quotePotentialSwaps(
        tokenAddressesPath,
        poolAddressesPath,
        utils.parseUnits(fromTokenAmount, fromTokenInstance.decimals),
      )
      // Market Quote
      const marketPromise = router
        .quotePotentialSwaps(
          tokenAddressesPath,
          poolAddressesPath,
          utils.parseUnits(
            INFINITESIMAL_SWAP_FROM_AMOUNT_FOR_MARKET,
            fromTokenInstance.decimals,
          ),
        )
        .catch(() => {
          // in case the market quote failed, we return the same from amount as the quote
          return {
            potentialOutcome: utils.parseUnits(
              INFINITESIMAL_SWAP_FROM_AMOUNT_FOR_MARKET,
              fromTokenInstance.decimals,
            ),
            haircut: BigNumber.from('0'),
          }
        })
      const [target, market] = await Promise.all([targetPromise, marketPromise])
      // return quote
      return { target, market }
    } else if (pool && fromTokenAddress && toTokenAddress) {
      // intra-pool quote
      // Target Quote
      const targetPromise = pool.quotePotentialSwap(
        fromTokenAddress,
        toTokenAddress,
        utils.parseUnits(fromTokenAmount, fromTokenInstance.decimals),
      )
      // Market Quote
      const marketPromise = pool.quotePotentialSwap(
        fromTokenAddress,
        toTokenAddress,
        utils.parseUnits(
          INFINITESIMAL_SWAP_FROM_AMOUNT_FOR_MARKET,
          fromTokenInstance.decimals,
        ),
      )
      const [target, market] = await Promise.all([targetPromise, marketPromise])
      // return quote
      return { target, market }
    }
  } catch (err) {
    console.error(err)
    const errorMessage = (err as Error).message
    const regex = /reason="(.+)"/i
    const result = regex.exec(errorMessage)
    return {
      target: null,
      market: null,
      errorReason: (result && result[1]) || undefined,
    }
  }
  return { target: null, market: null }
}

export const getStakableLPTooltip = (tokenSymbol: TokenSymbol): string => {
  return `Amount of your deposited ${tokenSymbol} (as LP token) which can be staked to generate yield.`
}

export const getStakedLPTooltip = (tokenSymbol: TokenSymbol): string => {
  return `Amount of your deposited ${tokenSymbol} (as LP token) which is currently staked and generating rewards.`
}

/**
 *
 * @param {TpYieldLiquidtyWADType} tpYieldLiquidtyWAD
 * @param {TokenSymbolStringType} tokenPrices
 */
export const getTpYieldLiquidtyUSD = (
  tpYieldLiquidtyWAD: TpYieldLiquidtyWADType,
  tokenPrices: TokenSymbolStringType,
): TpYieldLiquidtyUsdType => {
  const { PTP: ptpPrice, AVAX: avaxPrice } = tokenPrices
  return TpYieldSymbols.reduce((acc, tpYieldSymbol) => {
    const ptpLiquidtyUSDWAD = wmul(
      strToWad(ptpPrice),
      tpYieldLiquidtyWAD[tpYieldSymbol][TokenSymbol.PTP],
    )

    const wavaxLiquidtyUSDWAD = wmul(
      strToWad(avaxPrice),
      tpYieldLiquidtyWAD[tpYieldSymbol][TokenSymbol.AVAX],
    )

    return {
      ...acc,
      [tpYieldSymbol]: sum(ptpLiquidtyUSDWAD, wavaxLiquidtyUSDWAD),
    }
  }, {}) as TpYieldLiquidtyUsdType
}
