import React, { ReactElement } from 'react'
import UnclaimedBribeAlert from '../../components/alerts/UnclaimedBribeAlert/UnclaimedBribeAlert'
import GaugeTable from './GaugeTable/GaugeTable'
import { Container, Headline, Subtitle } from './GaugeVotingContainer.elements'
import GaugeWeightPieChart from './GaugeWeightPieChart/GaugeWeightPieChart'
import { UserVoteAllocationTableProvider } from './UserVoteAllocationTable/UserVoteAllocationTableContext'
import UserVotesSection from './UserVotesSection/UserVotesSection'

function GaugeVotingContainer(): ReactElement {
  return (
    <Container>
      <Headline>Gauge Weight Voting</Headline>
      <Subtitle>Realtime Gauge Weight Distribution</Subtitle>
      <GaugeWeightPieChart />
      <GaugeTable />
      <UserVoteAllocationTableProvider>
        <UnclaimedBribeAlert />
        <UserVotesSection />
      </UserVoteAllocationTableProvider>
    </Container>
  )
}

export default GaugeVotingContainer
