import {
  alpha,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import styled, { css } from 'styled-components'
import Input from '../../components/Input/Input'

export const Topbar = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const TokenListItemIcon = styled(ListItemIcon)`
  min-width: unset;
  margin-right: 10px;
`
export const TokenName = styled(ListItemText)``
export const TokenBalance = styled(ListItemText)`
  flex: unset;
`
interface TokenListItemProps {
  selected?: boolean
}
export const TokenListItem = styled(ListItemButton)<TokenListItemProps>`
  ${({ selected }) => css`
    padding-left: var(--modal-body-padding);
    padding-right: var(--modal-body-padding);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${selected &&
    css`
      &.Mui-selected {
        background-color: unset;
        opacity: 0.5;
      }
    `}
  `}
`

export const TokenList = styled(List)`
  max-height: 70vh;
  overflow-y: scroll;
  /* revert the modal's left / right padding */
  margin-left: calc(-1 * var(--modal-body-padding));
  margin-right: calc(-1 * var(--modal-body-padding));
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`
export const StyledInput = styled(Input)`
  ${({ theme }) => css`
    background: ${alpha(theme.palette.primary[500], 0.2)};
    margin: 0;
  `}
`
