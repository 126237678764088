import { safeWdiv, WAD } from '@hailstonelabs/big-number-utils'
import { BigNumber, ethers } from 'ethers'
import {
  ankrAvax,
  ankrEthOracle,
  sAvax,
  testYYOracle,
  yyAvax,
} from '../config/contracts'
import { PoolSymbol } from '../config/contracts/pool/poolSymbol'
import { ChainId } from '../config/networks'

/** @todo this should update in config! */
/** Third party avax (tpAvax) updates required */
/**
 * Since avax and tpAvax are not 1 to 1.
 * This function call smart contract to get the rate.
 * @param {PoolSymbol} selectedPoolSymbol
 * @param {ChainId} chainId
 * @param {ethers.providers.JsonRpcProvider} readOnlyProvider
 * @returns {Promise<ethers.BigNumber | undefined>}
 */
export const getTpAvaxToAvaxRateWad = async (
  selectedPoolSymbol: PoolSymbol,
  chainId: ChainId,
  readOnlyProvider: ethers.providers.JsonRpcProvider,
): Promise<ethers.BigNumber | undefined> => {
  let tpAvaxToAvaxRateWad: BigNumber | undefined
  if (selectedPoolSymbol === PoolSymbol.ALT_AVAX_YYAVAX) {
    if (chainId === ChainId.FUJI) {
      tpAvaxToAvaxRateWad = await testYYOracle
        .get(chainId, readOnlyProvider)
        ?.pricePerShare()
    } else {
      tpAvaxToAvaxRateWad = await yyAvax
        .get(chainId, readOnlyProvider)
        ?.pricePerShare()
    }
  } else if (selectedPoolSymbol === PoolSymbol.ALT_AVAX_SAVAX) {
    tpAvaxToAvaxRateWad = await sAvax
      .get(chainId, readOnlyProvider)
      ?.getPooledAvaxByShares(WAD)
  } else if (selectedPoolSymbol === PoolSymbol.ALT_AVAX_ANKRAVAX) {
    if (chainId === ChainId.FUJI) {
      tpAvaxToAvaxRateWad = WAD
    } else {
      const avaxToTpAvaxRateWad = await ankrAvax
        .get(chainId, readOnlyProvider)
        ?.ratio()
      tpAvaxToAvaxRateWad =
        avaxToTpAvaxRateWad && safeWdiv(WAD, avaxToTpAvaxRateWad)
    }
  }
  return tpAvaxToAvaxRateWad
}

/** @todo should rename this file or put the rate in context */
export const getAnkrEthToWetheRateWad = async (
  chainId: ChainId,
  readOnlyProvider: ethers.providers.JsonRpcProvider,
): Promise<BigNumber | undefined> => {
  const wetheToAnkrEthRateWad = await ankrEthOracle
    .get(chainId, readOnlyProvider)
    /** this address is a hardcoded address */
    ?.getRatioFor('0xE95A203B1a91a908F9B9CE46459d101078c2c3cb')

  return wetheToAnkrEthRateWad && safeWdiv(WAD, wetheToAnkrEthRateWad)
}
