import React, { ReactElement } from 'react'
import WaitForConfirmationModal from '../../../components/Modal/WaitForConfirmationModal/WaitForConfirmationModal'
import DisabledAppTokenInput from '../../../components/TokenInput/DisabledAppTokenInput'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { useModal } from '../../../contexts/ModalContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import UnstakeOrUnlockModalInfoBox from '../UnstakeModalContainer/UnstakeOrUnlockModalInfoBox'

interface Props {
  isOpen: boolean
}

function UnlockPtpWaitForConfirmationModal({ isOpen }: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
  } = useModal()

  const handleCloseModal = () => {
    modalDispatch(closeModal())
  }

  const { ptp } = useVePtp()

  return (
    <WaitForConfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="600px"
      subtitle={`Unlock ${TokenSymbol.PTP}`}
    >
      <DisabledAppTokenInput
        tokenItems={[
          {
            value: ptp.amount.locked,
            tokenSymbol: TokenSymbol.PTP,
          },
        ]}
      />
      <UnstakeOrUnlockModalInfoBox />
    </WaitForConfirmationModal>
  )
}

export default UnlockPtpWaitForConfirmationModal
