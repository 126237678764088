import { getDpFormat } from '@hailstonelabs/big-number-utils'
import React, { ReactElement } from 'react'
import PieChart from '../../../components/charts/PieChart/PieChart'
import { POOLS } from '../../../config/contracts'
import { POOL_GROUPS } from '../../../config/contracts/pool/PoolGroup'
import { useVotingData } from '../../../contexts/VotingDataContext'
import { DEPRECATED_GAUGES_POOLS } from '../../../config/contracts/pool'

function GaugeWeightPieChart(): ReactElement {
  const { total } = useVotingData()
  const colors: string[] = []
  const dataset: number[] = []
  const labels: {
    title: string
    subtitle: string
  }[] = []

  for (const poolGroup of Object.values(POOL_GROUPS)) {
    for (const poolSymbol of poolGroup.poolSymbols) {
      const pool = POOLS[poolSymbol]
      const assets = pool.getAssets()
      assets.forEach((asset) => {
        const percentage = getDpFormat(
          total.voteWeightOfEachAsset.percentage[poolSymbol][asset.tokenSymbol],
          1,
        )
        colors.push(asset.color)
        dataset.push(Number(percentage))
        labels.push({
          title: `${asset.tokenSymbol} (${pool.name})`,
          subtitle: `Gauge weight: ${percentage}%`,
        })
      })
    }
  }

  for (const poolSymbol of DEPRECATED_GAUGES_POOLS) {
    const deprecatedPool = POOLS[poolSymbol]
    for (const asset of deprecatedPool.getAssets(
      'deprecatedMainPoolForGauageVoting',
    )) {
      const percentage = getDpFormat(
        total.voteWeightOfEachAsset.percentage[poolSymbol][asset.tokenSymbol],
        1,
      )
      colors.push(asset.color)
      dataset.push(Number(percentage))
      labels.push({
        title: `${asset.tokenSymbol} (${deprecatedPool.name})`,
        subtitle: `Gauge weight: ${percentage}%`,
      })
    }
  }

  return (
    <PieChart
      maxWidth="550px"
      width="100%"
      data={{
        labels: labels,
        dataset: dataset,
        colors: colors,
      }}
    />
  )
}

export default GaugeWeightPieChart
