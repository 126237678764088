import { Box } from '@mui/material'
import styled, { css, keyframes } from 'styled-components'

interface ToggleButtonProps {
  active?: boolean
}

const rotate = keyframes`
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(0);
  }
`

export const ToggleButton = styled(Box)<ToggleButtonProps>`
  ${({ theme, active }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: ${theme.palette.primary[500]};
    margin: auto 0 auto 16px;
    border-radius: 4px;
    cursor: pointer;
    padding: 2px;
    transition: all 0.3s;
    svg {
      width: 100%;
      height: 100%;
    }
    ${active &&
    css`
      width: 20px;
      height: 20px;
      svg {
        animation: ${rotate} 0.3s forwards;
      }
    `}
  `}
`

export const HiddenContent = styled(Box)`
  ${() => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
    grid-gap: 20px 4px;
    justify-content: center;
    transform-origin: left top;
  `}
`
