import { Call } from 'ethcall'
import { BigNumber, utils } from 'ethers'
import cloneDeep from 'lodash.clonedeep'
import { POOLS } from '../../../config/contracts'
import {
  PoolSymbol,
  poolSymbols,
} from '../../../config/contracts/pool/poolSymbol'
import { ChainId } from '../../../config/networks'
import {
  EMPTY_POOLSYMBOL_TOKENSYMBOL_BN,
  EMPTY_POOLSYMBOL_TOKENSYMBOL_STR,
} from '../../../constants/empty'
import {
  PoolSymbolTokenSymbolBigNumberType,
  PoolSymbolTokenSymbolStringType,
} from '../../../interfaces/common'
import {
  CallbacksType,
  MulticallHelperSCReturnType,
} from '../../../utils/multicall'
import {
  HAIRCUT_RATE_WAD,
  SLIPPAGE_PARAM_C1_BN,
  SLIPPAGE_PARAM_K_WAD,
  SLIPPAGE_PARAM_N_BN,
  SLIPPAGE_PARAM_X_THRESHOLD_BN,
} from '../../../utils/pool'

export interface PoolDataType {
  newSwapData: SwapDataType
  newAssets: PoolSymbolTokenSymbolStringType
  newLiabilities: PoolSymbolTokenSymbolStringType
  newLpTotalSuppliesBN: PoolSymbolTokenSymbolBigNumberType
}

type SwapDataType = {
  [id in PoolSymbol]: {
    slippageParamKWad: BigNumber
    slippageParamNBN: BigNumber
    slippageParamC1BN: BigNumber
    slippageParamXThresholdBN: BigNumber
    haircutRateWad: BigNumber
  }
}

const initSwapData = poolSymbols.reduce((acc, curPoolSymbol) => {
  return {
    ...acc,
    [curPoolSymbol]: {
      slippageParamKWad: SLIPPAGE_PARAM_K_WAD,
      slippageParamNBN: SLIPPAGE_PARAM_N_BN,
      slippageParamC1BN: SLIPPAGE_PARAM_C1_BN,
      slippageParamXThresholdBN: SLIPPAGE_PARAM_X_THRESHOLD_BN,
      haircutRateWad: HAIRCUT_RATE_WAD,
    },
  }
}, {}) as SwapDataType

const states = {
  newSwapData: initSwapData,
  newAssets: cloneDeep(
    EMPTY_POOLSYMBOL_TOKENSYMBOL_STR,
  ) as PoolSymbolTokenSymbolStringType,
  newLiabilities: cloneDeep(
    EMPTY_POOLSYMBOL_TOKENSYMBOL_STR,
  ) as PoolSymbolTokenSymbolStringType,
  newLpTotalSuppliesBN: cloneDeep(
    EMPTY_POOLSYMBOL_TOKENSYMBOL_BN,
  ) as PoolSymbolTokenSymbolBigNumberType,
}

export const fetchPoolData = (chainId: ChainId) => {
  const contractCalls: Call[] = []
  const callbacks: CallbacksType<MulticallHelperSCReturnType> = []
  const handlePoolParams = () => {
    for (let i = 0; i < poolSymbols.length; i++) {
      const poolSymbol = poolSymbols[i]
      const poolContract = POOLS[poolSymbol].get(chainId)
      if (poolContract) {
        contractCalls.push(
          poolContract.getSlippageParamK() as unknown as Call,
          poolContract.getSlippageParamN() as unknown as Call,
          poolContract.getC1() as unknown as Call,
          poolContract.getXThreshold() as unknown as Call,
          poolContract.getHaircutRate() as unknown as Call,
        )
        callbacks.push(
          (valueBN) => {
            states.newSwapData[poolSymbol]['slippageParamKWad'] =
              valueBN as BigNumber
          },
          (valueBN) => {
            states.newSwapData[poolSymbol]['slippageParamNBN'] =
              valueBN as BigNumber
          },
          (valueBN) => {
            states.newSwapData[poolSymbol]['slippageParamC1BN'] =
              valueBN as BigNumber
          },
          (valueBN) => {
            states.newSwapData[poolSymbol]['slippageParamXThresholdBN'] =
              valueBN as BigNumber
          },
          (valueBN) => {
            states.newSwapData[poolSymbol]['haircutRateWad'] =
              valueBN as BigNumber
          },
        )
      }
    }
  }

  const handlePoolData = () => {
    for (let j = 0; j < poolSymbols.length; j++) {
      const poolSymbol = poolSymbols[j]
      // get all asset symbol
      const assets = POOLS[poolSymbol].getAssets()
      for (let i = 0; i < assets.length; i++) {
        const tokenSymbol = assets[i].tokenSymbol
        const lpToken = assets[i]
        const lpTokenContract = lpToken.get(chainId)
        if (lpTokenContract) {
          contractCalls.push(
            lpTokenContract.liability() as unknown as Call,
            lpTokenContract.cash() as unknown as Call,
            lpTokenContract.totalSupply() as unknown as Call,
          )
          callbacks.push(
            (valueBN) => {
              states.newLiabilities[poolSymbol][tokenSymbol] =
                utils.formatUnits(valueBN as BigNumber, lpToken.decimals)
            },
            (valueBN) => {
              states.newAssets[poolSymbol][tokenSymbol] = utils.formatUnits(
                valueBN as BigNumber,
                lpToken.decimals,
              )
            },
            (valueBN) => {
              states.newLpTotalSuppliesBN[poolSymbol][tokenSymbol] =
                valueBN as BigNumber
            },
          )
        }
      }
    }
  }

  handlePoolParams()
  handlePoolData()

  return {
    contractCalls,
    callbacks,
    states,
  }
}
