import styled, { css } from 'styled-components'

export const Circle = styled.span`
  ${({ theme }) =>
    css`
      display: inline-block;
      width: 10px;
      height: 10px;
      background: ${theme.palette.secondary.main};
      border-radius: 999px;
      margin: auto 8px auto 0;
    `}
`
