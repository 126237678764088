import {
  getCommifiedFormat,
  getDpFormat,
  getMillifiedFormat,
  strToWad,
} from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import { constants } from 'ethers'
import React, { ReactElement, useMemo, useState } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import Dashable from '../../../components/Dashable/Dashable'
import ExportIcon from '../../../components/icons/ExportIcon'
import InfoBox from '../../../components/InfoBox/InfoBox'
import TooltipNum from '../../../components/InfoBox/TooltipNum'
import Table from '../../../components/Table/Table'
import { DataProps } from '../../../components/Table/Table.elements'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import { POOLS, voter } from '../../../config/contracts'
import { LpToken } from '../../../config/contracts/lpToken/LpToken'
import { POOL_GROUPS } from '../../../config/contracts/pool/PoolGroup'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { NETWORKS } from '../../../config/networks'
import { useBalance } from '../../../contexts/BalanceContext'
import { useNetwork } from '../../../contexts/NetworkContext'
import { useStakeLpData } from '../../../contexts/StakeLpDataContext'
import { useVePtp } from '../../../contexts/VePtpContext'
import { ContextType, useVotingData } from '../../../contexts/VotingDataContext'
import {
  Colorized,
  ExternalLink,
  StyledLinearProgress,
} from '../../../globalStyles'
import useBreakpoints from '../../../hooks/useBreakpoints'
import useTable, { SortingState, TableData } from '../../../hooks/useTable'
import showDashIfNecessary from '../../../utils/showDashIfNecessary'
import { Container, DataTypography, Summary } from './GaugeTable.elements'
import { DEPRECATED_GAUGES_POOLS } from '../../../config/contracts/pool'

function GaugeTable(): ReactElement {
  const { isMobileLg } = useBreakpoints()
  const { user } = useVotingData()
  const { chainId, account } = useNetwork()
  const { apr } = useStakeLpData()
  const { vePtp } = useVePtp()
  const { total, weeklyBribeIncentives, bribeAprData } = useVotingData()
  const hasVePtpBalance = vePtp.balance.isMoreThanZero
  const [sorting, setSorting] = useState<SortingState | undefined>(undefined)
  const { isTokenPriceFetched } = useBalance()

  /**
   * Table Head Config
   */
  const tableHeadConfig: {
    style?: DataProps
    data: ReactElement | string
  }[] = useMemo(
    () => [
      {
        style: { textAlign: 'center' },
        data: <DataTypography>#</DataTypography>,
      },
      {
        style: { textAlign: 'center' },
        data: <DataTypography>Pool</DataTypography>,
      },
      { data: 'Token' },
      {
        style: { textAlign: 'center' },
        data: <DataTypography>Base&nbsp;APR</DataTypography>,
      },
      {
        style: { textAlign: 'center' },
        data: <DataTypography>Median Boosted&nbsp;APR</DataTypography>,
      },
      {
        style: { textAlign: 'center' },
        data: (
          <>
            <DataTypography centerContent flexWrap="nowrap" whiteSpace="nowrap">
              <TokenIcon
                tokenSymbol={TokenSymbol.PTP}
                size={16}
                margin="0 4px 0 0"
              />
              PTP Emission
            </DataTypography>
            <DataTypography transparent>Per month</DataTypography>
          </>
        ),
      },
      {
        style: { textAlign: 'center' },
        data: (
          <DataTypography whiteSpace="nowrap" centerContent flexWrap="nowrap">
            Gauge Weights
            <InfoBox.Tooltip text="The total vePTP voted on this gauge." />
          </DataTypography>
        ),
      },
      {
        style: { textAlign: 'center' },
        data: (
          <>
            <DataTypography flexWrap="nowrap" whiteSpace="nowrap">
              {hasVePtpBalance
                ? 'Your Max Bribe Rewards'
                : 'Weekly Bribe Rewards'}
            </DataTypography>
            <DataTypography centerContent>
              <DataTypography transparent component="span">
                {hasVePtpBalance ? 'Per week' : 'Per 100k vePTP'}
              </DataTypography>
              <InfoBox.Tooltip
                text={
                  hasVePtpBalance
                    ? 'The max bribe rewards if you use 100% of your voting power on this gauge. This amount may vary based on the total vote of this gauge.'
                    : 'The bribe rewards per 100k vePTP on this gauge. This amount may vary based on the total vote of this gauge.'
                }
              />
            </DataTypography>
          </>
        ),
      },
      {
        style: { textAlign: 'center' },
        data: (
          <>
            <DataTypography centerContent flexWrap="nowrap" whiteSpace="nowrap">
              {hasVePtpBalance ? 'Your Max Bribe APR' : 'Average Bribe APR'}
              <InfoBox.Tooltip
                text={
                  hasVePtpBalance
                    ? 'The max bribe APR if you use 100% of your voting power on this gauge. This amount may vary based on the total vote of this gauge and the calculation is based on your staked PTP.'
                    : 'The average bribe APR on this gauge. This amount may vary based on the total vote of this gauge and the calculation is based on the average staked PTP.'
                }
              />
            </DataTypography>
          </>
        ),
      },
    ],
    [hasVePtpBalance],
  )

  /**
   * Table Data
   */
  const tableData = useMemo(() => {
    const outputData: TableData = []
    const getOutputData = (
      asset: LpToken,
      weeklyBribeIncentives: ContextType['weeklyBribeIncentives'],
    ) => {
      const poolSymbol = asset.poolSymbol
      const pool = POOLS[poolSymbol]
      const weeklyBribeIncentivesPerMaxVeptp =
        weeklyBribeIncentives.perMaxVeptp[poolSymbol]?.[asset.tokenSymbol]
      const weeklyBribeIncentivesPer100kVeptp =
        weeklyBribeIncentives.per100kVeptp[poolSymbol]?.[asset.tokenSymbol]
      const targetWeeklyBribeIncentives = hasVePtpBalance
        ? weeklyBribeIncentivesPerMaxVeptp
        : weeklyBribeIncentivesPer100kVeptp
      const targetBribeApr = hasVePtpBalance
        ? bribeAprData.user.max[poolSymbol]?.[asset.tokenSymbol]
        : bribeAprData.average[poolSymbol]?.[asset.tokenSymbol]
      const bribeTokenSymbol = asset.bribe?.tokenSymbol
      const targetBribeBalance = bribeTokenSymbol
        ? total.bribeBalanceOfEachAsset[poolSymbol]?.[asset.tokenSymbol]
            ?.inToken
        : '0'

      const isBribeBalanceDrained = strToWad(targetBribeBalance).eq(
        constants.Zero,
      )

      return {
        // available lp tokens must have sorting ids
        // if -1 occurs, the implementation is not correct
        id: asset.sortingId || -1,
        configs: [
          /**
           * column 1
           */
          {
            sortBy: asset.sortingId,
            cell: (
              <>
                <DataTypography>{asset.sortingId || '-'}</DataTypography>
              </>
            ),
          },
          /**
           * column 2
           */
          {
            sortBy: pool.name,
            // remove "Pool" text
            cell: (
              <>
                <DataTypography>
                  {pool.name.replace(' Pool', '')}
                </DataTypography>
              </>
            ),
          },

          /**
           * column 3
           */
          {
            sortBy: asset.tokenSymbolForDisplay,
            cell: (
              <>
                <DataTypography centerContent flexWrap="nowrap">
                  <TokenIcon
                    tokenSymbol={asset.tokenSymbolForDisplay}
                    size={16}
                    margin="0 4px 0 0"
                  />
                  {asset.tokenSymbolForDisplay}
                </DataTypography>
              </>
            ),
          },

          /**
           * column 4
           */
          {
            sortBy: apr.base.each[poolSymbol][asset.tokenSymbol]?.PTP || '0.0',
            cell: (
              <>
                <DataTypography>
                  <Dashable showDash={isBribeBalanceDrained}>
                    {DEPRECATED_GAUGES_POOLS.includes(poolSymbol)
                      ? '-'
                      : `${getDpFormat(
                          apr.base.each[poolSymbol][asset.tokenSymbol]?.PTP ||
                            '0.0',
                          1,
                        )} %`}
                  </Dashable>
                </DataTypography>
              </>
            ),
          },
          /**
           * column 5
           */
          {
            sortBy:
              pool.hasPtpBooster || pool.hasBonusBooster
                ? apr.medianBoosted.sum[poolSymbol][asset.tokenSymbol] || '0.0'
                : '-1',
            cell: (
              <>
                <DataTypography>
                  <Dashable
                    showDash={
                      isBribeBalanceDrained ||
                      !(pool.hasPtpBooster || pool.hasBonusBooster)
                    }
                  >
                    {DEPRECATED_GAUGES_POOLS.includes(poolSymbol)
                      ? '-'
                      : `${getDpFormat(
                          apr.medianBoosted.sum[poolSymbol][
                            asset.tokenSymbol
                          ] || '0.0',
                          1,
                        )}%`}
                  </Dashable>
                </DataTypography>
              </>
            ),
          },
          /**
           * column 6
           */
          {
            sortBy:
              apr.ptpMonthlyEmission[poolSymbol][asset.tokenSymbol] || '0.0',
            cell: (
              <>
                <DataTypography>
                  <TooltipNum
                    format="commified"
                    amount={
                      DEPRECATED_GAUGES_POOLS.includes(poolSymbol)
                        ? '0'
                        : apr.ptpMonthlyEmission[poolSymbol][asset.tokenSymbol]
                    }
                  >
                    {DEPRECATED_GAUGES_POOLS.includes(poolSymbol)
                      ? '-'
                      : getMillifiedFormat(
                          apr.ptpMonthlyEmission[poolSymbol][asset.tokenSymbol],
                        ) || '0.0'}
                  </TooltipNum>
                </DataTypography>
              </>
            ),
          },
          /**
           * column 7
           */
          {
            sortBy: DEPRECATED_GAUGES_POOLS.includes(poolSymbol)
              ? '0.0'
              : total.voteWeightOfEachAsset.vePtp[poolSymbol][
                  asset.tokenSymbol
                ] || '0.0',
            cell: (
              <>
                <Box display="flex" flexDirection="column" width="100%">
                  <DataTypography whiteSpace="nowrap">
                    <TooltipNum
                      format="commified"
                      amount={
                        total.voteWeightOfEachAsset.vePtp[poolSymbol][
                          asset.tokenSymbol
                        ]
                      }
                    >
                      {getMillifiedFormat(
                        total.voteWeightOfEachAsset.vePtp[poolSymbol][
                          asset.tokenSymbol
                        ] || '0.0',
                      )}
                    </TooltipNum>
                    &nbsp;(
                    {getDpFormat(
                      total.voteWeightOfEachAsset.percentage[poolSymbol][
                        asset.tokenSymbol
                      ],
                      1,
                    )}
                    %)
                  </DataTypography>
                  <StyledLinearProgress
                    variant="determinate"
                    // 0 - 100%
                    value={Number(
                      total.voteWeightOfEachAsset.percentage[poolSymbol][
                        asset.tokenSymbol
                      ],
                    )}
                    style={{ height: '2px' }}
                  />
                </Box>
              </>
            ),
          },
          /**
           * column 8
           */
          {
            sortBy: targetWeeklyBribeIncentives?.inUsd || '0.0',
            cell: (
              <>
                <DataTypography>
                  <Dashable
                    showDash={
                      !asset.bribe?.tokenSymbol ||
                      isBribeBalanceDrained ||
                      DEPRECATED_GAUGES_POOLS.includes(poolSymbol)
                    }
                  >
                    <DataTypography
                      component="span"
                      centerContent
                      justifyContent="center"
                    >
                      <TokenIcon
                        tokenSymbol={
                          asset.bribe ? asset.bribe.tokenSymbol : null
                        }
                        size={16}
                        margin="0 4px 0 0"
                      />
                      {showDashIfNecessary(
                        !targetWeeklyBribeIncentives?.inToken,
                        getCommifiedFormat(
                          targetWeeklyBribeIncentives?.inToken || '0.0',
                        ),
                      )}{' '}
                      {asset.bribe ? asset.bribe.tokenSymbol : '-'}&nbsp;
                    </DataTypography>
                    <br />
                    <DataTypography transparent component="span">
                      ($
                      {showDashIfNecessary(
                        !targetWeeklyBribeIncentives?.inUsd ||
                          !isTokenPriceFetched,
                        getCommifiedFormat(
                          targetWeeklyBribeIncentives?.inUsd || '0.0',
                        ),
                      )}
                      )
                    </DataTypography>
                  </Dashable>
                </DataTypography>
              </>
            ),
          },
          /**
           * column 9
           */
          {
            sortBy: targetBribeApr || '0.0',
            cell: (
              <>
                <DataTypography>
                  <Dashable showDash={!targetBribeApr || isBribeBalanceDrained}>
                    {getDpFormat(targetBribeApr || '0.0', 1)}%
                  </Dashable>
                </DataTypography>
              </>
            ),
          },
        ],
      }
    }

    let idx = 0
    for (const poolGroup of Object.values(POOL_GROUPS)) {
      for (const poolSymbol of poolGroup.poolSymbols) {
        const pool = POOLS[poolSymbol]
        const assets = pool.getAssets()
        /**
         * add data
         */
        assets.forEach((asset) => {
          asset.sortingId = ++idx
          outputData.push(getOutputData(asset, weeklyBribeIncentives))
        })
      }
    }

    if (account) {
      for (const poolSymbol of DEPRECATED_GAUGES_POOLS) {
        const deprecatedPool = POOLS[poolSymbol]
        for (const asset of deprecatedPool.getAssets(
          'deprecatedMainPoolForGauageVoting',
        )) {
          /**
           * add data
           */
          // Hide deprecated gauges if user is not voting
          if (
            user.voteWeightOfEachAsset.vePtp[poolSymbol]?.[
              asset.tokenSymbol
            ] !== '0.0'
          ) {
            asset.sortingId = ++idx
            outputData.push(getOutputData(asset, weeklyBribeIncentives))
          }
        }
      }
    }

    return outputData.sort((a, b) => (a.id as number) - (b.id as number))
  }, [
    account,
    hasVePtpBalance,
    bribeAprData.user.max,
    bribeAprData.average,
    total.bribeBalanceOfEachAsset,
    total.voteWeightOfEachAsset.vePtp,
    total.voteWeightOfEachAsset.percentage,
    apr.base.each,
    apr.medianBoosted.sum,
    apr.ptpMonthlyEmission,
    isTokenPriceFetched,
    user.voteWeightOfEachAsset.vePtp,
    weeklyBribeIncentives,
  ])

  const table = useTable({
    tableData,
    options: { sortingState: sorting },
  })

  const handleTableHeadDataClick = (index: number) => {
    const isSortingNewColumn = sorting?.columnIndex !== index
    setSorting((prev) => ({
      ...prev,
      columnIndex: index,
      ascending: isSortingNewColumn ? true : !prev?.ascending,
    }))
  }
  return (
    <Container>
      <Table
        scrollableTable
        stickyHead
        height="500px"
        maxWidth="1200px"
        width="100%"
        HeaderComponent={
          <Summary>
            <AppTypography variant="subtitle2" component="div">
              Current&nbsp;vote{'  '}
              {isMobileLg && <br />}
              <AppTypography variant="h6" component="span">
                {getCommifiedFormat(total.currentVote)}{' '}
              </AppTypography>
              <AppTypography variant="subtitle2" component="span" transparent>
                /{getCommifiedFormat(vePtp.totalSupply)}&nbsp;vePTP
              </AppTypography>
            </AppTypography>
            <AppTypography variant="h6" component="div">
              {getDpFormat(total.votedPercentage)}
              %&nbsp;
              <AppTypography variant="subtitle2" component="span" transparent>
                of total vePTP Supply
              </AppTypography>
            </AppTypography>
          </Summary>
        }
        FooterComponent={
          <Summary>
            <AppTypography variant="body2">
              Total Gauge: {tableData.length}
            </AppTypography>
            <ExternalLink
              href={`${NETWORKS[chainId].blockExplorerUrls[0]}/address/${
                voter.address[chainId] || ''
              }`}
              disableUnderline
            >
              <Colorized variant="blue">
                <AppTypography centerContent variant="body2">
                  View vote transactions
                  <ExportIcon
                    size={16}
                    style={{ marginLeft: '4px' }}
                    color="inherit"
                  />
                </AppTypography>
              </Colorized>
            </ExternalLink>
          </Summary>
        }
      >
        <Table.Head>
          <Table.Row>
            {tableHeadConfig.map((headData, index) => (
              <Table.Data
                key={index}
                {...headData.style}
                onClick={() => handleTableHeadDataClick(index)}
                enableSortingIcon={
                  (sorting?.columnIndex === index &&
                    (sorting.ascending ? 'asc' : 'desc')) ||
                  undefined
                }
                style={{ userSelect: 'none', cursor: 'pointer' }}
              >
                {headData.data}
              </Table.Data>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {table.tableData.map((rowData) => (
            <Table.Row key={rowData.id}>
              {rowData.configs.map(({ cell }, index) => (
                <Table.Data
                  key={index}
                  // apply corresponding styles to each column
                  {...tableHeadConfig[index]?.style}
                >
                  {cell}
                </Table.Data>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Container>
  )
}

export default GaugeTable
