import React, { ReactElement } from 'react'
import InfoOutline from '../../../assets/icons/InfoOutline.svg'
import { tokenIconPaths } from '../../../assets/tokens'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import {
  Capsule,
  Container,
  Status,
  StatusText,
} from './PoolStakeStatusBar.elements'
interface PoolStakeStatusBarProps {
  children: React.ReactNode
  responsive?: boolean
}
function PoolStakeStatusBar({
  children,
  responsive = true,
}: PoolStakeStatusBarProps): ReactElement {
  return <Container responsive={responsive}>{children}</Container>
}

export default PoolStakeStatusBar

export type StatusType = 'Earning' | 'Booster+' | 'Please Stake'

interface Props {
  status: StatusType
}

const iconPathMap: { [key in StatusType]: string } = {
  'Booster+': tokenIconPaths.vePTP,
  Earning: tokenIconPaths.PTP,
  'Please Stake': InfoOutline,
}

PoolStakeStatusBar.Status = function PoolStakeStatus({
  status,
}: Props): ReactElement {
  return (
    <Status>
      <TokenIcon
        iconPath={iconPathMap[status]}
        size={20}
        className="PoolStakeStatus__token-icon"
      />
      <Capsule status={status}>
        <StatusText variant="caption3">{status}</StatusText>
      </Capsule>
    </Status>
  )
}
