import { Box, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

interface StyledSelectItemButtonProps {
  disabledPointer?: boolean
  disabled?: boolean
}
export const StyledSelectItemButton = styled(Box)<StyledSelectItemButtonProps>`
  ${({ disabledPointer, disabled, theme }) => css`
    cursor: ${disabledPointer ? 'initial' : 'pointer'};
    border-radius: ${theme.borderRadius.md};
    padding: 4px 8px;
    min-width: 180px;
    border: 1px solid ${theme.palette.common.white};
    .select-item-button__arrow-down {
      font-size: 1.5rem;
      margin: 0 0 0 auto;
    }

    ${disabled &&
    css`
      border: 0;
      cursor: unset;
      .select-item-button__arrow-down {
        display: none;
      }
    `}
  `}
`

export const SelectItemLabel = styled(Typography)`
  white-space: nowrap;
`
