import * as Sentry from '@sentry/react'
import {
  prepareWriteContract,
  writeContract,
  WriteContractResult,
} from '@wagmi/core'
import { useState } from 'react'
import { vePtp } from '../../config/contracts'
import { TokenSymbol } from '../../config/contracts/token/tokenSymbol'
import { VEPTP_V3_ABI } from '../../config/contracts/wagmiAbis/VePtpV3'
import { useModal } from '../../contexts/ModalContext'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ModalId } from '../../interfaces/Modal'
import { isUserDeniedTransaction } from '../../utils/contract'
import useRevertReason from '../useRevertReason'

type Props = {
  isWaiting: boolean
  isDone: boolean
  handleClaimVePtp: () => Promise<void>
}
const useClaimVeptp = (): Props => {
  const { chainId } = useWeb3()
  const getRevertReason = useRevertReason()
  const [isWaiting, setIsWaiting] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const { showMessage } = useSnackbar()
  const {
    modalDispatch,
    actions: { openModal },
  } = useModal()
  const handleClaimVePtp = async () => {
    let writeContractResult: WriteContractResult | undefined

    try {
      modalDispatch(openModal(ModalId.CLAIM_VEPTP_WAIT_FOR_CONFIRMATION))
      const vePtpAddress = vePtp.getAddress(chainId)
      if (vePtpAddress) {
        const config = await prepareWriteContract({
          address: vePtpAddress,
          abi: VEPTP_V3_ABI,
          functionName: 'claim',
          chainId,
        })
        writeContractResult = await writeContract(config)
        setIsWaiting(true)
        if (writeContractResult) {
          const { hash, wait } = writeContractResult
          await wait()
          modalDispatch(
            openModal(ModalId.TRANSACTION_SUBMITTED, {
              transactionHashes: [hash],
              tokenSymbols: [TokenSymbol.VEPTP],
            }),
          )
          showMessage(`Successfully claimed vePTP.`)
          setIsDone(true)
        } else {
          showMessage('Transaction failed.', 'warning')
          modalDispatch(openModal(ModalId.UNSET))
        }
      }
    } catch (err) {
      if (!isUserDeniedTransaction(err)) {
        Sentry.setContext('contract_call', {
          name: 'claim_vePTP',
        })
        Sentry.captureException(err)
      }
      const reason = await getRevertReason(err)
      showMessage(reason || 'Transaction failed.', 'warning')
      modalDispatch(openModal(ModalId.UNSET))
    } finally {
      setIsWaiting(false)
    }
  }
  return { handleClaimVePtp, isWaiting, isDone }
}

export default useClaimVeptp
