import { alpha, Box, Divider, LinearProgress } from '@mui/material'
import styled, { css } from 'styled-components'

export const Shade = styled(Box)`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: -4.5%;
    left: 0;
    background: radial-gradient(
      40% 3.56% at 50% 5%,
      ${theme.palette.primary[500]} 0%,
      ${alpha(theme.palette.primary[700], 0.51)} 63.46%,
      rgba(6, 45, 130, 0) 100%
    );
  `}
`
interface PageContainerProps {
  noPadding?: boolean
}

export const PageContainer = styled(Box)<PageContainerProps>`
  ${({ theme, noPadding }) => css`
    position: relative;
    width: 100%;
    max-width: 640px;
    margin: 0 auto 16px;
    padding: ${noPadding ? 'unset' : '24px 24px 32px 24px'};
    border-radius: 20px;
    box-shadow: 0px 0px 20px -10px rgba(255, 255, 255, 0.5),
      0px 30px 80px -60px #0c58ff, 0px 100px 180px -100px #00ff57;
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 400px;
      margin: 0 16px 16px;
    }
    @media only screen and (max-width: ${theme.breakpoints.values.xs}px) {
      margin-bottom: 24px;
    }
  `}
`
interface StyledDividerProps {
  $colored?: boolean
  opacity?: number
  margin?: string
}
export const StyledDivider = styled(Divider).attrs(() => ({
  flexItem: true,
}))<StyledDividerProps>`
  ${({ theme, $colored: colored, opacity = 0.5, margin }) => css`
    border-color: ${alpha(theme.palette.common.white, opacity)};
    border-bottom-width: 2px;
    margin: ${margin ? margin : 'unset'};
    &.MuiDivider-withChildren {
      &::before,
      ::after {
        border-top-width: 2px;
      }
    }
    span {
      color: ${theme.palette.common.white};
    }
    ${colored &&
    css`
      border-color: ${alpha(theme.palette.primary[500], opacity)};
      &::before,
      &::after {
        border-color: ${alpha(theme.palette.primary[500], opacity)};
      }
    `}
  `}
`

const scrollbarSize = 4
const borderSize = 7
interface ScrollableBoxProps {
  scrollDirection?: 'horizontal' | 'vertical' | 'both'
}
export const ScrollableBox = styled(Box)<ScrollableBoxProps>`
  ${({ scrollDirection = 'vertical', theme }) => css`
    position: relative;
    ${scrollDirection === 'horizontal' &&
    css`
      padding-right: 0;
      overflow-x: auto;
      overflow-y: hidden;
    `}
    ${scrollDirection === 'vertical' &&
    css`
      padding-bottom: 0;
      overflow-x: hidden;
      overflow-y: auto;
    `}
    ${scrollDirection === 'both' &&
    css`
      overflow: auto;
    `}
    ::-webkit-scrollbar {
      height: ${scrollbarSize + borderSize * 2}px;
      width: ${scrollbarSize + borderSize * 2}px;
    }
    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-track {
      border-radius: 10px;
      // add spacing between content and scrollbar
      border: ${borderSize}px solid transparent;
      background-clip: padding-box;
    }
    ::-webkit-scrollbar-track {
      background-color: ${alpha(theme.palette.primary.main, 0.5)};
    }
    ::-webkit-scrollbar-thumb {
      background-color: #0091de;
    }
    ::-webkit-scrollbar-corner {
      width: 0;
    }
  `}
`

interface ExternalLinkProps {
  disableUnderline?: boolean
}
export const ExternalLink = styled.a.attrs(() => ({
  target: '_blank',
  ref: 'noreferrer',
}))<ExternalLinkProps>`
  ${({ disableUnderline }: ExternalLinkProps) => css`
    text-decoration: ${disableUnderline ? 'unset' : 'underline'};
  `}
`

export type ColorizedPropsVariantType =
  | 'green'
  | 'red'
  | 'yellow'
  | 'platopia'
  | 'white'
  | 'blue'
  | 'inherit'
  | 'orange'

interface ColorizedProps {
  variant?: ColorizedPropsVariantType
}
export const Colorized = styled.span<ColorizedProps>`
  ${({ theme, variant = 'green' }) => css`
    ${variant === 'green' &&
    css`
      color: ${theme.palette.secondary.main};
      fill: ${theme.palette.secondary.main};
    `}
    ${variant === 'red' &&
    css`
      color: ${theme.palette.error.main};
      fill: ${theme.palette.error.main};
    `}
    ${variant === 'yellow' &&
    css`
      color: ${theme.palette.accents.yellow};
      fill: ${theme.palette.accents.yellow};
    `}
    ${variant === 'platopia' &&
    css`
      color: ${theme.palette.platopia.main};
      fill: ${theme.palette.platopia.main};
    `}

    ${variant === 'white' &&
    css`
      color: ${theme.palette.common.white};
      fill: ${theme.palette.common.white};
    `}
    ${variant === 'blue' &&
    css`
      color: ${theme.palette.primary['A200']};
      fill: ${theme.palette.primary['A200']};
    `}

    ${variant === 'inherit' &&
    css`
      color: inherit;
      fill: inherit;
    `}
    ${variant === 'orange' &&
    css`
      color: ${theme.palette.warning.main};
      fill: ${theme.palette.warning.main};
    `}
  `}
`

export const StyledLinearProgress = styled(LinearProgress)`
  ${({ theme }) =>
    css`
      &.MuiLinearProgress-root {
        background-color: ${alpha(theme.palette.primary['A200'], 0.5)};
        border-radius: 999px;
        margin: 8px 0;
      }
      .MuiLinearProgress-bar {
        background-color: ${theme.palette.secondary[500]};
      }
    `}
`
