import { Call } from 'ethcall'
import { BigNumber, utils } from 'ethers'
import { POOLS, TOKENS } from '../../../config/contracts'
import { FetchPriceMethod } from '../../../config/contracts/token/Token'
import { TokenSymbol } from '../../../config/contracts/token/tokenSymbol'
import { ChainId } from '../../../config/networks'
import {
  CallbacksType,
  MulticallHelperSCReturnType,
} from '../../../utils/multicall'

/**
 * This is for fetching price data from smart contract related method etc FetchPriceMethod.PLATYPUS.
 * FetchPriceMethod.COINGECKO_COINMARKETCAP will fetch from balanceContext
 */
export interface SCPriceDataType {
  withoutAccount: SCPriceWithoutAccountType
}

export interface SCPriceWithoutAccountType {
  exchangeRatesFromPlatypus: {
    [id in TokenSymbol]?: string
  }
}

export const fetchSCPriceData = (chainId: ChainId) => {
  const states: SCPriceDataType = {
    withoutAccount: {
      exchangeRatesFromPlatypus: {},
    },
  }
  const contractCalls: Call[] = []
  const callbacks: CallbacksType<MulticallHelperSCReturnType> = []
  const handleSCPriceData = () => {
    for (const token of Object.values(TOKENS)) {
      const method = token.priceFetchingConfig?.method
      if (method === FetchPriceMethod.PLATYPUS) {
        const priceFetchingConfigPayload = token.priceFetchingConfig.payload
        const poolSymbol = priceFetchingConfigPayload.poolSymbol
        const toTokenSymbol = priceFetchingConfigPayload.toTokenSymbol
        const toToken = TOKENS[toTokenSymbol]
        const pool = POOLS[poolSymbol]?.get(chainId)
        if (!pool) continue
        contractCalls.push(
          pool.quotePotentialSwap(
            token.address[chainId] || '',
            toToken.address[chainId] || '',
            utils.parseUnits('1', token.decimals),
          ) as unknown as Call,
        )
        callbacks.push((value) => {
          const { potentialOutcome, haircut } = value as {
            potentialOutcome: BigNumber
            haircut: BigNumber
          }
          states.withoutAccount.exchangeRatesFromPlatypus[token.symbol] =
            utils.formatUnits(potentialOutcome.add(haircut), toToken.decimals)
        })
      }
    }
  }

  handleSCPriceData()

  return {
    contractCalls,
    callbacks,
    states,
  }
}
